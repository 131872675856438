import styled from 'styled-components';

export const Wrapper = styled.div`
  overflow: auto;
`;
Wrapper.displayName = 'Wrapper';

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 1230px;
`;
Content.displayName = 'Content';

export const CartWrapper = styled.div`
  display: flex;
  width: 369px;
  height: 204px;
  flex-direction: column;
  padding: 21px;
  border-radius: 3px;
  box-shadow: 0 2px 20px 0 rgb(28 34 67 / 5%);
  border: solid 1px ${({ theme }) => theme.borders.memoListItem};
  background: ${({ theme }) => theme.backgrounds.analyticsNewGraph};
  margin: 16px; 
  justify-content: space-around;
  cursor: pointer;
  position: relative;
  ${({ selected, theme }) => selected === true && `
    border: 1px solid ${theme.borders.workspaceSelectedItem};
  `}
`;
CartWrapper.displayName = 'CartWrapper';

export const ActionBar = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  box-shadow: 0 2px 8px 0 rgba(28, 34, 67, 0.03);
  border: solid 1px ${({ theme }) => theme.borders.actionButtonsGridCard};
  border-radius: 4px;
  padding: 0 8px;
  background: ${({ theme }) => theme.backgrounds.actionButtonsGridCard};
`;
ActionBar.displayName = 'ActionBar';

export const Title = styled.div`
  font-size: 21px;
  font-weight: 600;
  color: ${({ theme }) => theme.text.runAgentTitle};
  overflow-wrap: anywhere;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: max-content;
  &:hover {
    text-decoration: underline;
  }
`;
Title.displayName = 'Title';

export const Subtitle = styled.div`
  font-size: 15px;
  line-height: 1.28;
  color: ${({ theme }) => theme.text.customAttributeItem};
`;
Subtitle.displayName = 'Subtitle';

export const Time = styled(Subtitle)`
  margin-left: auto;
  font-size: 13px;
`;
Time.displayName = 'Time';
