import React, { useContext } from 'react';
import Select from 'react-select';
import get from 'lodash/get';
import { useSelector, useDispatch } from 'react-redux';
import { updateVulnTemplate } from 'Screens/Contextualization/KnowledgeBase/actions/Actions';
import { selectVulnTemplateDetail } from 'store/KnowledgeBase/selectors';
import { basicSelect } from 'Common/styles/style';
import { capitalize } from 'utils/stringUtils';
import { easeOfResolution } from 'Screens/Contextualization/Constants';
import Wrapper from 'Screens/Contextualization/Manage/components/VulnerabilityDetail/components/Tabs/components/GeneralTab/components/ActionBar/components/EaseOfResolutionPicker/styled';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_VULN_TEMPLATE, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { ThemeContext } from 'Context';

const EaseOfResolutionPicker = () => {
  const dispatch = useDispatch();
  const vulnTemplate = useSelector(selectVulnTemplateDetail);
  const canUpdate = useSelector((state) => selectAllowedByRole(state, FEATURE_VULN_TEMPLATE, PERMISSION_TYPES.UPDATE));
  const id = get(vulnTemplate, '_id', 0);
  const vulnTemplateEaseOfResolution = get(vulnTemplate, 'easeofresolution', '');
  const value = {
    label: capitalize(vulnTemplateEaseOfResolution) || 'Ease of resolution',
    value: vulnTemplateEaseOfResolution || 'Ease of resolution'
  };

  const { themeStyles } = useContext(ThemeContext);

  return (
    <Wrapper isDisabled={ !canUpdate }>
      <Select
        styles={ basicSelect(themeStyles) }
        isSearchable={ false }
        isDisabled={ !canUpdate }
        value={ value }
        onChange={ (e) => dispatch(updateVulnTemplate(id, { easeofresolution: e.value })) }
        options={ easeOfResolution.map((element) => ({ label: element.desc, value: element.name })) }
      />
    </Wrapper>
  );
};

export default EaseOfResolutionPicker;
