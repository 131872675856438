import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as Warning } from 'Images/warning-delete.svg';
import { Accept } from 'Common/Components/Button/styled';

export const Wrapper = styled.div`
  margin-bottom: 10px;
  box-shadow: 18px 43px 50px 0 ${({ theme }) => theme.backgrounds.modal} !important;
`;
Wrapper.displayName = 'Wrapper';

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 430px;
  width: 505px;
  background-color: ${({ theme }) => theme.backgrounds.modal};
  padding: 34px;
  border-top-style: solid;
  border-top-width: 10px;
  border-top-color: ${colors.softBlue};
`;
ModalWrapper.displayName = 'ModalWrapper';

export const Title = styled.div`
  margin-bottom: 5px;
  font-size: 21.5px;
  font-weight: 600;
  color: ${({ theme }) => theme.text.modalTitle2};
  display: flex;
  align-items: center;
`;
Title.displayName = 'Title';

export const WarningIcon = styled(Warning)`
  margin-right: 13px;
  path {
    fill: ${({ theme }) => theme.text.modalTitle2};
  }
`;
WarningIcon.displayName = 'WarningIcon';

export const Subtitle = styled.p`
  font-size: 12.5px;
  color: ${({ theme }) => theme.text.modalTitle2};
  margin-top: 15px;
`;
Subtitle.displayName = 'Subtitle';

export const Bold = styled.span`
  color: ${({ theme }) => theme.text.modalTitle2};  
  font-size: 12.5px;
  font-weight: 600;
  margin-top: 15px;
`;
Bold.displayName = 'Bold';

export const List = styled.ul`
  margin-top: 17px;
  font-size: 12.5px;
  font-weight: 500;
  color: ${({ theme }) => theme.text.modalTitle2};
  list-style-type: none;
`;
List.displayName = 'List';

export const Item = styled.li`
  margin-bottom: 3px;
`;
Item.displayName = 'Item';

export const Done = styled(Accept)`
  width: 89px;
  align-self: flex-end;
`;
Done.displayName = 'Done';
