/* eslint-disable no-nested-ternary */
import React, {
  useState, useRef, useEffect
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Gantt as GanttChart } from 'gantt-task-react';
import 'gantt-task-react/dist/index.css';
import { selectProjects, selectGanttData } from 'store/Projects/selectors';
import { selectTasks } from 'store/Tasks/selectors';
import { openTaskDetail } from 'store/Tasks/actions';
import { setSelectedProject } from 'store/Projects/actions';
import useElementSizeUpdate from 'Hooks/useElementSizeUpdate';
import { openModal } from 'store/modals/actions';
import { MODAL_PROJECTS_CREATE_EDIT } from 'store/modals/modals';
import get from 'lodash/get';
import CustomTooltip from './components/CustomTooltip';
import Wrapper from './styled';
import useTheme from 'Hooks/UseTheme';
import { ThemeContext } from 'Context';

const Gantt = () => {
  const [columnWidth, setColumnWidth] = useState(36);
  const dispatch = useDispatch();
  const { themeStyles } = useTheme(ThemeContext);
  const taskGanttList = useSelector((state) => selectGanttData(state, themeStyles));

  const projectsList = useSelector(selectProjects);
  const tasksList = useSelector(selectTasks);
  const endDate = Math.max(...taskGanttList.map((p) => p.end.getTime()));
  const startDate = Math.min(...taskGanttList.map((p) => p.start.getTime()));
  const totalDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));
  const refContainer = useRef();
  const { width } = useElementSizeUpdate(refContainer);
  const ganttWidth = (21 + totalDays) * columnWidth;

  useEffect(() => {
    const dividedColumns = width / (21 + totalDays);
    if (dividedColumns > 36) setColumnWidth(dividedColumns);
    else setColumnWidth(36);
  }, [width, ganttWidth, totalDays]);

  const handleSelect = (selectedItem, isSelected) => {
    if (isSelected) {
      const type = get(selectedItem, 'type', '');
      if (type === 'project') {
        const selectedProject = projectsList.find((p) => p.id === selectedItem.id);
        if (selectedProject) {
          dispatch(setSelectedProject(selectedProject));
          dispatch(openModal(MODAL_PROJECTS_CREATE_EDIT));
        }
      }
      if (type === 'task' || type === 'milestone') {
        const projectTasks = tasksList.find((p) => p.projectId === selectedItem.project);
        if (projectTasks) {
          const selectedTask = projectTasks.tasks.find((t) => `${selectedItem.project}-${t.id}` === selectedItem.id);
          if (selectedTask) dispatch(openTaskDetail(selectedTask.projectId, selectedTask.id, 'general'));
        }
      }
    }
  };

  const id = 2;
  const row = id && taskGanttList.findIndex((t) => t.id === id) + 1;

  return (
    <Wrapper row={ row } ref={ refContainer }>
      <GanttChart
        tasks={ taskGanttList }
        onSelect={ handleSelect }
        TooltipContent={ (props) => <CustomTooltip task={ props.task } /> }
        columnWidth={ columnWidth }
        fontFamily="Sequel Sans"
        fontSize="13px"
        listCellWidth=""
        ganttHeight={ 0 }
        viewMode="Day"
        headerHeight={ 45 }
        rowHeight={ 50 }
      />
    </Wrapper>
  );
};

export default Gantt;
