import styled from 'styled-components';
import { ReactComponent as Regenerate } from 'Images/regenerate.svg';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 267px;
  height: 34px;
  margin-top: 20px;
  gap: 9px;
  background: ${({ disabled }) => disabled ? '#c2c3c5' : colors.blueCerulean};
  cursor: pointer;
`;
Wrapper.displayName = 'Wrapper';

export const Text = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
`;
Text.displayName = 'Text';

export const RegenerateIcon = styled(Regenerate)`
    path {
      fill: ${colors.white};
    }
`;
