import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as EditIcon } from 'Images/icon-action-bar-edit.svg';
import { ReactComponent as AssigWsIcon } from 'Images/assign-ws.svg';
import { ReactComponent as TwoFactorIcon } from 'Images/2fa.svg';
import { ReactComponent as RoleIcon } from 'Images/change-role.svg';
import { ReactComponent as TrashIcon } from 'Images/icon-action-bar-trash.svg';

export const AssignWs = styled(AssigWsIcon).attrs({
  id: 'qa-assign-ws-to-user'
})`
   & path{
    fill: ${({ theme }) => theme.icons.actionBar.color};
  }
  cursor: ${({ disabled }) => disabled ? 'not-allowed !important' : 'pointer'};
`;

AssignWs.displayName = 'AssignWs';

export const TwoFactor = styled(TwoFactorIcon).attrs({
  id: 'qa-2fa-user'
})`
  cursor: ${({ disabled }) => disabled ? 'not-allowed !important' : 'pointer'};
  g > g > path {
    stroke: none !important;
    fill: ${({ theme }) => theme.icons.actionBar.color};
  }
  g > path {
    fill: ${({ theme }) => theme.icons.actionBar.color};
  }
  &:hover {
    g {
      fill: ${({ theme }) => theme.icons.twoFactor.hover};
    }
    g g path {
      fill: ${({ theme }) => theme.icons.twoFactor.hover};
    }
  }
`;

TwoFactor.displayName = 'TwoFactor';

export const Role = styled(RoleIcon).attrs({
  id: 'qa-change-role-user'
})`
  cursor: ${({ disabled }) => disabled ? 'not-allowed !important' : 'pointer'}; 
  g path {
    fill: ${({ theme }) => theme.icons.actionBar.color};
  }
`;

Role.displayName = 'Role';

export const Trash = styled(TrashIcon).attrs({
  id: 'qa-delete-user'
})`
    height: 17px;
    cursor: ${({ disabled }) => disabled ? 'not-allowed !important' : 'pointer'}; 
     g, path {
      fill: ${colors.red1} !important;
    }
`;

Trash.displayName = 'Trash';

export const Edit = styled(EditIcon).attrs({
  id: 'qa-edit-user'
})`
    height: 17px;
    cursor: ${({ disabled }) => disabled ? 'not-allowed !important' : 'pointer'}; 
    g > path {
      fill: ${({ theme }) => theme.icons.actionBar.color};
    }
`;
