import styled, { css } from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  width: ${(props) => (props.width ? `${props.width}` : '400px')};    
  margin: auto;
  user-select: none;
  background-color: ${({ theme }) => theme.backgrounds.editModal};
  ${({ disabled }) => disabled && css`
    pointer-events: none;
  `}
`;
Wrapper.displayName = 'Wrapper';

export const SectionTitle = styled.div`
  color: ${({ theme }) => theme.text.modalLabel} !important;
  text-transform: capitalize;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 12px; 
`;
SectionTitle.displayName = 'SectionTitle';

export const ImpactItem = styled.div`
    width: 195px;
    height: 24px;
    border-radius: 3px;
    font-size: 12.5px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.08;
    letter-spacing: normal;
    text-align: center;    
    margin-right: 2px;
    margin-bottom: 3px;
    background-color: ${(props) => (props.active ? props.theme.backgrounds.impactsSelected : props.theme.backgrounds.impactsUnselected)};
    color: ${(props) => (props.active ? colors.white : props.theme.text.impactsUnselected)};

    &:hover {
        background-color: ${(props) => (props.active ? '#787a87' : props.theme.backgrounds.impactItem)};
        cursor: pointer;
        color: ${(props) => (props.active ? 'white' : props.theme.textfield.text)};
    }
`;
ImpactItem.displayName = 'ImpactItem';

export const Title = styled.div`
  font-size: 12.5px;
  font-weight: 600;
  line-height: 2.08;
  text-transform: capitalize;
  color: ${({ theme }) => theme.text.modalLabel};
  font-weight: 600;
  margin-bottom: 6px; 
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 7px;
`;
