import styled from 'styled-components';

const Wrapper = styled.div`
border-radius: 3px;
background-color: ${({ theme }) => theme.backgrounds.detailModalFields};
padding: 14px 20px 26px 20px;
user-select: none;
font-size: 13px;
font-weight: 300;
color: #5E6667;
padding: 0px 20px 20px 20px;
`;

export default Wrapper;
