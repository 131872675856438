import React, { createContext, useState, useEffect } from 'react';
import { lightTheme, darkTheme } from './constants';

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState('light');

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    const root = document.getElementById('root');
    if (savedTheme) {
      setTheme(savedTheme);
      root.classList.add(`${savedTheme}-theme`);
    }
  }, []);

  const setParticularTheme = (particularTheme) => {
    setTheme(particularTheme);
    localStorage.setItem('theme', particularTheme);
    const root = document.getElementById('root');
    if (particularTheme === 'light') root.classList.remove('dark-theme');
    else root.classList.remove('light-theme');
    root.classList.add(`${particularTheme}-theme`);
  };

  // Toggle between light and dark mode
  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
    const root = document.getElementById('root');
    if (newTheme === 'light') root.classList.remove('dark-theme');
    else root.classList.remove('light-theme');
    root.classList.add(`${newTheme}-theme`);
  };

  const resetTheme = () => {
    const newTheme = 'light';
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
    const root = document.getElementById('root');
    root.classList.remove('dark-theme');
    root.classList.add(`${newTheme}-theme`);
  };

  // Provide theme and theme toggle functionality to children
  return (
    <ThemeContext.Provider value={ { theme, toggleTheme, resetTheme, themeStyles: theme === 'light' ? lightTheme : darkTheme, setParticularTheme } }>
      {children}
    </ThemeContext.Provider>
  );
};
