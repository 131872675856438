import React, { useContext, useState } from 'react';
import { Wrapper, DataContainer, More, DataItem, IconWrapper, Trash } from './styled';
import { Dropdown } from 'Common/Components/Inputs';
import { components } from 'react-select';
import IssueType from '../IssueType';
import { cloneDeep } from 'lodash';
import { dropdownStyle } from 'Common/Components/Inputs/styled';
import { ThemeContext } from 'Context';

const CustomDropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator { ...props }>
      <More />
    </components.DropdownIndicator>
  );
};

const AlternativeFields = ({ options, className, ticketConfig, setTicketConfig, issueFieldOptions, issueFieldData, users }) => {
  const parsedOptions = options.map(option => ({
    label: option.name,
    value: option.key
  }));
  const issueFieldDataNotRequired = issueFieldData.filter((e) => !e.required);
  const [selectedFields, setSelectedFields] = useState([...issueFieldDataNotRequired]);

  const handleChange = (e) => setSelectedFields([...selectedFields, options.find((option) => option.name === e.label)]); // those that were already selected are rejected
  const filteredOptions = parsedOptions.filter((option) => !selectedFields.map((e) => e.name).includes(option.label));
  const { themeStyles } = useContext(ThemeContext);

  return (
    <Wrapper>
      <Dropdown styles={ dropdownStyle(themeStyles) } components={ { DropdownIndicator: CustomDropdownIndicator } } options={ filteredOptions } className={ className } onChange={ handleChange } />
      <SelectedFields
        setSelectedFields={ setSelectedFields }
        selectedFields={ selectedFields }
        ticketConfig={ ticketConfig }
        setTicketConfig={ setTicketConfig }
        issueFieldOptions={ issueFieldOptions }
        options={ options }
        users={ users }
      />
    </Wrapper>
  );
};

export const SelectedFields = ({ selectedFields, setSelectedFields, ticketConfig, setTicketConfig, issueFieldOptions, options, users }) => {
  const [copyTicketConfig, setCopyTicketConfig] = useState(cloneDeep(ticketConfig));
  const onRemove = (name, key) => {
    setSelectedFields(selectedFields.filter((field) => field.name !== name));

    const filteredFields = Object.keys(ticketConfig.issue_types[issueFieldOptions.key].fields)
      .filter((fieldKey) => selectedFields.some((field) => field.key === fieldKey))
      .reduce((acc, fieldKey) => {
        acc[fieldKey] = ticketConfig.issue_types[issueFieldOptions.key].fields[fieldKey];
        return acc;
      }, {});

    delete filteredFields[key];

    const updatedTicketConfig = {
      ...ticketConfig,
      issue_types: {
        ...ticketConfig.issue_types,
        [issueFieldOptions.key]: {
          ...ticketConfig.issue_types[issueFieldOptions.key],
          fields: filteredFields
        }
      }
    };

    setTicketConfig(updatedTicketConfig);
  };

  const fieldDataWithoutLabel = (keySelected) => {
    const { ...fieldData } = options.find((field) => field.key === keySelected);
    return fieldData;
  };

  const handleIssueTypeChange = (e, key, action) => {
    if (action && (action.action === 'pop-value' || action.action === 'remove-value')) {
      const { [key]: del, ...other } = copyTicketConfig.issue_types[issueFieldOptions.key].fields;
      setCopyTicketConfig({
        ...copyTicketConfig,
        [copyTicketConfig.issue_types[issueFieldOptions.key].fields]: other
      });
    } else {
      const updatedTicketConfig = {
        ...copyTicketConfig,
        issue_types: {
          ...copyTicketConfig.issue_types,
          [issueFieldOptions.key]: {
            ...copyTicketConfig.issue_types[issueFieldOptions.key],
            fields: {
              ...copyTicketConfig.issue_types[issueFieldOptions.key].fields,
              [key]: {
                ...fieldDataWithoutLabel(key),
                value: e
              }
            }
          }
        }
      };
      setTicketConfig(updatedTicketConfig);
      setCopyTicketConfig(updatedTicketConfig);
    }
  };

  return (
    <DataContainer>
      {selectedFields.map(({ id, name, key, schema, options, ...data }) => (
        <DataItem
          key={ id || name }
          found
        >
            <IssueType
              onChange={ (e, action) => handleIssueTypeChange(e, key, action) }
              type={ schema.type }
              title={ name }
              options={ options }
              key={ `${issueFieldOptions.key}-${name}` }
              issueType={ issueFieldOptions.key }
              users={ users }
              { ...data }
            />
            <IconWrapper onClick={ () => onRemove(name, key) }>
              <Trash />
            </IconWrapper>
        </DataItem>
      ))}
    </DataContainer>);
};

export default AlternativeFields;
