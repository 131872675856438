import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as ChevronDown } from 'Images/arrow-down.svg';
import { ReactComponent as WsIcon_ } from 'Images/activity-dashboard.svg';
import { ReactComponent as LockImage } from 'Images/ws-lock.svg';

export const Wrapper = styled.div`
  width: ${({ insideAsset }) => insideAsset ? '211px' : '264px'};
  align-self: center;
  margin-right: 10px;
  user-select: none;
  border-radius: 12px;
  border: solid 1px ${({ theme }) => theme.borders.vulnsCounter};
  background-color: ${({ theme }) => theme.backgrounds.vulnsCounter};
`;
Wrapper.displayName = 'Wrapper';

export const Toggle = styled.div`
  padding: 0 12px 0 9px;
  height: 31px;
  font-size: 11.5px;
  color: ${colors.dark2};
  width: 264px;
  display:flex;
  align-items: center;
  cursor: pointer;
  &:after {
    content: none;
  }
`;
Toggle.displayName = 'Toggle';

export const Menu = styled.div`
  background: ${({ theme }) => theme.backgrounds.actionDropDown};
  width: 264px !important;
  padding-top: 8px;
  padding-bottom: 14px;
  position: absolute;
  z-index: 99;
  border-radius: 2px;
  box-shadow: 2px 2px 30px 0 ${({ theme }) => theme.shadows.actionDropDown};
  border: solid 1px ${({ theme }) => theme.borders.actionDropDown};
  min-height: 100px;
  a {
    text-decoration: none;
  }
`;
Menu.displayName = 'Menu';

export const Item = styled.div`
  cursor: pointer;
  padding-left: 21px;
  padding-right: 17px;
  height: 34px;
  display: flex;
  align-items: center;

  &:hover {
    background-color: ${({ theme }) => theme.backgrounds.actionDropDownHover};
  }

  &:active {
    color: ${({ theme }) => theme.text.actionDropDownTitle};
  }
`;
Item.displayName = 'Item';

export const NoItems = styled.div`
  padding-left: 13px;
  padding-right: 13px;
  height: 43px;
  display: flex;
  align-items: center;
  font-size: 13.5px;
  font-weight: 300;
  font-stretch: normal;
  max-width: 235px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
NoItems.displayName = 'NoItems';

export const WorkspaceSelectedName = styled.div`
  color: ${({ theme }) => theme.text.vulnsCounter};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
  max-width: ${({ insideAsset, lockedWs }) => {
    if (insideAsset && lockedWs) return '110px';
    if (insideAsset && !lockedWs) return '133px';
    if (!insideAsset && lockedWs) return '165px';
    return '184px';
  }};
`;
WorkspaceSelectedName.displayName = 'Item';

export const ArrowDown = styled(ChevronDown)`
  width: 10px;
  color: ${colors.grey5};
`;
ChevronDown.displayName = 'ChevronDown';

export const WorkspaceOptionName = styled.span`
  font-size: 13.5px;
  font-weight: 400;
  font-stretch: normal;
  color: ${({ theme }) => theme.text.actionDropDownItem};
  max-width: 210px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
WorkspaceOptionName.displayName = 'WorkspaceOptionName';

export const ActionsContainer = styled.div`
  margin: 21px 7px 0 21px;
  padding-left: 13px;
  padding-right: 13px;
`;
ActionsContainer.displayName = 'ActionsContainer';

export const ViewAll = styled.span`
  font-size: 12.5px;
  font-weight: 300;
  font-stretch: normal;
  color: ${colors.blueCerulean};
  float: left;
  cursor: pointer;
  position: absolute;
  left: 21px;
`;
ViewAll.displayName = 'ViewAll';

export const CreateNew = styled.span`
  font-size: 12.5px;
  font-weight: 300;
  font-stretch: normal;
  color: ${colors.blueCerulean};
  float: right;
  cursor: pointer;
`;
CreateNew.displayName = 'CreateNew';

export const WorkspaceVulns = styled.span`
  color: ${colors.darkBlueGrey};
  font-size: 12.5px;
  font-weight: 300;
  font-stretch: normal;
  padding-top: 1px;
  position: absolute;
  right: 20px;
`;
WorkspaceVulns.displayName = 'WorkspaceVulns';

export const IconWrapper = styled.div`
`;
IconWrapper.displayName = 'IconWrapper';

export const WsIcon = styled(WsIcon_)`
  width: 12px;
  & path {
    fill: ${({ theme }) => theme.text.vulnsCounter};
  }
`;
WsIcon.displayName = 'WsIcon';

export const WsWrapper = styled.div`
  background-color: ${({ theme }) => theme.backgrounds.vulnsCounter};
  min-width: ${({ insideAsset }) => insideAsset ? '171px' : '226px'};
  height: 31px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 8px;
`;
WsWrapper.displayName = 'WsWrapper';

export const WsSelected = styled.div`
  display: flex;
  align-items: center;

`;
WsSelected.displayName = 'WsSelected';

export const LockIcon = styled(LockImage)`
  width: 14px;
  height: 16px;
  margin-right: 8px;
  & path {
    fill: ${({ theme }) => theme.text.vulnsCounter};
  }
`;
LockImage.displayName = 'LockImage';
