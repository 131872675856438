// Colors

export default {
  black: '#000',
  black1: '#3b3b3b',
  black2: '#0b2128',
  black3: '#686a76',
  black4: '#2c3e50',
  black5: '#1D1D1D54',
  black6: '#1d1d1d',
  black7: '#151515',
  white: '#fff',
  white1: '#fafbfc',
  white2: '#f9f9f9',
  white3: '#f5f8fa',
  white4: 'rgba(209, 218, 227, 0.5)',
  white5: '#e4e9ee',
  white6: '#e5e8ec',
  white7: '#cdd5dd',
  white8: '#d5dee6',
  white9: '#f1f4f7',
  white10: '#f4f5f6',
  white11: '#d8e0e7',
  white12: '#dbdbdb',
  white13: '#F9F9F980',
  white14: '#eff3f7',
  white15: '#e5e5e5',
  white16: '#e3e3e3',
  white17: '#eef0f4',
  white18: '#f5f7f9',
  white19: '#e8e8e8',
  white20: '#dddddd',
  white21: 'dde6ee',
  white22: 'e7ecf1',
  dark1: '#273e55',
  dark2: '#1c2243',
  dark3: '#292929',
  dark4: '#426485',
  dark5: '#53686f',
  dark6: '#707070',
  dark7: '#272f36',
  dark8: '#192027',
  dark9: '#20262c',
  dark10: '#1d2023',
  dark11: '#2e3741',
  dark12: '#20282e',
  dark13: '#2c2f31',
  dark14: '#191f23',
  dark15: 'rgba(209, 218, 227, 0)',
  dark16: '#282e33',
  dark17: '#2D2F31',
  dark18: '#444446',
  dark19: 'rgba(32, 38, 44, 0.4)',
  dark20: '#1f252a',
  dark21: '#29333b',
  dark22: '#40454a',
  dark23: '#232a31',
  dark24: '#1e1f20',
  dark25: '#150a02',
  dark26: '#232b31',
  dark27: '#191919',
  dark28: '#2c363e',
  dark29: '#181818',
  dark30: '#20272e',
  dark31: '#1e2021',
  dark32: '#202224',
  dark33: '#83a9c7',
  dark34: '#161616',
  dark35: '#49687b',
  dark36: '#282C34',
  dark37: 'rgba(11, 13, 15, 0.4)',
  dark38: 'rgba(28, 34, 67, 0.5)',
  dark39: '#3d3e40',
  dark40: '#24272a',
  dark41: '#252525',
  dark42: '#21272c',
  dark43: '#161D22',
  dark44: 'rgba(0, 0, 0, 0.06)',
  purple1: '#9b72ea',
  purple2: '#a581eb',
  purple3: '#c846ce',
  purple5: '#b95bba',
  purple6: '#a14ca4',
  warmPurple: '#a4247a',
  light1: '#fafcfd',
  light2: '#ededed',
  light3: '#e9e9e9',
  light4: '#eff1f4',
  lightGrey: '#d9e4ef',
  paleGrey: '#fafbfc',
  paleGrey2: '#d9e4ef26',
  grey1: '#c2cbd5',
  grey2: '#82b8e5',
  grey3: '#aea3a3',
  grey4: '#989898',
  grey5: '#8da6be',
  grey6: '#7c97b2',
  grey7: '#a6bcd1',
  grey8: '#afb3b8',
  grey9: '#adb6c1',
  grey10: '#b7b7b7',
  grey11: '#7a7e8f',
  grey12: '#bec8d2',
  grey13: '#dcdcdc',
  grey14: '#ececec',
  grey15: '#bdbdbd',
  grey16: '#cfcfcf',
  grey17: '#afb1bc',
  grey18: '#c6c5c4',
  grey19: '#63758d',
  grey20: '#c5c5c5',
  grey21: '#9699ae',
  grey22: '#7389A5',
  grey23: '#e2e2e2',
  grey24: '#dbe2e7',
  grey25: '#e3e7ec',
  grey26: '#f7fbff',
  grey27: '#d6d6d6',
  grey28: '#B0C8DF',
  grey29: '#8b99b2',
  grey30: '#afafaf',
  grey31: '#f7fbff',
  grey32: '#eef2f6',
  grey33: '#e6ebf0',
  grey34: '#c7c7c7',
  grey35: '#3c444b',
  grey36: '#a1b4b6',
  grey37: 'rgba(144, 169, 192, 0.3)',
  grey38: '#d9e4ef',
  grey39: '#7e7e7e',
  gray40: '#6b6b6b',
  gray41: '#3c444d',
  gray42: '#90a9c0',
  grey43: '#71808d',
  grey44: '#b1b1b1',
  grey45: '#d8d9d9',
  grey46: '#a2a2a3',
  grey47: '#aaabab',
  grey48: '#4a5763',
  grey49: '#c7cfd8',
  grey50: '#8f94b2',
  grey51: 'rgb(144, 169, 192)',
  grey52: '#545f6b',
  grey53: '#E8E8E829',
  grey54: '#565a5e',
  grey55: '#639efe',
  grey56: '#7c7474',
  grey57: '#70707080',
  grey58: '#31393f',
  grey59: '#6161610F',
  grey60: '#A7A7A780',
  grey61: '#a8a8a8',
  grey62: '#343f49',
  grey63: '#45494d',
  grey64: '#f2f5f9',
  grey65: '#0000001A',
  grey66: '#5c6c7b',
  grey67: '#696969',
  grey68: '#58626b8a',
  grey69: '#ededed5c',
  grey70: 'rgba(190, 200, 210, 0.2)',
  grey71: '#515a65',
  grey72: '#384552',
  grey73: '#b4c9db',
  grey74: 'rgba(214, 214, 214, 0.9)',
  grey75: 'rgba(124, 116, 116, 0.5)',
  grey76: '#f4f7fa',
  grey77: '#758390',
  grey78: '#c2d1e9',
  grey79: '#686a76',
  grey80: '#082731',
  grey81: 'rgba(8, 39, 49, 0.6)',
  grey82: '#dee5ec',
  grey83: '#bababa',
  grey84: 'rgba(237, 242, 247, 0.5)',
  grey85: 'rgba(161, 180, 182, 0.6)',
  grey86: '#808080',
  grey87: '#8da6bc',
  grey88: '#757575',
  grey89: 'd8d8d8',
  grey90: '#d1d1d1',
  grey91: '#D6D6D6E8',
  grey92: '#7C747482',
  grey93: '#3e4a55',
  grey94: '#acbdd2',
  grey95: '#6f869d',
  grey96: '#dde5f0',
  grey97: 'rgba(190, 200, 210, 0.29)',
  grey98: 'rgba(190, 200, 210, 0.24)',
  grey99: 'rgba(99, 117, 141, 0.14)',
  grey100: 'rgba(161, 180, 182, 0.6)',
  blue1: '#33495e',
  blue2: '#586f85',
  blue3: '#e7e8e9',
  blue4: '#ebeef2',
  blue5: '#8da6be',
  blue6: '#1c2243',
  blue7: '#e2f0fb',
  blue8: '#edf2f7',
  blue9: '#419bf9',
  blue10: '#0067ff',
  blue11: '#1c4566',
  blue12: '#ebeff3',
  blue13: '#264d6d',
  blue14: '#1C4566',
  blue15: '#4c6c8c',
  blue16: 'rgb(38, 77, 109)',
  blue17: '#678cab',
  blue18: '#373a3c',
  blue19: '#0B2633',
  blue20: '#5395ff',
  blue21: '#2996f0',
  blue22: '#122d46',
  blue23: '#262d37',
  blue24: '#0081ed',
  darkBlueGrey: '#1c2243',
  blueCerulean: '#0082ed',
  softBlue: '#53a9ff',
  iceBlue: '#edf2f7',
  greyBlue: '#63758d',
  lightBlue: '#bad8f1',
  red1: '#dc4a4a',
  red2: '#e26464',
  red3: '#ea3158',
  red4: '#d43030',
  red5: '#dd494a',
  redPink: '#ea3158',
  orange1: '#f1ae62',
  orange2: '#f59220',
  orange3: '#f6a34c',
  orange4: '#f1ad62',
  green1: '#88cc3f',
  green2: '#a1ce31',
  green3: '#74ab39',
  green4: '#d5f3b5',
  green5: '#94cc88',
  green6: '#60ad54',
  green7: '#89cc3f',
  apple: '#88cc3f',
  blueGrey: '#90a9c0',
  blueGrey2: '#264d6d',
  veryLightPink: '#d6d6d6',
  primaryColor: '#ffffff',
  tangerine: '#f59220',
  yellow1: '#fffced',
  paleGold: '#ffd76b',
  paleGold2: '#ffd76b26',
  lightYellow: '#ffe7a6',
  lightOrange: '#fac68a',
  lightBlue2: '#7bafda',
  transparent: 'transparent',
  boxShadow1: '18px 43px 50px 0 rgba(167, 167, 167, 0.5)',
  boxShadow2: '0 2px 50px 0 rgba(0, 0, 0, 0.24)',
  boxShadow3: '2px 2px 5px 0 rgba(0, 0, 0, 0.06), inset 0 -2px 0 0 #696969',
  boxShadow4: 'rgb(175, 175, 175) 0px 1px 0px 0px',
  boxShadow5: 'rgba(0, 0, 0, 0.06) 2px 2px 5px 0px, rgb(175, 175, 175) 0px -1px 0px 0px inset',
  boxShadow6: 'rgba(0, 0, 0, 0.24)',
  boxShadow7: '2px 2px 5px 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 #afafaf'
};
