import styled from 'styled-components';

export const Wrapper = styled.div`
  user-select: none;
  border-radius: 5px;
  position: absolute;
  background-color: ${({ theme }) => theme.backgrounds.modal};
  width: 550px;
  right: 28px;
  bottom: 31px;
  box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.24);
  z-index: 2000;
`;
Wrapper.displayName = 'Wrapper';

export default Wrapper;
