import styled from 'styled-components';

const Text = styled.span`
  text-transform: ${(props) => (props.capitalize ? props.capitalize : 'none')};
  color: ${({ theme }) => theme.text.tableVulnName};
  font-size: 12.5px;
`;
Text.displayName = 'Text';

export default Text;
