/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  gap: 22px;
`;
Wrapper.displayName = 'Wrapper';

export const TitleContainer = styled.div`
  display: flex;
  justify-content: right;
  width: 267px;
`;
TitleContainer.displayName = 'TitleContainer';
