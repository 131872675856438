import styled from 'styled-components';

export const Table = styled.table`
  margin: 8px 0 0 20px;
`;
Table.displayName = 'Table';

export const Row = styled.tr`
  font-size: 13px;
`;
Row.displayName = 'Row';

export const Title = styled.td`
  font-weight: 600;
  color: ${({ theme }) => theme.text.detailModalFields};
  width: 160px;
  padding-bottom: 14px;
`;
Title.displayName = 'Title';

export const Value = styled.td`
  font-weight: 400;
  color: ${({ theme }) => theme.text.detailModalFields};
  padding-bottom: 14px;
`;
Value.displayName = 'Value';
