import React from 'react';
import { TableHeader } from '../../styled';
import LastActivityCell from './components/LastActivityCell';
import CheckColumn from './components/CheckColumn';
import CheckHeader from './components/CheckHeader';
import TriggerByColumn from './components/TriggerByColumn';
import NotificationMessageColumn from './components/NotificationMessageColumn';
import { TypeColumn } from './styled';

export default function getColumns (isFetching) {
  const columns = [
    {
      Header: () => (<CheckHeader />),
      id: 'selected',
      resizable: false,
      sortable: false,
      filterable: false,
      maxWidth: 55,
      minWidth: 55,
      headerStyle: { overflow: 'visible' },
      style: { display: 'flex' },
      Cell: ({ original }) => <CheckColumn notification={ original } />
    }, {
      Header: () => <TableHeader>NOTIFICATION</TableHeader>,
      id: 'message',
      resizable: false,
      sortable: false,
      filterable: false,
      Cell: ({ original }) => <NotificationMessageColumn original={ original } />
    }, {
      Header: () => <TableHeader>TYPE</TableHeader>,
      id: 'type',
      resizable: false,
      sortable: false,
      filterable: false,
      maxWidth: 100,
      minWidth: 100,
      Cell: ({ original }) => <TypeColumn>{original.type === 'hosts' ? 'assets' : original.type}</TypeColumn>
    }, {
      Header: () => <TableHeader>TRIGGERED BY</TableHeader>,
      id: 'trigered_by',
      resizable: false,
      sortable: false,
      filterable: false,
      Cell: ({ original }) => <TriggerByColumn original={ original } />
    }, {
      Header: () => <TableHeader>TIME</TableHeader>,
      resizable: false,
      sortable: false,
      filterable: false,
      id: 'create_time',
      Cell: (rowInfo) => <LastActivityCell rowInfo={ rowInfo } isFetching={ isFetching } />
    }];

  return columns;
}
