import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as LockImage } from 'Images/ws-lock.svg';
import { ReactComponent as UnlockImage } from 'Images/ws-unlock.svg';

export const Wrapper = styled.div`
  display: flex;
  cursor: pointer;
`;
Wrapper.displayName = 'Wrapper';

export const LockIcon = styled(LockImage)`
  g > path {
    fill: ${({ theme }) => theme.icons.actionBar.color};
  }

`;
LockImage.displayName = 'LockImage';

export const UnlockIcon = styled(UnlockImage)`
  g > path {
    fill: ${({ theme }) => theme.icons.actionBar.color};
  }

`;
UnlockIcon.displayName = 'UnlockIcon';

export const Text = styled.span`
  font-size: 13px;
  color: ${colors.grey19};
  margin-left: 9px;
`;
Text.displayName = 'Text';
