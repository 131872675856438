/* eslint-disable no-unused-vars */
import { get, isEmpty } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import {
  mockProjectData, normalizeProjects, parseProjectData, parseToLabel
} from './helpers';

export const selectError = (state) => get(state, 'settings.ticketingTools.jira.error', false);

export const selectSuccess = (state) => get(state, 'settings.ticketingTools.jira.success', false);

export const selectMessage = (state) => get(state, 'settings.ticketingTools.jira.message', '');

export const selectUrl = (state) => get(state, 'settings.ticketingTools.jira.url', '');

export const selectProject = (state) => get(state, 'settings.ticketingTools.jira.projectKey', '');

export const selectAuthenticated = (state) => get(state, 'settings.ticketingTools.jira.authenticated', false);

export const selectAuthorized = (state) => get(state, 'settings.ticketingTools.jira.authorized', false);

export const selectConsumerKey = (state) => get(state, 'settings.ticketingTools.jira.consumerKey', '');

export const selectCustomFields = (state) => get(state, 'settings.ticketingTools.jira.customFields', {});

export const selectTemplates = (state) => get(state, 'settings.ticketingTools.jira.templates', '');

export const selectTemplate = (state) => get(state, 'settings.ticketingTools.jira.template', '');

export const selectIssueType = (state) => get(state, 'settings.ticketingTools.jira.issueType', '');

export const selectIsFetching = (state) => get(state, 'settings.ticketingTools.jira.isFetching', false);

export const selectTicketConfig = (state) => get(state, 'settings.ticketingTools.jira.ticketConfig', {});

export const selectDefaultProject = (state) => get(state, 'settings.ticketingTools.jira.defaultProject', '');

export const selectIssueTypes = () => [
  { desc: 'Task', name: 'task' },
  { desc: 'Bug', name: 'bug' },
  { desc: 'Improvement', name: 'improvement' },
  { desc: 'New Feature', name: 'new_feature' },
  { desc: 'Epic', name: 'epic' }
];

// export const selectProjectData = (state) => get(state, 'settings.ticketingTools.jira.template', {});
export const selectMockProjectsData = () => parseToLabel(parseProjectData(mockProjectData));
export const selectProjects = (state) => {
  const templates = get(state, 'settings.ticketingTools.jira.templates', null);
  if (templates) return normalizeProjects(templates);
  return [];
};
export const selectPublicKey = (state) => get(state, 'settings.ticketingTools.jira.publicKey', '');

export const selectProjectData = (state) => {
  const template = get(state, 'settings.ticketingTools.jira.template', {});
  if (!isEmpty(template)) return parseProjectData(cloneDeep(template));
  return template;
};

export const selectIsTemplate = (state) => get(state, 'settings.ticketingTools.jira.isTemplate');

export const selectProjectsSelected = (state) => get(state, 'settings.ticketingTools.jira.payload.projects_selected', []);

export const selectJiraUsers = state => get(state, 'settings.ticketingTools.jira.users', []);
