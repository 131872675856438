import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 34px;
  padding-left: 15px;
`;
Wrapper.displayName = 'Wrapper';

export const Label = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.text.jobDetailDescription};
  text-align: left;
  margin-bottom: 7px;
`;
Label.displayName = 'Label';

export const Input = styled.input`
  padding: 10px 16px 10px 16px;
  background-color: ${({ theme }) => theme.textfield.background};
  font-size: 13.5px;
  color: ${({ theme }) => theme.textfield.text};
  border: 0;
`;
Input.displayName = 'Input';
