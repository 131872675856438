import styled from 'styled-components';
import colors from 'Styles/colors';
import { slideIn } from 'Styles/effects';

export const ModalContent = styled.div`
  padding: 34px 27px 27px 31px;
  box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.24);
  border-radius: 2px;
  border-top: 10px solid ${colors.softBlue};
  background: ${({ theme }) => theme.backgrounds.modal};
  width: 505px;
  height: 424px;
  overflow: auto;
  ${slideIn}
`;
ModalContent.displayName = 'ModalContent';

export const Title = styled.h3`
  font-size: 21.5px;
  font-weight: 600;
  line-height: 1.49;
  color: ${({ theme }) => theme.text.modalTitle};
  margin: 2px 0 11px 0;
`;
Title.displayName = 'Title';

export const Subtitle = styled.h5`
  font-size: 12.5px;
  font-weight: 500;
  line-height: 1.44;
  color: ${colors.greyBlue};
  margin-bottom: 21px;
  font-weight: 400; 
`;
Subtitle.displayName = 'Subtitle';

export const Buttons = styled.div`
  display: flex;
  position: absolute;
  bottom: 27px;
  right: 27px;
  & >:first-child {
    margin-right: 9.5px;
  }
`;
Buttons.displayName = 'Buttons';
