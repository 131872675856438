import styled from 'styled-components';

export const Wrapper = styled.div`
margin-top: 10px;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
    user-select: none;
    text-align: left;
    font-size: 13.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.37;
    letter-spacing: normal;
    color: ${({ theme }) => theme.text.blueSubtitle};
`;
Title.displayName = 'Title';

export const TagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 568px;
  margin-top: 14px;
  margin-bottom: 14px;
  >:not(:nth-child(4n)){
    margin-right: 10px;
  }
  >:nth-child(n+5){
    margin-top: 14px;
  }
`;
TagsContainer.displayName = 'TagsContainer';

export const NoCFContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
NoCFContainer.displayName = 'NoCFContainer';

export const NoCF = styled.div`
  cursor: default;
  user-select: none;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #90a9c0;
`;
NoCF.displayName = 'NoCF';

export const NoCFBold = styled(NoCF)`
    font-weight: 600;
    white-space: break-spaces;
`;
NoCFBold.displayName = 'NoCFBold';
