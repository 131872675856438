import styled from 'styled-components';
import { ReactComponent as _CopyLink } from 'Images/copy_link.svg';

const CopyLinkIcon = styled(_CopyLink)`
  path {
    fill: ${({ theme }) => theme.icons.actionBar.color};
  }
`;
CopyLinkIcon.displayName = 'CopyLinkIcon';

export default CopyLinkIcon;
