import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tour from 'reactour';
import { NextBtn, PrevBtn } from './styled';
import { endNewTour } from 'store/Manage/actions';
import { selectNewTourFinished } from 'store/Preferences/Tour/selectors';

const CommonTour = ({ isTourOpen, setTour, steps, flag }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const dispatch = useDispatch();
  const tourFinished = useSelector((state) => selectNewTourFinished(state, flag));

  useEffect(() => {
    setTour(!tourFinished);
  }, [dispatch, tourFinished, setTour]);

  const enabledSteps = steps.filter(({ selector }) => {
    if (selector === '' || selector === 'first-tour-step') return true;
    return !!document.querySelector(selector);
  });

  const finishAllTour = () => {
    setTour(false);
    dispatch(endNewTour(true));
  };

  const currentTheme = () => {
    const themeClass = localStorage.getItem('theme') === 'light' ? 'light-tour' : 'dark-tour';
    return themeClass;
  };

  return (
    <Tour
      onRequestClose={ () => setTour(false) }
      steps={ enabledSteps }
      isOpen={ isTourOpen }
      lastStepNextButton={ <PrevBtn onClick={ () => dispatch(endNewTour(false, flag)) } children="Close" /> }
      nextButton={ <NextBtn children="Next tip >" /> }
      getCurrentStep={ (curr) => { setCurrentStep(curr); } }
      prevButton={ (((currentStep + 1) === steps.length) || (steps.length === 2)) ? <></> : <PrevBtn onClick={ finishAllTour } children="Exit Tour" /> }
      showNavigation={ false }
      showNumber={ false }
      showCloseButton={ false }
      maskClassName='tourBackground'
      startAt={ 1 }
      className={ `new-tour ${currentTheme()}` }
    />
  );
};

export default CommonTour;
