import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { Wrapper, Title, ChildWrapper, Header, ExpandedIcon, CollapsedIcon } from './styled';

const Comment = ({ children }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Wrapper>
      <Header onClick={ handleClick }>
        <Title children={ 'Comment' } />
        { isExpanded ? <ExpandedIcon /> : <CollapsedIcon /> }
      </Header>
      <ChildWrapper isExpanded={ isExpanded } children={ children } />
    </Wrapper>
  );
};

Comment.propTypes = {
  children: PropTypes.node.isRequired,
  count: PropTypes.number,
  defaultValue: PropTypes.bool,
  id: PropTypes.string.isRequired,
  tab: PropTypes.string.isRequired
};

Comment.defaultProps = {
  count: -1,
  defaultValue: false
};

export default React.memo(Comment);
