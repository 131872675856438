/* eslint-disable import/prefer-default-export */

export const CVSS_PATTERNS = {
  'CVSS v2.0': /^AV:[NAL]\/AC:[LH]\/Au:[NSM]\/C:[CNP]\/I:[CNP]\/A:[CNP]$/,
  'CVSS v3.1': /^CVSS:3\.1\/AV:(N|A|L|P)\/AC:(L|H)\/PR:(N|L|H)\/UI:(N|R)\/S:(U|C)\/C:(N|L|H)\/I:(N|L|H)\/A:(N|L|H)$/,
  'CVSS v4.0': /^CVSS:4\.0\/AV:[NALP]\/AC:[LH]\/AT:[PN]\/PR:[NLH]\/UI:[NRA]\/VC:[NLH]\/VI:[NLH]\/VA:[NLH]\/SC:[NLH]\/SI:[NLH]\/SA:[NLH]\/E:[XUP]\/CR:[LMH]\/IR:[LMH]\/AR:[LMH]\/MAV:[NALP]\/MAC:[LH]\/MAT:[PN]\/MPR:[NLH]\/MUI:[PN]\/S:[NUC]\/AU:[N]\/R:[A-Z]\/V:[A-Z]\/RE:[A-Z]\/U:[A-Za-z]+$/
};

export const CVSS_TYPES = {
  'CVSS v2.0': 'cvss2',
  'CVSS v3.1': 'cvss3',
  'CVSS v4.0': 'cvss4'
};
