import React from 'react';
import CustomReactTable from 'Common/Components/CustomReactTable/styled';
import { useSelector, useDispatch } from 'react-redux';
import { withBreakpoints } from 'react-breakpoints';
import {
  selectIsFetchingExpandedData,
  selectVulnsGroupedData,
  selectVulnsSelected
} from 'store/ManageGeneral/selectors';

import { PropTypes } from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { getTrProps } from 'Screens/Contextualization/Manage/components/ManageTable/components/Table/Functions';
import {
  selectVulnRow,
  setShowContextMenu,
  unSelectAllVulns
} from 'store/ManageGeneral/actions';

const SubTable = ({ getColumns, row, themeStyles }) => {
  const dispatch = useDispatch();
  const rowCount = get(row, 'original.count', 0);
  const vulnsSelected = useSelector(selectVulnsSelected);
  const isFetchingExpandedData = useSelector(selectIsFetchingExpandedData);
  const groupedData = useSelector((state) => selectVulnsGroupedData(state, row.index));

  const selectRowAction = (row) => dispatch(selectVulnRow({}, row));
  const unSelectAllAction = (row) => dispatch(unSelectAllVulns({}, row));
  const showContextMenuAction = (value, xPos, yPos) => dispatch(setShowContextMenu(value, xPos, yPos));

  const hasGroupedData = !isEmpty(groupedData);
  const emptyState = isFetchingExpandedData ? [''] : [];

  return (
    <CustomReactTable
      manual
      minRows={ 0 }
      data={ hasGroupedData ? groupedData : emptyState }
      columns={ getColumns(hasGroupedData) }
      defaultPageSize={ rowCount }
      showPagination={ false }
      TheadComponent={ () => null }
      getTrProps={ (_, rowInfo) => getTrProps(rowInfo, vulnsSelected, selectRowAction, unSelectAllAction, showContextMenuAction, themeStyles) }
    />
  );
};

SubTable.propTypes = {
  getColumns: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired
};

export default withBreakpoints(SubTable);
