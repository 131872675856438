import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Wrapper, Label, dropdownStyle } from '../DropdownField/styled';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoadingGroupedData, selectOptionsData, selectTemplateFieldValue, selectTemplateSelected } from 'store/Settings/serviceDesk/selectors';
import { getDataForGroupedDropdown, setField } from 'store/Settings/serviceDesk/actions';
import { DROPDOWN_ENDPOINTS } from 'store/Settings/serviceDesk/constants';
import useTheme from 'Hooks/UseTheme';

const GroupedDropdown = ({ fieldName, mandatory }) => {
  const dispatch = useDispatch();
  const title = fieldName.split('_').join(' ');
  const initialCategoryValue = useSelector((state) => selectTemplateFieldValue(state, fieldName));
  const initialSubategoryValue = useSelector((state) => selectTemplateFieldValue(state, 'subcategory'));
  const [value, setValue] = useState({
    label: initialSubategoryValue,
    value: { groupedLabel: `${initialCategoryValue} - ${initialSubategoryValue}`, category: initialCategoryValue, subcategory: initialSubategoryValue }
  });
  const options = useSelector((state) => selectOptionsData(state, DROPDOWN_ENDPOINTS[fieldName]));
  const templateSelected = useSelector(selectTemplateSelected);
  const isLoading = useSelector(selectLoadingGroupedData);
  const { themeStyles } = useTheme();

  useEffect(() => {
    dispatch(getDataForGroupedDropdown(DROPDOWN_ENDPOINTS[fieldName]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (v) => {
    const template = templateSelected;
    const fieldToUpdate = { [fieldName]: { data: { name: v.value.category }, type: mandatory ? 'mandatory' : 'optional' }, deleted: false };
    const subcategoryField = { subcategory: { data: { name: v.value.subcategory }, type: mandatory ? 'mandatory' : 'optional' }, deleted: false };
    if (templateSelected?.fields?.length > 0 && templateSelected.fields.find(el => Object.hasOwn(el, fieldName))) {
      const filteredFields = templateSelected.fields.filter(item => !Object.hasOwn(item, fieldName) && !Object.hasOwn(item, 'subcategory'));
      Object.assign(template, { fields: [...filteredFields, fieldToUpdate, subcategoryField] });
    } else if (templateSelected?.fields?.length > 0 && !templateSelected.fields.find(el => Object.hasOwn(el, fieldName))) {
      const filteredFields = templateSelected.fields.filter(item => !Object.hasOwn(item, fieldName) && !Object.hasOwn(item, 'subcategory'));
      Object.assign(template, { fields: [...filteredFields, fieldToUpdate, subcategoryField] });
    } else {
      Object.assign(template, { fields: [fieldToUpdate, subcategoryField] });
    }

    dispatch(setField('templateSelected', template));
    setValue(v);
  };

  const formatOptionLabel = (option, { context }) => {
    if (typeof option.label === 'undefined') return '';
    else {
      if (context === 'value') {
        return `${option?.value?.groupedLabel}` || '';
      } else return option.label;
    }
  };

  return (
    <Wrapper mandatory={ mandatory }>
      { mandatory ? <Label>{ title }</Label> : null }
      <Select
        value={ value }
        defaultValue={ value }
        options={ options }
        formatOptionLabel={ formatOptionLabel }
        onChange={ handleChange }
        styles={ dropdownStyle(themeStyles) }
        isSearchable={ false }
        isLoading={ isLoading }
      />
    </Wrapper>
  );
};

export default GroupedDropdown;
