import React, { useState } from 'react';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_REPORTING, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectIsFetching,
  selectPage, selectReportList, selectReportsCount, selectRowsPerPage,
  selectSelectedReports
} from 'store/ExecutiveReport/selectors';
import { cloneReport, removeReport, setPage } from 'store/ExecutiveReport/actions';
import Pagination from 'Common/Components/Pagination';
import CreateButton from './components/CreateButton';
import { Wrapper, Actions, PaginationContainer } from './styled';
import { CloneAndEditCell, CloneAndUpdateCell, DeleteCell, DownloadCell } from '../Table/components/cells';
import { redirect } from 'store/Router/actions';
import ModalConfirmation from 'Common/Components/ModalConfirmation';
import ModalWarning from 'Common/Components/ModalWarning';

const ActionBar = () => {
  const dispatch = useDispatch();
  const [showModal, setModal] = useState(false);
  const [error, setError] = useState(false);
  const canCreate = useSelector((state) => selectAllowedByRole(state, FEATURE_REPORTING, PERMISSION_TYPES.CREATE));
  const isFetching = useSelector(selectIsFetching);
  const data = useSelector(selectReportList);
  const rowsPerPage = useSelector(selectRowsPerPage);
  const page = useSelector(selectPage);
  const reportsCount = useSelector(selectReportsCount);
  const hasData = data.length > 0;
  const onPrev = () => dispatch(setPage(page - 1));
  const onNext = () => dispatch(setPage(page + 1));
  const reportsSelected = useSelector(selectSelectedReports);
  const [message, setMessage] = useState();
  const [title, setTitle] = useState();

  const action = {
    clone: (id, isRetry) => {
      handleClone(id, isRetry);
      setTitle('Clone and Update Report');
      setMessage('Please select only one Report.');
    },
    edit: (id) => {
      handleEdit(id);
      setTitle('Edit Report');
      setMessage('Please select only one Report.');
    },
    delete: (id) => {
      if (reportsSelected.length >= 1) setModal(true);
      else {
        setTitle('Delete Report(s)');
        setMessage('You’re about to delete 3 Reports, this action can’t be undone. Are you sure you want to proceed?');
        setError(true);
      }
    }
  };

  const handleSubmit = () => {
    setModal(true);
    const ids = reportsSelected.map((e) => (e?.id));
    const reportsToDelete = { ids };
    dispatch(removeReport(reportsToDelete));
    setModal(false);
  };

  const handleEdit = (id) => {
    if (id && reportsSelected.length === 1) dispatch(redirect(`/report/edit/${id}`));
    else setError(true);
  };

  const handleClone = (id, isRetry) => {
    if (id && reportsSelected.length === 1) dispatch(cloneReport(id, isRetry));
    else setError(true);
  };

  const handleClose = () => {
    setModal(false);
  };

  return (
    <Wrapper canCreate={ canCreate }>
      {canCreate && <CreateButton />}
      <Actions>
        <CloneAndUpdateCell rowInfo={ reportsSelected } cloneAction={ action.clone } />
        <CloneAndEditCell rowInfo={ reportsSelected } editAction={ action.edit } />
        <DownloadCell rowInfo={ reportsSelected } setError={ setError } setTitle={ setTitle } setMessage={ setMessage } />
        <DeleteCell deleteAction={ action.delete } />
      </Actions>
      <PaginationContainer>
        <Pagination offset={ (hasData || isFetching) ? page : null } limit={ rowsPerPage } total={ reportsCount } onPrev={ onPrev } onNext={ onNext } />
      </PaginationContainer>
      { showModal
        ? <ModalConfirmation
            handleSubmit={ handleSubmit }
            handleClose={ handleClose }
            entity="Report"
            message="This operation cannot be undone. Are you sure you want to proceed?"
        />
        : null
      }
      { error
        ? <ModalWarning
            onCloseCallback={ () => setError(false) }
            title={ title }
            description={ message }
        />
        : null
      }
    </Wrapper>
  );
};
export default ActionBar;
