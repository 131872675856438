/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import get from 'lodash/get';
import TimeAgo from 'javascript-time-ago';
// Load locale-specific relative date/time formatting rules.
import en from 'javascript-time-ago/locale/en';
import { FormattedMessage } from 'react-intl';
import DateFormatter from 'Common/Components/DateFormatter';
import Skeleton from 'Common/Components/Skeleton';
import {
  ColUserName, ColUserRole, ColUserLastSeen, ColUserIp, ColUser2faStatus, ColWorkspaces,
  Username
} from './styled';
import TwoFactorSwitch from './2faSwitch';
import { CheckHeader } from './CheckHeader';
import { CheckColumn } from './CheckColumn';

TimeAgo.addLocale(en);

const getUserWorkspaces = (user) => {
  const workspaceList = user.workspaces.map((workspace) => (typeof workspace === 'object' ? workspace.name : workspace));
  const deduplicated = [...new Set(workspaceList)];
  return deduplicated.join(', ');
};

export default function getColumns (change2fa, isFetching, activeUser, handleClickName) {
  const columns = [{
    Header: () => <CheckHeader />,
    accessor: 'selected',
    id: 'Selected',
    maxWidth: 38,
    minWidth: 38,
    resizable: false,
    sortable: false,
    show: true,
    Cell: (rowInfo) => (isFetching ? <Skeleton /> : <CheckColumn rowInfo={ rowInfo } />),
    custom_order: -1
  }, {
    Header: () => <FormattedMessage id="users.table.columns.username" />,
    id: 'username',
    Cell: (rowInfo) => (
      isFetching
        ? <Skeleton />
        : (
          <ColUserName>
            <Username isSaml={ get(rowInfo, 'original.user_type', 'local') === 'saml' } active={ rowInfo.original.active } isCurrentUser={ activeUser === rowInfo.original.username } onClick={ (e) => handleClickName(e, rowInfo.original) }>
              {rowInfo.original.username}
            </Username>
          </ColUserName>
          )
    ),
    // maxWidth: '20%',
    resizable: false
  }, {
    Header: () => <FormattedMessage id="users.table.columns.role" />,
    id: 'roles__weight',
    filterable: false,
    Cell: (rowInfo) => (
      isFetching
        ? <Skeleton />
        : (
          <ColUserRole role={ rowInfo.original.roles[0] } active={ rowInfo.original.active }>
            {rowInfo.original.active
              ? (rowInfo.original.roles[0] === 'admin'
                  ? 'Administrator'
                  : (rowInfo.original.roles[0] === 'pentester'
                      ? 'Pentester'
                      : (rowInfo.original.roles[0] === 'client' ? 'Client' : 'Asset Owner')))
              : ''}
          </ColUserRole>
          )
    ),
    maxWidth: 130,
    resizable: false
  }, {
    Header: () => <FormattedMessage id="users.table.columns.lastSeen" />,
    id: 'current_login_at',
    Cell: (rowInfo) => (
      isFetching
        ? <Skeleton />
        : (
          <ColUserLastSeen>
            {rowInfo.original.active ? (rowInfo.original.current_login_at ? <DateFormatter date={ rowInfo.original.current_login_at } /> : '-') : ''}
          </ColUserLastSeen>
          )
    ),
    maxWidth: 135,
    resizable: false
  }, {
    Header: () => <FormattedMessage id="users.table.columns.from" />,
    id: 'last_login_ip',
    Cell: (rowInfo) => (
      isFetching
        ? <Skeleton />
        : (
          <ColUserIp>
            {rowInfo.original.active ? rowInfo.original.last_login_ip : ''}
          </ColUserIp>
          )
    ),
    maxWidth: 110,
    resizable: false
  }, {
    Header: () => <FormattedMessage id="users.table.columns.workspaces" />,
    id: 'workspaces',
    Cell: (rowInfo) => (
      isFetching
        ? <Skeleton />
        : (
          <ColWorkspaces allLabel={ rowInfo.original.roles[0] === 'admin' }>
            {(rowInfo.original.roles[0] === 'admin') ? 'All' : (rowInfo.original.workspaces ? getUserWorkspaces(rowInfo.original) : '')}
          </ColWorkspaces>
          )
    ),
    maxWidth: 330,
    resizable: false,
    sortable: false
  }, {
    Header: () => <FormattedMessage id="users.table.columns.2fa" />,
    id: 'status_2fa',
    sortable: false,
    filterable: false,
    Cell: (rowInfo) => (
      isFetching
        ? <Skeleton />
        : (
          <ColUser2faStatus status={ rowInfo.original.status_2fa }>
            {rowInfo.original.active
              ? (rowInfo.original.status_2fa === 'requested'
                  ? <FormattedMessage id="users.table.columns.inProgress" />
                  : (rowInfo.original.status_2fa === 'disabled' ? <FormattedMessage id="disabled" /> : <FormattedMessage id="active" />))
              : ''}
          </ColUser2faStatus>
          )
    ),
    maxWidth: 130,
    resizable: false
  }, {
    Header: () => <FormattedMessage id="users.table.columns.2faChange" />,
    key: 'id',
    Cell: (rowInfo) => (
      isFetching
        ? <Skeleton />
        : rowInfo.original.active
          ? <TwoFactorSwitch status={ rowInfo.original.status_2fa } id={ rowInfo.original.id } change2fa={ change2fa } />
          : ''
    ),
    maxWidth: 170,
    resizable: false,
    sortable: false,
    filterable: false
  }];

  return columns;
}
