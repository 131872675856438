import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Empty = styled.div`
  padding: 14px 20px;
  border-radius: 3px;
  box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.03);
  background-color: ${({ theme }) => theme.backgrounds.table};
  font-size: 13.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.93;
  letter-spacing: normal;
  color: ${({ theme }) => theme.text.modalTitle2};
  user-select: none;
`;
Empty.displayName = 'EmptyComments';
