import styled from 'styled-components';
import colors from 'Styles/colors';

export const NextBtn = styled.div`
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  padding: 9px 20px;
  border: none;
  border-radius: 2px;
  background-color: ${colors.blueCerulean};
`;
NextBtn.displayName = 'NextBtn';

export const PrevBtn = styled.div`
  color: ${({ theme }) => theme.buttons.custom.color};
  font-weight: 500;
  font-size: 14px;
  padding: 8px 20px;

  border: ${({ theme }) => theme.buttons.custom.border} solid 1px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.buttons.custom.background};
`;
PrevBtn.displayName = 'PrevBtn';
