import styled from 'styled-components';
import { customFieldContent } from 'Styles/styles';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

Wrapper.displayName = 'Wrapper';

export const Buttons = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 34px;
    > :nth-child(1){
        margin-right: 8px;
    }
`;

Buttons.displayName = 'Buttons';

export const Content = styled.span`
    ${customFieldContent}
`;

Content.displayName = 'Content';

export const Input = styled.input`
  position: relative;
  padding: 5px 5px 5px 15px;
  font-size: 14.5px;
  border: none;
  box-shadow: inset 0 -1px 0 0 ${({ theme }) => theme.shadows.inputInset};
  background-color: ${colors.iceBlue};
  color: ${colors.darkBlueGrey};
  width: 140px;
  height: 34px;

  &::-webkit-inner-spin-button {
    z-index: 1;
  }

 &::-webkit-clear-button {
    z-index: 1;
 }
`;
Input.displayName = 'Input';
