import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

Wrapper.displayName = 'Wrapper';

export const Tabs = styled.div`
  display: flex;
  padding-right: 13px;
  & > *:not(:last-child) {
    border-style: solid;
    border-color: ${({ theme }) => theme.borders.feedTabs};
    border-width: 1px 0px 1px 1px;
  }
  & > *:last-child {
    border-style: solid;
    border-color: ${({ theme }) => theme.borders.feedTabs};
    border-width: 1px 1px 1px 1px;
  }

`;

Tabs.displayName = 'Tabs';

export const Tab = styled.div`
  cursor: pointer;
  font-size: 14px;
  height: 42px;
  padding: 10px;
  justify-content: center;
  display: flex;
  width: 100%;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${({ active, theme }) => active ? theme.text.feedTabActive : theme.text.feedTab};
  background: ${({ active, theme }) => active ? theme.tab.feedSelected : theme.tab.feed};
`;

Tab.displayName = 'Tab';
