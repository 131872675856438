import { newGetVulns } from 'store/Manage/filterActions';
import { getData as getAssetsData } from 'store/Host/actions';
import { getData as getKBData } from 'Screens/KnowledgeBase/actions/Actions';
import { getVulnsByAsset, getServices as getServicesInAsset } from 'store/Contextualization/AssetDetail/actions';
import {
  VULNS_SEARCH_BY_FILTER_KEYS, ASSETS_SEARCH_BY_FILTER_KEYS, KB_SEARCH_BY_FILTER_KEYS,
  VULNS_ASSETS_SEARCH_BY_FILTER_KEYS, GENERAL_ASSETS_SEARCH_BY_FILTER_KEYS,
  GENERAL_VULNS_SEARCH_BY_FILTER_KEYS,
  WORKSPACES_SEARCH_BY_FILTER_KEYS,
  GENERAL_SERVICES_SEARCH_BY_FILTER_KEYS,
  SERVICES_SEARCH_BY_FILTER_KEYS,
  SERVICES_ASSET_SEARCH_BY_FILTER_KEYS,
  REPORTS_SEARCH_BY_FILTER_KEYS
} from './constants';

import { getHostsData } from 'store/HostsGeneral/actions';
import { getVulnsGeneral } from 'store/ManageGeneral/actions';
import { filterWs } from 'Screens/Contextualization/Workspaces/actions/Actions';
import { getServicesGeneral } from 'store/ServicesGeneral/actions';
import { getServices } from 'store/Services/actions';
import { getReportsByFilters } from 'store/ExecutiveReport/actions';

export const ENTITIES = {
  vulns: {
    name: {
      label: 'Vulnerabilities',
      value: 'vulns'
    },
    pathname: 'manage',
    values: VULNS_SEARCH_BY_FILTER_KEYS,
    placeholder: 'Search vulns',
    advancedPlaceholder: 'e.g. {"name":"severity","op":"==","val":"critical"}',
    documentationUrl: 'https://docs.faradaysec.com/Filters/#manage-vulns-aka-status-report-fields',
    searchFunction: newGetVulns,
    wsDependant: true
  },
  assets: {
    name: {
      label: 'Assets',
      value: 'assets'
    },
    pathname: 'host',
    values: ASSETS_SEARCH_BY_FILTER_KEYS,
    placeholder: 'Search assets',
    advancedPlaceholder: 'e.g. {"name":"os","op":"ilike","val":"%Win%"}',
    documentationUrl: 'https://docs.faradaysec.com/Filters/#manage-hosts-fields',
    searchFunction: getAssetsData,
    wsDependant: true
  },
  knowledgeBase: {
    name: {
      label: 'Vuln Templates',
      value: 'knowledgeBase'
    },
    pathname: 'knowledge_base',
    values: KB_SEARCH_BY_FILTER_KEYS,
    placeholder: 'Search vuln templates',
    advancedPlaceholder: 'e.g. {"name":"name","op":"ilike","val":"%SQL%"}',
    documentationUrl: 'https://docs.faradaysec.com/Filters/#search',
    searchFunction: getKBData,
    wsDependant: false
  },
  vulnsAssets: {
    name: {
      label: 'Vulnerabilities',
      value: 'vulnsAssets'
    },
    pathname: 'vulns',
    values: VULNS_ASSETS_SEARCH_BY_FILTER_KEYS,
    placeholder: 'Search vulns',
    advancedPlaceholder: 'e.g. {"name":"severity","op":"==","val":"critical"}',
    documentationUrl: 'https://docs.faradaysec.com/Filters/#manage-vulns-aka-status-report-fields',
    searchFunction: getVulnsByAsset,
    wsDependant: true

  },
  assetsGeneral: {
    name: {
      label: 'Assets',
      value: 'assetsGeneral'
    },
    pathname: 'hosts',
    values: GENERAL_ASSETS_SEARCH_BY_FILTER_KEYS,
    placeholder: 'Search assets',
    advancedPlaceholder: 'e.g. {"name":"os","op":"ilike","val":"%Win%"}',
    documentationUrl: 'https://docs.faradaysec.com/Filters/#manage-hosts-fields',
    searchFunction: getHostsData,
    wsDependant: false
  },
  vulnsGeneral: {
    name: {
      label: 'Vulnerabilities',
      value: 'vulnsGeneral'
    },
    pathname: 'vulnerabilities',
    values: GENERAL_VULNS_SEARCH_BY_FILTER_KEYS,
    placeholder: 'Search vulns',
    advancedPlaceholder: 'e.g. {"name":"severity","op":"==","val":"critical"}',
    documentationUrl: 'https://docs.faradaysec.com/Filters/#manage-vulns-aka-status-report-fields',
    searchFunction: getVulnsGeneral,
    wsDependant: false
  },
  workspaces: {
    name: {
      label: 'Workspaces',
      value: 'workspaces'
    },
    pathname: 'workspaces',
    values: WORKSPACES_SEARCH_BY_FILTER_KEYS,
    placeholder: 'Search workspaces',
    advancedPlaceholder: 'e.g. {"name":"public","op":"==","val":"true"}',
    documentationUrl: 'https://docs.faradaysec.com/Filters/#manage-vulns-aka-status-report-fields',
    searchFunction: filterWs,
    wsDependant: false
  },
  servicesGeneral: {
    name: {
      label: 'Services',
      value: 'servicesGeneral'
    },
    pathname: 'services',
    values: GENERAL_SERVICES_SEARCH_BY_FILTER_KEYS,
    placeholder: 'Search services',
    advancedPlaceholder: 'e.g. {"name":"status","op":"==","val":"open"}',
    documentationUrl: 'https://docs.faradaysec.com/Filters/#manage-vulns-aka-status-report-fields',
    searchFunction: getServicesGeneral,
    wsDependant: false
  },
  services: {
    name: {
      label: 'Services',
      value: 'services'
    },
    pathname: 'service',
    values: SERVICES_SEARCH_BY_FILTER_KEYS,
    placeholder: 'Search services',
    advancedPlaceholder: 'e.g. {"name":"status","op":"==","val":"open"}',
    documentationUrl: 'https://docs.faradaysec.com/Filters/#manage-vulns-aka-status-report-fields',
    searchFunction: getServices,
    wsDependant: true
  },
  servicesAssets: {
    name: {
      label: 'Services',
      value: 'servicesAssets'
    },
    pathname: 'services',
    values: SERVICES_ASSET_SEARCH_BY_FILTER_KEYS,
    placeholder: 'Search services',
    advancedPlaceholder: 'e.g. {"name":"status","op":"==","val":"open"}',
    documentationUrl: 'https://docs.faradaysec.com/Filters/#manage-vulns-aka-status-report-fields',
    searchFunction: getServicesInAsset,
    wsDependant: true
  },
  reports: {
    name: {
      label: 'Reports',
      value: 'reports'
    },
    pathname: 'report',
    values: REPORTS_SEARCH_BY_FILTER_KEYS,
    placeholder: 'Search reports',
    advancedPlaceholder: 'e.g. {"name":"status","op":"==","val":"open"}',
    documentationUrl: 'https://docs.faradaysec.com/Filters/#manage-vulns-aka-status-report-fields',
    searchFunction: getReportsByFilters,
    wsDependant: true
  }
};

export const SEARCH_BY_ENTITIES = [
  ENTITIES.vulns,
  ENTITIES.assets,
  ENTITIES.knowledgeBase,
  ENTITIES.vulnsAssets,
  ENTITIES.assetsGeneral,
  ENTITIES.vulnsGeneral,
  ENTITIES.workspaces,
  ENTITIES.servicesGeneral,
  ENTITIES.services,
  ENTITIES.reports
];
