import styled from 'styled-components';
import DatePicker from 'react-datepicker';

const Input = styled(DatePicker)`
  min-width: 150px;
  flex: 1 1 0;
  border: solid 0px rgba(255, 255, 255, 0);
  background-color: rgba(255, 255, 255, 0);

  font-size: 13px;
  letter-spacing: 0.1px;
  color: ${({ theme }) => theme.text.dateInput};
  
  :-ms-input-placeholder {
    color: rgb(222,229,236);
  }
  ::-webkit-input-placeholder {
    color: rgb(222,229,236);
  }
  ::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
`;

export default Input;
