import styled from 'styled-components';
import { Title } from 'Screens/Contextualization/Settings/common-styled';
import StringField from 'Common/Components/StringField';
import Checkbox from 'Common/Components/Checkbox/styled';

export const Wrapper = styled.div`
  display: flex;
  margin-left: 90px;
`;
Wrapper.displayName = 'Wrapper';

export const OAuthKey = styled(StringField).attrs(({
  type: 'text',
  width: '100%'
}))`
  margin-top: 16px;
  margin-bottom: 16px;
  max-width: 48%;
  width: 100%;
  ${Title} {
    margin-left: 0;
    font-weight: 400;
    color: #afb1bc;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-right: 0px;
  }
`;
OAuthKey.displayName = 'OAuthKey';

export const GreenCheckbox = styled(Checkbox)`
  margin-left: 10px; 
`;
