import get from 'lodash/get';

const parseId = (id) => {
  if (id === '_id') return 'id';
  if (id === 'service__name') return 'service';
  if (id === 'create_date') return 'created';
  return id;
};

// Saves field width when user resize column
export const handleResizedChange = (changes, fields, pSetFieldWidth) => {
  changes.forEach((change) => {
    const id = parseId(change.id);
    const field = get(fields, id, null);
    const newValue = get(change, 'value', 0);
    const defaultMin = get(field, 'defaultMin', 0);
    const roundedValue = Math.abs(Math.round(newValue));

    if (!field) return;
    if (roundedValue >= defaultMin) pSetFieldWidth(id, roundedValue);
    else pSetFieldWidth(id, defaultMin);
  });
  return false;
};

// Set styles and events for rows
export const getTrProps = (rowInfo, vulnsSelected, selectVuln, unSelectAll, isFetching, showContextMenuAction, themeStyles) => {
  const selected = vulnsSelected.find((vuln) => vuln._id === rowInfo.original._id);
  if (rowInfo && rowInfo.row) {
    return {
      onContextMenu: (e) => {
        e.preventDefault();
        showContextMenuAction(true, e.pageX, e.pageY);
        if (!selected && !isFetching) {
          unSelectAll();
          selectVuln(rowInfo.original);
        }
      },
      style: {
        background: selected ? `${themeStyles?.backgrounds?.selectedTableItem}` : ''
      },
      className: selected ? 'selected' : 'test-table-selector'
    };
  }
  return {};
};

// Set width and styles for table header
export const getThProps = (column, fields, currentBreakpoint, showVulnDetail, showContextMenuAction, isDisplayingContextMenu) => {
  const extendFieldName = (showVulnDetail && currentBreakpoint === 'second');
  const defaultMin = fields[column.id] ? fields[column.id].defaultMin : 0;
  const response = { minWidth: defaultMin };
  const nameMaxWidth = fields.name ? fields.name.maxWidth : '';

  if (column.id === 'name') response.maxWidth = extendFieldName ? '' : `max-wd-${nameMaxWidth}`;

  return {
    style: defaultMin ? response : {},
    className: column.id === 'expander' ? `display-none ${extendFieldName ? '' : `max-wd-${nameMaxWidth}`}` : `${extendFieldName ? '' : `max-wd-${nameMaxWidth}`}`,
    onContextMenu: (e) => {
      e.preventDefault();
      if (isDisplayingContextMenu) showContextMenuAction(false);
    }
  };
};
