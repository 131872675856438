import styled from 'styled-components';

const Title = styled.div`
  font-size: 21px;
  font-weight: 600;
  color: ${({ theme }) => theme.textfield.text};
  user-select: none;
  margin-right: auto;
`;
export default Title;
