import api from 'services/api';
import * as types from './types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import { selectContextMenuXPos, selectContextMenuYPos, selectHostDetail, selectHostGeneralCount, selectHostList, selectHostsSelected, selectLastSelected, selectModalBulkUpdateField, selectModalBulkUpdateValue, selectSelectAllHosts } from './selectors';
import { selectAdvancedExpandedQueryParam, selectAdvancedFilterQueryParam, selectExpandedQueryParam, selectGroupByField, selectIsGrouping, selectQueryParam, selectRowsPerPage } from 'store/Filters/selectors';
import { clearGroupBy, expandGroupBy, setFilterError, setGroupBy, setOrderBy, setPageNumber } from 'store/Filters/actions';
import { redirect } from 'store/Router/actions';
import { hideHostModalDelete } from 'store/Host/actions';
import { HOST_CREATE_UPDATE_SUCCESS } from 'store/HostEditCreate/types';
import { showHostDetail } from 'store/HostDetail/actions';
import { selectHostDetailId } from 'store/HostDetail/selectors';
import { getWorkspaces } from 'Screens/Workspaces/actions/Actions';
import { closeModal, openModal } from 'store/modals/actions';
import { MODAL_MANAGE_BULK_UPDATE, MODAL_MANAGE_BULK_UPDATE_CONFIRMATION } from 'store/modals/modals';
import { getGeneralHostsTags, getGeneralServicesTags, getGeneralTags } from 'Screens/Contextualization/Tags/actions/Actions';

export const resetState = () => (dispatch) => dispatch({ type: types.GENERAL_HOSTS_RESET_STATE });

export const getHostsData = () => async (dispatch, getState) => {
  dispatch({ type: types.GET_HOSTS_DATA_START });
  const state = getState();
  let advancedFilterQueryParam = [];
  try {
    advancedFilterQueryParam = selectAdvancedFilterQueryParam(state, 'assetsGeneral');
  } catch (e) {
    dispatch(setFilterError('assetsGeneral', 'Syntax error. Please try again. For further help check our documentation'));
  }
  const hasAdvancedFilter = advancedFilterQueryParam.filters.length > 0;
  const standardQueryParam = selectQueryParam('assetsGeneral', state);
  const queryParam = hasAdvancedFilter ? advancedFilterQueryParam : standardQueryParam;

  try {
    const response = await api.hostsGeneral.fetchHosts(queryParam);
    const data = response.rows.map((item) => item.value);
    const hostCount = response.count;
    dispatch({ type: types.GET_HOSTS_DATA_SUCCESS, data, hostCount });
  } catch (error) {
    dispatch({ type: types.GET_HOSTS_DATA_FAILURE, errorMessage: error.message || 'Error fetching hosts data' });
  }
};

export const setOrderByAssetsGeneral = (sorting) => (dispatch) => {
  dispatch(setOrderBy('assetsGeneral', sorting));
  dispatch(getHostsData());
};

export const setPageNumberAssetsGeneral = (pageNumber) => (dispatch, getState) => {
  const state = getState();
  const isGrouping = selectIsGrouping('assetsGeneral', state);

  dispatch(setPageNumber('assetsGeneral', pageNumber));
  if (!isGrouping) dispatch(getHostsData());
};

const addDeleteController = (hosts, hostList) => {
  const allHostsAreSelected = hosts.every((testHost) => hostList.some((host) => host._id === testHost._id));

  if (allHostsAreSelected) return [types.GENERAL_HOSTS_UNSELECTED, hosts];
  return [types.GENERAL_HOSTS_SELECTED, hosts];
};

const selectCalculator = (e, host, hostList, areHostSelected) => (dispatch, getState) => {
  const pivot = selectLastSelected(getState());
  const index = hostList.findIndex((el) => el._id === host._id);
  const hostsSelected = selectHostsSelected(getState());

  dispatch({ type: types.GENERAL_HOSTS_NEW_PIVOT, payload: index });
  if (e.shiftKey && pivot !== -1 && areHostSelected) {
    const start = Math.min(pivot, index);
    const end = Math.max(pivot, index) + 1;
    const hosts = hostList.slice(start, end);
    const [type, payload] = addDeleteController(hosts, hostsSelected);
    return dispatch({ type, payload });
  }
  const [type, payload] = addDeleteController([host], hostsSelected);
  return dispatch({ type, payload });
};

export const selectHostRow = (e, host) => (dispatch, getState) => {
  const allHost = selectHostList(getState());
  const isGroupingBy = selectIsGrouping('assetsGeneral', getState());
  const hostSelected = selectHostsSelected(getState());

  if (isGroupingBy) {
    const group = allHost.find((g) => g.groupData && g.groupData.find((el) => el._id === host._id));
    if (!group) return; // si no hay groupData no se puede hacer calculos con las hosts
    const hostGroup = group.groupData;
    const areHostSelected = hostSelected.length > 0 && hostGroup.some((el) => el.id === host.id);
    dispatch(selectCalculator(e, host, hostGroup, areHostSelected, hostSelected));
  } else {
    dispatch(selectCalculator(e, host, allHost, hostSelected.length > 0));
  }
};

export const unSelectAllHosts = () => (dispatch) => dispatch({ type: types.GENERAL_HOSTS_UNSELECT_ALL_HOST });

export const selectAllHost = () => (dispatch, getState) => {
  const hostsList = selectHostList(getState());
  const hostsSelected = selectHostsSelected(getState());
  const hostCount = selectHostGeneralCount(getState());
  const pageSize = selectRowsPerPage('assetsGeneral', getState());

  if (hostsSelected.length === hostCount || hostsSelected.length >= pageSize) return dispatch(unSelectAllHosts());

  return dispatch({ type: types.GENERAL_HOSTS_SELECT_ALL_HOST, hostsList });
};

export const selectTotalHosts = () => (dispatch, getState) => {
  return dispatch({ type: types.GENERAL_HOSTS_SELECT_TOTAL_ASSETS });
};

export const unselectTotalHosts = () => (dispatch, getState) => {
  return dispatch({ type: types.GENERAL_HOSTS_UNSELECT_TOTAL_ASSETS });
};

export const getExpandedGroupByData = (index) => async (dispatch, getState) => {
  const state = getState();
  dispatch({ type: types.GET_HOSTS_DATA_START });

  let advancedFilterQueryParam = [];
  try {
    advancedFilterQueryParam = selectAdvancedExpandedQueryParam(state, 'assetsGeneral');
  } catch (e) {
    dispatch(setFilterError('assetsGeneral', 'Syntax error. Please try again. For further help check our documentation'));
  }
  const hasAdvancedFilter = advancedFilterQueryParam.filters.length > 0;
  const standardQueryParam = selectExpandedQueryParam('assetsGeneral', state);
  const queryParam = hasAdvancedFilter ? advancedFilterQueryParam : standardQueryParam;

  try {
    const response = await api.hostsGeneral.fetchHosts(queryParam);
    const data = response && response.rows ? response.rows.map((data) => data.value) : [];
    return dispatch({ type: types.GET_HOSTS_DATA_GROUP_BY_EXPAND, index, data });
  } catch (e) {
    const errorMessage = get(e, 'message', 'Failed to obtain assets.');
    if (errorMessage.includes('filter')) {
      dispatch(setFilterError('assets', 'Invalid filters. Please try again. For further help check our documentation'));
    }
    dispatch({ type: types.GET_HOSTS_DATA_FAILURE, errorMessage });
  }
};

export const clearExpandedGroupByData = () => async (dispatch) => dispatch({ type: types.GET_HOSTS_DATA_GROUP_BY_RESET });

export const clearGroupByAssetsGeneral = () => async (dispatch) => await dispatch(clearGroupBy('assetsGeneral'));

export const setGroupByAssetsGeneral = (group_by) => async (dispatch) => {
  const field = get(group_by, '[0].field', '');
  await dispatch(setGroupBy('assetsGeneral', group_by));
  if (field) await dispatch(setOrderBy('assetsGeneral', [{ id: field, desc: false }]));
  dispatch(getHostsData());
};

export const expandGroupByAssetsGeneral = (index, value) => async (dispatch) => {
  await dispatch(expandGroupBy('assetsGeneral', value));
  dispatch(getExpandedGroupByData(index));
};

export const redirectToHostDetailGeneral = (id) => (dispatch) => {
  dispatch(redirect(`/hosts/${id}`));
};

export const redirectToHostsGeneral = () => (dispatch) => {
  dispatch(redirect('/hosts'));
};

export const loadHostDetail = (id) => async (dispatch) => {
  if (id > 0) {
    try {
      const host = await api.hostsGeneral.fetchById(id);
      dispatch(showHostDetail(host));
    } catch (e) {
      dispatch(redirectToHostsGeneral());
    }
  }
};

export const updateHostGeneral = (hostId, valueObj, workspace) => async (dispatch, getState) => {
  const hostList = selectHostList(getState());
  const hostCount = selectHostGeneralCount(getState());
  const hostDetailId = selectHostDetailId(getState());
  const newHost = await api.host.updateHost(workspace, { id: hostId, ...valueObj });
  const index = hostList.findIndex((x) => hostId === x.id || hostId === x._id);
  hostList[index] = { ...newHost, severity_counts: hostList[index].severity_counts };
  dispatch({ type: types.GET_HOSTS_DATA_SUCCESS, data: hostList, hostCount });
  if (hostDetailId && newHost._id === hostDetailId) dispatch(showHostDetail(newHost));
};

export const createAssetOutsideWs = (host) => async (dispatch) => {
  const { ip, os, mac, description, owned, hostnames, default_gateway, workspaces } = host;

  try {
    const promises = workspaces.map((workspace) => {
      const newHost = { ip, os, mac, description, owned, hostnames, default_gateway };
      return api.host.createHost(workspace.name, newHost);
    });
    const hosts = await Promise.all(promises);

    dispatch({ type: types.GENERAL_HOSTS_CREATE_SUCCESS, hosts });
    dispatch({ type: HOST_CREATE_UPDATE_SUCCESS });
    dispatch(getWorkspaces(false));
  } catch (e) {
    dispatch({ type: types.GENERAL_HOSTS_CREATE_FAIL, error: e.message });
  }
};

export const deleteAssetsGeneral = () => async (dispatch, getState) => {
  dispatch({ type: types.GENERAL_HOSTS_DELETE_HOSTS_START });
  const hostselected = selectHostsSelected(getState());
  const hostIDs = hostselected.map(host => host._id);
  const groupByField = selectGroupByField('assetsGeneral', getState());
  const hostsList = selectHostList(getState());
  const selectAll = selectSelectAllHosts(getState());

  const advancedFilterQueryParam = selectAdvancedFilterQueryParam(getState(), 'assetsGeneral');
  const hasAdvancedFilter = advancedFilterQueryParam.filters.length > 0;
  const standardQueryParam = selectQueryParam('vulnsGeneral', getState());
  const queryParam = hasAdvancedFilter ? advancedFilterQueryParam : standardQueryParam;

  dispatch(hideHostModalDelete());

  try {
    if (selectAll) {
      await api.hostsGeneral.deleteAllHosts({ filters: queryParam.filters });
    } else {
      await api.hostsGeneral.deleteHost(hostIDs);
    }
    if (groupByField) {
      const newHostsList = hostsList.map((group) => {
        const groupedData = get(group, 'groupData', null);
        if (groupedData) {
          const newGroupedData = groupedData.filter((host) => !hostIDs.includes(host._id));
          return {
            ...group,
            count: newGroupedData.length,
            groupData: newGroupedData
          };
        }
        return group;
      });
      dispatch({ type: types.GENERAL_HOSTS_UPDATE_HOSTS_LIST, hostsList: newHostsList });
    } else dispatch(getHostsData());
    dispatch(unSelectAllHosts());

    // dispatch({ type: types.GENERAL_HOSTS_DELETE_HOSTS_SUCCESS, response });
    // dispatch(getWorkspaces(false));
    // return dispatch(getHostsData());
  } catch (e) {
    dispatch({ type: types.GENERAL_HOSTS_DELETE_HOSTS_FAIL, errorMessage: e.message || 'An error occured while deleting assets' });
  }
};

export const showAssetsRightFilters = (visible) => (dispatch) => dispatch({ type: types.GENERAL_HOSTS_SHOW_FILTERS, visible });

export const clearError = () => async (dispatch) => dispatch({ type: types.GENERAL_HOSTS_CLEAR_ERROR });

export const showContextMenu = (show, XPos, YPos) => {
  return (dispatch, getState) => {
    const state = getState();
    const currentXPos = selectContextMenuXPos(state);
    const currentYPos = selectContextMenuYPos(state);
    const newXPos = XPos ? (XPos + 1) : currentXPos;
    const newYPos = YPos ? (YPos + 1) : currentYPos;

    dispatch({
      type: types.GENERAL_HOSTS_SHOW_CONTEXT_MENU, show, contextMenuXPos: newXPos, contextMenuYPos: newYPos
    });
  };
};

export const setBulkUpdateField = (field) => (dispatch) => dispatch({ type: types.GENERAL_HOSTS_SET_BULK_UPDATE_FIELD, field });

export const setBulkUpdateValue = (value) => (dispatch) => dispatch({ type: types.GENERAL_HOSTS_SET_BULK_UPDATE_VALUE, value });

export const addBulkUpdateValue = (value) => (dispatch) => dispatch({ type: types.GENERAL_HOSTS_ADD_BULK_UPDATE_VALUE, value });

export const removeBulkUpdateValue = (value) => (dispatch) => dispatch({ type: types.GENERAL_HOSTS_REMOVE_BULK_UPDATE_VALUE, value });

export const refreshAssetsList = (assetsList, assetsSelected, assetDetail) => (dispatch, getState) => {
  dispatch({
    type: types.GENERAL_HOSTS_REFRESH_LIST, assetsList, assetsSelected, assetDetail, assetsCount: selectHostGeneralCount(getState())
  });
};

export function bulkUpdateAssets () {
  return async (dispatch, getState) => {
    const state = getState();

    dispatch({ type: types.GENERAL_HOSTS_CONFIRMATION_CHANGE_START });

    try {
      const assetsList = selectHostList(state);
      const assetsSelected = selectHostsSelected(state);
      const assetDetail = selectHostDetail(state);
      const field = selectModalBulkUpdateField(state);
      const value = selectModalBulkUpdateValue(state);
      const selectAll = selectSelectAllHosts(state);
      const assetIDs = assetsSelected.map((v) => v._id);

      const advancedFilterQueryParam = selectAdvancedFilterQueryParam(state, 'assetsGeneral');
      const hasAdvancedFilter = advancedFilterQueryParam.filters.length > 0;
      const standardQueryParam = selectQueryParam('assetsGeneral', state);
      const queryParam = hasAdvancedFilter ? advancedFilterQueryParam : standardQueryParam;

      let data = {};

      if (field === 'hostnames') data = { hostnames: value };
      else data = { [field]: value };

      const isDetailAssetSelected = !isEmpty(assetDetail) && assetsSelected.some((selectedAsset) => selectedAsset._id === assetDetail?._id);

      const updatedAssetDetail = isDetailAssetSelected ? { ...assetDetail, ...data } : { ...assetDetail };

      if (selectAll) {
        await api.hostsGeneral.updateAllHosts({ filters: queryParam.filters }, data);

        const updatedAssetsList = assetsList.map((asset) => ({ ...asset, ...data }));
        const updatedSelectedAssets = assetsSelected.map((asset) => ({ ...asset, ...data }));

        dispatch(refreshAssetsList(updatedAssetsList, updatedSelectedAssets, updatedAssetDetail));
      } else {
        const payload = { ids: assetIDs, ...data };

        await api.hostsGeneral.updateHosts(payload);

        const updatedAssetsList = assetsList.map((asset) => {
          const isSelected = assetsSelected.some((selectedAsset) => selectedAsset._id === asset._id);
          if (isSelected) return { ...asset, ...data };
          return asset;
        });

        const updatedSelectedAssets = assetsSelected.map((asset) => ({ ...asset, ...data }));

        dispatch({ type: types.GENERAL_HOSTS_SET_BULK_UPDATE_SUCCESS });
        dispatch(refreshAssetsList(updatedAssetsList, updatedSelectedAssets, updatedAssetDetail));
      }

      dispatch(closeModal(MODAL_MANAGE_BULK_UPDATE));
      dispatch(closeModal(MODAL_MANAGE_BULK_UPDATE_CONFIRMATION));
      dispatch({ type: types.GENERAL_HOSTS_BULK_UPDATE_FINISHED });
    } catch (e) {
      dispatch(closeModal(MODAL_MANAGE_BULK_UPDATE));
      dispatch(closeModal(MODAL_MANAGE_BULK_UPDATE_CONFIRMATION));
      dispatch({ type: types.GENERAL_HOSTS_ERROR, errorMessage: e.message || 'An error occured while updating vulns' });
    }
  };
}

export const showBulkUpdateModal = (bulkUpdateField, bulkUpdateValue) => {
  return (dispatch) => {
    dispatch(setBulkUpdateField(bulkUpdateField));
    dispatch(setBulkUpdateValue(bulkUpdateValue));
    dispatch(openModal(MODAL_MANAGE_BULK_UPDATE));
  };
};

export const setAssetsGeneralTags = (tagsToAdd = [], tagsToRemove = []) => async (dispatch, getState) => {
  try {
    const state = getState();
    const hostsSelected = selectHostsSelected(state);
    const hostsList = selectHostList(state);
    const selectedHostsIds = hostsSelected.map((h) => (h._id));
    const data = {
      host_ids: selectedHostsIds,
      tags_to_add: tagsToAdd,
      tags_to_remove: tagsToRemove
    };

    await api.hostsGeneral.setHostsTags(data);

    const newTags = tagsToAdd.filter((tag) => !tagsToRemove.includes(tag));

    selectedHostsIds.forEach((id) => {
      const host = find(hostsSelected, { _id: id });
      const newTagsList = newTags.filter((tag) => !host.tags.includes(tag));
      host.tags = [
        ...host.tags.filter((tag) => !tagsToRemove.includes(tag)),
        ...newTagsList
      ];

      const indexHostsList = findIndex(hostsList, { _id: host._id });
      if (indexHostsList > -1 && !isEmpty(hostsList)) hostsList.splice(indexHostsList, 1, host);

      const indexVulnsSelected = findIndex(hostsSelected, { _id: id });
      if (indexHostsList > -1 && !isEmpty(hostsSelected)) hostsSelected.splice(indexVulnsSelected, 1, host);
    });

    dispatch({ type: types.SET_ASSETS_TAGS_SUCCESS });
    dispatch(getGeneralTags());
    dispatch(getGeneralHostsTags());
    dispatch((getGeneralServicesTags()));
  } catch (e) {
    dispatch({ type: types.SET_ASSETS_TAGS_FAIL, error: e.message });
  }
};

export const addAssetTag = (tag) => {
  return (dispatch) => {
    dispatch(setAssetsGeneralTags([tag], []));
  };
};

export const removeAssetTag = (tag) => {
  return (dispatch) => {
    dispatch(setAssetsGeneralTags([], [tag]));
  };
};
