import styled from 'styled-components';

export const TabWrapper = styled.div`
  display: flex;
`;

export const Tab = styled.div`
  padding: 16px 32px;
  cursor: pointer;
  color: ${({ theme }) => theme.text.tab};
  font-size: 14px;
  font-weight: 500;
  background-color: ${({ isActive, theme }) => (isActive ? theme.tab.selected : theme.tab.item)};
`;
