import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from 'Common/Components/Checkbox';
import get from 'lodash/get';
import { selectGraphsSelected } from 'store/Analytics/selectors';
import { selectRow } from 'store/Analytics/actions';

export const CheckColumn = ({ rowInfo }) => { // eslint-disable-line import/prefer-default-export
  const graph = get(rowInfo, 'original', {});
  const graphsSelected = useSelector(selectGraphsSelected);
  const dispatch = useDispatch();
  const isSelected = graphsSelected.find((graphSelected) => graphSelected.id === graph.id);
  const handleClick = (e) => dispatch(selectRow(e, graph));

  return (
    <Checkbox theme="grey" state={ !!isSelected } onChange={ handleClick } />
  );
};
