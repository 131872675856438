/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAuthenticated, selectError, selectMessage,
  selectDefaultProject,
  selectProjectData,
  selectTemplate,
  selectTemplates
} from 'store/Settings/jiraSelectors';
import {
  getJiraSettings, sendVulnToJiraContext, getJiraCacheProjects,
  newGetJiraTemplate,
  setprojectConfig
} from 'store/Settings/jiraActions';
import { closeModal } from 'store/modals/actions';
import { MODAL_NEW_JIRA_TEMPLATE, MODAL_SEND_TO_JIRA } from 'store/modals/modals';
import { useIntl } from 'react-intl';
import first from 'lodash/first';
import FlexModal from 'Common/Components/FlexModal';
import Error from 'Common/Components/Error';
import Checkbox from 'Common/Components/Checkbox';
import {
  Wrapper, Title, Subtitle, Blue,
  Header, CancelButton, ConfirmButton, CheckboxOption, Label, WrapperField, Input
} from './styled';
import ConfirmSend from './Confirm';
import { isEmpty } from 'lodash';

const SetProject = ({ vulnsSelected, vulnId, isVulnDetailOpen, entity }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [mergeVulns, setMergeVulns] = useState(false);
  const [issueName, setIssueName] = useState('');
  const dispatch = useDispatch();
  const authenticated = useSelector(selectAuthenticated);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (authenticated) setIndex(1);
  }, [authenticated]);

  const onClose = () => dispatch(closeModal(MODAL_SEND_TO_JIRA));
  const onSave = () => {
    if (authenticated) {
      dispatch(sendVulnToJiraContext(vulnsSelected, vulnId, isVulnDetailOpen, entity, {
        password,
        username,
        issue_name: issueName,
        merge_vulns: mergeVulns
      }));
    } else dispatch(sendVulnToJiraContext(vulnsSelected, vulnId, isVulnDetailOpen, entity));
    onClose();
  };

  const content = () => {
    if (index === 0) {
      return (
        <ConfirmSend
          onClose={ onClose }
          onSave={ () => setIndex(1) }
          username={ username }
          setUsername={ setUsername }
          password={ password }
          setPassword={ setPassword }
        />
      );
    }
    return (
      <SendIssue
        onBack={ () => {
          setIndex(0);
          if (authenticated) onClose();
        } }
        onSave={ onSave }
        username={ username }
        password={ password }
        mergeVulns={ mergeVulns }
        setMergeVulns={ setMergeVulns }
        issueName={ issueName }
        setIssueName={ setIssueName }
        vulnsSelected={ vulnsSelected }
      />
    );
  };

  return (
    <FlexModal z="9" height="auto">
      <Wrapper>
        {content()}
      </Wrapper>
    </FlexModal>
  );
};

export default SetProject;

const SendIssue = ({
  onBack, onSave, username, password, mergeVulns, setMergeVulns, issueName, setIssueName, vulnsSelected
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const defaultProject = useSelector(selectDefaultProject);
  const getText = () => ((vulnsSelected.length === 1) ? first(vulnsSelected).name : `${vulnsSelected.length} vulnerabilities`);
  const projectData = useSelector(selectProjectData);
  const { name = '', ticket_config = {} } = projectData;
  const template = useSelector(selectTemplate);
  const templates = useSelector(selectTemplates);

  useEffect(() => {
    dispatch(getJiraCacheProjects({ username, password }));
    dispatch(getJiraSettings());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    dispatch(newGetJiraTemplate(defaultProject));
  }, [dispatch, defaultProject]);

  useEffect(() => {
    if (!isEmpty(template) && !isEmpty(templates)) dispatch(setprojectConfig(ticket_config, name));
  }, [dispatch, template, templates]);

  useEffect(() => {
    return () => {
      dispatch(closeModal(MODAL_NEW_JIRA_TEMPLATE));
    };
  }, [dispatch, defaultProject]);

  const error = useSelector(selectError);
  const message = useSelector(selectMessage);

  const toggleMerge = () => setMergeVulns(!mergeVulns);

  return (
    <>
      <Header>
        <Title>Send to Jira </Title>
        <CancelButton onClick={ onBack }>Close</CancelButton>
        <ConfirmButton disabled={ !defaultProject } onClick={ onSave }>Send</ConfirmButton>
      </Header>
      <Subtitle>
        { intl.formatMessage({ id: 'sendToTool.jira.message.youAreSending' }) }
        <Blue>{ getText() }</Blue>
        { intl.formatMessage({ id: 'sendToTool.jira.message.continue' }) }
      </Subtitle>
      { vulnsSelected.length > 1 &&
      (
      <>
        <CheckboxOption>
          <Checkbox onChange={ toggleMerge } state={ mergeVulns } />
          <Subtitle onClick={ toggleMerge }>Merge Vulns</Subtitle>
        </CheckboxOption>
        {
        mergeVulns && (
        <WrapperField>
          <Label>Issue Name</Label>
          <Input placeholder="My Issue" value={ issueName } onChange={ (e) => setIssueName(e.target?.value) } />
        </WrapperField>
        )
      }
      </>
      )}

      {error && message ? <Error>{message}</Error> : null}
    </>
  );
};
