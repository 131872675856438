/* eslint-disable react/jsx-max-depth */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { Provider } from 'react-redux';
import { Switch } from 'react-router-dom';
import { PersistGate } from 'redux-persist/lib/integration/react';
import ReactBreakpoints from 'react-breakpoints';
import IntlProviderContainer from 'settings/IntlProvider';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { SENTRY_DSN } from 'settings/constants';
import { ConnectedRouter } from 'connected-react-router';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import App from './App';
import { persistor, store, history } from './store/Store';
import ApplicationRoutes from './Routes/Routes';
import AppRoute from './Routes/AppRoute';
import { ThemeProvider } from './Context';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import useTheme from 'Hooks/UseTheme';

const breakpoints = {
  first: 900,
  second: 1200,
  third: 1800,
  fourth: 2500
};

const { Routes } = ApplicationRoutes;

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing(
    {
      beforeNavigate: (context) => ({
        ...context,
        name: window.location.pathname
          .replace(/[a-f0-9]{32}/g, '<hash>')
          .replace(/\d+/g, '<digits>')
      })
    }
  )],
  tracesSampleRate: 1.0 // Be sure to lower this in production
});

Sentry.setTag('build_version', process.env.REACT_APP_VERSION);

// Global store reference - used for fetchApi dispatch
window.store = store;

const ThemedApp = () => {
  const { themeStyles } = useTheme();

  return (
    <StyledThemeProvider theme={ themeStyles }>
      <App>
        <Switch>
          {Routes.map((route) => (
            <AppRoute
              key={ route.path || 'default' }
              exact={ route.exact }
              path={ route.path }
              component={ route.component }
              isPrivate={ route.isPrivate }
              requireWorkspace={ route.requireWorkspace }
              edit={ route.edit }
              isNew={ route.isNew }
            />
          ))}
        </Switch>
      </App>
    </StyledThemeProvider>
  );
};

ReactDOM.render(
  <Provider store={ store }>
    <IntlProviderContainer>
      <PersistGate persistor={ persistor }>
        <ConnectedRouter history={ history }>
          <ReactBreakpoints breakpoints={ breakpoints }>
            <DndProvider backend={ HTML5Backend }>
              <ThemeProvider>
                <ThemedApp />
              </ThemeProvider>
            </DndProvider>
          </ReactBreakpoints>
        </ConnectedRouter>
      </PersistGate>
    </IntlProviderContainer>
  </Provider>, document.getElementById('root')
);
