import { newGetVulns } from 'store/Manage/filterActions';
import api from 'services/api';
import { selectCurrentUserId } from 'store/Faraday/selectors';

// **
// settings section
// **
export const SET_SETTINGS_SHOW_MODAL = 'SET_SETTINGS_SHOW_MODAL';

export const GET_SETTINGS_TEMPLATES_START = 'GET_SETTINGS_TEMPLATES_START';
export const GET_SETTINGS_TEMPLATES_SUCCESS = 'GET_SETTINGS_TEMPLATES_SUCCESS';
export const GET_SETTINGS_TEMPLATES_FAIL = 'GET_SETTINGS_TEMPLATES_FAIL';

export const SEND_VULN_TO_TOOL = 'SEND_VULN_TO_TOOL';
export const GET_USER_DATA_SUCCESS = 'GET_USER_DATA_SUCCESS';
export const GET_USER_DATA_FAIL = 'GET_USER_DATA_FAIL';

export const GET_ACTIVE_CONFIG_REQUEST = 'GET_ACTIVE_CONFIG_REQUEST';
export const GET_ACTIVE_CONFIG_SUCCESS = 'GET_ACTIVE_CONFIG_SUCCESS';
export const GET_ACTIVE_CONFIG_FAILURE = 'GET_ACTIVE_CONFIG_FAILURE';

export const UPDATE_ACTIVE_CONFIG_REQUEST = 'UPDATE_ACTIVE_CONFIG_REQUEST';
export const UPDATE_ACTIVE_CONFIG_SUCCESS = 'UPDATE_ACTIVE_CONFIG_SUCCESS';
export const UPDATE_ACTIVE_CONFIG_FAILURE = 'UPDATE_ACTIVE_CONFIG_FAILURE';

export const SET_USER_EMAIL = 'SET_USER_EMAIL';
// **
// settings section
// **

export function closeModal () {
  return async (dispatch) => {
    dispatch({ type: SET_SETTINGS_SHOW_MODAL, showModal: false });
  };
}

export function openModal () {
  return async (dispatch) => {
    dispatch({ type: SET_SETTINGS_SHOW_MODAL, showModal: true });
  };
}

export function getTemplates (tool) {
  return async (dispatch) => {
    try {
      const response = await api.settings.fetchTemplates(tool);
      return dispatch({ type: GET_SETTINGS_TEMPLATES_SUCCESS, templates: response.templates });
    } catch (e) {
      return dispatch({ type: GET_SETTINGS_TEMPLATES_FAIL, error: true, message: 'There was an error, please try again.' });
    }
  };
}

export function updateVulns (vulns, trackerKey) {
  return async (dispatch, getState) => {
    const { workspaceSelected } = getState().faraday;
    const results = [];

    Object.keys(vulns).forEach((key) => {
      const issuetracker = {};
      issuetracker[trackerKey] = {
        id: vulns[key].id,
        key: vulns[key].key,
        self: vulns[key].self,
        url: vulns[key].self ? vulns[key].self : vulns[key].url,
        vuln_id: vulns[key].vuln_id
      };
      const vulnData = {
        issuetracker
      };
      results.push(api.settings.updateVuln(vulns[key].vuln_id, workspaceSelected, vulnData));
    });
    await Promise.all(results);
    dispatch(newGetVulns());
  };
}

export function resetWarning () {
  return async (dispatch) => {
    // hago reset del error.
    dispatch({ type: SEND_VULN_TO_TOOL, payload: { error: false, message: '' } });
  };
}

export const getActiveConfigRequest = () => ({
  type: GET_ACTIVE_CONFIG_REQUEST
});

export const getActiveConfigSuccess = (data) => ({
  type: GET_ACTIVE_CONFIG_SUCCESS,
  payload: data
});

export const getActiveConfigFailure = (error) => ({
  type: GET_ACTIVE_CONFIG_FAILURE,
  payload: error
});

export const fetchActiveConfig = () => async (dispatch, getState) => {
  dispatch(getActiveConfigRequest());
  try {
    const { integrations } = await api.settings.getActiveConfig();
    dispatch(getActiveConfigSuccess(integrations));
  } catch (error) {
    dispatch(getActiveConfigFailure(error.message));
  }
};

export const updateActiveConfigRequest = () => ({
  type: UPDATE_ACTIVE_CONFIG_REQUEST
});

export const updateActiveConfigSuccess = (data) => ({
  type: UPDATE_ACTIVE_CONFIG_SUCCESS,
  payload: data
});

export const updateActiveConfigFailure = (error, tool) => ({
  type: UPDATE_ACTIVE_CONFIG_FAILURE,
  payload: error,
  tool
});

export const updateActiveConfig = (tool) => async (dispatch) => {
  dispatch(updateActiveConfigRequest());
  try {
    const response = await api.settings.updateActiveConfig(tool);

    dispatch(updateActiveConfigSuccess(response));
  } catch (error) {
    dispatch(updateActiveConfigFailure(error.message));
  }
};
export function getUserData () {
  return async (dispatch, getState) => {
    const userId = selectCurrentUserId(getState());
    try {
      const userData = await api.users.getUserById(userId);
      return dispatch({ type: GET_USER_DATA_SUCCESS, userData });
    } catch (e) {
      return dispatch({ type: GET_USER_DATA_FAIL, error: e.message || 'An error occured while fetching user data.' });
    }
  };
}

export function setEmailField (value) {
  return (dispatch) => {
    dispatch({ type: SET_USER_EMAIL, value });
  };
}

export const updateActiveConfigNew = (tool) => async (dispatch) => {
  dispatch(updateActiveConfigRequest());
  try {
    let parsedTool = tool;
    if (tool === 'serviceDesk') parsedTool = 'mesd';
    const response = await api.settings.updateActiveConfig(parsedTool);
    dispatch(updateActiveConfigSuccess(response));
    dispatch(fetchActiveConfig());
  } catch (error) {
    dispatch(updateActiveConfigFailure(error.message, tool));
  }
};
