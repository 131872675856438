import styled from 'styled-components';
import { Accept } from 'Common/Components/Button/styled';

// eslint-disable-next-line import/prefer-default-export
export const SaveButton = styled(Accept)`
  width: 89px;
  height: 34px;
  margin-left: 12px;
`;
SaveButton.displayName = 'SaveButton';
