import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as TrashSVG } from 'Images/icon-action-bar-trash.svg';
import { ReactComponent as CopySVG } from 'Images/copy.svg';
import { ReactComponent as EditSVG } from 'Images/icon-action-bar-edit.svg';

export const Wrapper = styled.div`
  text-align: right;
  margin: 0px;
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  height: 53px;
  padding: 8px 0;
`;
Wrapper.displayName = 'Wrapper';

export const TitleWrapper = styled.div`
  color: ${colors.dark2};
  display: flex;
  align-items: center;
  & > * {
    margin-bottom: 0;
  }
`;
TitleWrapper.displayName = 'TitleWrapper';

export const Title = styled.h2`
  font-size: 17px;
  font-weight: 500;
  margin-right: 5px;
`;
Title.displayName = 'Title';

export const Subtitle = styled.h3`
  font-size: 13.5px;
  font-weight: bold;
  margin-top: 4px;
`;
Subtitle.displayName = 'Subtitle';

export const EditIcon = styled(EditSVG)`
  width: 16px;
  * > path {
    fill: ${({ theme }) => theme.icons.actionBar.color};
  }
`;
EditIcon.displayName = 'EditIcon';

export const TrashIcon = styled(TrashSVG)`
  width: 16px;
  & > g, & > g > path {
    fill: ${colors.red1} !important;
  }
`;
TrashIcon.displayName = 'TrashIcon';

export const DuplicateIcon = styled(CopySVG)`
  width: 16px;
  path {
    fill: ${({ theme }) => theme.icons.actionBar.color};
  }
  `;
DuplicateIcon.displayName = 'DuplicateIcon';
