import styled from 'styled-components';
import { ReactComponent as EvidenceSVG } from 'Images/icon-evidence.svg';

const EvidenceIcon = styled(EvidenceSVG)`
  path {
    fill: ${({ theme }) => theme.icons.actionBar.color};
    stroke: ${({ theme }) => theme.icons.actionBar.color};
  }
`;
EvidenceIcon.displayName = 'EvidenceIcon';

export default EvidenceIcon;
