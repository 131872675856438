/* eslint-disable no-negated-condition */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MarkdownIt from 'markdown-it';
import MdEditor from 'react-markdown-editor-lite';
import './styles.scss';
import {
  Wrapper,
  PreviewWrapper,
  EmptyWrapper,
  DataContainer,
  EditorContainer,
  ButtonsWrapper,
  GlobalStyle,
  CancelButton,
  RedAster,
  SaveButton,
  StyledReactMarkdown
} from './styled';

class Md extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      markdown: '',
      editable: false,
      temp: ''
    };

    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.toogleShowPreview = this.toogleShowPreview.bind(this);
    this.cancelEdition = this.cancelEdition.bind(this);
    this.calculateHeight = this.calculateHeight.bind(this);
  }

  componentDidMount () {
    this.setState({ markdown: this.props.value }, () => {
      if (this.props.hidePlaceholder && this.state.markdown.length === 0) {
        this.toggleEditable();
      }
    });
  }

  componentDidUpdate (prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ markdown: this.props.value });
    }
  }

  handleEditorChange (it) {
    this.setState({
      markdown: it.text
    });
  }

  toogleShowPreview () {
    this.setState((prevState) => ({ showPreview: !prevState.showPreview }));
  }

  toggleEditable () {
    if (!this.state.editable) {
      this.setState((prevState) => ({ temp: prevState.markdown }));
    }

    this.setState((prevState) => ({ editable: !prevState.editable }));
  }

  cancelEdition () {
    this.setState((prevState) => ({ markdown: prevState.temp }));
    if (this.props.onCancel) {
      this.props.onCancel();
    }
    this.toggleEditable();
  }

  saveMarkdown () {
    this.props.saveAction(this.state.markdown);
    this.toggleEditable();
    this.setState({ temp: '' });
  }

  calculateHeight () {
    const { size } = this.props;
    switch (size) {
      case 'md':
        return '232px';
      case 'sm':
        return '180px';
      case 'lg':
        return '225px';
      default:
        return '328px';
    }
  }

  currentTheme () {
    const themeClass = localStorage.getItem('theme');
    return themeClass;
  }

  render () {
    const {
      placeholder,
      size,
      title,
      isRequired,
      hidePlaceholder,
      width
    } = this.props;
    const { markdown, editable } = this.state;
    const mdParser = new MarkdownIt(/* Markdown-it options */);
    const height = this.calculateHeight(size);
    const PLUGINS = [
      'header',
      'font-bold',
      'font-italic',
      'block-code-inline',
      'block-code-block',
      'block-quote',
      'table',
      'link'
    ];
    const theme = this.currentTheme();
    return (
      <Wrapper width={ width } height={ height } className={ size }>
        <GlobalStyle />
        <div className="input-list-title">
          {title}
          { isRequired && <RedAster>*</RedAster> }
        </div>
        {
          !editable
            ? (
              <DataContainer>
                {
                    (markdown === '' && !hidePlaceholder)
                      ? (
                        <EmptyWrapper onClick={ () => { this.toggleEditable(); } } width={ width } height={ height }>
                          <p>{ placeholder }</p>
                        </EmptyWrapper>
                        )
                      : (
                        <PreviewWrapper onClick={ () => { this.toggleEditable(); } } width={ width } height={ height }>
                          <StyledReactMarkdown>
                            { this.state.markdown }
                          </StyledReactMarkdown>
                        </PreviewWrapper>
                        )
                }
              </DataContainer>
              )
            : (
              <EditorContainer theme={ theme }>
                <MdEditor
                  value={ markdown }
                  style={ {
                    height,
                    flexDirection: 'column-reverse',
                    paddingBottom: '0px',
                    backgroundColor: `${theme === 'light' ? 'white' : '#20282e'}`
                  } }
                  renderHTML={ (text) => mdParser.render(text) }
                  plugins={ PLUGINS }
                  config={ {
                    view: {
                      menu: true,
                      md: true,
                      html: false,
                      fullScreen: false,
                      hideMenu: false
                    },
                    canView: {
                      menu: true,
                      md: true,
                      html: false,
                      fullScreen: true,
                      hideMenu: false
                    }
                  } }
                  onChange={ this.handleEditorChange }
                />
                <ButtonsWrapper>
                  <CancelButton onClick={ () => { this.cancelEdition(); } } label="Cancel" />
                  <SaveButton onClick={ () => { this.saveMarkdown(); } } label="Save" />
                </ButtonsWrapper>
              </EditorContainer>
              )
        }
      </Wrapper>
    );
  }
}

Md.propTypes = {
  value: PropTypes.string,
  saveAction: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(['md', 'lg', 'sm']),
  title: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  hidePlaceholder: PropTypes.bool,
  width: PropTypes.string,
  onCancel: PropTypes.func
};

Md.defaultProps = {
  value: '',
  placeholder: 'This field is empty. Click to edit.',
  size: 'md',
  isRequired: false,
  hidePlaceholder: false,
  width: '405px',
  onCancel: null
};

const mapDispatchToProps = () => ({

});

const mapStateToProps = () => ({

});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Md));
