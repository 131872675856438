/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const SwaggerContainer = styled.div`
  .swagger-ui {
    background-color: ${({ theme }) => theme.backgrounds.swagger};
    color: ${({ theme }) => theme.textfield.text};
  }

  .swagger-ui .topbar {
    color: ${({ theme }) => theme.topbarText};
  }

  .swagger-ui .info {
    color: ${({ theme }) => theme.textfield.text};
  }

   .swagger-ui {
    color: ${({ theme }) => theme.text};
  }

  .swagger-ui .info h1 {
    color: ${({ theme }) => theme.textfield.text};
  }

  .swagger-ui .info a {
        color: ${({ theme }) => theme.text.link};
  }

  .swagger-ui .info p {
    color: ${({ theme }) => theme.textfield.text};
  }

  .swagger-ui .opblock-tag {
    color: ${({ theme }) => theme.textfield.text};
    background-color: ${({ theme }) => theme.sectionHeaderBackground};
  }

  .swagger-ui .opblock-summary-method {
    color: ${({ theme }) => theme.textfield.text};
    background-color: ${({ theme }) => theme.methodBackground};
  }

  .swagger-ui .authorize-btn {
    color: ${({ theme }) => theme.textfield.text};
  }
  
  .swagger-ui .scheme-container{
    background-color: transparent;
    border: ${({ theme }) => `1px solid ${theme.borders.vulnsCounter}`};
  }

  .swagger-ui a {
    color: ${({ theme }) => theme.textfield.text};
  }

  .swagger-ui .opblock-description-wrapper p {
    color: ${({ theme }) => theme.textfield.text};
  }
  
  .swagger-ui .responses-inner {
    color: ${({ theme }) => theme.textfield.text};
  }
    
  .swagger-ui .response-col_status {
    color: ${({ theme }) => theme.textfield.text};
  }
        
  .swagger-ui .response-col_description {
    color: ${({ theme }) => theme.textfield.text};
  }
                
  .swagger-ui .response-col_links {
    color: ${({ theme }) => theme.textfield.text};
  }
                                
  .swagger-ui table thead tr th {
    color: ${({ theme }) => theme.textfield.text};
  }
                                                                
  .swagger-ui .parameter__name {
    color: ${({ theme }) => theme.textfield.text};
  }
                                                                                                                                
  .swagger-ui .parameter__in {
    color: ${({ theme }) => theme.textfield.text};
  }
                                                                                                                                                                                                                                                                
  .swagger-ui section.models h4 {
    color: ${({ theme }) => theme.textfield.text};
  }
  
  .swagger-ui {
    color: ${({ theme }) => theme.openedSectionText};
  }

  .swagger-ui .model-box {
    background-color: ${({ theme }) => theme.closedSectionBackground};
    color: ${({ theme }) => theme.openedSectionText};
  }

  .swagger-ui .model-box span {
    color: ${({ theme }) => theme.openedSectionText};
  }

  .swagger-ui .model {
    background-color: ${({ theme }) => theme.openedSectionBackground};
    color: ${({ theme }) => theme.openedSectionText};
  }

  .swagger-ui .model .property {
    color: ${({ theme }) => theme.textfield.text};
  }

  .swagger-ui .model-box .model {
    color: ${({ theme }) => theme.textfield.text};
  }

  .swagger-ui .model-toggle::after {
    background: ${({ theme }) =>
      `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        theme.textfield.text
      )}" d="M10 15l-5-5h10z"/></svg>')`} !important;
    background-size: 100% !important;
    color: ${({ theme }) => theme.textfield.text};
    width: 20px;
    height: 20px;
    display: block;
    content: "";
  }

  .swagger-ui .info .title {
    color: ${({ theme }) => theme.textfield.text} !important;
  }

`;
