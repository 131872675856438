import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as WordImage } from 'Images/report-word.svg';
import { ReactComponent as PDFImage } from 'Images/report-pdf.svg';
import { ReactComponent as CSVImage } from 'Images/report-csv.svg';
import { ReactComponent as Gnericmage } from 'Images/download_reports.svg';

export const ElementWrapper = styled.div`
  display: flex;
  flex: 0 0 54px;
  flex-direction: row;
  :hover{
    background-color: ${({ theme }) => theme.backgrounds.dropdown1OptionsHover};
  }
  pointer-events: ${({ disabled }) => disabled ? 'none' : 'auto'};
  opacity: ${({ disabled }) => disabled ? 0.5 : 1};
`;
ElementWrapper.displayName = 'ElementWrapper';

export const DropdownLabel = styled.div`
  margin: auto auto auto 9px;
  font-size: 13.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  /* color: ${colors.darkBlueGrey}; */
  color: ${({ theme }) => theme.textfield.text};
`;

export const WordIcon = styled(WordImage)`
flex: 0 0 24px;
height: 24px;
margin: auto 0px auto 12px;
`;
WordIcon.displayName = 'WordIcon';

export const PDFIcon = styled(PDFImage)`
flex: 0 0 24px;
height: 24px;
margin: auto 0px auto 12px;
`;
PDFIcon.displayName = 'PDFIcon';

export const CsvIcon = styled(CSVImage)`
flex: 0 0 24px;
height: 24px;
margin: auto 0px auto 12px;
`;
CsvIcon.displayName = 'CsvIcon';

export const GenericIcon = styled(Gnericmage)`
flex: 0 0 24px;
height: 24px;
margin: auto 0px auto 12px;
`;
GenericIcon.displayName = 'GenericIcon';
