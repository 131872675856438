import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PERMISSION_TYPES, FEATURE_USERS } from 'Hooks/usePermission/permissions';
import IconButton from 'Common/Components/IconButton';
import { showUserModalDelete } from 'Screens/Contextualization/Users/actions/Actions';
import { Trash } from '../../styled';
import { selectUserSelected } from 'store/Users/selectors';
import { selectActiveUser } from 'store/Sesion/selectors';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';

const Delete = () => {
  const dispatch = useDispatch();
  const userSelected = useSelector(selectUserSelected);
  const activeUser = useSelector(selectActiveUser);
  const allowedByRole = useSelector((state) => selectAllowedByRole(state, FEATURE_USERS, PERMISSION_TYPES.DELETE));
  const canUpdate = allowedByRole && !userSelected.some((u) => u.username === activeUser);
  return (
    <IconButton icon={ <Trash disabled={ !canUpdate } /> } title="Delete" onClick={ () => dispatch(showUserModalDelete()) } disabled={ !canUpdate } />
  );
};

export default Delete;
