/* eslint-disable no-console */
/* eslint-disable react/jsx-max-depth */
import React from 'react';
import { ItemContainer, StyledCol } from './styled';
import Header from './Header';
import Item from './Item';
import { useSelector } from 'react-redux';
import { selectTemplates } from 'store/Settings/jiraSelectors';

const Table = () => {
  const templates = useSelector(selectTemplates);
  const data = Object.values(templates);
  const keys = Object.keys(templates);

  const getItems = (item, index) => {
    return <Item name={ item.name } projectKey={ keys[index] } />;
  };

  return (
    <StyledCol>
      <Header />
      <ItemContainer>
        {data?.map(getItems)}
      </ItemContainer>
    </StyledCol>
  );
};

export default Table;
