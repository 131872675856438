import styled from 'styled-components';
import { ReactComponent as TrashIcon } from 'Images/icon-action-bar-trash.svg';
import { ReactComponent as WarningIcon_ } from 'Images/warning-delete.svg';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  display: block;
`;
Wrapper.displayName = 'Wrapper';

export const Trash = styled(TrashIcon)`
    height: 17px;
    g > path { 
      fill: ${colors.red1};
    }
`;

Trash.displayName = 'Trash';

export const WarningIcon = styled(WarningIcon_)`
  g g {
    fill: ${({ theme }) => theme.icons.warningIcon.color};
  }
`;

WarningIcon.displayName = 'WarningIcon';

export const Message = styled.div`
  color: ${({ theme }) => theme.text.modalDescription};
`;
Message.displayName = 'Message';
