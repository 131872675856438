import React from 'react';
import { useSelector } from 'react-redux';
import { selectMoreOptionsOpened, selectWorkspacesSelected } from 'store/Workspace/selectors';
import isEmpty from 'lodash/isEmpty';
import IconButton from 'Common/Components/IconButton';
import ContextMenuButton from 'Common/Components/ContextMenuButton';
import ContextMenuWorkspaceMassiveUpdate from 'Screens/Contextualization/Workspaces/components/ContextMenuWorkspaceMassiveUpdate';
import { WithPermission } from 'Common/Components/WithPermission';
import { FEATURE_WORKSPACES, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import More from './styled';

// eslint-disable-next-line import/prefer-default-export
export const MoreOptionsButton = ({ setShowConfirmation }) => {
  const moreOptionsOpened = useSelector(selectMoreOptionsOpened);
  const selectedWs = useSelector(selectWorkspacesSelected);
  const hasSelectedWs = !isEmpty(selectedWs);

  return (
    <WithPermission permission={ FEATURE_WORKSPACES } type={ PERMISSION_TYPES.UPDATE }>
      <ContextMenuButton
        opened={ moreOptionsOpened }
        menu={ <ContextMenuWorkspaceMassiveUpdate trigger="massive_update_workspaces_table_nav" setShowConfirmation={ setShowConfirmation } /> }
        trigger="massive_update_workspaces_table_nav"
        display={ hasSelectedWs }
        icon={ <IconButton icon={ <More /> } title="More" onClick={ () => {} } /> }
      />
    </WithPermission>
  );
};
