import React from 'react';
import { useSelector } from 'react-redux';
import { selectProjects, selectIsFetching, selectViewType } from 'store/Projects/selectors';
import getColumns from 'Screens/Planner/Projects/Content/components/Table/Columns';
import { FormattedMessage } from 'react-intl';
import { selectTasks } from 'store/Tasks/selectors';
import getSplitViewColumns from 'Screens/Planner/Projects/Content/components/Table/SplitViewColumns';
import SubComponent from './components/SubComponent';
import { Wrapper, StyledTable } from './styled';
import useTheme from 'Hooks/UseTheme';

const Table = () => {
  const projects = useSelector(selectProjects);
  const tasks = useSelector(selectTasks);
  const isFetching = useSelector(selectIsFetching);
  const { themeStyles } = useTheme();

  const expanded = projects.map((p) => {
    if (tasks.some((t) => t.projectId === p.id)) return {};
    return false;
  });

  const getTrProps = () => ({ style: { backgroundColor: themeStyles.borders.vulnsCounter } });

  const viewType = useSelector(selectViewType);
  return (
    <Wrapper>
      <StyledTable
        data={ isFetching ? [] : projects }
        columns={ viewType.id === 'splitView' ? getSplitViewColumns(isFetching, true) : getColumns(isFetching, true) }
        manual
        minRows={ 0 }
        showPagination={ false }
        noDataText={ !isFetching && <FormattedMessage id="app.nodata" /> }
        getTrProps={ getTrProps }
        SubComponent={ (row) => <SubComponent row={ row } /> }
        expanded={ expanded }
      />
    </Wrapper>
  );
};

export default Table;
