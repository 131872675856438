import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as ListViewImage } from 'Images/list_view.svg';
import { ReactComponent as GridViewImage } from 'Images/grid_view.svg';
import AddButton from 'Common/Components/AddButton';
import { selectCurrentViewMode } from 'store/Analytics/selectors';
import { GRAPH_GRID_VIEW, GRAPH_LIST_VIEW } from 'store/Analytics/viewMode';
import { toggleView } from 'store/Analytics/actions';
import Wrapper from './styled';

const ListView = () => {
  const dispatch = useDispatch();

  const toggle = () => dispatch(toggleView(GRAPH_LIST_VIEW));

  return <AddButton onClick={ toggle } text="List View" icon={ <ListViewImage /> } />;
};

const GridView = () => {
  const dispatch = useDispatch();

  const toggle = () => dispatch(toggleView(GRAPH_GRID_VIEW));

  return <AddButton onClick={ toggle } text="Grid View" icon={ <GridViewImage /> } />;
};

const ViewTypeAnalytics = () => {
  const viewMode = useSelector(selectCurrentViewMode);
  return (
    <Wrapper>
      {viewMode === GRAPH_LIST_VIEW ? <GridView /> : <ListView />}
    </Wrapper>
  );
};

export default ViewTypeAnalytics;
