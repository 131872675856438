import React from 'react';
import PropTypes from 'prop-types';
import Button from 'Common/Components/BlueButton/styled';
import useTheme from 'Hooks/UseTheme';

const BlueButton = ({
  onClick, label, disabled, className, isLoading, id, children
}) => {
  const { themeStyles } = useTheme();

  return (
    <Button id={ id } theme={ themeStyles } className={ className } onClick={ onClick } disabled={ disabled } type="button" isLoading={ isLoading }>{ label || children }</Button>
  );
};

BlueButton.defaultProps = {
  disabled: false,
  isLoading: false,
  className: '',
  id: ''
};

BlueButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string
};

export default BlueButton;
