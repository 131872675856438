import styled from 'styled-components';
import { ReactComponent as _EvidenceDefault } from 'Images/evidence-default.svg';

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 126px;
  height: 130px;
  border-radius: 3px;
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px #dbdbdb;
  /* background-color: #ffffff; */
  background-color: ${({ theme }) => theme.backgrounds.modal};
  padding: 7px;
  margin: 10px 0px 0px 3px;
  cursor: pointer;

  &:hover{
    background-color: #eeeeee;
  }
`;

export const EvidenceDefault = styled(_EvidenceDefault)`
  /* margin: 15px; */
  width: 80px;
  height: 80px;
`;

export const BlueText = styled.p`
  color: #0082ed;
  font-size: 13px;
  font-weight: 600;
  width: fit-content;
  margin: 5px auto auto auto;
  cursor: pointer;
`;
