/* eslint-disable import/prefer-default-export */
import StringInput from 'Common/Components/v2/Input/components/StringInput';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
`;
Wrapper.displayName = 'Wrapper';

export const Url = styled(StringInput)`
  width: 267px;
`;
Url.displayName = 'CustomPassword';

export const ConsumeKey = styled(StringInput)`
  width: 377px;
`;
ConsumeKey.displayName = 'CustomPassword';
