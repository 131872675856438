import colors from 'Styles/colors';
import styled from 'styled-components';
import { Col } from 'Common/Components/Grid/index';

// title
export const Title = styled.label`
  padding-top: 11px;
  user-select: none;
  color: ${({ theme }) => theme.text.dashboardTitle};
  font-size: 14px;
  font-weight: 600;
  opacity: 1;
`;
Title.displayName = 'Title';

// description
export const Description = styled.div`
  color: ${({ theme }) => theme.text.dashboardLightText};
  font-size: 12px;
  line-height: 3.17;
  overflow: hidden;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 400;
`;
Description.displayName = 'Description';

// text
export const Text = styled.div`
  color: ${({ theme }) => theme.text.dashboardLightText};
  font-size: 13px;
  font-weight: 500;
  line-height: 2.62;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
Text.displayName = 'Text';

export const Details = styled(Text)`
  font-weight: 400;
  color: ${({ theme }) => theme.text.dashboardDarkText};
`;
Details.displayName = 'Details';

export const StyledCol = styled(Col)`
  & > *:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.borders.dashboardRow};
  }
`;
StyledCol.displayName = 'StyledCol';

export const Empty = styled.div`
  user-select: none;
  color: ${colors.grey5};
  display: inline;
  font-size: 13px;
  font-weight: 300;
  line-height: 2.62;
`;
Empty.displayName = 'Empty';
