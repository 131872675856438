import styled from 'styled-components';
import colors from 'Styles/colors';
import { fadeIn } from 'Styles/effects';
import { ReactComponent as PlannerIcon } from 'Images/tasks_icon.svg';

export const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  border-radius: 20px;
  width: 37px;
  height: 37px;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  & > svg {
    cursor: pointer;
    min-width: 17px;
  }
  &:hover {
    background-color: ${({ theme }) => theme.icons.actionBar.background};
    & > svg g, path {
      fill: ${({ theme }) => theme.icons.actionBar.hover};
    }
  }
  &:active {
    ${fadeIn(0, 1)}
    background-color: ${colors.blueGrey};
    & > svg {
      g, path {
        fill: ${colors.white};
      }
    }
  }
  ${({ disabled }) => disabled && `
    opacity:0.5;
  `};
`;
Wrapper.displayName = 'Wrapper';

export const Icon = styled(PlannerIcon)`
  g {
    fill: ${({ theme }) => theme.icons.actionBar.color};
  }
  g > path {
    fill: ${({ theme }) => theme.icons.actionBar.color};
  }

`;
