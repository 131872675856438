import * as types from './types';

const initialState = {
  redirectPathname: '',
  redirectSearch: '',
  redirectSaveHistory: false,
  redirectHasPendingChanges: false
};

function global (state = initialState, action) {
  switch (action.type) {
    case types.REDIRECT_START: {
      return {
        ...state,
        redirectPathname: action.pathname,
        redirectSearch: action.search,
        redirectSaveHistory: action.saveHistory
      };
    }
    case types.REDIRECT_CLEAR: {
      return {
        ...state,
        redirectPathname: initialState.redirectPathname,
        redirectSearch: initialState.redirectSearch,
        redirectSaveHistory: initialState.redirectSaveHistory
      };
    }
    case types.SET_HAS_PENDING_CHANGES: {
      return {
        ...state,
        redirectHasPendingChanges: action.hasPendingChanges
      };
    }
    case types.EXPAND_COLLAPSE: {
      const defaultFields = state.tabs && state.tabs[action.tab] ? state.tabs[action.tab] : null;
      return {
        ...state,
        tabs: {
          ...state.tabs,
          [action.tab]: {
            ...defaultFields,
            ...action.fields
          }
        }
      };
    }

    case types.FETCH_WORKSPACES_DATA: {
      return {
        ...state,
        fetchingWorkspaces: true
      };
    }

    case types.FETCH_WORKSPACES_DATA_SUCCESS: {
      return {
        ...state,
        fetchingWorkspaces: false,
        errorFetchingWorkspaces: false,
        workspacesData: action.data,
        workspacesCount: action.count
      };
    }

    case types.FETCH_WORKSPACES_DATA_FAILURE: {
      return {
        ...state,
        fetchingWorkspaces: false,
        errorFetchingWorkspaces: true,
        errorMessageFetchingWorkspaces: action.data
      };
    }
    default:
      return state;
  }
}

export default global;
