import React from 'react';
// import Delete from './Delete';
import Edit from './Edit';
import { Wrapper } from './styled';
import { useDispatch } from 'react-redux';
import { newGetJiraTemplate } from 'store/Settings/jiraActions';

const Buttons = ({ projectKey }) => {
  const dispatch = useDispatch();
  const onClick = () => dispatch(newGetJiraTemplate(projectKey));

  return (
    <Wrapper>
      {/* <Delete /> */}
      <Edit onClick={ () => onClick() } />
    </Wrapper>
  );
};

export default Buttons;
