import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
`;
Wrapper.displayName = 'Wrapper';

export const FilesContainer = styled.div`
  width: 100%;
  border-radius: 3px;
  border: solid 1px ${({ theme }) => theme.selector.background};
  background-color: ${({ theme }) => theme.selector.background};
  overflow-y: auto;
  height: 200px;
`;
FilesContainer.displayName = 'FilesContainer';

export const FilesItem = styled.div`
  padding: 0px 15px;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 34px;
  align-items: center;
  
  &:hover {
    background-color: ${({ theme }) => theme.selector.selected};
  }
`;
FilesItem.displayName = 'FilesItem';

export const FileName = styled.span`
  flex: 1 1 0px;
  font-size: 12.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #42719f;
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
FileName.displayName = 'FileName';

export const FileSize = styled.span`
  flex: 0 0 auto;
  font-size: 12.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7a7e8f;
  margin: 0px 10px;
  user-select: none;
`;
FileSize.displayName = 'FileSize';

export const Icon = styled.span`
  padding-bottom: 3px;
  flex: 0 0 auto;
  margin-left: auto;
`;
Icon.displayName = 'Icon';

export const DragableZoneActive = styled.div`
    width: ${(props) => (props.onManage ? '452px' : 'unset')};
    height: ${(props) => (props.onManage ? '425px' : 'unset')};
    border: 1px solid #0082ed;
    background-color: aliceblue;
    position: absolute;
    top: -10px;
    bottom: 0;
    left: ${(props) => (props.onManage ? '-2px' : '0')};
    right: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
`;
DragableZoneActive.displayName = 'DragableZoneActive';

export const DragableZoneActiveContent = styled.div`
    position: absolute;
    color: grey;
    font-size: 36px;
`;
DragableZoneActiveContent.displayName = 'DragableZoneActiveContent';

export const Text = styled.div`
    color: #0082ed;
    font-size: 14.5px;
    line-height: 21px;
`;
Text.displayName = 'Text';
