import styled from 'styled-components';
import colors from 'Styles/colors';
import { Accept } from 'Common/Components/Button/styled';
import { ReactComponent as _modalIcon } from 'Images/new_vuln_modal_icon.svg';
import { slideIn } from 'Styles/effects';
import { ReactComponent as NextSVG } from 'Images/next_arrow.svg';

export const Wrapper = styled.div`
  width: 906px;
  height: 79%;
  border-radius: 8px 8px 2px 2px;
  box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.24);
  border: solid 1px ${({ theme }) => theme.backgrounds.modal};
  background-color: ${({ theme }) => theme.backgrounds.modal};
  border-top: 10px solid #419bf9;
  padding: 40px 18px 40px 30px;
  text-align: left;
  ${slideIn}
`;

export const GeneralWrapper = styled.div`
  height: 530px;
  overflow-y: auto;
  padding-top: 24px;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  width: 350px;
  height: 32px;
  font-size: 21.5px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.49;
  letter-spacing: normal;
  color: ${({ theme }) => theme.text.modalTitle2};
`;
Title.displayName = 'Title';

export const ErrorMessage = styled.p`
    font-size: 12.5px;
    font-weight: normal;
    text-align: left;
    color: ${colors.redPink};
    float: left;
    width: 245px;
    margin-bottom: 8px !important;
    margin-top: 8px;
`;
ErrorMessage.displayName = 'ErrorMessage';

export const CreateButton = styled(Accept)`
  width: 194px;
  margin-left: 10px;
  margin-right: 34px;
  user-select: none;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  background-color: ${({ disabled, theme }) => (disabled ? theme.buttons.disabled.background : theme.buttons.primary.background)};
  color: ${(props) => (props.disabled ? props.theme.buttons.disabled.color : props.theme.buttons.primary.color)};
  border: 1px solid ${(props) => (props.disabled ? props.theme.buttons.disabled.border : props.theme.buttons.primary.border)};
  opacity:1;

  & span {
    padding: 0 55px;
  }

  & svg {
    margin-bottom: 2px;
  }

`;
CreateButton.displayName = 'CreateButton';

export const NextIcon = styled(NextSVG)`
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  & > path {
    fill: ${(props) => (props.disabled ? props.theme.buttons.disabled.color : props.theme.buttons.primary.color)};
  }
`;
NextIcon.displayName = 'NextIcon';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonWrapper = styled.div``;

export const ModalBody = styled.div`
  height: 95%;
  display: flex;
  flex-direction: column;
`;

export const ModalIcon = styled(_modalIcon)`
  cursor: default;
  margin-right: 12px;
  path {
    fill: ${({ theme }) => theme.icons.actionBar.color};
  }
`;

export const Tabs = styled.div`
  display: flex;
  margin: 21px 0;
`;

export const Tab = styled.div`
  margin-right: 28px;
  padding: 0 13px;
  padding-bottom: 10px;
  cursor: pointer;
  color: ${({ active, theme }) => (active ? colors.blueCerulean : theme.text.detailModalTabs)};
  border-bottom: ${({ active }) => (active ? `2px solid ${colors.blueCerulean}` : 'none')};
  &:hover {
    border: none;
    color: #419bf9;
    }
    border-bottom: 2px solid transparent;
    
  ${({ active, theme }) => (active
? `
    border: none;
    border-bottom: 2px solid #419bf9;
    font-weight: 600;
    &:hover {
      border-bottom: 2px solid #419bf9;
    }
  `
: '')}
  
`;
