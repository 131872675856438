import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

export const Input = styled.input`
  width: 406px;
  height: 34px;
  background-color: ${({ theme }) => theme.textfield.background};
  border-style: solid;
  border-color: transparent;
  border-width: 1px 1px 0px 1px;
  border-left: 12px solid #53a9ff;
  padding-left: 15px;
  padding-right: 42px;
  font-weight: 400;
  color: ${({ theme }) => theme.textfield.text};
  @extend .text-black-14;

  &.full{
      background-color: white; 
  }

  &::placeholder {
      color: ${({ theme }) => theme.text.placeholder};
      font-size: 13.5px;
      font-weight: 300;
  }

  &:hover{
    border-style: solid;
    border-color: #afb1bc;
    border-width: 1px 1px 1px 1px;
    border-left: 12px solid #53a9ff;
  }

  &:focus{
      background-color: ${({ theme }) => theme.textfield.background};
      border-left: 12px solid #53a9ff!important;

      &::placeholder {
          color: transparent;
      }
  }
`;

export const RedAster = styled.span`
  color: #ea3158;
  bottom: 12px;
  position: relative;
  left: 2px;
`;
RedAster.displayName = 'RedAster';
