import styled from 'styled-components';
import MdEditor from 'react-markdown-editor-lite';

export const EditorContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  background-color: #EDF2F7;
`;
EditorContainer.displayName = 'EditorContainer';

export const StyledMdEditor = styled(MdEditor)`
  display: block;
  position: relative;
  height: 100%;
  background-color: ${({ theme }) => theme.textfield.focus} !important;
  border: none;

  .editor-container {
    height: 100%;
    top: 0px;
    .sec-md .input {
      background-color: ${({ theme }) => theme.textfield.focus};
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.69;
      letter-spacing: normal;
      color: #63758d;
      padding: 18px 22px;
      width: 100% !important;
      height: 100% !important;
      margin-left: 0 !important;
    }
  }

  .rc-md-navigation {
    position: absolute;
    height: 20px;
    min-height: 20px !important;
    bottom: -30px;
    background-color: transparent;
    width: fit-content !important;
    border-bottom: none;
  }

  .drop-wrap {
    h1 { font-size: 21px; font-weight: bold; }
    h2 { font-size: 17px; font-weight: bold; }
    h3 { font-size: 15px; font-weight: bold; }
    h4 { font-size: 14px; font-weight: bold; }
    h5 { font-size: 13px; font-weight: bold; }
  }

`;
StyledMdEditor.displayName = 'StyledMdEditor';
