import styled from 'styled-components';
import { ReactComponent as IconKb_ } from 'Images/vuln-kb.svg';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 34px;
  flex: 1 1 auto;
`;
Wrapper.displayName = 'Wrapper';

export const InputTitle = styled.input`
  padding-right: 10px;
  margin: auto 5px auto 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.text.detailModalTitle};
  border: none;
  cursor: pointer;
  border-radius: 5px;
  background-color: transparent;
  width: 100%;
  
  &:focus {
    cursor: text;
    color: ${({ theme }) => theme.text.detailNameHover};
  }

  &:not(:focus) {
    &:hover {
      color: ${({ theme }) => theme.text.detailNameHover};
      background-color: ${({ theme }) => theme.textfield.background};
    }
  }

`;
InputTitle.displayName = 'InputTitle';

export const IconKb = styled(IconKb_)`
  margin-right: 11px !important;
  height: 20px;
  path {
    fill: ${({ theme }) => theme.icons.detailModalTitle.color};
    stroke: ${({ theme }) => theme.icons.detailModalTitle.color};
    stroke-width: 0.5;
  }
`;
IconKb.displayName = 'IconKb';
