import styled from 'styled-components';
import { disableTextSelection } from 'Styles/effects/text';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  padding-right: 12px;
  overflow: auto;
  margin-right: 12px;
`;
Wrapper.displayName = 'Wrapper';

export const WrapperItem = styled.div`
  position: relative;
  display: flex;
  padding: 15px 20px 15px 20px;
  border-radius: 3px;
  box-shadow: 0 2px 15px 0 ${({ theme }) => theme.shadows.memoListItem};
  border: solid 1px ${({ theme }) => theme.borders.memoListItem};
  background-color: ${({ theme }) => theme.backgrounds.memoListItem};
  margin-bottom: 30px;
  flex-direction: column;
  text-align: left;
  ${({ selected, theme }) => selected && `
    border: solid 3px ${theme.borders.memoListItemSelected};
    padding: 13px 18px 13px 18px;
  `}
  ${disableTextSelection}
`;
WrapperItem.displayName = 'WrapperItem';

export const Title = styled.div`
  font-size: 17px;
  font-weight: 600;
  color: ${({ theme }) => theme.text.memoListItemTitle};
  margin-bottom: 3px;
  cursor: pointer;
`;
Title.displayName = 'Title';

export const Description = styled.div`
  font-size: 12.5px;
  line-height: 1.44;
  color: ${({ theme }) => theme.text.memoListItemDescription};
  margin-bottom: 10px;
`;
Description.displayName = 'Description';

export const Id = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: ${({ theme }) => theme.text.memoListItemID};
`;
Id.displayName = 'Id';
