import styled from 'styled-components';
import DebouncedInput from 'Common/Components/v2/DebouncedInput';
import { Input, Texts } from 'Common/Components/StandardTextField/styled';

const CustomInput = styled(DebouncedInput)`
  margin-top: ${({ mandatory }) => mandatory ? '10px' : '0'};
  ${Texts} {
    height: ${({ mandatory }) => mandatory ? '26px' : '0'};
  }
  span {
    font-weight: 400;
    margin-right: 3px;
  }
  ${Input} {
    /* box-shadow: inset 0 -1px 0 0 #afafaf; */
  }
`;
CustomInput.displayName = 'CustomInput';

export default CustomInput;
