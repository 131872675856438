import styled from 'styled-components';
import StandardTextField from 'Common/Components/StandardTextField';
import UserRoleDropdown from 'Common/Components/UserRoleDropdown';

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.backgrounds.detailModalFields};
  display: flex;
  flex-direction: column;
  padding: 10px 20px 20px 20px;
  & > :first-child {
    margin-bottom: 17px;
  }
`;
Wrapper.displayName = 'Wrapper';

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  #test_user_edit_role {
    margin: 0;
  }
`;
Field.displayName = 'Field';

export const Row = styled.div`
  display: flex;
  ${Field} {
    width: 50%;
  }

  & >:first-child {
    margin-right: 30px;
  }


`;
Row.displayName = 'Row';

export const Label = styled.label`
  font-weight: 400;
  font-size: 12.5px;
  color: ${({ theme }) => theme.text.detailModalFields};
  margin-bottom: 6px;
  line-height: 2.08;
`;
Label.displayName = 'Label';

export const TextField = styled(StandardTextField)`
  font-weight: 400;
  font-size: 12.5px;
  margin-bottom: 0;
  display: block;
  width: ${(props) => (props.width ? props.width : '100%')};
  
  span {
    font-weight: 600;
    margin-bottom: 0;
    color: ${({ theme }) => theme.text.detailModalFields} !important;
  }
  input {
    margin-top: 6px;
    ${({ disabled }) => {
      if (!disabled) return null;
      return `
        cursor: not-allowed;
        `;
    }}
  }
  svg {
    top: 34px;
  }
`;
TextField.displayName = 'TextField';

export const RoleDropdown = styled(UserRoleDropdown)`
  div {
    margin: 0 !important;
  }
`;
RoleDropdown.displayName = 'RoleDropdown';

export const Workspaces = styled(Field)`
  margin-top: 15px;
`;
Workspaces.displayName = 'Workspaces';
