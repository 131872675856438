import styled from 'styled-components';
import { ReactComponent as Run } from 'Images/icon-action-bar-trigger.svg';

export const WrapperColumn = styled.div`
  font-size: 13px;
  font-weight: 300;
  display: flex;
  height: 38px;
  align-items: center;
  text-transform: capitalize;
`;
WrapperColumn.displayName = 'WrapperColumn';

export default WrapperColumn;

export const RunIcon = styled(Run)`
`;
RunIcon.displayName = 'RunIcon';

export const NameStyled = styled.div`
  font-weight: 600;
  color: ${({ theme }) => theme.text.serviceName};
  font-size: 14.5px;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: capitalize;
  &:hover {
    text-decoration: underline;
  }
`;
NameStyled.displayName = 'NameStyled';

export const Wrapper = styled.div`
  
`;
Wrapper.displayName = 'Wrapper';
