import styled from 'styled-components';
import { ReactComponent as DeleteIcon } from 'Images/icon-action-bar-trash.svg';
import { ReactComponent as viewInLogIcon } from 'Images/viewInLog.svg';
import { ReactComponent as MarkAsReadIconSvg } from 'Images/icon-action-bar-column.svg';
import { ReactComponent as SettingsIconSvg } from 'Images/icon-customfields.svg';

export const Wrapper = styled.div`
  position: absolute;
  width: 256px;
  //max-height: 319px;
  border-radius: 2px;
  top: 43px;
  right: 0px;
  z-index: 999;
  border: 1px solid ${({ theme }) => theme.borders.preferenceMenuLine};
  background-color: ${({ theme }) => theme.backgrounds.actionDropDown};
  box-shadow: ${({ theme }) => theme.shadows.actionDropDown} 0 0 50px 0;
`;

export const Footer = styled.div`
  height: 45px;
  margin: 0;
  padding: 5px;
`;

export const FooterButtom = styled.button`
background: none;
color: inherit;
border: 1px solid #63758d;
padding: 0;
font: inherit;
cursor: pointer;
outline: inherit;

font-size: 14px;
font-weight: 500;
text-align: center;
color: ${({ theme }) => theme.text.modalTitle2};
width: 100%;
height: 100%;

`;

export const Header = styled.div`
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: solid 1px ${({ theme }) => theme.borders.preferenceMenuLine};
  padding: 0 12px;
  height: 45px;

  & p {
    color: ${({ theme }) => theme.text.modalTitle2};
    font-size: 14px;
    font-weight: 600;
  }
`;

export const Text = styled.p`
  margin: 0;
  font-size: 11px;
  /* color: #1c2243; */
  color: ${({ theme }) => theme.text.modalTitle2};
  text-align: initial;
  padding: 10px 0;
`;

export const TimeStamp = styled.p`
  margin: 0;
  font-size: 9px;
  color: #63758d;
`;

export const NotificationList = styled.ul`
  width: 100%;
  margin: 0;
  padding: 0;
`;

export const Details = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
`;

export const Icons = styled.div`
display: flex;
flex-direction: column;
justify-content: space-around;
align-items: center;
`;

export const Delete = styled(DeleteIcon)`
  width: 16px;
  display:none;
`;

export const ViewLog = styled(viewInLogIcon)`
  display:none;
`;

export const ListItem = styled.li`
  list-style: none;
  min-height: 70px;
  margin: 0;
  padding: 0 13px;
  padding-right: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid ${({ theme }) => theme.borders.preferenceMenuLine};
  cursor: pointer;
  background-color: ${({ theme, read }) => (!read ? theme.backgrounds.unreadNotificationsRow : 'inherit')};

  &:hover {
    background-color: ${({ theme }) => theme.backgrounds.selectedTableItem};
  }

  &:hover ${Delete}{
    display:block;
  }
  &:hover ${ViewLog}{
    display:block;
  }
`;

export const MarkAsReadIcon = styled(MarkAsReadIconSvg)`
  height:14px;
  margin-right: 7px;
  margin-left: auto;
  g, path {
    fill: ${({ theme }) => theme.icons.actionBar.color};
  }
`;

export const SettingsIcon = styled(SettingsIconSvg)`
  height:16px;
  g, path {
    fill: ${({ theme }) => theme.icons.actionBar.color};
  }
`;
