import React, { useContext } from 'react';
import { DropdownMenu } from '../../styled';
import { CollapsedIcon, Item, MoonIcon, SunIcon, Hr, CheckIcon } from './styled';
import { ThemeContext } from 'Context';

const AppearanceMenu = ({ setAppearance }) => {
  const { theme, setParticularTheme } = useContext(ThemeContext);

  const action = (e) => {
    e.stopPropagation();
    setAppearance(false);
  };

  const changeTheme = (e, colorTheme) => {
    e.stopPropagation();
    setParticularTheme(colorTheme);
  };

  return (
    <DropdownMenu>
      <Item onClick={ (e) => action(e) }>
        <CollapsedIcon />
        Appearance
      </Item>
      <Hr />
      <Item onClick={ (e) => changeTheme(e, 'dark') }>
        <MoonIcon />
        Dark mode
        {theme === 'dark' && <CheckIcon />}
      </Item>
      <Item onClick={ (e) => changeTheme(e, 'light') }>
        <SunIcon />
        Light mode
        {theme === 'light' && <CheckIcon />}
      </Item>
    </DropdownMenu>
  );
};

export default AppearanceMenu;
