import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as CloseSVG } from 'Images/icon-close-without-background.svg';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

Wrapper.displayName = 'CustomFieldsWrapper';

export const Head = styled.div`
    display: flex;
    justify-content: space-between;
`;

Head.displayName = 'Head';

export const Description = styled.span`
    color: ${colors.grey19};
    font-size: 12.5px;
    line-height: 1.36;
    text-align: left;
`;

Description.displayName = 'Description';

export const Title = styled.span`
  color: ${({ theme }) => theme.text.settingsBlueTitle};
  font-size: 21.5px;
    font-weight: 600;
`;

Title.displayName = 'Title';

export const CloseIcon = styled(CloseSVG)`
  position: absolute;
  left: 9px;
  top: 9px;
  margin: auto;
  cursor: pointer;
  &>:hover{
    left: 8px;
    top: 8px;
    & g {
      & circle{
        background-color: #edf2f7;
      }  
    }
  }  
  & g {
    & path{
      fill: #63758d;
    }  
  }
`;
CloseIcon.displayName = 'CloseIcon';

export const CloseIconWrapper = styled.div`
  flex: 0 0 37px;
  position: relative;
  padding-left: 3px;
  height: 37px;
  margin: auto 0 auto auto;
  cursor: pointer;
  &:hover{
    background-color: #edf2f7;
    border-radius: 50%;
  }  
`;
CloseIconWrapper.displayName = 'CloseIconWrapper';
