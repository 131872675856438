/* eslint-disable react/destructuring-assignment */
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectWorkspacesField } from 'store/ManageEditCreate/selectors';
import { selectLocation } from 'store/Router/selectors';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import isEmpty from 'lodash/isEmpty';
import { getAssetsByWorkspace } from 'store/ServicesGeneral/actions';
import './styled.css';
import GeneralView from './GeneralView';
import WorkspaceContextView from './WorkspaceContextView';

const General = ({ assets, setAssets }) => {
  const dispatch = useDispatch();
  const location = useSelector(selectLocation);
  const currentWs = useSelector(selectCurrentWorkspace);
  const isOutsideWorkspace = location.pathname.startsWith('/vulnerabilities') && !location.pathname.includes(`/manage/${currentWs}`);
  const addedWorkspaces = useSelector(selectWorkspacesField);

  useEffect(() => {
    if (!isEmpty(addedWorkspaces) && isOutsideWorkspace) dispatch(getAssetsByWorkspace(addedWorkspaces));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedWorkspaces]);

  if (isOutsideWorkspace) return <GeneralView assets={ assets } setAssets={ setAssets } />;
  return <WorkspaceContextView assets={ assets } setAssets={ setAssets } />;
};

export default withRouter(General);
