import styled from 'styled-components';
import { ReactComponent as FiltersOff } from 'Images/icon_filter_off.svg';
import { ReactComponent as FiltersOn } from 'Images/icon_filter_on.svg';

export const DisabledFilterIcon = styled(FiltersOff)`
  height: 16px;
  path {
    fill: ${({ theme }) => theme.icons.actionBar.color};
    stroke: ${({ theme }) => theme.icons.actionBar.color};
    stroke-width: 0.5;
  }
`;
DisabledFilterIcon.displayName = 'DisabledFilterIcon';

export const EnabledFilterIcon = styled(FiltersOn)`
  height: 16px;
  path {
    fill: ${({ theme }) => theme.icons.actionBar.color};
    stroke: ${({ theme }) => theme.icons.actionBar.color};
    stroke-width: 0.5;
  }
`;
EnabledFilterIcon.displayName = 'EnabledFilterIcon';
