import StringField from 'Common/Components/StringField';
import styled from 'styled-components';
import colors from 'Styles/colors';

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  max-height: 30px;
  gap: 18px;
`;
TitleContainer.displayName = 'Title';

export const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: 12.5px;
  font-weight: 500;
  color: ${({ theme }) => theme.text.modalTitle2};

  &::after {
    content: "*";
    color: red;
    margin-left: 5px;
    font-size: inherit;
    font-weight: bold;
  }
  
`;
Title.displayName = 'Title';

export const Subtitle = styled.div`
  user-select: none;
  padding: 17px 0 0 0;
  font-size: 12.5px;
  line-height: 1.12;
  text-align: left;
  color: ${colors.grey19};
  margin-bottom: 30px;
`;
Subtitle.displayName = 'Subtitle';

export const WrapperCheckbox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 34px;
  .checkbox-container {
    display: inline;
    margin-bottom: 0px;
  }
`;
WrapperCheckbox.displayName = 'WrapperCheckbox';

export const CheckboxLabel = styled.div`
  font-size: 13.5px;
  line-height: 1.04;
  text-align: left;
  font-weight: 400;
  color : ${({ theme }) => theme.text.modalTitle};
`;
CheckboxLabel.displayName = 'CheckboxLabel';

export const Input = styled(StringField).attrs(({
  type: 'text',
  width: '100%'
}))`
  margin-bottom: 16px;
  max-width: 62%;
  width: 100%;
  ${Title} {
    margin-left: 0;
    font-weight: 400;
    color: #afb1bc;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-right: 0px;
  }
`;
Input.displayName = 'Input';

export const Wrapper = styled.div`
  width: 996px;
  height: 813px;
  background-color: ${({ theme }) => theme.backgrounds.modal2};
  padding: 0 !important;
  text-align: initial;
`;
Wrapper.displayName = 'Wrapper';

export const Toolbar = styled.div`
  display: flex;
  align-items: center;
  margin: 23px 0 14px 0;
`;
Toolbar.displayName = 'Toolbar';

export const StatusLabel = styled.div`
  font-size: 10px;
  font-weight: 500;
  padding: 3px 10px 2px 9px;
  border-radius: 6px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.06), inset 0 0 12px 0 rgba(144, 169, 192, 0.16);
  color: ${colors.paleGrey};
  background-color: ${({ isActive }) => isActive ? colors.blueCerulean : colors.grey20};
  margin: 0 12px 0 16px;
  user-select: none;
`;
StatusLabel.displayName = 'StatusLabel';
