import styled from 'styled-components';

const Number = styled.input.attrs({
  type: 'number'
})`
  width: 50px;
  height: 34px;
  border: 1px solid transparent;
  box-shadow: 0 1px 0 0 ${({ theme }) => theme.shadows.inputInset};
  border-bottom: none;
  background-color: ${({ theme }) => theme.textfield.background};
  padding-left: 8px;
  padding-right: 5px;
  font-size: 14.5px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.48;
  letter-spacing: normal;
  color: ${({ theme }) => theme.textfield.text} !important;
  border-radius: 3px;
  margin-left: 10px;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    opacity: 1;
  }
`;
Number.displayName = 'Number';

export default Number;
