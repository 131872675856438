import api from 'services/api';
import { trackEvent } from 'tracking/GA';
import { ACTIONS, CATEGORIES } from 'tracking/GA/constants';
import get from 'lodash/get';
import {
  SCHEDULE_FETCHING,
  SCHEDULE_FAIL,
  RUN_SCHEDULE,
  SCHEDULED_AGENTS_GET_DATA,
  SCHEDULE_TABLE_SORT_DATA,
  SCHEDULE_SET_PAGE,
  SCHEDULE_SET_IS_CREATE,
  SCHEDULER_UNSELECTED,
  SCHEDULER_SELECTED,
  SCHEDULER_NEW_PIVOT,
  UNSELECT_ALL_SCHEDULER,
  SELECT_ALL_SCHEDULER,
  SCHEDULE_SET_SECTION,
  SCHEDULER_CLEAR_ERROR,
  GET_SCHEDULERS_COUNT_SUCCESS,
  SCHEDULE_SET_TAGS_ENTITY,
  SCHEDULE_RESET_TAGS_FIELDS,
  SCHEDULED_CLOUD_AGENTS_GET_DATA,
  SCHEDULE_RESET_STATE
} from './constants';
import {
  selectData,
  selectIsFetching,
  selectLastSelected,
  selectSchedulersPage,
  selectSchedulersPageSize,
  selectSchedulersSelected,
  selectSchedulersSortBy,
  selectSchedulersSortDirection,
  selectSchedulersTotal,
  selectSelectedSection,
  selectHasAssetsTags,
  selectHasServicesTags,
  selectHasVulnTags
} from './selectors';

export const resetState = () => (dispatch) => dispatch({ type: SCHEDULE_RESET_STATE });

export function fetching (section) {
  return async (dispatch, getState) => {
    const isFetching = selectIsFetching(getState(), section);
    if (!isFetching) dispatch({ type: SCHEDULE_FETCHING, isFetching: true, section });
  };
}

export function somethingWentWrong (message) {
  return async (dispatch, getState) => {
    const section = selectSelectedSection(getState());
    dispatch({ type: SCHEDULE_FAIL, message, section });
  };
}

export function getSchedules () {
  return async (dispatch, getState) => {
    const section = 'scheduledAgents';
    try {
      dispatch(fetching(section));
      const page = selectSchedulersPage(getState(), section);
      const pageSize = selectSchedulersPageSize(getState(), section);
      const sortBy = selectSchedulersSortBy(getState(), section);
      const sortDirection = selectSchedulersSortDirection(getState(), section);

      const schedulers = await api.schedule.fetchSchedules(page, pageSize, sortBy, sortDirection);
      dispatch({ type: SCHEDULED_AGENTS_GET_DATA, data: schedulers?.rows, total: schedulers?.total_rows });
    } catch (e) {
      dispatch(somethingWentWrong(get(e, 'message', 'There was an error, please try again')));
    }
  };
}

export function getCloudSchedules () {
  return async (dispatch, getState) => {
    const section = 'scheduledCloudAgents';
    try {
      dispatch(fetching(section));
      const page = selectSchedulersPage(getState(), section);
      const pageSize = selectSchedulersPageSize(getState(), section);
      const sortBy = selectSchedulersSortBy(getState(), section);
      const sortDirection = selectSchedulersSortDirection(getState(), section);

      const schedulers = await api.schedule.fetchCloudSchedules(page, pageSize, sortBy, sortDirection);
      dispatch({ type: SCHEDULED_CLOUD_AGENTS_GET_DATA, data: schedulers?.rows, total: schedulers?.total_rows });
    } catch (e) {
      dispatch(somethingWentWrong(section));
    }
  };
}

export const getCountSchedulers = () => async (dispatch) => {
  try {
    const response = await api.schedule.fetchCountSchedulers();
    dispatch({ type: GET_SCHEDULERS_COUNT_SUCCESS, response });
  } catch (e) {
    dispatch(somethingWentWrong(get(e, 'message', 'There was an error, please try again')));
  }
};

export function sortBy (field, direction) {
  return async (dispatch) => {
    dispatch({ type: SCHEDULE_TABLE_SORT_DATA, field, direction });
    dispatch(getSchedules());
  };
}

export function setPage (page, section) {
  return async (dispatch) => {
    dispatch({ type: SCHEDULE_SET_PAGE, section, page });
    dispatch(getSchedules());
  };
}

export const setIsCreateSchedule = (value) => (dispatch) => {
  dispatch({ type: SCHEDULE_SET_IS_CREATE, value });
};

const addDeleteController = (schedulers, schedulersList) => {
  const allSchedulersAreSelected = schedulers.every(testScheduler => schedulersList.some((scheduler) => scheduler.value.id === testScheduler.value.id));

  if (allSchedulersAreSelected) return [SCHEDULER_UNSELECTED, schedulers];
  return [SCHEDULER_SELECTED, schedulers];
};

const selectCalculator = (e, scheduler, schedulersList, areSchedulerSelected) => (dispatch, getState) => {
  const pivot = selectLastSelected(getState());
  const index = schedulersList.findIndex((el) => el.value.id === scheduler.value.id);
  const schedulersSelected = selectSchedulersSelected(getState());

  dispatch({ type: SCHEDULER_NEW_PIVOT, payload: index });
  if (e.shiftKey && pivot !== -1 && areSchedulerSelected) {
    const start = Math.min(pivot, index);
    const end = Math.max(pivot, index) + 1;
    const schedulers = schedulersList.slice(start, end);
    const [type, payload] = addDeleteController(schedulers, schedulersSelected);
    return dispatch({ type, payload });
  }
  const [type, payload] = addDeleteController([scheduler], schedulersSelected);
  return dispatch({ type, payload });
};

export const selectRow = (e, scheduler) => (dispatch, getState) => {
  const section = selectSelectedSection(getState());
  const allSchedulers = selectData(getState(), section);
  const schedulerSelected = selectSchedulersSelected(getState());
  dispatch(selectCalculator(e, scheduler, allSchedulers, schedulerSelected.length > 0));
};

export const unSelectAll = () => (dispatch) => dispatch({ type: UNSELECT_ALL_SCHEDULER });

export const selectAllScheduler = () => (dispatch, getState) => {
  const section = selectSelectedSection(getState());
  const schedulersList = selectData(getState(), section);
  const schedulersSelected = selectSchedulersSelected(getState());
  const schedulerCount = selectSchedulersTotal(getState(), section);
  const pageSize = selectSchedulersPageSize(getState(), section);

  if (schedulersSelected.length === schedulerCount || schedulersSelected.length >= pageSize) return dispatch(unSelectAll());

  return dispatch({ type: SELECT_ALL_SCHEDULER, schedulersList });
};

export const selectScheduler = (scheduler) => (dispatch) => {
  dispatch({ type: SCHEDULER_SELECTED, payload: [scheduler] });
};

export const setSection = (section) => (dispatch) => {
  dispatch({ type: SCHEDULE_SET_SECTION, section });
  dispatch(unSelectAll());
};
export function changeStatus (scheduleId, status, type) {
  return async (dispatch) => {
  // if (status && selectLimitReached(getState())) { comento esto por ahora porque no deberia validar el limite cuando se activa/desactiva
  // dispatch(openModal(MODAL_UPGRADE_LICENSE));
  // } else {
    dispatch(fetching());
    if (type === 'cloud_agent') {
      await api.schedule.changeStatusCloud(scheduleId, status);
      dispatch(getCloudSchedules());
    } else {
      await api.schedule.changeStatus(scheduleId, status);
      dispatch(getSchedules());
    }

    // }
  };
}

export function removeSchedule () {
  return async (dispatch, getState) => {
    try {
      const section = selectSelectedSection(getState());
      dispatch(fetching());
      const schedulersSelected = selectSchedulersSelected(getState());

      await Promise.all(schedulersSelected.map(async (row) => {
        await api.schedule.removeSchedule(row.id);
        dispatch(trackEvent(CATEGORIES.schedule, ACTIONS.deleteSchedule.name, ACTIONS.deleteSchedule.label, row.id));
      }));
      dispatch(unSelectAll());
      if (section === 'scheduledAgents') {
        dispatch(getSchedules());
      } else {
        dispatch(getCloudSchedules());
      }
      dispatch(getCountSchedulers());
    } catch (e) {
      dispatch(somethingWentWrong(get(e, 'message', 'There was an error, please try again')));
    }
  };
}

export function runSchedule (id, type) {
  return async (dispatch) => {
    try {
      if (type === 'cloud_agent') {
        await api.schedule.runCloudSchedule(id);
      } else {
        await api.schedule.runSchedule(id);
      }
      dispatch({ type: RUN_SCHEDULE, id });
    } catch (e) {
      dispatch(somethingWentWrong(get(e, 'message', 'There was an error, please try again')));
    }
  };
}

export function setTagsEntity (entity, value) {
  return (dispatch) => {
    dispatch({ type: SCHEDULE_SET_TAGS_ENTITY, entity, value });
  };
}

export function resetTagsFields () {
  return (dispatch) => {
    dispatch({ type: SCHEDULE_RESET_TAGS_FIELDS });
  };
}

export function saveSchedule (scheduleId, agentId, crontabName, active, crontab, description, executorId, parameters, workspaces, timezone, tags, type) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      dispatch(fetching());
      const assetsTags = selectHasAssetsTags(state);
      const vulnsTags = selectHasVulnTags(state);
      const servicesTags = selectHasServicesTags(state);
      const parsedTags = tags ? tags.split(',').map((t) => t.trim()) : [];
      const newTags = [...new Set(parsedTags)];
      const host_tag = assetsTags ? newTags : [];
      const vuln_tag = vulnsTags ? newTags : [];
      const service_tag = servicesTags ? newTags : [];

      if (type === 'cloud_agent') {
        if (scheduleId === 0) {
          const response = await api.schedule.createCloudSchedule(agentId, crontabName, crontab, active, description, parameters, workspaces, timezone, host_tag, vuln_tag, service_tag);
          dispatch(trackEvent(CATEGORIES.schedule, ACTIONS.createSchedule.name, ACTIONS.createSchedule.label, response.id));
        } else {
          await api.schedule.updateCloudSchedule(scheduleId, agentId, crontabName, crontab, active, description, parameters, workspaces, timezone, host_tag, vuln_tag, service_tag, type);
          dispatch(trackEvent(CATEGORIES.schedule, ACTIONS.editSchedule.name, ACTIONS.editSchedule.label, scheduleId));
          dispatch(unSelectAll());
        }
        dispatch(getCloudSchedules());
      } else {
        if (scheduleId === 0) {
          const response = await api.schedule.createSchedule(agentId, crontabName, active, crontab, description, executorId, JSON.stringify(parameters), workspaces, timezone, host_tag, vuln_tag, service_tag);
          dispatch(trackEvent(CATEGORIES.schedule, ACTIONS.createSchedule.name, ACTIONS.createSchedule.label, response.id));
        } else {
          await api.schedule.updateSchedule(scheduleId, agentId, crontabName, active, crontab, description, executorId, JSON.stringify(parameters), workspaces, timezone, host_tag, vuln_tag, service_tag);
          dispatch(trackEvent(CATEGORIES.schedule, ACTIONS.editSchedule.name, ACTIONS.editSchedule.label, scheduleId));
          dispatch(unSelectAll());
        }
        dispatch(getSchedules());
      }
      dispatch(getCountSchedulers());
    } catch (e) {
      dispatch(somethingWentWrong(get(e, 'message', 'There was an error, please try again')));
    }
  };
}

export function clearError (section) {
  return async (dispatch) => {
    dispatch({ type: SCHEDULER_CLEAR_ERROR, section });
  };
}
