import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from 'Common/Components/Checkbox';
import { selectReportList, selectSelectedReports } from 'store/ExecutiveReport/selectors';
import { setAllReports, unselectAllReports } from 'store/ExecutiveReport/actions';

export const CheckReportHeader = () => { // eslint-disable-line import/prefer-default-export
  const reportsList = useSelector(selectReportList);
  const selectedReports = useSelector(selectSelectedReports);
  const dispatch = useDispatch();

  const allReportsSelected = reportsList.length === selectedReports.length;

  const handleClick = () => {
    if (allReportsSelected) {
      dispatch(unselectAllReports());
    } else {
      dispatch(setAllReports());
    }
  };

  return (
    <Checkbox theme="grey" onChange={ handleClick } state={ allReportsSelected } partialChecked={ selectedReports.length > 0 && !allReportsSelected } />
  );
};
