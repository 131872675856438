import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${({ theme }) => theme.backgrounds.actionBar};
  overflow: hidden;
`;
Wrapper.displayName = 'Wrapper';
