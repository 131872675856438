import styled from 'styled-components';
import colors from 'Styles/colors';
import { ellipsisText } from 'Styles/styles';
import { ReactComponent as LinkSVG } from 'Images/copy_link.svg';

export const Wrapper = styled.div`
  width: 100%;      
  background-color: ${colors.white};
  box-shadow: ${({ theme }) => theme.boxShadow.modal};
  max-height: 102px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    height: 20px;
  }
`;
Wrapper.displayName = 'Wrapper';

export const Item = styled.div`    
  height: 34px;
  width: 100%;
  padding: 7px 5px 7px 15px;
  display: flex;
  align-items: center;
  background-color: ${({ theme, isOdd }) => (isOdd ? `${theme.backgrounds.modal4}` : `${theme.backgrounds.modal3}`)};
`;
Item.displayName = 'Item';

export const LinkIcon = styled(LinkSVG)`
  & > path {
    fill: ${colors.blueCerulean};
  }
`;
LinkIcon.displayName = 'LinkIcon';

export const Text = styled.div`
  text-align: left;
  ${ellipsisText}
  font-size: 13.5px;
  font-weight: 500;
  color: ${({ theme }) => theme.text.sectionTitle};
`;
Text.displayName = 'Text';

export const Link = styled.a`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;
Link.displayName = 'Link';
