import styled from 'styled-components';
import { Title } from '../MarkdownInput/styled';
import Checkbox from 'Common/Components/Checkbox/styled';

export const Field = styled.div`
  width: 50%;
`;
Field.displayName = 'Field';

export const Wrapper = styled.div`
  overflow-y: auto;
  user-select: none;
  >:nth-child(2) {
    & ${Field} {
      margin-bottom: 13px;
    }
  }
`;
Wrapper.displayName = 'Wrapper';

export const AditionalText = styled.div`
  width: 220px;
  height: 36px;
  font-size: 13.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.67;
  letter-spacing: 0.1px;
  color: #afb1bc;
  margin-left: 20px;
`;
AditionalText.displayName = 'AditionalText';

export const Row = styled.div`
  display:flex;
  align-items: flex-start;
  & >:first-child {
    margin-right: 22px
  }
  ${({ disabled }) => disabled && `
    opacity: 0.5;
    pointer-events: none;
  `};
`;
Row.displayName = 'Row';

export const SwitchContainer = styled.div`
  display:flex;
  align-items: flex-start;
  margin: 20px 0px 20px 0px;
  & >:first-child {
    margin-right: 22px
  }
  ${({ disabled }) => disabled && `
    opacity: 0.5;
    pointer-events: none;
  `};
`;
Row.displayName = 'Row';

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 405px;
  gap: 20px;
`;
Column.displayName = 'Column';

export const ColumnCustom = styled.div`
  display: flex;
  flex-direction: column;
  width: 405px;
  max-height: 185px;
  padding-top: 1px;
  gap: 20px;
  overflow: hidden;
`;
ColumnCustom.displayName = 'ColumnCustom';

export const DoubleElement = styled.div`
  display: flex;
  flex-direction: column;
  width: 405px;
  padding-top: 1px;
  gap: 0.5px;
`;
DoubleElement.displayName = 'DoubleElement';

export const DoubleElementGap = styled.div`
  display: flex;
  flex-direction: column;
  width: 405px;
  max-height: 164px;
  padding-top: ${({ padding }) => padding || '3px'};
  gap: 9px;
`;
DoubleElementGap.displayName = 'DoubleElementGap';

export const Gap = styled.div`
  height: 20px;
`;

export const MethodTitle = styled(Title)`
  margin-top: 10px;
  gap: 15px;
`;
MethodTitle.displayName = 'MethodTitle';

export const Toggle = styled(Checkbox)`
  margin-top: 5px;
  & >:first-child {
    margin-right: 15px;
  }
`;
Toggle.displayName = 'Toggle';

export const ToggleTitle = styled.div`
  color: ${({ theme }) => theme.text.modalTitle2};
  font-weight: 600;
`;
ToggleTitle.displayName = 'ToggleTitle';
