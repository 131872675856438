import styled from 'styled-components';

const Wrapper = styled.div`
  height: 100%;
  padding-top: 8px;
  background-color: ${({ theme }) => theme.backgrounds.workspaces};
  overflow: hidden;
`;

export default Wrapper;
