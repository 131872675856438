import React from 'react';
import { EditIcon } from '../styled';

const Edit = ({ onClick }) => {
  return (
    <EditIcon onClick={ onClick } />
  );
};

export default Edit;
