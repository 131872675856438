import styled from 'styled-components';
import BlueButton from 'Common/Components/BlueButton';

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 17px;
  margin-bottom: 13px;
`;
Wrapper.displayName = 'Wrapper';

export const CancelButton = styled.button`
  border: ${({ theme }) => `1px solid ${theme.buttons.secondary.border}`};
  color: ${({ theme }) => theme.textfield.text};
  background-color: transparent;
  margin-right: 13px;
  font-size: 14.5px;
  padding: 5px 21px;
  border-radius: 3px;
  font-weight: 400;
  width: 89px;
  user-select: none;
`;
CancelButton.displayName = 'CancelButton';

export const CustomBlueButton = styled(BlueButton)`
  width: 89px;
  margin-right: 24px;
  user-select: none;
`;
CustomBlueButton.displayName = 'CustomBlueButton';
