/* eslint-disable no-useless-constructor */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Dropdown from 'Common/Components/Dropdown';
import { severityDropdownStyle } from 'Common/styles/style';
import { severities } from 'Screens/Constants';
import SeverityIcon from '../SeverityIcon';
import { Wrapper, SeverityName, Title } from './styled';
import { ThemeContext } from 'Context';

const SeverityItem = ({ title }) => (
  <div className="d-inline-flex item-option">
    <SeverityIcon type={ title.charAt(0).toUpperCase() }>{title.charAt(0).toUpperCase()}</SeverityIcon>
    <SeverityName>{title.charAt(0).toUpperCase() + title.slice(1).toLowerCase()}</SeverityName>
  </div>
);

SeverityItem.propTypes = {
  title: PropTypes.string.isRequired
};

const SeverityDropdown = ({ updateValue, defaultValue, context }) => {
  const { themeStyles } = useContext(ThemeContext);

  const onChange = (value) => {
    updateValue('severity', value);
  };

  const options = severities.map((element) => ({ label: <SeverityItem title={ element.desc } />, value: element.name }));
  return (
      <Wrapper context={ context }>
        <Title required>Severity</Title>
        <Dropdown customStyle={ severityDropdownStyle(themeStyles) } options={ options } updateValue={ onChange } placeholder="Severity" className="severity" defaultValue={ defaultValue } />
      </Wrapper>
  );
};

SeverityDropdown.propTypes = {
  updateValue: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  context: PropTypes.bool
};

SeverityDropdown.defaultProps = {
  defaultValue: undefined,
  context: false
};

export default withRouter(SeverityDropdown);
