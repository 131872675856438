import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as DownloadImage } from 'Images/download-dropdown.svg';

export const Wrapper = styled.div`
  visibility: ${({ keepVisible }) => keepVisible && 'visible !important'};
  position: relative;
  overflow: visible;
  cursor: pointer;
  display: flex;
  width: 37px;
  height: 37px;
  align-items: center;
  justify-content: center;
  margin-left: 7px;

  & > svg {
    cursor: pointer;
    min-width: 17px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.icons.actionBar.background};
    border-radius: 100%;
  }

  &:active {
    background-color: ${({ theme }) => theme.backgrounds.reportActiveActionButton};
    & > svg {
      g, path {
        fill: ${colors.white};

      }
    }
  }
`;
Wrapper.displayName = 'Wrapper';

export const Dropdown = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  visibility: visible !important;
  position: absolute;
  z-index: 3;
  top: 38px;
  left: 0;
  min-height: 54px;
  max-height: 110px;
  width: 257px;
  border-radius: 2px;
  box-shadow: 0 2px 20px 0 ${({ theme }) => theme.shadows.reportsDownload};;
  border: solid 1px ${({ theme }) => theme.borders.reportsDownload};
  background-color: ${({ theme }) => theme.backgrounds.dropdown1Options};
  `;
Dropdown.displayName = 'Dropdown';

export const Backdrop = styled.div`
  cursor: pointer;
  visibility: visible !important;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
`;
Backdrop.displayName = 'PopupBackdrop';

export const DownloadIcon = styled(DownloadImage)`
    margin: auto;
`;
DownloadIcon.displayName = 'DownloadIcon';
