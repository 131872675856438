import styled from 'styled-components';

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  width: 60px;
  height: 30px;
  background-color: ${({ theme }) => theme.backgrounds.toggleSwitch};
  border: ${({ theme }) => `1px solid ${theme.backgrounds.expander}`};
  border-radius: 15px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
`;

export const Handle = styled.div`
  position: absolute;
  top: 2px;
  left: ${({ checked }) => (checked ? '32px' : '2px')};
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${({ theme }) =>
    theme.backgrounds.pipelineBox};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 8px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  pointer-events: none;
  color: ${({ checked }) => (checked ? '#b3b3b3' : '#666666')};
`;
