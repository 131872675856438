import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 360px;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  height: 17px;
  text-align: left;
  user-select: none;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8f94b2;
`;
Title.displayName = 'Title';

export const Content = styled.div`
  border-radius: 3px;
  box-shadow: 0 2px 15px 0 ${({ theme }) => theme.shadows.reportsBox};
  border: solid 1px ${({ theme }) => theme.borders.reportsBox};
  background-color: ${({ theme }) => theme.backgrounds.reportsBox};
  padding: 14px 16px 13px 20px;
  display: flex;
`;
Content.displayName = 'Content';

export const Thumbnail = styled.div`
  width: 110px;
  height: 110px;
  margin-right: 21px;
`;
Thumbnail.displayName = 'Thumbnail';

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
Right.displayName = 'Right';

export const ReportTitle = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: ${({ theme }) => theme.text.memoListItemTitle};
  word-break: break-all;
`;
ReportTitle.displayName = 'ReportTitle';

export const Buttons = styled.div`
  display: flex;
`;
Buttons.displayName = 'Buttons';

export const BlueButton = styled.button`
  width: 128px;
  height: 34px;
  border-radius: 3px;
  border: none;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: ${({ disabled, theme }) => (disabled ? `${theme.buttons.disabled.color}` : `${theme.buttons.primary.color}`)};
  margin-right: 10px;
  background-color: ${({ disabled, theme }) => (disabled ? `${theme.buttons.disabled.background}` : `${theme.buttons.primary.background}`)};
  :hover {
    background-color: ${({ disabled, theme }) => (disabled ? `${colors.veryLightPink}` : `${theme.buttons.primary.background}`)};
  }
  :active {
    background-color: ${({ disabled, theme }) => (disabled ? `${colors.veryLightPink}` : `${theme.buttons.primary.background}`)};
  }
`;
BlueButton.displayName = 'BlueButton';

export const SelectedTemplate = styled.div`
  color: #1c2243;
  font-size: 14.5px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  letter-spacing: normal;
  line-height: normal;
  margin: auto auto auto 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  user-select: none;
`;
SelectedTemplate.displayName = 'SelectedTemplate';
