import styled from 'styled-components';
import colors from 'Styles/colors';
import DebouncedInput from 'Common/Components/v2/DebouncedInput';
import { Input } from 'Common/Components/StandardTextField/styled';

export const Wrapper = styled.div`
`;
Wrapper.displayName = 'Wrapper';

export const Content = styled.div`
  display: flex;
  flex-flow: wrap;
  & > * {
    width: calc(92% / 2);
  }
`;
Content.displayName = 'Content';

export const CustomInput = styled(DebouncedInput)`
  span {
    font-weight: 400;
    margin-right: 3px;
  }
  ${Input} {
    &::placeholder,
    &::-webkit-input-placeholder {
      font-size: 14.5px;
      color: ${({ theme }) => theme.text.taskPlaceholder};
    }
  }
`;
CustomInput.displayName = 'CustomInput';

export const Url = styled(CustomInput)`
  margin-right: 32px;
  width: 267px;
`;
Url.displayName = 'Url';

export const Project = styled(CustomInput)`
  margin-right: 10px;
  width: 267px;
`;
Project.displayName = 'Project';

export const AccessToken = styled(CustomInput)`
  margin-right: 10px;
  width: 267px;
`;
AccessToken.displayName = 'AccessToken';

export const WrapperCustomField = styled.div`
  margin-right: 10px;
  padding: 10px 0;
  display: flex;
`;
WrapperCustomField.displayName = 'WrapperCustomField';

export const Label = styled.div`
  font-size: 13.5px;
  line-height: 1.12;
  font-weight: 400;
  color: ${({ theme }) => theme.textfield.text};
  padding-bottom: 20px;
  margin-right: 17px;
  ${({ mandatory }) => mandatory && `
    position: relative;
    &:after {
      content: '*';
      color: ${colors.redPink};
      position: absolute;
      top: 0px;
      margin-left: 3px;
    }
  `}  
`;
Label.displayName = 'Label';
