import styled from 'styled-components';
import colors from 'Styles/colors';
import { slideIn } from 'Styles/effects';
import { Wrapper } from 'Common/Components/Contextualization/InputFilter/components/DataContainer/styled';
import { WorkspaceRemoveIcon } from 'Screens/Contextualization/Users/components/CreationModal/styled';
import StandardTextField from 'Common/Components/StandardTextField';
import { Texts } from 'Common/Components/StandardTextField/styled';

export const Modal = styled.div`
  border-radius: 8px 8px 2px 2px;
  box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.24);
  border: solid 1px ${({ theme }) => theme.backgrounds.modal};
  background-color: ${({ theme }) => theme.backgrounds.modal};
  border-top: 10px solid #419bf9;
  padding: 35px;
  text-align: left;
  width: 500px;
  margin-right: 5px;
  ${slideIn};
  max-height: 80%;
  height: 710px;
  
  .modal-dialog {
    margin: 0;
  }

  .modal-content {
    padding: 34px 30px;
  }
`;
Modal.displayName = 'Modal';

export const Title = styled.div`
  font-size: 21.5px;
  font-weight: 600;
  display: inline-block;
  max-width: 250px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${({ theme }) => theme.text.modalTitle2};
`;
Title.displayName = 'Title';

const Button = styled.button`
  width: 89px;
  height: 34px;
  border-radius: 2px;
  float: right;
  font-size: 14px;
  font-weight: 500;
`;

export const CancelButton = styled(Button)`
  margin-left: auto;
  border: solid 1px ${colors.dark6};
  color: ${({ theme }) => theme.text.modalTitle};
  background-color: ${({ theme }) => theme.backgrounds.modal};
  margin-right: 8px;
  float: none;
`;
CancelButton.displayName = 'CancelButton';

export const CreateButton = styled(Button)`
  background-color: ${(props) => (props.disabled ? colors.grey20 : colors.softBlue)};
  color: white;
  border: 0;
`;
CreateButton.displayName = 'CreateButton';

export const Header = styled.div`
  display: flex;
  align-items: center;
  & > *:first-child {
    margin-right: 18px;
  }
`;
Header.displayName = 'Header';

export const Body = styled.div`
  margin-top: 21px;
  padding-right: 8px;
  height: 95%;
  overflow-y: auto;
`;
Body.displayName = 'Body';

export const TopRow = styled.div`
  display: flex;
  width: 100%;
`;
TopRow.displayName = 'TopRow';

export const FieldTitle = styled.div`
  font-size: 12.5px;
  color: ${colors.grey17};
  line-height: 1;
  letter-spacing: 0.09px;
  color: #afb1bc;
  margin-bottom: 5px;
`;
FieldTitle.displayName = 'FieldTitle';

export const FieldTitleDescription = styled(FieldTitle)`
  margin-bottom: 5px;
`;
FieldTitleDescription.displayName = 'FieldTitleDescription';

export const Field = styled.div`
  display: inline-block;
  width: 50%;
  margin-left: ${(props) => (props.index > 0 ? '21px' : '0')};
`;
Field.displayName = 'Field';

export const FieldDescription = styled(Field)`
  width: 100%;
  margin-top: 21px;
  margin-bottom: 5px;

  textarea {
    overflow: hidden;
    resize: none;
  }
`;
FieldDescription.displayName = 'FieldDescription';

export const FieldScope = styled(Field)`
  width: 100%;
  margin-top: 16px;
`;
FieldScope.displayName = 'FieldScope';

export const CheckboxContainer = styled.div`
`;
CheckboxContainer.displayName = 'CheckboxContainer';

export const Checkbox = styled.input`
  background-color: ${colors.grey18};
  vertical-align: middle;
  display: inline-block;
  margin-bottom: 2px;
  margin-right: 8px;
`;
Checkbox.displayName = 'Checkbox';

export const CheckboxText = styled.div`
  display: inline-block;
  font-size: 13.5px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;

  svg {
    margin-left: 8px;
    height: 15px;
    width: 15px;
    color: ${colors.grey21}
  }
`;
CheckboxText.displayName = 'CheckboxText';

export const SecondTitle = styled.div`
  font-size: 14.5px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  margin-top: 21px;
`;
CheckboxText.displayName = 'CheckboxText';

export const SubTitle = styled.div`
  font-size: 12.5px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  margin-top: 5px;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.text.blueSubtitle};
`;
SubTitle.displayName = 'SubTitle';

export const UserSelectorWraper = styled.div`
  input {
    width: 436px;
  }

  ${Wrapper} {
    width: 436px;
    max-height: 125px;
    overflow-x: hidden;
  }
`;
UserSelectorWraper.displayName = 'UserSelectorWraper';

export const UserSelect = styled.div`
  max-height: 102px;
  border-radius: 3px;
  border: solid 1px ${({ theme }) => theme.borders.assignedUserList};
  background-color: ${({ theme }) => theme.backgrounds.workspaceList};
  margin-top: 21px;
  overflow-y: scroll;
  overflow-x: hidden;
`;
UserSelect.displayName = 'UserSelect';

export const TextAreaContainer = styled.div`
  width: 100%;
  div {
    width: 100%;
    margin-bottom: 0px;
  }
`;
TextAreaContainer.displayName = 'TextAreaContainer';

export const ErrorMessage = styled.div`
  height: 12px;
  margin-top: 15px;
  color: ${colors.redPink};
  font-size: 10.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.08px;
`;
ErrorMessage.displayName = 'ErrorMessage';

export const UserRow = styled.div`
  height: 33px;
  width: 430px;

  &:hover {
    background-color: ${colors.iceBlue};
    background-color: ${({ theme }) => theme.backgrounds.modal};

    svg {
      color: ${colors.blue8};
    }
  }
`;
UserRow.displayName = 'UserRow';

const Row = styled.div`
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-stretch: normal;
  font-style: normal;
  font-size: 12.5px;
  padding-top: 8px;
`;
Row.displayName = 'Row';

export const NameRow = styled(Row)`
  width: 200px;
  font-weight: 300;
  color: ${({ theme }) => theme.text.modalTitle};
  padding-left: 20px;
`;
NameRow.displayName = 'NameRow';

export const RoleRow = styled(Row)`
  width: 150px;
  font-size: 12.5px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  white-space: nowrap;
  color: ${({ role }) => {
    if (role === 'admin') return colors.warmPurple;
    if (role === 'pentester') return colors.redPink;
    if (role === 'client') return colors.blueCerulean;
    if (role === 'asset_owner') return colors.softBlue;
    return colors.softBlue;
  }};
`;
RoleRow.displayName = 'RoleRow';

export const IconRow = styled(WorkspaceRemoveIcon)`
  margin-right: 22px;
  & svg {
    color: ${({ theme }) => theme.icons.actionBar.color} !important;
  }
`;
IconRow.displayName = 'IconRow';

export const TextField = styled(StandardTextField)`
  width: ${(props) => (props.fullwidth ? '100%' : '50%')};
  height: min-content;
  
  span {
    font-weight: 400;
    line-height: 1;
    margin-bottom: 0px;
  }

  input {
    border-bottom: 1px solid transparent;
    box-shadow: ${(props) => (props.error ? '0 1px 0 0 #ea3158' : 'none')};
    margin-top: 5px;
    background-color: ${({ theme }) => theme.textfield.background};
    color: ${({ theme }) => theme.textfield.text};
    &:focus {
      background-color: ${({ theme }) => theme.textfield.focus};
    }
  }

  span:not(:first-child) {
    margin-top: 4px;
  }
  svg {
    top: 28px;
  }

  ${Texts} {
    height: min-content;
  }
`;
TextField.displayName = 'TextField';

export const TextSpan = styled.span`
font-size: 12.5px;
color: ${({ theme }) => theme.text.modalTitle2};
margin-left: 10px;
line-height: 1;
height: fit-content;
font-weight: 300;
`;
TextSpan.displayName = 'TextSpan';

export const Flex = styled.div`
  display: flex;
  justify-content: ${({ justify }) => justify || 'flex-start'};
  align-items: ${({ align }) => align || 'flex-start'};
`;

Flex.displayName = 'Flex';
