import React from 'react';
import PropType from 'prop-types';
import {
  TextArea, Title, Wrapper, Asterisk, Texts, ErrorMessage, Warning
} from './styled';

const StandardTextAreaField = ({
  value, name, disabled, onChange, placeholder, title, mandatory, error, errorMessage, className, boldTitle
}) => (
  <Wrapper className={ className }>
    <Texts>
      {title && <Title boldTitle={ boldTitle }>{title}</Title>}
      {mandatory && <Asterisk>*</Asterisk>}
    </Texts>
    <TextArea
      resize="false"
      name={ name }
      value={ value }
      disabled={ disabled }
      onChange={ (e) => onChange(e.target.value) }
      isEmpty={ value?.length === 0 }
      placeholder={ placeholder }
      error={ error }
    />
    {error && <Warning />}
    {error && <ErrorMessage>{ errorMessage }</ErrorMessage>}
  </Wrapper>
);

StandardTextAreaField.defaultProps = {
  disabled: false,
  placeholder: '',
  mandatory: false,
  error: false,
  className: '',
  boldTitle: false
};

StandardTextAreaField.propTypes = {
  value: PropType.string.isRequired,
  name: PropType.string.isRequired,
  disabled: PropType.bool,
  onChange: PropType.func.isRequired,
  placeholder: PropType.string,
  mandatory: PropType.bool,
  error: PropType.bool,
  className: PropType.string,
  boldTitle: PropType.bool
};

export default StandardTextAreaField;
