import styled from 'styled-components';
import colors from 'Styles/colors';
import { slideIn } from 'Styles/effects';
import StringField from 'Common/Components/StringField';
import { Accept, Cancel } from 'Common/Components/Button/styled';
import { Title as InputTitle, CustomInput as Input } from 'Common/Components/StringField/styled';

export const ModalContent = styled.div`
  padding: 34px;
  box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.24);
  border-radius: 2px;
  border-top: 10px solid ${colors.softBlue};
  background: ${({ theme }) => theme.backgrounds.modal};
  width: 505px;
  ${slideIn}
`;
ModalContent.displayName = 'ModalContent';

export const CustomInput = styled(StringField)`
  ${InputTitle} {
    font-weight: 400;
    color: #afb1bc;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-right: 0px;
  }
  ${Input} {
    width: 100%;
  }
`;
CustomInput.displayName = 'CustomInput';

export const Url = styled(CustomInput)`
margin-bottom: 0;
`;
Url.displayName = 'Url';

export const ConfirmButton = styled(Accept)`
    //margin-left: 10px;
    width: fit-content;
    margin: ${({ margin }) => (margin || '')};
`;
ConfirmButton.displayName = 'ConfirmButton';

export const CancelButton = styled(Cancel)`
`;
CancelButton.displayName = 'CancelButton';

export const Title = styled.h3`
  font-size: 21.5px;
  font-weight: 500;
  margin-bottom: 25px;
  color: ${({ theme }) => theme.text.modalTitle2}
`;
export const SubTitle = styled.h4`
font-size: 14.5px;
font-weight: 400;
color: #7A7E8F;
margin-bottom: 15px;
`;

export const ButtonContainer = styled.div`
align-self: flex-end;
margin-top: 16px;
`;

export const Content = styled.div`
display: flex;
flex-direction: column;
`;

export const Code = styled.code`
font-size: 11px;
line-height: 1.42;
color: #1c2243;
word-break: break-all;
padding: 13px 0;
padding-right: 21px;
`;

export const CodeWrapper = styled.div`
  background:#edf2f7;
  border: solid 1px #bec8d2;
  display: flex;
  align-items: center;
  padding-left: 21px;
  margin-right: 20px;
  margin-bottom: 20px;
`;
export const Section = styled.div`
  margin: 16px 0;
`;

export const Flex = styled.div`
  display:flex;
  align-items: flex-end;
`;
