import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from 'Common/Components/Checkbox';
import { allGraphsAreSelected, selectGraphs, selectGraphsSelected, someGraphIsSelected } from 'store/Analytics/selectors';
import { selectAllGraph, unSelectAll } from 'store/Analytics/actions';

export const CheckHeader = () => { // eslint-disable-line import/prefer-default-export
  const allGraphSelected = useSelector(allGraphsAreSelected);
  const someGraphSelected = useSelector(someGraphIsSelected);
  const graphs = useSelector(selectGraphs);
  const count = graphs.length;
  const graphsSelected = useSelector(selectGraphsSelected);
  const dispatch = useDispatch();
  const allGraphsSelected = someGraphSelected && (count === graphsSelected.length);

  const handleClick = () => {
    if (allGraphsSelected) {
      dispatch(unSelectAll());
    } else {
      dispatch(selectAllGraph());
    }
  };

  return (
    <Checkbox theme="grey" onChange={ handleClick } state={ allGraphSelected } partialChecked={ someGraphSelected && !allGraphSelected } />
  );
};
