import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  margin-bottom: 3px;
  width: 100%;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  font-size: 12.5px;
  font-weight: 400;
  margin-bottom: 1px;
  color: ${colors.grey17};
`;
Title.displayName = 'Title';

export const Asterisk = styled.span`
  font-size: 12.5px;
  line-height: 2.08;
  color: ${colors.redPink};
  margin-bottom: 1px;
`;
Asterisk.displayName = 'Asterisk';

export const Input = styled.input`
  position: relative;
  padding: 5px 5px 5px 15px;
  font-size: 14.5px;
  border: none;
  background-color: ${({ theme }) => theme.backgrounds.modal};
  color: ${({ theme }) => theme.textfield.text};
  width: 100%;
  height: 34px;

  &::-webkit-inner-spin-button {
    z-index: 1;
  }

 &::-webkit-clear-button {
    z-index: 1;
 }
`;
Input.displayName = 'Input';

export const Container = styled.div`
  z-index: 2;
  .react-datepicker {
    background-color: ${({ theme }) => theme.backgrounds.modal};
    border: none;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  .react-datepicker__header {
    background-color: ${({ theme }) => theme.backgrounds.modal2};
    color: ${({ theme }) => theme.text.modalTitle2};
    border-bottom: none;
    padding: 10px 0;
  }
  .react-datepicker__day,
  .react-datepicker__day-name {
    color: ${({ theme }) => theme.text.modalTitle2};
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: blue;
    color: white;
  }
  .react-datepicker__day:hover {
    background-color: lightblue;
    color: white;
  }
  .react-datepicker__day:hover {
    background-color: lightblue;
    color: darkblue;
  }
  .react-datepicker__current-month {
    color: ${({ theme }) => theme.text.modalTitle2};
  }
  .react-datepicker__input-container input {
    width: 157.5px;
    height: 34px;
    padding: 5px 10px;
    font-size: 14px;
    border: none;
    background-color: ${({ theme }) => theme.textfield.background};
    color: ${({ theme }) => theme.textfield.text};
  }
`;
