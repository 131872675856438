import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import colors from 'Styles/colors';

// eslint-disable-next-line import/prefer-default-export
export const HeaderWrapper = styled.div`
  display: inline-flex;
  margin-bottom: 34px;
`;
HeaderWrapper.displayName = 'HeaderWrapper';

export const BodyWrapper = styled.div`
  
`;
BodyWrapper.displayName = 'BodyWrapper';

export const RowContainer = styled.div`
  margin-bottom: 10px;
`;
RowContainer.displayName = 'RowContainer';

export const Title = styled.span`
  font-size: 21.5px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${({ theme }) => theme.text.modalTitle2};
`;
Title.displayName = 'Title';

export const BtnsWrapper = styled.div`
  display: inline-flex;
  margin-left: auto;
`;
BtnsWrapper.displayName = 'BtnsWrapper';

export const CancelButton = styled.div`
  width: 89px;
  height: 34px;
  border-radius: 2px;
  border: solid 1px ${({ theme }) => theme.buttons.custom.border};
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${({ theme }) => theme.buttons.custom.color};
  padding: 7px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.buttons.custom.background};
  margin-right: 8px;
`;
CancelButton.displayName = 'CancelButton';

export const CreateBtn = styled.div`
  width: 89px;
  height: 34px;
  border-radius: 2px;
  background-color:  ${(props) => (props.disabled ? '#ebeff3' : props.theme.buttons.custom.background)};    
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${(props) => (props.disabled ? props.theme.buttons.custom.disableColor : props.theme.buttons.custom.color)};
  padding: 8px;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};;
`;
CreateBtn.displayName = 'CreateBtn';

export const SectionTitle = styled.div`
  font-size: 14.5px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${({ theme }) => theme.text.modalTitle2};
  margin-top: 14px;

`;
SectionTitle.displayName = 'SectionTitle';

export const SubTitle = styled.div`
  font-size: 12.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #63758d;
  margin-top: 12px;
  margin-bottom: 21px;
`;
SubTitle.displayName = 'SubTitle';

export const WorkspaceList = styled.div`
  margin-top: 21px;
  width: 430px;
  height: 104px;
  border: solid 1px ${({ theme }) => theme.borders.actionDropDown};
  overflow-y: auto;
  overflow-x: hidden;
`;
WorkspaceList.displayName = 'WorkspaceList';

export const WorkspaceItem = styled.div`
  width: 428px;
  height: 33px;
  background-color: ${({ theme }) => theme.backgrounds.actionDropDown};
  padding: 0 20px;
  display: table;
  color: ${({ theme }) => theme.text.modalTitle2};
  &:hover {
    background-color: ${({ theme }) => theme.backgrounds.actionDropDownHover};
  }
`;
WorkspaceItem.displayName = 'WorkspaceItem';

export const WorkspaceName = styled.div`
  font-size: 12.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.64;
  letter-spacing: normal;
  color: ${({ theme }) => theme.text.actionDropDown};
  display: contents;
`;
WorkspaceName.displayName = 'WorkspaceName';

export const WorkspaceStats = styled.div`
  font-size: 12.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.72;
  letter-spacing: normal;
  color: #90a9c0;
  float: right;
`;
WorkspaceStats.displayName = 'WorkspaceStats';

export const WorkspaceCriticalStats = styled.div`
  font-size: 12.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.64;
  letter-spacing: normal;
  color: ${colors.warmPurple};
  float:right;
  margin-left: 21px;
`;
WorkspaceCriticalStats.displayName = 'WorkspaceCriticalStats';

export const WorkspaceRemoveIcon = styled.div`
  color: ${({ theme }) => theme.icons.actionBar.color} !important;
  float: right;
  margin-left: 21px;
  position: relative;
  top: 4px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  padding: 0px 5px;
  cursor: pointer;
  svg {
    color: ${({ theme }) => theme.icons.actionBar.color} !important;
  }
  &:hover {
    background-color: #90a9c0;
    svg {
      color: ${({ theme }) => theme.icons.actionBar.color} !important;
    }
  }
`;
WorkspaceRemoveIcon.displayName = 'WorkspaceRemoveIcon';

export const PasswordErrorMessage = styled.div`
  font-size: 12.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ea3158;
`;
PasswordErrorMessage.displayName = 'PasswordErrorMessage';

export const ModalWrapper = styled.div`
  #modal_creation_user {
    background-color: ${({ theme }) => theme.backgrounds.modal} !important;
  }
`;
ModalWrapper.displayName = 'ModalWrapper';

export const CustomModal = styled(Modal)`
  background-color: ${({ theme }) => theme.backgrounds.modal} !important;
`;
CustomModal.displayName = 'CustomModal';
