import styled from 'styled-components';
import { ReactComponent as UsersIcon } from 'Images/icon_users.svg';

const Users = styled(UsersIcon).attrs({
  id: 'qa-users-ws'
})`
  cursor: ${({ disabled }) => { if (disabled) return 'not-allowed !important'; else return 'pointer'; }};
  & > g {
    & path {
      fill: transparent !important;
    }
    & :last-child {
      fill: ${({ theme }) => theme.icons.actionBar.color} !important;
    }
  }
`;

Users.displayName = 'Users';

export default Users;
