export const SCHEDULE_TABLE_SELECT_ROW = 'SCHEDULE_TABLE_SELECT_ROW';
export const SCHEDULED_AGENTS_GET_DATA = 'SCHEDULED_AGENTS_GET_DATA';
export const SCHEDULE_FETCHING = 'SCHEDULE_FETCHING';
export const SCHEDULE_FAIL = 'SCHEDULE_FAIL';
export const SCHEDULE_SET_PAGE = 'SCHEDULE_SET_PAGE';
export const SCHEDULE_TABLE_SORT_DATA = 'SCHEDULE_TABLE_SORT_DATA';
export const RUN_SCHEDULE = 'RUN_SCHEDULE';
export const SCHEDULE_SET_TAGS_ENTITY = 'SCHEDULE_SET_TAGS_ENTITY';
export const SCHEDULE_RESET_TAGS_FIELDS = 'SCHEDULE_RESET_TAGS_FIELDS';

export const SCHEDULE_SET_SECTION = 'SCHEDULE_SET_SECTION';
export const SCHEDULE_SET_IS_CREATE = 'SCHEDULE_SET_IS_CREATE';
export const SCHEDULER_SELECTED = 'SCHEDULER_SELECTED';
export const SCHEDULER_UNSELECTED = 'SCHEDULER_UNSELECTED';
export const SCHEDULER_NEW_PIVOT = 'SCHEDULER_NEW_PIVOT';
export const SELECT_ALL_SCHEDULER = 'SELECT_ALL_SCHEDULER';
export const UNSELECT_ALL_SCHEDULER = 'UNSELECT_ALL_SCHEDULER';
export const SCHEDULED_CLOUD_AGENTS_GET_DATA = 'SCHEDULED_CLOUD_AGENTS_GET_DATA';
export const SCHEDULER_CLEAR_ERROR = 'SCHEDULER_CLEAR_ERROR';
export const GET_SCHEDULERS_COUNT_SUCCESS = 'GET_SCHEDULERS_COUNT_SUCCESS';
export const SCHEDULE_RESET_STATE = 'SCHEDULE_RESET_STATE';
