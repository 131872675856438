import styled from 'styled-components';
import { ReactComponent as _CopyApiLink } from 'Images/api_link.svg';

export const CopyApiLinkWrapper = styled.div`
  position: relative;
  cursor: pointer;
  width: 34px;
  height: 34px;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(28, 34, 67, 0.03);
  border: solid 1px ${({ theme }) => theme.borders.detailModalHeaderIcons};
  background-color: ${({ theme }) => theme.backgrounds.detailModalHeaderIcons};
  &:hover {
    background-color: #f1f4f7 ;
  }
  &:active{
    background-color: #63758d ;
    & > svg {
      & > g {
        fill: #fff;
      }
    }
  }
  
`;
CopyApiLinkWrapper.displayName = 'CopyApiLink';

export const CopyApiLinkIcon = styled(_CopyApiLink)`
  position: absolute;
  cursor: pointer;
  left: 6px;
  top: 6px;
  path {
    fill: ${({ theme }) => theme.icons.detailModal.color};
    stroke: ${({ theme }) => theme.icons.detailModal.color};
    stroke-width: 0.5;
  }
`;
CopyApiLinkIcon.displayName = 'CopyApiLinkIcon';
