import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  padding: 20px 0 30px 0;
`;
Wrapper.displayName = 'Wrapper';

export const Content = styled.div`
  padding: 0;
  & > *:not(:last-child) {
    padding-bottom: 20px;
  }
`;
Content.displayName = 'Content';

export const Label = styled.div`
  font-size: 12.5px;
  line-height: 1.12;
  font-weight: 400;
  color: ${colors.grey17};
  padding-bottom: 20px;
  display: inline;
`;
Label.displayName = 'Label';

export const WrapperTemplate = styled.div`
  width: calc(92% / 2);
`;
WrapperTemplate.displayName = 'WrapperTemplate';

export const Title = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.textfield.text};
  margin-bottom: 12px;
`;
Title.displayName = 'Title';
