import styled from 'styled-components';
import colors from 'Styles/colors';

export const Title = styled.div`
  font-size: 21.5px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.16;
  letter-spacing: normal;
  text-align: left;
  color: ${({ theme }) => theme.text.loginCard};
`;
Title.displayName = 'Title';

export const SubTitle = styled.div`
  margin-top: 21px;
  margin-bottom: 30px;
  font-size: 12.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: left;
  color: ${colors.blueGrey}
`;
SubTitle.displayName = 'SubTitle';

export const ButtonsContainer = styled.div`
  
`;
ButtonsContainer.displayName = 'ButtonsContainer';

export const BtnResetLink = styled.button`
    height: 34px;
    margin: 22px 191px 0 0px;
    border-radius: 2px;
    background-color: ${(props) => (props.disabled ? props.theme.buttons.disabled.background : props.theme.buttons.primary.background)};
    font-size: 12.5px;
    font-weight: 500;
    line-height: 34px;
    text-align: center;
    color: #ffffff;
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
    border: 0;
    width: 145px;

    &:hover {
        ${({ disabled }) => !disabled && `background-color: ${colors.blue21}`};
    }

    &:active {
        background-color: ${(props) => (props.disabled ? '' : colors.softBlue)};
    }
`;
BtnResetLink.displayName = 'BtnResetLink';

export const BtnEmailSent = styled.div`
    background-color: ${colors.green1};
    height: 34px;
    margin: 22px 191px 0 0px;
    border-radius: 2px;
    font-size: 12.5px;
    font-weight: 500;
    line-height: 34px;
    text-align: center;
    color: #ffffff;
    cursor: default;
    border: 0;
    width: 145px;
`;
BtnEmailSent.displayName = 'BtnEmailSent';

export const BtnGoBack = styled.div`
    font-size: 12.5px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    cursor: pointer;
    color: ${colors.blueCerulean};
    width: 68px;
    margin-top: 41px;
`;
BtnGoBack.displayName = 'BtnGoBack';

export const ErrorMessage = styled.div`
  color: ${colors.redPink};
  letter-spacing: 0.08px;
  font-size: 10.5px;
  text-align: left;
`;
ErrorMessage.displayName = 'ErrorMessage';
