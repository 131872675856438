import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import { useIntl } from 'react-intl';
import { selectGraphsSelected } from 'store/Analytics/selectors';
import IconButton from 'Common/Components/IconButton';
import ModalWarning from 'Common/Components/ModalWarning';
import { setGraphId } from 'store/Analytics/actions';
import { openModal } from 'store/modals/actions';
import { MODAL_ANALYTICS_CONFIRM_DELETE } from 'store/modals/modals';
import { TrashIcon } from '../styled';

const Delete = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [showWarning, setShowWarning] = useState(false);
  const graphSelected = useSelector(selectGraphsSelected);
  const graphId = get(graphSelected, '[0].id', 0);
  const graphName = get(graphSelected, '[0].name', '');
  const message = intl.formatMessage({ id: 'modalWarning.selectOnlyOne' }, { entity: 'Graph' });
  const remove = intl.formatMessage({ id: 'analytics.options.remove' });
  const handleClick = () => {
    if ((graphSelected.length === 1)) {
      dispatch(setGraphId(graphId, graphName));
      dispatch(openModal(MODAL_ANALYTICS_CONFIRM_DELETE));
    } else setShowWarning(true);
  };

  const onClose = () => setShowWarning(false);
  return (
    <>
      <IconButton icon={ <TrashIcon /> } title={ remove } onClick={ handleClick } />
      { showWarning && <ModalWarning onCloseCallback={ onClose } title="Delete Graph" description={ message } /> }
    </>
  );
};

export default Delete;
