import styled from 'styled-components';
import colors from 'Styles/colors';
import { Title } from '../ExpandableWrapper/styled';
import { ReactComponent as TrashIcon } from 'Images/tool_trash.svg';

const sevColor = {
  critical: colors.warmPurple,
  high: colors.redPink,
  medium: colors.tangerine,
  low: colors.apple,
  disable: colors.blueGrey
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 20px 26px 20px;
`;
Wrapper.displayName = 'Wrappper';

export const Button = styled.button`
  border: none;
  background-color: ${({ theme }) => theme.backgrounds.detailModalFields};
  font-size: 13px;
  color: ${({ theme }) => theme.text.detailModalFields};
  margin-left: 17px;
  display: flex;
  align-items: center;
`;
Button.displayName = 'Button';

export const Arrow = styled.span`
  font-size: 8px;
  margin-left: 5px;
`;
Arrow.displayName = 'Arrow';

export const Header = styled.div`
  display: flex;
  align-items: baseline;
  padding: 20px 0 23px 0;
`;
Header.displayName = 'Header';

export const ResultsWrapper = styled.div`

`;
ResultsWrapper.displayName = 'ResultsWrapper';

export const Results = styled.div`
  display: flex;
`;
Results.displayName = 'Results';

export const ResultsTitle = styled.h6`
  font-size: 13px;
  font-weight: 600;
  color: ${({ theme }) => theme.text.detailModalFields};
  margin-bottom: 10px;
`;
ResultsTitle.displayName = 'ResultsTitle';

export const Score = styled.div`
  height: 34px;
  width: 107px;
  background-color: ${({ severity }) => severity ? sevColor[severity] : sevColor.disable};
  color: ${({ theme }) => theme.text.detailModalFields};
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  padding: 7px 0;
  margin-right: 13px;
  text-transform: capitalize;
`;
Score.displayName = 'Score';

export const Remove = styled(TrashIcon)`
  path {
    width: 16px;
    height: 17px;
  }
`;
Remove.displayName = 'Remove';

export const IconWrapper = styled.div`
  position: absolute;
  right: 13px;
  top: 5px;
  opacity: 0;
  pointer-events: none;
  ${({ fullVector }) => fullVector && `
      opacity: 1;
      pointer-events: all;
  `}
`;
IconWrapper.displayName = 'IconWrapper';

export const Vector = styled.div`
  max-height: max-content;
  width: calc(100% - 120px);
  background-color: ${({ theme }) => theme.textfield.background2};
  border: ${({ theme }) => `solid 1px ${theme.borders.importanceMeter}`};
  color: ${({ theme }) => theme.textfield.text};
  font-size: 14.5px;
  letter-spacing: 0.11px;
  padding: 7px 55px 6px 13px;
  position: relative;
  word-wrap: break-word;
  line-height: 1.2;
  ${({ disable }) => disable && `
    opacity: 0.31;
  `}
`;
Vector.displayName = 'Vector';

export const CVSS2Title = styled(Title)`
  margin: 25px 0 20px 0;
`;
CVSS2Title.displayName = 'CVSS2Title';
