import styled from 'styled-components';
import { slideIn } from 'Styles/effects';
import colors from 'Styles/colors';
import { Cancel } from 'Common/Components/Button/styled';
import BlueButton from 'Common/Components/BlueButton';
import { ReactComponent as Help } from 'Images/noun-help.svg';

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0 0 34px;
  box-shadow: ${({ theme }) => theme.boxShadow.modal};
  border-radius: 2px;
  border-top: 10px solid ${colors.softBlue};
  background: ${({ theme }) => theme.backgrounds.modal};
  width: 587px;
  user-select: none;
  height: 95%;
  ${slideIn}
`;
ModalContent.displayName = 'ModalContent';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 34px;
`;
Header.displayName = 'Header';

export const Buttons = styled.div`
`;
Buttons.displayName = 'Buttons';

export const SaveButton = styled(BlueButton)`
`;
SaveButton.displayName = 'SaveButton';

export const CancelButton = styled(Cancel)`
  margin-right: 10px;
`;
CancelButton.displayName = 'CancelButton';

export const Title = styled.div`
  color: ${({ theme }) => theme.text.modalTitle};
  font-weight: 600;
  font-size: 21.5px;
  line-height: 1.49;
`;
Title.displayName = 'Title';

export const Subtitle = styled.div`
  color: ${({ theme }) => theme.text.modalTitle2};
  font-size: 12.5px;
  line-height: 1.44;
  margin-top: 26px;
  
`;
Subtitle.displayName = 'Subtitle';

export const DocLink = styled.a`
  cursor: pointer;
  font-size: 12.5px;
  font-weight: 500;
  color: ${colors.blueCerulean};
  margin-left: 3px;
`;
DocLink.displayName = 'DocLink';

export const Content = styled.div`
  height: calc(100% - 50px);
  overflow-y: scroll;
  padding: 0 34px 34px 0;
`;
Content.displayName = 'Content';

export const HelpIcon = styled(Help)`
  margin-left: 6px;
  & > path {
    fill: #a1b4b6; 
  }
`;
HelpIcon.displayName = 'HelpIcon';
