/* eslint-disable no-nested-ternary */
/* eslint-disable no-negated-condition */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import TimeAgo from 'javascript-time-ago';
// Load locale-specific relative date/time formatting rules.
import en from 'javascript-time-ago/locale/en';
import { FormattedMessage } from 'react-intl';
import Skeleton from 'Common/Components/Skeleton';
import DateFormatter from 'Common/Components/DateFormatter';
import {
  ColWorkspaceDescription, ColWorkspaceNumeric, ColWorkspaceBetweenTime,
  ColWorkspaceCreated
} from './styled';
import Name from './Name';
import ImportanceColumn from './ImportanceColumn';
import EnableDisableWs from './EnableDisableWs';
import LockUnlockWs from './LockUnlockWs';
import CheckHeader from './CheckHeader';
import CheckColum from './CheckColumn';
import formatNumber from 'Common/Functions/FormatNumber';
import Description from './Description';

TimeAgo.addLocale(en);

export default function getColumns (isFetching, setShowConfirmation, fields, theme) {
  const columns = [{
    Header: () => <CheckHeader />,
    id: 'selected',
    accessor: 'Selected',
    sortable: false,
    resizable: false,
    maxWidth: 38,
    minWidth: 38,
    style: { display: 'flex' },
    Cell: (rowInfo) => ((isFetching) ? <Skeleton /> : <CheckColum ws={ rowInfo.original } />)
  },
  {
    Header: () => <FormattedMessage id="workspaces.table.columns.importance" />,
    id: 'importance',
    accessor: 'importance',
    maxWidth: fields.importance.maxWidth,
    minWidth: fields.importance.minWidth,
    width: fields.importance.defaultMin,
    show: fields.importance.visible,
    custom_order: fields.importance.order,
    resizable: true,
    Cell: (rowInfo) => ((isFetching) ? <Skeleton /> : <ImportanceColumn ws={ rowInfo.original } />)
  },
  {
    Header: () => <FormattedMessage id="workspaces.table.columns.workspace" />,
    id: 'name',
    accessor: 'name',
    resizable: true,
    maxWidth: fields.name.maxWidth,
    minWidth: fields.name.minWidth,
    width: fields.name.defaultMin,
    show: fields.name.visible,
    custom_order: fields.name.order,
    Cell: (rowInfo) => <Name isFetching={ isFetching } ws={ rowInfo.original } />,
    getProps: () => ({ 'column-type': 'name' })
  },
  {
    Header: () => <FormattedMessage id="workspaces.table.columns.description" />,
    id: 'description',
    accessor: 'description',
    sortable: false,
    maxWidth: fields.description.maxWidth,
    minWidth: fields.description.minWidth,
    width: fields.description.defaultMin,
    show: fields.description.visible,
    custom_order: fields.description.order,
    resizable: true,
    Cell: (rowInfo) => (
      isFetching
        ? <Skeleton />
        : <Description ws={ rowInfo.original } />
    ),
    getProps: () => ({ 'column-type': 'description' })
  }, {
    Header: () => <FormattedMessage id="workspaces.table.columns.created.context" />,
    resizable: true,
    id: 'create_date',
    accessor: 'create_date',
    maxWidth: fields.create_date.maxWidth,
    minWidth: fields.create_date.minWidth,
    width: fields.create_date.defaultMin,
    show: fields.create_date.visible,
    custom_order: fields.create_date.order,
    Cell: (rowInfo) => (isFetching ? <Skeleton /> : <ColWorkspaceCreated><DateFormatter date={ rowInfo.original.create_date } /></ColWorkspaceCreated>)
  }, {
    Header: () => <FormattedMessage id="workspaces.table.columns.customer" />,
    resizable: true,
    id: 'customer',
    maxWidth: 160,
    Cell: (rowInfo) => (isFetching
      ? <Skeleton />
      : (
      <ColWorkspaceDescription active={ rowInfo.original.active }>
        { rowInfo.original.customer }
      </ColWorkspaceDescription>
        ))
  }, {
    Header: () => <FormattedMessage id="workspaces.table.columns.vulnerabilidades" />,
    resizable: true,
    id: 'vulnerabilities',
    accessor: 'vulnerabilities',
    maxWidth: fields.vulnerabilities.maxWidth,
    minWidth: fields.vulnerabilities.minWidth,
    width: fields.vulnerabilities.defaultMin,
    show: fields.vulnerabilities.visible,
    custom_order: fields.vulnerabilities.order,
    Cell: (rowInfo) => (
      isFetching
        ? <Skeleton />
        : (
          <ColWorkspaceNumeric active={ rowInfo.original.active } title={ rowInfo.original.stats.total_vulns }>
            { formatNumber(rowInfo.original.stats.total_vulns) }
          </ColWorkspaceNumeric>
          )
    ),
    getProps: () => ({ 'column-type': 'vulnerabilities' }),
    sortable: false
  }, {
    Header: () => <FormattedMessage id="workspaces.table.columns.assets" />,
    resizable: true,
    id: 'hosts',
    accessor: 'hosts',
    sortable: false,
    maxWidth: fields.hosts.maxWidth,
    minWidth: fields.hosts.minWidth,
    width: fields.hosts.defaultMin,
    show: fields.hosts.visible,
    custom_order: fields.hosts.order,
    Cell: (rowInfo) => (
      isFetching
        ? <Skeleton />
        : (
          <ColWorkspaceNumeric active={ rowInfo.original.active } title={ rowInfo.original.stats.hosts }>
            { formatNumber(rowInfo.original.stats.hosts) }
          </ColWorkspaceNumeric>
          )
    ),
    getProps: () => ({ 'column-type': 'hosts' })
  }, {
    Header: () => <FormattedMessage id="workspaces.table.columns.services" />,
    resizable: true,
    id: 'services',
    accessor: 'services',
    sortable: false,
    maxWidth: fields.services.maxWidth,
    minWidth: fields.services.minWidth,
    width: fields.services.defaultMin,
    show: fields.services.visible,
    custom_order: fields.services.order,
    Cell: (rowInfo) => (
      isFetching
        ? <Skeleton />
        : (
          <ColWorkspaceNumeric active={ rowInfo.original.active } title={ rowInfo.original.stats.services }>
            { formatNumber(rowInfo.original.stats.services) }
          </ColWorkspaceNumeric>
          )
    ),
    getProps: () => ({ 'column-type': 'services' })
  }, {
    Header: () => <FormattedMessage id="workspaces.table.columns.datesBetween.context" />,
    resizable: true,
    id: 'range_dates',
    accessor: 'range_dates',
    sortable: false,
    maxWidth: fields.range_dates.maxWidth,
    minWidth: fields.range_dates.minWidth,
    width: fields.range_dates.defaultMin,
    show: fields.range_dates.visible,
    custom_order: fields.range_dates.order,
    Cell: (rowInfo) => (
      isFetching
        ? <Skeleton />
        : (
          <ColWorkspaceBetweenTime>
            {`${rowInfo.original.duration.start_date ? new Date(rowInfo.original.duration.start_date).toString().substring(0, 15) : ''}${rowInfo.original.duration.start_date && rowInfo.original.duration.end_date ? ' - ' : ''}${rowInfo.original.duration.end_date ? new Date(rowInfo.original.duration.end_date).toString().substring(0, 15) : ''}`}
          </ColWorkspaceBetweenTime>
          )
    ),
    getProps: () => ({ 'column-type': 'range_dates' })
  }, {
    Header: () => <FormattedMessage id="workspaces.table.columns.lastModified" />,
    id: 'update_date',
    accessor: 'update_date',
    resizable: true,
    maxWidth: fields.update_date.maxWidth,
    minWidth: fields.update_date.minWidth,
    width: fields.update_date.defaultMin,
    show: fields.update_date.visible,
    custom_order: fields.update_date.order,
    Cell: (rowInfo) => {
      if (isFetching) {
        return <Skeleton />;
      }
      const date = new Date(rowInfo.original.update_date);
      const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(date.getSeconds()).padStart(2, '0')}`;
      return <div>{formattedDate}</div>;
    },
    getProps: () => ({ 'column-type': 'update_date', 'style': { color: theme.themeStyles.text.tableVulnName } })
  }, {
    Header: () => <FormattedMessage id="workspaces.table.columns.permissions" />,
    id: 'readonly',
    accessor: 'readonly',
    filterable: false,
    Cell: (rowInfo) => <LockUnlockWs isFetching={ isFetching } ws={ rowInfo.original } />,
    resizable: true,
    maxWidth: fields.readonly.maxWidth,
    minWidth: fields.readonly.minWidth,
    width: fields.readonly.defaultMin,
    show: fields.readonly.visible,
    custom_order: fields.readonly.order,
    getProps: () => ({ 'column-type': 'readonly', 'style': { color: theme.themeStyles.text.tableVulnName } })
  }, {
    Header: () => <FormattedMessage id="workspaces.table.columns.status" />,
    accessor: 'active',
    maxWidth: fields.active.maxWidth,
    minWidth: fields.active.minWidth,
    width: fields.active.defaultMin,
    show: fields.active.visible,
    custom_order: fields.active.order,
    resizable: true,
    filterable: false,
    id: 'active',
    Cell: (rowInfo) => <EnableDisableWs isFetching={ isFetching } ws={ rowInfo.original } setShowConfirmation={ setShowConfirmation } />,
    getProps: () => ({ 'column-type': 'active' })
  }
  ];
  return columns.filter((row, index) => row.show === true || index === 0).sort((a, b) => (a.custom_order < b.custom_order ? -1 : 1));
}
