import React from 'react';
import {
  ContextMenuTrigger
} from 'react-contextmenu';
import Wrapper from './styled';
import { ThemeContext } from 'Context';

const ContextMenuButton = ({
  opened, menu, trigger, display, icon
}) => {
  const themeStyles = ThemeContext === 'light' ? 'light' : 'dark';

  if (!display) return null;
  return (
    <Wrapper>
      <ContextMenuTrigger className={ themeStyles } id={ trigger } holdToDisplay={ opened ? -1 : 0 }>
        {icon}
      </ContextMenuTrigger>
      {menu}
    </Wrapper>
  );
};

export default ContextMenuButton;
