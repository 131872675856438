import React, { useState, useEffect, useRef } from 'react';
import PropType from 'prop-types';
import {
  Input, Title, Wrapper, Asterisk, Texts, ErrorMessage, Warning
} from 'Common/Components/StandardTextField/styled';
import debounce from 'lodash/debounce';
import get from 'lodash/get';

const DebouncedInput = ({
  focusOnMount, value, name, disabled, onChange,
  placeholder, title, mandatory, error, errorMessage,
  className, boldTitle, type, min, max, step, hideArrows,
  id, autocomplete
}) => {
  const [fieldValue, setFieldValue] = useState('');
  const inputRef = useRef(null);

  useEffect(() => {
    const currentRef = get(inputRef, 'current', null);
    if (focusOnMount && currentRef) currentRef.focus();
  }, [focusOnMount]);

  useEffect(() => {
    setFieldValue(value);
  }, [value]);

  const updateName = useRef(debounce((v) => {
    if (name) onChange(v);
  }, 250));

  const handleChange = (e) => {
    const v = e.target.value;
    setFieldValue(v);
    updateName.current(v);
  };

  return (
    <Wrapper className={ className }>
      <Texts>
        {title && <Title boldTitle={ boldTitle }>{title}</Title>}
        {mandatory && <Asterisk>*</Asterisk>}
      </Texts>
      <Input
        name={ name }
        value={ fieldValue }
        disabled={ disabled }
        onChange={ handleChange }
        isEmpty={ value ? value.length === 0 : true }
        placeholder={ placeholder }
        error={ error }
        type={ type }
        min={ min }
        max={ max }
        step={ step }
        ref={ inputRef }
        hideArrows={ hideArrows }
        id={ id }
        autoComplete={ autocomplete }
      />
      {error && <Warning />}
      {error && <ErrorMessage>{ errorMessage }</ErrorMessage>}
    </Wrapper>

  );
};
DebouncedInput.defaultProps = {
  disabled: false,
  placeholder: '',
  value: '',
  mandatory: false,
  error: false,
  className: '',
  boldTitle: false,
  type: 'text',
  title: '',
  min: 0,
  max: 0,
  step: 0,
  errorMessage: '',
  focusOnMount: false,
  hideArrows: false,
  id: '',
  autocomplete: 'off'
};

DebouncedInput.propTypes = {
  value: PropType.string,
  name: PropType.string.isRequired,
  disabled: PropType.bool,
  onChange: PropType.func.isRequired,
  placeholder: PropType.string,
  mandatory: PropType.bool,
  error: PropType.bool,
  boldTitle: PropType.bool,
  className: PropType.string,
  type: PropType.string,
  title: PropType.string,
  min: PropType.number,
  max: PropType.number,
  step: PropType.number,
  errorMessage: PropType.string,
  focusOnMount: PropType.bool,
  hideArrows: PropType.bool,
  id: PropType.string,
  autocomplete: PropType.string
};

export default DebouncedInput;
