import styled from 'styled-components';
import CustomReactTable from 'Common/Components/Contextualization/CustomReactTable/styled';

export const Wrapper = styled.div`
  height: 90%;
  overflow: hidden;
`;
Wrapper.displayName = 'Wrapper';

export const StyledTable = styled(CustomReactTable)`
  .rt-tbody {
    .rt-tr {
      height: 50px;
    }
  }
`;
CustomReactTable.displayName = 'CustomReactTable';
