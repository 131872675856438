import React from 'react';
import ModalWrapper from 'Common/Components/ModalWrapper';
import { PropTypes } from 'prop-types';
import {
  ModalTitle, Title, BodyText, ModalContent, ButtonsContainer,
  BlueText, Warning, ModalBody,
  CancelButton
} from './styled';
import Button from 'Common/Kit/Button';

const ConfirmationDialog = ({
  show, title, count, upperCaseTitle, handleClose, handleSubmit, message, submitText, isLoading
}) => {
  if (!show) return null;
  return (
    <ModalWrapper>
      <ModalContent>
        <ModalTitle>
          <Warning />
          <Title>
            {title}
          </Title>
        </ModalTitle>
        <ModalBody>
          {message
            ? (
            <BodyText>
              {message}
            </BodyText>
              )
            : (
            <>
              <BodyText>You’re editing &nbsp;</BodyText>
              <BlueText>
                {`${count} ${upperCaseTitle}(s)`}
              </BlueText>
              <BodyText>and changing their properties. Are you sure?</BodyText>
            </>
              )}
        </ModalBody>
        <ButtonsContainer>
          <CancelButton onClick={ handleClose } color="white">
            Cancel
          </CancelButton>
          <Button onClick={ handleSubmit } isLoading={ isLoading } >
          {submitText || 'Save'}
          </Button>
        </ButtonsContainer>
      </ModalContent>
    </ModalWrapper>
  );
};

ConfirmationDialog.propTypes = {
  show: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  count: PropTypes.number,
  title: PropTypes.string.isRequired,
  upperCaseTitle: PropTypes.string,
  message: PropTypes.string,
  submitText: PropTypes.string
};

ConfirmationDialog.defaultProps = {
  show: true,
  message: null,
  submitText: null,
  upperCaseTitle: null,
  count: null
};

export default ConfirmationDialog;
