import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import { useIntl } from 'react-intl';
import { redirect } from 'store/Router/actions';
import { selectGraphsSelected } from 'store/Analytics/selectors';
import IconButton from 'Common/Components/IconButton';
import { EditIcon } from '../styled';
import ModalWarning from 'Common/Components/ModalWarning';

const Edit = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [showWarning, setShowWarning] = useState(false);
  const graphSelected = useSelector(selectGraphsSelected);
  const graphId = get(graphSelected, '[0].id', 0);
  const message = intl.formatMessage({ id: 'modalWarning.selectOnlyOne' }, { entity: 'Graph' });
  const edit = intl.formatMessage({ id: 'analytics.options.edit' });
  const handleClick = () => {
    if ((graphSelected.length === 1)) dispatch(redirect(`/analytics/${graphId}`));
    else setShowWarning(true);
  };

  const onClose = () => setShowWarning(false);
  return (
    <>
      <IconButton icon={ <EditIcon /> } title={ edit } onClick={ handleClick } />
      { showWarning && <ModalWarning onCloseCallback={ onClose } title="Edit Graph" description={ message } /> }
    </>
  );
};

export default Edit;
