import styled from 'styled-components';
import colors from 'Styles/colors';
import { BackButton } from 'Common/Components/MainContainer/styled';
import { slideIn } from 'Styles/effects';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 450px; */
  width: fit-content;
  height: fit-content;
  max-height: 95%;
  border-radius: 2px;
  /* box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.24); */
  box-shadow: ${({ theme }) => theme.boxShadow.modal};
  /* border: solid 1px #d6d6d6; */
  background-color: ${({ theme }) => theme.backgrounds.modal};
  border-top: 10px solid #419bf9;
  padding: 34px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  ${slideIn}
`;
Wrapper.displayName = 'Wrapper';

export const Text = styled.label`
  color: #0082ed;
  float: right;
  margin-top: 42px;
  font-size: 12.5px;
  cursor: pointer;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  font-size: 21.5px;
  font-weight: 600;
  line-height: 1.16;
  text-align: left;
  margin-bottom: 34px;
  color: ${({ theme }) => theme.text.modalTitle2};
`;
Title.displayName = 'Title';

export const LeftTitle = styled(Title)`
  display: flex;
  flex: 0 0 25px;
  align-content: flex-start;
  margin-bottom: 15px;
`;
LeftTitle.displayName = 'LeftTitle';

export const BackBtn = styled(BackButton)`
  float: right;
  margin-right: 9px;
  border: solid 1px ${({ theme }) => theme.buttons.custom.border};
  color: ${({ theme }) => theme.buttons.custom.color};
  background-color: ${({ theme }) => theme.buttons.custom.background};
`;
BackBtn.displayName = 'BackBtn';

export const Click = styled.a`
  margin-top: 5px;
    font-size: 12.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.12;
    letter-spacing: normal;
    text-align: left !important;
    color: #419bf9; 
    display: block;   
`;
Click.displayName = 'Click';

export const AddMore = styled.div`
  margin-top: 10px;
  cursor:pointer;
  font-size: 12.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.12;
  letter-spacing: normal;
  text-align: left;
  color: #0082ed;

  svg {
    margin-right: 5px;
    font-size: 0.9em;
    position: relative;
    top: -2px;
  }

  label {
    cursor:pointer;
  font-size: 12.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.12;
  letter-spacing: normal;
  text-align: left;
  color: #0082ed;
  }
`;
AddMore.displayName = 'AddMore';

export const SubTitle = styled.div`
  font-size: 12.5px;
  font-weight: normal;
  font-stretch: normal;
  margin-top: 14px;
  color: ${colors.grey11};
`;
SubTitle.displayName = 'SubTitle';
