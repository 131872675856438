import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import General from 'Screens/Contextualization/ManageEditCreate/components/ManualCreationModal/components/General';
import ModalWrapper from 'Common/Components/ModalWrapper';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_VULNS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { selectIsReadOnly } from 'store/Faraday/selectors';
import TechnicalDetails from './components/TechnicalDetails';
import CustomFields from './components/CustomFields';
import Evidence from './components/Evidence';
import {
  Wrapper, ModalBody, Tab, Tabs,
  TabContent
} from './styled';
import Header from './components/Header';
import { MODAL_EVIDENCE_WARNING_UPLOAD } from 'store/modals/modals';
import ModalWarning from 'Common/Components/EvidenceTab/components/ModalWarning';
import ReduxModal from 'Common/Components/ReduxModal';
import { selectCurrentHost } from 'store/Host/selectors';
import { addAsset, getServicesByHostIds, getTemplates, setField } from 'Screens/Contextualization/ManageEditCreate/actions/Actions';
import { selectErrorCreateVuln, selectNameField, selectTemplates } from 'store/ManageEditCreate/selectors';
import CreationWarning from './components/CreationWarning';
import InputFilter from 'Common/Components/Contextualization/InputFilter';
import { Title } from './components/General/styled';
import debounce from 'lodash/debounce';

const ManualCreationModal = ({ handleClose, entity }) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('general');
  const canUpdate = useSelector((state) => selectAllowedByRole(state, FEATURE_VULNS, PERMISSION_TYPES.UPDATE));
  const readonly = useSelector(selectIsReadOnly);
  const [assets, setAssets] = useState([]);
  const name = useSelector(selectNameField);
  const currentAsset = useSelector(selectCurrentHost);
  const showErrorCreateVuln = useSelector(selectErrorCreateVuln);
  const templates = useSelector(selectTemplates);

  const handleChange = (field, value) => {
    dispatch(setField(field, value));
  };

  const debouncedSetField = debounce(handleChange, 150);

  const applyTemplate = (template) => {
    dispatch(setField('name', template.name));
    dispatch(setField('description', template.description));
    dispatch(setField('resolution', template.resolution));
    dispatch(setField('severity', template.exploitation));
    dispatch(setField('policies', template.policyviolations));
    dispatch(setField('references', template.refs));
    dispatch(setField('externalId', template.external_id));
    dispatch(setField('accountability', template.impact.accountability));
    dispatch(setField('availability', template.impact.availability));
    dispatch(setField('integrity', template.impact.integrity));
    dispatch(setField('confidentiality', template.impact.confidentiality));
    dispatch(setField('easeOfResolution', template.easeofresolution));
    dispatch(setField('data', template.data));
    dispatch(setField('customFields', template.customfields));
    dispatch(setField('CVE', template.cve));
    dispatch(setField('cvss2', template.cvss2));
    dispatch(setField('cvss3', template.cvss3));
  };

  useEffect(() => {
    if (Object.keys(currentAsset).length > 0) {
      setAssets(currentAsset);
      dispatch(addAsset(currentAsset));
      dispatch(getServicesByHostIds());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const RenderTab = {
    general: <General assets={ assets } setAssets={ setAssets } />,
    technicalDetails: <TechnicalDetails assets={ assets } />,
    customFields: <CustomFields />,
    evidence: <Evidence />
  };

  return (
    <ModalWrapper>
      <Wrapper readonly={ readonly || !canUpdate }>
        <Header handleClose={ handleClose } activeTab={ activeTab } setActiveTab={ setActiveTab } entity={ entity } />
        <div style={ { width: '415px' } }>
          <Title required>Vulnerability Name</Title>
          <InputFilter
            getObjects={ () => dispatch(getTemplates()) }
            data={ templates }
            width="405px"
            height="38px"
            placeholder="Search or give a name to your Vulnerability"
            onSelect={ applyTemplate }
            onChange={ debouncedSetField }
            value={ name }
          />
        </div>
        <ModalBody>
          <Tabs>
            <Tab onClick={ () => setActiveTab('general') } active={ activeTab === 'general' }>General</Tab>
            <Tab onClick={ () => setActiveTab('technicalDetails') } active={ activeTab === 'technicalDetails' }>Technical Details</Tab>
            <Tab onClick={ () => setActiveTab('customFields') } active={ activeTab === 'customFields' }>Custom Attributes</Tab>
            <Tab onClick={ () => setActiveTab('evidence') } active={ activeTab === 'evidence' }>Evidence</Tab>
          </Tabs>
          <TabContent>
            {RenderTab[activeTab]}
          </TabContent>
        </ModalBody>
      </Wrapper>
      <ReduxModal id={ MODAL_EVIDENCE_WARNING_UPLOAD }>
        <ModalWarning />
      </ReduxModal>
      { showErrorCreateVuln && <CreationWarning /> }
    </ModalWrapper>
  );
};

ManualCreationModal.propTypes = {
  handleClose: PropTypes.func.isRequired
};

export default ManualCreationModal;
