import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IssuesConfiguration from './components/IssuesConfiguration';
import Settings from './components/Settings';
import { Wrapper, Title, Content } from '../../../SolarWinds/components/MainSettings/styled';
import { selectError, selectMessage } from 'store/Settings/gitLabSelectors';
import Error from '../../../Error';
import { setField } from 'store/Settings/gitLabActions';

const MainSettings = () => {
  const error = useSelector(selectError);
  const errorMessage = useSelector(selectMessage);
  const dispatch = useDispatch();

  const onCloseError = () => {
    dispatch(setField('error', false));
    dispatch(setField('message', ''));
  };

  return (
    <Wrapper>
      <Title children='Main settings' />
      <Content>
        <Settings />
        <IssuesConfiguration />
        { error && errorMessage ? <Error message={ errorMessage } close={ onCloseError } /> : null }
      </Content>
    </Wrapper>
  );
};

export default MainSettings;
