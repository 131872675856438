import styled, { css } from 'styled-components';
import colors from 'Styles/colors';
import { FiTrash2 as Trash } from 'react-icons/fi';

const ellipsis = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const baseFontStyle = css`
  font-size: 12.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 2.72;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  max-height: 138px;
  border: solid 1px  ${({ theme }) => theme.borders.actionDropDown};
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin-top: 19px;
  background-color: ${({ theme }) => theme.backgrounds.workspaceList}
`;

Wrapper.displayName = 'WorkspaceListWrapper';

export const Workspace = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 17px;
  background-color: ${(props) => (props.isOdd ? props.theme.backgrounds.listItemOdd : props.theme.backgrounds.listItemEven)};
`;

Workspace.displayName = 'Workspace';

export const Name = styled.span`
  ${baseFontStyle}
  color: ${({ theme }) => theme.text.workspaceListItem};
  width: 40%;
  text-align: left;
  ${ellipsis}
`;

Name.displayName = 'Name';

export const TotalVulns = styled.span`
  ${baseFontStyle}
  color: ${colors.blueGrey};
  width: 30%;
  ${ellipsis}
`;

TotalVulns.displayName = 'TotalVulns';

export const CriticalVulns = styled.span`
  ${baseFontStyle}
  color: ${colors.warmPurple};
  width: 25%;
  ${ellipsis}
`;

CriticalVulns.displayName = 'CriticalVulns';

export const TrashIcon = styled(Trash)` 
  width: 16px;
  height: 17px;
  color: ${colors.grey12};
`;

TrashIcon.displayName = 'TrashIcon';
