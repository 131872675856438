import styled from 'styled-components';
import colors from 'Styles/colors';

export const TableWrapper = styled.div`
  width: 100%;
  height: 90%;
  margin-top: 23px;
  @media (max-width: 1370px) {
    height: 72%;
  }
`;
TableWrapper.displayName = 'TableWrapper';

export const TabWrapper = styled.div`
  display: flex;
`;
TabWrapper.displayName = 'TabWrapper';

export const Tab = styled.div`
  padding: 16px 40px;
  background-color: ${({ active, theme }) => (active ? theme.selector.selected : theme.selector.background)};
  color: ${({ theme }) => theme.text.tableItem};
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
`;
Tab.displayName = 'Tab';

export const TableHeader = styled.div`
  font-family: 'CentraNo2';
  color: ${colors.blueGrey};
`;
TableHeader.displayName = 'TableHeader';

export const TableHeaderAlias = styled(TableHeader)`
  padding: 0 10px;
`;
TableHeaderAlias.displayName = 'TableHeaderAlias';
