import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.backgrounds.modal};
  padding: 12px 0 13px 14px;
  display: flex;
  margin-top: ${({ hasItems }) => (hasItems ? '0' : '25px')};
`;
Wrapper.displayName = 'Wrapper';

export const InputWrapper = styled.div`
  margin-right: 10px;
`;
InputWrapper.displayName = 'InputWrapper';

export const Input = styled.input`
  width: 214px;
  height: 34px;
  padding: 10px 15px 8px 15px;
  box-shadow: none;
  background-color: ${({ theme }) => theme.textfield.background};
  border: none;
  color: ${({ theme }) => theme.textfield.text};
  font-size: 13.5px;
  font-weight: normal;
  ::placeholder {
    color: #bec8d2;
  }
`;
Input.displayName = 'Input';

export const Label = styled.div`
  margin-top: 10px;
  font-size: 12.5px;
  font-weight: normal;
  color: ${colors.greyBlue};
  text-align: initial;
`;
Label.displayName = 'Label';

export const AddButton = styled.button`
  border-radius: 2px;
  /* border: solid 1px #707070; */
  border: ${({ theme }) => `solid 1px ${theme.buttons.tertiary.background}`};
  /* background-color: ${colors.white}; */
  background-color: ${({ theme }) => theme.buttons.primary.background};
  /* color: ${colors.darkBlueGrey}; */
  color: ${({ theme }) => theme.buttons.primary.color};
  text-align: center;
  min-width: 68px;
  width: auto;
  height: 34px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 500;
  padding: 4px;
  cursor: pointer;
`;
AddButton.displayName = 'AddButton';
