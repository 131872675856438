import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
`;
Wrapper.displayName = 'Wrapper';

export const CustomInput = styled.input.attrs((props) => ({
  type: props.type
}))`
  width: 100%;
  height: 34px;
  border: 1px solid transparent;
  border-bottom: none;
  background-color: ${(props) => (props.isEmpty ? `${props.theme.textfield.background}` : `${props.theme.textfield.focus}`)};
  padding: 0 10px;
  font-size: 13.5px;
  line-height: 2.48;
  color: ${({ theme }) => theme.textfield.text};
  margin-bottom: 22px;
  ${({ disabled }) => disabled && `
    opacity: 0.5;
    cursor: not-allowed;
  `};
  &::placeholder {
    color: ${({ theme }) => theme.text.taskPlaceholder};
    font-size: 12.5px;
  }
`;
CustomInput.displayName = 'CustomInput';

export const InputTitle = styled.div`
  text-align: left;
  font-size: 12.5px;
  line-height: 2.08;
  margin-bottom: 1px;
  color: ${colors.grey17};
  ${({ disabled }) => disabled && `
    opacity: 0.5;
  `};
`;
InputTitle.displayName = 'InputTitle';
