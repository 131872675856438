/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CustomReactTable from 'Common/Components/Contextualization/CustomReactTable/styled';
import {
  ContextMenuTrigger
} from 'react-contextmenu';
import { connect } from 'react-redux';
import { sortData } from 'Screens/Contextualization/KnowledgeBase/actions/Actions';
import { selectIsFetching, selectTemplatesSelected, vulnTemplatesList } from 'store/KnowledgeBase/selectors';
import getColumns from './Columns';
import { FormattedMessage } from 'react-intl';
import { selectFields } from 'store/Preferences/knowledgeBase/selectors';
import useTheme from 'Hooks/UseTheme';

class Table extends Component {
  constructor (props) {
    super(props);
    this.table = React.createRef();
    this.fetchData = this.fetchData.bind(this);
    this.getTrProps = this.getTrProps.bind(this);
  }

  getTrProps (theme, state, rowInfo) {
    const selected = this.props.templatesSelected.filter((x) => x.id === rowInfo.original.id).length > 0;

    return {
      style: {
        background: selected ? `${theme.themeStyles.backgrounds.selectedTableItem}` : ''
      }
    };
  }

  fetchData (state) {
    const {
      isFetching
    } = this.props;

    if (!isFetching) {
      this.props.sortData(state.sorted);
    }
  }

  render () {
    const {
      data,
      isFetching,
      fields
    } = this.props;

    const mockData = [...Array(13).keys()];

    return (
      <div className="h-100 w-100" ref={ (element) => { this.table = element; } }>
        <ContextMenuTrigger id="massive_update_kb_table" holdToDisplay={ 1000000 }>
          <CustomReactTable
            data={ isFetching ? mockData : data }
            columns={ getColumns(isFetching, fields) }
            minRows={ 0 }
            page={ 1 }
            manual
            onFetchData={ this.fetchData }
            getTrProps={ (state, rowInfo) => this.getTrProps(this.props.theme, state, rowInfo) }
            showPagination={ false }
            noDataText={ !isFetching && <FormattedMessage id="app.nodata.context" /> }
          />
        </ContextMenuTrigger>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  data: vulnTemplatesList(state),
  isFetching: selectIsFetching(state),
  templatesSelected: selectTemplatesSelected(state),
  fields: selectFields(state)
});

const mapDispatchToProps = (dispatch) => ({
  sortData: (sorting) => {
    dispatch(sortData(sorting));
  }
});

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  isFetching: PropTypes.bool.isRequired,
  sortData: PropTypes.func.isRequired,
  templatesSelected: PropTypes.arrayOf(PropTypes.object).isRequired
};

const ThemedTable = (props) => {
  const theme = useTheme(); // Get the theme using the hook
  return <Table { ...props } theme={ theme } />;
};

export default connect(mapStateToProps, mapDispatchToProps)(ThemedTable);
