import timeSince from 'Common/Functions/timeSince';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import { selectCurrentViewMode, selectGraphs, selectGraphsSelected, selectIsEmpty } from 'store/Analytics/selectors';
import { GRAPH_GRID_VIEW } from 'store/Analytics/viewMode';
import { graphTypeParsed } from '../Table/cells';
import {
  Wrapper, Content, CartWrapper, Title, Subtitle, Time
} from './styled';
import { redirect } from 'store/Router/actions';
import { selectRow } from 'store/Analytics/actions';
import some from 'lodash/some';

const Grid = () => {
  const isEmpty = useSelector(selectIsEmpty);
  const graphs = useSelector(selectGraphs);
  const viewMode = useSelector(selectCurrentViewMode);
  const graphsSelected = useSelector(selectGraphsSelected);

  if (isEmpty) return null;
  if (viewMode === GRAPH_GRID_VIEW) {
    return (
      <Wrapper>
        <Content>
          {graphs.map((graph) =>
            <GraphCard
              title={ graph.name }
              type={ graph.type }
              description={ graph.description }
              time={ timeSince(graph.update_date) }
              id={ graph.id }
              key={ graph.id }
              selected={ some(graphsSelected, { name: graph.name }) }
              graph={ graph }
            />)}
        </Content>
      </Wrapper>
    );
  }
  return null;
};

export default Grid;

const GraphCard = ({
  title, type, description, time, id, selected, graph
}) => {
  const dispatch = useDispatch();
  const typeParsed = graphTypeParsed(type);
  const onClick = () => {
    if (id > 0) dispatch(redirect(`/analytics/${id}`));
  };

  const handleSelectGraph = (e) => dispatch(selectRow(e, graph));

  return (
    <CartWrapper onClick={ handleSelectGraph } selected={ selected }>
      <Subtitle>{typeParsed}</Subtitle>
      <Title onClick={ onClick }>{title}</Title>
      <Subtitle>{description}</Subtitle>
      <Time>{time}</Time>
    </CartWrapper>
  );
};

GraphCard.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired
};
