import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as TrashSVG } from 'Images/icon-action-bar-trash.svg';
import { ReactComponent as CopySVG } from 'Images/copy.svg';
import { ReactComponent as EditSVG } from 'Images/icon-action-bar-edit.svg';

export const Wrapper = styled.div`
  position: absolute;
  right: 4px;
  top: 4px;
  padding: 0px 4px 3px 4px;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgb(28 34 67 / 3%);
  border: solid 1px ${({ theme }) => theme.borders.importanceMeter};
  background-color: ${({ theme }) => theme.backgrounds.modal2};
`;
Wrapper.displayName = 'Wrapper';

export const TrashIcon = styled(TrashSVG)`
  width: 16px;
  height: 17px;
  margin: auto 0px auto auto;
  * > path {
    fill: ${colors.blueGrey};
  }
`;

TrashIcon.displayName = 'TrashIcon';

export const CopyIcon = styled(CopySVG)`
  width: 16px;
  height: 17px;
  margin: auto 13px auto auto;
  * > path {
    fill: ${colors.blueGrey};
  }
`;

CopyIcon.displayName = 'CopyIcon';

export const EditIcon = styled(EditSVG)`
  width: 16px;
  height: 17px;
  margin: auto 13px auto auto;
  * > path {
    fill: ${colors.blueGrey};
  }
`;

EditIcon.displayName = 'EditIcon';
