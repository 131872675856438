import styled, { css } from 'styled-components';
import colors from 'Styles/colors';

export const Default = styled.div`
  user-select: none;
  border: solid 1px ${colors.dark6};
  text-align: center;
  min-width: 89px;
  width: auto;
  height: 34px;
  line-height: 24px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 500;
  padding: 4px;
  color: ${({ theme }) => theme.buttons.tertiary.color};
  cursor: pointer;
  display: inline-block;
  user-select: none;
  color: ${({ theme }) => theme.textfield.text};
  ${(props) => props.disabled && css`
    opacity: 0.4;
    pointer-events: none;
  `}
`;
Default.displayName = 'Default';

export const Accept = styled(Default)`
  user-select: none;
  background-color: ${colors.blueCerulean};
  color: ${colors.white};
  border-color: ${colors.blueCerulean};
  ${({ disabled, theme }) => disabled && `
    background-color: ${theme.buttons.disabledSave.background};
    color: ${theme.buttons.disabledSave.color};
    border-color: transparent;
    opacity: 1
  `}
`;
Accept.displayName = 'Accept';

export const Cancel = styled(Default)`
  user-select: none;
  background-color: ${({ theme }) => theme.backgrounds.modal};
  color: ${({ theme }) => theme.text.modalTitle};
  border: solid 1px ${colors.dark6};
`;
Cancel.displayName = 'Cancel';
