import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  height: 100%;
  padding-top: 8px;
  background-color: ${({ theme }) => theme.backgrounds.actionBar};
`;
Wrapper.displayName = 'Wrapper';

export const TableContainer = styled.div`
  height: 95%;
  display: flex;
  flex-direction: row;
  flex: 1 1 0px;
  position: relative;
`;
TableContainer.displayName = 'TableContainer';
