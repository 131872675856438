import styled from 'styled-components';
import colors from 'Styles/colors';
import { ellipsisText } from 'Styles/styles';

export const Wrapper = styled.div`
  width: 100%;      
  background-color: ${colors.white};
  box-shadow: 0 2px 50px 0 ${({ theme }) => theme.textfield.shadow};
  max-height: 102px;
  overflow-y: auto;
  margin-bottom: 20px;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    height: 20px;
  }
`;
Wrapper.displayName = 'Wrapper';

export const Item = styled.div`    
  height: 34px;
  width: 100%;
  padding: 7px 5px 7px 15px;
  display: flex;
  align-items: center;
  background-color: ${(props) => (props.isOdd ? props.theme.textfield.background : props.theme.textfield.background)};
`;
Item.displayName = 'Item';

export const Text = styled.div`
  text-align: left;
  ${ellipsisText}
  font-size: 13.5px;
  font-weight: 500;
  color: ${({ theme }) => theme.textfield.text};
`;
Text.displayName = 'Text';
