import styled from 'styled-components';
import colors from 'Styles/colors';
import emptyImage from 'Images/jobs_pipelines_empty.png';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: solid 1px ${({ theme }) => theme.borders.detailPanel};
  border-radius: 4px;
  max-width: calc(100% - 508px);
  width: 100%;
  padding: 114px 70px;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  color: ${colors.greyBlue};
  margin-bottom: 10px;
`;
Title.displayName = 'Title';

export const Description = styled.div`
  font-size: 15px;
  line-height: 1.2;
  text-align: center;
  color: ${colors.blueGrey};
`;
Description.displayName = 'Description';

export const EmptyImage = styled.img.attrs({
  src: emptyImage
})`
  width: 100%;
  height: auto;
  max-width: 460px;
  margin-bottom: 30px;
`;
EmptyImage.displayName = 'EmptyImage';
