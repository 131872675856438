import styled from 'styled-components';
import { ReactComponent as Image } from 'Images/assets.svg';

export const Wrapper = styled.div`
  user-select: none;
  margin-bottom: 15px;
`;
Wrapper.displayName = 'Wrapper';

export const Icon = styled(Image)`
  margin-right: 14px;
  font-size: 24px;
  position: relative;
  bottom: 5px;
  display: inline-block;
  width: 26px;
  height: 24px;
  vertical-align: bottom;
  g, path {
    fill: ${({ theme }) => theme.icons.actionBar.color};
  }
`;
Icon.displayName = 'Icon';

export const Title = styled.div`
  color: ${({ theme }) => theme.text.modalTitle2};
  font-weight: 500;
  font-size: 21.5px;
  display: inline-block;
`;
Title.displayName = 'Title';
