import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import useKeyPress from 'Hooks/useKeyPress';
import ModalWrapper from 'Common/Components/ModalWrapper';
import { MODAL_USERS_ASSIGN_WS } from 'store/modals/modals';
import { closeModal } from 'store/modals/actions';
import { Accept, Cancel } from 'Common/Components/Button/styled';
import { ModalContent, Subtitle, Title, Buttons } from './styled';
import { saveUserWorkspaces } from 'Screens/Contextualization/Users/actions/Actions';
import InputSearch from 'Screens/Contextualization/ManageEditCreate/components/ManualCreationModal/components/General/InputSearch';
import { selectWorkspaces } from 'store/Faraday/selectors';

const AssignWsModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const workspaces = useSelector(selectWorkspaces);
  const activeWorkspaces = workspaces && workspaces.filter((ws) => ws.active);
  const [workspacesSelected, setWorkspaces] = useState([]);

  const onChange = (newWorkspaces) => setWorkspaces(newWorkspaces);

  const addWorkspace = (value) => {
    const temp = workspacesSelected;
    const found = temp.find((item) => item.id === value.id);
    if (!found) onChange([...workspacesSelected, value]);
  };

  const onRemoveWorkspace = (value) => {
    const temp = workspacesSelected;
    const filteredWorkspaces = temp.filter((item) => item.id !== value.id);
    onChange(filteredWorkspaces);
  };

  const handleClose = () => dispatch(closeModal(MODAL_USERS_ASSIGN_WS));

  const handleAccept = () => {
    if (workspacesSelected) {
      dispatch(saveUserWorkspaces(workspacesSelected));
      handleClose();
    }
  };

  useKeyPress(() => {
    handleAccept();
  }, ['Enter']);

  useKeyPress(() => {
    handleClose();
  }, ['Escape']);

  return (
    <ModalWrapper>
      <ModalContent>
        <Title>{ intl.formatMessage({ id: 'modal.assignWs.title' }) }</Title>
        <Subtitle>{ intl.formatMessage({ id: 'modal.assignWs.subtitle' }) }</Subtitle>
          <InputSearch
            onSelect={ addWorkspace }
            data={ activeWorkspaces }
            placeholder="Search for Workspaces"
            id="users-assign-workpaces"
            title="Workspace(s)"
            addedItems={ workspacesSelected }
            removeItem={ onRemoveWorkspace }
            fullWidth
            height="158px"
          />
        <Buttons>
          <Cancel onClick={ handleClose } children="Cancel" />
          <Accept
            onClick={ handleAccept }
            children="Assign"
          />
        </Buttons>
      </ModalContent>
    </ModalWrapper>
  );
};

export default AssignWsModal;
