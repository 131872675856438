import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as _Warning } from 'Images/warning-delete.svg';

export const Wrapper = styled.div`
  max-width: 505px;
  width: 100%;
  padding: 36px 40px 27px 40px;
  box-shadow: rgb(167 167 167 / 50%) 18px 43px 50px 0px;
  border-top: 10px solid ${colors.softBlue};
  border-radius: 2px 2px 0px 0px;
  overflow: auto;
  max-height: 85%;
  text-align: left;
`;
Wrapper.displayName = 'Wrapper';

export const Header = styled.div`
  display: flex;
  margin-bottom: 17px;
`;
Header.displayName = 'Header';

export const Warning = styled(_Warning)`
  align-self: center;
  margin-right: 11px;
  & > g{
    fill: ${({ theme }) => theme.icons.actionBar.color};
  }
  & > g > g{
    fill: ${({ theme }) => theme.icons.actionBar.color};
  }
`;
Warning.displayName = 'Warning';

export const Title = styled.div`
  display: inline-block;
  font-size: 21.5px;
  font-weight: 600;
  line-height: 1.49;
  color: ${colors.darkBlueGrey};
`;
Title.displayName = 'Title';

export const Message = styled.div`
  display: block;
  font-size: 12.5px;
  color: ${colors.grey19};
`;
Message.displayName = 'Message';

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 25px 0 0;
`;
Footer.displayName = 'Footer';
