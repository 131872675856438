import styled from 'styled-components';
import colors from 'Styles/colors';

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 430px;
  width: 505px;
  background-color: #fff;
  padding: 34px;
  border-top-style: solid;
  border-top-width: 10px;
  border-top-color: ${colors.softBlue};
`;
ModalWrapper.displayName = 'ModalWrapper';

export const Title = styled.div`
  font-weight: 600;
  color: ${({ theme }) => theme.text.sectionTitle} !important;
  line-height: 1.49;
`;
Title.displayName = 'Title';

export const Message = styled.div`
  font-size: 12.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  color: ${colors.greyBlue};
  margin-top: 17px;
`;
Message.displayName = 'Message';

export const ButtonsWrapper = styled.div`
  margin-top: 34px;
  margin-left: auto;
  & > *:not(:first-child) {
    margin-left: 8px;
  }
`;
ButtonsWrapper.displayName = 'ButtonsWrapper';
