import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  width: 249px;
  text-align: left;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 2.08;
  user-select: none;
  text-transform: capitalize;
  color: ${colors.greyBlue};
`;
Title.displayName = 'Title';
