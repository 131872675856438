/* eslint-disable react/jsx-max-depth */
import React from 'react';
import { Col, Row } from 'Common/Components/Grid/index';
import { HeaderText, HeaderTextWrapper, Line } from '../styled';

const Header = () => {
  return (
    <Row onClick={ null }>
      <HeaderTextWrapper width="100px"><Col height={ '28.8px' }><HeaderText>NAME</HeaderText></Col></HeaderTextWrapper>
      <HeaderTextWrapper width="27%"><Col height={ '28.8px' }><HeaderText><Line />ISSUE TYPE</HeaderText></Col></HeaderTextWrapper>
      <HeaderTextWrapper width="100px"><Col height={ '28.8px' }><HeaderText>{ '' }</HeaderText></Col></HeaderTextWrapper>
    </Row>
  );
};

export default Header;
