import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectClientId, selectCategoryId, selectLocationId
} from 'store/Settings/solarWindsSelectors';
import { useIntl } from 'react-intl';

import {
  Content,
  Wrapper,
  WrapperTitle,
  CustomInput,
  Title,
  Row
} from './styled';
import { setField } from 'store/Settings/solarWindsActions';

const Oauth = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const clientId = useSelector(selectClientId);
  const categoryId = useSelector(selectCategoryId);
  const locationId = useSelector(selectLocationId);

  const onChange = (field, value) => dispatch(setField(field, value));

  return (
    <Wrapper>
      <WrapperTitle>
        <Title>{ intl.formatMessage({ id: 'preferences.ticketingTools.solarWinds.settings.oauth.title' }) }</Title>
      </WrapperTitle>
      <Content autoComplete='off'>
        <Row>
          <CustomInput title={ intl.formatMessage({ id: 'preferences.ticketingTools.solarWinds.settings.oauth.clientId' }) } value={ clientId } defaultValue={ clientId } onChange={ (v) => { onChange('client_id', v); } } autocomplete="new-number" name="whd-client-id" id="whd-client-id" mandatory type="number" placeholder="Client ID" min={ 0 } hideArrows />
          <CustomInput title={ intl.formatMessage({ id: 'preferences.ticketingTools.solarWinds.settings.oauth.categoryId' }) } value={ categoryId } defaultValue={ categoryId } onChange={ (v) => { onChange('category_id', v); } } autocomplete="new-number" name="whd-category-id" id="whd-category-id" mandatory type="number" placeholder="Category ID" min={ 0 } hideArrows />
        </Row>
        <Row>
          <CustomInput title={ intl.formatMessage({ id: 'preferences.ticketingTools.solarWinds.settings.oauth.locationId' }) } value={ locationId } defaultValue={ locationId } onChange={ (v) => { onChange('location_id', v); } } autocomplete="new-number" name="whd-location-id" id="whd-location-id" mandatory type="number" placeholder="Location ID" min={ 0 } hideArrows />
        </Row>
      </Content>
    </Wrapper>
  );
};
export default Oauth;
