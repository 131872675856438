/* eslint-disable react/no-children-prop */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Wrapper, Title, CustomInput, ErrorMessage
} from './styled';

const Input = ({
  required, title, type, defaultValue, onChange, disabled, error, ...props
}) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    if (value !== defaultValue) setValue(defaultValue);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue, defaultValue]);

  const onChangeHandler = ({ target }) => {
    setValue(target.value);
    onChange(target.value);
  };

  return (
    <Wrapper disabled={ disabled }>
      <Title disabled={ disabled } required={ required } isEmpty={ !value } children={ title } />
      <CustomInput
        disabled={ disabled }
        error={ error }
        onChange={ onChangeHandler }
        type={ type }
        value={ value }
        required={ required }
        isEmpty={ !value }
        // eslint-disable-next-line react/jsx-props-no-spreading
        { ...props }
      />
      <ErrorMessage children={ error } />
    </Wrapper>
  );
};

export default Input;

Input.propTypes = {
  required: PropTypes.bool,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string,
  type: PropTypes.string
};

Input.defaultProps = {
  required: false,
  defaultValue: '',
  disabled: false,
  error: '',
  title: '',
  type: 'text'
};
