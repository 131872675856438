import styled from 'styled-components';
import colors from 'Styles/colors';
import { slideIn } from 'Styles/effects';

export const ModalContent = styled.div`
  padding: 34px;
  box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.24);
  border-radius: 2px;
  border-top: 10px solid ${colors.softBlue};
  background: ${({ theme }) => theme.backgrounds.modal};
  width: 505px;
  /* height: 705px; */
  height: ${({ height }) => height || '705px'};
  overflow: auto;
  ${slideIn}
`;
ModalContent.displayName = 'ModalContent';

export const Content = styled.div`
display: flex;
flex-direction: column;
text-align: initial;
`;
