/* eslint-disable react/no-children-prop */
import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import Select from 'react-select';
import {
  Title, dropdownStyle, LabelWrapper, OptionsCount, Label
} from '../styled';
import { ThemeContext } from 'Context';
// To see aviable props go to https://react-select.com/props
// options prop must have a label (and value if is Multi) key to work properly
const Dropdown = ({
  className, required, title, isMulti = false, style, ...props
}) => {
  const { themeStyles } = useContext(ThemeContext);
  const styles = dropdownStyle(themeStyles);
  const customStyle = style || styles;

  const formatGroupLabel = (data) => (
    <LabelWrapper>
      <Label>{data.label}</Label>
      <OptionsCount>{data.options.length}</OptionsCount>
    </LabelWrapper>
  );

  const parsedTitle = required ? `${title}*` : title;
  return (
    <div className={ className }>
      {title && <Title children={ parsedTitle } />}
      <Select
        styles={ customStyle }
        formatGroupLabel={ formatGroupLabel }
        closeMenuOnSelect={ !isMulti }
        isMulti={ isMulti }
        required={ required }
        classNamePrefix="custom-select"
        { ...props }
      />
    </div>
  );
};

Dropdown.propTypes = {
  className: PropTypes.string,
  required: PropTypes.bool,
  title: PropTypes.string,
  isMulti: PropTypes.bool
};

Dropdown.defaultProps = {
  className: '',
  required: false,
  title: '',
  isMulti: false
};

export default Dropdown;
