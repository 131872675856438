import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { createAsset, resetState } from 'store/HostEditCreate/actions';
import ModalWrapper from 'Common/Components/ModalWrapper';
import CustomList from 'Common/Components/CustomList';
import { toggleModalEditCreate } from 'store/Host/actions';
import { selectShowModalEditCreate } from 'store/Host/selectors';
import CheckBox from 'Common/Components/Checkbox';
import {
  Title, TitleContainer, Wrapper, ModalBody,
  LeftSide, RightSide,
  WrapperField, Label, TextWrapper, TextField,
  ButtonsWrapper, Description, HostnameTitle, ModalIcon,
  Workspaces
} from './styled';
import InputSearch from 'Screens/Contextualization/ManageEditCreate/components/ManualCreationModal/components/General/InputSearch';
import { selectActiveWorkspaces } from 'store/Workspace/selectors';
import { createAssetOutsideWs } from 'store/HostsGeneral/actions';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_HOSTS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import AssetNameInput from './components/AssetName';
import { Accept, Cancel } from 'Common/Components/Button/styled';

const HostModalCreate = ({ assetsGeneral }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState({
    ip: '',
    os: '',
    mac: '',
    description: '',
    owned: false,
    hostnames: [],
    workspaces: [],
    default_gateway: 'None'
  });
  const showModalEditCreate = useSelector((state) => (selectShowModalEditCreate(state)));
  const [isValidMacAddress, setIsValidMacAddress] = useState(true);
  const workspacesOptions = useSelector(selectActiveWorkspaces);
  const canUpdate = useSelector((state) => selectAllowedByRole(state, FEATURE_HOSTS, PERMISSION_TYPES.CREATE));

  const onChange = (field, value) => setData({ ...data, [field]: value });

  const validateMacAddress = (str) => {
    // eslint-disable-next-line prefer-regex-literals
    const regex = new RegExp('^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})|([0-9a-fA-F]{4}\\.[0-9a-fA-F]{4}\\.[0-9a-fA-F]{4})$');
    setIsValidMacAddress(!!regex.test(str));
  };

  const onChangeMac = (value) => {
    onChange('mac', value);
    if (value === '') setIsValidMacAddress(true);
    else validateMacAddress(value);
  };

  const onAddHostname = (value) => setData({ ...data, hostnames: [...data.hostnames, value] });

  const onRemoveHostname = (indexToRemove) => {
    const newHostnames = data.hostnames.filter((item, index) => index !== indexToRemove);
    setData({ ...data, hostnames: newHostnames });
  };

  const setWorkspace = (value) => {
    const temp = data.workspaces;
    const found = temp.find((item) => item.id === value.id);
    if (!found) onChange('workspaces', [...data.workspaces, value]);
  };

  const onRemoveWorkspace = (value) => {
    const temp = data.workspaces;
    const filteredWorkspaces = temp.filter((item) => item.id !== value.id);
    onChange('workspaces', filteredWorkspaces);
  };

  const resetData = () => {
    setData({
      ip: '',
      os: '',
      mac: '',
      description: '',
      owned: false,
      hostnames: [],
      workspaces: [],
      default_gateway: 'None'
    });
  };

  const create = () => {
    assetsGeneral ? dispatch(createAssetOutsideWs(data)) : dispatch(createAsset(data));
    resetData();
  };

  const canCreate = () => {
    if (assetsGeneral) return !data.ip || !isValidMacAddress || isEmpty(data.workspaces);
    else return (!data.ip || !isValidMacAddress);
  };

  useEffect(() => () => resetData(), [dispatch]);

  return showModalEditCreate && (
    <ModalWrapper>
      <Wrapper>
        <TitleContainer>
          <Title><FormattedMessage id="host.creation.title" /></Title>
          <ButtonsWrapper>
            <Cancel onClick={ () => { dispatch(toggleModalEditCreate(false)); resetData(); dispatch(resetState()); } } id="cancelButton">
              <FormattedMessage id="cancel" />
            </Cancel>
            <Accept disabled={ canCreate() } onClick={ create } id="createButton"><FormattedMessage id="create" /></Accept>
            <ModalIcon />
          </ButtonsWrapper>
        </TitleContainer>
        <ModalBody>
          <LeftSide>
            <TextWrapper>
              <AssetNameInput
                ip={ data.ip }
                handleChange={ (value) => onChange('ip', value) }
              />
              <TextField title="Operating System" name="os" width="190px" placeholder="Unix" value={ data.os } onChange={ (value) => onChange('os', value) } />
            </TextWrapper>
            <TextField title="MAC Address" name="mac address" width="265px" placeholder="00:00:5e:00:53:af" value={ data.mac } onChange={ onChangeMac } error={ !isValidMacAddress } errorMessage="Invalid MAC Address" />
            <Description height="145px" title="Description" name="description" placeholder="Click to add a description" value={ data.description } onChange={ (value) => onChange('description', value) } isEmpty={ !data.description.length } />
            <WrapperField>
              <CheckBox checked={ data.owned } onChange={ () => onChange('owned', !data.owned) } state={ data.owned } />
              <Label>Has been owned</Label>
            </WrapperField>
          </LeftSide>
          <RightSide>
            <HostnameTitle>Add Hostnames</HostnameTitle>
            <CustomList
              key="HOST_CREATION_HOST_NAMES"
              title="Hostname"
              placeholder="net.hostname.com"
              addItem={ onAddHostname }
              removeItem={ onRemoveHostname }
              listData={ data.hostnames }
              height="34px"
              canUpdate={ canUpdate }
            />
            { assetsGeneral &&
              <Workspaces>
                <InputSearch
                  onSelect={ setWorkspace }
                  data={ workspacesOptions }
                  placeholder="Add a Workspace"
                  id="asset-add-workpaces"
                  title="Workspace(s)"
                  required
                  addedItems={ data.workspaces }
                  removeItem={ onRemoveWorkspace }
                  fullWidth
                  height="224px"
                />
              </Workspaces>}
          </RightSide>
        </ModalBody>
      </Wrapper>
    </ModalWrapper>
  );
};

export default HostModalCreate;
