import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as LockImage } from 'Images/ws-lock.svg';
import { ReactComponent as UnlockImage } from 'Images/ws-unlock.svg';
import { ReactComponent as MoreIcon_ } from 'Images/icon-action-bar-more.svg';
import { ReactComponent as ArchiveImage } from 'Images/archive.svg';
import { ReactComponent as UnarchiveImage } from 'Images/unarchive.svg';
import { disableTextSelection } from 'Styles/effects/text';

export const Wrapper = styled.div`
  width: 400px;
  height: 450px;
  margin: 0 30px 33px 0;
  padding: 20px 0 22px 32px;
  border-radius: 2px;
  box-shadow: 0 2px 20px 0 rgba(28, 34, 67, 0.05);
  border: solid 1px ${({ theme }) => theme.borders.workspaceItem};
  background-color: ${({ theme }) => theme.backgrounds.workspaceItem};
  text-align: left;
  cursor: pointer;

  ${({ selected, theme }) => selected === true && `
    border: 1px solid ${theme.borders.workspaceSelectedItem};
  `}
  
  ${disableTextSelection}

  & >:not(:first-child) {
    margin-right: 30px;
    ${({ active }) => (active ? 'opacity: 1' : 'opacity: 0.55')};
  }

  & >:first-child > :not(:last-child) {
    ${({ active }) => (active ? 'opacity: 1' : 'opacity: 0.55')};
  }
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${({ theme }) => theme.text.workspaceItemTitle};
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }

`;
Title.displayName = 'Title';

export const Label = styled.div`
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${({ theme }) => theme.text.workspaceItem};
`;
Label.displayName = 'Label';

export const Text = styled(Label)`
  font-weight: 400;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
Text.displayName = 'Text';

export const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 2px;
  & > *:not(:last-child) {
    margin-right: 8px;
  }
`;
FieldContainer.displayName = 'FieldContainer';

export const ReadOnlyContainer = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  & > *:not(:last-child) {
    margin-right: 8px;
  }
`;
ReadOnlyContainer.displayName = 'ReadOnlyContainer';

export const ActiveContainer = styled.div`
  display: flex;
  align-items: center;
  & > *:not(:last-child) {
    margin-right: 8px;
  }
`;
ActiveContainer.displayName = 'ActiveContainer';

export const LockIcon = styled(LockImage)`
  cursor: default;
  g > path {
    fill: ${({ theme }) => theme.icons.workspaceItem.color};
  }
`;
LockImage.displayName = 'LockImage';

export const UnlockIcon = styled(UnlockImage)`
  cursor: default;
  g > path {
    fill: ${({ theme }) => theme.icons.actionBar.color};
  }
`;
UnlockIcon.displayName = 'UnlockIcon';

export const MoreActionsWrapper = styled.div`
  margin: 0 16px 0 auto;
  position: relative;
`;
MoreActionsWrapper.displayName = 'MoreActionsWrapper';

export const MoreButton = styled.div`
  border-radius: 20px;
  width: 37px;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: ${colors.iceBlue};
  }
`;
MoreButton.displayName = 'MoreButton';

export const MoreIcon = styled(MoreIcon_)`
  width: 16px;
  transform: rotate(90deg);
  .st0 {
    fill: ${({ theme }) => theme.icons.actionBar.color} !important;
  }
`;
MoreIcon.displayName = 'MoreIcon';

export const Dropdown = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 3;
  top: 16px;
  right: -109px;
  width: 118px;
  height: 77px;
  padding: 7px 0 9px 0;
  border-radius: 3px;
  border: solid 1px ${({ theme }) => theme.borders.importanceMeter};
  background-color: ${({ theme }) => theme.backgrounds.actionDropDown};
  & >:first-child svg {
    margin-left: 14.5px;
  }
  & >:nth-child(2) svg {
    margin-left: 13px;
  }
`;
Dropdown.displayName = 'Dropdown';

export const DropdownItem = styled.div`
  padding: 6px 0;
  display: flex;

  &:hover {
    background-color: ${({ theme }) => theme.gantt.project};
  }
`;
DropdownItem.displayName = 'DropdownItem';

export const DropdownText = styled.p`
  font-size: 13px;
  color: ${({ theme }) => theme.icons.actionBar.color};
  margin-left: 13px;
`;
DropdownText.displayName = 'DropdownText';

export const ArchiveIcon = styled(ArchiveImage)`
  path, rect {
    stroke: ${({ theme }) => theme.icons.actionBar.color};
  }
`;
LockImage.displayName = 'LockImage';

export const UnarchiveIcon = styled(UnarchiveImage)`
  g > path {
    fill: ${({ theme }) => theme.icons.workspaceItem.color};
  }
`;
LockImage.displayName = 'LockImage';
