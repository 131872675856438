import styled from 'styled-components';
import { ReactComponent as TrashIcon } from 'Images/icon-action-bar-trash.svg';

export const IconWrapper = styled.div`
  display: none;
  flex: 0 0 16px;
  margin: auto;
  padding-bottom: 3px;
`;
IconWrapper.displayName = 'IconWrapper';

export const Trash = styled(TrashIcon)`
  width: 16px;
    g path {
      fill: ${({ theme }) => theme.icons.taskUsers.color};
    }
`;
Trash.displayName = 'Tash';
