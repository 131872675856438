import styled from 'styled-components';
import DebouncedInput from 'Common/Components/v2/DebouncedInput';
import { Input } from 'Common/Components/StandardTextField/styled';

export const Wrapper = styled.div`
  padding: 0;
`;
Wrapper.displayName = 'Wrapper';

export const Content = styled.div`
  display: flex;
  flex-flow: wrap;
`;
Content.displayName = 'Content';

export const CustomInput = styled(DebouncedInput)`
  span {
    font-weight: 400;
    margin-right: 3px;
  }
  ${Input} {
    &::placeholder,
    &::-webkit-input-placeholder {
      font-size: 14.5px;
      color: ${({ theme }) => theme.text.taskPlaceholder};
    }
  }
`;
CustomInput.displayName = 'CustomInput';

export const Url = styled(CustomInput)`
  margin-right: 32px;
  width: 267px;
 
`;
Url.displayName = 'Url';
