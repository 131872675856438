/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { TableHeader } from '../../styled';
import {
  VulnsCountCell,
  CreatorCell, LastActivityCell,
  WorkspacesCell
} from '.';
import NameCell from './components/NameCell';
import { CheckReport } from '../CheckReport';
import { CheckReportHeader } from '../CheckReportHeader';

export default function getColumns (isFetching) {
  const columns = [
    {
      Header: () => <CheckReportHeader />,
      id: 'selected',
      accessor: 'Selected',
      show: true,
      sortable: false,
      resizable: false,
      maxWidth: 55,
      minWidth: 55,
      headerStyle: { overflow: 'visible' },
      style: { display: 'flex' },
      Cell: (rowInfo) => <CheckReport report={ rowInfo.original } showSkeleton={ null } />
    },
    {
      Header: () => <TableHeader>NAME</TableHeader>,
      id: 'name',
      resizable: false,
      maxWidth: 411,
      Cell: (rowInfo) => <NameCell rowInfo={ rowInfo } isFetching={ isFetching } />
    }, {
      Header: () => <TableHeader>VULNS</TableHeader>,
      id: 'vuln_count',
      key: 'vuln_count',
      maxWidth: 102,
      resizable: false,
      Cell: (rowInfo) => <VulnsCountCell rowInfo={ rowInfo } isFetching={ isFetching } />
    }, {
      Header: () => <TableHeader>CREATOR</TableHeader>,
      id: 'creator',
      key: 'creator',
      maxWidth: 119,
      resizable: false,
      Cell: (rowInfo) => <CreatorCell rowInfo={ rowInfo } isFetching={ isFetching } />
    }, {
      Header: () => <TableHeader>LAST ACTIVITY</TableHeader>,
      resizable: false,
      id: 'create_time',
      maxWidth: 138,
      Cell: (rowInfo) => <LastActivityCell rowInfo={ rowInfo } isFetching={ isFetching } />
    }, {
      Header: () => <TableHeader>WORKSPACES</TableHeader>,
      id: 'workspaces',
      key: 'workspaces',
      maxWidth: 140,
      sortable: false,
      resizable: false,
      Cell: (rowInfo) => <WorkspacesCell rowInfo={ rowInfo } isFetching={ isFetching } />
    }];
  return columns;
}
