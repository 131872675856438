import styled from 'styled-components';
import colors from 'Styles/colors';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  font-size: 14.5px;
  color: ${colors.blueGrey};
  border-radius: 2px;
  background-color: ${({ theme }) => theme.backgrounds.table};
  height: 50px;
  width: 100%;
`;

export default Wrapper;
