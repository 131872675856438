import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  margin-top: 10px;
  margin-left: auto;
  & > :first-child {
    margin-right: auto;
  }
`;
Wrapper.displayName = 'Wrapper';

export const Label = styled.div`
  font-size: 15px;
  color: ${({ theme }) => theme.text.sideModaTitle};
  display: flex;
  align-items: center;
`;
Label.displayName = 'Label';
