import styled from 'styled-components';
import { ReactComponent as FaradayWhite } from 'Images/faraday_white_logo.svg';
import { ReactComponent as Faraday } from 'Images/faraday_logo.svg';

const wrapperStyle = {
  light: 'linear-gradient(37deg, #cad3db 1%, #f1f5f9 81%, #ececec 92%, #d3d3d3 104%, #dedede 110%)',
  dark: 'linear-gradient(38deg, #410a20 1%, #141414 74%, #565656)',
  generic: 'linear-gradient(32deg, #fff 84%, #d3d3d3 109%, #dedede 111%)'
};

const titleWrapperStyle = {
  light: 'linear-gradient(51deg, #cad3db 24%, #f1f5f9 93% )',
  dark: 'linear-gradient(51deg, #141414 24%, #410a20 75%)',
  generic: ''
};

const textColorStyle = {
  light: '#1c2243',
  dark: '#fff',
  generic: '#1c2243'
};

export const Wrapper = styled.div`
  margin: 0px auto auto auto;
  width: 100%;
  max-width: 589px;
  flex: 0 0 753px;
  display: flex;
  flex-direction: column;
  background-image: ${({ themeTemplate }) => wrapperStyle[themeTemplate]};
  border: solid 1px #ddd;
  box-shadow: ${({ theme }) => theme.boxShadow.modal};
`;
Wrapper.displayName = 'Wrapper';

export const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 258px;
    margin: 0px 0px 104px 0px;
    background-image: ${({ themeTemplate }) => titleWrapperStyle[themeTemplate]};
`;
Wrapper.displayName = 'Wrapper';

export const GenericTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0px 0px 299px 0px;
    background-image: ${({ themeTemplate }) => titleWrapperStyle[themeTemplate]};
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
    margin-top: ${({ themeTemplate }) => (themeTemplate === 'generic' ? '0px' : '80px')};
    margin-right: auto;
    margin-bottom: 31px;
    margin-left: 135px;
    min-height: 25px;
    text-align: left;
    user-select: none;
    font-size: 21px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${({ themeTemplate }) => textColorStyle[themeTemplate]};
`;
Title.displayName = 'Title';

export const Enterprise = styled.div`
    min-height: 17px;
    text-align: left;
    user-select: none;
    margin: 0 50px auto 135px;
    font-size: 14px;
    font-weight: lighter;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${({ themeTemplate }) => textColorStyle[themeTemplate]};
`;
Enterprise.displayName = 'Enterprise';

export const StyledDate = styled.div`
  user-select: none;
  text-align: left;
  margin-left: 135px;
  font-size: 9px;
  font-weight: lighter;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${({ themeTemplate }) => textColorStyle[themeTemplate]};
`;
Enterprise.displayName = 'Enterprise';

export const FaradayWhiteLogo = styled(FaradayWhite)`
    cursor: default;
    width: 85px;
    height: 17px;
    margin: 93px auto 74px 135px;
`;
FaradayWhiteLogo.displayName = 'FaradayWhiteLogo';

export const FaradayLogo = styled(Faraday)`
    cursor: default;
    width: 85px;
    height: 17px;
    margin: 93px auto 74px 135px;
`;
FaradayLogo.displayName = 'FaradayLogo';

export const Separator = styled.div`
    margin: 152px 0px 19px auto;
    width: 75%;
    height: 3px;
    opacity: 0.32;
    background-color: #d8d8d8;
`;
Separator.displayName = 'Separator';
