/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 10px 0px 0px 9px;
`;
Wrapper.displayName = 'Wrapper';
