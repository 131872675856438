import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import ExpandableWrapper from 'Screens/Contextualization/Manage/components/VulnerabilityDetail/components/Tabs/components/GeneralTab/components/ExpandableWrapper';
import useExpandable from 'Hooks/useExpandable';
import { Wrapper, Row, Field, TextField, Label, RoleDropdown, Workspaces } from './styled';
import { FEATURE_USERS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { selectedUserType, selectParsedWorkspaces, selectUserDetail } from 'store/Users/selectors';
import { updateUser } from 'Screens/Contextualization/Users/actions/Actions';
import InputSearch from 'Screens/Contextualization/ManageEditCreate/components/ManualCreationModal/components/General/InputSearch';
import { selectWorkspaces } from 'store/Faraday/selectors';
import { selectLdapUseLocalRoles } from 'store/Config/selector';

const ID = 'mainInfo';

const MainInfo = () => {
  const userSelected = useSelector(selectUserDetail);
  const parsedWorkspaces = useSelector(selectParsedWorkspaces);
  const getUser = (elem, defaultValue) => get(userSelected, elem, defaultValue);
  const [data, setData] = useState({
    name: getUser('username', ''),
    role: getUser('roles[0]', ''),
    password: '',
    repeatPassword: '',
    email: getUser('email', ''),
    workspaces: parsedWorkspaces
  });
  const isLdapUsingLocalRoles = useSelector(selectLdapUseLocalRoles);
  const userType = useSelector(selectedUserType);
  const isLdap = userType === 'ldap';
  const workspaces = useSelector(selectWorkspaces);
  const activeWorkspaces = workspaces && workspaces.filter((ws) => ws.active);
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'users.userDetail.MainInfo' });
  const allowedByRole = useSelector((state) => selectAllowedByRole(state, FEATURE_USERS, PERMISSION_TYPES.UPDATE));
  const dispatch = useDispatch();
  const isExpanded = useExpandable('general', ID, true);

  useEffect(() => {
    setData({
      name: getUser('username', ''),
      role: getUser('roles[0]', '') === 'asset_owner' ? 'asset owner' : getUser('roles[0]', ''),
      password: '',
      repeatPassword: '',
      email: getUser('email', ''),
      workspaces: parsedWorkspaces
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSelected]);

  const onChangeInput = (field, value) => setData((prevData) => ({ ...prevData, [field]: value }));

  const updatePassword = () => {
    if (data.password === data.repeatPassword) dispatch(updateUser('password', data.password));
  };

  const onChange = (newWorkspaces) => {
    dispatch(updateUser('workspaces', newWorkspaces));
  };

  const addWorkspace = (value) => {
    const temp = data.workspaces;
    const found = temp.find((item) => item.name === value.name);
    if (!found) onChange([...data.workspaces, { name: value.name }]);
  };

  const onRemoveWorkspace = (value) => {
    const temp = data.workspaces;
    const filteredWorkspaces = temp.filter((item) => item.name !== value.name);
    onChange(filteredWorkspaces);
  };

  const changeRole = (v) => {
    if (v === 'asset owner') dispatch(updateUser('role', 'asset_owner'));
    else dispatch(updateUser('role', v));
  };

  return (
    <ExpandableWrapper id={ ID } title={ title } defaultValue={ isExpanded } tab="general">
      <Wrapper>
        <Row>
        <Field>
            <TextField title={ intl.formatMessage({ id: 'users.userDetail.username' }) } name="username" value={ data.name } onChange={ (value) => onChangeInput('name', value) } onBlur={ () => dispatch(updateUser('name', data.name)) } disabled={ !allowedByRole } />
        </Field>
          <Field>
            <Label>{ intl.formatMessage({ id: 'users.userDetail.role' }) }</Label>
            <RoleDropdown updateValue={ (v) => changeRole(v) } defaultValue={ data.role } id="test_user_edit_role" disabled={ !allowedByRole || (isLdap && !isLdapUsingLocalRoles) } />
          </Field>
        </Row>
        <Row>
          <Field>
            <TextField title={ intl.formatMessage({ id: 'users.userDetail.password' }) } name="password" value={ data.password } type="password" autoComplete="new-password" placeholder="**************" onChange={ (value) => onChangeInput('password', value) } disabled={ !allowedByRole } />
          </Field>
          <Field>
            <TextField title={ intl.formatMessage({ id: 'users.userDetail.repeatPassword' }) } name="repeatPassword" value={ data.repeatPassword } type="password" autoComplete="new-password" placeholder="**************" onChange={ (value) => onChangeInput('repeatPassword', value) } onBlur={ () => updatePassword('password', data.password) } disabled={ !allowedByRole } />
          </Field>
        </Row>
        <Row>
          <Field>
            <TextField title={ intl.formatMessage({ id: 'users.userDetail.email' }) } name="email" value={ data.email } onChange={ (value) => onChangeInput('email', value) } onBlur={ () => dispatch(updateUser('email', data.email)) } disabled={ !allowedByRole } />
          </Field>
        </Row>
        {/* <Row> */}
          <Workspaces>
            <InputSearch
              onSelect={ addWorkspace }
              data={ activeWorkspaces }
              placeholder="Search for Workspaces"
              id="users-assign-workpaces"
              title="Workspace(s)"
              addedItems={ data.workspaces }
              removeItem={ onRemoveWorkspace }
              fullWidth
              height="158px"
            />
          </Workspaces>
        {/* </Row> */}
      </Wrapper>
    </ExpandableWrapper>
  );
};

export default MainInfo;
