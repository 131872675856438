import React from 'react';
import { useDispatch } from 'react-redux';
import AddButton from 'Common/Components/AddButton';
import { redirect } from 'store/Router/actions';
import { LeftGroup, RightGroup, Separator, Group } from 'Common/Components/Contextualization/CustomActionBar/styled';
import Edit from './Edit';
import Duplicate from './Duplicate';
import Delete from './Delete';
import { Wrapper } from './styled';

// import { selectShowLegacyUI } from 'store/Preferences/selectors';

const NewGraph = () => {
  const dispatch = useDispatch();
  return <AddButton text="Create New Graph" onClick={ () => dispatch(redirect('/analytics/new')) } />;
};

// create new graph, list view, grid view
const ActionBar = () => {
  // const showLegacyUI = useSelector(selectShowLegacyUI);

  return (
  <Wrapper>
    {/* { showLegacyUI &&
      <TitleWrapper>
        <Title>Analytics</Title>
        <Subtitle>- My graphs</Subtitle>
      </TitleWrapper>
    } */}
    <LeftGroup>
      <NewGraph />
      <Separator />
      <Group>
        <Edit />
        <Duplicate />
        <Delete />
      </Group>
    </LeftGroup>
    <RightGroup />
  </Wrapper>
  );
};

export default ActionBar;
