import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Wrapper, CloseIconWrapper, CloseIcon, IconUser, UsernameWrapper, Username } from './styled';
import { showUserDetail } from 'Screens/Contextualization/Users/actions/Actions';
import { selectedUserUsername } from 'store/Users/selectors';

const Header = () => {
  const dispatch = useDispatch();
  const username = useSelector(selectedUserUsername);

  return (
    <Wrapper>
      <UsernameWrapper>
        <IconUser />
        <Username>{ username }</Username>
      </UsernameWrapper>
      <CloseIconWrapper onClick={ () => dispatch(showUserDetail(false)) }>
        <CloseIcon />
      </CloseIconWrapper>
    </Wrapper>
  );
};

export default Header;
