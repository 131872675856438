import styled from 'styled-components';
import colors from 'Styles/colors';

const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  width: 37px;
  height: 37px;
  align-items: center;
  justify-content: center;
  & > svg {
    cursor: pointer;
    min-width: 17px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.icons.actionBar.background};
    border-radius: 100%;
  }

  &:active {
    background-color: ${({ theme }) => theme.backgrounds.reportActiveActionButton};
    & > svg {
      g, path {
        fill: ${colors.white};
      }
    }
  } 
`;
Wrapper.displayName = 'Wrapper';

export default Wrapper;
