import RadioButton from 'Common/Components/RadioButton/index';
import styled, { css } from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  text-align: left;
  padding: 36px 39px 80px 39px;
  overflow: auto;
  background: ${({ theme }) => theme.backgrounds.sideModal};
  height: calc(100% - 90px);
  width: 588px;
`;
Wrapper.displayName = 'Wrapper';

export const WrapperTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 14px;
  padding-right: 39px;
`;
WrapperTitle.displayName = 'WrapperTitle';

export const Title = styled.div`
  font-size: 17px;
  font-weight: 600;
  color: ${({ theme }) => theme.text.sideModaTitle};
  padding-top: 27px;
  padding-bottom: 20px;
`;
Title.displayName = 'Title';

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  & > :first-child {
    margin-right: 8px;
  }
`;
ButtonsWrapper.displayName = 'ButtonsWrapper';

export const Content = styled.div`
  overflow-y: auto;
  padding-right: 32px;
`;

export const DescriptionSection = styled.div`
  padding-bottom: 31px;
`;
DescriptionSection.displayName = 'DescriptionSection';

export const Label = styled.div`
  font-size: 12.5px;
  font-weight: normal;
  letter-spacing: 0.09px;
  color: ${colors.grey17};
  padding-bottom: 8px;
  &:after {
    content: '*';
    color: ${colors.redPink};
  }
`;
Label.displayName = 'Label';

export const Description = styled.input`
  height: 34px;
  padding: 9px 9px 7px 10px;
  background-color: ${({ theme }) => theme.textfield.background};
  border: 0;
  width: 78%;
  margin-right: 20px;
  color: ${({ theme }) => theme.textfield.text};
  &:focus {
    background-color: ${({ theme }) => theme.textfield.focus};
    color: ${({ theme }) => theme.textfield.text};
  }
  
  &::placeholder {
    font-size: 14.5px;
    color: ${({ theme }) => theme.text.placeholder};
  }

  &:-webkit-autofill {
    background-color: ${({ theme }) => theme.textfield.background} !important;
    color: ${({ theme }) => theme.textfield.text} !important;
    box-shadow: 0 0 0px 1000px ${({ theme }) => theme.textfield.background} inset !important;
    -webkit-text-fill-color: ${({ theme }) => theme.textfield.text} !important; /* Evitar el cambio de color del texto */
    border: none !important;
  }
`;
Description.displayName = 'Description';

export const SetupSection = styled.div`
  padding-bottom: 31px;
`;
SetupSection.displayName = 'SetupSection';

export const Subtitle = styled.div`
  font-size: 17px;
  font-weight: 500;
  color: ${({ theme }) => theme.text.sideModaLabel};
  padding-bottom: 14px;
`;
Subtitle.displayName = 'Subtitle';

export const DescriptionSchedule = styled.div`
  font-size: 13.5px;
  font-weight: normal;
  line-height: 1.33;
  color: ${colors.blueGrey};
  padding-bottom: 21px;
`;
DescriptionSchedule.displayName = 'DescriptionSchedule';

export const FieldWrapper = styled.div`
  border-radius: 3px;
  box-shadow: 2px 2px 5px 0 rgba(97, 97, 97, 0.06);
  border: solid 1px #dbdbdb;
  padding: 22px 22px 0px 22px;
  margin-bottom: 20px;
  ${(props) => props.disabled && css`
    pointer-events: none;
    opacity: 0.5;
  `}
`;
FieldWrapper.displayName = 'FieldWrapper';

export const WrapperAgent = styled.div`
  padding-bottom: 30px;
`;
WrapperAgent.displayName = 'WrapperAgent';

export const WrapperToolParams = styled.div`
  border: solid 1px ${({ theme }) => theme.borders.tool};
  padding: 21px;
  background-color: ${({ theme }) => theme.backgrounds.editModal};
  margin-bottom: 30px;
  border-radius: 4px;
  box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.1);
  background-color: ${({ theme }) => theme.backgrounds.scheduleWrapper};
  ${({ isCloud }) => isCloud && `
    padding: 2px 21px;
  `}
`;
WrapperToolParams.displayName = 'WrapperToolParams';

export const AgentSection = styled.div`
`;
AgentSection.displayName = 'AgentSection';

export const CustomRadioButton = styled(RadioButton)`
  color: ${colors.greyBlue};
  font-size: 12.5px;
`;
CustomRadioButton.displayName = 'CustomRadioButton';

export const CustomCronExpressionLink = styled(RadioButton)`
  color:${colors.blueCerulean};
  font-size: 13.5px;
  font-weight: 500;
  padding-left: 0;
  margin: 0 15px 0 0;
  & > div {
    display: none;
  }
`;
CustomCronExpressionLink.displayName = 'CustomCronExpressionLink';

export const ToolSubtitle = styled.p`
  font-size: 13.5px;
  color: ${colors.greyBlue};
  margin-top: 14px;
`;
ToolSubtitle.displayName = 'ToolSubtitle';
