import styled from 'styled-components';
import { customFieldContent } from 'Styles/styles';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

Wrapper.displayName = 'Wrapper';

export const Buttons = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 34px;
    > :nth-child(1){
        margin-right: 8px;
    }
`;

Buttons.displayName = 'Buttons';

export const Content = styled.span`
    ${customFieldContent}
`;

Content.displayName = 'Content';

export const Input = styled.input`
  position: relative;
  padding: 5px 5px 5px 15px;
  font-size: 14.5px;
  border: none;
  box-shadow: inset 0 -1px 0 0 ${({ theme }) => theme.shadows.inputInset};
  background-color: ${({ theme }) => theme.textfield.background};
  color: ${({ theme }) => theme.textfield.text};
  width: 140px;
  height: 34px;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &::-webkit-calendar-picker-indicator {
    background-color: transparent;
    color: ${({ theme }) => theme.textfield.text};
    padding: 5px;
    border-radius: 3px;
    cursor: pointer;
  }

  &::placeholder {
    color: #777;
  }

  &:focus {
    outline: none;
    border-color: #888;
    box-shadow: 0 0 5px rgba(136, 136, 136, 0.5);
  }

  &::-webkit-inner-spin-button {
    z-index: 1;
  }

 &::-webkit-clear-button {
    z-index: 1;
 }
`;
Input.displayName = 'Input';
