import styled from 'styled-components';
import { ReactComponent as Gitlab } from 'Images/preferences_gitlab_logo.svg';

export const Wrapper = styled.div`
  font-size: 21.5px;
  min-height: 100%;
  max-height: 100%;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  font-size: 21.5px;
`;
Title.displayName = 'Title';

export const Content = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 0;
  & > *:not(:last-child) {
    border-bottom: 1px;
    border-bottom-style: solid;
    border-bottom-color: #e9e9e9;
  }
`;
Content.displayName = 'Content';

export const GitLabLogo = styled(Gitlab)`
  margin: 10px 0 9px 0;
`;
