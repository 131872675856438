import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  display: flex;
  padding: 11px;
  box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.03);
  background-color: #fff;
  background-color: ${({ theme }) => theme.backgrounds.modal2};
  border: 0;
  border-style: solid;
  border-left-width: 6px;
  border-color: ${colors.tangerine};
  margin-top: 19px;
`;
Wrapper.displayName = 'Wrapper';

export const Description = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.text.modalTitle2};
`;
Description.displayName = 'Description';

export const Title = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: ${({ theme }) => theme.text.modalTitle2};
  margin-right: 5px;
`;
Title.displayName = 'Title';
