import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as SplitViewIcon } from 'Images/icon_task_view.svg';
import { ReactComponent as ListViewIcon } from 'Images/icon_list.svg';

export const Wrapper = styled.div`
  margin-left: auto;
  user-select: none;
  align-items: center;
  background-color: ${({ theme }) => theme.buttons.custom.background};
  border-radius: 2px;
  border: solid ${(props) => (props.border === 0 || props.border > 0 ? props.border : 1)}px ${({ theme }) => theme.buttons.custom.border};
  cursor: pointer;
  display: flex;
  font-size: 11.5px;
  font-weight: 500;
  height: 34px;
  padding: 0 10px;
  color: ${({ disabled, theme }) => `${disabled ? colors.grey1 : theme.buttons.custom.color}`};
  
  & > svg {
    margin-right: 10px;
    & g > path {
      fill: ${({ theme }) => theme.buttons.custom.color};
    }
    & > path {
      fill: ${({ theme }) => theme.buttons.custom.color};
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.buttons.custom.backgroundHover};
    border-radius: 3px;
    border: solid ${(props) => (props.border === 0 || props.border > 0 ? props.border : 1)}px #d8dee5;
  }
  
  ${({ disabled }) => {
    if (!disabled) return null;
    return `
      pointer-events: none;
      background-color: ${colors.white};
      & > svg {
        & > path {
          fill: ${colors.grey26};
        }
      }
      `;
  }}
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div``;
Title.displayName = 'Title';

export const SplitIcon = styled(SplitViewIcon)``;
SplitIcon.displayName = 'SplitIcon';

export const ListIcon = styled(ListViewIcon)`
  width: 19px;
`;
ListIcon.displayName = 'ListIcon';
