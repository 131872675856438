import styled from 'styled-components';

export const Button = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.buttons.custom.background};
  border-radius: 2px;
  border: ${({ theme }) => `solid 1px ${theme.buttons.custom.border}`};
  cursor: pointer;
  display: flex;
  font-size: 11.5px;
  font-weight: 500;
  height: 34px;
  justify-content: center;
  line-height: 34px;
  padding: 0 10px;
  & > svg {
    margin-right: 10px;
     & > path {
      fill: ${({ theme }) => theme.buttons.custom.color};
    }
  }
  &:hover {
    background-color: ${({ theme }) => theme.buttons.custom.backgroundHover};
    border-radius: 3px;
    border: ${({ theme }) => `solid 1px ${theme.buttons.custom.border}`};
  }
`;
Button.displayName = 'Button';

export const Text = styled.div`
  appearence: none;
  color: ${({ theme }) => theme.buttons.custom.color};
  font-size: 11.5px;
  font-weight: 500;
  line-height: 34px;
`;
Text.displayName = 'Text';
