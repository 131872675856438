import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  width: 100%;
  max-width: 505px;
  background: ${({ theme }) => theme.backgrounds.modal};
  text-align: left;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  font-size: 21.5px;
  font-weight: 600;
  line-height: 1.49;
  padding-bottom: 21px;
  color: ${({ theme }) => theme.text.modalTitle};
`;
Title.displayName = 'Title';

export const Subtitle = styled.div`
  font-size: 12.5px;
  font-weight: normal;
  line-height: 1.44;
  color: ${({ theme }) => theme.text.modalTitle2};
`;
Subtitle.displayName = 'Subtitle';

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20px 0px 0px;
`;
Footer.displayName = 'Footer';

export const DefaultButton = styled.div`
  cursor: pointer;
  width: 89px;
  line-height: 34px;
  margin: 0 8px 0 0;
  border-radius: 2px;
  border: 0px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  &:active {
    transform: translateY(1px);
  }
`;
DefaultButton.displayName = 'DefaultButton';

export const Close = styled(DefaultButton)`
  border: solid 1px ${colors.dark6};
  color: ${({ theme }) => theme.text.modalTitle};
`;
Close.displayName = 'Close';

export const Save = styled(DefaultButton)`
  background: ${colors.blueCerulean};
  color: ${colors.white};
`;
Save.displayName = 'Save';

export const Blue = styled.span`
  color: ${colors.blueCerulean};
  margin: 0 5px;
`;
Blue.displayName = 'Blue';
