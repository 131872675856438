import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as _Warning } from 'Images/warning-delete.svg';
import { slideIn } from 'Styles/effects';
import { Accept } from '../Button/styled';

export const ModalContent = styled.div`
  padding: 34px;
  box-shadow: ${({ theme }) => theme.boxShadow.modal};
  border-radius: 2px;
  border-top: 10px solid ${colors.softBlue};
  background: ${({ theme }) => theme.backgrounds.modal};
  width: 505px;
  ${slideIn}
`;
ModalContent.displayName = 'ModalContent';

export const ModalTitle = styled.div`
  display: flex;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.text.modalTitle2};
`;
ModalTitle.displayName = 'ModalTitle';

export const Warning = styled(_Warning)`
  align-self: center;
  & > g{
    fill: ${({ theme }) => theme.icons.actionBar.color};
  }
  & > g > g{
    fill: ${({ theme }) => theme.icons.actionBar.color};
  }
`;
Warning.displayName = 'Warning';

export const Title = styled.div`
  color: ${({ theme }) => theme.text.modalTitle2};
  font-weight: 600;
  font-size: 21.5px;
  margin-left: 13px;
`;
Title.displayName = 'Title';

export const ModalBody = styled.div`
  & > *:not(:last-child) {
    margin-right: 4px;
  };
`;
ModalBody.displayName = 'ModalBody';

export const BodyText = styled.div`
  /* color: ${colors.grey19}; */
  color: ${({ theme }) => theme.text.modalTitle2};
  font-size: 12.5px;
  font-weight: 300;
  display: inline-block;
  margin-right: 3px;
`;
BodyText.displayName = 'BodyText';

export const BlueText = styled(BodyText)`
  color: ${colors.softBlue};
`;
BlueText.displayName = 'BlueText';

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 34px;
  & >:last-child {
    margin-left: 10px;
  }
`;
ButtonsContainer.displayName = 'ButtonsContainer';

export const ConfirmButton = styled(Accept)`
`;
ConfirmButton.displayName = 'ConfirmButton';

export const CancelButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  border: solid 1px ${colors.dark6};
  text-align: center;
  min-width: 89px;
  width: auto;
  height: 38px;
  line-height: 24px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.buttons.tertiary.color};
  cursor: pointer;
  user-select: none;
  color: ${({ theme }) => theme.textfield.text};
  user-select: none;
  background-color: ${({ theme }) => theme.backgrounds.modal};
  color: ${({ theme }) => theme.text.modalTitle};
  margin-right: 10px;
`;
CancelButton.displayName = 'CancelButton';
