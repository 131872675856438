import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { selectOptionsData, selectTemplateFieldValue, selectTemplateSelected } from 'store/Settings/serviceDesk/selectors';
import { getDataForDropdown, setField } from 'store/Settings/serviceDesk/actions';
import { Wrapper, Label, dropdownStyle } from './styled';
import { DROPDOWN_ENDPOINTS } from 'store/Settings/serviceDesk/constants';
import useTheme from 'Hooks/UseTheme';

const DropdownField = ({ fieldName, mandatory }) => {
  const dispatch = useDispatch();
  const title = fieldName.split('_').join(' ');
  // const options = [{id: '1', data: 'uno'}, {id: '2', data: 'dos'}, {id: '3', data: 'tres'}]; // reemplazar por selector
  const options = useSelector((state) => selectOptionsData(state, DROPDOWN_ENDPOINTS[fieldName]));
  const initialValue = useSelector((state) => selectTemplateFieldValue(state, fieldName));
  const [value, setValue] = useState({ label: initialValue, value: initialValue });
  const templateSelected = useSelector(selectTemplateSelected);
  const { themeStyles } = useTheme();

  useEffect(() => {
    dispatch(getDataForDropdown(DROPDOWN_ENDPOINTS[fieldName]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (v) => {
    const template = templateSelected;
    const fieldToUpdate = { [fieldName]: { data: { name: v.value }, type: mandatory ? 'mandatory' : 'optional' }, deleted: false };
    if (templateSelected?.fields?.length > 0 && templateSelected.fields.find(el => Object.hasOwn(el, fieldName))) {
      const filteredFields = templateSelected.fields.filter(item => !Object.hasOwn(item, fieldName));
      Object.assign(template, { fields: [...filteredFields, fieldToUpdate] });
    } else if (templateSelected?.fields?.length > 0 && !templateSelected.fields.find(el => Object.hasOwn(el, fieldName))) {
      const filteredFields = templateSelected.fields.filter(item => !Object.hasOwn(item, fieldName));
      Object.assign(template, { fields: [...filteredFields, fieldToUpdate] });
    } else {
      Object.assign(template, { fields: [fieldToUpdate] });
    }

    dispatch(setField('templateSelected', template));
    setValue(v);
  };

  const optionsParsed = options.map((opt) => ({ label: opt, value: opt }));

  return (
    <Wrapper mandatory={ mandatory }>
      { mandatory ? <Label>{ title }</Label> : null }
      <Select
        value={ value }
        defaultValue={ value }
        options={ optionsParsed }
        onChange={ handleChange }
        styles={ dropdownStyle(themeStyles) }
        isSearchable={ false }
        isDisabled={ false }
      />
    </Wrapper>
  );
};

export default DropdownField;
