import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

export const InputDropdown = styled.input`
  background-color: ${({ theme }) => theme.textfield.background};
  color: ${({ theme }) => theme.textfield.text} !important;

  :focus{
    background-color: ${({ theme }) => theme.backgrounds.inputsFocus};
  }
`;

export const RedAster = styled.span`
  color: #ea3158;
  bottom: 12px;
  position: relative;
  left: 2px;
`;
RedAster.displayName = 'RedAster';
