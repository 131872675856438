import styled from 'styled-components';
import colors from 'Styles/colors';
import iconCheckboxSelected from 'Images/Checkbox_ok.svg';
import iconCheckboxNoSelected from 'Images/Checkbox_empty.svg';

export const Wrapper = styled.div`
  width: 100%;
  text-align: left;
  position: relative;
`;
Wrapper.displayName = 'Wrapper';

export const String = styled.input`
  background-color: ${({ theme }) => theme.textfield.background};
  border: 0;
  width: 100%;
  padding: 15px;
  color: ${({ theme }) => theme.textfield.text};
  height: 36px;
`;
String.displayName = 'String';

export const Comment = styled.textarea`
  background-color: ${colors.white};
  padding: 15px;
  border: 0;
  width: 100%;
  resize: none;
  box-shadow: inset 0 -1px 0 0 ${({ theme }) => theme.shadows.inputInset};
`;
Comment.displayName = 'Comment';

export const Label = styled.div`
  font-size: 12.5px;
  letter-spacing: 0.09px;
  color: ${({ theme }) => theme.text.modalTitle};
  padding-bottom: 8px;
`;
Label.displayName = 'Label';

export const Boolean = styled.div`
  cursor: pointer;
  font-size: 14.5px;
  font-weight: 500;
  color: ${({ theme }) => theme.textfield.text};
  &::before {
    width: 14px;
    content: url(${({ defaultValue }) => (defaultValue === 'True' ? iconCheckboxSelected : iconCheckboxNoSelected)});
    margin-right: 10px;
    vertical-align: middle;
  }
`;
Boolean.displayName = 'Boolean';
