import styled from 'styled-components';
import colors from 'Styles/colors';

const Wrapper = styled.div`
  margin-bottom: 25px;

  #cvssboard {
    text-align: center;
    align-content: center;
    background: transparent;
  }

  .cvssjs {
    display: flex;
  }

  .metrics {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
  }

  dl {
    background: ${({ theme }) => theme.backgrounds.modal2};
    width: 164px;
    padding-bottom: 10px;
    margin: 5px 3px;
    transition: background-color 0.3s ease-in-out;
  }

  dt {
    padding-top: 16px;
    font-size: 13px;
    font-weight: 600;
    color: ${({ theme }) => theme.text.detailModalFields};
    background-color: ${({ theme }) => theme.backgrounds.tableHeader};
    height: 49px;
    overflow-x: hidden;
  }

  dd {
    padding: 0px;
    text-align: left;
    margin-bottom: 0;
  }

  input[type=radio] + label:before {
    display: none;
  }

  input[type=radio]:checked + label:after {
    display: none;
  }

  label {
    color: ${({ theme }) => theme.text.vulnsCounter};
    font-size: 13px;
    font-weight: 400;
    display: flex;
    align-items: center;
    background: ${({ theme }) => theme.backgrounds.modal2};
    margin: 0;
    padding: 0;
    cursor: pointer;
    min-width: 7.5em;
    margin-top: 0px;
    transition: background-color 0.3s ease-in-out;
    height: 40px;
    justify-content: center;
  }

  label:hover {
    color: ${colors.white};
  }
  .cvssjs dd:nth-child(2) input:checked + label,
  .cvssjs dd:nth-child(2) label:hover {
    background-color: ${colors.warmPurple};
  }

  .cvssjs dd input:checked + label,
  .cvssjs dd label:hover 
    {
    background-color: ${colors.redPink};
    transition: background-color 0.1s ease-in-out;
    color: #FFF;
  }

  .cvssjs dd:last-child input:checked + label,
  .cvssjs dl.AV dd:nth-child(4) input:checked + label,
  .cvssjs dl.PR dd:last-child label:hover,
  .cvssjs dd:last-child label:hover,
  .cvssjs dl.AV dd:nth-child(4) label:hover {
    background-color: ${colors.tangerine};
  }

  .cvssjs dl.C dd:last-child input:checked + label,
  .cvssjs dl.I dd:last-child input:checked + label,
  .cvssjs dl.A dd:last-child input:checked + label,
  .cvssjs dl.C dd:last-child label:hover,
  .cvssjs dl.I dd:last-child label:hover,
  .cvssjs dl.A dd:last-child label:hover {
    background-color: ${colors.apple}
  }

  .old-results {
    display: none;
  }

  .severity-score-vector {
    padding: 0;
  }

  .results {
    display:flex;
  }

  .cvssjs dd small {
    display: none;
  }

  .cvssjs dd:hover small {
    position: absolute;
    max-width: 220px;
    border: solid 1px ${colors.lightGrey};
    display: block;
    color: ${colors.greyBlue};
    background-color: ${colors.white};
    border-radius: 2px;
    margin-top: .7em;
    margin-left: 5em;
    padding: 10px;
    z-index: 1;
  }

  ul {
    margin-left: 20px;
  }
`;
Wrapper.displayName = 'Wrappper';

export default Wrapper;
