import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.backgrounds.actionBar};
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;
Wrapper.displayName = Wrapper;

export default Wrapper;
