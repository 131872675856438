import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  background:${({ theme }) => theme.backgrounds.modal};
  width: 300px;
  padding: 20px 20px;
`;
Wrapper.displayName = 'Wrapper';

export const Header = styled.div`
  display: flex;
  align-items: center;
`;
Header.displayName = 'Header';

export const Body = styled.div`
  min-height: 30px;
  padding: 1.5rem 0;
`;
Body.displayName = 'Body';

export const Title = styled.div`
  user-select: none;
  font-size: 17px;
  color:${({ theme }) => theme.text.modalTitle};
  font-weight: 700;
  margin-left: 10px;
  margin-right: auto;
`;
Title.displayName = 'Title';

export const Button = styled.div`
  background-color: ${colors.redPink};
  border-radius: 3px;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  font-weight: 500;
  height: 34px;
  letter-spacing: normal;
  line-height: normal;
  padding: 7px 0;
  text-align: center;
  user-select: none;
  width: 89px;
  &:hover {
    background-color: #e95171;
  }
  &:active {
    background-color: #f06b88;
  }
`;
Button.displayName = 'Button';

export const Text = styled.div`
  font-weight: 300;
  color: #8da6be;
  font-size: 14px;
  text-align: initial;
`;
Text.displayName = 'Text';
