import styled from 'styled-components';
import { ReactComponent as TrashIcon } from 'Images/icon-action-bar-trash.svg';
import { ReactComponent as ErrorIcon } from 'Images/exclamation.svg';
import { ReactComponent as LoadingIcon } from 'Images/icon-pipeline-running.svg';
import colors from 'Styles/colors';

export const Title = styled.h4`
  font-weight: 400;
  font-size: 12.5px;
  line-height: 2.08;
  user-select: none;
  text-transform: capitalize;
  color: ${({ theme }) => theme.text.modalTitle2}
`;

export const Wrapper = styled.h4`
margin-top: 32px;
`;

export const InputComp = styled.input`
  all: unset;
  border-left: solid 12px #53a9ff;
  background-color: ${({ theme }) => theme.textfield.background};
  height: 34px;
  width: 92%;

  font-size: 14px;
    color: ${({ theme }) => theme.textfield.text};
    font-weight: 400;
    letter-spacing: 0.11px;
    padding-left: 22px;
    line-height: 34px;
    flex: 1;

  &::placeholder {
     color: #afb1bc;
  }
`;

export const InputWrapper = styled.div`
position: relative;
`;

export const DataContainer = styled.div`
`;

export const IconWrapper = styled.div`
  margin-left: auto;
  padding-left: 20px;
`;

export const Trash = styled(TrashIcon)` 
    width: 16px;
    height: 17px;
    align-self: flex-end;
    color: ${colors.grey12};
`;

export const Error = styled(ErrorIcon)`
`;

export const Loading = styled(LoadingIcon)`
`;

export const DataItem = styled.div`    
    font-size: 13px;
    color: ${({ theme }) => theme.text.modalTitle};
    cursor: pointer;
    padding: 0 14px;
    height: 34px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid ${props => (!props.loading && !props.found) ? 'red' : '#ededed'};
    font-weight: 400;
    margin: 3px 0;

    &:hover{
      font-weight: 500;

  }
`;
DataItem.displayName = 'DataItem';
