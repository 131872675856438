/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import { FormattedMessage } from 'react-intl';
import DateFormatter from 'Common/Components/DateFormatter';
import Skeleton from 'Common/Components/Skeleton';
import {
  ColKbDescription, ColKbReferences,
  ColKbResolution, ColKbPolicyViolations, ColKbCreator,
  ColKbCreatedDate, Col
} from './styled';
import { CheckHeader } from './CheckHeader/CheckHeader';
import { CheckColum } from './CheckColumn/CheckColum';
import NameCell from './NameCell';

TimeAgo.addLocale(en);

export default function getColumns (isFetching, fields) {
  // Severity column is copy/paste from manage. It should be refactored
  const columns = [{
    Header: () => <CheckHeader />,
    id: 'selected',
    accessor: 'Selected',
    show: true,
    sortable: false,
    resizable: false,
    maxWidth: 38,
    minWidth: 38,
    style: { display: 'flex' },
    Cell: (rowInfo) => ((isFetching) ? <Skeleton /> : <CheckColum template={ rowInfo.original } />)
  }, {
    Header: () => (
      <div />
    ),
    accessor: 'exploitation',
    show: fields.exploitation.visible,
    maxWidth: fields.exploitation.maxWidth,
    minWidth: fields.exploitation.minWidth,
    custom_order: fields.exploitation.order,
    id: 'severity',
    resizable: false,
    Cell: (rowInfo) => (
      isFetching
        ? <Skeleton />
        : (
          <Col>
            <div className={ `background-${rowInfo.original.exploitation === 'info' ? 'informational' : (rowInfo.original.exploitation === 'med' ? 'medium' : rowInfo.original.exploitation)} ml-0` }>
              <div className="severity-letter">{rowInfo.original.exploitation ? rowInfo.original.exploitation.charAt(0).toUpperCase() : ''}</div>
            </div>
          </Col>
          )
    ),
    getProps: () => ({ 'column-type': 'exploitation' })
  }, {
    Header: () => <FormattedMessage id="Name" />,
    id: 'name',
    resizable: false,
    show: fields.name.visible,
    maxWidth: fields.name.maxWidth,
    minWidth: fields.name.minWidth,
    custom_order: fields.name.order,
    Cell: (rowInfo) => (isFetching ? <Skeleton /> : <NameCell rowInfo={ rowInfo.original } />),
    getProps: () => ({ 'column-type': 'name' })
  }, {
    Header: () => <FormattedMessage id="description" />,
    id: 'description',
    resizable: false,
    show: fields.description.visible,
    custom_order: fields.description.order,
    Cell: (rowInfo) => (
      isFetching
        ? <Skeleton />
        : (
          <ColKbDescription>
            { rowInfo.original.description }
          </ColKbDescription>
          )
    ),
    getProps: () => ({ 'column-type': 'description' })
  }, {
    Header: () => <FormattedMessage id="references" />,
    id: 'references',
    resizable: false,
    sortable: false,
    show: fields.references.visible,
    custom_order: fields.references.order,
    Cell: (rowInfo) => (
      isFetching
        ? <Skeleton />
        : (
          <ColKbReferences>
            { rowInfo.original.references }
          </ColKbReferences>
          )
    ),
    getProps: () => ({ 'column-type': 'references' })
  }, {
    Header: () => <FormattedMessage id="resolution" />,
    id: 'resolution',
    resizable: false,
    show: fields.resolution.visible,
    custom_order: fields.resolution.order,
    Cell: (rowInfo) => (
      isFetching
        ? <Skeleton />
        : (
          <ColKbResolution>
            { rowInfo.original.resolution }
          </ColKbResolution>
          )
    ),
    getProps: () => ({ 'column-type': 'resolution' })
  }, {
    Header: () => <FormattedMessage id="policyviolations" />,
    id: 'policyviolations',
    resizable: false,
    sortable: false,
    show: fields.policyviolations.visible,
    custom_order: fields.policyviolations.order,
    Cell: (rowInfo) => (
      isFetching
        ? <Skeleton />
        : (
          <ColKbPolicyViolations>
            { rowInfo.original.policyviolations?.map((policy, index) => {
              const { policyviolations } = rowInfo.original;
              if (policyviolations?.length > 1 && index + 1 < policyviolations?.length) return `${policy}, `;
              return `${policy}`;
            }) }
          </ColKbPolicyViolations>
          )
    ),
    getProps: () => ({ 'column-type': 'creator' })
  }, {
    Header: () => <FormattedMessage id="creator" />,
    id: 'creator_id',
    resizable: false,
    show: fields.creator_id.visible,
    maxWidth: fields.creator_id.maxWidth,
    minWidth: fields.creator_id.minWidth,
    custom_order: fields.creator_id.order,
    Cell: (rowInfo) => (
      isFetching
        ? <Skeleton />
        : (
          <ColKbCreator>
            { rowInfo.original.creator }
          </ColKbCreator>
          )
    ),
    getProps: () => ({ 'column-type': 'creator' })
  }, {
    Header: () => <FormattedMessage id="created_date" />,
    id: 'create_date',
    resizable: false,
    width: fields.create_date.defaultWidth,
    show: fields.create_date.visible,
    maxWidth: fields.create_date.maxWidth,
    minWidth: fields.create_date.minWidth,
    custom_order: fields.create_date.order,
    Cell: (rowInfo) => (
      isFetching
        ? <Skeleton />
        : (
          <ColKbCreatedDate>
            { rowInfo.original.create_at ? <DateFormatter date={ rowInfo.original.create_at } /> : '-'}
          </ColKbCreatedDate>
          )
    ),
    getProps: () => ({ 'column-type': 'created_date' })
  }];

  const ordered_columns = columns
    .map(column => ({
      ...column,
      // Using !column.custom_order could cause problems due to how javascript handles false values. For example, if custom_order is 0
      custom_order: typeof column.custom_order === 'undefined' ? 0 : column.custom_order
    }))
    .filter((row, index) => row.show === true || index === 0)
    .sort((a, b) => a.custom_order - b.custom_order);
  return ordered_columns;
}
