import React from 'react';
import { Wrapper, Container, TextAreaStyled, CopyIcon } from './styled';
import { Title } from 'Common/Components/v2/Input/styled';
import { useSelector } from 'react-redux';
import { selectPublicKey } from 'store/Settings/jiraSelectors';

const TextArea = () => {
  const publicKey = useSelector(selectPublicKey);
  const handleCopy = () => {
    const textArea = document.getElementById('consumerKey');
    textArea.select();
    document.execCommand('copy');
  };

  return (
    <Wrapper>
      <Title children='Consumer Key' required />
      <Container>
        <TextAreaStyled id="consumerKey" value={ publicKey } readOnly />
        <CopyIcon onClick={ handleCopy } />
      </Container>
    </Wrapper>
  );
};

export default TextArea;
