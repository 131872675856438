/* eslint-disable import/prefer-default-export */
import React from 'react';
import get from 'lodash/get';
import Skeleton from 'Common/Components/Skeleton';
import { Wrapper } from './styled';

export const CVSS = ({ vuln, showSkeleton }) => {
  const cvss3 = get(vuln, 'cvss3', null);

  if (showSkeleton) return <Skeleton />;
  if (!cvss3) return null;
  return (
    <Wrapper color={ cvss3.base_severity } >
        {cvss3.base_score}
    </Wrapper>

  );
};
