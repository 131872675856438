import colors from 'Styles/colors';
import styled from 'styled-components';
import { Col } from 'Common/Components/Grid';

export const Title = styled.label`
  user-select: none;
  color: ${({ theme }) => theme.text.dashboardTertiaryTitle};
  font-size: 14px;
  font-weight: 600;
  opacity: 1;
`;
Title.displayName = 'Title';

export const Tag = styled.div`
  user-select: none;
  display: inline;
  border-radius: 3px;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  color: ${({ theme }) => theme.text.dashboardTag};
  padding: 5px;
  border: 1px solid ${({ theme }) => theme.borders.dashboardTag};
  text-align: center;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : undefined)};
  background-color: ${({ theme }) => theme.backgrounds.dashboardTag};
`;
Tag.displayName = 'Tag';

export const StyledCol = styled(Col)`
  & > *:not(:last-child) {
    margin-right: 4px;
  }
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 5px;
`;
StyledCol.displayName = 'StyledCol';

export const Empty = styled.div`
  user-select: none;
  color: ${colors.grey5};
  display: inline;
  font-size: 13px;
  font-weight: 300;
  line-height: 2.62;
`;
Empty.displayName = 'Empty';
