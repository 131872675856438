import styled from 'styled-components';
import colors from 'Styles/colors';

// eslint-disable-next-line import/prefer-default-export
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* margin-right: ${(props) => props.context ? '21px' : '56px'}; */
  margin-right: 18px;
`;
Wrapper.displayName = 'Wrapper';

export const RedAster = styled.span`
    color: #ea3158;
    bottom: 8px;
    position: relative;
    left: 2px;
`;
RedAster.displayName = 'RedAster';

export const SeverityName = styled.span`
  margin-left: 10px;
`;

export const Title = styled.div`
    font-size: 12.5px;
    font-weight: 500;
    line-height: 2.08;
    color: ${({ theme }) => theme.text.modalLabel} !important;
    font-weight: 600;
    margin-bottom: 12px;
    ${({ required }) => required && `
        position: relative;
        &:after {
        content: '*';
        color: ${colors.redPink};
        position: absolute;
        top: 3px;
        margin-left: 3px;
        }
    `}  
`;
Title.displayName = 'Title';
