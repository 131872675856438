import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 7px 0 100px 0;
`;
Wrapper.displayName = 'Wrapper';

export const Content = styled.div`
  padding: 0;
  & > *:not(:last-child) {
    padding-bottom: 20px;
  }
`;
Content.displayName = 'Content';

export const WrapperTemplate = styled.div`
  width: calc(92% / 2);
`;
WrapperTemplate.displayName = 'WrapperTemplate';

export const Title = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.textfield.text};
  margin-bottom: 12px;
`;
Title.displayName = 'Title';
