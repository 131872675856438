import styled from 'styled-components';

export const Wrapper = styled.div`
`;
Wrapper.displayName = 'Wrapper';

export const FileUploaderContainer = styled.div`
  text-align: center;
  background-color: ${({ theme }) => theme.backgrounds.modal4};  
  width: 370px;
  height: ${(props) => (props.height ? `${props.height}` : '205px')};
  margin-top: 22px;
  overflow-y: auto;
  padding-top: ${(props) => (props.height ? '22px' : '')};
`;
FileUploaderContainer.displayName = 'FileUploaderContainer';

export const FileName = styled.div`
  color: ${(props) => (props.currentUploadState === 'Error' ? '#c71717' : '#419bf9')};
  font-size: 12.5px;
  margin-bottom: 20px;
  margin-top: 17px;
`;
FileName.displayName = 'FileName';

export const LongTimeMessage = styled.div`
    color: #7a7e8f;
    font-size: 12.5px;
    line-height: 18px;
    margin-top: 10px;
`;
LongTimeMessage.displayName = 'LongTimeMessage';

export const CustomProgressBar = styled.div`
  .progressbar-container {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: transparent;
    transition: width .6s ease;
    width: 299px;
    height: 4px;
    background-color: ${(props) => (props.currentUploadState === 'Error' ? '#f4d9d9 !important' : '#e6eff6 !important')};
  }

  h2 {
    display: none !important;
  }

  .progressbar-progress{
    background-color: ${(props) => (props.currentUploadState === 'Error' ? '#f4d9d9 !important' : '#419bf9 !important')};
  }
`;
CustomProgressBar.displayName = 'CustomProgressBar';

export const File = styled.img`
  width: 28px;
  height: 34px;
  color: #7a7e8f;
  margin-top: 34px;
`;
File.displayName = 'File';

export const LoadingIcon = styled.img`
  height: 5px;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
`;
LoadingIcon.displayName = 'LoadingIcon';
