import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as warningIcon } from 'Images/warning-delete.svg';

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.backgrounds.modal};
  max-width: 460px;
  width: 100%;
  text-align: left;
  font-size: 12.5px;
  font-weight: normal;
  line-height: 1.44;
  color: ${colors.grey19};
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  padding-bottom: 22px;
  font-size: 21.5px;
  font-weight: 600;
  line-height: 1.49;
  color: ${({ theme }) => theme.text.modalTitle2} !important;
`;
Title.displayName = 'Title';

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px 0 0;
  & > *:not(:last-child) {
    margin-right: 10px;
  }
`;
Footer.displayName = 'Footer';

export const WarningIcon = styled(warningIcon)`
  width: 27px;
  margin-right: 13px;
  height: 27px;
  & > g{
    fill: ${({ theme }) => theme.icons.actionBar.color};
  }
  & > g > g{
    fill: ${({ theme }) => theme.icons.actionBar.color};
  }
`;
WarningIcon.displayName = 'WarningIcon';
