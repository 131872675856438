import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  margin-bottom: 30px;
  padding-right: 23px;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  margin-right: auto;
  color: ${({ theme }) => theme.text.sectionTitle};
`;
Title.displayName = 'Title';
