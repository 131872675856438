/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import TimeAgo from 'react-timeago';
import get from 'lodash/get';
import CheckBox from 'Common/Components/Checkbox/styled';
import Skeleton from 'Common/Components/Skeleton';
import IconButton from 'Common/Components/IconButton';
import { WrapperColumn, RunIcon } from './styled';
import NameColumn from './NameColumn';
import { CheckHeader } from './CheckHeader';
import { CheckColumn } from './CheckColumn';

const getColumns = (fields, onChangeStatus, isFetching, run) => {
  const columns = [{
    Header: () => <CheckHeader />,
    accessor: 'selected',
    id: 'Selected',
    maxWidth: 38,
    minWidth: 38,
    resizable: false,
    sortable: false,
    show: true,
    Cell: (rowInfo) => (isFetching ? <Skeleton /> : <CheckColumn rowInfo={ rowInfo } />),
    custom_order: -1
  }, {
    Header: () => <FormattedMessage id="schedule.table.columns.id" />,
    id: 'id',
    accessor: 'id',
    resizable: false,
    show: false,
    Cell: (rowInfo) => (isFetching ? <Skeleton /> : <WrapperColumn>{ rowInfo.original.value.id }</WrapperColumn>),
    custom_order: get(fields, 'id.order', 1)
  }, {
    Header: () => <FormattedMessage id="schedule.table.columns.name" />,
    id: 'description',
    accessor: 'description',
    resizable: false,
    Cell: (rowInfo) => (isFetching ? <Skeleton /> : <NameColumn rowInfo={ rowInfo } />),
    custom_order: get(fields, 'description.order', 2),
    maxWidth: 350,
    minWidth: 250
  }, {
    Header: () => <FormattedMessage id="schedule.table.columns.nextRun" />,
    id: 'next_run',
    accessor: 'next_run',
    resizable: false,
    // eslint-disable-next-line no-nested-ternary
    Cell: (rowInfo) => (isFetching ? <Skeleton /> : rowInfo.original.value.next_run ? <WrapperColumn><TimeAgo date={ rowInfo.original.value.next_run } /></WrapperColumn> : null),
    custom_order: get(fields, 'nextRun.order', 3),
    maxWidth: 150,
    minWidth: 150
  }, {
    Header: () => <FormattedMessage id="schedule.table.columns.crontab" />,
    id: 'crontab',
    resizable: false,
    Cell: (rowInfo) => (isFetching ? <Skeleton /> : <WrapperColumn>{ rowInfo.original.value.crontab }</WrapperColumn>),
    custom_order: get(fields, 'crontab.order', 4)
  }, {
    Header: () => <FormattedMessage id="schedule.table.columns.agentName" />,
    id: 'name',
    resizable: false,
    Cell: (rowInfo) => (isFetching ? <Skeleton /> : <WrapperColumn>{ rowInfo.original.value.type === 'cloud_agent' ? rowInfo.original.value.cloud_agent.name : rowInfo.original.value.agent.name }</WrapperColumn>),
    custom_order: get(fields, 'agentName.order', 5)
  }, {
    Header: () => <FormattedMessage id="schedule.table.columns.tool" />,
    id: 'tool',
    resizable: false,
    Cell: (rowInfo) => {
      const executors = get(rowInfo, 'original.value.agent.executors', []);
      const executorsNames = executors.map((executor) => executor.name).join(', ');
      return (isFetching ? <Skeleton /> : <WrapperColumn>{ executorsNames }</WrapperColumn>);
    },
    custom_order: get(fields, 'tool.order', 6)
  }, {
    Header: () => <FormattedMessage id="schedule.table.columns.run" />,
    id: 'run',
    resizable: false,
    Cell: (rowInfo) => (isFetching ? <Skeleton /> : <WrapperColumn><IconButton id="scheduler-table-trigger" onClick={ (e) => run(e, rowInfo.original.id, rowInfo.original.value.workspaces, rowInfo.original.value.type === 'agent' && rowInfo.original.value.agent.is_online, rowInfo.original.value.type) } title="Trigger" icon={ <RunIcon /> } /></WrapperColumn>),
    custom_order: get(fields, 'run.order', 7)
  }, {
    Header: () => <FormattedMessage id="schedule.table.columns.active" />,
    id: 'active',
    resizable: false,
    Cell: (rowInfo) => (isFetching ? <Skeleton /> : <WrapperColumn><CheckBox checked={ rowInfo.original.value.active } onClick={ () => onChangeStatus(rowInfo.original.id, !rowInfo.original.value.active, rowInfo.original.value.workspaces, rowInfo.original.value.type) } /></WrapperColumn>),
    custom_order: get(fields, 'active.order', 8)
  }];
  return columns;
};

getColumns.propTypes = {
  fields: PropTypes.instanceOf(Object).isRequired
};

export default getColumns;
