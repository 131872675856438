import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FEATURE_USERS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { selectUserSelected } from 'store/Users/selectors';
import { AssignWs } from '../../styled';
import IconButton from 'Common/Components/IconButton';
import ModalWarning from 'Common/Components/ModalWarning';
import { useIntl } from 'react-intl';
import { selectActiveUser } from 'store/Sesion/selectors';
import { openModal } from 'store/modals/actions';
import { MODAL_USERS_ASSIGN_WS } from 'store/modals/modals';

const AssignWsButton = () => {
  const dispatch = useDispatch();
  const [showWarning, setShowWarning] = useState(false);
  const userSelected = useSelector(selectUserSelected);
  const activeUser = useSelector(selectActiveUser);
  const allowedByRole = useSelector((state) => selectAllowedByRole(state, FEATURE_USERS, PERMISSION_TYPES.UPDATE));
  const allAdminsSelected = userSelected.every(user => user.roles.includes('admin'));
  const canUpdate = (allowedByRole && !userSelected.some((u) => u.username === activeUser) && !allAdminsSelected) || userSelected.length === 0;
  const intl = useIntl();
  const message = intl.formatMessage({ id: 'modalWarning.selectOneOrMore' }, { entity: 'user' });
  const handleClick = () => {
    if (canUpdate) {
      if ((userSelected.length > 0)) dispatch(openModal(MODAL_USERS_ASSIGN_WS));
      else setShowWarning(true);
    }
  };

  const onClose = () => setShowWarning(false);

  return (
    <>
      <IconButton icon={ <AssignWs disabled={ !canUpdate } /> } title="Assign Workspace(s)" onClick={ handleClick } disabled={ !canUpdate } />
      { showWarning && <ModalWarning onCloseCallback={ onClose } title="Assign Workspace(s)" description={ message } /> }
    </>
  );
};

export default AssignWsButton;
