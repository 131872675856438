import styled from 'styled-components';
import { Wrapper as WrapperUpgradeLicense } from 'Common/Components/UpgradeLicense/styled';

export const Wrapper = styled.div`
  font-size: 21.5px;
  min-height: 100%;
  max-height: 100%;
  * > ${WrapperUpgradeLicense} {
    flex-direction: column;
  }
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  font-size: 21.5px;
`;
Title.displayName = 'Title';

export const Content = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 0;
  & > *:not(:last-child) {
    border-bottom: 1px;
    border-bottom-style: solid;
    border-bottom-color: #e9e9e9;
  }
`;
Content.displayName = 'Content';

export const WebHelpDeskLogo = styled.img`
  width: 98px;
  margin-bottom: 6px;
`;
WebHelpDeskLogo.displayName = 'WebHelpDeskLogo';
