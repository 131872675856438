import * as p from 'store/Global/types';
import api from 'services/api';
// eslint-disable-next-line import/prefer-default-export
export const toggleExpandCollapse = (tab, fields) => {
  return async (dispatch) => {
    dispatch({ type: p.EXPAND_COLLAPSE, tab, fields });
  };
};

export function getWorkspacesData (histogram = true, stats = true, onlyTotalVulns = false) {
  return async (dispatch) => {
    try {
      dispatch({ type: p.FETCH_WORKSPACES_DATA });
      const queryParam = { filters: { name: 'active', op: '==', val: 'true' } };
      const response = await api.workspace.fetchWorkspaces(queryParam, histogram, !stats, false, onlyTotalVulns);
      const data = response.rows;
      const count = response.count;
      dispatch({ type: p.FETCH_WORKSPACES_DATA_SUCCESS, data, count });
    } catch (e) {
      dispatch({ type: p.FETCH_WORKSPACES_DATA_FAILURE, data: e.message || 'Failed to obtain workspaces.' });
    }
  };
}
