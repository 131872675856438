import fetchApi from 'services/api/connector';

export const getAttachments = (ws, id) => fetchApi(`ws/${ws}/vulns/${id}/attachment`, { method: 'GET', version: 'v3' });

export const saveAttachments = (ws, id, data) => fetchApi(`ws/${ws}/vulns/${id}/attachment`, {
  method: 'POST', data, contentType: false, processData: false
});

export const addDescriptionInEvidence = (ws, id, data, attachmentName) => fetchApi(`ws/${ws}/vulns/${id}/attachment/${attachmentName}`, {
  method: 'PATCH', data, contentType: false, processData: false
});

export const removeAttachments = (ws, id, name) => fetchApi(`ws/${ws}/vulns/${id}/attachment/${name}`, { method: 'DELETE', contentType: false, processData: false });
