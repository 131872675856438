import styled from 'styled-components';
import { slideInLeft } from 'Styles/effects';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 570px;
  background-color: ${({ theme }) => theme.backgrounds.sideModal};
  overflow-y: hidden;
  overflow-x: hidden;
  position: absolute;
  right: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  box-shadow: ${({ theme }) => theme.boxShadow.modal};
  padding: 25px 0 0 30px;
  ${slideInLeft};
`;
Wrapper.displayName = 'Wrapper';

export default Wrapper;
