import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { Dropdown } from 'Common/Components/Inputs';
import { selectActiveWorkspaces } from 'store/Workspace/selectors';
import { setWorkspace } from 'Screens/Contextualization/ManageEditCreate/actions/Actions';
import { selectWorkspaceSelected } from 'store/ManageEditCreate/selectors';
import { standardDropdownStyleUnCapitalize } from 'Common/styles/style';
import { Wrapper, Title } from './styled';
import { ThemeContext } from 'Context';

const WorkspacesDropdown = () => {
  const { themeStyles } = useContext(ThemeContext);
  const dispatch = useDispatch();
  const workspace = useSelector(selectWorkspaceSelected);
  const workspaces = useSelector(selectActiveWorkspaces);
  const options = !isEmpty(workspaces)
    ? workspaces.map((el) => ({ value: el.name, label: el.name }))
    : [];

  const handleChange = (selectedOption) => {
    dispatch(setWorkspace(selectedOption.value));
  };

  const changeStyle = () => {
    const newStyle = { ...standardDropdownStyleUnCapitalize(themeStyles) };
    const newControl = { ...newStyle.control(), width: 249 };
    const newMenu = { ...newStyle.menu(), marginTop: '0px' };
    const newMenuList = { ...newStyle.menuList(), maxHeight: '152px', overflowX: 'auto', paddingTop: '10px', paddingBottom: '10px' };
    newStyle.control = () => newControl;
    newStyle.menu = () => newMenu;
    newStyle.menuList = () => newMenuList;
    return newStyle;
  };

  const style = changeStyle();
  return (
    <Wrapper>
      <Title>Workspace</Title>
      <Dropdown
        placeholder="Select Workspace"
        style={ style }
        options={ options }
        onChange={ handleChange }
        value={ options.find((option) => option.value === workspace) }
        isMulti={ false }
        isSearchable={ false }
      />
    </Wrapper>
  );
};

export default WorkspacesDropdown;
