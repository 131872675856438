import styled from 'styled-components';
import {
  Title as _Title, SubTitle as _SubTitle
} from '../styled';

export const SubTitle = styled(_SubTitle)`
    margin-bottom: 23px;
`;

export const Title = styled(_Title)`
    margin-bottom: 21px;
`;

export const Code = styled.code`
  font-size: 11px;
  line-height: 1.42;
  color: solid 1px ${({ theme }) => theme.text.modalTitle2};
  word-break: break-all;
`;

export const CodeWrapper = styled.div`
  background: ${({ theme }) => theme.backgrounds.importanceMeter};
  border: solid 1px ${({ theme }) => theme.borders.importanceMeter};
  width: 395px;
  height: 35px;
  display: flex;
  align-items: center;
  padding-left: 21px;
  margin-right: 20px;
`;

export const Link = styled.a`
  text-decoration:none;
  font-size: 13.5px;
  cursor: pointer;
  color: #007bff;
  &:link,
  &:visited,
  &:hover,
  &:active {
    color: #007bff;
    text-decoration: none;
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 21px;
`;
