import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSchedulersSelected, selectSchedulersTotal, selectSelectedSection } from 'store/Schedule/selectors';
import IconButton from 'Common/Components/IconButton';
import { useIntl } from 'react-intl';
import { Wrapper, Trash } from './styled';
import ModalWarning from 'Common/Components/ModalWarning';
import { openModal } from 'store/modals/actions';
import { MODAL_SCHEDULE_DELETE } from 'store/modals/modals';

const Delete = () => {
  const [showWarning, setShowWarning] = useState(false);
  const section = useSelector(selectSelectedSection);
  const schedulersCount = useSelector((state) => selectSchedulersTotal(state, section));
  const hasSchedulers = schedulersCount > 0;
  const dispatch = useDispatch();
  const intl = useIntl();
  const schedulersSelected = useSelector(selectSchedulersSelected);
  const message = intl.formatMessage({ id: 'modalWarning.selectOneOrMore' }, { entity: 'scheduler' });

  const handleClick = () => {
    if (hasSchedulers) {
      if ((schedulersSelected?.length > 0)) {
        dispatch(openModal(MODAL_SCHEDULE_DELETE));
      } else setShowWarning(true);
    }
  };

  const onCloseWarning = () => setShowWarning(false);

  return (
    <Wrapper>
      <IconButton title="Delete" onClick={ handleClick } icon={ <Trash /> } />
      {showWarning && <ModalWarning title={ intl.formatMessage({ id: 'schedule.delete.title' }) } onCloseCallback={ onCloseWarning } description={ message } />}
    </Wrapper>
  );
};

export default Delete;
