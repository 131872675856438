import styled from 'styled-components';
import { ReactComponent as TrashIcon } from 'Images/tool_trash.svg';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 20px 26px 20px;
`;
Wrapper.displayName = 'Wrapper';

export const ResultsTitle = styled.div`
  font-size: 14.5px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.73;
  letter-spacing: normal;
  color: ${({ theme }) => theme.text.detailModalFields};
  user-select: none;
  text-align: left;
`;
ResultsTitle.displayName = 'ResultsTitle';

export const Description = styled.div`
  width: 132px;
  height: 26px;
  margin: 0 556px 4px 0;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: ${({ theme }) => theme.text.detailModalTitle};
`;
Description.displayName = 'Description';

export const IconWrapper = styled.div`
  position: absolute;
  right: 13px;
  top: 5px;
  opacity: 0;
  pointer-events: none;
  ${({ fullVector }) => fullVector && `
      opacity: 1;
      pointer-events: all;
  `}
`;
IconWrapper.displayName = 'IconWrapper';

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.borders.importanceMeter};
  border-radius: 4px;
  padding: 8px;
  width: 100%;
  height: 34px;
  background: ${({ theme }) => theme.textfield.background};
`;

export const StyledInput = styled.input`
  border: none;
  outline: none;
  flex: 1;
  font-size: 14px;
  background: transparent;
  color: ${({ theme }) => theme.textfield.text};
`;

export const Trash = styled(TrashIcon)`
  color: #999;
  cursor: pointer;
  margin-left: 8px;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};

  &:hover {
    color: #d11a2a; /* Cambia de color al pasar el mouse */
  }
`;
