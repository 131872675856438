import styled from 'styled-components';
import colors from 'Styles/colors';

export const ItemWrapper = styled.div`
  background-color: ${({ theme }) => theme.backgrounds.agentCard};
  position: relative;
`;
ItemWrapper.displayName = 'ItemWrapper';

export const CustomItem = styled.div`
  display: flex;
  flex-direction: row;
  height: 41px;
  padding: 0 12px;
  &:hover {
    >:last-child {
      ${({ canDelete }) => canDelete && `
        display: block;
        position: absolute;
        top: 7px;
        right: 20px;
      `};
    }
  }
`;
CustomItem.displayName = 'CustomItem';

export const Name = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-stretch: normal;
  font-style: normal;
  font-size: 13.5px;
  color: ${({ theme }) => theme.text.reportTableItem};
  flex: 1 1 auto;
  margin: auto 0px;
  user-select: none;
  width: 70px;
`;
Name.displayName = 'Name';

export const Email = styled(Name)`
  font-size: 12.5px;
  color: ${colors.blueGrey};
  width: 175px;
  margin: 12px 0 auto 5px;
`;
Email.displayName = 'Email';

export const Role = styled(Name)`
  font-size: 12.5px;
  color: ${({ role }) => {
    if (role === 'admin') return colors.warmPurple;
    if (role === 'pentester') return colors.redPink;
    if (role === 'client') return colors.blueCerulean;
    return colors.softBlue;
  }};
  margin-top: 12px;
`;
Role.displayName = 'Role';
