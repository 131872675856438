import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import {
  selectFileIndex, selectHasAssetTags, selectHasServiceTags,
  selectHasVulnTags, selectIgnoreInfo, selectResolveHost,
  selectWorkspaceSelected
} from 'store/ManageEditCreate/selectors';
import ModalWrapper from 'Common/Components/ModalWrapper';
import { selectLimitReached } from 'store/Host/selectors';
import TotalHostsUsed from 'Common/Components/TotalHostsUsed';
import { ACTIONS } from 'tracking/GA/constants';
import { uploadFile, setFileIndex, setUploadState, resetFileUploadState, setFilesCount, setWorkspace } from 'Screens/Contextualization/ManageEditCreate/actions/Actions';
import { Button, ButtonsContainer } from 'Common/Components/MainContainer/styled';
import { Wrapper, LeftTitle, BackBtn } from './styled';
import DragStep from './components/DragStep';
import ListStep from './components/ListStep';
import CreateEditTagsModal from './components/CreateEditTagsModal';
import { selectShowTagsModal, selectTagsToAdd } from 'store/Tags/selectors';
import { showProcessingQueue } from 'Common/Components/FileUploadContext/actions';

const ImportFileCreationModal = ({ handleClose, entity }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [files, setFiles] = useState([]);
  const [step, setStep] = useState('DRAG');
  const tags = useSelector(selectTagsToAdd);
  const fileIndex = useSelector(selectFileIndex);
  const limitReached = useSelector(selectLimitReached);
  const showCreateTagModal = useSelector((state) => selectShowTagsModal('import', state));
  const ignoreInfo = useSelector(selectIgnoreInfo);
  const resolveHost = useSelector(selectResolveHost);
  const workspace = useSelector(selectWorkspaceSelected);

  const titleLabel = intl.formatMessage({ id: 'importFileCreationModal.title' });
  const doneLabel = intl.formatMessage({ id: 'importFileCreationModal.doneButton' });
  const uploadLabel = intl.formatMessage({ id: 'importFileCreationModal.uploadButton' });
  const cancelLabel = intl.formatMessage({ id: 'importFileCreationModal.cancelButton' });
  const parsedTags = tags.map((t) => t.name).join(',');
  const hasAssetsTags = useSelector(selectHasAssetTags);
  const hasVulnsTags = useSelector(selectHasVulnTags);
  const hasServicesTags = useSelector(selectHasServiceTags);

  const onHandleClose = () => {
    setFiles([]);
    setStep('DRAG');
    dispatch(resetFileUploadState());
    handleClose();
    dispatch(setWorkspace(''));
  };

  const handleDrop = (filesUploading) => {
    const fileList = files;
    for (let i = 0; i < filesUploading.length; i += 1) {
      if (!filesUploading[i].name) return;
      if (fileList.findIndex((item) => (item.name === filesUploading[i].name && item.size === filesUploading[i].size)) === -1) {
        fileList.push(filesUploading[i]);
      }
    }
    setFiles([...fileList]);
    if (fileList.length > 0) setStep('LIST');
    dispatch(setFileIndex(fileList.length - 1));
    dispatch(setUploadState());
  };

  const upload = () => {
    const params = [
      { name: 'file', value: files[fileIndex] },
      { name: 'ignore_info', value: ignoreInfo },
      { name: 'resolve_hostname', value: resolveHost }
    ];
    if (hasAssetsTags) params.push({ name: 'host_tag', value: parsedTags });
    if (hasVulnsTags) params.push({ name: 'vuln_tag', value: parsedTags });
    if (hasServicesTags) params.push({ name: 'service_tag', value: parsedTags });

    setStep('UPLOAD');
    dispatch(setFilesCount(files));
    if (fileIndex >= 0) dispatch(uploadFile(params, workspace));
    dispatch(showProcessingQueue(true));
    onHandleClose();
  };

  const stepComponent = {
    LIST: <ListStep
      files={ files }
      setFiles={ setFiles }
      tags={ tags }
      setStep={ setStep }
      handleDrop={ handleDrop }
      entity={ entity }
    />,
    DRAG: <DragStep
      files={ files }
      setFiles={ setFiles }
      tags={ tags }
      setStep={ setStep }
      handleDrop={ handleDrop }
      entity={ entity }
    />
  };

  return (
    <ModalWrapper z={ 1000 }>
      <Wrapper>
        <LeftTitle children={ titleLabel } />
        { stepComponent[step] }
        <TotalHostsUsed trackingShow={ ACTIONS.showImportVulns } trackingClick={ ACTIONS.clickImportVulns } />
        <ButtonsContainer>
          { step === 'SUMMARY'
            ? <Button onClick={ onHandleClose } children={ doneLabel } />
            : <Button onClick={ upload } disabled={ step !== 'LIST' || isEmpty(files) || limitReached || (entity === 'vulnsGeneral' && isEmpty(workspace)) } children={ uploadLabel } />
          }
          <BackBtn onClick={ onHandleClose } children={ cancelLabel } />
        </ButtonsContainer>
      </Wrapper>
      { showCreateTagModal && <CreateEditTagsModal /> }
    </ModalWrapper>
  );
};

ImportFileCreationModal.propTypes = {
  handleClose: PropTypes.func.isRequired
};

export default ImportFileCreationModal;
