import React, { useContext } from 'react';
import capitalize from 'lodash/capitalize';
import PropTypes from 'prop-types';
import Dropdown from 'Common/Components/Dropdown';
import { standardDropdownStyle } from 'Common/styles/style';
import { Wrapper } from './styled';
import { ThemeContext } from 'Context';

const Item = ({ title, normal }) => (
  <div className="d-inline-flex item-option">
    <span>{normal ? title : capitalize(title)}</span>
  </div>
);

Item.propTypes = {
  title: PropTypes.string.isRequired
};

const StandardDropdown = ({
  options, width, defaultValue, placeholder, disabled, menuPortalTarget, normal, isSearchable, field, updateValue, selectObject, customStyle = null
}) => {
  const { themeStyles } = useContext(ThemeContext);

  const onChange = (newValue) => {
    if (selectObject) {
      const selected = options.find((o) => o.name === newValue);
      updateValue(field, selected);
    } else {
      updateValue(field, newValue);
    }
  };

  const style = standardDropdownStyle(themeStyles);

  const dropdownOptions = options.map((element) => ({
    label: <Item title={ element.desc } normal={ normal } />,
    value: element.name,
    id: element.id || undefined
  }));

  return (
    <Wrapper>
      <Dropdown
        customStyle={ style }
        options={ dropdownOptions }
        updateValue={ onChange }
        placeholder={ placeholder }
        defaultValue={ defaultValue }
        disabled={ disabled }
        menuPortalTarget={ menuPortalTarget }
        isSearchable={ isSearchable }
      />
    </Wrapper>
  );
};

StandardDropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    desc: PropTypes.string
  })).isRequired,
  width: PropTypes.string,
  field: PropTypes.string.isRequired,
  updateValue: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  selectObject: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  menuPortalTarget: PropTypes.node,
  normal: PropTypes.bool,
  id: PropTypes.string,
  isSearchable: PropTypes.bool
};

StandardDropdown.defaultProps = {
  width: '',
  defaultValue: '',
  selectObject: false,
  placeholder: 'Select',
  disabled: false,
  menuPortalTarget: undefined,
  normal: false,
  id: '',
  isSearchable: false
};

export default StandardDropdown;
