import React, { useContext } from 'react';
import { Wrapper, Item, Text } from './styled';
import { ThemeContext } from 'Context';

const List = ({ data }) => {
  const isOdd = (n) => n % 2 !== 0;
  const { themeStyles } = useContext(ThemeContext);
  if (!data) return null;

  return (
    <Wrapper>
      { data.map((element, i) =>
        <Item isOdd={ isOdd(i) } key={ `item_${element}_${i}` } theme={ themeStyles }>
          <Text>{ element }</Text>
        </Item>
      )}
    </Wrapper>
  );
};

export default List;
