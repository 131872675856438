import React from 'react';
import FieldTypeComponents from '../FieldTypeComponents';

const IssueType = ({
  type, title, options, onChange, issueType, required = false, users, ...data
}) => {
  const SelectedComponent = FieldTypeComponents[type.replaceAll('-', '')]; // FieldTypeComponent es un objeto con todos los componentes

  if (!SelectedComponent) return <div />;
  return <SelectedComponent setValue={ onChange } title={ title } options={ options } key={ `${issueType}-${title}` } required= { required } users={ users } { ...data } />;
};

export default IssueType;
