/* eslint-disable react/no-children-prop */
import React from 'react';
import { PropTypes } from 'prop-types';
import { Default, Accept } from 'Common/Components/Button/styled';
import { FormattedMessage } from 'react-intl';
import { Wrapper, Title, ButtonsWrapper } from './styled';

const TitleBar = ({
  onDone, onCancel, disabled, isEditing
}) => {
  const newTitle = <FormattedMessage id="planner.project.create" defaultMessage="New Project" />;
  const editTitle = <FormattedMessage id="planner.project.edit" defaultMessage="Edit Project" />;
  const cancelLabel = <FormattedMessage id="planner.project.create.cancel" defaultMessage="Cancel" />;
  const doneLabel = <FormattedMessage id="planner.project.create.done" defaultMessage="Done" />;

  return (
    <Wrapper>
      <Title children={ isEditing ? editTitle : newTitle } />
      <ButtonsWrapper>
        <Default children={ cancelLabel } onClick={ onCancel } />
        <Accept disabled={ disabled } children={ doneLabel } onClick={ onDone } />
      </ButtonsWrapper>
    </Wrapper>
  );
};

TitleBar.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isEditing: PropTypes.bool
};

TitleBar.defaultProps = {
  disabled: false,
  isEditing: false
};

export default TitleBar;
