import styled, { css } from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as RemoveImage } from 'Images/icon-trash-red.svg';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 15px;
  flex-direction: column;
`;
Wrapper.displayName = 'Wrapper';

export const Search = styled.input.attrs(({
  type: 'text'
}))`
  padding: 9px 11px;
  box-shadow: inset 0 -1px 0 0 ${({ theme }) => theme.textfield.shadow};
  background-color: ${({ theme }) => theme.textfield.background};
  border: 0;
  height: 34px;
  font-size: 14.5px;
  letter-spacing: 0.11px;
  color: ${({ theme }) => theme.textfield.text};
  border-left: 12px solid ${colors.softBlue};
  width: 100%;
  &::placeholder {
    color: ${colors.grey17};
  }
`;
Search.displayName = 'Search';

export const Remove = styled(RemoveImage)`
  ${({ disabled }) => disabled && css`
    cusor: auto;
  `}
  margin: auto 0px auto 10px;
  width: 20px;
  height: 20px;
  & > path {
    fill: ${colors.grey12};
    stroke: ${colors.grey12};
  }
`;
Remove.displayName = 'Remove';

export const WrapperSearch = styled.div`
  display: flex;
  flex-direction: row;
`;
WrapperSearch.displayName = 'WrapperSearch';
