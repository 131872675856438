import uniq from 'lodash/uniq';
import {
  SCHEDULE_FETCHING,
  SCHEDULE_FAIL,
  SCHEDULED_AGENTS_GET_DATA,
  RUN_SCHEDULE,
  SCHEDULE_SET_TAGS_ENTITY,
  SCHEDULE_RESET_TAGS_FIELDS,
  SCHEDULE_SET_SECTION,
  SCHEDULE_SET_PAGE,
  SCHEDULE_SET_IS_CREATE,
  SCHEDULER_SELECTED,
  SCHEDULER_UNSELECTED,
  SCHEDULER_NEW_PIVOT,
  SELECT_ALL_SCHEDULER,
  UNSELECT_ALL_SCHEDULER,
  SCHEDULED_CLOUD_AGENTS_GET_DATA,
  SCHEDULER_CLEAR_ERROR,
  GET_SCHEDULERS_COUNT_SUCCESS,
  SCHEDULE_RESET_STATE
} from './constants';
import get from 'lodash/get';

export const initialState = {
  scheduledAgents: {
    isFetching: false,
    error: false,
    errorMessage: '',
    list: [],
    total: 0,
    page: 1,
    sortBy: 'id',
    sortDirection: 'asc',
    pageSize: 50,
    fields: {
      id: {
        order: 1, visible: false
      },
      description: {
        order: 2, visible: true
      },
      nextRun: {
        order: 3, visible: true
      },
      crontab: {
        order: 4, visible: true
      },
      agentName: {
        order: 5, visible: true
      },
      tool: {
        order: 6, visible: true
      },
      run: {
        order: 7, visible: true
      },
      active: {
        order: 8, visible: true
      }
    }
  },
  scheduledCloudAgents: {
    isFetching: false,
    error: false,
    errorMessage: '',
    list: [],
    total: 0,
    page: 1,
    sortBy: 'id',
    sortDirection: 'asc',
    pageSize: 50,
    fields: {
      id: {
        order: 1, visible: false
      },
      description: {
        order: 2, visible: true
      },
      nextRun: {
        order: 3, visible: true
      },
      crontab: {
        order: 4, visible: true
      },
      agentName: {
        order: 5, visible: true
      },
      tool: {
        order: 6, visible: true
      },
      run: {
        order: 7, visible: true
      },
      active: {
        order: 8, visible: true
      }
    }
  },
  selectedSection: 'scheduledAgents',
  isCreateSchedule: false,
  schedulersSelected: [],
  lastSelected: -1,
  count: {
    cloudAgentSchedulers: 0,
    agentSchedulers: 0
  }
};

function schedule (state = initialState, action) {
  const { section } = action;
  let sectionState;

  if (section) {
    sectionState = get(state, section, undefined);
  }

  switch (action.type) {
    case SCHEDULE_FETCHING: {
      return {
        ...state,
        [action.section]: {
          ...sectionState,
          isFetching: action.isFetching,
          error: false
        }
      };
    }

    case SCHEDULED_AGENTS_GET_DATA: {
      return {
        ...state,
        scheduledAgents: {
          ...state.scheduledAgents,
          isFetching: false,
          error: false,
          list: action.data,
          total: action.total
        }
      };
    }

    case SCHEDULE_FAIL: {
      return {
        ...state,
        [action.section]: {
          ...sectionState,
          isFetching: false,
          error: true,
          errorMessage: action.message

        }
      };
    }

    case RUN_SCHEDULE: {
      return {
        ...state,
        [section]: {
          ...sectionState,
          isFetching: false,
          error: true,
          errorMessage: action.message
        }
      };
    }

    case SCHEDULED_CLOUD_AGENTS_GET_DATA: {
      return {
        ...state,
        scheduledCloudAgents: {
          ...state.scheduledCloudAgents,
          isFetching: false,
          error: false,
          list: action.data,
          total: action.total
        }
      };
    }

    case SCHEDULE_SET_PAGE: {
      return {
        ...state,
        [section]: {
          ...sectionState,
          page: action.page
        }
      };
    }

    case SCHEDULE_SET_SECTION: {
      return {
        ...state,
        selectedSection: action.section,
        lastSelected: -1,
        schedulersSelected: []
      };
    }

    case SCHEDULE_SET_IS_CREATE: {
      return {
        ...state,
        isCreateSchedule: action.value
      };
    }

    case SCHEDULER_SELECTED: {
      return {
        ...state,
        schedulersSelected: uniq([...state.schedulersSelected, ...action.payload])
      };
    }

    case SCHEDULER_UNSELECTED: {
      const filterByID = (array, id) => array.filter((el) => el.id !== id);
      const newSchedulersSelected = action.payload.reduce((schedulersSelected, schedulerToDelete) => filterByID(schedulersSelected, schedulerToDelete.id), state.schedulersSelected);

      return {
        ...state,
        schedulersSelected: newSchedulersSelected
      };
    }

    case SCHEDULER_NEW_PIVOT: {
      return {
        ...state,
        lastSelected: action.payload
      };
    }

    case SELECT_ALL_SCHEDULER: {
      return {
        ...state,
        schedulersSelected: [...action.schedulersList]
      };
    }

    case UNSELECT_ALL_SCHEDULER: {
      return {
        ...state,
        schedulersSelected: []
      };
    }

    case SCHEDULER_CLEAR_ERROR: {
      return {
        ...state,
        [action.section]: {
          ...sectionState,
          error: false,
          errorMessage: ''
        }
      };
    }

    case GET_SCHEDULERS_COUNT_SUCCESS: {
      return {
        ...state,
        count: {
          cloudAgentSchedulers: get(action.response, 'cloud_agent_schedulers', 0),
          agentSchedulers: get(action.response, 'agent_schedulers', 0)
        }
      };
    }

    case SCHEDULE_SET_TAGS_ENTITY: {
      const field = `${action.entity}Checked`;
      return {
        ...state,
        [field]: action.value
      };
    }

    case SCHEDULE_RESET_TAGS_FIELDS: {
      return {
        ...state,
        assetsTagsChecked: false,
        vulnsTagsChecked: false,
        servicesTagsChecked: false
      };
    }

    case SCHEDULE_RESET_STATE: {
      return {
        ...initialState
      };
    }

    default: {
      return state;
    }
  }
}

export default schedule;
