import styled from 'styled-components';
import colors from 'Styles/colors';

export const Label = styled.div`
  font-size: 12.5px;
  font-weight: normal;
  letter-spacing: 0.09px;
  color: ${({ theme }) => theme.text.runAgentInputLabel};
  padding-bottom: 5px;
  padding-right: 15px;
`;
Label.displayName = 'Label';

export const Field = styled.input`
  padding: 9px 53px 8px 15px;
  box-shadow: inset 0 -1px 0 0 ${(props) => (props.failed ? 'red' : props.theme.shadows.inputInset)};
  background-color: ${({ theme }) => theme.backgrounds.runAgentInput};
  color: ${({ theme }) => theme.text.runAgentInput};
  height: 34px;
  border: 0;
  &::placeholder {
    color: ${colors.grey12};
  }
`;
Field.displayName = 'Field';

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 0px 8px 0 0;
  padding-bottom: 21px;
  &[required] {
    ${Label}::after {
      content: ' *';
      color: ${colors.redPink};
    }
  }
`;
FieldWrapper.displayName = 'FieldWrapper';

export const Error = styled.div`
  font-size: 11px;
  color: ${colors.red1};
  margin-top: 12px;
  text-align: justify;
`;
Error.displayName = 'Error';
