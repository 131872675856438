import styled from 'styled-components';
import colors from 'Styles/colors';
import { FiTrash2 as Trash } from 'react-icons/fi';
import { ellipsisText } from 'Styles/styles';
import { ReactComponent as CopyLink } from 'Images/copy_link.svg';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;
Wrapper.displayName = 'Wrapper';

export const DataContainer = styled.div`
    width: ${(props) => (props.responsive ? '100%' : '400px')};   
    background: ${({ showGradient, theme }) =>
        showGradient
            ? `linear-gradient(
                to bottom,
                ${theme.backgrounds.modal} 0%,       
                ${theme.backgrounds.modal} 33.33%,  
                ${theme.backgrounds.modal4} 33.33%,   
                ${theme.backgrounds.modal4} 66.66%,   
                ${theme.backgrounds.modal} 66.66%,  
                ${theme.backgrounds.modal} 100%
            )`
            : theme.backgrounds.modal};
    box-shadow: 0 2px 50px 0 ${({ theme }) => theme.textfield.background};
    overflow-y: auto;
    min-height: 110px;
    max-height: 110px;
`;
DataContainer.displayName = 'DataContainer';

export const DataItem = styled.div`    
    height: 36.6px;
    font-size: 13px;
    color: ${({ theme }) => theme.textfield.text};
    cursor: pointer;
    padding: 7px 5px 7px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover{
      background: transparent;
    }

    background-color: ${(props) => (props.isOdd ? props.theme.backgrounds.modal4 : props.theme.backgrounds.modal)};
    width: ${(props) => {
    if (props.responsive) { return '100%;'; }
    if (props.width) { return `${props.width};`; }
    return '400px';
  }};      
`;
DataItem.displayName = 'DataItem';

export const Text = styled.div`
  text-align: left;
  ${ellipsisText}
  color: ${({ item }) => {
    if (item === 'Exploit') {
      return '#e53056';
    }
    if (item === 'Patch') {
      return '#85c83e';
    }
    return 'inherit';
    }
  };
`;
Text.displayName = 'Text';

export const Span = styled.span`
  position: ${(props) => (props.responsive ? 'absolute' : 'relative')};
  top: ${(props) => {
    if (props.responsive) {
      return props.hasTitle ? '39px' : '9px';
    }
    return '';
  }};
  right: ${(props) => (props.responsive ? '15px' : '')};
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;
Span.displayName = 'Span';

export const TrashIcon = styled(Trash)` 
    width: 16px;
    height: 17px;
    align-self: flex-end;
    color: ${colors.grey12};
`;

TrashIcon.displayName = 'TrashIcon';

export const Input = styled.input`
  min-height: 38px;
  width: 100%;
  border-style: solid;
  border-color: transparent;
  border-width: 1px 1px 0px 1px;
  background-color: ${({ theme }) => theme.textfield.background};
  padding-left: 15px;
  padding-right: 42px;
  font-weight: 300 !important;
  margin-bottom: 2px;
  color: ${({ theme }) => theme.textfield.text};
  box-shadow: ${({ theme }) => theme.shadows.inputs};

  &.full{
    background-color: ${({ theme }) => theme.textfield.focus} !important; 
  }

  &.responsive {
      width:100%;
  }

  &::placeholder {
    color: ${({ theme }) => theme.text.placeholder} !important;
    font-size: 13.5px;
  }

  &:hover{
    border-style: solid;
    border-color: ${({ theme }) => theme.shadows.inputInset};
    border-width: 1px 1px 1px 1px;
  }

  &:focus{
    background-color: ${({ theme }) => theme.textfield.focus};

    &::placeholder {
        color: transparent;
    }
  }

  ${({ disabled }) => disabled && 'cursor: not-allowed'};
`;
Input.displayName = 'Input';

// export const Input = styled.input`
//   height: ${(props) => (props.height)};
//   background: ${({ theme }) => theme.textfield.background};
//   color: ${({ theme }) => theme.textfield.text};
//   border: none;
//   outline: none;
//   box-shadow: none;

//   &.full{
//     background-color: ${({ theme }) => theme.backgrounds.inputsFocus};
//   }

//   &.responsive {
//     width: 100%;
//   }

//   &::placeholder {
//     color: ${({ theme }) => theme.text.placeholder};
//     font-size: 13.5px;
//     font-weight: 300;
//   }

//   &:hover{
//     border: none;
//     outline: none;
//     box-shadow: none;
//   }

//   &:focus{
//     background-color: ${({ theme }) => theme.backgrounds.inputsFocus};

//     &::placeholder {
//       color: transparent;
//     }
//   }

//   ${({ disabled }) => disabled && 'cursor: not-allowed'};
// `;
// Input.displayName = 'Input';

export const Title = styled.div`
    font-size: 12.5px;
    font-weight: 500;
    line-height: 2.08;
    color: ${({ theme }) => theme.text.modalLabel} !important;
    font-weight: 600;
    margin-bottom: 12px;
    ${({ required }) => required && `
        position: relative;
        &:after {
        content: '*';
        color: ${colors.redPink};
        position: absolute;
        top: 3px;
        margin-left: 3px;
        }
    `}  
`;
Title.displayName = 'Title';

export const CopyLinkIcon = styled(CopyLink)`
  & > path {
      fill: #0082ed;
  }
`;
CopyLinkIcon.displayName = 'CopyLinkIcon';
