import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as _PlusIcon } from 'Images/icon-action-bar-plus.svg';

export const Wrapper = styled.div`
  position: relative;
`;
Wrapper.displayName = 'Wrapper';

export const AddButton = styled.span`
  font-weight: 300;
  appearence: none;
  font-size: 12px;
  -webkit-appearance: none;
  color: ${colors.black}
`;
AddButton.displayName = 'AddButton';

export const DropdownText = styled.span`
  font-weight: 500;
  color: ${({ theme }) => theme.buttons.custom.color};
  font-size: 11.5px;
`;
DropdownText.displayName = 'DropdownText';

export const DropdownItem = styled.div`
height: 34px;
padding: 10px 17px;
display: flex;

svg {
  position: relative;
  margin-right: 15px;
}

&:hover {
  background-color: ${({ theme }) => theme.backgrounds.actionDropDownHover};
  font-weight: 600;
}
  font-size: 12px;
  font-weight: 300;
  color: ${({ theme }) => theme.text.actionDropDownItem};
`;
DropdownItem.displayName = 'DropdownItem';

export const Dropdown = styled.div`
  position: absolute;
  left: 0;
  width: 244px;
  height: 76px;
  border-radius: 3px;
  box-shadow: 2px 2px 30px 0 ${({ theme }) => theme.shadows.actionDropDown};
  border: solid 1px ${({ theme }) => theme.borders.actionDropDown};
  background-color: ${({ theme }) => theme.backgrounds.actionDropDown};
  padding: 3px 4px;
  z-index: 1000;
`;

export const PlusIcon = styled(_PlusIcon)`
height: 11px;
margin-right: 10px;
& > path {
  fill: ${({ theme }) => theme.buttons.addButton.color};
}
`;

export const DropdownWrapper = styled.div`
  background-color: ${({ theme }) => theme.buttons.addButton.background};
  padding: 0px 10px;
  font-size: 11.5px;
  font-weight: 500;
  line-height: 34px;
  height: 34px;
  border-radius: 2px;
  border: ${({ theme }) => `solid 1px ${theme.buttons.addButton.border}`};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    border-radius: 3px;
    border: ${({ theme }) => `solid 1px ${theme.buttons.addButton.border}`};
    background-color: ${({ theme }) => theme.buttons.addButton.backgroundHover};
  }

  &:active {
    border: solid 1px #bcccd9;
    background-color: #dde5ec;
  }

  ${({ disabled }) => {
    if (!disabled) return null;
    return `
      pointer-events: none;
      background-color: ${colors.white};
      & > span {
        color: ${colors.grey27};
      }
      & > svg {
        & > path {
          fill: ${colors.grey27};
        }
      }
      `;
  }}
`;
