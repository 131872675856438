import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from 'Common/Components/Checkbox';
import { allUsersAreSelected, selectUserSelected, selectUsersList, someUserIsSelected } from 'store/Users/selectors';
import { selectAllUser, unSelectAll } from 'Screens/Contextualization/Users/actions/Actions';

export const CheckHeader = () => { // eslint-disable-line import/prefer-default-export
  const allUserSelected = useSelector(allUsersAreSelected);
  const someUserSelected = useSelector(someUserIsSelected);
  const users = useSelector(selectUsersList);
  const count = users.length;
  const usersSelected = useSelector(selectUserSelected);
  const dispatch = useDispatch();
  const allUsersSelected = someUserSelected && (count === usersSelected.length);

  const handleClick = () => {
    if (allUsersSelected) {
      dispatch(unSelectAll());
    } else {
      dispatch(selectAllUser());
    }
  };

  return (
    <Checkbox theme="grey" onChange={ handleClick } state={ allUserSelected } partialChecked={ someUserSelected && !allUserSelected } />
  );
};
