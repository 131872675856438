import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { setField } from 'store/Settings/gitLabActions';
import {
  selectAccessToken, selectProject, selectSelfSignedCertificate, selectSslVerify, selectUrl
} from 'store/Settings/gitLabSelectors';
import CheckBox from 'Common/Components/Checkbox/styled';
import {
  Url, Wrapper, Content, WrapperCustomField, AccessToken, Project, Label
} from './styled';

const Settings = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const url = useSelector(selectUrl);
  const project = useSelector(selectProject);
  const accessToken = useSelector(selectAccessToken);
  const sslVerify = useSelector(selectSslVerify);
  const selfSignedCertificate = useSelector(selectSelfSignedCertificate);

  const change = (field, value) => {
    dispatch(setField(field, value));
  };

  return (
    <Wrapper>
      <Content>
        <Url title={ intl.formatMessage({ id: 'preferences.ticketingTools.gitLab.settings.url.title' }) } placeholder={ intl.formatMessage({ id: 'preferences.ticketingTools.gitLab.settings.url.placeholder' }) } onChange={ (v) => { change('url', v); } } value={ url } defaultValue={ url } autocomplete="url" id="gitlab-url" name="url" mandatory />
        <AccessToken title={ intl.formatMessage({ id: 'preferences.ticketingTools.gitLab.settings.accessToken.title' }) } placeholder={ intl.formatMessage({ id: 'preferences.ticketingTools.gitLab.settings.accessToken.placeholder' }) } onChange={ (v) => { change('accessToken', v); } } value={ accessToken } defaultValue={ accessToken } type="password" autocomplete="one-time-code" id="gitlab-access-token" name="accessToken" mandatory />
        <WrapperCustomField>
          <Label mandatory>{ intl.formatMessage({ id: 'preferences.ticketingTools.gitLab.settings.sslVerify.title' }) }</Label>
          <CheckBox checked={ sslVerify } onChange={ (e) => change('sslVerify', e.target.checked) } id="gitlab-ssl-verify" />
        </WrapperCustomField>
        <WrapperCustomField>
          <Label mandatory>{ intl.formatMessage({ id: 'preferences.ticketingTools.gitLab.settings.selfSignedCertificate.title' }) }</Label>
          <CheckBox checked={ selfSignedCertificate } onChange={ (e) => change('selfSignedCertificate', e.target.checked) } id="gitlab-self-signed-certificate" />
        </WrapperCustomField>
        <Project title={ intl.formatMessage({ id: 'preferences.ticketingTools.gitLab.settings.project.title' }) } placeholder={ intl.formatMessage({ id: 'preferences.ticketingTools.gitLab.settings.project.placeholder' }) } onChange={ (v) => { change('project', v); } } value={ project } defaultValue={ project } autocomplete="new-text" name="project" id="gitlab-project" mandatory />
      </Content>
    </Wrapper>
  );
};
export default Settings;
