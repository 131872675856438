import styled from 'styled-components';
import { Accept, Cancel } from 'Common/Components/Button/styled';

export const ConfirmButton = styled(Accept)`
`;
ConfirmButton.displayName = 'ConfirmButton';

export const CancelButton = styled(Cancel)`
`;
CancelButton.displayName = 'CancelButton';

export const ButtonContainer = styled.div`
    display: flex;
    align-self: flex-end;
    gap: 10px;
`;
