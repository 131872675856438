import styled from 'styled-components';
import OSX from 'Images/osx.png';
import Linux from 'Images/linux.png';
import Windows from 'Images/windows.png';
import Unknown from 'Images/laptop_icon.svg';
import Shodan from 'Images/shodan.png';
import colors from 'Styles/colors';
import Severity from './components/Severity';
import TimeAgo from 'react-timeago';
// import GoTo from 'Images/icon-goto.svg';
import { ReactComponent as GoTo } from 'Images/icon-goto.svg';

export const Wrapper = styled.div`
  height: 100%;
  flex: 1 1 0px;
  overflow: hidden;
`;
Wrapper.displayName = 'Wrapper';

export const Expander = styled.div.attrs((props) => ({
  className: props.isExpanded ? '-group-expanded' : '-group-unexpanded'
}))``;
Expander.displayName = 'Expander';

export const Icon = styled.img.attrs((props) => {
  if (props.type === 'unix') return { src: Linux };
  if (props.type === 'linux') return { src: Linux };
  if (props.type === 'osx') return { src: OSX };
  if (props.type === 'apple') return { src: OSX };
  if (props.type === 'mac') return { src: OSX };
  if (props.type === 'windows') return { src: Windows };
  if (props.type === 'shodan') return { src: Shodan };
  return { src: Unknown };
})`
  width: 12px;
  pointer-events: none;
  margin: 0 auto;
`;
Icon.displayName = 'Icon';

export const CenteredIcon = styled.div`
  display: flex;
  justify-content: center;
`;
CenteredIcon.displayName = 'CenteredIcon';

export const CenteredText = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.text.tableVulnName};
`;
CenteredText.displayName = 'CenteredText';

export const CenteredHeader = styled(CenteredText)`
  color: ${({ theme }) => theme.text.tableHeader};
`;
CenteredHeader.displayName = 'CenteredHeader';

export const Ip = styled.div`
  font-size: 14.5px;
  font-weight: 600;
  color: ${({ theme }) => theme.text.assetName};
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &:hover {
    text-decoration: underline;
  }
`;
Ip.displayName = 'Ip';

export const NumericRow = styled.div`
  font-weight: 500;
  color: ${colors.grey11};
  pointer-events: none;
`;
NumericRow.displayName = 'NumericRow';

export const CenteredNumericRow = styled(NumericRow)`
  text-align: center;
  pointer-events: all;
  cursor: default;
  color: ${({ theme }) => theme.text.tableVulnName};
`;
NumericRow.displayName = 'NumericRow';

export const Hostnames = styled.div`
  pointer-events: none;
  color: ${({ theme }) => theme.text.tableVulnName};
`;
Hostnames.displayName = 'Hostnames';

export const SeverityColumn = styled(Severity)`
`;
SeverityColumn.displayName = 'SeverityColumn';

export const TimeAgoColumn = styled(TimeAgo)`
  pointer-events: none;
`;
TimeAgoColumn.displayName = 'TimeAgoColumn';

export const IpWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;
IpWrapper.displayName = 'IpWrapper';

export const GoToButton = styled.div`
  width: 38px;
  cursor: pointer;
  border-radius: 20px;
  &:hover {
    background-color: #dfe5ed;
  }
`;
GoToButton.displayName = 'GoToButton';

export const GoToIcon = styled(GoTo)`
  path:not([d="M0 0h38v38H0z"]) {
    fill: ${({ theme }) => theme.icons.actionBar.color} !important;
  }
`;
GoToIcon.displayName = 'GoToIcon';
