import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedEntity, selectFilterHistory, selectLabeledHistory } from 'store/Filters/selectors';
import { removeFilter } from 'store/Filters/actions';
import PropTypes from 'prop-types';
import {
  CloseIcon, TagContainer, TagLabel, FilterTagContainer, Label
} from './styled';
import moment from 'moment';
import { selectCustomAttributes } from 'store/CustomAttributes/selectors';

export const Tag = ({
  showDelete, value, onDelete, type, valSelected, filterKey, customAttributes, isOneOf = false
}) => {
  const isDate = type === 'val' && filterKey.toLowerCase().includes('date');
  const isCustomField = type === 'key' && filterKey.toLowerCase().includes('custom_field');
  const label = isCustomField ? customAttributes.find((cf) => cf.field_name === value.split('->')[1]).field_display_name : '';
  const isOneOfVal = type === 'val' && isOneOf;

  const valueParsed = () => {
    if (isDate) return moment(value).format('L');
    else if (isCustomField) return label;
    else if (isOneOfVal) return value.split(',');
    else return value;
  };
  if (!value) return null;
  return (
    <TagContainer type={ type } valSelected={ valSelected } isOneOf={ isOneOf }>
    { isOneOfVal
      ? valueParsed()?.map((v, i) => <Label key={ `filter_value__${value}_${i}` }>{ v }</Label>)
      : <TagLabel type={ type } >
          {valueParsed()}
        </TagLabel>
    }
    { showDelete && <CloseIcon onClick={ onDelete } isOneOfVal={ isOneOfVal } />}
    </TagContainer>
  );
};

export const FilterTags = ({ onSearch }) => {
  const dispatch = useDispatch();
  const selectedEntity = useSelector(getSelectedEntity);
  const filterHistory = useSelector((state) => selectFilterHistory(selectedEntity, state));
  const labeledFilters = useSelector((state) => selectLabeledHistory(selectedEntity, state));
  const customAttributes = useSelector(selectCustomAttributes);
  const deleteTag = (filterIndex) => {
    dispatch(removeFilter(selectedEntity, filterHistory[filterIndex]));
    onSearch();
  };

  return (
    labeledFilters.map((filter, i) => (
      <FilterTagContainer key={ JSON.stringify(filter) }>
        { filter.name.includes('custom_fields') && <Tag type="mainKey" filterKey={ filter.name.split('->')[0] } value="Custom Attribute" /> }
        <Tag type="key" filterKey={ filter.name } value={ filter.name } customAttributes={ customAttributes } />
        <Tag type="op" filterKey={ filter.name } value={ filter.op } valSelected={ filter.val } />
        <Tag type="val" filterKey={ filter.name } value={ filter.val } showDelete onDelete={ () => deleteTag(i) } />
      </FilterTagContainer>
    )));
};

export const DraftTag = ({ draftTag }) => (
  <>
    <Tag type="key" value={ draftTag.name } />
    <Tag type="op" value={ draftTag.op } />
  </>
);

Tag.propTypes = {
  showDelete: PropTypes.bool,
  type: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
  value: PropTypes.string,
  filterKey: PropTypes.string
};

Tag.defaultProps = {
  onDelete: null,
  showDelete: false,
  value: '',
  filterKey: ''
};

DraftTag.propTypes = {
  draftTag: PropTypes.object.isRequired
};
