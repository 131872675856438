import styled from 'styled-components';
import colors from 'Styles/colors';
import { spinner } from 'Styles/effects';

const Button = styled.button`
    user-select: none;
    height: 34px;
    border-radius: 2px;
    background-color: ${(props) => (props.disabled ? props.theme.buttons.disabled.background : props.theme.buttons.primary.background)};
    padding: 7px 22px;
    color: ${(props) => (props.disabled ? props.theme.buttons.disabled.color : props.theme.buttons.primary.color)};
    text-transform: none;
    border: none;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    pointer-events: ${({ isLoading }) => isLoading && 'none'};
    ${({ isLoading }) => isLoading && spinner({ diameter: '15px' })};

    &:hover {
        ${({ disabled }) => !disabled && `background-color: ${colors.blue21}`};
    }

    &:active {
        background-color: ${(props) => (props.disabled ? '' : colors.softBlue)};
    }
`;

Button.displayName = 'Button';

export default Button;
