import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as _Warning } from 'Images/warning-delete.svg';
import { slideIn } from 'Styles/effects';
import { Accept, Cancel } from '../Button/styled';

export const ModalContent = styled.div`
  padding: 34px;
  box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.24);
  border-radius: 2px;
  border-top: 10px solid ${colors.softBlue};
  background: ${({ theme }) => theme.backgrounds.modal2};
  width: 505px;
  ${slideIn}
`;
ModalContent.displayName = 'ModalContent';

export const ModalTitle = styled.div`
  display: flex;
  margin-bottom: 21px;
`;
ModalTitle.displayName = 'ModalTitle';

export const Warning = styled(_Warning)`
  align-self: center;
  & > g path{
    fill: ${({ theme }) => theme.icons.actionBar.color};
  }
`;
Warning.displayName = 'Warning';

export const Title = styled.div`
  color: ${({ theme }) => theme.text.modalTitle2};
  font-weight: 600;
  font-size: 21.5px;
  margin-left: 13px;
`;
Title.displayName = 'Title';

export const ModalBody = styled.div`
  text-align: initial;
`;
export const BodyText = styled.div`
  color: ${colors.grey19};
  font-size: 12.5px;
  font-weight: 300;
  display: inline-block;
  text-align: initial;
`;
BodyText.displayName = 'BodyText';

export const ButtonsContainer = styled.div`
display: flex;
justify-content: flex-end;
margin-top: 34px;
`;
ButtonsContainer.displayName = 'ButtonsContainer';

export const ConfirmButton = styled(Accept)`
`;
ConfirmButton.displayName = 'ConfirmButton';

export const CancelButton = styled(Cancel)`
  margin-right: 10px;
`;
CancelButton.displayName = 'CancelButton';
