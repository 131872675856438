/* eslint-disable react/destructuring-assignment */
import React from 'react';
import Select, { components } from 'react-select';
import { PropTypes } from 'prop-types';
import {
  Wrapper, OptionWrapper, dropdownStyle, Dot, Placeholder, Asterisk
} from './styled';
import useTheme from 'Hooks/UseTheme';

const { Option, SingleValue } = components;
const IconOption = (props) => (
  <Option { ...props }>
    <OptionWrapper>
      {props.selectProps.fields === 'status' && <Dot progress={ props.data.label } /> }
      {props.data.label}
    </OptionWrapper>
  </Option>
);
const ValueSelected = (props) => (
  <SingleValue { ...props }>
    <OptionWrapper>
      {props.selectProps.fields === 'status' && <Dot progress={ props.data.label } /> }
      {props.data.label}
    </OptionWrapper>
  </SingleValue>
);

const CustomPlaceholder = ({ fields }) => (
  <>
    {fields === 'types'
      ? (
        <Placeholder>
          Task Type
          <Asterisk>*</Asterisk>
        </Placeholder>
        )
      : <Placeholder>Select One</Placeholder> }
  </>
);

const Dropdown = ({
  options, value, handleChange, fields, hasTitle
}) => {
  const { themeStyles } = useTheme();

  return (
    <Wrapper hasTitle={ hasTitle }>
      <Select
        options={ options }
        value={ options.filter((opt) => opt.value === value) }
        onChange={ handleChange }
        fields={ fields }
        components={ { Option: IconOption, SingleValue: ValueSelected } }
        styles={ dropdownStyle(themeStyles) }
        placeholder={ <CustomPlaceholder fields={ fields } /> }
        isSearchable={ false }
      />
    </Wrapper>
  );
};

Dropdown.defaultProps = {
  hasTitle: false
};

Dropdown.propTypes = {
  options: PropTypes.instanceOf(Array).isRequired,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  fields: PropTypes.string.isRequired,
  hasTitle: PropTypes.bool
};

export default Dropdown;
