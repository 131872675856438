import styled from 'styled-components';

const Text = styled.p`
  font-size: 13.5px;
  color: ${({ theme }) => theme.text.card};
  font-weight: ${({ confirmed }) => (confirmed ? '600' : 'normal')}; 
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 1 1 0;
  ${({ isLinkEnabled }) => isLinkEnabled && `
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  `};
`;
Text.dislayName = 'Text';

export default Text;
