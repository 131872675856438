import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 50px;
  width: 100%;
  align-items: center;
  background-color: ${({ theme }) => theme.backgrounds.actionBar};
  padding: 8px 10px;
`;
Wrapper.displayName = 'Wrapper';

export const Line = styled.div`
  width: 1px;
  content: ' ';
  background: transparent;
  border-right: 1px #B5BEC6;
  border-right-style: dashed;
  height: 13px;
  margin: 0 16px;
`;
Line.displayName = 'Line';

export const Title = styled.div`
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.08;
  letter-spacing: normal;
  color: ${({ theme }) => theme.text.sectionTitle};
  user-select: none;
`;
Title.displayName = 'Title';

export const PaginationWrapper = styled.div`
  margin-left: auto;
`;
PaginationWrapper.displayName = 'PaginationWrapper';
