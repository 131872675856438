import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 21px 24px;
  border-radius: 3px;
  box-shadow: 2px 2px 5px 0 rgba(97, 97, 97, 0.06);
  border: solid 1px ${({ theme }) => theme.borders.analyticsNewGraphItems};
  background-color: ${({ theme }) => theme.backgrounds.analyticsNewGraphItems};
  margin-top: 8px;

  &:hover {
  border: solid 1px ${colors.blueCerulean};
  cursor: pointer;
  background-color: ${({ theme }) => theme.backgrounds.analyticsNewGraphItems};
  }
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  font-size: 13.5px;
  font-weight: 600;
  color: ${({ theme }) => theme.text.analyticsNewGraphItems};
  margin-bottom: 13px;
`;
Title.displayName = 'Title';

export const Subtitle = styled.div`
font-size: 12.5px;
line-height: 1.28;
color: ${({ theme }) => theme.text.customAttributeItem};
`;
Subtitle.displayName = 'Subtitle';
