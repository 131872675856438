import styled from 'styled-components';
import DebouncedInput from 'Common/Components/v2/DebouncedInput';
import { Input } from 'Common/Components/StandardTextField/styled';

export const Wrapper = styled.div`
  padding: 0;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  font-size: 13.5px;
  font-weight: 400;
  color: ${({ theme }) => theme.textfield.text};
`;
Title.displayName = 'Title';

export const Content = styled.form`
  padding-top: 12px;
  & > :nth-child(odd) {
    margin-right: 32px;
  }
`;
Content.displayName = 'Content';

export const CustomInput = styled(DebouncedInput)`
  width: 267px;
  span {
    font-weight: 400;
    margin-right: 3px;
  }
  ${Input} {
    &::placeholder,
    &::-webkit-input-placeholder {
      font-size: 14.5px;
      color: ${({ theme }) => theme.text.taskPlaceholder};
    }
  }
`;
CustomInput.displayName = 'CustomInput';

export const PleaseWait = styled.div`
  padding: 17px 0;
  font-size: 12.5px;
  line-height: 1.12;
  text-align: left;
  color: #63758d;
`;
PleaseWait.displayName = 'PleaseWait';

export const WrapperTitle = styled.div`
  display: flex;
  align-items: center;
  & > :not(:last-child) {
    margin-right: 10px;
  }
`;
WrapperTitle.displayName = 'WrapperTitle';

export const Row = styled.div`
  display: flex;
  align-items: center;
  & > :not(:last-child) {
    margin-right: 32px;
  }
`;
Row.displayName = 'Row';
