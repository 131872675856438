import colors from 'Styles/colors';
import styled from 'styled-components';

import { ReactComponent as Exploit } from 'Images/icon_threat.svg';
import { ReactComponent as Cisa } from 'Images/icon_cisa.svg';
import { ReactComponent as Trending } from 'Images/icon_trending.svg';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.label`
  user-select: none;
  color: ${({ theme }) => theme.text.dashboardBlueTitle};
  font-size: 14px;
  font-weight: 600;
  margin-top: 8px;
  margin-bottom: 15px;
`;
Title.displayName = 'Title';

export const Content = styled.div`
  display: flex;
  justify-content: space-around;
  padding-bottom: 6px;
  align-items: center;
`;
Content.displayName = 'Content';

export const Value = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #4c6c8c;
`;
Value.displayName = 'Value';

export const Text = styled.div`
  font-size: 10px;
  color:${colors.blueGrey};
`;
Text.displayName = 'Text';

export const Threat = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: normal;
  cursor: pointer;
`;
Threat.displayName = 'Threat';

export const ExploitIcon = styled(Exploit)`
  margin-bottom: 4px;
`;
ExploitIcon.displayName = 'ExploitIcon';

export const CisaIcon = styled(Cisa)`
  margin-bottom: 4px;
`;
CisaIcon.displayName = 'CisaIcon';

export const TrendingIcon = styled(Trending)`
  margin-bottom: 4px;
`;
TrendingIcon.displayName = 'TrendingIcon';

export const Empty = styled.div`
  user-select: none;
  color: ${colors.grey5};
  display: inline;
  font-size: 13px;
  font-weight: 300;
  line-height: 2.62;
`;
Empty.displayName = 'Empty';
