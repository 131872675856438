import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalConfirmation from 'Common/Components/ModalConfirmation';
import { selectSchedulersSelected } from 'store/Schedule/selectors';
import { removeSchedule } from 'store/Schedule/actions';
import { closeModal } from 'store/modals/actions';
import { MODAL_SCHEDULE_DELETE } from 'store/modals/modals';
import selectModal from 'store/modals/selectors';

const DeleteConfirmationModal = () => {
  const dispatch = useDispatch();
  const showDeleteConfirmation = useSelector((state) => selectModal(state, MODAL_SCHEDULE_DELETE));
  const schedulersSelected = useSelector(selectSchedulersSelected);
  const count = schedulersSelected.length;

  const onClose = () => dispatch(closeModal(MODAL_SCHEDULE_DELETE));

  const handleSubmit = () => {
    dispatch(removeSchedule());
    onClose();
  };

  return (
    <ModalConfirmation
      show={ showDeleteConfirmation }
      handleSubmit={ handleSubmit }
      handleClose={ onClose }
      entity="scheduler"
      count={ count }
    />
  );
};

export default DeleteConfirmationModal;
