import React, { useContext } from 'react';
import { Wrapper, LabelWrapper, CloudIcon, Option, dropdownStyle } from './styled';
import { Dropdown } from 'Common/Components/Inputs';
import { ThemeContext } from 'Context';

const formatOptionLabel = (opt) => {
  return (
    <LabelWrapper>
      { opt.value.type ? <CloudIcon /> : null }
      <Option title={ opt.label }>{opt.label}</Option>
    </LabelWrapper>
  );
};

const AgentsDropdown = ({ options, updateValue, value, placeholder }) => {
  const { themeStyles } = useContext(ThemeContext);
  return (
    <Wrapper>
      <Dropdown
        onChange={ updateValue }
        options={ options }
        value={ value }
        style={ dropdownStyle(themeStyles) }
        formatOptionLabel={ formatOptionLabel }
        placeholder={ placeholder }
      />
    </Wrapper>
  );
};

export default AgentsDropdown;
