/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { ReactComponent as MoonImage } from 'Images/moon-stars-svgrepo-com.svg';
import { ReactComponent as SunImage } from 'Images/sun-dm.svg';
import { ReactComponent as collapsedImage } from 'Images/wf_arrow_collapsed.svg';
import { ReactComponent as laptopImage } from 'Images/device.svg';
import { ReactComponent as checkImage } from 'Images/check2.svg';

export const Item = styled.li`
    display: flex;
    align-items: center;
    justify-content: left;
    list-style: none;
    text-align: left;
    color: ${({ theme }) => theme.text.actionDropDownItem};
    font-size: 14px;
    padding:10px 20px;
    gap: 15px;
    font-weight: 300;
    cursor: pointer;
    &:hover{
        background-color: ${({ theme }) => theme.backgrounds.actionDropDownHover};
    }

    & a{
        color: ${({ theme }) => theme.text.actionDropDownItem};
    }
`;
Item.displayName = 'Item';

export const Hr = styled.div`
    height: 1px;
    background-color: ${({ theme }) => theme.borders.preferenceMenuLine};
`;
Hr.displayName = 'Hr';

export const MoonIcon = styled(MoonImage)`
    border: 1px solid red;
    width: 17.6px;
    height: 16px;
    path {
      fill: ${({ theme }) => theme.text.actionDropDownItem};
    }
`;
MoonIcon.displayName = 'MoonIcon';

export const SunIcon = styled(SunImage)`
    width: 16px;
    height: 16px;
    margin-left: 2px;
    path {
      fill: ${({ theme }) => theme.text.actionDropDownItem};
    }
`;
SunIcon.displayName = 'SunIcon';

export const CollapsedIcon = styled(collapsedImage)`
    width: 10px;
    height: 10px;
    margin-left: 2px;
    margin-right: 6px;
    transform: rotate(180deg);
    path {
      fill: ${({ theme }) => theme.icons.preferenceMenu.color};
    }
`;
CollapsedIcon.displayName = 'CollapsedIcon';

export const LaptopIcon = styled(laptopImage)`
    path {
      fill: ${({ theme }) => theme.text.actionDropDownItem};
    }
`;
LaptopIcon.displayName = 'LaptopIcon';

export const CheckIcon = styled(checkImage)`
    path {
      fill: ${({ theme }) => theme.text.actionDropDownItem};
    }
`;
CheckIcon.displayName = 'CheckIcon';
