import React from 'react';
import { ReactComponent as PlusIcon } from 'Images/icon-action-bar-plus.svg';
import { Wrapper, Button } from './styled';
import { useDispatch } from 'react-redux';
import { setIsCreateSchedule } from 'store/Schedule/actions';
import { MODAL_SCHEDULE_CREATE_EDIT } from 'store/modals/modals';
import { openModal } from 'store/modals/actions';

const CreateButton = () => {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(setIsCreateSchedule(true));
    dispatch(openModal(MODAL_SCHEDULE_CREATE_EDIT));
  };

  return (
    <Wrapper>
      <Button title="Create" onClick={ handleClick }>
        <PlusIcon />
        Create Schedule
      </Button>
    </Wrapper>
  );
};

export default CreateButton;
