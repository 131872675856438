import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAuthenticated, selectError, selectIsFetching, selectMessage
} from 'store/Settings/serviceNowSelectors';
import { useIntl } from 'react-intl';

import {
  Content,
  Wrapper,
  WrapperTitle,
  CustomInput,
  Title,
  Row,
  PleaseWait
} from './styled';
import { authenticate, deauthenticate } from 'store/Settings/serviceNowActions';
import BlueButton from 'Common/Components/BlueButton';
import Error from 'Common/Components/Error';
import Success from 'Common/Components/Success';
import Checkbox from 'Common/Components/Checkbox/styled';

const Oauth = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectAuthenticated);
  const [authenticated, setAuthenticated] = useState(isAuthenticated);
  const [showParams, setShowParams] = useState(false);
  const [clientId, setClientId] = useState();
  const [secretKey, setSecretKey] = useState();
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();

  const isFetching = useSelector(selectIsFetching);
  const error = useSelector(selectError);
  const message = useSelector(selectMessage);

  useEffect(() => {
    if (authenticated) setShowParams(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const oAuthChange = (e) => {
    setAuthenticated(e.target.checked);
    setShowParams(e.target.checked);
    if (isAuthenticated && !e.target.checked) dispatch(deauthenticate());
  };

  const save = () => {
    dispatch(authenticate(clientId, secretKey, username, password));
  };

  if (isFetching) return <PleaseWait>{ intl.formatMessage({ id: 'preferences.ticketingTools.wait' }) }</PleaseWait>;
  return (
    <Wrapper>
      <WrapperTitle>
        <Title>{ intl.formatMessage({ id: 'preferences.ticketingTools.serviceNow.oauth.title2' }) }</Title>
        <Checkbox defaultChecked={ isAuthenticated } onChange={ (e) => oAuthChange(e) } disabled={ isFetching } />
      </WrapperTitle>
      { showParams && (
        <Content autoComplete='off'>
          <Row>
            <CustomInput title={ intl.formatMessage({ id: 'preferences.ticketingTools.serviceNow.oauth.clientId2' }) } value={ clientId } defaultValue={ clientId } onChange={ (v) => { setClientId(v); } } autocomplete="new-text" name="serviceNowCI" id="serviceNowCI" mandatory placeholder="John_Doe" />
            <CustomInput title={ intl.formatMessage({ id: 'preferences.ticketingTools.serviceNow.oauth.secretKey2' }) } value={ secretKey } defaultValue={ secretKey } onChange={ (v) => { setSecretKey(v); } } autocomplete="new-password" name="serviceNowSK" id="serviceNowSK" mandatory type="password" placeholder="ABC61234563ABC123456123431" />
          </Row>
          <Row>
            <CustomInput title={ intl.formatMessage({ id: 'preferences.ticketingTools.serviceNow.oauth.username2' }) } value={ username } defaultValue={ username } onChange={ (v) => { setUserName(v); } } autocomplete="new-text" name="serviceNowU" id="serviceNowU" mandatory placeholder="John_Doe" />
            <CustomInput title={ intl.formatMessage({ id: 'preferences.ticketingTools.serviceNow.oauth.password2' }) } value={ password } defaultValue={ password } onChange={ (v) => { setPassword(v); } } autocomplete="new-password" name="serviceNowP" id="serviceNowP" mandatory type="password" placeholder="*************" />
          </Row>
          <BlueButton onClick={ save } label={ intl.formatMessage({ id: 'preferences.ticketingTools.serviceNow.oauth.save' }) } isLoading={ isFetching } />
        </Content>
      )}
      {error && message ? <Error>{message}</Error> : null}
      {!error && message ? <Success>{message}</Success> : null}
    </Wrapper>
  );
};
export default Oauth;
