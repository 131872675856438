import styled from 'styled-components';

export const Title = styled.div`
  font-size: 21.5px;
  font-weight: 600;
  color: ${({ theme }) => theme.textfield.text};
  margin-bottom: 20px;
`;
Title.displayName = 'Title';

export const Description = styled.div`
font-size: 12.5px;
line-height: 1.44;
color: ${({ theme }) => theme.text.modalTitle2};
margin-bottom: 34px;
`;
Description.displayName = 'Description';

export const Center = styled.div`
  background-color: ${({ theme }) => theme.backgrounds.modal};
  max-width: 406px;
  padding: 20px 40px;
  margin: 34px;
`;
Center.displayName = 'Center';

export const ButtonContainer = styled.div`
  display:flex;
  width: 191px;
  justify-content: space-between;
  margin-left:auto;
`;
ButtonContainer.displayName = 'ButtonContainer';
