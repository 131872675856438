import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from 'Common/Components/Checkbox';
import get from 'lodash/get';
import { selectRow } from 'Screens/Contextualization/Users/actions/Actions';
import { selectUserSelected } from 'store/Users/selectors';

export const CheckColumn = ({ rowInfo }) => { // eslint-disable-line import/prefer-default-export
  const user = get(rowInfo, 'original', {});
  const usersSelected = useSelector(selectUserSelected);
  const dispatch = useDispatch();
  const isSelected = usersSelected.find((userSelected) => userSelected.id === user.id);
  const handleClick = (e) => dispatch(selectRow(e, user));

  return (
    <Checkbox theme="grey" state={ !!isSelected } onChange={ handleClick } />
  );
};
