import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto;
  margin-left: ${({ searchBarStyle }) => searchBarStyle ? 'auto' : '15px'};
  align-items: center;
`;

export const SearchBarWrapper = styled.div`
  min-width: 490px;
  height: 40px;
  position: relative;
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 2px;
  box-shadow: 3px 15px 50px 0 '#edf2f7';
  border: solid 1px ${({ theme }) => theme.borders.searchBar};
  box-shadow: 3px 15px 50px 0 '#edf2f7';
  background-color: ${({ theme }) => theme.backgrounds.searchBar};
`;
