import styled from 'styled-components';
import { ReactComponent as Folder } from 'Images/empty-folder.svg';

export const Wrapper = styled.div`
    margin: 0px auto auto auto;
    width: 100%;
    max-width: 589px;
    flex: 0 0 753px;
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(32deg, #fff 84%, #d3d3d3 109%, #dedede 111%);
    border: solid 1px #ddd;
    box-shadow: ${({ theme }) => theme.boxShadow.modal};
`;
Wrapper.displayName = 'Wrapper';

export const FolderIcon = styled(Folder)`
    margin: 181px auto 19px auto;
    `;
FolderIcon.displayName = 'FolderIcon';

export const NoVulnsTitle = styled.div`
    margin: 0px auto auto auto;
    font-size: 13.5px;
    font-weight: lighter;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #8f94b2;
`;
NoVulnsTitle.displayName = 'NoVulnsTitle';
