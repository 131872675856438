import styled from 'styled-components';

export const DataWrapper = styled.div`
  padding: 0 20px 30px 20px;
`;
DataWrapper.displayName = 'DataWrapper';

export const Subtitle = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.text.modalTitle2};
  margin-bottom: 11px;
`;
Subtitle.displayName = 'Subtitle';
