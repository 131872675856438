import styled from 'styled-components';

export const StyledTextarea = styled.textarea`
  border: none;
  outline: none;
  resize: none;
  overflow: hidden;
  width: 100%;
  font-size: 13.5px;
  font-family: inherit;
  line-height: 1.5;
  color: ${({ theme }) => theme.text.detailModalTitle};
  background-color: ${({ theme }) => theme.textfield.background};
  
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  
  &:focus {
    outline: none;
  }
`;

export const StyledMarkdown = styled.span`
  color: ${({ theme }) => theme.text.detailModalTitle};
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;
  /* line-height: 1; */
  width: 100%;
  p {
    display: inline;
  }
`;
