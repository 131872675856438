import styled from 'styled-components';
import colors from 'Styles/colors';

export const Col = styled.div`
  font-size: 12.5px;
  font-weight: 300;
  color: ${({ theme }) => theme.text.tableVulnName};
  pointer-events: none;
  color: ${({ theme }) => theme.text.tableVulnName};
`;

export const ColKbName = styled(Col)`
  font-size: 14.5px;
  font-weight: 400;
  color: ${colors.dark5} !important;
  z-index: 10;
`;
ColKbName.displayName = 'ColKbName';

export const ColKbDescription = styled(Col)`
`;
ColKbDescription.displayName = 'ColKbDescription';

export const ColKbReferences = styled(Col)`
`;
ColKbReferences.displayName = 'ColKbReferences';

export const ColKbResolution = styled(Col)`
`;
ColKbResolution.displayName = 'ColKbResolution';

export const ColKbSeverity = styled(Col)`
`;
ColKbSeverity.displayName = 'ColKbSeverity';

export const ColKbPolicyViolations = styled(Col)`
`;
ColKbPolicyViolations.displayName = 'ColKbPolicyViolations';

export const ColKbCreator = styled(Col)`
`;
ColKbCreator.displayName = 'ColKbCreator';

export const ColKbCreatedDate = styled(Col)`
`;
ColKbCreatedDate.displayName = 'ColKbCreatedDate';
