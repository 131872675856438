import styled from 'styled-components';
import { ReactComponent as RefreshImage } from 'Images/reload.svg';

// eslint-disable-next-line import/prefer-default-export
export const RefreshIcon = styled(RefreshImage)`
    g > path {
      fill: ${({ theme }) => theme.icons.actionBar.color};
      stroke: ${({ theme }) => theme.icons.actionBar.color};
      stroke-width: 0.5;
    }
`;
RefreshIcon.displayName = 'RefreshIcon';
