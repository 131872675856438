/* eslint-disable import/prefer-default-export */
import BlueButton from 'Common/Components/BlueButton';
import StringInput from 'Common/Components/v2/Input/components/StringInput';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  gap: 22px;
`;
Wrapper.displayName = 'Wrapper';

export const Button = styled(BlueButton)`
  min-width: 267px;
  margin-top: 23px;
`;
Button.displayName = 'Button';

export const Url = styled(StringInput)`
  width: 267px;
`;
Url.displayName = 'CustomPassword';
