import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ContextMenu, MenuItem
} from 'react-contextmenu';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Trash from 'Images/icon-trash-red.svg';
import { MdKeyboardArrowRight as Arrow } from 'react-icons/md';
import {
  moreStatusChange, update2faBulk, updateRoleBulk, showUserModalDelete
} from '../../actions/Actions';
import { selectAllowedByFeature, selectAllowedByRole } from 'Hooks/usePermission/selector';
import {
  FEATURE_2FA, FEATURE_USERS, FEATURE_WORKSPACES, PERMISSION_TYPES
} from 'Hooks/usePermission/permissions';
import { selectUserSelected } from 'store/Users/selectors';
import { selectUserName } from 'store/Faraday/selectors';
import find from 'lodash/find';
import SubMenuWorkspaces from './SubMenuWorkspaces';
import {
  Wrapper, MenuItemContent, Text, SubMenuUsers, ArrowIcon, RoleText, SubMenuRol,
  IconDelete, SubMenu2Fa
} from './styled';
import { ThemeContext } from 'Context';
import useTheme from 'Hooks/UseTheme';
import { Separator } from 'Common/Components/ContextMenu/styled';
import { openModal } from 'store/modals/actions';
import { MODAL_USERS_ASSIGN_WS } from 'store/modals/modals';
import { AssignWs, Role, TwoFactor } from '../ActionBar/styled';

const AssignWsMenuItem = ({ isBulk }) => (
  <MenuItemContent>
  <AssignWs />
    <Text><FormattedMessage id="users.contextMenu.assignWorkspaces" /></Text>
    { !isBulk ? <ArrowIcon><Arrow /></ArrowIcon> : null }
  </MenuItemContent>
);

const ContextMenuUsersMassiveUpdate = ({
  onShow, trigger, onHide, disabled
}) => {
  const dispatch = useDispatch();
  const [showing, setShowing] = useState(false);
  const assignWorkspacesIsAllowed = useSelector((state) => selectAllowedByRole(state, FEATURE_WORKSPACES, PERMISSION_TYPES.PATCH) && selectAllowedByFeature(state, FEATURE_WORKSPACES, PERMISSION_TYPES.PATCH));
  const changeRoleIsAllowed = useSelector((state) => selectAllowedByRole(state, FEATURE_USERS, PERMISSION_TYPES.PATCH) && selectAllowedByFeature(state, FEATURE_USERS, PERMISSION_TYPES.PATCH));
  const twoFaIsAllowed = useSelector((state) => selectAllowedByRole(state, FEATURE_2FA, PERMISSION_TYPES.CREATE) && selectAllowedByFeature(state, FEATURE_2FA, PERMISSION_TYPES.CREATE));
  const deleteIsAllowed = useSelector((state) => selectAllowedByRole(state, FEATURE_USERS, PERMISSION_TYPES.DELETE) && selectAllowedByFeature(state, FEATURE_USERS, PERMISSION_TYPES.DELETE));
  const userName = useSelector(selectUserName);
  const userSelected = useSelector(selectUserSelected);
  const isBulk = userSelected?.length > 1;
  const { theme } = useTheme(ThemeContext);

  const themeClass = theme === 'light' ? 'light' : 'dark';
  const disabledActionsCurrentUser = () => (find(userSelected, { username: userName }));
  const allAdminsSelected = userSelected.every(user => user.roles.includes('admin'));

  return (
    <ContextMenu className={ `users-context-menu-${themeClass}` } id={ trigger } onHide={ () => { setShowing(false); dispatch(moreStatusChange(false)); onHide(); } } onShow={ () => { setShowing(true); onShow(true); } }>
      { (!disabled) && (
      <Wrapper>
         { (assignWorkspacesIsAllowed && isBulk && !disabledActionsCurrentUser() && !allAdminsSelected) && (
          <MenuItem onClick={ () => { dispatch(openModal(MODAL_USERS_ASSIGN_WS)); } } className={ `users-context-menu-item-${themeClass}` }>
            <AssignWsMenuItem isBulk={ isBulk } />
          </MenuItem>
         )}
        { (assignWorkspacesIsAllowed && !isBulk && !disabledActionsCurrentUser() && !allAdminsSelected) && (
          <SubMenuUsers
            preventCloseOnClick
            title={ (
            <AssignWsMenuItem isBulk={ isBulk } />
          ) }
          >
          <SubMenuWorkspaces showing={ showing } />
          </SubMenuUsers>
        ) }
        { twoFaIsAllowed && (
          <SubMenu2Fa title={ (
            <MenuItemContent>
            <TwoFactor />
              <Text><FormattedMessage id="users.contextMenu.enableDisable2fa" /></Text>
              <ArrowIcon><Arrow /></ArrowIcon>
            </MenuItemContent>
            ) }
          >
            <MenuItem onClick={ () => { dispatch(update2faBulk('disabled')); } }>Enabled</MenuItem>
            <MenuItem onClick={ () => { dispatch(update2faBulk('enabled')); } }>Disabled</MenuItem>
          </SubMenu2Fa>
        ) }
        { changeRoleIsAllowed && !disabledActionsCurrentUser() && (
        <SubMenuRol title={ (
          <MenuItemContent>
          <Role />
            <Text><FormattedMessage id="users.contextMenu.changeRole" /></Text>
            <ArrowIcon><Arrow /></ArrowIcon>
          </MenuItemContent>
          ) }
        >
          <MenuItem onClick={ () => { dispatch(updateRoleBulk('admin')); } }><RoleText role="admin"><FormattedMessage id="admin" /></RoleText></MenuItem>
          <MenuItem onClick={ () => { dispatch(updateRoleBulk('pentester')); } }><RoleText role="pentester"><FormattedMessage id="pentester" /></RoleText></MenuItem>
          <MenuItem onClick={ () => { dispatch(updateRoleBulk('asset_owner')); } }><RoleText role="asset_owner"><FormattedMessage id="asset_owner" /></RoleText></MenuItem>
          <MenuItem onClick={ () => { dispatch(updateRoleBulk('client')); } }><RoleText role="client"><FormattedMessage id="client" /></RoleText></MenuItem>
        </SubMenuRol>
        ) }
        { deleteIsAllowed && !disabledActionsCurrentUser() && (
          <>
            <Separator show />
            <MenuItem onClick={ () => { dispatch(showUserModalDelete()); } } className={ `users-context-menu-item-${themeClass}` }>
              <MenuItemContent>
                <IconDelete src={ Trash } />
                <Text isDeleteItem><FormattedMessage id="users.contextMenu.delete" /></Text>
              </MenuItemContent>
            </MenuItem>
          </>
        )}
      </Wrapper>
      ) }
    </ContextMenu>
  );
};

ContextMenuUsersMassiveUpdate.prototypes = {
  onHide: PropTypes.func.isRequired,
  trigger: PropTypes.string.isRequired
};

export default ContextMenuUsersMassiveUpdate;
