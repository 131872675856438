/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 5px;
    height: 78px;
`;
Wrapper.displayName = 'Wrapper';

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 38px;
    gap: 8px;
`;
Container.displayName = 'Container';

export const Title = styled.div`
    font-size: 12.5px;
    font-weight: 600;
    margin-bottom: 9px;
    color: ${({ theme }) => theme.text.modalLabel} !important;
`;
Title.displayName = 'Title';

export const Error = styled.div`
    margin-left: 130px;
    margin-top: 9px;
    font-size: 10.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.08px;
    color: #ea3158;
`;
Error.displayName = 'Error';
