/* eslint-disable quote-props */
/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as TrashIcon } from 'Images/icon-action-bar-trash.svg';
import { ReactComponent as ErrorIcon } from 'Images/exclamation.svg';
import { ReactComponent as LoadingIcon } from 'Images/icon-pipeline-running.svg';
import { ReactComponent as MoreIcon } from 'Images/icon-action-bar-plus.svg';
import { dropdownStyle } from 'Common/Components/Inputs/styled';

export const Wrapper = styled.h4`
  margin-top: 32px;
`;

export const InputComp = styled.select`
  all: unset;
  background-color: #fafbfc;
  height: 34px;
  width: 92%;

  font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.11px;
    padding-left: 22px;
    line-height: 34px;
    flex: 1;

  &::placeholder {
     color: #afb1bc;
  }
`;

export const InputWrapper = styled.div`
position: relative;
`;

export const DataContainer = styled.div`
  margin-top: 7px;
  max-height: 117px;
`;

export const IconWrapper = styled.div`
`;

export const Trash = styled(TrashIcon)` 
    width: 16px;
    height: 17px;
    align-self: flex-end;
    g, path {
      fill: ${({ theme }) => theme.icons.actionBar.color};
    }
`;

export const Error = styled(ErrorIcon)`
`;

export const Loading = styled(LoadingIcon)`
`;

export const Label = styled.div`
  min-width: 120px;
  font-size: 13.5px;
`;

export const Input = styled.input`
  min-width: 234px;
  max-width: 234px;
  height: 27px;
  background-color: ${colors.blue8};
  border: none;
  padding: 10px;
  width: 100%;
  color: #333;
  font-size: 14px;
  font-family: Arial, sans-serif;
  box-shadow: inset 0 -1px 0 0 #afafaf;

  &:focus {
    outline: none;
  }
`;

export const DataItem = styled.div`    
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    color: #1c2243;
    cursor: pointer;
    padding: 0 12px 0px 20px;
    gap: 11px;
    min-width: 100%;
    height: 34px;
    border: 1px solid ${props => (!props.loading && !props.found) ? 'red' : '#ededed'};
    font-weight: 400;
    margin: 3px 0;

    &:hover{
      font-weight: 500;

  }
`;
DataItem.displayName = 'DataItem';

export const CustomDropdownStyle = {
  ...dropdownStyle,
  cursor: 'pointer',
  singleValue: (provided, state) => ({
    ...provided,
    display: 'none'
  }),
  multiValue: (provided) => ({
    ...provided,
    display: 'none'
  })
};
CustomDropdownStyle.displayName = 'CustomDropdownStyle';

export const CustomSelectStyle = (theme) => ({
  control: (provided) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    minWidth: '234px',
    height: '27px',
    minHeight: '27px',
    borderRadius: '1px',
    boxShadow: theme.boxShadow.modal,
    backgroundColor: `${theme.textfield.background} !important`,
    color: `${theme.textfield.text} !important`,
    border: `1px solid ${theme.borders.importanceMeter}`,
    fontWeight: '700',
    padding: '0',
    boxSizing: 'border-box'
  }),
  valueContainer: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    height: '27px',
    padding: '0 5px',
    boxSizing: 'border-box',
    color: `${theme.textfield.text} !important`,
    backgroundColor: `${theme.textfield.background} !important`
  }),
  multiValue: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: `${theme.textfield.background} !important`,
    color: `${theme.textfield.text} !important`,
    fontSize: '11.5px',
    fontWeight: '500',
    height: '19px',
    padding: '0 4px',
    margin: '0 2px',
    boxSizing: 'border-box'
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    padding: '0',
    height: '100%',
    color: `${theme.textfield.text} !important`,
    backgroundColor: `${theme.textfield.background} !important`
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    padding: '0 4px',
    height: '100%',
    boxSizing: 'border-box',
    color: `${theme.textfield.text} !important`,
    backgroundColor: `${theme.textfield.background} !important`
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '27px',
    display: 'flex',
    alignItems: 'center',
    color: `${theme.textfield.text} !important`,
    backgroundColor: `${theme.textfield.background} !important`
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '0 4px',
    display: 'flex',
    alignItems: 'center',
    color: `${theme.textfield.text} !important`,
    backgroundColor: `${theme.textfield.background} !important`
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
    textAlign: 'left',
    paddingLeft: '22px',
    paddingRight: '22px',
    height: '27px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: `${theme.textfield.background} !important`,
    color: `${theme.textfield.text} !important`,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }),
  menu: (provided) => ({
    ...provided,
    position: 'absolute',
    marginTop: '6px',
    zIndex: 5000,
    width: '100%',
    borderRadius: '3px',
    color: `${theme.textfield.text} !important`,
    background: `${theme.textfield.background} !important`,
    boxShadow: theme.boxShadow.modal,
    boxSizing: 'border-box'
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '250px',
    overflowY: 'auto',
    borderRadius: '30px',
    paddingTop: '10px',
    paddingBottom: '10px',
    color: `${theme.textfield.text} !important`,
    background: `${theme.textfield.background} !important`
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return {
      ...provided, opacity, transition, color: `${theme.textfield.text} !important`, width: 'fit-content', height: '19px', fontSize: '12px', fontWeight: 400, position: 'relative'
    };
  }
});

export const CustomInputStyle = (theme) => ({
  control: (provided, { isDisabled }) => ({
    display: 'flex',
    minWidth: '234px',
    height: '27px',
    minHeight: '27px',
    borderRadius: '1px',
    bboxShadow: theme.boxShadow.modal,
    backgroundColor: isDisabled ? '#edf2f7' : theme.textfield.background,
    border: 'solid 1px #fafbfc',
    borderBottomWidth: '0px',
    color: `${theme.textfield.text} !important`,
    paddingLeft: '10px',
    fontWeight: '700',
    textOverflow: 'ellipsis'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  placeholder: (provided, { isDisabled }) => ({
    ...provided,
    fontSize: '13px',
    color: `${theme.textfield.text} !important`,
    opacity: isDisabled ? 0.5 : 0.7,
    fontWeight: 300
  }),
  input: (provided) => ({
    ...provided,
    fontSize: '13px',
    color: `${theme.textfield.text} !important`,
    fontWeight: 300
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: `${theme.textfield.text} !important`,
    width: '30px'
  }),
  option: (provided, { isFocused, data }) => ({
    ...provided,
    fontSize: '14px',
    textAlign: 'left',
    color: `${theme.textfield.text} !important`,
    cursor: isFocused ? 'pointer' : 'default',
    paddingLeft: '22px',
    paddingRight: '22px',
    height: '36px',
    textTransform: 'none',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }),
  valueContainer: () => ({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginRight: 'auto',
    color: `${theme.textfield.text} !important`
  }),
  menu: () => ({
    position: 'absolute',
    marginTop: '6px',
    zIndex: 5000,
    width: '100%',
    border: 'none',
    borderRadius: '3px',
    backgroundColor: theme.textfield.background,
    boxShadow: theme.boxShadow.modal,
    minWidth: '190px',
    color: `${theme.textfield.text} !important`
  }),
  menuList: () => ({
    maxHeight: '250px',
    overflowX: 'auto',
    borderRadius: '3px',
    paddingTop: '17px',
    paddingBottom: '13px',
    color: `${theme.textfield.text} !important`
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return {
      ...provided, opacity, transition, color: `${theme.textfield.text} !important`, width: 'fit-content', height: '19px', fontSize: '14px', fontWeight: 400, top: '8px', position: 'relative'
    };
  },
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: state?.data?.required ? '#bec8d2' : theme.textfield.background,
    color: `${theme.textfield.text} !important`,
    fontSize: '11.5px',
    fontWeight: 500
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    fontWeight: state.data.required ? 'bold' : 'normal',
    color: state.data.required ? 'white' : `${theme.textfield.text} !important`,
    paddingRight: 6
  }),
  multiValueRemove: (provided, state) => (state.data.required ? { ...provided, display: 'none' } : provided)
});

export const More = styled(MoreIcon)`
    path {
      fill: ${colors.grey17};
    }
`;
More.displayName = 'More';
