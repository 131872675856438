import styled from 'styled-components';
import { FiTrash2 as Trash } from 'react-icons/fi';
import { ellipsisText } from 'Styles/styles';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;
Wrapper.displayName = 'Wrapper';

export const DataContainer = styled.div`
    width: ${(props) => (props.responsive ? '100%' : '400px')};      
    background: ${({ showGradient, theme }) =>
        showGradient
            ? `linear-gradient(
                to bottom,
                ${theme.backgrounds.modal} 0%,       
                ${theme.backgrounds.modal} 33.33%,  
                ${theme.backgrounds.modal4} 33.33%,   
                ${theme.backgrounds.modal4} 66.66%,   
                ${theme.backgrounds.modal} 66.66%,  
                ${theme.backgrounds.modal} 100%
            )`
            : theme.backgrounds.modal};
    box-shadow: 0 2px 50px 0 ${({ theme }) => theme.textfield.background};
    height: 110px;
    overflow-y: auto;
`;
DataContainer.displayName = 'DataContainer';

export const DataItem = styled.div`    
    font-size: 13px;
    color: ${({ theme }) => theme.text.modalTitle2};
    cursor: pointer;
    padding: 7px 5px 7px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: ${(props) => {
    if (props.responsive) { return '100%;'; }
    if (props.width) { return `${props.width};`; }
    return '400px';
  }};      
  background-color: ${(props) => (props.isOdd ? props.theme.backgrounds.modal4 : props.theme.backgrounds.modal)};
  &:hover {
      background-color: ${({ theme }) => theme.textfield.background};
    }
  &::placeholder {
    color: ${({ theme }) => theme.text.placeholder} !important;
    font-size: 13.5px;
  }

`;
DataItem.displayName = 'DataItem';

export const Text = styled.div`
  text-align: left;
  ${ellipsisText}
`;
Text.displayName = 'Text';

export const Span = styled.span`
  position: ${(props) => (props.responsive ? 'absolute' : 'relative')};
  top: ${(props) => {
    if (props.responsive) {
      return props.hasTitle ? '39px' : '9px';
    }
    return '';
  }};
  right: ${(props) => (props.responsive ? '15px' : '')};
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;
Span.displayName = 'Span';

export const TrashIcon = styled(Trash)` 
    width: 16px;
    height: 17px;
    align-self: flex-end;
    g > path { 
      fill: ${colors.blueGrey};
    }
`;

TrashIcon.displayName = 'TrashIcon';

export const Input = styled.input`
  height: ${(props) => (props.height)};
  background-color: ${({ theme }) => theme.textfield.background} !important;
  box-shadow: none;
  color: ${({ theme }) => theme.textfield.text} !important;
  box-shadow: ${({ theme }) => theme.shadows.inputs};
  &:focus {
    background-color: ${({ theme }) => theme.textfield.focus};
  }
  &:hover{
    border-style: solid;
    border-color: #afb1bc;
    border-width: 1px 1px 1px 1px;
  }
  &::placeholder {
    color: ${({ theme }) => theme.text.placeholder} !important;
    font-size: 13.5px;
  }
`;

export const Title = styled.div`
    font-size: 12.5px;
    font-weight: 500;
    line-height: 2.08;
    color: ${({ theme }) => theme.text.modalLabel};
    font-weight: 600;
    margin-bottom: 12px;
    ${({ required }) => required && `
        position: relative;
        &:after {
        content: '*';
        color: ${colors.redPink};
        position: absolute;
        top: 3px;
        margin-left: 3px;
        }
    `}  
`;
Title.displayName = 'Title';
