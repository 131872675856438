import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  display:flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,0.5);
  `;
Wrapper.displayName = 'Wrapper';

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 500px;
  padding: 27px 33px;
  box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.24);
  border-radius: 2px;
  border-top: 10px solid ${colors.softBlue};
  background-color: ${({ theme }) => theme.backgrounds.modal};
`;
ModalContent.displayName = 'ModalContent';

export const EditorWrapper = styled.div`
  flex: 1 1 0px;
`;
EditorWrapper.displayName = 'EditorWrapper';
