/* eslint-disable no-unused-vars */
import React from 'react';
import IssueType from '../IssueType';

const RequeridFields = ({ options, issueType, handleIssueTypeChange, issueFieldData, users }) => {
  const issueFieldDataRequired = issueFieldData.filter((e) => e.required);

  return (
    <>
      {!!options.length && issueFieldDataRequired.map(({
        schema, name, options, key, ...data
      }) => (
      <IssueType
        required
        onChange={ (e, action) => handleIssueTypeChange(e, key, action) }
        type={ schema.type }
        title={ name }
        options={ options }
        key={ `${issueType}-${name}` }
        issueType={ issueType }
        users={ users }
        { ...data }
      />
      ))}
    </>
  );
};

export default RequeridFields;
