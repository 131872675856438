import styled from 'styled-components';
import colors from 'Styles/colors';
import { SubMenu } from 'react-contextmenu';
import { ReactComponent as ArchiveImage } from 'Images/archive.svg';
import { ReactComponent as LockImage } from 'Images/ws-lock.svg';
import { ReactComponent as UnlockImage } from 'Images/ws-unlock.svg';

export const Wrapper = styled.div`
    .react-contextmenu {
      width: 269px;
      box-shadow: 2px 2px 10px 0 ${({ theme }) => theme.borders.actionDropDown};
      border-radius: 2px;
      box-shadow: 2px 2px 30px 0 ${({ theme }) => theme.shadows.actionDropDown};
      border: solid 1px ${({ theme }) => theme.borders.importanceMeter};
      background-color: ${({ theme }) => theme.backgrounds.actionDropDown};
      & > :first-child:hover {
        background-color: ${({ theme }) => theme.backgrounds.actionDropDownHover}
      }
    }
    .react-contextmenu-item {
      &:hover {
        background-color: ${({ theme }) => theme.backgrounds.actionDropDownHover};
      }
    }
    .react-contextmenu-submenu {
      &:hover {
        background-color: ${({ theme }) => theme.backgrounds.actionDropDownHover}
      }
    }
`;
Wrapper.displayName = 'Wrapper';

export const MenuItemContent = styled.div`
`;
MenuItemContent.displayName = 'MenuItemContent';

export const Text = styled.div`
  display: inline-block;
  font-size: 13.5px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  color: ${({ theme }) => theme.icons.actionBar.color};
  margin-left: 5px;
`;
Text.displayName = 'Text';

export const Icon = styled.img`
  width: 18px;
  height: 17px;
  display: inline-block;
  margin-right: 13px;
`;
Icon.displayName = 'Icon';

export const IconActiveInactive = styled(Icon)`
  margin-bottom: 2px;
`;
IconActiveInactive.displayName = 'IconActiveInactive';

export const IconEdit = styled(LockImage)`
  width: 18px;
  height: 17px;
  display: inline-block;
  margin-right: 13px;
  margin-bottom: 2px;
  g > path {
    fill: ${({ theme }) => theme.icons.actionBar.color};
  }

`;
IconEdit.displayName = 'IconEdit';

export const ArrowIcon = styled.div`
  width: 10px;
  height: 6px;
  margin-right: 34px;
  margin-top: 3px;
  display: inline-block;
  float: right;
  color: ${({ theme }) => theme.text.actionDropDownItem};
`;
ArrowIcon.displayName = 'ArrowIcon';

export const SubMenuStatus = styled(SubMenu)`
    width: 118px !important;
    height: 85px !important;    
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

SubMenuStatus.displayName = 'SubMenuStatus';

export const StatusText = styled.div`
  color: ${colors.grey19};
  padding-left: 13px;
  font-weight: 300;
  font-size: 13px;
  display: inline-block;
`;
StatusText.displayName = 'StatusText';

export const LockIcon = styled(LockImage)`
  margin-left: 1px;
  g, path {
    fill: ${({ theme }) => theme.icons.actionBar.color};
  }
`;
LockIcon.displayName = 'LockIcon';

export const ArchiveIcon = styled(ArchiveImage)`
  width: 18px;
  height: 17px;
  display: inline-block;
  /* margin-right: 13px; */
  path, rect {
    stroke: ${({ theme }) => theme.icons.actionBar.color};
  }
`;
ArchiveIcon.displayName = 'ArchiveIcon';

export const UnlockIcon = styled(UnlockImage)`
  g, path {
    fill: ${({ theme }) => theme.icons.actionBar.color};
  }
`;
UnlockIcon.displayName = 'UnlockIcon';
