import styled from 'styled-components';
import { slideInRight } from 'Styles/effects';

const Text = styled.div`
  display: flex;
  padding-left: 58px;
  font-size: 15px;
  color: ${({ theme }) => theme.text.sidebarSubMenu};
  height: 42px;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${slideInRight}

    &:hover {
    background-color: ${({ theme }) => theme.backgrounds.sidebarSubMenuItemHover};
  }
  
  ${({ selected, theme }) => selected &&
  `
      background-color: ${theme.backgrounds.sidebarSubMenuItemHover};
      font-weight: 600;
  `}
`;

export default Text;
