import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  border: 1px solid black;
  background-color: ${({ theme }) => theme.backgrounds.modal}; 
  width: 190px;
  height: 240px;
  padding: 12px 20px;
  border-radius: 2px;
  border: solid 1px ${({ theme }) => theme.borders.workspaceStats};
`;
Wrapper.displayName = 'Wrapper';

export const StringDate = styled.p`
  font-size: 17px;
  font-weight: 500;
  color: #63758d;
  padding-bottom: 10px;
`;
StringDate.displayName = 'StringDate';

export const VulnsWrapper = styled.div`
  display: flex;
  margin-bottom: 5px;
  margin: 10px 0 5px 0;
`;
VulnsWrapper.displayName = 'VulnsWrapper';

export const VulnType = styled.span`
  font-size: 16px;
  color: ${(props) => props.color};
  width: 48px;
  margin-right: 60px;
`;
VulnType.displayName = 'VulnType';

export const VulnCount = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: ${colors.grey19};

`;
VulnCount.displayName = 'VulnCount';

export const Separator = styled.hr`
  height: 0;
  background-color: #edf2f7;
  margin: 12px 5px 8px 5px;
`;
Separator.displayName = 'Separator';

export const Title = styled.span`
  font-size: 15px;
  color: ${colors.grey19};
  width: 48px;
`;
Title.displayName = 'Title';
