import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
Wrapper.displayName = 'Wrapper';

export const Button = styled.div`
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-word;
  cursor: pointer;
  display: flex;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  font-size: 11.5px;
  font-weight: 500;
  line-height: 34px;
  color: ${({ theme }) => theme.buttons.custom.color};
  height: 34px;
  width: 155px;
  border-radius: 2px;
  border: solid 1px ${({ theme }) => theme.buttons.custom.border};
  background-color: ${({ theme }) => theme.buttons.custom.background};
  & > svg {
    height: 11px;
    margin-right: 10px;
     & > path {
      fill: ${({ theme }) => theme.buttons.custom.color};
    }
  }
  &:hover {
    background-color: ${({ theme }) => theme.buttons.custom.background};
  }

  &:active {
    background-color: ${({ theme }) => theme.buttons.custom.background};
  }
  
`;
