import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 7px;
`;
Wrapper.displayName = 'Wrapper';

export const Label = styled.div`
  flex: 0 0 85px;
  font-size: 11.5px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${({ theme }) => theme.text.modalDescription};
`;
Label.displayName = 'Label';

export const Content = styled(Label)`
  flex: 1 1 0;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: normal;
  margin-left: 4px;
`;
Content.displayName = 'Content';
