import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { selectAllowedByFeature } from 'Hooks/usePermission/selector';
import { MODAL_UPGRADE_LICENSE } from 'store/modals/modals';
import upgradeLicenseImage from 'Images/ticketing-license.png';
import {
  FEATURE_TICKETING_GITLAB, FEATURE_TICKETING_JIRA, FEATURE_TICKETING_SERVICENOW, FEATURE_TICKETING_WHD, PERMISSION_TYPES
} from 'Hooks/usePermission/permissions';
import { ACTIONS } from 'tracking/GA/constants';
import Header from '../../../Header';
import { Wrapper, Title, Tools, JiraLogo } from './styled';
import { fetchActiveConfig, getTemplates } from 'store/Settings/settingsActions';
import UpgradeLicense from '../UpgradeLicense';
import StatusTag from './components/StatusTag';
import Content from './components/Content';
import { getJiraSettings, setField, setIsTemplate, TOOL_NAME } from 'store/Settings/jiraActions';
import { selectAuthenticated, selectError, selectMessage, selectSuccess } from 'store/Settings/jiraSelectors';
import ConfigureIssueModal from './components/ConfigureIssueModal';
import Error from '../Error';
import Success from '../Success';
import { GreenCheckbox } from './components/Oauth/styled';

const Jira = ({ onClose }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [disabled, setDisabled] = useState(false);

  const isEnabledJira = useSelector((state) => selectAllowedByFeature(state, FEATURE_TICKETING_JIRA, PERMISSION_TYPES.CREATE));
  const isEnabledGitlab = useSelector((state) => selectAllowedByFeature(state, FEATURE_TICKETING_GITLAB, PERMISSION_TYPES.CREATE));
  const isEnabledServiceNow = useSelector((state) => selectAllowedByFeature(state, FEATURE_TICKETING_SERVICENOW, PERMISSION_TYPES.CREATE));
  const isEnabledWhd = useSelector((state) => selectAllowedByFeature(state, FEATURE_TICKETING_WHD, PERMISSION_TYPES.CREATE));

  const error = useSelector(selectError);
  const success = useSelector(selectSuccess);
  const message = useSelector(selectMessage);

  const onCloseError = () => {
    dispatch(setField('error', false));
    dispatch(setField('message', ''));
  };

  const onCloseSuccess = () => {
    dispatch(setField('success', false));
    dispatch(setField('message', ''));
  };

  useEffect(() => {
    dispatch(fetchActiveConfig());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getTemplates(TOOL_NAME));
    dispatch(getJiraSettings());
    setDisabled(false);
  }, [dispatch, setDisabled]);

  useEffect(() => {
    dispatch(setIsTemplate(true));
    return () => {
      dispatch(setIsTemplate(false));
    };
  }, [dispatch]);

  const featureEnabled = () => isEnabledJira || isEnabledGitlab || isEnabledServiceNow || isEnabledWhd;
  const authenticated = useSelector(selectAuthenticated);

  return (
    <Wrapper>
      <Header onAccept={ onClose } onClose={ onClose } disabled={ disabled } />
      { featureEnabled()
        ? (
            <>
              <Title>{ intl.formatMessage({ id: 'preferences.ticketingTools.title' }) }</Title>
              <Tools>
                <JiraLogo />
                <StatusTag text={ 'Active' } />
                <GreenCheckbox checked={ authenticated } onChange={ null } />
              </Tools>
              <Content />
              <ConfigureIssueModal />
              { error && message && <Error message={ message } close={ onCloseError } /> }
              { success && message && <Success message={ message } close={ onCloseSuccess } /> }
            </ >
          )
        : <UpgradeLicense id={ MODAL_UPGRADE_LICENSE } image={ upgradeLicenseImage } descriptionIntlId="upgradeLicense.ticketing.description" trackingShow={ ACTIONS.showSettings } trackingClick={ ACTIONS.clickSettings } />}
    </Wrapper>
  );
};

export default Jira;
