import styled from 'styled-components';
import { ReactComponent as GridViewImage } from 'Images/grid_view.svg';
import { disableTextSelection } from 'Styles/effects/text';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  ${disableTextSelection}
  position: absolute;
  right: 28px;
  top: 5px;
`;
Wrapper.displayName = 'Wrapper';

export const GridViewIcon = styled(GridViewImage)`
  height: auto;
  & g > path{
    fill: ${({ theme }) => theme.icons.actionBar.color};
  }
`;
GridViewImage.displayName = 'GridViewImage';
