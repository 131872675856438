import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as NotificationBell } from 'Images/icon-notifications-sidebar.svg';

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
`;
Wrapper.displayName = 'Wrapper';

export const Tabs = styled.div`
  background: ${({ theme }) => theme.backgrounds.preferencesSidebar};
  flex: 0 0 184px;
  display: inline-block;
  height: 100%;
`;
Tabs.displayName = 'Tabs';

export const TabGroup = styled.div`
`;
TabGroup.displayName = 'TabGroup';

export const Subtitle = styled.div`
  margin: 20px 0 10px 0;
  font-size: 11.5px;
  font-weight: normal;
  line-height: 1.22;
  text-align: left;
  color: ${({ theme }) => theme.text.preferencesMenuHeader};
  padding-left: ${({ hasIcon }) => hasIcon ? '9px' : '3px'};
`;
Subtitle.displayName = 'Subtitle';

export const Title = styled.div`
  font-size: 21.5px;
  font-weight: 500;
  line-height: 1.16;
  text-align: left;
  color: ${colors.softBlue};
  padding: 40px 20px 0px 20px;
`;
Title.displayName = 'Title';

export const Section = styled.div`
  display: flex;
  align-items: baseline;
  padding-left: 16px; 
  gap: 9px; 
  margin: 12px 0px;
`;
Section.displayName = 'Section';

export const Item = styled.div`
  display:flex;
  align-items: center;
  height: 28px;
  gap: 3px;
  cursor: pointer;
  font-size: 13.5px;
  font-weight: 400;
  color: ${(props) => (props.active ? props.theme.text.preferencesMenuItemHover : props.theme.text.preferencesMenuItem)};
  line-height: 1.04;
  text-align: left;
  padding: ${({ hasIcon }) => hasIcon ? '9px 0 10px 20px' : '10px 0px 10px 47px'};
  background: ${(props) => (props.active ? props.theme.backgrounds.preferencesItemHover : 'transparent')};
  
  & svg {
    margin-right: 8px;
    g, path {
      fill: ${({ theme }) => theme.text.preferencesMenuHeader};
    }
  }

  `;
Item.displayName = 'Item';

export const TabContent = styled.div`
  padding: 35px 68px 35px 35px;
  flex: 1 1 0;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.backgrounds.preferencesContent};
`;
TabContent.displayName = 'TabContent';

export const StyledNotificationBell = styled(NotificationBell)`
  width: 15px;

  g {
    stroke: #63758D;
  }
  path {
    stroke: #63758D;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: baseline;
  padding-left: 20px;
`;
Header.displayName = 'Header';

export const IconWrapper = styled.div`
  width: 18px;
  & svg {
    g, path {
      fill: ${({ theme }) => theme.text.preferencesMenuHeader};
    }
  }
`;
IconWrapper.displayName = 'IconWrapper';

export const Content = styled.div`
  height: calc(100% - 75px);
  overflow-y: auto;
`;
Content.displayName = 'Content';
