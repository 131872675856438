import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 14px;
  .checkbox-container {
    display: inline;
    margin-bottom: 0px;
  }
`;
Wrapper.displayName = 'Wrapper';

export const Text = styled.div`
  color: ${({ theme }) => theme.text.actionBar};
  font-size: 11.5px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  display: inline;
`;
Text.displayName = 'Text';
