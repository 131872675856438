import styled from 'styled-components';
import colors from 'Styles/colors';
import { ellipsisText } from 'Styles/styles';

export const Wrapper = styled.div`
  width: ${(props) => (props.fullWidth ? '100%' : '406px')};
  max-height: ${({ height }) => height || '350px'};
  border-radius: 2px;
  box-shadow: 2px 2px 30px 0 rgba(0, 0, 0, 0.14);
  background-color: ${({ theme }) => theme.selector.background};
  overflow-y: auto;
  padding: 18px 0;
  position: absolute;
  top: 100%;
  z-index: 10;
  ${({ customStyles }) => customStyles && `
    width: 100%;
    height: 100%;
    max-height: 100px;
    overflow: auto;
    position: relative;
    box-shadow: none;
    padding: 0;
    top: 5px;
  `}
`;
Wrapper.displayName = 'Wrapper';

export const DataItem = styled.div`
  font-size: ${(props) => (`${props.customStyles ? '14.5px' : '13px'}`)};
  color: ${({ theme }) => theme.selector.text} !important;
  cursor: pointer;
  padding: ${(props) => (`${props.customStyles ? '10px' : '7px 5px 7px 20px'}`)};
  border-radius: 3px;
  user-select: none;
  background-color: ${(props) => (`${props.isActive ? props.theme.selector.background : ''}`)};
  font-weight: ${(props) => (`${props.isActive ? 600 : 400}`)};
`;
DataItem.displayName = 'DataItem';

export const DataItemWrapper = styled.div`
  display: flex;
  width: 100%;
`;
DataItemWrapper.displayName = 'DataItemWrapper';

export const Text = styled.p`
  text-align: left;
  font-size: 13.5px;
  margin-right: auto;
  width: 90px;
  ${ellipsisText}
`;
Text.displayName = 'Text';

export const Dash = styled(Text)`
  width: max-content;
`;
Dash.displayName = 'Dash';

export const GroupName = styled(DataItemWrapper)`
  background-color: ${colors.light2};
`;
GroupName.displayName = 'GroupName';
