import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useClickOutside from 'Hooks/useClickOutside';
import { selectSelectedSection, selectScheduledAgentsCount, selectScheduledCloudAgentsCount } from 'store/Schedule/selectors';
import SECTIONS from './Sections';
import {
  Wrapper, Toggle, IconWrapper, Icon, Title,
  ArrowDown, Dropdown, Item, SectionName, Count,
  TitleWrapper
} from './styled';
import { getCountSchedulers, setSection } from 'store/Schedule/actions';

const SectionSelector = () => {
  const dispatch = useDispatch();
  const [showDropdown, setShowDropdown] = useState(false);
  const selectedSection = useSelector(selectSelectedSection);
  const dropdownRef = useRef();
  const scheduledAgentsCount = useSelector(selectScheduledAgentsCount);
  const scheduledCloudAgentsCount = useSelector(selectScheduledCloudAgentsCount);
  const title = SECTIONS.find((e) => e.value === selectedSection)?.label;
  const getTotal = (section) => section === 'scheduledAgents' ? scheduledAgentsCount : scheduledCloudAgentsCount;

  useEffect(() => {
    dispatch(getCountSchedulers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useClickOutside(dropdownRef, () => setShowDropdown(false));

  const handleToggle = () => setShowDropdown(!showDropdown);

  const onClickSection = (section) => {
    dispatch(setSection(section));
    handleToggle();
  };

  return (
    <Wrapper ref={ dropdownRef }>
      <Toggle onClick={ handleToggle }>
        <IconWrapper><Icon /></IconWrapper>
        <TitleWrapper>
          <Title>{ `${title} (${getTotal(selectedSection)})` }</Title>
          <ArrowDown />
        </TitleWrapper>
      </Toggle>
      { showDropdown
        ? <Dropdown>
            {SECTIONS.map(section => (
              <Item onClick={ () => onClickSection(section.value) } key={ section.value }>
                <SectionName>{ section.label }</SectionName>
                <Count>{ getTotal(section.value) }</Count>
              </Item>
            ))}
          </Dropdown>
        : null
      }
    </Wrapper>
  );
};

export default SectionSelector;
