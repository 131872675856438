import styled from 'styled-components';
import { ReactComponent as GridViewImage } from 'Images/grid_view.svg';
import { disableTextSelection } from 'Styles/effects/text';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  ${disableTextSelection}
`;
Wrapper.displayName = 'Wrapper';

export const GridViewIcon = styled(GridViewImage)`
  height: auto;
  & > g{
    fill: ${({ theme }) => theme.buttons.custom.color};
  }
`;
GridViewImage.displayName = 'GridViewImage';
