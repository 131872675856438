import styled from 'styled-components';
import { ReactComponent as Icon } from 'Images/icon-action-bar-trash.svg';

export const CFContainer = styled.div`
display: flex;
flex-direction: row;
height: 32px;
padding-left: 15px;
padding-right: 13px;

&:hover {
  background-color: ${({ theme }) => theme.backgrounds.selectedTableItem};
  
  >:nth-child(2){
    display: block;
  }
}
`;

export const CFLabel = styled.div`
  cursor: pointer;
  flex: 1 1 0;
  font-size: 14px;
  line-height: 2.51;
  color: ${({ theme }) => theme.textfield.text};
  text-align: left;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const RemoveIcon = styled(Icon)`
  display: none;
  margin: auto 0px auto auto;
  width: 17px;
  height: 17px;
  path {
      fill: ${({ theme }) => theme.icons.actionBar.color};
  }
`;
RemoveIcon.displayName = 'RemoveIcon';
