import styled from 'styled-components';
import { slideInLeft, fadeIn } from 'Styles/effects';

export const DetailWrapper = styled.div`
  display: flex;
  flex: 1 1 0px;
  flex-direction: column;
  padding: 20px 0 0 4px;
  background-color: ${({ theme }) => theme.backgrounds.detailModal};
  height: 100%;
  width: 97%;
  position: relative;
`;
DetailWrapper.displayName = 'DetailWrapper';

export const Wrapper = styled.div.attrs(({ wrapperWidth }) => {
  let width = '55%';
  if (wrapperWidth) width = `${wrapperWidth}px`;
  return ({ style: { width } });
})`
  display: flex;
  flex-direction: row;
  overflow-y: hidden;
  overflow-x: hidden;
  position: absolute;
  right: 0;
  z-index: 1;
  box-shadow: -5px 0 12px 0 rgba(35, 35, 35, 0.11);
  height: 100%;
  ${slideInLeft};
`;
Wrapper.displayName = 'Wrapper';

export const Resizer = styled.div`
    height: 100%;
    border-radius: 4px;
    width: 8px;
    margin-right: auto;
    background-color: ${({ theme }) => theme.backgrounds.detailModal};
`;
Resizer.displayName = 'Resizer';

export const ResizerWrapper = styled.div`
  display: flex;
  height: 100%;
  cursor: col-resize;
  flex: 0 0 25px;
  background-color: ${({ theme }) => theme.backgrounds.detailModal};
  &:hover{
      & > * {
          background-color: ${({ theme }) => theme.backgrounds.detailModal};
      }
  }
  &:active{
      & > * {
          background-color: ${({ theme }) => theme.backgrounds.detailModal};
      }
  }
`;
ResizerWrapper.displayName = 'ResizerWrapper';

export const Transparency = styled.div`
  position: absolute;
  z-index: 1;
  top: 98px;
  left: 0px;
  width: 100%;
  height: 70px;
  background-image: linear-gradient(to bottom, ${({ theme }) => theme.backgrounds.detailModalTransparency}, rgba(216, 216, 216, 0));
  ${fadeIn(0, 1)};
`;
Transparency.displayName = 'Transparency ';

export const Content = styled.div`
  overflow-y: auto;
  padding-bottom: 20px;
  & > * {
    margin-bottom: 20px;
  }
  & > :last-child {
    margin-bottom: 0;
  }
`;
Content.displayName = 'Content';
