import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { setField } from 'store/Settings/solarWindsActions';
import { selectUrl, selectApiKey } from 'store/Settings/solarWindsSelectors';
import {
  Url, ApiKey, Wrapper, Content
} from './styled';

const Settings = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const url = useSelector(selectUrl);
  const apiKey = useSelector(selectApiKey);

  const change = (field, value) => {
    dispatch(setField(field, value));
  };

  return (
    <Wrapper>
      <Content>
        <Url title={ intl.formatMessage({ id: 'preferences.ticketingTools.solarWinds.settings.url.title' }) } placeholder={ intl.formatMessage({ id: 'preferences.ticketingTools.solarWinds.settings.url.placeholder' }) } onChange={ (v) => { change('url', v); } } value={ url } defaultValue={ url } autocomplete="url" id="url" name="url" mandatory />
        <ApiKey title={ intl.formatMessage({ id: 'preferences.ticketingTools.solarWinds.settings.apiKey.title' }) } placeholder={ intl.formatMessage({ id: 'preferences.ticketingTools.solarWinds.settings.apiKey.placeholder' }) } onChange={ (v) => { change('apiKey', v); } } value={ apiKey } defaultValue={ apiKey } type="password" autocomplete="one-time-code" id="accessToken" name="accessToken" mandatory />
      </Content>
    </Wrapper>
  );
};
export default Settings;
