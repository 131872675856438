import styled from 'styled-components';
import colors from 'Styles/colors';
import {
  Input as Input_, Title as Title_
} from 'Common/Components/StandardTextField/styled';
import { ReactComponent as ShowHide } from 'Images/showHide.svg';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  height: 85px;
`;
Wrapper.displayName = 'Wrapper';

export const Input = styled(Input_)`
  position: relative;
  &::placeholder,
  &::-webkit-input-placeholder {
    font-size: 14.5px;
    color: ${colors.grey12};
  }
`;

Input.displayName = 'Input';

export const Title = styled(Title_)`
  text-align: left;
  font-size: 12.5px;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.08;
  letter-spacing: normal;
  font-weight: 400;
  margin-bottom: 1px;
  color: ${(props) => (props.boldTitle ? colors.darkBlueGrey : colors.grey17)};
  padding-left: ${(props) => (props.boldTitle ? '15px' : '')};
  margin-right: 3px;
`;
Title.displayName = 'Title';

export const Toggle = styled(ShowHide)`
  position: absolute;
  top: 35px;
  right: 7px;
`;
Toggle.displayName = 'Toggle';
