import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as CloseSVG } from 'Images/icon-close-without-background.svg';
import { ReactComponent as ArrowExpandedSVG } from 'Images/wf_arrow_expand.svg';
import { ReactComponent as ArrowCollapsedSVG } from 'Images/wf_arrow_collapsed.svg';
import { ReactComponent as QueueIcon } from 'Images/queue.svg';

export const Wrapper = styled.div`
  ${({ isShowingBody }) => !isShowingBody && 'border-radius: 5px'};
  ${({ isShowingBody }) => isShowingBody && 'border-top-left-radius: 5px'};
  ${({ isShowingBody }) => isShowingBody && 'border-top-right-radius: 5px'};
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: ${({ theme }) => theme.backgrounds.queueHeader};
  height: 45px;
  padding-left: 11px;
  padding-right: 25px;
  & > *:not(:last-child) {
    margin-right: 5px;
  }
`;
Wrapper.displayName = 'Wrapper';

export const HeaderMessage = styled.div`
  flex: 1 1 0px;
  color: white;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
  margin-left: 5px;
`;
HeaderMessage.displayName = 'HeaderMessage';

export const Close = styled(CloseSVG)`
  flex: 0 0 30px;
  height: 30px;
  cursor: pointer;
  margin: auto 0px auto auto;
  
  &:hover {
    background-color: ${colors.grey26} !important;
    border-radius: 50%;
  }
  path {
    fill: ${({ theme }) => theme.icons.processingQueue.color};
  }
`;
Close.displayName = 'Close';

export const ArrowExpanded = styled(ArrowExpandedSVG)`
  padding: 5px;
  flex: 0 0 30px;
  height: 30px;
  cursor: pointer;
  margin: auto 0px auto auto;
  
  &:hover {
    background-color: ${colors.grey26} !important;
    border-radius: 50%;
  }
  path {
    fill: ${({ theme }) => theme.icons.processingQueue.color};
  }
`;
ArrowExpanded.displayName = 'ArrowExpanded';

export const ArrowCollapsed = styled(ArrowCollapsedSVG)`
  padding: 5px;
  flex: 0 0 30px;
  height: 30px;
  cursor: pointer;
  margin: auto 0px auto auto;
  
  &:hover {
    background-color: ${colors.grey26} !important;
    border-radius: 50%;
  }
  path {
    fill: ${({ theme }) => theme.icons.processingQueue.color};
  }
`;
ArrowCollapsed.displayName = 'ArrowCollapsed';

export const Queue = styled(QueueIcon)`
  cursor: default;
  
  g > g {
    stroke: ${({ theme }) => theme.icons.processingQueue.color};
  }
`;
Queue.displayName = 'Queue';
