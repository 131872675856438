import styled from 'styled-components';

export const Tag = styled.div`
    background-color: ${(props) => (props.selected ? props.theme.backgrounds.reportTagSelected : props.theme.backgrounds.reportTag)};
    box-shadow: ${(props) => (props.selected ? 'inset 0 1px 0 0 #182d49' : 'inset 0 -1px 0 0 #acbdd2')};
    color: ${(props) => (props.selected ? props.theme.text.reportTagSelected : props.theme.text.reportTag)};
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
    flex: 0 0 calc(25% - 8px);
    font-size: 11.5px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    height: 24px;
    letter-spacing: 0.09px;
    line-height: 2.09;
    width: 73px;
    :hover{
        background-color: ${(props) => (!props.disabled && !props.selected && '#dce2eb')};
    }
`;
Tag.displayName = 'Tag';

export const Label = styled.div`
    margin: auto;
    width: 80%;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    user-select: none;
    white-space: nowrap;
`;
Label.displayName = 'Label';
