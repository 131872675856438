import styled from 'styled-components';
import colors from 'Styles/colors';

export const GreyButton = styled.button`
  width: 89px;
  height: 34px;
  margin: auto 0px auto auto;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.backgrounds.modal};
  color: ${({ theme }) => theme.text.modalTitle};
  border: solid 1px ${colors.dark6};
  font-size: 14.5px;
  line-height: 2.14;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  :hover {
    background-color: ${({ theme }) => theme.buttons.custom.background};
  }
  :active {
    background-color: ${({ theme }) => theme.buttons.custom.background};
  }
`;
GreyButton.displayName = 'GreyButton';

export const BlueButton = styled.button`
  width: 89px;
  height: 34px;
  margin: auto 12px auto 16px;
  border-radius: 3px;
  border: none;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${({ theme, disabled }) => disabled ? `${theme.buttons.disabledSave.color}` : colors.white};
  background-color: ${({ disabled, theme }) => (disabled ? `${theme.buttons.disabledSave.background}` : `${theme.buttons.primary.background}`)};
  :hover {
    background-color: ${({ disabled, theme }) => (disabled ? `${theme.buttons.disabledSave.background}` : `${theme.buttons.primary.background}`)};
  }
  :active {
    background-color: ${({ disabled, theme }) => (disabled ? `${theme.buttons.disabledSave.background}` : `${theme.buttons.primary.background}`)};
  }
  `;
BlueButton.displayName = 'BlueButton';
