import React from 'react';
import { Wrapper } from './styled';
import Oauth from '../../../Oauth';

const ThirdRow = () => {
  return (
    <Wrapper>
      <Oauth />
    </Wrapper>
  );
};

export default ThirdRow;
