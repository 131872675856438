import React, { useState, useEffect } from 'react';
import CustomReactTable from 'Common/Components/Contextualization/CustomReactTable/styled';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import debounce from 'lodash/debounce';
import { withBreakpoints } from 'react-breakpoints';
import { selectGroupByField, selectFilters } from 'store/Filters/selectors';
import { selectCustomAttributes } from 'store/CustomAttributes/selectors';
import { selectVulnsGeneralFields } from 'store/Preferences/vulnsGeneral/selectors';
import { setFieldWidth } from 'store/Preferences/vulnsGeneral/actions';
import { PropTypes } from 'prop-types';
import getColumns from './components/Columns';
import { handleResizedChange, getThProps, getTrProps } from 'Screens/Contextualization/Manage/components/ManageTable/components/Table/Functions';

import {
  selectIsFetching, selectIsFetchingExpandedData, selectShowContextMenu, selectShowDetail,
  selectVulns, selectVulnsSelected
} from 'store/ManageGeneral/selectors';
import {
  expandGroupByVulns, selectVulnRow, setOrderByVulnsGeneral,
  setShowContextMenu, unSelectAllVulns
} from 'store/ManageGeneral/actions';
import SubTable from './components/SubTable';
import { selectRiskScoreFeatureFlag } from 'store/Faraday/selectors';
import useTheme from 'Hooks/UseTheme';
import { selectShowQuickPlanner } from 'store/Manage/selectors';

const Table = ({ currentBreakpoint }) => {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState([]);
  const groupByField = useSelector((state) => selectGroupByField('vulnsGeneral', state));
  const customFields = useSelector(selectCustomAttributes);
  const showVulnDetail = useSelector(selectShowDetail);
  const vulnsSelected = useSelector(selectVulnsSelected);
  const isFetching = useSelector(selectIsFetching);
  const isFetchingExpandedData = useSelector(selectIsFetchingExpandedData);
  const data = useSelector(selectVulns);
  const fields = useSelector(selectVulnsGeneralFields);
  const filters = useSelector((state) => selectFilters('vulnsGeneral', state));
  const isAllowedByLicense = useSelector(selectRiskScoreFeatureFlag);
  const showContextMenu = useSelector(selectShowContextMenu);
  const noDataText = isFetching ? '' : <FormattedMessage id="workspaces.table.noData" />;
  const showQuickPlanner = useSelector(selectShowQuickPlanner);
  const { themeStyles } = useTheme();
  const columns = getColumns(true, customFields, showVulnDetail, currentBreakpoint, groupByField, fields, isFetching, isFetchingExpandedData, false, isAllowedByLicense, showQuickPlanner, themeStyles);
  const subTableColumns = (hasGroupedData) => getColumns(false, customFields, showVulnDetail, currentBreakpoint, groupByField, fields, isFetching, isFetchingExpandedData, hasGroupedData, isAllowedByLicense, showQuickPlanner, themeStyles);
  const selectRowAction = (row) => dispatch(selectVulnRow({}, row));
  const unSelectAllAction = (row) => dispatch(unSelectAllVulns({}, row));
  const setFieldWidthAction = (field, value) => dispatch(setFieldWidth(field, value, 'manage_table'));
  const showContextMenuAction = (value, xPos, yPos) => dispatch(setShowContextMenu(value, xPos, yPos));

  const onExpandedChange = (expanded, index) => {
    if (expanded[index]) {
      const expandData = data[index];
      dispatch(expandGroupByVulns(index, expandData));
    }
    setExpanded(expanded);
  };

  const handleResizeColumn = (newResized) => !groupByField && handleResizedChange(newResized, fields, setFieldWidthAction);

  const fetchData = (tableState) => !isFetching && dispatch(setOrderByVulnsGeneral(tableState.sorted));

  useEffect(() => {
    setExpanded([]);
  }, [groupByField, filters]);

  const mockData = [...Array(15).keys()];

  return (
    <CustomReactTable
      data={ (!isFetching || groupByField) ? data : mockData }
      columns={ columns }
      minRows={ 0 }
      manual
      onFetchData={ fetchData }
      showPagination={ false }
      getTrProps={ (_, rowInfo) => getTrProps(rowInfo, vulnsSelected, selectRowAction, unSelectAllAction, showContextMenuAction, isFetching, themeStyles) }
      getTheadThProps={ (_, __, column) => getThProps(column, fields, currentBreakpoint, showVulnDetail, showContextMenuAction, showContextMenu) }
      onResizedChange={ debounce(handleResizeColumn, 200) }
      expanded={ expanded }
      onExpandedChange={ onExpandedChange }
      noDataText={ noDataText }
      SubComponent={ (row) => <SubTable row={ row } getColumns={ subTableColumns } /> }
    />
  );
};

Table.propTypes = {
  currentBreakpoint: PropTypes.string.isRequired
};

export default withBreakpoints(Table);
