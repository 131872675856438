import colors from 'Styles/colors';
import styled from 'styled-components';

export const Box = styled.div`
  border-radius: 3px;
  border: solid 1px ${({ theme }) => theme.borders.dashboard};
  box-shadow: 0 0 20px 0 ${({ theme }) => theme.shadows.dashboard};
  padding: 11px 22px 22px 22px;
  text-align: left;
  height: 100%;
  background: ${({ theme }) => theme.backgrounds.dashboardItem};
`;
Box.displayName = 'Box';

export const DarkBox = styled(Box)`
  background-color: ${colors.dark1};
  box-shadow: rgb(209 218 227 / 97%) 0px 0px;
`;
Box.displayName = 'DarkBox';
