/* eslint-disable react/no-children-prop */
import React from 'react';
import { useSelector } from 'react-redux';
import { selectInputField } from 'store/ExecutiveReportEditCreate/selectors';
import {
  Wrapper, Title, Enterprise, TextWrapper, GenericTextWrapper, FaradayWhiteLogo, FaradayLogo, StyledDate, Separator
} from './styled';
import getParsedDate from 'Screens/Contextualization/ExecutiveReportEditCreate/components/ReportPreview/components/ReportCovers/dateFunctions';

const TemplatePreview = ({ themeTemplate, selected, thumbnail }) => {
  const title = useSelector((state) => selectInputField(state, 'title'));
  const enterprise = useSelector((state) => selectInputField(state, 'enterprise'));
  const date = getParsedDate();

  const placeholder = {
    title: "Title. e.g.: 'Network XYZ'",
    enterprise: "Client Name. e.g. 'ACME INC'"
  };

  return (
    <Wrapper themeTemplate={ themeTemplate } selected={ selected }>
      { themeTemplate === 'generic' && <Separator /> }
      { themeTemplate === 'light' && <FaradayLogo /> }
      { themeTemplate === 'dark' && <FaradayWhiteLogo /> }
      { themeTemplate === 'generic'
        ? (
          <>
            <GenericTextWrapper thumbnail={ thumbnail } >
              <Title children={ title || placeholder.title } themeTemplate={ themeTemplate } thumbnail={ thumbnail } />
              <Enterprise children={ enterprise || placeholder.enterprise } themeTemplate={ themeTemplate } thumbnail={ thumbnail } />
            </GenericTextWrapper>
            <StyledDate children={ date } themeTemplate={ themeTemplate } />
          </>
          )
        : (
          <>
            <TextWrapper themeTemplate={ themeTemplate } thumbnail={ thumbnail }>
              <Title children={ title || placeholder.title } themeTemplate={ themeTemplate } thumbnail={ thumbnail } />
              <Enterprise children={ enterprise || placeholder.enterprise } themeTemplate={ themeTemplate } thumbnail={ thumbnail } />
            </TextWrapper>
            <StyledDate children={ date } themeTemplate={ themeTemplate } />
          </>
          )}
    </Wrapper>
  );
};

export default TemplatePreview;
