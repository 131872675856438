import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Wrapper = styled.div`
  margin: 0 0 13px;
  padding: 21px 8px 21px 21px;
  border-radius: 3px;
  box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.03);
  background-color: #ffffff;
  background-color: ${({ theme }) => theme.backgrounds.sidebarSubMenu};
  & > *:not(:first-child) {
    margin-top: 24px;
    padding-top: 30px;
    border-top: ${({ theme }) => `solid 1px ${theme.borders.tableItem}`};
  }
`;
Wrapper.displayName = 'Wrapper';

export const CommentSeparator = styled.div`
    margin: 18px -1px 18px -10px;
    border-top: ${({ theme }) => `solid 1px ${theme.borders.tableItem}`};
`;
CommentSeparator.displayName = 'CommentSeparator';
