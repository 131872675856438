import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as Edit } from 'Images/details.svg';
import { ReactComponent as Delete } from 'Images/icon-action-bar-trash.svg';

export const Wrapper = styled.div`
  width: 63px;
  height: 34px;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(28, 34, 67, 0.03);
  border: solid 1px ${({ theme }) => theme.borders.importanceMeter};
  display: flex;
  justify-content: space-evenly;
  position: absolute;
  top: 4px;
  right: 4px;
  background: ${({ theme }) => theme.backgrounds.modal};
`;
Wrapper.displayName = 'Wrapper';

export const EditIcon = styled(Edit)`
  width: 18px;
  g, path {
    fill: ${colors.blueGrey};
  }
`;
EditIcon.displayName = 'EditIcon';

export const DeleteIcon = styled(Delete)`
  width: 18px;
  g, path {
    fill: ${colors.blueGrey};
  }
`;
DeleteIcon.displayName = 'DeleteIcon';
