/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { addAssetTag, bulkUpdateAssets, clearError, getHostsData, loadHostDetail, removeAssetTag, setAssetsGeneralTags, resetState } from 'store/HostsGeneral/actions';
import { Wrapper, TableContainer } from './styled';
import Table from './components/Table';
import ActionBar from './components/ActionBar';
import { MODAL_CANT_CREATE } from 'store/modals/modals';
import HostModalCreate from 'Screens/Contextualization/HostModalCreate';
import { getCustomFilters, resetFilters } from 'store/Filters/actions';
import { hideHostDetail } from 'store/HostDetail/actions';
import HostDetail from 'Screens/Contextualization/Host/components/HostDetail';
import FilterSideBar from 'Screens/Contextualization/Manage/components/FilterSideBar';
import { selectError, selectErrorMessage, selectGeneralHostsTags, selectHostGeneralCount, selectHostsSelected, selectIsFetching, selectModalBulkUpdateValue, selectSelectAllHosts, selectShowRightFilters } from 'store/HostsGeneral/selectors';
import ErrorDialog from 'Common/Components/ErrorDialog';
import WarningModal from 'Screens/Contextualization/ManageEditCreate/components/WarningModal';
import { selectActiveWorkspacesCount } from 'store/Global/selectors';
import selectModal from 'store/modals/selectors';
import AssetsContextMenu from './components/Table/ContextMenu';
import SelectAll from './components/SelectAll';
import BulkUpdateModal from './components/Table/components/BulkUpdateModal';
import BulkUpdateConfirmationDialog from '../Manage/components/ManageTable/components/BulkUpdateConfirmationDialog';
import { getGeneralHostsTags, getGeneralServicesTags, getGeneralTags } from '../Tags/actions/Actions';
import { selectShowTagsModal } from 'store/Tags/selectors';
import CreateEditTagsModal from '../Host/components/CreateEditTagsModal';
import { getWorkspacesData } from 'store/Global/actions';

const HostsGeneral = ({ params }) => {
  const dispatch = useDispatch();
  const showRightFilters = useSelector(selectShowRightFilters);
  const error = useSelector(selectError);
  const errorMessage = useSelector(selectErrorMessage);
  const showModalCantCreate = useSelector((state) => selectModal(state, MODAL_CANT_CREATE));
  const activeWorkspacesCount = useSelector(selectActiveWorkspacesCount);
  const hostsSelected = useSelector(selectHostsSelected);
  const count = useSelector(selectHostGeneralCount);
  const selectAll = useSelector(selectSelectAllHosts);
  const isFetching = useSelector(selectIsFetching);
  const bulkUpdateValue = useSelector(selectModalBulkUpdateValue);
  const hostsTags = useSelector(selectGeneralHostsTags);
  const showCreateTagModal = useSelector((state) => selectShowTagsModal('hosts', state));

  const handleParamId = useCallback(() => {
    const id = get(params, 'id', 0);
    if (parseInt(id, 10) > 0) dispatch(loadHostDetail(id));
    else dispatch(hideHostDetail());
  }, [params]);

  useEffect(() => {
    dispatch(getCustomFilters());
  }, [showRightFilters, dispatch]);

  useEffect(() => {
    dispatch(getHostsData());
    dispatch(getWorkspacesData(false, false));
    dispatch(getGeneralTags());
    dispatch(getGeneralHostsTags());
    dispatch(getGeneralServicesTags());
  }, []);

  useEffect(() => {
    handleParamId();
  }, [handleParamId]);

  useEffect(() => () => {
    dispatch(resetState());
    dispatch(resetFilters('assetsGeneral'));
  }, [dispatch]);

  return (
    <Wrapper>
      <ActionBar />
      <HostModalCreate assetsGeneral />
      <SelectAll />
      <TableContainer>
        <Table />
        <HostDetail assetsGeneral hostTags={ hostsTags } />
        <FilterSideBar entity="assetsGeneral" />
        <AssetsContextMenu />
        <BulkUpdateModal />
        <BulkUpdateConfirmationDialog
          selected={ hostsSelected }
          count={ count }
          isSelectAll={ selectAll }
          isFetching={ isFetching }
          bulkUpdateValue={ bulkUpdateValue }
          bulkUpdateAction={ bulkUpdateAssets }
          title="Assets"
        />
      </TableContainer>
      { error ? <ErrorDialog message={ errorMessage } resetError={ () => dispatch(clearError()) } /> : null }
      { showModalCantCreate && <WarningModal entity="Asset" noWorkspaces={ !activeWorkspacesCount } /> }
      { showCreateTagModal &&
        <CreateEditTagsModal
          hostsTags={ hostsTags }
          entity="hosts"
          addTag={ (tag) => dispatch(addAssetTag(tag)) }
          removeTag={ (tag) => dispatch(removeAssetTag(tag)) }
          setTags={ (tags) => dispatch(setAssetsGeneralTags(tags)) }
          allWorkspaces
          hostsSelected={ hostsSelected }
        />
      }
    </Wrapper>
  );
};

export default HostsGeneral;
