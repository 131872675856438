import styled from 'styled-components';

export const Workspace = styled.div`
  font-size: 12.5px;
  font-weight: normal;
  color: ${({ theme }) => theme.text.tableVulnName};
`;
Workspace.displayName = 'Workspace';

export const Column = styled.div`
  font-size: 11.5px;
  font-weight: 400;
  color: ${({ theme }) => theme.text.tableVulnName};
`;
Column.displayName = 'Column';

export const CenteredNumericRow = styled(Column)`
  text-align: center;
  color: ${({ theme }) => theme.text.tableVulnName};
`;
CenteredNumericRow.displayName = 'CenteredNumericRow';
