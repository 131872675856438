import { ReactComponent as _DeleteEvidenceIcon } from 'Images/icon-trash-red.svg';
import { ReactComponent as ClipboardIcon } from 'Images/copy.svg';
import { ReactComponent as _ImagePlaceholder } from 'Images/unknown_filetype.svg';
import { ReactComponent as _DownloadIcon } from 'Images/download-icon.svg';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  height: 165px;
  width: 100%;
  gap: 19px;
`;

export const EvidenceImg = styled.img`
  object-fit: scale-down;
`;

export const ImagePlaceholder = styled(_ImagePlaceholder)`
  margin: 16px 28px;
`;

export const Options = styled.div`
  /* background-color: #ffffff; */
  background-color: ${({ theme }) => theme.backgrounds.modal};
  position: absolute;
  right: 3px;
  bottom: 2px;
  padding: 3px;
  border-radius: 4px;
  border: solid 1px ${({ theme }) => theme.borders.importanceMeter};;
  display: none;

  & svg {
    margin: 0 5px;
  }
`;

export const AttachmentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: center; */
  /* height: 165px; */
  width: 149px;
  height: 172px;
`;

export const AttachmentContainer = styled.div`
  width: 126px;
  height: 130px;
  padding: 3px;
  border: solid 1px ${({ theme }) => theme.borders.evidenceImage};
  background: ${({ theme }) => theme.backgrounds.modal};
  border-radius: 3px;
  margin: 10px 0px 0px 3px;
  box-sizing: border-box;
`;

export const ImageWrapper = styled.div`
  /* height: 140px; */
  height: 118px;
  width: 100%;
  border-radius: 3px;
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.06);
  /* border: solid 1px #dbdbdb; */
  border: solid 1px ${({ theme }) => theme.borders.importanceMeter};
  background: #d8d8d8;
  background: ${({ theme }) => theme.backgrounds.modal};
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  &:hover{
    /* background-color: #eeeeee; */
    background-color: ${({ theme }) => theme.backgrounds.ticketFlapActive};

    & ${Options}{
      display:block;
    }
  }
`;

export const FileNameWrapper = styled.div`
  flex: 0 0 20px;
  user-select: none;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  width: 100%;
  max-width: 126px;
  margin-top: 5px;
`;

export const FileName = styled.div`
  flex: 0 1 auto;
  font-size: 13px;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.text.modalTitle};
`;

export const FileExtension = styled(FileName)`
  flex: 0 0 auto;
`;

export const DeleteEvidenceIcon = styled(_DeleteEvidenceIcon)`
& path{
  fill: ${({ theme }) => theme.textfield.text};
  stroke: ${({ theme }) => theme.textfield.text};
}
`;

export const Clipboard = styled(ClipboardIcon)``;

export const DownloadIcon = styled(_DownloadIcon)`
  fill: #90A9C0;
`;

export const Container = styled.div`
  padding: 0px 18px;
  background-color: ${({ theme }) => theme.backgrounds.modal2};
  height: 130px;
`;

export const StyledTextarea = styled.textarea`
  width: 100%;
  height: 100px;
  border: 1px solid ${({ theme }) => theme.borders.importanceMeter};
  border-radius: 4px;
  border: none;
  background-color: ${({ theme }) => theme.backgrounds.modal2};
  font-size: 14px;
  color: ${({ theme }) => theme.textfield.text};
  resize: none;
  outline: none;

  &::placeholder {
    color: ${({ theme }) => theme.text.placeholder};
  }
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
  text-align: center;
`;

export const Line = styled.span`
  font-size: 13.5;
  color: #63758d;
`;
