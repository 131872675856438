import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 99%;
  overflow: hidden;
  background-color: ${({ theme }) => theme.backgrounds.actionBar};
  margin-top: 8px;
  padding-top: 8px;
`;
Wrapper.displayName = 'Wrapper';

export const TableWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  height: 90%;
  flex: 1 1 0px;
  overflow: hidden;
`;
TableWrapper.displayName = 'TableWrapper';
