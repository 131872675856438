import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as _DeleteIcon } from 'Images/icon-trash-red.svg';
import { ReactComponent as _DownloadIcon } from 'Images/download-icon.svg';

export const Wrapper = styled.div`
  margin-bottom: 15px;
  overflow: auto;
`;
Wrapper.displayName = 'Wrapper';

export const Subtitle = styled.h3`
  font-size: 18px;
  font-weight: 400;
  color: ${({ theme }) => theme.text.modalTitle};
  margin-bottom: 15px;
`;
Subtitle.displayName = 'Subtitle';

export const ListWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px 50px;
  margin-bottom: 20px;
`;
ListWrapper.displayName = 'ListWrapper';

export const ItemWrapper = styled.div`
  width: 170px;
  cursor: pointer;
`;
ItemWrapper.displayName = 'ItemWrapper';

export const Options = styled.div`
  background-color: ${colors.white};
  position: absolute;
  right: 7px;
  bottom: 7px;
  padding: 3px;
  border-radius: 4px;
  border: solid 1px ${colors.lightGrey};
  display: none;

  & svg {
    margin: 0 5px;
  }
`;

export const PreviewWrapper = styled.div`
  height: 170px;
  position: relative;

  &:hover {
    & ${Options}{
      display: block;
      z-index: 999;
    }
  }
`;
PreviewWrapper.displayName = 'PreviewWrapper';

export const ItemTitle = styled.p`
  color: ${({ theme }) => theme.textfield.text};
  font-size: 12px;
  margin-top: 5px;
  text-align: center;
  overflow-wrap: anywhere;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
ItemTitle.displayName = 'ItemTitle';

export const DeleteIcon = styled(_DeleteIcon)`
  width: 16px;
  height: 17px;
  & path{
    fill: ${colors.black};
    stroke: ${colors.blueGrey};
  }
`;
DeleteIcon.displayName = 'DeleteIcon';

export const DownloadIcon = styled(_DownloadIcon)`
  fill: ${colors.blueGrey};
`;
DownloadIcon.displayName = 'DownloadIcon';
