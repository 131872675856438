import styled from 'styled-components';
import colors from 'Styles/colors';
import { slideIn } from 'Styles/effects';

export const ModalContent = styled.div`
  padding: 34px;
  box-shadow: ${({ theme }) => theme.boxShadow.modal};
  border-radius: 2px;
  border-top: 10px solid ${colors.softBlue};
  background: ${({ theme }) => theme.backgrounds.modal};
  width: 505px;
  ${slideIn}
`;
ModalContent.displayName = 'ModalContent';

export const RadioButtonsContainer = styled.div`
max-height: 160px;
overflow-y: auto;
margin-top: 30px;
`;
RadioButtonsContainer.displayName = 'RadioButtonsContainer';
