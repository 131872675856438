/* eslint-disable react/destructuring-assignment */
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  setField, addPolicy, removePolicy,
  selectCheckbox, addReference, removeReference,
  addCVE, removeCVE, getHosts, addAsset, removeAsset
} from 'Screens/Contextualization/ManageEditCreate/actions/Actions';
import {
  selectManageEditCreate,
  selectAllHosts,
  selectTargetsHosts,
  selectWorkspacesField
} from 'store/ManageEditCreate/selectors';
import SeverityDropdown from 'Common/Components/SeverityDropdown';
import EORDropdown from 'Common/Components/EORDropdown';
import CustomList from 'Common/Components/CustomList';
import Impacts from 'Common/Components/Impacts';
import MarkdownInput from '../../MarkdownInput';
import { Row, ExternalId, NewColumn, Title, WrapperColumn, FullRow, Input } from '../styled';
import debounce from 'lodash/debounce';
import { FEATURE_VULNS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import CVE from '../InputCVE';
import InputSearch from '../InputSearch';
import { selectCurrentHost } from 'store/Host/selectors';
import { selectLocation } from 'store/Router/selectors';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import isEmpty from 'lodash/isEmpty';
import { getAssetsByWorkspace } from 'store/ServicesGeneral/actions';
import '../styled.css';
import References from '../References';
import CVSS from '../CVSS';

const WorkspaceContextView = ({ assets, setAssets }) => {
  const manageEditCreate = useSelector(selectManageEditCreate);
  const hosts = useSelector(selectAllHosts);
  const addedHosts = useSelector(selectTargetsHosts);
  const assetForWorking = useSelector(selectCurrentHost);
  const createFromAsset = Object.keys(assetForWorking).length > 0;
  const dispatch = useDispatch();
  const canUpdate = useSelector((state) => selectAllowedByRole(state, FEATURE_VULNS, PERMISSION_TYPES.CREATE));
  const location = useSelector(selectLocation);
  const currentWs = useSelector(selectCurrentWorkspace);
  const isOutsideWorkspace = location.pathname.startsWith('/vulnerabilities') && !location.pathname.includes(`/manage/${currentWs}`);
  const addedWorkspaces = useSelector(selectWorkspacesField);

  const handleChange = (field, value) => {
    dispatch(setField(field, value));
  };

  const debouncedSetField = debounce(handleChange, 150);

  const setTarget = (value) => {
    const temp = addedHosts;
    const found = temp.find((item) => item.id === value.id);
    if (!found) {
      dispatch(addAsset(value));
      setAssets([...assets, value]);
    }
  };

  const onRemoveTarget = (value) => {
    const temp = addedHosts;
    const index = temp.findIndex((item) => item.id === value.id);
    if (index > -1) {
      temp.splice(index, 1);
      setAssets(temp);
      dispatch(removeAsset(value));
    }
  };
  const impacts = {
    accountability: manageEditCreate.accountability,
    availability: manageEditCreate.availability,
    confidentiality: manageEditCreate.confidentiality,
    integrity: manageEditCreate.integrity
  };

  useEffect(() => {
    if (!isEmpty(addedWorkspaces) && isOutsideWorkspace) dispatch(getAssetsByWorkspace(addedWorkspaces));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedWorkspaces]);

  return (
    <WrapperColumn>
      { !createFromAsset &&
          <FullRow>
            <InputSearch
              getObjects={ () => dispatch(getHosts()) }
              onSelect={ setTarget }
              data={ hosts }
              placeholder="Add a Asset"
              id="vuln-add-assets"
              title="Asset(s)"
              required
              addedItems={ addedHosts }
              removeItem={ onRemoveTarget }
              fullWidth
            />
          </FullRow>
        }
      <div style={ { display: 'flex', gap: '23px' } }>
        <NewColumn>
          <div>
            <Row>
              <SeverityDropdown updateValue={ (fieldName, value) => dispatch(setField(fieldName, value)) } defaultValue={ manageEditCreate.severity || '' } />
              <EORDropdown updateValue={ (fieldName, value) => dispatch(setField(fieldName, value)) } defaultValue={ manageEditCreate.easeOfResolution || '' } />
            </Row>
          </div>
          <div>
            <Input
              className="custom-background"
              width="405px"
              height="38px"
              title="CWE"
              name="cwe"
              placeholder="CWE-NNN"
              defaultValue={ manageEditCreate.cwe || '' }
              onChange={ (value) => debouncedSetField('CWE', [value]) }
            />
          </div>
          <div>
            <Title>CVE</Title>
            <CVE
              listData={ manageEditCreate.CVE }
              onAdd= { (val) => dispatch(addCVE(val)) }
              onRemove={ (val) => dispatch(removeCVE(val)) }
              expanded
            />
          </div>
          <MarkdownInput
            title="Description"
            value={ manageEditCreate.description }
            onBlur={ (value) => debouncedSetField('description', value) }
            minHeight={ 187 }
            isRequired
          />
          <CustomList
            key="CL_POLICY_VIOLATIONS"
            title="Policy Violations"
            placeholder="Add a Policy Violation"
            addItem={ (val) => dispatch(addPolicy(val)) }
            removeItem={ (index) => dispatch(removePolicy(index)) }
            listData={ manageEditCreate.policies || [] }
            responsive
            canUpdate={ canUpdate }
            spanStyle='icon2-container'
          />
        </NewColumn>
        <NewColumn>
          <ExternalId
            className="custom-background"
            width="405px"
            height="38px"
            title="External ID"
            name="external_id"
            defaultValue={ manageEditCreate.externalId || '' }
            onChange={ (value) => debouncedSetField('externalId', value) }
          />
          <div>
            <CVSS
              updateValue={ (fieldName, value) => dispatch(setField(fieldName, value)) }
              defaultValue= ''
              manageEditCreate={ manageEditCreate }
              debouncedSetField={ debouncedSetField }
            />
          </div>
          <References
            key="REFERENCES"
            title="References"
            placeholder="Add Reference"
            addItem={ (val) => dispatch(addReference(val)) }
            removeItem={ (index) => dispatch(removeReference(index)) }
            listData={ manageEditCreate.references || [] }
            responsive
            canUpdate={ canUpdate }
            spanStyle='icon2-container'
          />
          <MarkdownInput
            title="Resolution"
            value={ manageEditCreate.resolution }
            onBlur={ (value) => debouncedSetField('resolution', value) }
            minHeight={ 187 }
          />
          <Impacts selectCheckbox={ (field) => dispatch(selectCheckbox(field)) } impacts={ impacts } />
        </NewColumn>
      </div>
    </WrapperColumn>
  );
};

export default withRouter(WorkspaceContextView);
