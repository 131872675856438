import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: ${({ theme }) => theme.text.modalLabel};
  user-select: none;
  margin-bottom: 12px;

  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.08;
  letter-spacing: normal;
  ${(props) => props.isRequired && `
    &:after {
      content: '*';
      color: ${colors.redPink};
    }
  `};
`;
Title.displayName = 'Title';
