import styled, { css } from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as WarningIcon } from 'Images/exclamation_error.svg';

const errorStyle = css`
  box-shadow: 2px 2px 5px 0 rgb(0 0 0 / 6%), inset 0 -1px 0 0 ${colors.redPink};
`;

export const TextArea = styled.textarea`
  height: 136px;
  ${({ disabled }) => disabled && `
    cursor: not-allowed !important;
    opacity: 0.5;
    cursor: not-allowed !important;
  `};
  width: 100%;
  border: 1px solid transparent;
  border-bottom: none;
  background-color: ${({ theme }) => theme.textfield.background};
  ${(props) => (props.error ? errorStyle : '')};
  padding-top: 8px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14.5px;
  font-weight: 300;
  line-height: 1.85;
  display: inline-block;
  resize: none;
  color: ${({ theme }) => theme.textfield.text};
  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.text.taskPlaceholder};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.11px;
  }

  &::placeholder {
    color: ${({ theme }) => theme.text.taskPlaceholder};
    font-size: 14px;
    font-weight: 400;
  }
`;

TextArea.displayName = 'TextArea';

export const Title = styled.span`
    text-align: left;
    font-size: 12.5px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.08;
    letter-spacing: normal;
    margin-bottom: 1px;
    color: ${(props) => (props.boldTitle ? colors.darkBlueGrey : colors.grey17)};
    padding-left: ${(props) => (props.boldTitle ? '15px' : '')};
`;
Title.displayName = 'Title';

export const Asterisk = styled.span`
    text-align: left;
    font-size: 12.5px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.08;
    letter-spacing: normal;
    color: ${colors.redPink};
    margin-bottom: 1px;
`;
Asterisk.displayName = 'Asterisk';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
`;
Wrapper.displayName = 'Wrapper';

export const Texts = styled.div`
    display: flex;
`;
Texts.displayName = 'Texts';

export const ErrorMessage = styled.span`
  font-size: 10.5px;
  letter-spacing: 0.08px;
  color: ${colors.redPink};
  margin-left: 1px;
  margin-top: 8px;
`;
ErrorMessage.displayName = 'ErrorMessage';

export const Warning = styled(WarningIcon)`
  position: absolute;
  right: 10px;
  top: 8px;
`;

Warning.displayName = 'Warning';
