import styled from 'styled-components';

export const Content = styled.div`
    padding: 0 19px 18px 20px;
    grid-row: ${({ legacyUI }) => `${legacyUI ? '2/3' : '3/3'}`};
    overflow: auto;
`;
Content.displayName = 'Content';

export const Wrapper = styled.div`
   max-width: 1920px;
   min-width: 1338px;
   overflow: hidden;
   height: 100%;
   margin: 0 auto;
   display:grid;
   grid-template-rows: min-content min-content 1fr;
   grid-template-columns: min-content minmax(0, 1fr);
   background-color: ${({ theme }) => theme.backgrounds.primary};
   ${({ legacyUI }) => legacyUI && `
    min-width: 1250px;
    display: grid;
    grid-template-rows: min-content 1fr;
    background-color: #fafbfc;
    `};

`;
Wrapper.displayName = 'Wrapper';
