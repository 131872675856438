import styled from 'styled-components';
import { ContextMenu } from 'react-contextmenu';

export const CustomContextMenu = styled(ContextMenu)`
  width: 518px;
  height: 608px;
  z-index: 2 !important;
  background-color: ${({ theme }) => theme.backgrounds.modal};
`;
CustomContextMenu.displayName = 'CustomContextMenu';

export const Wrapper = styled.div`
  height: 100%;
  padding: 21px;
  display:flex;
  flex-direction: column;
`;
Wrapper.displayName = 'Wrapper';

export const Columns = styled.div`
  display: flex;
  flex: 1 1 0px;
`;
Columns.displayName = 'Columns';

export const AddedColumnsWrapper = styled.div`
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: scroll;
`;
AddedColumnsWrapper.displayName = 'AddedColumnsWrapper';

export const Icon = styled.img`
  width: 15px;
  height: 15px;
  margin-right: 5px;
`;
Icon.displayName = 'Icon';
