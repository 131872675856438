/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-children-prop */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { logOut } from 'Screens/Contextualization/Login/actions/Actions';
import { selectCanViewSubscription, selectIsFetching } from 'store/License/selectors';
import { openUpgradeLicense } from 'store/License/actions';
import { trackEvent } from 'tracking/GA';
import { CATEGORIES, ACTIONS } from 'tracking/GA/constants';
import { useIntl } from 'react-intl';
import DropdownItem from './UserDropdownItem';
import { DropdownMenu } from './styled';
import AppearanceMenu from './components/AppearenceMenu';
import AppearanceDropdown from './components/AppearenceItem';
import { Hr } from './components/AppearenceMenu/styled';

const UserDropdown = ({ modals }) => {
  const dispatch = useDispatch();
  const {
    Help, Extras, About, Preferences
  } = modals;
  const [appearance, setAppearance] = useState(false);

  const intl = useIntl();
  const label = intl.formatMessage({ id: 'license.mySubscription' });
  const canViewSubscription = useSelector(selectCanViewSubscription);
  const isFetching = useSelector((state) => selectIsFetching(state));

  const sentToMySubscription = () => {
    if (!isFetching) {
      dispatch(trackEvent(CATEGORIES.subscription, ACTIONS.mySubscription.name, ACTIONS.mySubscription.label));
      dispatch(openUpgradeLicense());
    }
  };

  if (appearance) return <AppearanceMenu setAppearance={ setAppearance } />;
  return (
    <DropdownMenu>
      {canViewSubscription && <DropdownItem action={ sentToMySubscription } children={ label } />}
      {canViewSubscription && <Hr /> }
      <DropdownItem Modal={ Preferences }>Preferences</DropdownItem>
      <Hr className="h-menu-separator m-0" />
      <DropdownItem link="/api-definitions" >Faraday Api</DropdownItem>
      <AppearanceDropdown setAppearance={ setAppearance } />
      <DropdownItem Modal={ Help }>Help</DropdownItem>
      <DropdownItem Modal={ Extras }>Extras</DropdownItem>
      <DropdownItem Modal={ About }>About</DropdownItem>
      <DropdownItem action={ () => dispatch(logOut()) }>Logout</DropdownItem>
    </DropdownMenu>
  );
};

UserDropdown.propTypes = {
  modals: PropTypes.shape({
    Extras: PropTypes.object.isRequired,
    Help: PropTypes.object.isRequired,
    About: PropTypes.object.isRequired,
    Preferences: PropTypes.object.isRequired
  }).isRequired
};

export default UserDropdown;
