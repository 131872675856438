import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { setLdapField } from 'store/Settings/ldap/actions';
import {
  selectServer, selectPort, selectDomain, selectDomainDn, selectBindFormat,
  selectBindDn, selectUserAttribute
} from 'store/Settings/ldap/selectors';
import Input from 'Screens/Settings/components/Ldap/components/Input';
import { Row } from 'Screens/Settings/components/Ldap/styled';
import {
  Wrapper, Server, Port, Domain, DomainDn, BindDn, UserAttribute,
  dropdownStyle, DropdownWrapper, Title
} from './styled';
import { ThemeContext } from 'styled-components';

const Dropdown = ({ value, handleChange, title }) => {
  const options = [
    {
      value: 'CN',
      label: 'CN'
    },
    {
      value: 'DN',
      label: 'DN'
    }
  ];

  return (
    <DropdownWrapper>
      <Title>{ title }</Title>
      <Select
        options={ options }
        value={ options.filter((opt) => opt.value === value) }
        onChange={ handleChange }
        styles={ dropdownStyle(useContext(ThemeContext)) }
        placeholder="Select One"
        isSearchable={ false }
      />
    </DropdownWrapper>
  );
};

const BasicFields = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const getIntlText = (id) => intl.formatMessage({ id: `preferences.ldap.${id}` });
  const bindFormatTitle = getIntlText('bind_format');
  const bindDnTooltip = getIntlText('bind_dn.tooltip');
  const server = useSelector(selectServer);
  const port = useSelector(selectPort);
  const domain = useSelector(selectDomain);
  const domainDn = useSelector(selectDomainDn);
  const bindFormat = useSelector(selectBindFormat);
  const bindDn = useSelector(selectBindDn);
  const userAttribute = useSelector(selectUserAttribute);
  const isDisabled = bindFormat !== 'DN';
  return (
    <Wrapper>
      <Row>
        <Server><Input inputValue={ server } field="server" type="string" isRequired /></Server>
        <Port><Input inputValue={ port || '' } field="port" type="number" isRequired /></Port>
        <Domain><Input inputValue={ domain } field="domain" type="string" isRequired /></Domain>
        <DomainDn><Input inputValue={ domainDn } field="domain_dn" type="string" isRequired /></DomainDn>
      </Row>
      <Row>
        <Dropdown value={ bindFormat } handleChange={ (e) => dispatch(setLdapField('bind_format', e.value)) } title={ bindFormatTitle } />
        <BindDn><Input inputValue={ bindDn } field="bind_dn" type="string" isRequired disabled={ isDisabled } tooltip={ bindDnTooltip } /></BindDn>
        <UserAttribute><Input inputValue={ userAttribute } field="user_attribute" type="string" isRequired disabled={ isDisabled } tooltip={ bindDnTooltip } /></UserAttribute>
      </Row>
    </Wrapper>
  );
};

Dropdown.propTypes = {
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

export default BasicFields;
