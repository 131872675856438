import styled from 'styled-components';

export const Wrapper = styled.div`
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  font-size: 21.5px;
  font-weight: 600;
  line-height: 0.84;
  color: ${({ theme }) => theme.text.settingsBlueTitle};
  text-align: initial;
`;
Title.displayName = 'Title';

export const Username = styled.span`
  margin-left: 7px;
`;
Username.displayName = 'Username';
