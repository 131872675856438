import styled from 'styled-components';

export const CommentTextAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;
CommentTextAreaWrapper.displayName = 'CommentTextAreaWrapper';

export const Title = styled.div`
  height: 42px;
  width: 100%;
  padding-left: 25px;
  justify-content: center;
  user-select: none;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.backgrounds.detailModalFields};
  color: ${({ theme }) => theme.textfield.text};
  font-size: 17.5px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
`;
Title.displayName = 'Title';

export const Subtitle = styled.div`
  font-size: 12.5px;
  line-height: normal;
  margin-bottom: 21px;
  color: ${({ theme }) => theme.text.modalTitle2};
`;
Subtitle.displayName = 'Subtitle';
