import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDefaultProject } from 'store/Settings/jiraSelectors';
import SetProject from './SetProject';
import ModalWarning from 'Common/Components/ModalWarning';
import { getJiraSettings } from 'store/Settings/jiraActions';

const JiraController = ({ vulnsSelected, vulnId, isVulnDetailVisable, entity, onCloseWarningJira }) => {
  const dispatch = useDispatch();
  const defaultProject = useSelector(selectDefaultProject);
  const [isComplete, setIsComplete] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        await dispatch(getJiraSettings());
      } catch (err) {
        setError(true);
      }
      setIsComplete(true);
    };

    fetchSettings();
  }, [dispatch]);

  if (!isComplete) {
    return null;
  }

  if (error) return <ModalWarning title="Error" description="Failed to load settings, please try again." onCloseCallback={ onCloseWarningJira } />;
  if (!defaultProject) return <ModalWarning onCloseCallback={ onCloseWarningJira } title="Send To Tool" description="Set up a default project in Jira settings" />;
  return <SetProject vulnsSelected={ vulnsSelected } vulnId={ vulnId } isVulnDetailOpen={ isVulnDetailVisable } entity={ entity } />;
};

export default JiraController;
