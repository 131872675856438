import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 480px;
    margin: 21px 0 0;
    padding: 21px 20px 21px 21px;
    background-color: ${({ theme }) => theme.backgrounds.customAttributesAdd};
    display: flex;
    flex-direction: column;
`;

Wrapper.displayName = 'AddCustomField';

export const Title = styled.span`
    font-size: 17.5px;
    font-weight: 600;
    color: ${({ theme }) => theme.text.modalTitle2};
    margin-bottom: 21px;
    text-align: left;
`;

Title.displayName = 'Title';

export const TextFieldContainer = styled.div`
    width: 285px;
`;

TextFieldContainer.displayName = 'TextFieldContainer';

export const Fields = styled.div`
    display: flex;
    align-items: center;
    > :nth-child(1){
        margin-right: 22px;
    }
`;

Fields.displayName = 'Fields';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

Container.displayName = 'Container';

export const Buttons = styled.div`
    display: flex;
    margin-top: 15px;
    justify-content: flex-end;
    > :last-child {
      margin-left: 8px;
    }
`;

Buttons.displayName = 'Buttons';
