import styled from 'styled-components';
import AddButton from 'Common/Components/Contextualization/AddButton';

const Button = styled(AddButton)`
  background-color: ${({ theme }) => theme.buttons.secondary.background};
  border: 1px solid ${({ theme }) => theme.buttons.secondary.border};
  margin-right: auto;
`;
Button.displayName = 'Button';

export default Button;
