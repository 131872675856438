import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 0px 20px 20px 20px;
  border: solid 1px #edf2f7;
`;
Wrapper.displayName = 'Wrapper';

export const ListItem = styled.div`
  color: ${({ theme }) => theme.text.expander}; 
  font-size: 13.5px;
  line-height: 2.52;
  padding: 0 20px;
`;
ListItem.displayName = 'ListItem';
