/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  /* font-family: SequelSans-RomanHead; */
  font-size: 13.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${({ theme }) => theme.text.modalTitle};
`;
Wrapper.displayName = 'Wrapper';

export const Section = styled.div`
  width: 116px;
  height: 50px;
  padding: 17px;
  opacity: 0.36;
  border-radius: 3px;
  font-size: 13.5px;
  color: ${colors.darkBlueGrey};
  background-color: ${colors.light2};
`;
Section.displayName = 'Section';

export const Content = styled.div`
  display: flex;
  justify-content: left;
  width: 100%;
  height: 500px;
  opacity: 0.36;
  background-color: ${colors.light2};
`;
Content.displayName = 'Content';
