import styled, { css } from 'styled-components';
import colors from 'Styles/colors';
import { fadeIn } from 'Styles/effects';

export const Wrapper = styled.div`
  cursor: ${({ disabled }) => { if (disabled) return 'not-allowed !important'; else return 'pointer'; }};
  display: flex;
  border-radius: 20px;
  width: 37px;
  height: 37px;
  align-items: center;
  justify-content: center;
  & > svg {
    cursor: ${({ disabled }) => { if (disabled) return 'not-allowed !important'; else return 'pointer'; }};
    min-width: 17px;
  }
  & > svg > g{
    fill: ${({ theme }) => theme.icons.actionBar.color};
  }
  & > svg > g > g > g{
    fill: ${({ theme }) => theme.icons.actionBar.color};
  }
  & > svg > g > path{
    fill: ${({ theme }) => theme.icons.actionBar.color};
  }
  circle {
    fill: ${({ theme }) => theme.backgrounds.modal};
    /* fill: transparent; */
    /* fill: none; */
    stroke: ${({ theme }) => theme.icons.actionBar.color};
  }
  g > g > path:last-of-type {
    stroke: ${({ theme }) => theme.icons.actionBar.color};
  }
  
  &:hover {
    background-color: ${({ theme }) => theme.icons.actionBar.background};
    & > svg g, path {
      fill: ${({ theme }) => theme.icons.actionBar.hover};
    }
  }

  ${({ disabled }) => !disabled &&
    css`&:active {
      ${fadeIn(0, 1)}
      background-color: ${colors.blueGrey};
      & > svg {
        g, path {
          fill: ${colors.white};
        }
      }
    }
  `}

  ${({ disabled }) => {
    if (!disabled) return null;
    return `
      opacity: 0.5;
      `;
  }}

`;
Wrapper.displayName = 'Wrapper';

export const Text = styled.div`
`;
Text.displayName = 'Text';
