import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as CloseSVG } from 'Images/icon-close-without-background.svg';
import { ReactComponent as IconUser_ } from 'Images/icon_users.svg';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  & > :not(:last-child) {
    margin-right: 8px;
  }
`;
Wrapper.displayName = 'Wrapper';

export const CloseIcon = styled(CloseSVG)`
  position: absolute;
  left: 9px;
  top: 9px;
  margin: auto;
  cursor: pointer;
  &>:hover{
    left: 8px;
    top: 8px;
    & g {
      & circle{
        background-color: ${colors.iceBlue};
      }  
    }
  }  
  & g {
    & path{
      fill: ${colors.grey19};
    }  
  }
`;
CloseIcon.displayName = 'CloseIcon';

export const CloseIconWrapper = styled.div`
  flex: 0 0 37px;
  position: relative;
  padding-left: 3px;
  height: 37px;
  cursor: pointer;
  &:hover{
    background-color: ${colors.iceBlue};
    border-radius: 50%;
  }  
`;
CloseIconWrapper.displayName = 'CloseIconWrapper';

export const IconUser = styled(IconUser_)`
  margin-right: 10px;
  cursor: default;
  & > g {
    & path {
      fill: transparent !important;
    }
    & :last-child {
      fill: ${({ theme }) => theme.icons.actionBar.color} !important;
    }
  }
`;
IconUser.displayName = 'IconUser';

export const UsernameWrapper = styled.div`
  display: flex;
  width: 91%;
  align-items: center;
`;
UsernameWrapper.displayName = 'UsernameWrapper';

export const Username = styled.div`
  font-size: 16px;
  font-weight: 600;
  width: 400px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.text.detailModalFields};
`;
Username.displayName = 'Username';
