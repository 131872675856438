import styled from 'styled-components';
import { ReactComponent as MoreIcon } from 'Images/icon-action-bar-more.svg';

const More = styled(MoreIcon).attrs({
  id: 'qa-more-ws'
})`
      height: 17px;
      width: 21px;
      .st0 {
        fill: ${({ theme }) => theme.icons.actionBar.color} !important;
      }
    `;

More.displayName = 'More';

export default More;
