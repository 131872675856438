import React from 'react';
import { PropTypes } from 'prop-types';
import { Accept } from 'Common/Components/Button/styled';
import { FormattedMessage } from 'react-intl';
import { Wrapper, CancelButton } from './styled';

const Header = ({ disabled, onClose, onAccept }) => (
  <Wrapper>
    <CancelButton onClick={ onClose }>
      <FormattedMessage id="common.components.button.cancel" />
    </CancelButton>
    <Accept disabled={ disabled } onClick={ onAccept }>
      <FormattedMessage id="common.components.button.save" />
    </Accept>
  </Wrapper>
);
export default Header;

Header.propTypes = {
  onClose: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

Header.defaultProps = {
  disabled: false
};
