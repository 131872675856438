import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import Wrapper from './styled';
import {
  Title, dropdownStyle, List,
  DataItem, Item, TrashWrapper, Trash
} from '../../../BasicSetupModal/components/Requester/styled';
import { getDataByQuery, setField } from 'store/Settings/serviceDesk/actions';
import { selectOptionsData, selectTemplateFieldValue, selectTemplateSelected } from 'store/Settings/serviceDesk/selectors';
import { SEARCH_ENDPOINTS } from 'store/Settings/serviceDesk/constants';
import useTheme from 'Hooks/UseTheme';

const SearchField = ({ fieldName, mandatory }) => {
  const dispatch = useDispatch();
  const title = fieldName.split('_').join(' ');
  const value = useSelector((state) => selectTemplateFieldValue(state, fieldName));
  const initialOptions = useSelector((state) => selectOptionsData(state, SEARCH_ENDPOINTS[fieldName]));
  const [options, setOptions] = useState(initialOptions);
  const optionsParsed = options.map((u) => ({ label: u, value: u }));
  const [selectedOption, setSelectedOption] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const templateSelected = useSelector(selectTemplateSelected);
  const { themeStyles } = useTheme();

  const handleInputChange = useCallback((typedOption) => {
    if (typedOption.length > 2) {
      dispatch(getDataByQuery(typedOption, SEARCH_ENDPOINTS[fieldName], setOptions));
      setShowOptions(true);
    } else setShowOptions(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (v) => {
    const template = templateSelected;
    const fieldToUpdate = { [fieldName]: { data: { name: v.value }, type: mandatory ? 'mandatory' : 'optional' }, deleted: false };
    if (templateSelected?.fields?.length > 0 && templateSelected.fields.find(el => Object.hasOwn(el, fieldName))) {
      const filteredFields = templateSelected.fields.filter(item => !Object.hasOwn(item, fieldName));
      Object.assign(template, { fields: [...filteredFields, fieldToUpdate] });
    } else if (templateSelected?.fields?.length > 0 && !templateSelected.fields.find(el => Object.hasOwn(el, fieldName))) {
      const filteredFields = templateSelected.fields.filter(item => !Object.hasOwn(item, fieldName));
      Object.assign(template, { fields: [...filteredFields, fieldToUpdate] });
    } else {
      Object.assign(template, { fields: [fieldToUpdate] });
    }
    setSelectedOption(v);
    dispatch(setField('templateSelected', template));
  };

  return (
    <Wrapper mandatory={ mandatory }>
      { mandatory ? <Title>{ title }</Title> : null }
      <Select
        value={ selectedOption }
        options={ showOptions ? optionsParsed : [] }
        onChange={ handleChange }
        onInputChange = { handleInputChange }
        controlShouldRenderValue={ false }
        noOptionsMessage={ () => null }
        styles={ dropdownStyle(themeStyles) }
        placeholder={ fieldName }
      />

        <List>
          { value &&
            <DataItem>
              <Item>{value}</Item>
                { mandatory &&
                  <TrashWrapper onClick={ () => dispatch(setField(fieldName, '')) }>
                    <Trash />
                  </TrashWrapper>
                }
            </DataItem>
            }
        </List>
    </Wrapper>
  );
};

export default SearchField;
