import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.backgrounds.primary};
  width: 220px;
  height: ${(props) => (props.isMilestone ? 'auto' : '')};
  min-height: ${(props) => (props.isMilestone ? 'auto' : '152px')};
  padding: ${(props) => (props.isMilestone ? '11px 16px 11px 13px' : '11px 16px 23px 13px')};;
  border: ${({ theme }) => `1px solid ${theme.borders.severityStack}`};
  border-radius: 2px;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  line-height: 1.2;
  & > *:not(:last-child) {
    margin-bottom: 12px;    
  }
`;
Wrapper.displayName = 'Wrapper';

export const TaskDate = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${colors.greyBlue};
  display: flex;
  margin-left: 7px;
  color: ${({ theme }) => theme.text.modalDescription};
`;
TaskDate.displayName = 'TaskDate';

export const Progress = styled.div`
  display: flex;
  align-items: center;
  margin-left: 7px;
`;
Progress.displayName = 'Progress';

export const Circle = styled.div`
  width: 10px;
  height: 10px;
  background-color:${({ progress }) => {
    if (progress === 'new') return `${colors.tangerine}`;
    if (progress === 'in progress') return `${colors.grey2}`;
    if (progress === 'review') return `${colors.paleGold}`;
    return `${colors.apple}`;
  }};
  border-radius: 50%;
  margin-right: 6px;
`;
Circle.displayName = 'Circle';

export const ProgressText = styled.p`
  font-size: 13px;
  color: ${colors.greyBlue};
  text-transform: capitalize;
  color: ${({ theme }) => theme.text.modalDescription};
`;
ProgressText.displayName = 'ProgressText';

export const Separator = styled.div`
  width: 188px;
  height: 1px;
  border: 1px solid ${colors.iceBlue};
`;
Separator.displayName = 'Separator';

export const AssignedToWrapper = styled.div`
  font-size: 13px;
  color: ${colors.greyBlue};
  font-weight: 600;
  margin-left: 7px;
  color: ${({ theme }) => theme.text.modalDescription};
`;
AssignedToWrapper.displayName = 'AssignedToWrapper';

export const Assignees = styled.p`
  font-weight: 400;
`;
Assignees.displayName = 'Assignees';

export const Dash = styled.p`
  margin: 0 3px;
`;
Dash.displayName = 'Dash';
