import styled from 'styled-components';

export const Wrapper = styled.div`
  grid-row: 2/3;
  display: flex;
  padding: 0 20px;
`;
Wrapper.displayName = 'Wrapper';

export const Item = styled.button`
  padding: 14px 29px;
  background-color: ${({ isActive, theme }) => isActive ? theme.tab.selected : theme.tab.item};
  border: none;
  border-left: ${({ theme }) => `1px solid ${theme.backgrounds.primary}`};
  border-right: ${({ theme }) => `1px solid ${theme.backgrounds.primary}`};
  border-top: ${({ isActive, theme }) => isActive ? `1px solid ${theme.tab.selected}` : `1px solid ${theme.tab.item}`};
  border-bottom: ${({ isActive, theme }) => isActive ? `1px solid ${theme.tab.selected}` : `1px solid ${theme.tab.item}`};
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: ${({ isActive, theme }) => isActive ? theme.text.tab : theme.text.tabSecondary};
  height: 50px;
  
  &:focus-visible {
    border: 1px solid #006fc9;
  }
`;
Item.displayName = 'Item';
