import colors from 'Styles/colors';

export const lightTheme = {
  borders: {
    vulnsCounter: colors.grey33,
    searchBar: colors.grey37,
    tableItem: colors.light3,
    tableHeaderBottom: colors.light3,
    tableHeaderTop: colors.light3,
    tableHeaderDivider: colors.blueGrey,
    importanceMeter: colors.grey12,
    workspaceItem: colors.iceBlue,
    workspaceSelectedItem: colors.blueCerulean,
    workspaceStats: colors.blue8,
    dashboardRow: colors.light2,
    dashboard: colors.grey38,
    feedTabs: colors.white5,
    feed: colors.white5,
    target: colors.white5,
    calendarDate: colors.white10,
    actionDropDown: colors.white7,
    detailModalHeaderIcons: colors.grey38,
    contextMenuSeparator: colors.white11,
    tool: colors.white12,
    memoListItem: colors.veryLightPink,
    memoListItemSelected: colors.grey55,
    detailPanel: colors.grey38,
    queue: colors.white14,
    scheduleWrapper: colors.white12,
    severityStack: colors.lightGrey,
    alanyticsPreviewTableHeader: colors.lightGrey,
    analyticsNewGraphItems: colors.white12,
    reportTable: colors.transparent,
    expander: colors.white12,
    loginCard: colors.white12,
    preferenceMenuLine: colors.grey65,
    dashboardFilter: colors.blue13,
    dashboardFilterDropdown: colors.blue13,
    vulnChartFlyout: colors.grey38,
    attributeTag: colors.grey12,
    assetFilterList: colors.white19,
    assignedUserList: colors.white20,
    customAttributeItem: colors.white12,
    dropdown: colors.paleGrey,
    plannerTabActive: colors.blueCerulean,
    taskUsers: colors.white19,
    actionButtonsGridCard: colors.lightGrey,
    dashboardTag: colors.grey9,
    reportsDownload: colors.grey89,
    reportsBox: colors.grey27,
    schedulersSectionSelector: colors.grey97,
    schedulersOptions: colors.transparent,
    evidenceImage: colors.white12
  },
  backgrounds: {
    primary: colors.white3,
    secondary: colors.white1,
    tertiary: colors.white1,
    quaternary: colors.white,
    sidebar: colors.iceBlue,
    sidebarSubMenu: colors.white,
    sidebarSubMenuItemHover: colors.grey38,
    card: colors.white1,
    vulnsCounter: colors.grey32,
    searchBar: colors.white,
    table: colors.white,
    tableItem: colors.white1,
    selectedTableItem: colors.blue8,
    hoverTableItem: colors.white1,
    tableHeader: colors.white,
    modal: colors.white,
    modal2: colors.white,
    modal3: colors.blue12,
    modal4: colors.iceBlue,
    actionBar: colors.white,
    importanceMeter: colors.blue8,
    workspaces: colors.white,
    workspaceItem: colors.paleGrey,
    workspaceStats: colors.white,
    dashboard: colors.white,
    vulnSummary: colors.dark1,
    vulnChartFlyout: colors.white,
    summaryFilter: colors.grey19,
    summaryDropdown: colors.grey19,
    severityChart: colors.blue1,
    dashboardItem: colors.transparent,
    feed: colors.transparent,
    feedTag: colors.grey8,
    target: colors.paleGrey,
    actionDropDown: colors.white,
    actionDropDownHover: colors.white9,
    detailModal: colors.white1,
    detailModalHeaderIcons: colors.white,
    detailModalHostData: colors.grey19,
    detailModalFields: colors.white,
    detailModalTransparency: colors.white1,
    contextMenu: colors.white,
    contextMenuHover: colors.white9,
    memoListItem: colors.white3,
    queueHeader: colors.blueGrey,
    sideModal: colors.paleGrey,
    scheduleWrapper: colors.transparent,
    listItemOdd: colors.iceBlue,
    listItemEven: colors.transparent,
    workspaceList: colors.transparent,
    toggleSwitch: colors.grey38,
    analytics: colors.white,
    severityStack: colors.white,
    alanyticsPreviewTableHeader: colors.iceBlue,
    analyticsNewGraph: colors.white,
    analyticsNewGraphItems: colors.white,
    expander: colors.white,
    groupTable: colors.grey21,
    groupTableItemOdd: colors.iceBlue,
    groupTableItemEven: colors.transparent,
    inputPlus: colors.iceBlue,
    pipelineBox: colors.grey64,
    swagger: colors.iceBlue,
    loginCard: colors.white,
    unreadNotificationsRow: colors.grey84,
    ticketFlap: colors.grey69,
    ticketFlap2: colors.white2,
    ticketFlapActive: colors.white15,
    featureflag: colors.white,
    impactItem: colors.white2,
    attributeTag: colors.white,
    attributeTagOp: colors.iceBlue,
    reportTag: colors.white17,
    reportTagSelected: colors.grey95,
    reportUnselectedInput: colors.white18,
    assetFilterList: colors.transparent,
    assetFilterListItem: colors.blue8,
    assignedUserListItemHover: colors.iceBlue,
    customAttributesAdd: colors.white1,
    customAttributeItem: colors.white,
    editModal: colors.white1,
    editModalContent: colors.white,
    dateCustomAttribute: colors.grey76,
    impactsUnselected: colors.white9,
    impactsSelected: colors.grey79,
    dropdown1: colors.white,
    dropdown1Options: colors.white,
    dropdown1OptionsHover: colors.iceBlue,
    sidebarSubMenuHeader: colors.greyBlue,
    sidebarSubMenuHeaderHover: colors.greyBlue,
    detailFields: colors.grey76,
    detailFieldsMDButtons: colors.white,
    inputsFocus: colors.white,
    preferencesSidebar: colors.blue12,
    preferencesContent: colors.white,
    preferencesItemHover: colors.grey12,
    runAgentSection: colors.white1,
    runAgentInput: colors.iceBlue,
    agentCard: colors.white,
    selectedTableNotification: colors.blue8,
    plannerTab: colors.white1,
    plannerTabActive: colors.white1,
    milestone: colors.blueGrey,
    actionButtonsGridCard: colors.white,
    workspacesSelectorHover: colors.iceBlue,
    dashboardTag: colors.white,
    reportActiveActionButton: colors.gray42,
    reportsBox: colors.white,
    rootBg: colors.white21,
    attributeIsOneOfVal: colors.grey96,
    schedulerIcon: colors.white22
  },
  shadows: {
    dashboard: colors.white4,
    actionDropDown: colors.white8,
    contextMenu: colors.white8,
    searchbar: colors.grey53,
    memoListItem: colors.grey53,
    sideModal: colors.grey60,
    scheduleWrapper: colors.grey59,
    preferenciesModal: colors.grey60,
    inputInset: colors.grey67,
    confirmationModal: colors.white,
    modal1: colors.boxShadow1,
    inputs: colors.boxShadow4,
    standardDropdown: colors.boxShadow5,
    agentCard: colors.white16,
    tagsInputAgent: colors.grey30,
    reportsDownload: colors.grey90,
    reportsBox: colors.white16,
    wsDropdownInset: colors.grey30,
    wsDropdown: colors.dark44,
    schedulersSectionSelector: colors.grey98,
    schedulersSectionSelectorInset: colors.grey99,
    agentSelectorInScheduler: colors.boxShadow7
  },
  icons: {
    actionBar: {
      color: colors.blue13,
      deleteColor: colors.blue13,
      background: colors.iceBlue,
      hover: colors.blue13
    },
    sideBar: {
      color: colors.blue13
    },
    workspaceItem: {
      color: colors.blue13
    },
    workspaceRefresh: {
      color: colors.darkBlueGrey
    },
    dashboard: {
      color: colors.blue8
    },
    detailModalTitle: {
      color: colors.blue16
    },
    detailModal: {
      color: colors.grey51
    },
    logo: {
      color: colors.blue19
    },
    modal: {
      color: colors.darkBlueGrey
    },
    switchTheme: {
      color: colors.dark33
    },
    preferenceMenu: {
      color: colors.dark17
    },
    processingQueue: {
      color: colors.white
    },
    processingQueueSuccess: {
      color: colors.white
    },
    removeAssetListItem: {
      color: colors.greyBlue
    },
    warningIcon: {
      color: colors.darkBlueGrey
    },
    notifications: {
      color: colors.grey39
    },
    taskUsers: {
      color: colors.blue11
    },
    plannerHover: {
      color: colors.blue13
    },
    twoFactor: {
      hover: colors.blue13
    }
  },
  text: {
    primary: colors.grey10,
    secondary: colors.grey12,
    link: colors.blue1,
    disabled: colors.grey14,
    vulnsCounter: colors.darkBlueGrey,
    tab: colors.blue13,
    tabSecondary: colors.blue13,
    tableHeader: colors.blueGrey,
    tableItem: colors.blueGrey,
    modalTitle: colors.dark2,
    modalTitle2: colors.darkBlueGrey,
    modalTitle3: colors.blueCerulean,
    modalDescription: colors.grey19,
    tableAssetItem: colors.blue6,
    actionBar: colors.dark2,
    workspaceItemTitle: colors.dark2,
    workspaceItem: colors.grey19,
    workspaceHighlightStat: colors.dark2,
    workspaceStat: colors.blueGrey,
    workspaceCriticalStat: colors.warmPurple,
    dashboardTitle: colors.dark2,
    dashboardLightText: colors.blue15,
    dashboardDarkText: colors.blueGrey,
    dashboardBlueTitle: colors.blue22,
    dashboardTertiaryTitle: colors.dark2,
    statusChartReference: colors.dark17,
    feedTabActive: colors.blue6,
    feedTab: colors.dark17,
    feedHost: colors.dark3,
    feedTrigger: colors.grey5,
    feedDate: colors.blueGrey,
    feedAuthor: colors.dark4,
    feedHighLight: colors.dark3,
    target: colors.dark17,
    feedHistoryTrigger: colors.blue5,
    emptyState: colors.grey19,
    sectionTitle: colors.darkBlueGrey,
    monthHeader: colors.black,
    actionDropDownItem: colors.dark17,
    actionDropDownTitle: colors.dark2,
    detailModalTitle: colors.blue16,
    detailModalTabs: colors.blue6,
    detailModalButtons: colors.blue6,
    detailModalFields: colors.dark2,
    contextMenu: colors.blue18,
    modalItem: colors.dark7,
    sidebarSubMenu: colors.dark17,
    searchBarPlaceholder: colors.grey82,
    searchBar: colors.dark25,
    memoListItemTitle: colors.black,
    memoListItemDescription: colors.grey44,
    memoListItemID: colors.grey44,
    sideModaTitle: colors.darkBlueGrey,
    sideModaLabel: colors.black,
    scheduleRadioButton: colors.dark2,
    workspaceListItem: colors.black,
    blackenedModalTitle: colors.black2,
    alanyticsPreviewTableItemHighlight: colors.dark2,
    alanyticsPreviewTableItem: colors.dark2,
    alanyticsPreviewTableItemTotal: colors.dark2,
    analyticsNewGraphItems: colors.black,
    reportTableItem: colors.blue6,
    expander: colors.dark2,
    settingsBlueTitle: colors.dark2,
    card: colors.dark5,
    loginCard: colors.darkBlueGrey,
    tableVulnName: colors.dark5,
    impactItem: colors.dark2,
    jobDetailTitle: colors.darkBlueGrey,
    jobDetailDescription: colors.grey19,
    attributeTag: colors.greyBlue,
    reportTag: colors.blue23,
    reportTagSelected: colors.white,
    assetFilterListItem: colors.darkBlueGrey,
    blueSubtitle: colors.blue13,
    tableVulnNameConfirmed: colors.blue14,
    description: colors.grey11,
    detailNameHover: colors.blue13,
    customAttributeItem: colors.greyBlue,
    customAttributeBulkEdit: colors.darkBlueGrey,
    dateInput: colors.darkBlueGrey,
    contextMenuDelete: colors.red3,
    impactsUnselected: colors.black2,
    severitiesDropdown: colors.white,
    eorDropdown: colors.grey81,
    eorDropdownIcon: colors.grey81,
    serviceName: colors.blue14,
    assetName: colors.dark2,
    modalLabel: colors.darkBlueGrey,
    inputPlaceholderVulns: colors.grey17,
    inputFilterGroupedData: colors.dark2,
    preferencesMenuHeader: colors.grey11,
    preferencesMenuItem: colors.dark2,
    preferencesMenuItemHover: colors.dark2,
    runAgentTitle: colors.darkBlueGrey,
    runAgentSectionTitle: colors.darkBlueGrey,
    runAgentDescription: colors.greyBlue,
    runAgentInputLabel: colors.grey17,
    runAgentInput: colors.black,
    duplicates: colors.blue11,
    notificationsBold: colors.dark3,
    notificationsTime: colors.blueGrey,
    plannerTabActive: colors.blueCerulean,
    taskPlaceholder: colors.grey12,
    analyticsPlaceholder: colors.grey88,
    analyticsSectionLabel: colors.blue13,
    analyticsGraphTickLabels: colors.grey19,
    analyticsGraphAxis: colors.dark41,
    systemComment: colors.grey19,
    userComment: colors.black4,
    dashboardTag: colors.black1,
    attributeOpTag: colors.greyBlue,
    tableReportsName: colors.dark3,
    placeholder: colors.grey12,
    analyticsTable: colors.grey19,
    usersDropdown: colors.white
  },
  buttons: {
    primary: {
      background: colors.blueCerulean,
      color: colors.white1,
      border: colors.blue2
    },
    secondary: {
      background: colors.lightGray,
      color: colors.grey1,
      border: colors.grey10
    },
    tertiary: {
      background: colors.white,
      colorActive: colors.darkBlueGrey,
      color: colors.grey26,
      borderActive: colors.darkBlueGrey,
      border: colors.grey26
    },
    disabled: {
      background: colors.transparent,
      color: colors.veryLightPink,
      border: colors.veryLightPink
    },
    sidebar: {
      hover: colors.grey38,
      selected: colors.grey38,
      text: colors.blue13
    },
    custom: {
      background: colors.white3,
      color: colors.dark2,
      disableColor: colors.blue12,
      border: colors.gray42,
      backgroundHover: colors.white9
    },
    assetSelector: {
      background: colors.grey49,
      border: colors.white6
    },
    pagination: {
      background: colors.iceBlue
    },
    rule: {
      background: colors.blueCerulean,
      border: colors.transparent,
      color: colors.white,
      hoverBg: colors.blue11,
      activeBg: colors.softBlue
    },
    disabledSave: {
      background: colors.grey74,
      color: colors.white
    },
    addButton: {
      background: colors.iceBlue,
      color: colors.dark2,
      disableColor: colors.blue12,
      border: colors.grey12,
      backgroundHover: colors.white9
    }
  },
  tab: {
    item: colors.iceBlue,
    selected: colors.white
  },
  stroke: colors.grey7,
  primary: colors.blue2,
  secondary: colors.darkGray,
  textfield: {
    background: colors.iceBlue,
    background2: colors.blue8,
    text: colors.darkBlueGrey,
    focus: colors.iceBlue,
    shadow: colors.iceBlue
  },
  selector: {
    background: colors.white,
    selected: colors.white2,
    text: colors.dark2
  },
  boxShadow: {
    modal: colors.boxShadow2,
    dropdown: colors.boxShadow3
  },
  gantt: {
    header: colors.paleGrey,
    project: colors.iceBlue,
    taskProjectProgress: colors.grey12,
    taskProjectBackground: colors.lightGrey,
    textColor: colors.blueGrey2
  }
};

export const darkTheme = {
  borders: {
    vulnsCounter: colors.dark7,
    searchBar: colors.grey35,
    tableItem: colors.dark10,
    tableHeaderBottom: colors.dark10,
    tableHeaderTop: colors.dark11,
    tableHeaderDivider: colors.blueGrey,
    importanceMeter: colors.dark13,
    // workspaceItem: colors.blueCerulean,
    workspaceItem: colors.dark13,
    workspaceSelectedItem: colors.blueCerulean,
    workspaceStats: colors.dark9,
    dashboardRow: colors.dark10,
    dashboard: colors.dark7,
    feedTabs: colors.transparent,
    feed: colors.transparent,
    target: colors.blueCerulean,
    calendarDate: colors.dark6,
    actionDropDown: colors.transparent,
    detailModalHeaderIcons: colors.transparent,
    contextMenuSeparator: colors.dark24,
    tool: colors.dark1,
    memoListItem: colors.transparent,
    memoListItemSelected: colors.white,
    detailPanel: colors.transparent,
    queue: colors.dark27,
    scheduleWrapper: colors.transparent,
    severityStack: colors.transparent,
    alanyticsPreviewTableHeader: colors.black5,
    analyticsNewGraphItems: colors.transparent,
    reportTable: colors.dark8,
    expander: colors.transparent,
    loginCard: colors.dark7,
    preferenceMenuLine: colors.dark34,
    dashboardFilter: colors.transparent,
    dashboardFilterDropdown: colors.black,
    vulnChartFlyout: colors.transparent,
    attributeTag: colors.transparent,
    assetFilterList: colors.transparent,
    assignedUserList: colors.transparent,
    dropdown: colors.transparent,
    plannerTabActive: colors.transparent,
    taskUsers: colors.dark10,
    actionButtonsGridCard: colors.black6,
    dashboardTag: colors.transparent,
    reportsDownload: colors.black6,
    reportsBox: colors.black6,
    customAttributeItem: colors.dark8,
    schedulersSectionSelector: colors.dark7,
    schedulersOptions: colors.dark34,
    evidenceImage: colors.dark9
  },
  backgrounds: {
    primary: colors.dark8,
    secondary: colors.grey3,
    tertiary: colors.grey4,
    quaternary: colors.dark31,
    sidebar: colors.dark7,
    sidebarSubMenu: colors.dark9,
    sidebarSubMenuItemHover: colors.dark8,
    card: colors.grey3,
    vulnsCounter: colors.dark7,
    searchBar: colors.grey35,
    table: colors.dark12,
    tableItem: colors.dark8,
    selectedTableItem: colors.dark8,
    hoverTableItem: colors.dark8,
    tableHeader: colors.dark11,
    actionBar: colors.dark7,
    modal: colors.dark7,
    modal2: colors.dark8,
    modal3: colors.dark7,
    modal4: colors.grey58,
    importanceMeter: colors.dark13,
    workspaces: colors.dark7,
    workspaceItem: colors.dark9,
    workspaceStats: colors.dark7,
    dashboard: colors.dark7,
    vulnSummary: colors.dark14,
    vulnChartFlyout: colors.dark14,
    summaryFilter: colors.gray41,
    summaryDropdown: colors.dark16,
    severityChart: colors.dark7,
    dashboardItem: colors.dark9,
    feed: colors.dark7,
    feedTag: colors.dark18,
    target: colors.gray41,
    actionDropDown: colors.dark9,
    actionDropDownHover: colors.dark8,
    detailModal: colors.dark21,
    detailModalHeaderIcons: colors.dark22,
    detailModalHostData: colors.dark23,
    detailModalFields: colors.dark23,
    detailModalTransparency: colors.transparent,
    contextMenu: colors.dark7,
    contextMenuHover: colors.dark8,
    memoListItem: colors.dark7,
    queueHeader: colors.dark26,
    sideModal: colors.dark28,
    scheduleWrapper: colors.dark7,
    listItemOdd: colors.dark12,
    listItemEven: colors.dark20,
    workspaceList: colors.dark20,
    toggleSwitch: colors.dark29,
    analytics: colors.dark7,
    severityStack: colors.dark30,
    alanyticsPreviewTableHeader: colors.dark28,
    analyticsNewGraph: colors.dark28,
    analyticsNewGraphItems: colors.dark30,
    expander: colors.grey62,
    groupTable: colors.dark12,
    groupTableItemOdd: colors.dark8,
    groupTableItemEven: colors.dark12,
    inputPlus: colors.grey35,
    pipelineBox: colors.dark7,
    swagger: colors.dark7,
    loginCard: colors.dark7,
    unreadNotificationsRow: colors.dark36,
    ticketFlap: colors.dark7,
    ticketFlap2: colors.dark8,
    ticketFlapActive: colors.dark8,
    featureflag: colors.grey68,
    impactItem: colors.grey77,
    attributeTag: colors.grey77,
    attributeTagOp: colors.grey93,
    reportTag: colors.grey77,
    reportTagSelected: colors.dark42,
    reportUnselectedInput: colors.dark20,
    assetFilterList: colors.dark30,
    assetFilterListItem: colors.dark8,
    assignedUserListItemHover: colors.dark8,
    customAttributesAdd: colors.dark8,
    customAttributeItem: colors.dark8,
    editModal: colors.dark7,
    editModalContent: colors.dark23,
    dateCustomAttribute: colors.dark20,
    impactsUnselected: colors.grey77,
    impactsSelected: colors.dark8,
    dropdown1: colors.dark26,
    dropdown1Options: colors.dark9,
    dropdown1OptionsHover: colors.dark8,
    sidebarSubMenuHeader: colors.dark9,
    sidebarSubMenuHeaderHover: colors.dark8,
    detailFields: colors.dark20,
    detailFieldsMDButtons: colors.dark20,
    inputsFocus: colors.dark12,
    preferencesSidebar: colors.dark7,
    preferencesContent: colors.dark8,
    preferencesItemHover: colors.dark8,
    runAgentSection: colors.dark7,
    runAgentInput: colors.dark9,
    agentCard: colors.dark9,
    selectedTableNotification: colors.grey62,
    plannerTab: colors.dark9,
    plannerTabActive: colors.dark7,
    milestone: colors.dark39,
    actionButtonsGridCard: colors.dark40,
    workspacesSelectorHover: colors.dark8,
    dashboardTag: colors.dark22,
    reportActiveActionButton: colors.transparent,
    reportsBox: colors.dark20,
    rootBg: colors.dark43,
    attributeIsOneOfVal: colors.grey77,
    schedulerIcon: colors.dark9
  },
  shadows: {
    dashboard: colors.dark15,
    actionDropDown: colors.transparent,
    contextMenu: colors.transparent,
    searchbar: colors.transparent,
    memoListItem: colors.transparent,
    sideModal: colors.transparent,
    scheduleWrapper: colors.transparent,
    preferenciesModal: colors.dark30,
    inputInset: colors.transparent,
    confirmationModal: colors.boxShadow6,
    modal1: colors.transparent,
    inputs: colors.transparent,
    standardDropdown: colors.transparent,
    agentCard: colors.dark38,
    tagsInputAgent: colors.transparent,
    reportsDownload: colors.dark30,
    reportsBox: colors.transparent,
    wsDropdownInset: colors.transparent,
    wsDropdown: colors.transparent,
    schedulersSectionSelector: colors.dark30,
    schedulersSectionSelectorInset: colors.transparent,
    agentSelectorInScheduler: colors.transparent
  },
  icons: {
    actionBar: {
      color: colors.grey43,
      deleteColor: colors.red1,
      background: colors.transparent,
      hover: colors.grey73
    },
    sideBar: {
      color: colors.grey39,
      selected: colors.grey34
    },
    workspaceItem: {
      color: colors.white2
    },
    workspaceRefresh: {
      color: colors.grey39
    },
    dashboard: {
      color: colors.blue8
    },
    detailModalTitle: {
      color: colors.grey16
    },
    detailModal: {
      color: colors.gray42
    },
    logo: {
      color: colors.transparent
    },
    modal: {
      color: colors.blueGrey
    },
    switchTheme: {
      color: colors.dark33
    },
    preferenceMenu: {
      color: colors.grey66
    },
    processingQueue: {
      color: colors.grey71
    },
    processingQueueSuccess: {
      color: colors.black
    },
    removeAssetListItem: {
      color: colors.softBlue
    },
    warningIcon: {
      color: colors.blueGrey
    },
    notifications: {
      color: colors.grey43
    },
    taskUsers: {
      color: colors.grey86
    },
    plannerHover: {
      color: colors.white2
    },
    twoFactor: {
      hover: colors.grey73
    }
  },
  text: {
    primary: colors.white1,
    secondary: colors.grey7,
    link: colors.blue3,
    disabled: colors.grey8,
    vulnsCounter: colors.grey34,
    tab: colors.white2,
    tabSecondary: colors.gray40,
    // tableHeader: colors.blueGrey,
    tableHeader: colors.grey5,
    tableItem: colors.blueGrey,
    modalTitle: colors.white1,
    modalTitle2: colors.blueGrey,
    modalTitle3: colors.gray42,
    modalDescription: colors.grey44,
    tableAssetItem: colors.grey44,
    actionBar: colors.white2,
    workspaceItemTitle: colors.white2,
    workspaceItem: colors.grey44,
    workspaceHighlightStat: colors.grey45,
    workspaceStat: colors.gray42,
    workspaceCriticalStat: colors.warmPurple,
    dashboardTitle: colors.white2,
    dashboardLightText: colors.grey44,
    dashboardDarkText: colors.white2,
    dashboardBlueTitle: colors.white2,
    dashboardTertiaryTitle: colors.white2,
    statusChartReference: colors.white2,
    feedTabActive: colors.white2,
    feedTab: colors.gray40,
    feedHost: colors.white2,
    feedTrigger: colors.grey46,
    feedDate: colors.gray42,
    feedAuthor: colors.grey47,
    feedHighLight: colors.white2,
    target: colors.white2,
    feedHistoryTrigger: colors.grey46,
    emptyState: colors.gray42,
    sectionTitle: colors.gray42,
    monthHeader: colors.white2,
    actionDropDownItem: colors.grey17,
    actionDropDownTitle: colors.white2,
    detailModalTitle: colors.grey16,
    detailModalTabs: colors.blue17,
    detailModalButtons: colors.white2,
    detailModalFields: colors.white2,
    contextMenu: colors.white,
    modalItem: colors.white,
    sidebarSubMenu: colors.white,
    searchBarPlaceholder: colors.grey36,
    searchBar: colors.white2,
    memoListItemTitle: colors.white,
    memoListItemDescription: colors.grey36,
    memoListItemID: colors.blueCerulean,
    sideModaTitle: colors.white2,
    sideModaLabel: colors.grey17,
    scheduleRadioButton: colors.grey19,
    workspaceListItem: colors.white2,
    blackenedModalTitle: colors.blueGrey,
    alanyticsPreviewTableItemHighlight: colors.white2,
    alanyticsPreviewTableItem: colors.greyBlue,
    alanyticsPreviewTableItemTotal: colors.blueGrey2,
    analyticsNewGraphItems: colors.white1,
    reportTableItem: colors.grey44,
    expander: colors.white2,
    settingsBlueTitle: colors.white2,
    tableVulnName: colors.grey44,
    tableVulnNameConfirmed: colors.white2,
    card: colors.gray42,
    loginCard: colors.white1,
    description: colors.white2,
    impactItem: colors.grey93,
    jobDetailTitle: colors.white2,
    jobDetailDescription: colors.grey44,
    attributeTag: colors.black7,
    reportTag: colors.grey93,
    reportTagSelected: colors.white,
    assetFilterListItem: colors.softBlue,
    blueSubtitle: colors.white,
    detailNameHover: colors.greyBlue,
    customAttributeItem: colors.blueGrey,
    customAttributeBulkEdit: colors.white2,
    dateInput: colors.white2,
    contextMenuDelete: colors.red1,
    impactsUnselected: colors.grey78,
    severitiesDropdown: colors.dark10,
    eorDropdown: colors.white2,
    eorDropdownIcon: colors.grey71,
    serviceName: colors.grey44,
    assetName: colors.grey44,
    modalLabel: colors.grey17,
    inputPlaceholderVulns: colors.white2,
    inputFilterGroupedData: colors.blueGrey,
    preferencesMenuHeader: colors.white2,
    preferencesMenuItem: colors.gray40,
    preferencesMenuItemHover: colors.grey83,
    runAgentTitle: colors.white2,
    runAgentSectionTitle: colors.grey34,
    runAgentDescription: colors.grey34,
    runAgentInputLabel: colors.grey34,
    runAgentInput: colors.white2,
    duplicates: colors.white1,
    notificationsBold: colors.grey44,
    notificationsTime: colors.white2,
    plannerTabActive: colors.white2,
    taskPlaceholder: colors.grey85,
    analyticsPlaceholder: colors.grey87,
    analyticsSectionLabel: colors.white2,
    analyticsGraphTickLabels: colors.white2,
    analyticsGraphAxis: colors.greyBlue,
    systemComment: colors.white,
    userComment: colors.white,
    dashboardTag: colors.grey44,
    attributeOpTag: colors.white,
    tableReportsName: colors.white2,
    placeholder: colors.grey85,
    analyticsTable: colors.grey44,
    usersDropdown: colors.white2
  },
  buttons: {
    primary: {
      background: colors.blueCerulean,
      color: colors.white1,
      border: colors.blue3
    },
    secondary: {
      background: colors.grey7,
      color: colors.white1,
      border: colors.grey8
    },
    tertiary: {
      background: colors.transparent,
      colorActive: colors.white2,
      color: colors.white13,
      borderActive: colors.dark6,
      border: colors.grey57
    },
    disabled: {
      background: colors.grey91,
      color: colors.grey92,
      border: colors.grey91
    },
    sidebar: {
      hover: colors.dark8,
      selected: colors.dark8,
      fill: colors.grey39,
      text: colors.white
    },
    custom: {
      background: colors.gray41,
      color: colors.white2,
      disableColor: colors.blueGrey,
      border: colors.gray41,
      backgroundHover: colors.grey52
    },
    assetSelector: {
      background: colors.dark8,
      border: colors.grey48
    },
    pagination: {
      background: colors.grey72
    },
    rule: {
      background: colors.transparent,
      border: colors.grey12,
      color: colors.white2,
      hoverBg: colors.blue11,
      activeBg: colors.softBlue
    },
    disabledSave: {
      background: colors.grey74,
      color: colors.grey75
    },
    addButton: {
      background: colors.gray41,
      color: colors.white2,
      disableColor: colors.blueGrey,
      border: colors.gray41,
      backgroundHover: colors.grey52
    }
  },
  tab: {
    item: colors.dark9,
    selected: colors.dark7,
    feed: colors.dark19,
    feedSelected: colors.dark9
  },
  stroke: colors.white1,
  primary: colors.blue3,
  secondary: colors.darkGray,
  textfield: {
    background: colors.dark20,
    background2: colors.grey35,
    text: colors.white2,
    focus: colors.dark12,
    shadow: colors.transparent
  },
  selector: {
    background: colors.dark9,
    selected: colors.dark8,
    text: colors.grey17
  },
  boxShadow: {
    modal: colors.boxShadow2,
    dropdown: colors.transparent
  },
  gantt: {
    header: colors.dark10,
    project: colors.dark7,
    taskProjectProgress: colors.dark32,
    taskProjectBackground: colors.grey63,
    textColor: colors.white2
  }
};
