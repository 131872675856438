import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-left: 15px;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: ${({ theme }) => theme.text.modalTitle2};
`;
Title.displayName = 'Title';

export const ActionBar = styled.div`
  display: flex;
  .create-button {
    width: 89px;
  }
`;
ActionBar.ActionBar = 'ActionBar';

export const CancelButton = styled.button`
  border: 1px solid ${({ theme }) => theme.buttons.tertiary.borderActive};
  color: ${({ theme }) => theme.text.modalTitle};
  background-color: ${({ theme }) => theme.buttons.tertiary.background};
  margin-right: 13px;
  font-size: 14.5px;
  width: 89px;
  height: 34px;
  border-radius: 3px;
  font-weight: 400;
`;
CancelButton.displayName = 'CancelButton';
