import fetchApi from 'services/api/connector';

// export const fetchSchedules = (page, pageSize, sortBy, sortDirection) => fetchApi(`scheduler?page=${page}&page_size=${pageSize}&sort=${sortBy}&sort_dir=${sortDirection}`, { method: 'GET' });
export const fetchSchedules = (page, pageSize, sortBy, sortDirection) => fetchApi(`agents_schedule?page=${page}&page_size=${pageSize}&sort=${sortBy}&sort_dir=${sortDirection}`, { method: 'GET' });
export const fetchCloudSchedules = (page, pageSize, sortBy, sortDirection) => fetchApi(`cloud_agents_schedule?page=${page}&page_size=${pageSize}&sort=${sortBy}&sort_dir=${sortDirection}`, { method: 'GET' });

export const removeSchedule = (id) => fetchApi(`scheduler/${id}`, { method: 'DELETE' });

export const createSchedule = (agentId, crontabName, active, crontab, description, executorId, parameters, workspaces_names, timezone, host_tag, vuln_tag, service_tag) => fetchApi('agents_schedule', {
  method: 'POST',
  data: {
    id: 0,
    description,
    crontab,
    crontab_name: crontabName,
    timezone: timezone || '',
    agent_id: agentId,
    executor_id: executorId,
    parameters,
    active,
    workspaces_names,
    host_tag,
    vuln_tag,
    service_tag,
    type: 'agent'
  }
});

export const updateSchedule = (scheduleId, agentId, crontabName, active, crontab, description, executorId, parameters, workspaces_names, timezone, host_tag, vuln_tag, service_tag) => fetchApi(`agents_schedule/${scheduleId}`, {
  method: 'PATCH',
  data: {
    id: scheduleId,
    description,
    crontab,
    crontab_name: crontabName,
    timezone: timezone || '',
    agent_id: agentId,
    executor_id: executorId,
    parameters,
    active,
    workspaces_names,
    host_tag,
    vuln_tag,
    service_tag,
    type: 'agent'
  }
});

export const getSchedule = (id) => fetchApi(`agents_schedule/${id}`, { method: 'GET' });

export const changeStatus = (id, active) => fetchApi(`agents_schedule/${id}`, { version: 'v3', method: 'PATCH', data: { active } });

export const fetchCountSchedulers = () => fetchApi('scheduler/count_schedulers', { method: 'GET' });

export const runSchedule = (id) => fetchApi(`agents_schedule/${id}/run`, { method: 'POST' });

export const createCloudSchedule = (agentId, crontabName, crontab, active, description, parameters, workspaces_names, timezone, host_tag, vuln_tag, service_tag) => fetchApi('cloud_agents_schedule', {
  method: 'POST',
  data: {
    id: 0,
    description,
    type: 'cloud_agent',
    crontab,
    crontab_name: crontabName,
    timezone: timezone || '',
    cloud_agent_id: agentId,
    parameters,
    active,
    workspaces_names,
    host_tag,
    vuln_tag,
    service_tag
  }
});

export const updateCloudSchedule = (scheduleId, agentId, crontabName, crontab, active, description, parameters, workspaces_names, timezone, host_tag, vuln_tag, service_tag) => fetchApi(`cloud_agents_schedule/${scheduleId}`, {
  method: 'PATCH',
  data: {
    id: scheduleId,
    description,
    type: 'cloud_agent',
    crontab,
    crontab_name: crontabName,
    timezone: timezone || '',
    cloud_agent_id: agentId,
    parameters,
    active,
    workspaces_names,
    host_tag,
    vuln_tag,
    service_tag
  }
});

export const runCloudSchedule = (id) => fetchApi(`cloud_agents_schedule/${id}/run`, { method: 'POST' });

export const changeStatusCloud = (id, active) => fetchApi(`cloud_agents_schedule/${id}`, { version: 'v3', method: 'PATCH', data: { active } });
