import styled from 'styled-components';
import BlueButton from 'Common/Components/BlueButton';

export const Wrapper = styled.div`
  display: flex;
  flex: 0 0 31px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 21px;
`;
Wrapper.displayName = 'Wrapper';

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 50%;
  & > :not(:last-child) {
    margin-right: 10px;
  }
`;
ButtonsWrapper.displayName = 'ButtonsWrapper';

export const CustomAttributeButton = styled(BlueButton)`
  flex: 1 1 0px;
  padding: 7px 16px;
`;
CustomAttributeButton.displayName = 'CustomAttributeButton';

export const ResetButton = styled(BlueButton)`
  flex: 0 0 31px;
  padding: 7px 16px;
`;
ResetButton.displayName = 'ResetButton';

export const Title = styled.div`
  font-size: 17px;
  font-weight: 600;
  color: ${({ theme }) => theme.text.modalTitle2};
`;
Title.displayName = 'Title';
