import styled from 'styled-components';

export const Wrapper = styled.div`
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid ${({ theme }) => theme.borders.queue};
  max-height: 967px;
`;
Wrapper.displayName = 'Wrapper';

export const FileWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 45px;
  align-items: center;
  width: 100%;
  padding: 10px 25px;
  position: relative;
  & > *:not(:last-child) {
    margin-right: 5px;
  }
`;
FileWrapper.displayName = 'FileWrapper';

export const FileName = styled.div`
  flex: 1 1 0px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: ${({ theme }) => theme.text.detailModalFields};
  font-size: ${({ commandType }) => commandType === 'moveAssets' ? '12px' : '14px'};;
`;
FileName.displayName = 'FileName';

export const Text = styled.div`
  font-size: 13px;
  color: #7b7e8e;
  padding: 15px 0;
  text-align: center;
`;
Text.displayName = 'Text';

export const Commands = styled.div`
  max-height: 182px;
  overflow-y: auto;
  & > *:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.borders.queue};
  }
`;
Commands.displayName = 'Commands';
