import styled from 'styled-components';

export { ReactComponent as PlusIcon } from 'Images/icon-action-bar-plus.svg';

export const Button = styled.div`
    user-select: none;
    height: 24px;
    width: 118px;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-word;
    cursor: pointer;
    display: flex;
    padding: 0px 10px;
    justify-content: center;
    align-items: center;
    font-size: 11.5px;
    font-weight: 500;
    line-height: 34px;
    color: ${({ theme }) => theme.buttons.rule.color};
    border-radius: 2px;
    background-color: ${({ theme }) => theme.buttons.rule.background};
    border: 1px solid ${({ theme }) => theme.buttons.rule.border};

    & > svg {
        height: 11px;
        margin-right: 10px;
        & > path {
        fill: ${({ theme }) => theme.buttons.rule.color};
        }
    }

    &:hover {
        background-color: ${({ theme }) => theme.buttons.rule.hoverBg};
    }

    &:active {
        background-color: ${({ theme }) => theme.buttons.rule.activeBg};
    }
`;
Button.displayName = 'AddButton';
