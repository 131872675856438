import styled, { css } from 'styled-components';
import { ReactComponent as ConfingIcon } from 'Images/command.svg';

export const Wrapper = styled.div`
  width: 100%;
  height: 94%;
  ${({ showNewUI }) => showNewUI && `
    margin-top: 21px;
  `}
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.h2`
font-size: 21.5px;
font-weight: 500;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
color: #1c2243;
margin-bottom:24px;
`;

export const Subtitle = styled.h3`
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
color: #1c2243;
`;

export const Text = styled.p`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.27px;
  color: #53686f;
  ${({ bold }) =>
  bold &&
  css`
  font-weight: 600;
  `}

`;

export const TableHeader = styled.div`
  color: #a6bbce;
  font-size: 10px;
  `;

export const ButtonWrapper = styled.div`
  margin: 20px 0;
  display:flex;
  `;

export const IconWrapper = styled.span`
  margin-right: 7px;
  margin-top: -3px;

`;

export const StyledButton = styled.button`
  all: unset;
  color: ${({ theme }) => theme.buttons.custom.color};
  padding: 9px 11px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 2px;

  ${({ type, theme }) =>
    type === 'white' &&
    css`
      background-color: ${theme.buttons.custom.background};
      border: 1px solid ${theme.buttons.custom.border};
    `}

  ${({ type, theme }) =>
    type === 'grey' &&
    css`
      background-color: ${theme.buttons.custom.background};
      border: 1px solid ${theme.buttons.custom.border};
      margin-left: 10px;    
      display: flex;
      align-items: center;
    `}
`;

export const Config = styled(ConfingIcon)`
  & g {
    fill: ${({ theme }) => theme.buttons.custom.color};
  }
`;

export const Checkbox = styled.input`
  /* Estilos para el checkbox */
  margin-right: 8px;
  /* Otros estilos que desees aplicar */
`;

export const NotifyWrapper = styled.div`
margin-bottom: 32px;
display:flex;
`;
