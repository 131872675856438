import styled from 'styled-components';

export const TabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  margin-bottom: 5px;
  padding-right: 30px;
`;
TabsWrapper.displayName = 'TabsWrapper';

export const TabList = styled.div`
  text-align: left;
  max-width: max-content;
  background-color: ${({ theme }) => theme.backgrounds.editModal};
`;
TabList.displayName = 'TabList';

export const TabListContent = styled.div`
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  overflow: scroll;
  background-color: ${({ theme }) => theme.backgrounds.editModal};
  padding-top: 20px;
`;
TabListContent.displayName = 'TabListContent';

export const Tab = styled.div`
  display: inline-block;
  padding: 18px 18px 12px 18px;
  font-size: 14.5px;
  cursor: pointer;
  color: ${({ theme }) => theme.text.tabSecondary};
  user-select: none;
  background-color: ${({ theme }) => theme.backgrounds.plannerTab};
  position: relative;
  ${({ active, theme }) => active && `
    background-color: ${theme.backgrounds.plannerTabActive};
    color: ${theme.text.plannerTabActive};
    font-weight: 600;
    &:after {
      position: absolute;
      content: "";
      height: 2px;
      width: 100%;
      bottom: 2px;
      left: 0;
      background-color: ${theme.borders.plannerTabActive};
    }
  `}
`;
Tab.displayName = 'Tab';
