import styled from 'styled-components';
import colors from 'Styles/colors';
import { Link } from 'react-router-dom';
import { ReactComponent as _Arrow } from 'Images/arrow_down_drop.svg';

// project List
export const Wrapper = styled.div`
  width: 490px;
  overflow: scroll;

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;

// Project
export const ProjectWrapper = styled.div`
  width: 100%;
  margin-bottom: 1px;
`;

export const ProjectHeader = styled.div`
  cursor: pointer;
  background-color: ${({ theme }) => theme.borders.memoListItem};
  height: 49px;
  padding: 0 53px;
  display:flex;
  justify-content: space-between;
  align-items: center;
`;

export const ProjectTitle = styled.div`
  font-size: 14.5px;
  font-weight: 600;
  color: ${({ theme }) => theme.textfield.text};
  width: 100%;
`;
export const Arrow = styled(_Arrow)`
  ${({ isOpen }) => !isOpen && 'transform: rotate(-90deg)'};
`;

export const EmptyWrapper = styled.div`
  /* background: #ffffff; */
  background: transparent;
  width: 100%;
  height: 60px;
  font-size: 13px;
  color: #63758d;
  display:flex;
  justify-content: center;
  align-items: center;
`;

export const Blue = styled(Link)`
  color: ${colors.blueCerulean};
  padding-left: 3px;
`;
Blue.displayName = 'Blue';
