import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  margin-top: 19px;
  background-color: ${({ theme }) => theme.backgrounds.runAgentSection};
  padding: 27px 21px;
  border-radius: 4px;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  user-select: none;
  font-size: 17px;
  font-weight: 600;
  color: ${({ theme }) => theme.text.runAgentSectionTitle};
  margin-bottom: 14px;
`;
Title.displayName = 'Title';

export const Subtitle = styled.div`
  user-select: none;
  margin-bottom: 20px;
  font-size: 12.5px;
  color: ${({ theme }) => theme.text.runAgentDescription};
`;
Subtitle.displayName = 'Subtitle';

export const Input = styled.input`
  height: 34px;
  padding: 9px 9px 7px 10px;
  box-shadow: ${({ theme }) => `inset 0 -1px 0 0 ${theme.shadows.tagsInputAgent}`};
  background-color: ${({ theme }) => theme.backgrounds.runAgentInput};
  color: ${({ theme }) => theme.text.runAgentInput};
  border: 0;
  width: 100%;
  margin-right: 20px;
  
  &::placeholder {
    font-size: 14.5px;
    color: ${colors.grey17};
  }
`;
Input.displayName = 'Input';

export const Entities = styled.div`
  display: flex;
`;
Entities.displayName = 'Entities';
