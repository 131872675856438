/* eslint-disable no-unused-vars */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import Circle from 'Common/Components/Circle';
import TinyCircle from 'Common/Components/TinyCircle';
import colors from 'Styles/colors';
import { selectWorkspaces } from 'store/Faraday/selectors';
import { selectWorkspace } from 'store/Pipelines/selectors';
import { setTarget } from 'store/Pipelines/actions';
import { isEmpty } from 'lodash';
import Step from '../Step';
import {
  Wrapper, Header, StepContainer,
  HeaderContainer, Title, Description, SelectTarget
} from './styled';
import sortArrayByName from 'Common/Functions/sortArrayByName';
import StandardDropdown from 'Common/Components/StandarDropdown';
import { dropdownStyle } from '../JobSequence/NewJob/styled';

const Target = () => {
  const dispatch = useDispatch();
  const workspaces = useSelector((state) => selectWorkspaces(state));
  const activeWorkspaces = workspaces && workspaces.filter((ws) => ws.active);
  const workspacesOptions = !isEmpty(activeWorkspaces)
    ? sortArrayByName(activeWorkspaces.map((el) => ({ name: el.name, desc: el.name })))
    : [];
  workspacesOptions.unshift({ name: '(None)', desc: '(None)' });
  const value = useSelector((state) => selectWorkspace(state));
  const bigCircle = <Circle color={ colors.blueGrey } />;
  const smallCircle = <TinyCircle color={ colors.blueGrey } />;
  const intl = useIntl();

  const options = !isEmpty(workspaces)
    ? workspaces.map((el) => ({ value: el.name, label: el.name }))
    : [];

  const handleChange = (field, selectedOption) => {
    dispatch(setTarget(selectedOption));
  };

  return (
    <Wrapper>
      <Header>
        <StepContainer>
          <Step icon={ bigCircle } select />
        </StepContainer>
        <HeaderContainer>
          <Title>{ intl.formatMessage({ id: 'pipelines.detail.target.title' }) }</Title>
          <Description>{ intl.formatMessage({ id: 'pipelines.detail.target.description' }) }</Description>
        </HeaderContainer>
      </Header>
      <SelectTarget>
        <StepContainer>
          <Step icon={ smallCircle } last select />
        </StepContainer>
        <StandardDropdown customStyle={ dropdownStyle } field="target" placeholder="Select Job" options={ options.map((el) => ({ name: el.value, desc: el.label })) } updateValue={ handleChange } />
      </SelectTarget>
    </Wrapper>
  );
};
export default Target;
