import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import ExpandableWrapper from '../ExpandableWrapper';
import {
  Wrapper, Header, Title, Line,
  Content, Row, Col,
  dropdownStyle, Tag,
  TabWrapper, Tab, ArrowRight
} from './styled';
import Select from 'react-select';
import { selectSeveritiesOutgoing, selectOptionsData, selectSeveritiesParsed } from 'store/Settings/serviceDesk/selectors';
import { setSeveritiesType } from 'store/Settings/serviceDesk/actions';
import { DROPDOWN_ENDPOINTS } from 'store/Settings/serviceDesk/constants';
import useTheme from 'Hooks/UseTheme';

const HeaderChildren = () => {
  return (
    <TabWrapper>
      <Tab active >Outgoing</Tab>
    </TabWrapper>
  );
};

const Severity = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const severities = useSelector((state) => selectOptionsData(state, DROPDOWN_ENDPOINTS.priority));
  const options = severities.map((s) => ({ label: s, value: s }));
  // const options = [{ label: 'High', value: 'high' }, { label: 'Medium', value: 'medium' }, { label: 'Low', value: 'low' }]
  const severitiesParsed = useSelector(selectSeveritiesParsed);
  const severitiesOutgoing = useSelector(selectSeveritiesOutgoing);
  const { themeStyles } = useTheme();
  const onSelect = (sev, value) => {
    const obj = { [sev.name]: value };
    dispatch(setSeveritiesType(obj));
  };

  return (
    <ExpandableWrapper id="service-desk-basic-setup-severity" expanded title={ intl.formatMessage({ id: 'preferences.ticketingTools.serviceDesk.basicSetup.severity' }) } headerChildren={ <HeaderChildren /> }>

      <Wrapper>

        <Header>
            <Title>{ intl.formatMessage({ id: 'preferences.ticketingTools.serviceDesk.basicSetup.severity.col1' }) }</Title>
            <Line />
            <Title>{ intl.formatMessage({ id: 'preferences.ticketingTools.serviceDesk.basicSetup.severity.col3' }) }</Title>
        </Header>
        <Content>
          { severitiesParsed.map((sev) => (
            <Row key={ sev.name }>
              <Col>{ sev.name }</Col>
              <Col>{ severitiesOutgoing?.[sev.name.toLowerCase()] ? <Tag><ArrowRight /></Tag> : null }</Col>
              <Col>
                <Select
                  value={ { label: severitiesParsed.find((item) => sev.name === item.name)?.value, value: severitiesParsed.find((item) => sev.name === item.name)?.value } }
                  onChange={ (item) => onSelect(sev, item.value) }
                  options={ options }
                  styles={ dropdownStyle(themeStyles) }
                  isSearchable={ false }
                />
              </Col>
            </Row>
          ))}
        </Content>
      </Wrapper>
    </ExpandableWrapper>
  );
};

export default Severity;
