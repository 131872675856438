import styled from 'styled-components';

const Button = styled.button`
  font-size: 11.5px;
  font-weight: 500;
  color: ${({ theme }) => theme.buttons.addButton.color};
  height: 34px;
  width: 155px;
  padding: 0 11px;
  border-radius: 2px;
  border: solid 1px ${({ theme }) => theme.buttons.custom.border};
  background-color: ${({ theme }) => theme.buttons.addButton.background};
  cursor: pointer;
  & > svg {
    height: 11px;
    margin-right: 10px;
     & > path {
      fill: ${({ theme }) => theme.buttons.addButton.color};
    }
  }
  &:hover {
    background-color: ${({ theme }) => theme.buttons.addButton.backgroundHover};
  }

  &:active {
    background-color: ${({ theme }) => theme.buttons.addButton.backgroundHover};
  }
`;
export default Button;
