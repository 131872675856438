import { ThemeContext } from 'Context';
import React, { Component, useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { logOut } from 'Screens/Login/actions/Actions';

const LogOutForcedWrapper = ({ logOut }) => {
  const { resetTheme } = useContext(ThemeContext);
  useEffect(() => {
  }, [resetTheme]);

  return <LogOutForced logOut={ logOut } />;
};

class LogOutForced extends Component {
  constructor (props) {
    super(props);
    this.state = {};
  }

  componentDidMount () {
    const { logOut } = this.props;
    logOut();
  }

  render () {
    return (
      <></>
    );
  }
}

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => ({
  logOut: () => {
    dispatch(logOut());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(LogOutForcedWrapper);
