import styled from 'styled-components';
import { ReactComponent as _CopyLink } from 'Images/copy_link.svg';

export const CopyLinkWrapper = styled.div`
  position: relative;
  cursor: pointer;
  width: 34px;
  height: 34px;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(28, 34, 67, 0.03);
  border: solid 1px ${({ theme }) => theme.borders.detailModalHeaderIcons};
  background-color: ${({ theme }) => theme.backgrounds.detailModalHeaderIcons};
  margin-left: auto;
  &:hover {
    background-color: #f1f4f7 ;
  }
  &:active{
    background-color: #63758d ;
    & > svg {
      & > path {
        fill: #fff;
      }
    }
  }
  
`;
CopyLinkWrapper.displayName = 'CopyLink';

export const CopyLinkIcon = styled(_CopyLink)`
  position: absolute;
  cursor: pointer;
  left: 8px;
  top: 8px;
  path {
    fill: ${({ theme }) => theme.icons.detailModal.color};
    stroke: ${({ theme }) => theme.icons.detailModal.color};
    stroke-width: 0.5;
  }
`;
CopyLinkIcon.displayName = 'CopyLinkIcon';
