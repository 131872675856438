/* eslint-disable react/jsx-max-depth */
import React from 'react';
import { Col, Row } from 'Common/Components/Grid/index';
import { TextWrapper, Text } from '../styled';
import Buttons from './Buttons';
import Radio from './Radio';

const Item = ({ name, projectKey }) => {
  return (
    <>
      <Row onClick={ null }>
        <TextWrapper width="25%"><Col height={ '39px' }><Radio name={ name } projectKey={ projectKey } /></Col></TextWrapper>
        <TextWrapper width="28.5%"><Col height={ '39px' }><Text>ISSUE TYPE</Text></Col></TextWrapper>
        <Buttons projectKey={ projectKey } />
      </Row>
    </>
  );
};

export default Item;
