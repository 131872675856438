/* eslint-disable react/no-children-prop */
import React from 'react';
import ModalWrapper from 'Common/Components/ModalWrapper';
import get from 'lodash/get';
import {
  ModalTitle, Title, BodyText, ModalContent, ButtonsContainer,
  ConfirmButton, CancelButton, Warning, ModalBody, BoldText
} from './styled';
import useKeyPress from 'Hooks/useKeyPress';

const DeleteConfirmationModal = ({
  showDeleteConfirmation, selectAll, vulnsSelected,
  deleteVulns, hideModalDelete, vulnsCount
}) => {
  const isBulk = vulnsSelected.length > 1;
  const hasDuplicates = vulnsSelected.some((v) => get(v, 'is_main', false));
  const count = selectAll ? vulnsCount : vulnsSelected.length;

  const bulk = `You’re deleting ${count} Vulnerability(s)${hasDuplicates ? ', ' : ''}`;
  const single = `A vulnerability will be deleted${hasDuplicates ? ', ' : ''}`;
  const confirmationText = '. This action cannot be undone. Are you sure you want to proceed?';

  const onCancel = () => hideModalDelete();
  const onDelete = () => deleteVulns();

  useKeyPress(() => {
    onCancel();
  }, ['Escape']);

  useKeyPress(() => {
    onDelete();
  }, ['Enter']);

  if (!showDeleteConfirmation) return null;
  return (
    <ModalWrapper>
      <ModalContent>
        <ModalTitle>
          <Warning />
          <Title>Delete Vulnerability(s)</Title>
        </ModalTitle>
        <ModalBody>
          <BodyText>
            <span children={ isBulk ? bulk : single } />
            <BoldText children={ hasDuplicates ? `including ${isBulk ? 'their' : 'its'} duplicates` : '' } />
            <span children={ confirmationText } />
          </BodyText>
        </ModalBody>
        <ButtonsContainer>
          <CancelButton onClick={ onCancel }>
            Cancel
          </CancelButton>
          <ConfirmButton onClick={ onDelete }>
            Delete
          </ConfirmButton>
        </ButtonsContainer>
      </ModalContent>
    </ModalWrapper>
  );
};

export default DeleteConfirmationModal;
