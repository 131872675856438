import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  height: 42px;
  width: 100%;
  justify-content: center;
  user-select: none;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.textfield.text};
  background-color: ${({ theme }) => theme.backgrounds.sideModal};
  font-size: 17.5px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
`;
Title.displayName = 'Title';
