import React from 'react';
import { Wrapper, RegenerateIcon, Text } from './styled';
import { useDispatch, useSelector } from 'react-redux';
import { getJiraProjects } from 'store/Settings/jiraActions';
import { selectIsFetching } from 'store/Settings/jiraSelectors';

const Button = () => {
  const dispatch = useDispatch();
  const getProjetcs = () => dispatch(getJiraProjects());
  const isFetching = useSelector(selectIsFetching);

  return (
    <Wrapper onClick={ getProjetcs } disabled={ isFetching }>
      <RegenerateIcon />
      <Text>Fetch Projects</Text>
    </Wrapper>
  );
};

export default Button;
