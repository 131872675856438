import styled from 'styled-components';

export const ContainerBox = styled.div`
  user-select: none;
  background: linear-gradient(143deg, rgba(255,255,255,1) 0%, rgba(241,245,248,1) 96%);
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.backgrounds.modal2};
`;
ContainerBox.displayName = 'ContainerBox';

export const Box = styled.div`
  position: relative;
  width: ${(props) => (props.width ? `${props.width}` : '450px')};
  height: ${(props) => (props.isSsoEnabled ? '500px' : '480px')};
  box-shadow: ${({ theme }) => theme.boxShadow.modal};
  border: ${({ theme }) => `1px solid ${theme.borders.loginCard}`};
  background-color: ${({ theme }) => theme.backgrounds.loginCard};
  margin-left: auto;
  margin-right: auto;
  padding-top: 55px;
  padding-left: 55px;
  padding-right: 55px;
  padding-bottom: ${(props) => (props.isSsoEnabled ? '32px' : '55px')};
`;
Box.displayName = 'Box';

export const LoginLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 37px;
  width: 348px;
  display: flex;
  justify-content: left;
`;
LoginLogoContainer.displayName = 'LoginLogoContainer';

export const Logo = styled.img`
  user-select: none;
  width: 97px;
`;
Logo.displayName = 'Logo';

export const Title = styled.a`
  display: flex;
  font-size: 21.5px;
  font-weight: 600;
  line-height: 1.16;
  text-align: left;
  color: ${({ theme }) => theme.text.modalTitle};
  text-decoration:none;
  :hover{
    color: inherit;
    text-decoration:none;
  }
`;
Title.displayName = 'Title';

export const SubTitle = styled.div`
  font-size: 12.5px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #7a7e8f;
  margin-top: 14px;
`;
SubTitle.displayName = 'SubTitle';

export const Button = styled.button`
  width: 89px;
  height: 34px;
  border-radius: 2px;
  background-color: #0082ed;
  border: none;
  color: white;
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.21;
  letter-spacing: normal;
  color: #ffffff;
  float: right;
  margin-top: ${(props) => (props.top ? `${props.top}` : '34px')};
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
`;
Button.displayName = 'Button';

export const BackButton = styled(Button)`
  float: left;
  border: solid 1px ${({ theme }) => theme.buttons.custom.border};
  color: ${({ theme }) => theme.buttons.custom.color};
  background-color: ${({ theme }) => theme.buttons.custom.background};
`;
BackButton.displayName = 'BackButton';

export const ButtonsContainer = styled.div`
  flex: 0 0 34px;
  margin-top: 22px;
  & > * {
    margin-top: 0px;
  }
`;
ButtonsContainer.displayName = 'ButtonsContainer';

export const ErrorMessage = styled.div`
    font-size: 14.5px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: #dc4a4a;
    float: left;
    margin-top: 45px;

`;
ErrorMessage.displayName = 'ErrorMessage';

export const ButtonWithoutBackground = styled.button`
  width: 149px;
  height: 34px;
  border-radius: 2px;
  background-color: #ffffff;
  border: 1px solid #0082ed;
  color: white;
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.21;
  letter-spacing: normal;
  color: #0082ed;
  margin-top: ${(props) => (props.top ? `${props.top}` : '34px')};
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  float: left;  
`;
ButtonWithoutBackground.displayName = 'ButtonWithoutBackground';

export const PullLeft = styled.div`
  display: flex;
  align-content: flex-start;
`;
PullLeft.displayName = 'PullLeft';
