import styled from 'styled-components';

export const TableWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.borders.actionBar};
`;
TableWrapper.displayName = 'TableWrapper';

export const TypeColumn = styled.div`
  color: ${({ theme }) => theme.text.runAgentTitle};
`;
TypeColumn.displayName = 'TypeColumn';
