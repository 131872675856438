import React, { useContext } from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { severitySelect } from 'Common/styles/style';
import { updateVulnTemplate } from 'Screens/Contextualization/KnowledgeBase/actions/Actions';
import SeverityIcon from 'Common/Components/SeverityIcon';
import { selectVulnTemplateDetail } from 'store/KnowledgeBase/selectors';
import { capitalize } from 'utils/stringUtils';
import get from 'lodash/get';
import { severities } from 'Screens/Contextualization/Constants';
import { Wrapper, SeverityName } from 'Screens/Contextualization/Manage/components/VulnerabilityDetail/components/Tabs/components/GeneralTab/components/ActionBar/components/SeverityPicker/styled';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_VULN_TEMPLATE, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { ThemeContext } from 'Context';

const SeverityItem = ({ title }) => (
  <div style={ { display: 'flex', alignItems: 'center' } }>
    <SeverityIcon type={ title.charAt(0).toUpperCase() }>{title.charAt(0).toUpperCase()}</SeverityIcon>
    <SeverityName>{ capitalize(title) }</SeverityName>
  </div>
);

const SeverityPicker = () => {
  const dispatch = useDispatch();
  const vulnTemplate = useSelector(selectVulnTemplateDetail);
  const canUpdate = useSelector((state) => selectAllowedByRole(state, FEATURE_VULN_TEMPLATE, PERMISSION_TYPES.UPDATE));
  const id = get(vulnTemplate, '_id', 0);
  const severity = get(vulnTemplate, 'exploitation', '');
  const value = {
    label: severity,
    value: severity
  };

  const { themeStyles } = useContext(ThemeContext);

  return (
    <Wrapper isDisabled={ !canUpdate }>
      <Select
        styles={ severitySelect(themeStyles) }
        isSearchable={ false }
        value={ value }
        isDisabled={ !canUpdate }
        onChange={ (e) => dispatch(updateVulnTemplate(id, { exploitation: e.value })) }
        options={ severities.map((element) => ({ label: <SeverityItem title={ element.name } />, value: element.name })) }
      />
    </Wrapper>
  );
};

export default SeverityPicker;
