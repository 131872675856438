import React from 'react';
import PropTypes from 'prop-types';
import CreateButton from './components/CreateButton';
import DeleteButton from './components/DeleteButton';
import EditButton from './components/EditButton';
import { Wrapper, Line, PaginationWrapper } from './styled';
import SectionSelector from './components/SectionSelector';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectSchedulersPage, selectSchedulersPageSize,
  selectSelectedSection, selectSchedulersTotal
} from 'store/Schedule/selectors';
import { setPage } from 'store/Schedule/actions';
import Pagination from 'Common/Components/Pagination';
import selectModal from 'store/modals/selectors';
import { MODAL_SCHEDULE_DELETE } from 'store/modals/modals';
import DeleteConfirmationModal from './components/DeleteConfirmationModal';

const ActionBar = () => {
  const dispatch = useDispatch();
  const selectedSection = useSelector(selectSelectedSection);
  const page = useSelector((state) => selectSchedulersPage(state, selectedSection));
  const rowsPerPage = useSelector((state) => selectSchedulersPageSize(state, selectedSection));
  const total = useSelector((state) => selectSchedulersTotal(state, selectedSection));
  const onPrev = () => dispatch(setPage(page - 1, selectedSection));
  const onNext = () => dispatch(setPage(page + 1, selectedSection));
  const showDeleteConfirmation = useSelector((state) => selectModal(state, MODAL_SCHEDULE_DELETE));
  return (
    <Wrapper>
      <SectionSelector />
      <Line />
      <CreateButton />
      <Line />
      <EditButton />
      <DeleteButton />
      <PaginationWrapper>
        <Pagination offset={ page } limit={ rowsPerPage } total={ total } onPrev={ onPrev } onNext={ onNext } />
      </PaginationWrapper>
      { showDeleteConfirmation ? <DeleteConfirmationModal /> : null }
    </Wrapper>
  );
};

ActionBar.propTypes = {
  toggleModalCreateSchedule: PropTypes.func.isRequired,
  toggleModalEditSchedule: PropTypes.func.isRequired
};

export default ActionBar;
