import styled from 'styled-components';

export const Wrapper = styled.div`
  height: calc(100% - 145px);
  padding-bottom: 5px;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  font-size: 13.5px;
  color: ${({ theme }) => theme.textfield.text};
  padding: 17px;
  background: ${({ theme }) => theme.backgrounds.ticketFlap};
  width: 116px;
`;
Title.displayName = 'Title';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.backgrounds.ticketFlap};
  height: calc(100% - 55px);
  padding: 18px 0 0 16px;
`;
Content.displayName = 'Content';

export const Subtitle = styled.h5`
  font-size: 13.5px;
  font-weight: 400;
  color: ${({ theme }) => theme.textfield.text};
`;
Title.displayName = 'Title';
