import styled from 'styled-components';
import { ReactComponent as ArrowExpanded } from 'Images/wf_arrow_expand.svg';
import { ReactComponent as ArrowCollapsed } from 'Images/wf_arrow_collapsed.svg';
import { ReactComponent as TrashBin } from 'Images/tool_trash.svg';

export const Label = styled.div`
    cursor: default;
    margin: auto 0px auto 0px;
    text-align: left;
    font-size: 14.5px;
    font-weight: 500;
    color: ${({ theme }) => theme.text.expander};
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    user-select: none;
`;
Label.displayName = 'Label';

export const SubtitleLabel = styled.div`
    cursor: default;
    margin: auto 0px 15px 0px;
    text-align: left;
    font-size: 13.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #63758d;
`;
SubtitleLabel.displayName = 'SubtitleLabel';

export const ExpandedIcon = styled(ArrowExpanded)`
    display: none;
    margin: auto 0px auto auto;
`;
ExpandedIcon.displayName = 'ExpandedIcon';

export const CollapsedIcon = styled(ArrowCollapsed)`
    display: none;
    margin: auto 0px auto auto;
`;
CollapsedIcon.displayName = 'CollapsedIcon';

export const RemoveIcon = styled(TrashBin)`
    display: none;
    margin: auto 16px auto 16px;
`;
RemoveIcon.displayName = 'RemoveIcon';

export const ChildContainer = styled.div`
    display: ${({ isExpanded }) => (isExpanded ? 'block' : 'none')};
    padding: 0px 20px 17px 20px;
`;
ChildContainer.displayName = 'HeaderContainer';
