import styled from 'styled-components';
import { ReactComponent as DeleteIconSvg } from 'Images/icon-action-bar-trash.svg';
import { ReactComponent as MarkAsReadIconSvg } from 'Images/mark_read.svg';

export const Wrapper = styled.div`
  display: flex;
  height: 50px;
  background-color: ${({ theme }) => theme.backgrounds.actionBar};
  align-items: center;
  padding: 0 18px;
`;

export const DeleteIcon = styled(DeleteIconSvg)`
  height:18px;
  margin-right: 14px;
  g > path {
    fill: ${({ theme }) => theme.icons.actionBar.deleteColor};
  }
`;

export const MarkAsReadIcon = styled(MarkAsReadIconSvg)`
  height:16px;
  margin-right: 10px;
  g > path {
    fill: ${({ theme }) => theme.icons.actionBar.color};
  }
`;

export const PaginationWrapper = styled.div`
  margin-left: auto;
`;
PaginationWrapper.displayName = 'Wrapper';
