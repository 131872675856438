import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MODAL_UPGRADE_LICENSE, MODAL_USERS_ASSIGN_WS } from 'store/modals/modals';
import upgradeLicenseImage from 'Images/users-license.png';
import ReduxModal from 'Common/Components/ReduxModal';
import UpgradeLicense from 'Common/Components/UpgradeLicense';
import { PERMISSION_TYPES, FEATURE_USERS } from 'Hooks/usePermission/permissions';
import { selectAllowedByFeature, selectAllowedByRole } from 'Hooks/usePermission/selector';
import { ACTIONS } from 'tracking/GA/constants';
import NotFound from 'Screens/Contextualization/Errores/NotFound';
import { resetFilters } from 'store/Filters/actions';
import { getData } from './actions/Actions';
import { TableWrapper } from './components/Table/styled';
import Table from './components/Table';
import ActionBar from './components/ActionBar';
import Wrapper from './styled';
import AssignWsModal from './components/ActionBar/components/AssignWsModal';
import selectModal from 'store/modals/selectors';
import { getWorkspaces } from 'Screens/Workspaces/actions/Actions';
import UserDetail from './components/UserDetail';
import { selectShowUserDetail } from 'store/Users/selectors';

const Users = () => {
  const isAllowedByFeature = useSelector((state) => selectAllowedByFeature(state, FEATURE_USERS, PERMISSION_TYPES.READ));
  const isAllowedByRole = useSelector((state) => selectAllowedByRole(state, FEATURE_USERS, PERMISSION_TYPES.CREATE));
  const showAssigWsModal = useSelector((state) => selectModal(state, MODAL_USERS_ASSIGN_WS));
  const showUserDetail = useSelector(selectShowUserDetail);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getData());
    dispatch(getWorkspaces(false, false));
  }, [dispatch]);

  useEffect(() => () => dispatch(resetFilters('users')), [dispatch]);

  const isAllowed = isAllowedByFeature && isAllowedByRole;

  if (!isAllowed) return <NotFound />;
  return (
    <Wrapper>
      <ActionBar />
      <TableWrapper>
      { showUserDetail && <UserDetail /> }
        <Table />
        <ReduxModal id={ MODAL_UPGRADE_LICENSE }>
          <UpgradeLicense id={ MODAL_UPGRADE_LICENSE } image={ upgradeLicenseImage } descriptionIntlId="upgradeLicense.user.description" trackingShow={ ACTIONS.showUser } trackingClick={ ACTIONS.clickUser } />
        </ReduxModal>
      </TableWrapper>
      { showAssigWsModal ? <AssignWsModal /> : null }
    </Wrapper>
  );
};

export default Users;
