import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { getGitLabSettings, save, TOOL_NAME } from 'store/Settings/gitLabActions';
import { fetchActiveConfig, getTemplates, updateActiveConfigNew } from 'store/Settings/settingsActions';
import { Wrapper, GitLabLogo } from './styled';
import {
  selectAccessToken, selectProject, selectUrl, selectTemplate
} from 'store/Settings/gitLabSelectors';
import { selectActiveConfig } from 'store/Settings/settingsSelectors';
import Header from '../../../Header';
import { StatusLabel, Title, Toolbar } from 'Screens/Contextualization/Settings/common-styled';
import Checkbox from 'Common/Components/Checkbox/styled';
import MainSettings from './components/MainSettings';
import { selectAllowedByFeature } from 'Hooks/usePermission/selector';
import { FEATURE_TICKETING_GITLAB, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import UpgradeLicense from '../UpgradeLicense';
import { MODAL_UPGRADE_LICENSE } from 'store/modals/modals';
import upgradeLicenseImage from 'Images/ticketing-license.png';
import { ACTIONS } from 'tracking/GA/constants';

const GitLab = ({ onClose }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const configActive = useSelector(selectActiveConfig);
  const isEnabledGitlab = useSelector((state) => selectAllowedByFeature(state, FEATURE_TICKETING_GITLAB, PERMISSION_TYPES.CREATE));

  // obtengo los datos del servidor
  useEffect(() => {
    dispatch(fetchActiveConfig());
    dispatch(getTemplates(TOOL_NAME));
    dispatch(getGitLabSettings());
  }, [dispatch]);

  const url = useSelector(selectUrl);
  const project = useSelector(selectProject);
  const accessToken = useSelector(selectAccessToken);
  const template = useSelector(selectTemplate);

  const allowedToSave = () => url &&
    project &&
    template &&
    accessToken;

  const onChange = () => {
    if (allowedToSave()) dispatch(updateActiveConfigNew('gitlab'));
  };

  const accept = () => {
    if (isEnabledGitlab) dispatch(save());
    onClose();
  };

  return (
    <Wrapper>
      <Header onAccept={ accept } onClose={ onClose } disabled={ isEnabledGitlab && !allowedToSave() } />
      { isEnabledGitlab
        ? <>
            <Title>{ intl.formatMessage({ id: 'preferences.ticketingTools.title' }) }</Title>
            <Toolbar>
              <GitLabLogo />
              <StatusLabel isActive={ configActive.gitlab.active }>{ configActive.gitlab.active ? 'Active' : 'Inactive' }</StatusLabel>
              <Checkbox checked={ configActive.gitlab.active } onClick={ onChange } disabled={ !configActive.gitlab.available } />
            </Toolbar>
            <MainSettings />
          </>
        : <UpgradeLicense id={ MODAL_UPGRADE_LICENSE } image={ upgradeLicenseImage } descriptionIntlId="upgradeLicense.ticketing.description" trackingShow={ ACTIONS.showSettings } trackingClick={ ACTIONS.clickSettings } />
      }
    </Wrapper>
  );
};
export default GitLab;
