import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FEATURE_USERS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { selectUserSelected } from 'store/Users/selectors';
import { TwoFactor } from '../../styled';
import IconButton from 'Common/Components/IconButton';
import ModalWarning from 'Common/Components/ModalWarning';
import { useIntl } from 'react-intl';
import useClickOutside from 'Hooks/useClickOutside';
import { Wrapper, Dropdown } from './styled';
import MenuItem from 'Common/Components/ContextMenu/components/MenuItem';
import { update2faBulk } from 'Screens/Contextualization/Users/actions/Actions';

const Item = ({ title, option, handleDropdown, userSelected }) => {
  const selectedUser = userSelected[0];
  const dispatch = useDispatch();
  const oneUserSelected = userSelected.length === 1;

  const handleClick = () => {
    dispatch(update2faBulk(option));
    handleDropdown();
  };

  return (
    <MenuItem
      isSelected={ oneUserSelected && selectedUser.status_2fa === title.toLowerCase() }
      title={ title }
      onClick={ handleClick }
    />
  );
};

const TwoFactorAuth = () => {
  const [showWarning, setShowWarning] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const userSelected = useSelector(selectUserSelected);
  const allowedByRole = useSelector((state) => selectAllowedByRole(state, FEATURE_USERS, PERMISSION_TYPES.UPDATE));
  const intl = useIntl();
  const message = intl.formatMessage({ id: 'modalWarning.selectOneOrMore' }, { entity: 'user' });
  const themeMode = localStorage.getItem('theme');

  const dropdownRef = useRef();
  useClickOutside(dropdownRef, () => setShowDropdown(false));

  const handleDropdown = () => setShowDropdown(!showDropdown);

  const handleClick = () => {
    if (allowedByRole) {
      if ((userSelected.length > 0)) handleDropdown();
      else setShowWarning(true);
    }
  };

  const onClose = () => setShowWarning(false);

  return (
    <Wrapper ref={ dropdownRef } themeMode={ themeMode }>
      <IconButton icon={ <TwoFactor disabled={ !allowedByRole } themeMode={ themeMode } /> } title="2F Authentication" onClick={ handleClick } disabled={ !allowedByRole } />
      {showDropdown && (
        <Dropdown>
          <Item title="Enabled" option="disabled" handleDropdown={ handleDropdown } userSelected={ userSelected } />
          <Item title="Disabled" option="enabled" handleDropdown={ handleDropdown } userSelected={ userSelected } />
        </Dropdown>
      )}
      { showWarning && <ModalWarning onCloseCallback={ onClose } title="2F Authentication" description={ message } /> }
    </Wrapper>
  );
};

export default TwoFactorAuth;
