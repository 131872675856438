import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import StandardTextField from 'Common/Components/StandardTextField/index';
import BlueButton from 'Common/Components/BlueButton/index';
import OutlinedButton from 'Common/Components/OutlinedButton/index';
import { CUSTOM_ATTRIBUTE_TYPES } from 'Screens/Contextualization/Manage/components/CustomAttributesModal/constants';
import { Wrapper, Buttons, Content, Container } from './styled';
import './styled.css';

const IntegerCustomField = ({
  field, onCancel, onSave, vuln, initialValue
}) => {
  const [value, setValue] = useState(initialValue);
  const [editMode, setEditMode] = useState(!initialValue);
  useEffect(() => {
    if (value !== initialValue) {
      setValue(initialValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = () => {
    onSave(vuln, field.field_name, value);
    setEditMode(false);
    if (!value) onCancel();
  };
  const handleCancel = () => {
    setEditMode(false);
    if (initialValue) {
      setValue(initialValue);
    } else {
      onCancel();
    }
  };

  const hasNonNumericValues = () => {
    const pattern = /^\d+$/;
    return value.length > 0 && !pattern.test(value);
  };

  const error = hasNonNumericValues();

  return (
    <Wrapper>
      <Container>
        {editMode
          ? (
            <StandardTextField
              className='autoHeightText'
              name="custom_field_name"
              value={ value }
              error={ error }
              errorMessage="Use numbers only"
              onChange={ setValue }
            />
            )
          : <Content onClick={ () => setEditMode(true) }>{initialValue}</Content> }
      </Container>
      {editMode &&
      (
      <Buttons>
        <OutlinedButton label="Cancel" onClick={ handleCancel } />
        <BlueButton label="Save" disabled={ error } onClick={ handleSave } />
      </Buttons>
      )}
    </Wrapper>
  );
};

IntegerCustomField.defaultProps = {
  initialValue: null
};

IntegerCustomField.propTypes = {
  field: PropTypes.shape({
    field_display_name: PropTypes.string,
    field_type: PropTypes.oneOf([CUSTOM_ATTRIBUTE_TYPES.INTEGER])
  }).isRequired,
  vuln: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  initialValue: PropTypes.number
};

export default IntegerCustomField;
