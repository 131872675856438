import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import { useIntl } from 'react-intl';
import { selectGraphsSelected } from 'store/Analytics/selectors';
import { copyGraph } from 'store/Analytics/actions';
import IconButton from 'Common/Components/IconButton';
import { DuplicateIcon } from '../styled';
import ModalWarning from 'Common/Components/ModalWarning';

const Duplicate = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [showWarning, setShowWarning] = useState(false);
  const graphSelected = useSelector(selectGraphsSelected);
  const graphId = get(graphSelected, '[0].id', 0);
  const message = intl.formatMessage({ id: 'modalWarning.selectOnlyOne' }, { entity: 'Graph' });
  const duplicate = intl.formatMessage({ id: 'analytics.options.duplicate' });

  const handleClick = () => {
    if ((graphSelected.length === 1)) dispatch(copyGraph(graphId));
    else setShowWarning(true);
  };

  const onClose = () => setShowWarning(false);
  return (
    <>
      <IconButton icon={ <DuplicateIcon /> } title={ duplicate } onClick={ handleClick } />
      { showWarning && <ModalWarning onCloseCallback={ onClose } title="Duplicate Graph" description={ message } /> }
    </>
  );
};

export default Duplicate;
