/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 710px;
  margin: 23px 0px 0px 0px;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 116px;
  height: 50px;
  background: ${({ theme }) => theme.backgrounds.ticketFlap};
  color: ${({ theme }) => theme.text.modalTitle};

  font-size: 13.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
`;
Wrapper.displayName = 'Title';
