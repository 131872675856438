import styled from 'styled-components';
import { ReactComponent as ArrowExpanded } from 'Images/arrow-down.svg';
import { ReactComponent as ArrowCollapsed } from 'Images/arrow-right.svg';

export const Wrapper = styled.div`

`;
Wrapper.displayName = 'Wrapper';

export const Header = styled.div`
  display: flex;
  flex: 0 0 44px;
  padding: 9px 20px 9px 0px;
  align-items: center;
  cursor: pointer;
  width: max-content;
`;
Header.displayName = 'Header';

export const SectionTitle = styled.h4`
  font-size: 13px;
  font-weight: 600;
  color: ${({ theme }) => theme.text.detailModalFields};
  margin-bottom: 0;
`;
SectionTitle.displayName = 'SectionTitle';

export const ExpandedIcon = styled(ArrowExpanded)`
  margin: 0 0 0 10px;
  width: 11px;
  color: #515a65;
  path {
    fill: #515a65;
  }
`;
ExpandedIcon.displayName = 'ExpandedIcon';

export const CollapsedIcon = styled(ArrowCollapsed)`
  margin: 0 0 0 10px;
  height: 10px;
  path {
    fill: #515a65;
  }
`;
CollapsedIcon.displayName = 'CollapsedIcon';

export const Content = styled.div`

`;
Content.displayName = 'Content';
