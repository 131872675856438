import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
user-select: none;
`;
Wrapper.displayName = 'Wrapper';

export const VulnsWrapper = styled.div`
  user-select: none;
  border: ${({ theme }) => `solid 1px ${theme.borders.tableItem}`};
  background-color: #ffffff;
  >:nth-child(n){
    padding: 0 10px;
  }
  >:not(:last-child) {
    border-bottom: ${({ theme }) => `solid 1px ${theme.borders.tableItem}`};
  }
  `;
VulnsWrapper.displayName = 'VulnsWrapper';

export const AddWrapper = styled.div`
  margin-bottom: 20px;
`;
AddWrapper.displayName = 'AddWrapper';

export const Subtitle = styled.div`
  font-size: 13px;
  font-weight: normal;
  line-height: 1.44;
  color: ${colors.grey19};
  margin-bottom: 24px;
`;
Subtitle.displayName = 'Subtitle';
