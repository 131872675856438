import isEqual from 'lodash/isEqual';
import React, { memo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import TemplatePreview from './components/TemplatePreview';
import {
  Wrapper, Subtitle, ItemWrapper, ItemTitle, ListWrapper, PreviewWrapper,
  Options, DownloadIcon, DeleteIcon
} from './styled';
import { selectCustomTemplates, selectTemplateTemp, selectParsedTemplateName } from 'store/ExecutiveReportEditCreate/selectors';
import { mappedTemplates } from 'store/ExecutiveReportEditCreate/mapping';
import { deleteTemplate, downloadTemplate } from 'store/ExecutiveReportEditCreate/actions';
import { selectUserRole } from 'store/Faraday/selectors';

const Item = memo(({ template, onSelection, selected, allTemplates = false }) => {
  const dispatch = useDispatch();
  const role = useSelector(selectUserRole);

  const getName = () => {
    if (!allTemplates || !template.docxOption.includes('(grouped)')) return template.label;
    else return `${template.label} (grouped)`;
  };

  const theme = () => {
    let themeSelected = 'generic';
    if (templateName.includes('dark')) {
      themeSelected = 'dark';
    } else if (templateName.includes('light')) {
      themeSelected = 'light';
    }
    return themeSelected;
  };

  const templateName = getName();
  const isCustom = templateName.includes('(custom)');
  const isGrouped = templateName.includes('(grouped)');
  const allowedByRole = role !== 'pentester';

  const download = (e) => {
    e.stopPropagation();
    dispatch(downloadTemplate(isGrouped, templateName));
  };

  const onDelete = (e) => {
    e.stopPropagation();
    dispatch(deleteTemplate(isGrouped, templateName));
  };

  return (
    <ItemWrapper onClick={ onSelection }>
      <PreviewWrapper>
        <TemplatePreview themeTemplate={ theme() } selected={ selected } />
        { (isCustom && allowedByRole) &&
          <Options>
            <DownloadIcon onClick={ download } title="Download" />
            <DeleteIcon onClick={ onDelete } title="Delete" />
          </Options>
        }
      </PreviewWrapper>
      <ItemTitle title={ templateName }>{ templateName }</ItemTitle>
    </ItemWrapper>
  );
});

Item.displayName = 'Item';

const Items = ({ onSelection, all, vulns, assets, custom, defaultTemplates, grouped }) => {
  const template = useSelector(selectParsedTemplateName);
  const templateSelected = template.length > 0 && template[0];
  const templateTemp = useSelector(selectTemplateTemp);
  const [selected, setSelected] = useState('');

  const intl = useIntl();
  const vulnsSubtitle = intl.formatMessage({ id: 'reports.editCreate.selectTemplateModal.menuOption.vulns' });
  const assetsSubtitle = intl.formatMessage({ id: 'reports.editCreate.selectTemplateModal.menuOption.assets' });
  const customSubtitle = intl.formatMessage({ id: 'reports.editCreate.selectTemplateModal.menuOption.custom' });
  const vulnsTemplatesList = mappedTemplates.filter((t) => t.label.includes('Vulns'));
  const assetsTemplatesList = mappedTemplates.filter((t) => t.label.includes('Assets'));
  const customTemplatesList = useSelector(selectCustomTemplates);
  const extraTemplatesList = mappedTemplates.filter((t) => (!t.label.includes('Vulns') && !t.label.includes('Assets')));
  const defaultTemplatesList = mappedTemplates.filter((t) => t.label.includes('Vulns') && !t.docxOption.includes('(grouped)'));
  const groupedTemplatesList = mappedTemplates.filter((t) => t.label.includes('Vulns') && t.docxOption.includes('(grouped)'));
  const extraDefault = mappedTemplates.filter((t) => (!t.label.includes('Vulns') && !t.label.includes('Assets') && !t.docxOption.includes('(grouped)')));
  const extraGrouped = mappedTemplates.filter((t) => (!t.label.includes('Vulns') && !t.label.includes('Assets') && t.docxOption.includes('(grouped)')));

  const isEmpty = (obj) => Object.keys(obj).length === 0;
  const handleClick = (template) => {
    setSelected(template);
    onSelection(template);
  };

  useEffect(() => {
    setSelected(isEmpty(templateTemp) ? templateSelected : templateTemp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      {
        vulns &&
        <>
          { all && <Subtitle>{ vulnsSubtitle }</Subtitle>}
          <ListWrapper>
          { vulnsTemplatesList.map((t) => <Item key={ t.docxOption.includes('(grouped)') ? `${t.label} (grouped)` : t.label } template={ t } onSelection={ () => handleClick(t) } selected={ (isEmpty(templateTemp)) ? isEqual(templateSelected.docxOption, t.docxOption) : isEqual(selected.docxOption, t.docxOption) } allTemplates />) }
          { extraTemplatesList.map((t) => <Item key={ t.label } template={ t } onSelection={ () => handleClick(t) } selected={ (isEmpty(templateTemp)) ? isEqual(templateSelected.label, t.label) : isEqual(selected.label, t.label) } allTemplates />) }
          </ListWrapper>
        </>
      }
      {
        assets &&
        <>
          { all && <Subtitle>{ assetsSubtitle }</Subtitle>}
          <ListWrapper>
          { assetsTemplatesList.map((t) => <Item key={ t.label } template={ t } onSelection={ () => handleClick(t) } selected={ (isEmpty(templateTemp)) ? isEqual(templateSelected.label, t.label) : isEqual(selected.label, t.label) } />) }
          </ListWrapper>
        </>
      }
      {
        (custom && customTemplatesList.length !== 0) &&
        <>
          { all && <Subtitle>{ customSubtitle }</Subtitle>}
          <ListWrapper>
          { customTemplatesList.map((t) => <Item key={ t.label } template={ t } onSelection={ () => handleClick(t) } selected={ (isEmpty(templateTemp)) ? isEqual(templateSelected.label, t.label) : isEqual(selected.label, t.label) } />) }
          </ListWrapper>
        </>
      }
      {
        defaultTemplates &&
        <>
          <ListWrapper>
          { defaultTemplatesList.map((t) => <Item key={ t.label } template={ t } onSelection={ () => handleClick(t) } selected={ (isEmpty(templateTemp)) ? isEqual(templateSelected.label, t.label) : isEqual(selected.label, t.label) } />) }
          { extraDefault.map((t) => <Item key={ t.label } template={ t } onSelection={ () => handleClick(t) } selected={ (isEmpty(templateTemp)) ? isEqual(templateSelected.label, t.label) : isEqual(selected.label, t.label) } />) }
          </ListWrapper>
        </>
      }
      {
        grouped &&
        <>
          <ListWrapper>
          { groupedTemplatesList.map((t) => <Item key={ t.label } template={ t } onSelection={ () => handleClick(t) } selected={ (isEmpty(templateTemp)) ? isEqual(templateSelected.label, t.label) : isEqual(selected.label, t.label) } />) }
          { extraGrouped.map((t) => <Item key={ t.label } template={ t } onSelection={ () => handleClick(t) } selected={ (isEmpty(templateTemp)) ? isEqual(templateSelected.label, t.label) : isEqual(selected.label, t.label) } />) }
          </ListWrapper>
        </>
      }
    </Wrapper>
  );
};

export default Items;
