import styled from 'styled-components';
import StringField from 'Common/Components/StringField';
import StandardTextAreaField from 'Common/Components/StandardTextAreaField';
import { TextArea } from 'Common/Components/StandardTextAreaField/styled';
import { Title as InputTitle, CustomInput as Input } from 'Common/Components/StringField/styled';

export const CustomInput = styled(StringField)`
  ${InputTitle} {
    font-weight: 400;
    color: #afb1bc;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-right: 0px;
  }
  ${Input} {
    width: 100%;
    font-size: 12px;
  }
`;
CustomInput.displayName = 'CustomInput';

export const CustomTextArea = styled(StandardTextAreaField)`
  ${TextArea} {
    font-size: 12px;
  }
`;
CustomTextArea.displayName = 'CustomTextArea';

export const Title = styled.div`
  font-size: 21.5px;
  font-weight: 500;
  color: ${({ theme }) => theme.text.modalTitle2};
`;

export const SubTitle = styled.h4`
font-size: 12.5px;
font-weight: 400;
color: #7A7E8F;
margin-bottom: 15px;
`;

export const TitleSection = styled.div`
  margin: 28px 0px 14px 0px;
  font-size: 14.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.11px;
  color: ${({ theme }) => theme.text.modalTitle2};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
`;
