import get from 'lodash/get';
import api from 'services/api';
import { openModal } from 'store/modals/actions';
import { MODAL_JIRA_TEMPLATE, MODAL_NEW_JIRA_TEMPLATE } from 'store/modals/modals';
import { SEND_VULN_TO_TOOL } from './settingsActions';
import { selectTemplate, selectTemplates, selectTicketConfig, selectUrl } from './jiraSelectors';
import { UPDATE_VULN_CLIENT_SIDE } from 'store/Manage/types';
import { selectShowDetail, selectVulnDetailId } from 'store/Manage/selectors';
import { loadVulnerabilityDetail } from 'store/Manage/actions';
import { loadManageDetail } from 'store/Contextualization/AssetDetail/actions';
import { loadVulnDetail } from 'store/ManageGeneral/actions';
import { VULNS_ASSET_UPDATE_VULN_CLIENT_SIDE } from 'store/Contextualization/AssetDetail/types';
import { GENERAL_MANAGE_UPDATE_VULN_CLIENT_SIDE } from 'store/ManageGeneral/types';

// **
// JIRA section
// **
export const TOOL_NAME = 'jira';
// obtiene datos de ticketing tools
export const GET_SETTINGS_TICKETING_TOOLS_JIRA_START = 'GET_SETTINGS_TICKETING_TOOLS_JIRA_START';
export const GET_SETTINGS_TICKETING_TOOLS_JIRA_FAIL = 'GET_SETTINGS_TICKETING_TOOLS_JIRA_FAIL';
export const GET_SETTINGS_TICKETING_TOOLS_JIRA_SUCCESS = 'GET_SETTINGS_TICKETING_TOOLS_JIRA_SUCCESS';

// guarda los datos
export const SAVE_SETTINGS_TICKETING_TOOLS_JIRA_START = 'SAVE_SETTINGS_TICKETING_TOOLS_JIRA_START';
export const SAVE_SETTINGS_TICKETING_TOOLS_JIRA_SUCCESS = 'SAVE_SETTINGS_TICKETING_TOOLS_JIRA_SUCCESS';
export const SAVE_SETTINGS_TICKETING_TOOLS_JIRA_FAIL = 'SAVE_SETTINGS_TICKETING_TOOLS_JIRA_FAIL';

export const SET_SETTINGS_TICKETING_TOOLS_JIRA_FIELD = 'SET_SETTINGS_TICKETING_TOOLS_JIRA_FIELD'; // acutaliza el estado de un campo

export const AUTHORIZE_SETTINGS_TICKETING_TOOLS_JIRA_START = 'AUTHORIZE_SETTINGS_TICKETING_TOOLS_JIRA_START';
export const AUTHORIZE_SETTINGS_TICKETING_TOOLS_JIRA_SUCCESS = 'AUTHORIZE_SETTINGS_TICKETING_TOOLS_JIRA_SUCCESS';
export const AUTHORIZE_SETTINGS_TICKETING_TOOLS_JIRA_FAIL = 'AUTHORIZE_SETTINGS_TICKETING_TOOLS_JIRA_FAIL';

export const ACCESS_SETTINGS_TICKETING_TOOLS_JIRA_START = 'ACCESS_SETTINGS_TICKETING_TOOLS_JIRA_START';
export const ACCESS_SETTINGS_TICKETING_TOOLS_JIRA_SUCCESS = 'ACCESS_SETTINGS_TICKETING_TOOLS_JIRA_SUCCESS';
export const ACCESS_SETTINGS_TICKETING_TOOLS_JIRA_FAIL = 'ACCESS_SETTINGS_TICKETING_TOOLS_JIRA_FAIL';

export const GET_PROJECTS_TICKETING_TOOLS_JIRA_START = 'GET_PROJECTS_TICKETING_TOOLS_JIRA_START';
export const GET_PROJECTS_TICKETING_TOOLS_JIRA_SUCCESS = 'GET_PROJECTS_TICKETING_TOOLS_JIRA_SUCCESS';
export const GET_PROJECTS_TICKETING_TOOLS_JIRA_FAIL = 'GET_PROJECTS_TICKETING_TOOLS_JIRA_FAIL';
export const ADD_TO_JIRA_PAYLOAD = 'ADD_TO_JIRA_PAYLOAD';
export const SET_JIRA_PAYLOAD = 'SET_JIRA_PAYLOAD';
export const SET_JIRA_TEMPLATE = 'SET_JIRA_TEMPLATE';

export const RESET_JIRA_PAYLOAD = 'RESET_JIRA_PAYLOAD';
export const ADD_PROJECT_JIRA_PAYLOAD = 'ADD_PROJECT_JIRA_PAYLOAD';
export const DELETE_PROJECT_JIRA_PAYLOAD = 'DELETE_PROJECT_JIRA_PAYLOAD';
export const SET_PROJECT_CONFIG_JIRA_PAYLOAD = 'SET_PROJECT_CONFIG_JIRA_PAYLOAD';

export const SEND_TEMPLATES_TICKETING_TOOLS_JIRA_SUCCESS = 'SEND_TEMPLATES_TICKETING_TOOLS_JIRA_SUCCESS';
export const SEND_TEMPLATES_TICKETING_TOOLS_JIRA_START = 'SEND_TEMPLATES_TICKETING_TOOLS_JIRA_START';
export const SEND_TEMPLATES_TICKETING_TOOLS_JIRA_FAIL = 'SEND_TEMPLATES_TICKETING_TOOLS_JIRA_FAIL';
export const GET_PUBLIC_KEY_TICKETING_TOOLS_JIRA_SUCCESS = 'GET_PUBLIC_KEY_TICKETING_TOOLS_JIRA_SUCCESS';
export const GET_PUBLIC_KEY_TICKETING_TOOLS_JIRA_START = 'GET_PUBLIC_KEY_TICKETING_TOOLS_JIRA_START';
export const GET_PUBLIC_KEY_TICKETING_TOOLS_JIRA_FAIL = 'GET_PUBLIC_KEY_TICKETING_TOOLS_JIRA_FAIL';

export const GET_PROJECT_TICKETING_TOOLS_JIRA_START = 'GET_PROJECT_TICKETING_TOOLS_JIRA_START';
export const GET_PROJECT_TICKETING_TOOLS_JIRA_SUCCESS = 'GET_PROJECT_TICKETING_TOOLS_JIRA_SUCCESS';
export const GET_PROJECT_TICKETING_TOOLS_JIRA_FAIL = 'GET_PROJECT_TICKETING_TOOLS_JIRA_FAIL';
export const SET_IS_TEMPLATE = 'SET_IS_TEMPLATE';
export const SET_PROJECT_BY_DEFAULT_JIRA_START = 'SET_PROJECT_BY_DEFAULT_JIRA_START';
export const SET_PROJECT_BY_DEFAULT_JIRA_SUCCESS = 'SET_PROJECT_BY_DEFAULT_JIRA_SUCCESS';
export const SET_PROJECT_BY_DEFAULT_JIRA_FAIL = 'SET_PROJECT_BY_DEFAULT_JIRA_FAIL';

// **
// JIRA section
// **
export function getJiraSettings () {
  return async (dispatch) => {
    try {
      const response = await api.settings.fetchTicketingTools(TOOL_NAME);
      return dispatch({ type: GET_SETTINGS_TICKETING_TOOLS_JIRA_SUCCESS, response });
    } catch (e) {
      return dispatch({ type: GET_SETTINGS_TICKETING_TOOLS_JIRA_FAIL, error: true, message: 'There was an error, please try again.' });
    }
  };
}

export function saveJiraSettings () {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: SAVE_SETTINGS_TICKETING_TOOLS_JIRA_START });
      const {
        url, projectKey, consumerKey, template, issueType, customFields
      } = getState().settings.ticketingTools.jira;
      const response = await api.settings.saveTicketingToolsJira('jira', url, projectKey, consumerKey, template, issueType, customFields);
      return dispatch({ type: SAVE_SETTINGS_TICKETING_TOOLS_JIRA_SUCCESS, response });
    } catch (e) {
      return dispatch({ type: SAVE_SETTINGS_TICKETING_TOOLS_JIRA_FAIL, error: true, message: get(e, 'message', 'There was an error, please try again.') });
    }
  };
}

export function authenticate () {
  return async (dispatch, getState) => {
    dispatch({ type: AUTHORIZE_SETTINGS_TICKETING_TOOLS_JIRA_START });
    try {
      const { consumerKey } = getState().settings.ticketingTools.jira;
      const response = await api.settings.authorize('jira', { consumer_key: consumerKey });
      window.open(response.authorization_url, 'Welcome to JIRA', 'width=640,height=320,left=150,top=150');
      return dispatch({ type: AUTHORIZE_SETTINGS_TICKETING_TOOLS_JIRA_SUCCESS, response });
    } catch (e) {
      return dispatch({ type: AUTHORIZE_SETTINGS_TICKETING_TOOLS_JIRA_FAIL, error: true, message: get(e, 'message', 'There was an error, please try again.') });
    }
  };
}

export function deauthenticate () {
  return async (dispatch) => {
    try {
      await api.settings.deauthenticate('jira');
      dispatch(getJiraSettings());
    } catch (e) {
      dispatch({ type: ACCESS_SETTINGS_TICKETING_TOOLS_JIRA_FAIL, error: true, message: get(e, 'message', 'There was an error, please try again.') });
    }
  };
}

export function setField (field, value) {
  return async (dispatch) => {
    dispatch({ type: SET_SETTINGS_TICKETING_TOOLS_JIRA_FIELD, field, value });
  };
}

export function accessToken () {
  return async (dispatch) => {
    dispatch({ type: ACCESS_SETTINGS_TICKETING_TOOLS_JIRA_START });
    try {
      const response = await api.settings.accessToken('jira');
      // dispatch(setField('authenticated', true));
      return dispatch({ type: ACCESS_SETTINGS_TICKETING_TOOLS_JIRA_SUCCESS, response });
    } catch (e) {
      return dispatch({ type: ACCESS_SETTINGS_TICKETING_TOOLS_JIRA_FAIL, error: true, message: get(e, 'message', 'There was an error, please try again.') });
    }
  };
}

export function getJiraProjects (credentials) {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_PROJECTS_TICKETING_TOOLS_JIRA_START });
      const response = await api.settings.fetchJIRAProjectsData(credentials);
      return dispatch({ type: GET_PROJECTS_TICKETING_TOOLS_JIRA_SUCCESS, response });
    } catch (e) {
      return dispatch({ type: GET_PROJECTS_TICKETING_TOOLS_JIRA_FAIL, error: true });
    }
  };
}

export function getJiraCacheProjects (credentials) {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_PROJECTS_TICKETING_TOOLS_JIRA_START });
      const response = await api.settings.fetchJIRACacheProjectsData(credentials);
      return dispatch({ type: GET_PROJECTS_TICKETING_TOOLS_JIRA_SUCCESS, response });
    } catch (e) {
      return dispatch({ type: GET_PROJECTS_TICKETING_TOOLS_JIRA_FAIL, error: true, message: e.message });
    }
  };
}

export function getJiraTemplate (key) {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_PROJECT_TICKETING_TOOLS_JIRA_START });
      const response = await api.settings.fetchJIRAProjectTemplate(key);
      dispatch({ type: GET_PROJECT_TICKETING_TOOLS_JIRA_SUCCESS, response });
      dispatch(openModal(MODAL_JIRA_TEMPLATE));
    } catch (e) {
      dispatch({ type: GET_PROJECT_TICKETING_TOOLS_JIRA_FAIL, error: true, message: 'There was an error, please try again.' });
    }
  };
}

export function newGetJiraTemplate (key) {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_PROJECT_TICKETING_TOOLS_JIRA_START });
      const response = await api.settings.fetchJIRAProjectTemplate(key);
      dispatch({ type: GET_PROJECT_TICKETING_TOOLS_JIRA_SUCCESS, response });
      dispatch(openModal(MODAL_NEW_JIRA_TEMPLATE));
    } catch (e) {
      dispatch({ type: GET_PROJECT_TICKETING_TOOLS_JIRA_FAIL, error: true, message: 'There was an error, please try again.' });
    }
  };
}

export function setProjectByDefault (key) {
  return async (dispatch, getState) => {
    try {
      const template = selectTemplate(getState());
      const templates = selectTemplates(getState());
      const ticketConfig = selectTicketConfig(getState());
      const projectKey = Object.keys(templates).find((projectKey) => templates[projectKey].name === template.name);

      if (projectKey !== key) {
        dispatch({ type: SET_PROJECT_BY_DEFAULT_JIRA_FAIL, message: 'Please complete project setup by clicking on the edit icon.' });
        return;
      }
      dispatch({ type: SET_PROJECT_BY_DEFAULT_JIRA_START });
      await api.settings.fetchJIRAProjectTemplate(key);
      dispatch(sendTemplateToJira(ticketConfig, {}, projectKey));
      dispatch({ type: SET_PROJECT_BY_DEFAULT_JIRA_SUCCESS, key });
    } catch (e) {
      dispatch({ type: SET_PROJECT_BY_DEFAULT_JIRA_FAIL, message: 'There was an error, please try again.' });
    }
  };
}

export const addToJiraPayload = (payload) => (dispatch) => {
  dispatch({ type: ADD_TO_JIRA_PAYLOAD, payload });
};

// reset payload
export const resetJiraPayload = () => (dispatch) => {
  dispatch({ type: RESET_JIRA_PAYLOAD });
};
// add project
export const addProject = (project) => (dispatch) => {
  dispatch({ type: ADD_PROJECT_JIRA_PAYLOAD, project });
};
// delete proyect
export const deleteProject = (project) => (dispatch) => {
  dispatch({ type: DELETE_PROJECT_JIRA_PAYLOAD, project });
};
// set project config
export const setprojectConfig = (config, name) => (dispatch, getState) => {
  const projects = selectTemplates(getState());

  if (!projects) return;
  const projectName = Object.keys(projects).find((projectKey) => projects[projectKey].name === name);
  dispatch({ type: SET_PROJECT_CONFIG_JIRA_PAYLOAD, config, key: projectName });
};

export const setJiraTemplate = (project) => (dispatch) => {
  dispatch({ type: SET_JIRA_TEMPLATE, template: project });
};

export const sendTemplateToJira = (ticket_config, credentials = {}, project_key = '') => (dispatch, getState) => {
  try {
    const template = selectTemplate(getState());
    const templates = selectTemplates(getState());
    const url = selectUrl(getState());
    const key = Object.keys(templates).find((projectKey) => templates[projectKey].name === template.name);

    dispatch({ type: SEND_TEMPLATES_TICKETING_TOOLS_JIRA_START });
    api.settings.sendTemplateToJira({
      default_project: project_key,
      projects: {
        ...templates,
        [key]: {
          ...template,
          ticket_config
        }
      },
      url,
      ...credentials
    });
    dispatch({ type: SEND_TEMPLATES_TICKETING_TOOLS_JIRA_SUCCESS, ticketConfig: ticket_config });
  } catch {
    dispatch({ type: SEND_TEMPLATES_TICKETING_TOOLS_JIRA_FAIL, message: 'There was an error, please try again.' });
  }
};

export function sendVulnToJira (credentials = {}) {
  return async (dispatch, getState) => {
    dispatch({ type: SEND_VULN_TO_TOOL, payload: { isFetching: true, error: false, message: '' } });
    try {
      const isVulnDetailOpen = selectShowDetail(getState());
      const vulnId = selectVulnDetailId(getState());
      const { vulnsSelected } = getState().manage;
      const { payload } = getState().settings.ticketingTools.jira;
      const data = {
        vulns: vulnsSelected,
        ...payload,
        ...credentials
      };

      const updatedVulns = await api.settings.sendVulnToJira(data);
      updatedVulns.forEach(vuln => {
        dispatch({ type: UPDATE_VULN_CLIENT_SIDE, payload: { updatedVuln: vuln, toolName: TOOL_NAME } });
      });

      if (isVulnDetailOpen) dispatch(loadVulnerabilityDetail(vulnId, true));

      return dispatch({ type: SEND_VULN_TO_TOOL, payload: { isFetching: false, error: false, message: 'Operation was executed successfully.' } });
    } catch (e) {
      return dispatch({ type: SEND_VULN_TO_TOOL, payload: { isFetching: false, error: true, message: get(e, 'message', 'There was an error, please try again.') } });
    }
  };
}

export function getJiraPublicKey () {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: SAVE_SETTINGS_TICKETING_TOOLS_JIRA_START });
      const {
        url, projectKey, consumerKey, template, issueType, customFields
      } = getState().settings.ticketingTools.jira;
      const res = await api.settings.saveTicketingToolsJira('jira', url, projectKey, consumerKey, template, issueType, customFields);
      dispatch({ type: SAVE_SETTINGS_TICKETING_TOOLS_JIRA_SUCCESS, res });

      dispatch({ type: GET_PUBLIC_KEY_TICKETING_TOOLS_JIRA_START });
      const response = await api.settings.getPublicKey('jira');
      return dispatch({ type: GET_PUBLIC_KEY_TICKETING_TOOLS_JIRA_SUCCESS, response });
    } catch (e) {
      return dispatch({ type: GET_PUBLIC_KEY_TICKETING_TOOLS_JIRA_FAIL, error: true, message: 'There was an error, please try again.' });
    }
  };
}

export const setIsTemplate = (isTemplate) => (dispatch) => {
  dispatch({ type: SET_IS_TEMPLATE, payload: isTemplate });
};

export function sendVulnToJiraContext (vulnsSelected, vulnId, isVulnDetailOpen, entity, credentials = {}) {
  return async (dispatch, getState) => {
    dispatch({ type: SEND_VULN_TO_TOOL, payload: { isFetching: true, error: false, message: '' } });
    try {
      const { payload } = getState().settings.ticketingTools.jira;
      const data = {
        vulns: vulnsSelected,
        ...payload,
        ...credentials
      };

      const updatedVulns = await api.settings.sendVulnToJira(data);
      updatedVulns.forEach(vuln => {
        if (entity === 'vulns') dispatch({ type: UPDATE_VULN_CLIENT_SIDE, payload: { updatedVuln: vuln, toolName: TOOL_NAME } });
        else if (entity === 'vulnsAssets') dispatch({ type: VULNS_ASSET_UPDATE_VULN_CLIENT_SIDE, payload: { updatedVuln: vuln, toolName: TOOL_NAME } });
        else dispatch({ type: GENERAL_MANAGE_UPDATE_VULN_CLIENT_SIDE, payload: { updatedVuln: vuln, toolName: TOOL_NAME } });
      });

      if (isVulnDetailOpen) {
        if (entity === 'vulns') dispatch(loadVulnerabilityDetail(vulnId, true));
        else if (entity === 'vulnsAssets') dispatch(loadManageDetail(vulnId));
        else dispatch(loadVulnDetail(vulnId));
      }
      return dispatch({ type: SEND_VULN_TO_TOOL, payload: { isFetching: false, error: false, message: 'Operation was executed successfully.' } });
    } catch (e) {
      return dispatch({ type: SEND_VULN_TO_TOOL, payload: { isFetching: false, error: true, message: get(e, 'message', 'There was an error, please try again.') } });
    }
  };
}
