import styled from 'styled-components';
import { ReactComponent as TrashIcon } from 'Images/icon-action-bar-trash.svg';
import colors from 'Styles/colors';

export default styled(TrashIcon).attrs({
  id: 'qa-delete-ws'
})`
  cursor: ${({ disabled }) => { if (disabled) return 'not-allowed !important'; else return 'pointer'; }};
  height: 17px;
  g > path {
    fill: ${colors.red1};
  }
`;
