import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as PlusImage } from 'Images/icon-action-bar-plus.svg';

export const Button = styled.div`
  white-space: nowrap;
  align-items: center;
  background-color: ${({ theme }) => theme.buttons.custom.background};
  border-radius: 2px;
  border: solid ${(props) => (props.border === 0 || props.border > 0 ? props.border : 1)}px ${({ theme }) => theme.buttons.custom.border};
  cursor: pointer;
  display: flex;
  font-size: 11.5px;
  font-weight: 500;
  height: 34px;
  justify-content: center;
  line-height: 34px;
  padding: 0 10px;



  & > svg {
    margin-right: 10px;
    & g > path {
      fill: ${({ theme }) => theme.buttons.custom.color};
    }
    & > path {
      fill: ${({ theme }) => theme.buttons.custom.color};
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.buttons.custom.backgroundHover};
    border-radius: 3px;
    border: solid ${(props) => (props.border === 0 || props.border > 0 ? props.border : 1)}px #d8dee5;
  }
  
  ${({ disabled }) => {
    if (!disabled) return null;
    return `
      pointer-events: none;
      background-color: ${colors.white};
      & > svg {
        & > path {
          fill: ${colors.grey1};
        }
      }
    `;
  }
}
`;
Button.displayName = 'Button';

export const Text = styled.div`
  user-select: none;
  color: ${({ disabled, theme }) => `${disabled ? colors.grey1 : theme.buttons.custom.color}`};
  font-size: 11.5px;
  font-weight: 500;
  line-height: 34px;
`;
Text.displayName = 'Text';

export const PlusIcon = styled(PlusImage)`
  height: 11px;
  & path{
    fill: ${({ theme }) => theme.buttons.custom.color};
  }
`;
Text.displayName = 'Text';
