/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Label = styled.div`
    font-weight: 400;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-right: 0px;
    width: 100%;
    font-size: 12px;
    color: ${({ theme }) => theme.textfield.text};
`;

export const Input = styled.div`
    display: flex;
    align-items: center;
    justify-content: left;
    min-width: 232.4px;
    height: 27px;
    cursor: 'pointer';
    margin-right: 14px;
    padding-left: 8px;
    box-sizing: border-box;
    border-radius: '4px';
    color: ${({ theme }) => theme.textfield.text};
    background: ${({ theme }) => theme.textfield.background};
`;
