import React from 'react';
import { Wrapper, Title } from './styled';
import Elements from './components/Elements';

const Content = () => {
  return (
    <Wrapper>
      <Title children='Main settings' />
      <Elements />
    </Wrapper>
  );
};

export default Content;
