import React from 'react';
import { PropTypes } from 'prop-types';
import { get } from 'lodash';
import Skeleton from 'Common/Components/Skeleton';
import DateFormatter from 'Common/Components/DateFormatter';
import { graphTemplates } from 'store/Analytics/selectors';
import { useDispatch } from 'react-redux';
import { redirect } from 'store/Router/actions';
import { Title, Text, GraphDate, WrapperWs, Workspaces } from './styled';

export const graphTypeParsed = (type) => {
  const graph = graphTemplates.find((g) => g.key === type);
  const graphType = graph ? graph.name : '';
  return graphType || '';
};

export const NameCell = ({ rowInfo, isFetching }) => {
  const dispatch = useDispatch();
  const id = get(rowInfo, 'original.id', 0);
  const name = get(rowInfo, 'original.name', '');
  const onClick = () => {
    if (id > 0) dispatch(redirect(`/analytics/${id}`));
  };

  if (isFetching) return <Skeleton />;
  return <Title onClick={ onClick }>{ name }</Title>;
};

export const TypeCell = ({ rowInfo, isFetching }) => {
  const type = get(rowInfo, 'original.type', '');
  const typeParsed = graphTypeParsed(type);
  if (isFetching) return <Skeleton />;
  return <Text>{ typeParsed }</Text>;
};

export const DescriptionCell = ({ rowInfo, isFetching }) => {
  const description = get(rowInfo, 'original.description', '');
  if (isFetching) return <Skeleton />;
  return <Text>{ description }</Text>;
};

export const DateCell = ({ rowInfo, isFetching }) => {
  const date = get(rowInfo, 'original.update_date', '');
  if (isFetching) return <Skeleton />;
  return <GraphDate><DateFormatter date={ date } /></GraphDate>;
};

export const WorkspacesCell = ({ rowInfo, isFetching }) => {
  const workspaces = get(rowInfo, 'original.filters.workspaces', []);
  const parsedWorkspaces = workspaces?.join(', ');
  const count = workspaces?.length || 0;
  if (isFetching) return <Skeleton />;
  return (
    <WrapperWs>
      {/* <Count>{`(${count})`}</Count> */}
      <Workspaces title={ parsedWorkspaces }>{`(${count}) ${parsedWorkspaces}` }</Workspaces>
    </WrapperWs>
  );
};

NameCell.propTypes = {
  rowInfo: PropTypes.instanceOf(Object).isRequired,
  isFetching: PropTypes.bool.isRequired
};
TypeCell.propTypes = {
  rowInfo: PropTypes.instanceOf(Object).isRequired,
  isFetching: PropTypes.bool.isRequired
};
DescriptionCell.propTypes = {
  rowInfo: PropTypes.instanceOf(Object).isRequired,
  isFetching: PropTypes.bool.isRequired
};
DateCell.propTypes = {
  rowInfo: PropTypes.instanceOf(Object).isRequired,
  isFetching: PropTypes.bool.isRequired
};
