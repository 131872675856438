import styled from 'styled-components';

export const Input = styled.input`
    width: 100%;
    height: 34px;
    padding: 10px 15px 8px 15px;
    box-shadow: inset 0 -1px 0 0 ${({ theme }) => theme.textfield.shadow};
    background-color: ${({ disabled, theme }) => (disabled ? theme.backgrounds.reportUnselectedInput : theme.textfield.background)};
    border: none;
    color: ${({ theme }) => theme.textfield.text};
    font-size: 13.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    opacity: ${({ disabled, theme }) => (disabled ? '0.62' : '1')};
    ::placeholder {
        color: ${({ disabled, theme }) => (disabled ? '#b5b8cb' : theme.text.placeholder)};
    }
    :focus {
        background-color: ${({ theme }) => theme.textfield.focus};
    }
`;
Input.displayName = 'Input';

export const InputTitle = styled.div`
    text-overflow: ellipsis;
  height: 17px;
  text-align: left;
  user-select: none;
  margin-bottom: 7px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8f94b2;
`;
InputTitle.displayName = 'InputTitle';

export const Wrapper = styled.div`
  height: 58px;
`;
export const TextArea = styled.textarea`
    width: 100%;
    height: 100%;
    padding: 10px 15px 8px 15px;
    box-shadow: inset 0 -1px 0 0 ${({ theme }) => theme.textfield.shadow};
    background-color: ${({ disabled, theme }) => (disabled ? '#f5f7f9' : theme.textfield.background)};
    border: none;
    color: ${({ theme }) => theme.textfield.text};
    font-size: 13.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    resize: none;
    opacity: ${({ disabled, theme }) => (disabled ? '0.62' : '1')};
    ::placeholder {
        color: ${({ disabled, theme }) => (disabled ? '#b5b8cb' : theme.textfield.text)};
    }
    :focus {
        background-color: ${({ theme }) => theme.textfield.background};
    }
`;
TextArea.displayName = 'TextArea';
