import styled from 'styled-components';

const Wrapper = styled.div`
  height: 85%;
  margin: 28px 0 0;
  padding: 22px 15px 15px 15px;
  background-color: ${({ theme }) => theme.backgrounds.modal3};
  display: flex;
  flex-direction: column;
  @media (max-width: 1370px) {
    height: 78%;
  }
`;
Wrapper.displayName = 'Wrapper';

export default Wrapper;
