import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
`;
Wrapper.displayName = 'Wrapper';

export const Server = styled.div`
  width: 150px;
  margin-right: 20px;
`;
Server.displayName = 'Server';

export const Port = styled.div`
  width: 80px;
  margin-right: 20px;
`;
Port.displayName = 'Port';

export const Domain = styled.div`
  width: 150px;
  margin-right: 20px;
`;
Domain.displayName = 'Domain';

export const DomainDn = styled.div`
  width: 150px;
`;
DomainDn.displayName = 'DomainDn';

export const BindFormat = styled.div`

`;
BindFormat.displayName = 'BindFormat';

export const BindDn = styled.div`
  width: 200px;
  margin-right: 20px;
`;
BindDn.displayName = 'BindDn';

export const UserAttribute = styled.div`
  width: 200px;
`;
UserAttribute.displayName = 'UserAttribute';

export const DropdownWrapper = styled.div`
  width: 152px;
  margin-right: 20px;
`;
DropdownWrapper.displayName = 'DropdownWrapper';

export const Title = styled.div`
  text-align: left;
  font-size: 12.5px;
  line-height: 2.08;
  margin-bottom: 1px;
  color: ${colors.grey17};
`;
Title.displayName = 'Title';

export const dropdownStyle = (theme) => ({
  control: () => ({
    display: 'flex',
    height: '34px',
    borderRadius: '1px',
    // boxShadow: '2px 2px 5px 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 #afafaf',
    backgroundColor: theme.selector.background,
    border: 'solid 1px ' + theme.selector.background,
    borderBottomWidth: '0px',
    color: theme.textfield.text,
    paddingLeft: '10px',
    fontWeight: '700',
    textOverflow: 'ellipsis',
    cursor: 'pointer'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  placeholder: (provided) => ({
    ...provided,
    color: theme.textfield.text + ' !important',
    fontWeight: 500,
    fontSize: '14.5px'
  }),
  input: (provided) => ({
    ...provided,
    fontSize: '13px',
    color: theme.textfield.text + ' !important',
    fontWeight: 300

  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#505965',
    width: '30px'
  }),
  option: (provided, { isFocused, data }) => ({
    ...provided,
    // eslint-disable-next-line no-nested-ternary
    backgroundColor: isFocused ? theme.selector.selected : data.required ? 'rgba(255,86,48,0.1)' : theme.selector.background,
    fontSize: '14px',
    textAlign: 'left',
    color: theme.textfield.text,
    cursor: 'pointer',
    paddingLeft: '13px',
    height: '36px',
    textTransform: 'capitalize',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }),
  valueContainer: () => ({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginRight: 'auto'
  }),
  menu: () => ({
    position: 'absolute',
    marginTop: '6px',
    zIndex: 5000,
    width: '100%',
    border: 'none',
    borderRadius: '3px',
    backgroundColor: theme.selector.background,
    boxShadow: '2px 2px 30px 0 ' + theme.selector.background,
    minWidth: '153px'
  }),
  menuList: () => ({
    maxHeight: '250px',
    overflowX: 'auto',
    borderRadius: '3px',
    paddingTop: '17px',
    paddingBottom: '13px'
  }),
  singleValue: (provided) => {
    const opacity = 1;
    const transition = 'opacity 300ms';
    return {
      ...provided, opacity, transition, color: theme.textfield.text, width: '90px', height: '19px', fontSize: '14px', fontWeight: 400, top: '8px', position: 'relative'
    };
  }
});
