import styled from 'styled-components';
import { ReactComponent as Cancel } from 'Images/cancel.svg';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 13px;
`;
Wrapper.displayName = 'Wrapper';

export const CancelIcon = styled(Cancel)`
  margin-left: 20px;
  opacity: 0.7;
  width: 11px;
  height: 11px;
`;
CancelIcon.displayName = 'CancelIcon';
