import styled from 'styled-components';
import colors from 'Styles/colors';
import { Title } from 'Common/Components/StringField/styled';

export const CalendarWrapper = styled.div`
  position: fixed;
  background-color: ${({ theme }) => theme.backgrounds.modal};
  /* box-shadow: 2px 2px 30px 0 ${({ theme }) => theme.textfield.text}; */
  box-shadow: ${({ theme }) => theme.boxShadow.modal};
  height: 280px;
  z-index: 1;

  .DateRangePicker__Date{
    border: solid 1px ${({ theme }) => theme.borders.calendarDate};
  }

  .DateRangePicker__MonthDates{
    border-collapse: collapse;
  }
  
  .DateRangePicker__CalendarSelection {
    background-color: ${colors.softBlue};
    border: 1px solid ${colors.softBlue};
  }

  .DateRangePicker__CalendarHighlight {
    background-color: ${colors.softBlue};
    border: ${colors.softBlue};
    color: white;
  }

  .DateRangePicker__Date--is-selected{
    .DateRangePicker__DateLabel {
      color: ${({ theme }) => theme.text.monthHeader} !important;
    }
  }

  .DateRangePicker__Date--is-highlighted{
    .DateRangePicker__DateLabel {
      color: ${({ theme }) => theme.text.monthHeader};
    }

    .DateRangePicker__CalendarHighlight--single {
      color: ${({ theme }) => theme.text.monthHeader};
    }
  }

  .DateRangePicker__PaginationArrow--next{
    &:hover {
      background-color: transparent;
      color: ${({ theme }) => theme.text.monthHeader};
    }
  }

  .DateRangePicker__PaginationArrow--previous{
    &:hover {
      background-color: transparent;
      color: ${({ theme }) => theme.text.monthHeader};
    }
  }

  .DateRangePicker__Month{
    width: ${(props) => props.width}px;
  }

  .DateRangePicker__Date--is-selected {
    background-color: ${colors.softBlue} !important;
  }

  .DateRangePicker__PaginationArrow{
    height: 25px;
  }

  .DateRangePicker__WeekdayHeading {
    font-size: 11px;
    abbr{
      color: ${({ theme }) => theme.textfield.text} !important;
    }
  }

  .DateRangePicker__MonthHeaderLabel{
    font-size: 12px;
    color: ${({ theme }) => theme.text.monthHeader};
  }

  .DateRangePicker__DateLabel{
    font-size: 11px;
    color: ${({ theme }) => theme.textfield.text};
  }

  .DateRangePicker__Date--weekend{
    background-color: ${({ theme }) => theme.backgrounds.modal};
  }
`;
CalendarWrapper.displayName = 'CalendarWrapper';

export const StringFieldContainer = styled.div`

  input {
    background-color: ${({ theme }) => theme.textfield.background} !important;
  }

  input::placeholder {
    color: ${({ highlight, theme }) => (highlight ? theme.textfield.text : '')};
    font-weight: 300;
    font-size: 14.5px;
  }

  input:-ms-input-placeholder {
    color: ${({ highlight, theme }) => (highlight ? theme.textfield.text : '')};
  }

  input::-ms-input-placeholder {
    color: ${({ highlight, theme }) => (highlight ? theme.textfield.text : '')};
  }

  input:focus::placeholder { 
    background-color: ${({ theme }) => theme.textfield.background} !important;
    color: ${({ highlight, theme }) => (highlight ? theme.textfield.text : '')} !important;
  }

  ${Title}{
    margin-bottom: 0px;
  }
`;
StringFieldContainer.displayName = 'StringFieldContainer';

export const Wrapper = styled.div`
`;
Wrapper.displayName = 'Wrapper';

export const CloseButton = styled.button`
  position: absolute;
  width: 50px;
  height: 25px;
  bottom: 12px;
  right: 19px;
  border: 0;
  background-color: ${(props) => (props.disabled ? colors.grey27 : colors.softBlue)};
  color: white;
  font-size: 12px;
  border-radius: 2px;
`;
CloseButton.displayName = 'CloseButton';

export const CleanButton = styled.button`
  position: absolute;
  width: 50px;
  height: 25px;
  bottom: 12px;
  left: 19px;
  color: ${({ theme }) => theme.buttons.custom.color} !important;
  font-size: 12px;
  border: solid 1px ${({ theme }) => theme.buttons.custom.border};
  color: ${colors.dark2};
  background-color: ${({ theme }) => theme.buttons.custom.background};
  border-radius: 2px;
`;
CleanButton.displayName = 'CleanButton';
