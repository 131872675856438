import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as Refresh } from 'Images/reload.svg';
import { ReactComponent as Help } from 'Images/noun-help.svg';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 20px 20px 20px;
`;
Wrapper.displayName = 'Wrapper';

export const Table = styled.table`
`;
Table.displayName = 'Table';

export const TableBody = styled.tbody`
  & > :first-child td {
    padding-bottom: 12px;
  }
  & > :not(:first-child):not(:last-child) td {
    padding-bottom: 7px;
  }
`;
TableBody.displayName = 'TableBody';

export const Text = styled.td`
  font-size: 12.5px;
  color: ${({ theme }) => theme.text.modalTitle2};
`;
Text.displayName = 'Text';

export const Title = styled.td`
  font-weight: 600;
  font-size: 16px;
  padding-bottom: 10px;
  color: #1c4566;
`;

export const Line = styled.tr`
  font-size: 13px;
  & >:first-child {
    width: 180px;
  }
`;
Line.displayName = 'Line';

export const Link = styled.a`
  font-weight: normal;
  font-size: 13.5px;
  color: #1c4566;
`;

export const IconButton = styled.div`
  cursor: pointer;
`;
IconButton.displayName = 'IconButton';

export const RefreshIcon = styled(Refresh)`
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  g {
    fill: ${colors.darkBlueGrey};
  }
`;
RefreshIcon.displayName = 'RefreshIcon';

export const HelpIcon = styled(Help)`
  width: 18px;
  height: 18px;
`;
HelpIcon.displayName = 'HelpIcon';

export const Row = styled.div`
  display: flex;
  & >:first-child {
    margin-right: 21px;
    width: 50%;
  }
  & >:last-child label {
    width: 50%;
  }
`;
Row.displayName = 'Row';

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 26px;
  display: inline-block;
`;
Field.displayName = 'Field';

export const Label = styled.label`
  font-weight: 400;
  font-size: 12.5px;
  color: ${colors.grey17};
  margin-bottom: 13px;
`;
Label.displayName = 'Label';

export const CreationDate = styled.div`
  height: 34px;
  padding: 7px 10px 0 15.5px;
  background-color: ${({ theme }) => theme.textfield.background};
  font-size: 14.5px;
  color: ${({ theme }) => theme.textfield.text}; 
`;
CreationDate.displayName = 'CreationDate';
