import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { saveGraph } from 'store/Analytics/actions';
import {
  selectName, selectWorkspaces, selectHosts, selectAllowHosts, selectFrom, selectTo,
  selectGraphs, selectGraphDetail, filtersChanged, selectId
} from 'store/Analytics/selectors';
import {
  SaveButton
} from './styled';

const Save = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const saveLabel = intl.formatMessage({ id: 'analytics.createEdit.save' });
  const name = useSelector(selectName);
  const workspaces = useSelector(selectWorkspaces);
  const allowHosts = useSelector(selectAllowHosts);
  const hosts = useSelector(selectHosts);
  const notValidWs = (workspaces.length === 0) || workspaces.some((ws) => ws === '');
  const notValidHosts = allowHosts && ((hosts.length === 0) || hosts.some((host) => host === ''));
  const from = useSelector(selectFrom);
  const to = useSelector(selectTo);
  const id = useSelector(selectId);
  const graphsList = useSelector(selectGraphs);
  const graphSelected = useSelector(selectGraphDetail);

  const onClick = () => {
    dispatch(saveGraph());
  };

  const graphUpdated = () => {
    const prevGraph = graphsList.find((graph) => graph.id === id);
    const updated = prevGraph && filtersChanged(prevGraph, graphSelected, allowHosts, true);
    return updated;
  };

  const isDisabled = () => (!name || notValidWs || notValidHosts || !((from && to) || (!from && !to))) ||
    (id && !graphUpdated());

  return (
    <SaveButton disabled={ isDisabled() } onClick={ onClick }>{ saveLabel }</SaveButton>
  );
};

export default Save;
