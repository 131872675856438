import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
Wrapper.displayName = 'Wrapper';

export const Button = styled.div`
  padding: 0px 10px;
  font-size: 11.5px;
  font-weight: 500;
  line-height: 34px;
  height: 34px;
  border-radius: 2px;
  border: solid 1px ${({ theme }) => theme.buttons.addButton.border};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.buttons.addButton.color};
  background: ${({ theme }) => theme.buttons.addButton.background};
  & > svg {
    height: 11px;
    margin-right: 10px;
     & > path {
      fill: ${({ theme }) => theme.buttons.addButton.color};
    }
  }
  &:hover {
        border-radius: 3px;
        border: solid 1px ${({ theme }) => theme.buttons.addButton.border};
        background-color: ${({ theme }) => theme.buttons.addButton.backgroundHover};
      }

      &:active {
        border: solid 1px ${({ theme }) => theme.buttons.addButton.border};
        background-color: ${({ theme }) => theme.buttons.addButton.backgroundHover};
      }
  
`;
