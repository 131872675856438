import styled from 'styled-components';

export const CommentsItem = styled.div`
    font-size: 13.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    display: flex;
    align-items: center;
`;
CommentsItem.displayName = 'CommentsItem';

export const CommentsDate = styled.div`
    user-select: none;
    color: #90a9c0;
    margin-bottom: 4px;
`;
CommentsDate.displayName = 'CommentsDate';

export const CommentsAuthor = styled.span`
    user-select: none;
    color: ${({ theme }) => theme.text.sectionTitle};
    font-weight: 600;
    margin-bottom: 12px;
`;
CommentsAuthor.displayName = 'CommentsAuthor';

export const CommentsContent = styled.span`
    color: ${({ theme }) => theme.text.sectionTitle};
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: break-spaces;
    width: 100%;
    p {
      display: inline;
    }
`;
CommentsContent.displayName = 'CommentsContent';

export const CommentRemove = styled.span`
    float: right;
    margin-right: 6px;
    border-radius: 50%;
    padding: 3px;
    cursor:pointer;   

    &:hover {
        background-color: #94acc2;
        svg {
            color: #fff!important;
        }
    }
`;
CommentRemove.displayName = 'CommentRemove';

export const CommentEdit = styled.span`
    float: right;
    margin-right: 16px;    
    border-radius: 50%;
    padding: 3px;
    cursor:pointer;    

    &:hover {
        background-color: #94acc2;
        svg {
            color: #fff!important;
        }
    }
`;
CommentEdit.displayName = 'CommentEdit';

export const CommentContainer = styled.div`
    display: flex;
    flex-direction: column;
    white-space: pre;
    width: 85%;
`;
CommentContainer.displayName = 'CommentContainer';

export const ButtonsContainer = styled.div`
    display: inline-block;
    padding: 15px 0px;
    width: 15%;
`;
ButtonsContainer.displayName = 'ButtonsContainer';
