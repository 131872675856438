/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  width: 49px;
  height: 20px;
  padding: 4px 10px;
  margin: 0px 7px 0px 18px;
  background: ${colors.blue24};
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.06), inset 0 0 12px 0 rgba(144, 169, 192, 0.16);
  color: white;
  border-radius: 6px;
  /* font */
  font-family: 'SequelSans-Head';
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
`;
Wrapper.displayName = 'Wrapper';
