/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-children-prop */
import React, { useRef } from 'react';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';
import { useResizeY } from 'Hooks/useResize';
import CustomMentionEditor from './CustomMentionEditor';
import {
  EditorContainer, ButtonsWrapper, Resizer
} from './styled';

const CommentTextArea = ({
  disabled, placeholder, commentToEdit, setCommentToEdit, addComment, editComment, entity, entityId,
  isBulk, handleClose
}) => {
  const intl = useIntl();
  const wrapperRef = useRef(null);
  const resizerRef = useRef(null);
  const height = useResizeY(wrapperRef, resizerRef, 190, 190);
  const disabledFieldLabel = intl.formatMessage({ id: 'manage.detail.fieldDisabled' });
  const placeholderText = disabled ? disabledFieldLabel : placeholder;

  return (
    <EditorContainer disabled={ disabled } containerHeight={ height } wrapperRef={ wrapperRef }>
      <CustomMentionEditor
        placeholder={ placeholderText }
        addComment={ addComment }
        editComment={ editComment }
        commentToEdit={ commentToEdit }
        setCommentToEdit={ setCommentToEdit }
        entity={ entity }
        entityId={ entityId }
        disabled={ disabled }
        isBulk={ isBulk }
        handleClose={ handleClose }
      />
      { !disabled && <Resizer ref={ resizerRef } /> }
      <ButtonsWrapper />
    </EditorContainer>
  );
};

CommentTextArea.propTypes = {
  entity: PropTypes.string.isRequired,
  entityId: PropTypes.number,
  addComment: PropTypes.func.isRequired,
  editComment: PropTypes.func,
  placeholder: PropTypes.string,
  commentToEdit: PropTypes.object,
  setCommentToEdit: PropTypes.func
};

CommentTextArea.defaultProps = {
  placeholder: '',
  commentToEdit: null,
  entityId: null,
  editComment: () => {},
  setCommentToEdit: () => {}
};

export default CommentTextArea;
