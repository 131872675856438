import styled from 'styled-components';
import colors from 'Styles/colors';
import { slideIn } from 'Styles/effects';
import { ellipsisText } from 'Styles/styles';
import { ReactComponent as Trash } from 'Images/icon-action-bar-trash.svg';

export const ModalContent = styled.div`
  padding: 16px 34px 30px 34px;
  width: 612px;
  height: 373px;
  box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.24);
  border-radius: 2px;
  border-top: 10px solid ${colors.softBlue};
  background: ${({ theme }) => theme.backgrounds.modal};
  ${slideIn}
`;
ModalContent.displayName = 'ModalContent';

export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 13px;
`;
Flex.displayName = 'Flex';

export const Title = styled.h3`
  font-size: 21.5px;
  font-weight: 600;
  line-height: 1.49;
  color: ${({ theme }) => theme.text.modalTitle};
  margin: 2px 0 0 0;
`;
Title.displayName = 'Title';

export const Buttons = styled.div`
  display: flex;
  & >:first-child {
    margin-right: 9.5px;
  }
`;
Buttons.displayName = 'Buttons';

export const Subtitle = styled.h5`
  font-size: 12.5px;
  font-weight: 500;
  line-height: 1.44;
  color: ${colors.greyBlue};
  margin-bottom: 32px;
  font-weight: 400; 
`;
Subtitle.displayName = 'Subtitle';

export const DocLink = styled.a`
  cursor: pointer;
  font-size: 12.5px;
  font-weight: 500;
  color: ${colors.blueCerulean};
  margin-left: 3px;
`;
DocLink.displayName = 'DocLink';

export const WorkspaceWrapper = styled.div`
  width: 430px;
  margin: 0 auto;
`;
WorkspaceWrapper.displayName = 'WorkspaceWrapper';

export const Label = styled.p`
  font-size: 12.5px;
  color: ${colors.grey17};
  padding-bottom: 12px;
  position: relative;
    &:after {
      content: '*';
      color: ${colors.redPink};
      position: absolute;
      top: 0;
      margin-left: 3px;
    }
`;
Label.displayName = 'Label';

export const Box = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.backgrounds.modal};
  padding: 10px 16px;
  box-shadow: 0 2px 50px 0 ${({ theme }) => theme.backgrounds.modal4};
  height: 38px;
  margin-top: 8px;
`;
Box.displayName = 'Box';

export const WsName = styled.div`
  width: 400px; 
  font-size: 13.5px;
  color: ${({ theme }) => theme.textfield.text};
  ${ellipsisText}

`;
WsName.displayName = 'WsName';

export const TrashIcon = styled(Trash)` 
  width: 16px;
  height: 17px;
  g > path {
    fill: ${colors.blueGrey};
  }
`;

TrashIcon.displayName = 'TrashIcon';

export const dropdownStyle = (theme) => ({
  control: (provided) => ({
    display: 'flex',
    minHeight: '35px',
    boxShadow: '2px 2px 5px 0 rgba(0, 0, 0, 0.06)',
    backgroundColor: theme.textfield.background,
    borderBottomWidth: '0px',
    color: theme.textfield.text,
    paddingLeft: '10px',
    fontWeight: '700',
    textOverflow: 'ellipsis',
    borderLeft: '12px solid #53a9ff',
    cursor: 'pointer'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '12.5px',
    color: '#afb1bc',
    fontWeight: 400
  }),
  input: (provided) => ({
    ...provided,
    fontSize: '13px',
    color: '#1c2243',
    fontWeight: 300
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#63758d',
    padding: '8px 13px 8px 8px',
    width: '38px'
  }),
  option: (provided, { isFocused, data }) => ({
    ...provided,
    backgroundColor: 'transparent',
    fontSize: '13.5px',
    textAlign: 'left',
    color: theme.textfield.text,
    cursor: isFocused ? 'pointer' : 'default',
    paddingLeft: '22px',
    paddingRight: '22px',
    height: '36px',
    textTransform: 'none',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }),
  valueContainer: () => ({
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    marginRight: 'auto'
  }),
  menu: () => ({
    position: 'absolute',
    marginTop: '6px',
    zIndex: 5000,
    width: '100%',
    border: 'none',
    borderRadius: '3px',
    backgroundColor: theme.textfield.background,
    boxShadow: theme.boxShadow.modal,
    minWidth: '190px'
  }),
  menuList: () => ({
    maxHeight: '152px',
    overflowX: 'auto',
    borderRadius: '3px',
    paddingTop: '10px',
    paddingBottom: '13px'
  }),
  singleValue: (provided, state) => {
    const transition = 'opacity 300ms';
    return {
      ...provided, transition, color: '#1c2243', width: 'fit-content', height: '19px', fontSize: '14px', fontWeight: 400, top: '8px', position: 'relative'
    };
  }
});

export const StyledLabel = styled.div`
  font-size: 11px;
  color: ${colors.greyBlue};
  margin-left: 13px;
`;
StyledLabel.displayName = 'StyledLabel';

export const BoldStyledLabel = styled.span`
  font-weight: 500;
  font-size: 11px;
  color: ${colors.greyBlue};
`;
BoldStyledLabel.displayName = 'BoldStyledLabel';

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 35px 0 0 58px;
`;
CheckboxWrapper.displayName = 'CheckboxWrapper';
