import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  width: 100%;
  max-width: 505px;
  background: ${({ theme }) => theme.backgrounds.modal};
  text-align: left;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  font-size: 21.5px;
  font-weight: 600;
  line-height: 1.49;
  padding-bottom: 21px;
  color: ${({ theme }) => theme.text.modalTitle};
`;
Title.displayName = 'Title';

export const Subtitle = styled.div`
  font-size: 12.5px;
  font-weight: normal;
  line-height: 1.44;
  color: ${({ theme }) => theme.text.modalTitle2};
`;
Subtitle.displayName = 'Subtitle';

export const WrapperField = styled.div`
  flex: 1 1 auto;
`;
WrapperField.displayName = 'WrapperField';

export const Form = styled.div`
  display: flex;
  padding: 34px 0;
  & > ${WrapperField}:not(:last-child) {
    padding-right: 15px;
  }
`;
Form.displayName = 'Form';

export const Label = styled.div`
  font-size: 12.5px;
  font-weight: normal;
  letter-spacing: 0.09px;
  color: ${colors.grey17};
  padding-bottom: 8px;
`;
Label.displayName = 'Label';

export const Input = styled.input.attrs({
  type: 'number'
})`
  width: 100%;
  padding: 9px 15px 8px 15px;
  box-shadow: inset 0 -1px 0 0 #afafaf;
  background: ${colors.iceBlue};
  border: 0;
  font-size: 14.5px;
  letter-spacing: 0.11px;
  color: ${colors.grey17};
  ::placeholder {
    color: ${colors.grey17};
    opacity: 1;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
Footer.displayName = 'Footer';

export const DefaultButton = styled.div`
  cursor: pointer;
  width: 89px;
  line-height: 34px;
  margin: 0 8px 0 0;
  border-radius: 2px;
  border: 0px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
`;
DefaultButton.displayName = 'DefaultButton';

export const Close = styled(DefaultButton)`
  border: solid 1px ${colors.dark6};
  color: ${({ theme }) => theme.text.modalTitle};
`;
Close.displayName = 'Close';

export const Save = styled(DefaultButton)`
  background: ${colors.blueCerulean};
  color: ${colors.white};
`;
Save.displayName = 'Save';

export const Blue = styled.span`
  color: ${colors.blueCerulean};
  padding: 0 5px;
`;
Blue.displayName = 'Blue';
