import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { useResizeX } from 'Hooks/useResize';
import useWindowDimensions from 'Hooks/useWindowSize';
import Header from './components/Header';
import MainInfo from './components/MainInfo';
import {
  Wrapper, ResizerWrapper, DetailWrapper, Resizer,
  Transparency, Content
} from './styled';
import { selectUserDetail } from 'store/Users/selectors';

const UserDetail = () => {
  const [showTransparency, setShowTransparency] = useState(false);
  const ref = useRef(null);
  const wrapperRef = useRef(null);
  const resizerRef = useRef(null);
  const { width } = useWindowDimensions();
  const minWidth = width < 3000 ? width * 0.55 : width * 0.35;
  const maxWidth = width < 3000 ? width * 0.75 : width * 0.55;
  const wrapperWidth = useResizeX(wrapperRef, resizerRef, minWidth, minWidth, maxWidth);
  const data = useSelector(selectUserDetail);
  const onScroll = () => {
    const scrollTop = get(ref, 'current.scrollTop', 0);
    if (scrollTop === 0) setShowTransparency(false);
    else setShowTransparency(true);
  };

  return data
    ? (
      <>
        <Wrapper ref={ wrapperRef } wrapperWidth={ wrapperWidth }>
          <ResizerWrapper ref={ resizerRef } children={ <Resizer /> } />
          <DetailWrapper>
            <Header />
            { showTransparency && <Transparency /> }
            <Content tabRef={ ref } onScroll={ onScroll } showTransparency={ showTransparency }>
              <MainInfo />
            </Content>
          </DetailWrapper>
        </Wrapper>
      </>
      )
    : null;
};

export default UserDetail;
