import React from 'react';
import ScheduledAgents from '../../../ScheduledAgents';
import ScheduledCloudAgents from '../../../ScheduledCloudAgents';

const SECTIONS = [
  {
    label: 'Scheduled Agents',
    value: 'scheduledAgents',
    component: <ScheduledAgents />
  },
  {
    label: 'Scheduled Cloud Agents',
    value: 'scheduledCloudAgents',
    component: <ScheduledCloudAgents />
  }
];

export default SECTIONS;
