import styled from 'styled-components';

export const Wrapper = styled.div`
  width: ${(props) => (props.width ? `${props.width}` : '400px')};
  margin-bottom: 20px;
`;
Wrapper.displayName = 'Wrapper';

export const TextArea = styled.textarea`    
    border-radius: 3px;
    /* box-shadow: 0 1px 0 0 ${({ theme }) => theme.shadows.inputInset}; */
    padding: 14px;
    background-color: ${({ theme }) => theme.textfield.background};
    font-size: 14.5px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: ${({ theme }) => theme.textfield.text};
    width: ${(props) => (props.width ? `${props.width}` : '392px')};
    height: ${(props) => (props.height ? `${props.height}` : '137px')};
    border: 1px solid transparent;

    &::placeholder {
      color: ${({ theme }) => theme.text.taskPlaceholder};
      font-size: 13.5px;
      font-weight: 300;
    }

    &:hover{
      border-style: solid;
      border-color: ${({ theme }) => theme.shadows.inputInset};
      border-width: 1px 1px 1px 1px;
    }

    &:focus{
      background-color: ${({ theme }) => theme.textfield.focus};

      &::placeholder {
        color: transparent;
      }
    }
`;
TextArea.displayName = 'TextArea';
