import React from 'react';
import NewJiraTemplateModal from './Components/NewJiraTemplateModal';
import { useSelector } from 'react-redux';
import selectModal from 'store/modals/selectors';
import { MODAL_NEW_JIRA_TEMPLATE } from 'store/modals/modals';

const ConfigureIssueModal = () => {
  const show = useSelector((state) => selectModal(state, MODAL_NEW_JIRA_TEMPLATE));

  if (!show) return null;
  return <NewJiraTemplateModal />;
};

export default ConfigureIssueModal;
