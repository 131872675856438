import styled from 'styled-components';
import colors from 'Styles/colors';
import StandardTextField from 'Common/Components/StandardTextField';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ErrorMessage = styled.p`
  font-size: 14.5px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: ${colors.redPink};
  float: left;
`;
ErrorMessage.displayName = 'ErrorMessage';

export const TextField = styled(StandardTextField)`
  color: ${colors.grey17};
  font-weight: 400;
  font-size: 12.5px;
  margin-bottom: 0;
  display: block;
  width: ${(props) => (props.width ? props.width : '100%')};
  span {
    font-weight: 400;
  }
  input {
    box-shadow: ${(props) => (props.error ? 'inset 0 -1px 0 0 #ea3158' : 'none')};
  }
  svg {
    top: 34px;
  }
`;
TextField.displayName = 'TextField';
