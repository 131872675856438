import styled from 'styled-components';
import { Default } from 'Common/Components/Button/styled';

// eslint-disable-next-line import/prefer-default-export
export const Wrapper = styled.div`
  display: flex;
  justify-content: right;
  justify-content: space-between;
  margin-bottom: 19px;
`;
Wrapper.displayName = 'Wrapper';

export const CancelButton = styled(Default)`
  color: ${({ theme }) => theme.buttons.custom.color};
  background-color: ${({ theme }) => theme.buttons.custom.background};
  border: 1px solid ${({ theme }) => theme.buttons.custom.border};
  &:hover {
    background-color: ${({ theme }) => theme.buttons.custom.backgroundHover};
  }
  ${({ showNewUI }) => showNewUI && `
    margin-left: auto;
  `}
`;
CancelButton.displayName = 'CancelButton';
