/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-children-prop */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { EditorState, ContentState } from 'draft-js';
import Editor from 'draft-js-plugins-editor';
import 'draft-js-mention-plugin/lib/plugin.css';
import 'draft-js/dist/Draft.css';
import { useIntl } from 'react-intl';
import { ButtonsWrapper, CustomBlueButton, CancelButton } from './styled';

const CommentEditor = ({
  disabled, entity, entityId, placeholder, commentToEdit, setCommentToEdit, plugins, addComment, editComment,
  isBulk, handleClose
}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const intl = useIntl();
  const cancelLabel = intl.formatMessage({ id: 'common.components.comments.cancel' });
  const saveLabel = intl.formatMessage({ id: 'common.components.comments.save' });
  const commentLabel = intl.formatMessage({ id: 'common.components.comments.comment' });
  const onChange = (editorState) => setEditorState(editorState);

  const clearEditor = () => {
    setEditorState(EditorState.createEmpty());
    setCommentToEdit(null);
  };

  const handleAddComment = (text) => {
    if (text) {
      const data = {
        text,
        object_type: entity,
        object_id: entityId,
        create_date: new Date()
      };
      addComment(data);
    }
    clearEditor();
  };

  const handleEditComment = (text) => {
    const actualCommentText = get(commentToEdit, 'text', '');
    if (text && actualCommentText !== text) {
      const newComment = { ...commentToEdit, text };
      editComment(newComment, newComment.id);
    }
    clearEditor();
  };

  const onSave = () => {
    const text = editorState.getCurrentContent().getPlainText();
    if (commentToEdit) handleEditComment(text);
    else handleAddComment(text);
  };

  const onSaveBulk = () => {
    const text = editorState.getCurrentContent().getPlainText();
    addComment(text);
  };

  useEffect(() => {
    if (commentToEdit) {
      const commentToEditText = get(commentToEdit, 'text', '');
      const content = ContentState.createFromText(commentToEditText);
      setEditorState(EditorState.createWithContent(content));
      setEditorState((editorState) => EditorState.moveFocusToEnd(editorState));
    }
  }, [commentToEdit]);

  return (
    <div>
      <Editor
        editorState={ editorState }
        onChange={ onChange }
        plugins={ plugins }
        placeholder={ placeholder }
      />
      <ButtonsWrapper>
        { ((commentToEdit && !disabled) || isBulk) && <CancelButton onClick={ isBulk ? handleClose : clearEditor } children={ cancelLabel } /> }
        <CustomBlueButton disabled={ disabled } label={ (commentToEdit && !disabled) ? saveLabel : commentLabel } onClick={ isBulk ? onSaveBulk : onSave } />
      </ButtonsWrapper>
    </div>
  );
};

CommentEditor.propTypes = {
  disabled: PropTypes.bool.isRequired,
  entity: PropTypes.string.isRequired,
  entityId: PropTypes.number,
  placeholder: PropTypes.string,
  addComment: PropTypes.func.isRequired,
  editComment: PropTypes.func.isRequired,
  commentToEdit: PropTypes.object,
  setCommentToEdit: PropTypes.func.isRequired,
  plugins: PropTypes.array.isRequired
};

CommentEditor.defaultProps = {
  commentToEdit: null,
  placeholder: '',
  entityId: null
};

export default CommentEditor;
