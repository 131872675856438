import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  padding: 25px 15px;
  border-radius: 4px;
  border: solid 1px ${({ theme }) => theme.borders.detailPanel};
  background-color: ${({ theme }) => theme.backgrounds.modal};
  overflow: hidden;
`;
Wrapper.displayName = 'Wrapper';

export const Content = styled.div`
  overflow: auto;
  height: 98%;
  padding-top: 25px;
  padding-right: 15px;
  padding-bottom: 25px;
`;
Content.displayName = 'Content';
