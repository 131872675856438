import styled from 'styled-components';
import { ReactComponent as EditIcon } from 'Images/icon-action-bar-edit.svg';
import { ReactComponent as TrashIcon } from 'Images/icon-action-bar-trash.svg';
import { ReactComponent as ColumnIcon } from 'Images/icon-action-bar-column.svg';
import { ReactComponent as DownloadIcon } from 'Images/download-icon.svg';
import { ReactComponent as TagIcon } from 'Images/icon-action-bar-tags.svg';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
`;
Wrapper.displayName = 'Wrapper';

export const EditColumns = styled(ColumnIcon).attrs({
  id: 'qa-edit-columns-vuln'
})`
    height: 17px;
`;
EditColumns.displayName = 'EditColumns';

export const Trash = styled(TrashIcon).attrs({
  id: 'qa-delete-vuln'
})`
    height: 17px;
    g > path {
      fill: ${colors.red1};
    }
`;
Trash.displayName = 'Trash';

export const Edit = styled(EditIcon).attrs({
  id: 'qa-edit-vuln'
})`
    height: 17px;
    g > path {
      fill: ${({ theme }) => theme.icons.actionBar.color};
    }
`;
Edit.displayName = 'Edit';

export const Download = styled(DownloadIcon).attrs({
  id: 'qa-download-ws'
})`
    height: 17px;
`;

export const Tag = styled(TagIcon)`
    height: 17px;
`;
Tag.displayName = 'Tag';
