import styled from 'styled-components';
import { customFieldContent } from 'Styles/styles';

export const Wrapper = styled.div`
   width: 100%;
`;

Wrapper.displayName = 'Wrapper';

export const Container = styled.div`
   width: 384px;
`;

Container.displayName = 'Container';

export const Buttons = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 34px;
    > :nth-child(1){
        margin-right: 8px;
    }
`;

Buttons.displayName = 'Buttons';

export const Content = styled.span`
    ${customFieldContent}
`;

Content.displayName = 'Content';
