import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  enableDisable, massiveUpdateWorkspacesActive,
  unSelectAll
} from 'Screens/Contextualization/Workspaces/actions/Actions';
import { selectIsFetching, selectWorkspacesSelected } from 'store/Workspace/selectors';
import ModalWrapper from 'Common/Components/ModalWrapper';
import {
  ModalTitle, Title, BodyText, ModalContent, ButtonsContainer,
  ConfirmButton, CancelButton, Warning, ModalBody
} from 'Common/Components/ModalConfirmation/styled';
import useKeyPress from 'Hooks/useKeyPress';

const DeactivateConfirmationModal = ({ onCloseCallback, show, ws, isMassive, active }) => {
  const dispatch = useDispatch();
  const isFetching = useSelector(selectIsFetching);
  const workspacesSelected = useSelector(selectWorkspacesSelected);
  const numberWorkspaces = isMassive ? `${workspacesSelected.length} workspaces` : 'a workspace';
  const labelDone = isMassive ? 'Workspaces' : 'Workspace';

  const handleSubmit = () => {
    if (isMassive) dispatch(massiveUpdateWorkspacesActive(active));
    else dispatch(enableDisable(ws, false));
    dispatch(unSelectAll());
    onCloseCallback();
  };

  useKeyPress(() => {
    if (onCloseCallback) onCloseCallback();
  }, ['Escape']);

  useKeyPress(() => {
    handleSubmit();
  }, ['Enter']);

  if (!show) return null;
  return (
    <ModalWrapper>
      <ModalContent>
        <ModalTitle>
          <Warning />
          <Title>{'Archive Workspace'}</Title>
        </ModalTitle>
        <ModalBody>
          <BodyText>{`You’re archiving ${numberWorkspaces}.  Are you sure you want to proceed?`}</BodyText>
        </ModalBody>
        <ButtonsContainer>
          <CancelButton onClick={ onCloseCallback }>
            Cancel
          </CancelButton>
          <ConfirmButton onClick={ handleSubmit } disabled={ isFetching }>
            {`Archive ${labelDone}`}
          </ConfirmButton>
        </ButtonsContainer>
      </ModalContent>
    </ModalWrapper>
  );
};

export default DeactivateConfirmationModal;
