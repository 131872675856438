import styled from 'styled-components';

export const Wrapper = styled.div`
   & > :first-child:hover {
    & > svg g g path {
      fill: ${({ theme }) => theme.icons.twoFactor.hover};
    }
  }
`;
Wrapper.displayName = 'Wrapper';

export const Dropdown = styled.div`
  position: absolute;
  width: ${({ width }) => width || '130px'};
  box-shadow: 2px 2px 10px 0 ${({ theme }) => theme.shadows.actionDropDown};
  background-color: ${({ theme }) => theme.backgrounds.actionDropDown};
  border: 1px solid ${({ theme }) => theme.borders.actionDropDown};
  border-radius: 0.25rem;
  z-index: 3;
`;
Dropdown.displayName = 'Dropdown';
