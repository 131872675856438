import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectGroupBy, selectGroupByField, selectPage, selectRowsPerPage } from 'store/Filters/selectors';
import {
  selectIsFetching, selectSelectAll, selectShowDeleteConfirmation,
  selectShowDetail, selectVulnDetailId, selectVulnsCount, selectVulnsSelected
} from 'store/ManageGeneral/selectors';
import { selectVulnsGeneralFields } from 'store/Preferences/vulnsGeneral/selectors';
import { clearGroupByVulnsGeneral, deleteSelectedVulns, hideVulnModalDelete, redirectToVulnDetailGeneral, setGroupByVulnsGeneral, setPage, showVulnDetailGeneral, showVulnModalDelete } from 'store/ManageGeneral/actions';
import { resetDefault, setFieldVisibility, setNewOrderColumns } from 'store/Preferences/vulnsGeneral/actions';
import { Wrapper, LeftGroup, RightGroup, Separator, Group } from 'Common/Components/Contextualization/CustomActionBar/styled';
import Pagination from 'Common/Components/Pagination';
import CreateActions from 'Screens/Contextualization/Manage/components/CreateActions';
import EditButton from 'Screens/Contextualization/Manage/components/CustomActionBar/components/EditButton';
import TagButton from 'Screens/Contextualization/Manage/components/CustomActionBar/components/TagButton';
import CopyLink from 'Screens/Contextualization/Manage/components/CustomActionBar/components/CopyLink';
import CopyApiLink from 'Screens/Contextualization/Manage/components/CustomActionBar/components/CopyApiLink';
import DeleteButton from 'Screens/Contextualization/Manage/components/CustomActionBar/components/DeleteButton';
import DeleteConfirmationModal from 'Screens/Contextualization/Manage/components/CustomActionBar/components/DeleteConfirmationModal';
import DuplicateButton from '../DuplicateButton';
import RefreshButton from 'Screens/Contextualization/Manage/components/CustomActionBar/components/RefreshButton';
import Severity from 'Screens/Contextualization/Manage/components/CustomActionBar/components/Severity';
import Status from 'Screens/Contextualization/Manage/components/CustomActionBar/components/Status';
import AddNewEvidence from 'Screens/Contextualization/Manage/components/CustomActionBar/components/AddNewEvidence';
import SaveAsTemplate from 'Screens/Contextualization/Manage/components/CustomActionBar/components/SaveAsTemplate';
import ApplyTemplate from 'Screens/Contextualization/Manage/components/CustomActionBar/components/ApplyTemplate';
import AddRemoveColumnsButton from 'Screens/Contextualization/Manage/components/CustomActionBar/components/AddRemoveColumnsButton';
import FilterSideBarIcon from 'Screens/Contextualization/Manage/components/CustomActionBar/components/FilterSidebarIcon';
import GroupFieldSelector from 'Screens/Contextualization/Manage/components/CustomActionBar/components/GroupFieldSelector';
import SendToTool from 'Common/Components/Contextualization/SendToTool';
import AddComment from '../AddComment';
import { selectIsAdmin } from 'store/Faraday/selectors';
import QuickPlannerButton from 'Screens/Contextualization/Planner/QuickPlanner/QuickPlannerButton';

const ActionBar = ({ entity, showDuplicateModal }) => {
  const dispatch = useDispatch();
  const vulnsSelected = useSelector(selectVulnsSelected);
  const vulnsCount = useSelector(selectVulnsCount);
  const showVulnDetail = useSelector(selectShowDetail);
  const page = useSelector((state) => selectPage('vulnsGeneral', state));
  const rowsPerPage = useSelector((state) => selectRowsPerPage('vulnsGeneral', state));
  const showDeleteConfirmation = useSelector(selectShowDeleteConfirmation);
  const selectAll = useSelector(selectSelectAll);
  const isFetching = useSelector(selectIsFetching);
  const fields = useSelector(selectVulnsGeneralFields);
  const groupBy = useSelector((state) => selectGroupBy('vulnsGeneral', state));
  const hasVulns = vulnsCount > 0;
  const isGrouping = useSelector((state) => selectGroupByField('vulnsGeneral', state));
  const onPrev = () => dispatch(setPage(page - 1));
  const onNext = () => dispatch(setPage(page + 1));
  const isAdmin = useSelector(selectIsAdmin);
  const vulnDetailId = useSelector(selectVulnDetailId);

  return (
    <Wrapper>
      { showDeleteConfirmation
        ? <DeleteConfirmationModal
            showDeleteConfirmation={ showDeleteConfirmation }
            selectAll={ selectAll }
            isFetching={ isFetching }
            vulnsSelected={ vulnsSelected }
            deleteVulns={ () => dispatch(deleteSelectedVulns()) }
            hideModalDelete={ () => dispatch(hideVulnModalDelete()) }
            vulnsCount={ vulnsCount }
        />
        : null
      }
      <LeftGroup>
        <CreateActions entity={ entity } />
        <Separator />
        <Group>
          <EditButton hasVulns={ hasVulns } vulnsSelected={ vulnsSelected } openVulnDetail={ (vulnId) => dispatch(redirectToVulnDetailGeneral(vulnId)) } />
          <SendToTool hasVulns={ hasVulns } vulnsSelected={ vulnsSelected } isVulnDetailVisable={ showVulnDetail } vulnId={ vulnDetailId } entity="vulnsGeneral" />
          { !selectAll && <TagButton hasItems={ hasVulns } vulnsSelected={ vulnsSelected } tagsEntity="vulnsGeneralMenu" /> }
          <CopyLink hasVulns={ hasVulns } vulnsSelected={ vulnsSelected } />
          <CopyApiLink hasVulns={ hasVulns } vulnsSelected={ vulnsSelected } entity={ entity } />
          <Severity hasVulns={ hasVulns } vulnsSelected={ vulnsSelected } entity={ entity } groupBy={ isGrouping } />
          <Status hasVulns={ hasVulns } groupBy={ isGrouping } vulnsSelected={ vulnsSelected } entity={ entity } />
          <AddComment hasVulns={ hasVulns } />
          <AddNewEvidence hasVulns={ hasVulns } vulnsSelected={ vulnsSelected } openEvidenceTab={ (vulnId) => dispatch(showVulnDetailGeneral(vulnId, 'evidence')) } />
          <SaveAsTemplate hasVulns={ hasVulns } vulnsSelected={ vulnsSelected } entity={ entity } />
          <ApplyTemplate hasVulns={ hasVulns } vulnsSelected={ vulnsSelected } />
          <DeleteButton hasVulns={ hasVulns } vulnsSelected={ vulnsSelected } showVulnDetail={ showVulnDetail } showModalDelete={ () => dispatch(showVulnModalDelete()) } />
          <DuplicateButton hasVulns={ hasVulns } showModal={ showDuplicateModal } />
        </Group>
        { isAdmin &&
          <Group>
            <Separator />
            <QuickPlannerButton hasVulns={ hasVulns } entity={ entity } />
          </Group>
        }
      </LeftGroup>

      <RightGroup>
        { ((hasVulns) && (vulnsSelected.length === 0 && !showVulnDetail)) && <GroupFieldSelector groupBy={ groupBy } setGroupBy={ (groupBy) => dispatch(setGroupByVulnsGeneral(groupBy)) } clearGroupBy={ () => dispatch(clearGroupByVulnsGeneral()) } /> }
        { <Pagination offset={ hasVulns ? page : null } limit={ rowsPerPage } total={ vulnsCount } onPrev={ onPrev } onNext={ onNext } />}
        { hasVulns && <Separator /> }
        <Group>
          <RefreshButton entity={ entity } />
          <FilterSideBarIcon entity="vulnsGeneral" />
          <AddRemoveColumnsButton
            fields={ fields }
            setFieldVisibility={ (fieldObj, value) => dispatch(setFieldVisibility(fieldObj, value)) }
            setNewOrderColumns={ (columns) => dispatch(setNewOrderColumns(columns)) }
            resetDefault={ () => dispatch(resetDefault()) }
          />
        </Group>
      </RightGroup>
    </Wrapper>
  );
};

export default ActionBar;
