import styled from 'styled-components';
import { ReactComponent as Jira } from 'Images/jira_logo.svg';
import { Wrapper as WrapperUpgradeLicense } from 'Common/Components/UpgradeLicense/styled';

export const JiraLogo = styled(Jira)``;

export const Wrapper = styled.div`
  font-size: 21.5px;
  * > ${WrapperUpgradeLicense} {
    flex-direction: column;
  }
`;
Wrapper.displayName = 'Wrapper';

export const CustomImage = styled.img`
  display: block;
`;
CustomImage.displayName = 'CustomImage';

export const Tools = styled.div`
  display: flex;
  align-items: center;
`;
Tools.displayName = 'Tools';

export const View = styled.div`
  padding-left: 34px;
  width: 100%;
`;
View.displayName = 'View';

export const Title = styled.div`
  margin-bottom: 37px;
  user-select: none;
  font-size: 21.5px;
  font-weight: 600;
  line-height: 0.84;
  color: ${({ theme }) => theme.text.modalTitle2};
`;
Title.displayName = 'Title';
