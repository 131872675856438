/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch } from 'react-redux';
import { downloadBulkReport, downloadReport } from 'store/ExecutiveReport/actions';
import {
  Wrapper, DownloadIcon, Dropdown, Backdrop
} from './styled';
import ExportItem from './components/ExportItem';
import { isEmpty } from 'lodash';

const DownloadDropdown = ({ setError, setTitle, setMessage, reportsToDownload, reportId, reportName, template_name }) => {
  const dispatch = useDispatch();
  const [showDropdown, setDropdownVisibility] = useState(false);
  const isBulk = reportsToDownload?.ids.length > 1;
  const isWord = template_name.toLowerCase().includes('.doc');

  const exportBulkReports = () => dispatch(downloadBulkReport(reportsToDownload));
  const exportSingleReport = (type) => dispatch(downloadReport(reportId, reportName, type));
  const exportTo = isBulk ? exportBulkReports : exportSingleReport;

  const showErrorModal = () => {
    setTitle('Download Report(s)');
    setMessage('Please select only one Report.');
    setError(true);
    return null;
  };

  // const onClick = isEmpty(reportsToDownload?.ids) ? showErrorModal : exportTo;
  const onClick = () => isEmpty(reportsToDownload?.ids) ? showErrorModal() : setDropdownVisibility(!showDropdown);

  return (
    <>
      <Wrapper title="Download" onClick={ () => onClick() } keepVisible={ showDropdown }>
        <DownloadIcon />
        { showDropdown &&
        (
        <Dropdown>
          {isBulk
            ? <ExportItem type="generic" exportAction={ exportTo } />
            : isWord
              ? <ExportItem type="doc" exportAction={ exportTo } />
              : <ExportItem type="pdf" exportAction={ exportTo } />
          }
          <ExportItem type="csv" exportAction={ exportTo } disabled={ reportsToDownload?.ids.length > 1 } />
        </Dropdown>
        )}
      </Wrapper>
      { showDropdown && <Backdrop onClick={ () => setDropdownVisibility(false) } /> }
    </>
  );
};

DownloadDropdown.propTypes = {
  reportId: PropTypes.number.isRequired,
  reportName: PropTypes.string.isRequired,
  template_name: PropTypes.string.isRequired
};

export default DownloadDropdown;
