import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from 'Common/Components/Checkbox';
import get from 'lodash/get';
import { selectSchedulersSelected } from 'store/Schedule/selectors';
import { selectRow } from 'store/Schedule/actions';

export const CheckColumn = ({ rowInfo }) => { // eslint-disable-line import/prefer-default-export
  const scheduler = get(rowInfo, 'original', {});
  const schedulersSelected = useSelector((state) => selectSchedulersSelected(state));
  const dispatch = useDispatch();
  const isSelected = schedulersSelected.find((schedulerSelected) => schedulerSelected.value.id === scheduler.value.id);
  const handleClick = (e) => dispatch(selectRow(e, scheduler));

  return (
    <Checkbox theme="grey" state={ !!isSelected } onChange={ handleClick } />
  );
};
