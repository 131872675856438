/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import colors from 'Styles/colors';

const Wrapper = styled.div`
  flex: 1 1 0;
  width: 100%;

  .today rect {
      fill: ${colors.paleGold2}
  }

  .calendar {
    line {
      stroke: ${({ theme }) => theme.gantt.project};
      stroke-width: 1px;
      opacity: 0.5;
    }
    rect {
      stroke: none;
      fill: ${({ theme }) => theme.gantt.header};
    }
    text {
      font-family: 'CentraNo2';
      font-size: 10px;
      color: ${colors.grey5};
      fill: ${colors.grey5};
      text-transform: uppercase;
    } 
  }
   
  .bar text {
    fill: ${({ theme }) => theme.gantt.textColor};
    font-weight: 500;
  }

  /* .bar text {
    fill: inherit;
  } */
  
  /* .bar text { 
    fill: ${({ theme }) => theme.gantt.textColor};
    font-weight: bold;
    font-size: 13px;
  } */

  .rows rect:nth-child(even) {
    fill: ${({ theme }) => theme.backgrounds.sidebar};
  }

  .rows rect:nth-child(${(props) => props.row}) {
    fill: ${({ theme }) => theme.gantt.project};
  }

  /* .grid rect {
    fill: ${({ theme }) => theme.backgrounds.primary};
  } */
  
  .grid line { 
      stroke: ${({ theme }) => theme.gantt.header};
      stroke-width: 1;
  }
    
  .rows rect { 
      fill: ${({ theme }) => theme.backgrounds.sidebar};
  }

  .grid rect { 
    stroke-width: 1;
  }

`;
export default Wrapper;
