import styled from 'styled-components';
import { ReactComponent as Logo } from 'Images/icon-notifications.svg';
import colors from 'Styles/colors';

export const Bell = styled(Logo)`
  path {
    fill: ${({ theme }) => theme.icons.notifications.color} !important;
  }
`;
Bell.displayName = 'Bell';

export const Count = styled.span`
  position: absolute;
  top: 13px;
  left: 7px;
  border-radius: 50%;
  background: ${colors.red1};
  color: ${colors.white};
  width: 17px;
  height: 17px;
  min-width: max-content;
  padding: 2px 4px;
  font-size: 11px;
  font-weight: 600;
  line-height: 1.18;
  user-select: none;
  text-align: center;
  `;
Count.displayName = 'Count';

export const IconWrapper = styled.div`
  position: relative;
  cursor: pointer;
  height: 30px;
  width: 36px;
  `;
IconWrapper.displayName = 'IconWrapper';

export const Wrapper = styled.div`
  padding-top: 5px;
  position: relative;
  width: 44px;
  height: 40px;
`;
Wrapper.displayName = 'Wrapper';
