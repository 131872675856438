import React, { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ModalWrapper from 'Common/Components/ModalWrapper';
import {
  ModalContent, Flex, Buttons, DocLink, Subtitle,
  Title, WorkspaceWrapper, Label, dropdownStyle, Box, WsName, TrashIcon,
  CheckboxWrapper, StyledLabel, BoldStyledLabel
} from './styled';
import { Accept, Cancel } from 'Common/Components/Button/styled';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from 'store/modals/actions';
import { MODAL_MOVE_ASSET_WS } from 'store/modals/modals';
import { selectActiveWorkspaces } from 'store/Workspace/selectors';
import Select from 'react-select';
import Checkbox from 'Common/Components/Checkbox';
import { moveAssetsToWorkspace } from 'store/Host/actions';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import { ThemeContext } from 'Context';

const MoveAssetModal = () => {
  const dispatch = useDispatch();
  const [wsSelected, setWs] = useState(null);
  const [replaceAssets, setReplaceAssets] = useState(false);
  const currentWs = useSelector(selectCurrentWorkspace);
  const workspaces = useSelector(selectActiveWorkspaces);
  const workspaceList = workspaces.filter((el) => el.name !== currentWs)?.map((ws) => ({ label: ws.name, value: ws }));
  const intl = useIntl();
  const docLink = '';
  const { themeStyles } = useContext(ThemeContext);

  const handleClose = () => dispatch(closeModal(MODAL_MOVE_ASSET_WS));

  const handleAccept = () => {
    if (wsSelected) {
      dispatch(moveAssetsToWorkspace(wsSelected, replaceAssets));
      handleClose();
    }
  };

  const onSelectWs = (ws) => setWs(ws.value);

  const onRemoveWs = () => setWs(null);

  return (
    <ModalWrapper>
      <ModalContent>
        <Flex>
          <Title>{ intl.formatMessage({ id: 'modal.moveAsset.title' }) }</Title>
          <Buttons>
            <Cancel onClick={ handleClose } children="Cancel" />
            <Accept
              onClick={ handleAccept }
              children="Move"
            />
          </Buttons>
        </Flex>
        <Subtitle>
          { intl.formatMessage({ id: 'modal.moveAsset.subtitle' }) }
          <DocLink href={ docLink } target="_blank">{ intl.formatMessage({ id: 'modal.moveAsset.learnMore' }) }</DocLink>
        </Subtitle>
        <WorkspaceWrapper>
          <Label>{ intl.formatMessage({ id: 'modal.moveAsset.ws.label' }) }</Label>
          <Select
            value={ wsSelected }
            onChange={ onSelectWs }
            options={ workspaceList }
            controlShouldRenderValue={ false }
            placeholder={ intl.formatMessage({ id: 'modal.moveAsset.ws.placeholder' }) }
            styles={ dropdownStyle(themeStyles) }
            isDisabled={ wsSelected }
          />
          <Box>
            <WsName>{ wsSelected?.name }</WsName>
            { wsSelected ? <TrashIcon onClick={ onRemoveWs } /> : null }
          </Box>
        </WorkspaceWrapper>
        <CheckboxWrapper>
          <Checkbox checked={ replaceAssets } onChange={ () => setReplaceAssets(!replaceAssets) } state={ replaceAssets } />
          <StyledLabel>
            <FormattedMessage
              id="modal.moveAsset.checkboxLabel"
              values={ {
                b: (chunks) => <BoldStyledLabel children={ chunks } />
              } }
            />
          </StyledLabel>
        </CheckboxWrapper>
      </ModalContent>
    </ModalWrapper>
  );
};

export default MoveAssetModal;
