import styled from 'styled-components';
import { Dropdown } from 'Common/Components/Inputs';
import colors from 'Styles/colors';

export const StyledDropdown = styled(Dropdown)`
  ${({ isDisabled }) => isDisabled && 'opacity: 0.5;'};
  margin-bottom: 16px;
`;
StyledDropdown.displayName = 'StyledDropdown';

export const Roles = styled.div`
  display: flex;
  margin-bottom: 15px;

  & > *:not(:last-child) {
    margin-right: 20px;
  }
`;
Roles.displayName = 'Roles';

export const StyledInput = styled.input`
  width: 150px;
  height: 34px;
  border: 1px solid transparent;
  border-bottom: none;
  background-color: ${(props) => (props.isEmpty ? `${props.theme.textfield.background}` : `${props.theme.textfield.focus}`)};
  padding: 0 10px;
  font-size: 13.5px;
  line-height: 2.48;
  /* box-shadow: inset 0 -1px 0 0 ${colors.grey30}; */
  color: ${({ theme }) => theme.textfield.text};
  margin: 5px 0 22px 0;
  ${({ disabled }) => disabled && `
    opacity: 0.5;
    cursor: not-allowed;
  `};
  &::placeholder {
    color: ${colors.grey17};
    font-size: 12.5px;
  }
`;
StyledInput.displayName = 'StyledInput';

export const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
InputsWrapper.displayName = 'InputsWrapper';
