import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import { resetFilters } from 'store/Filters/actions';
import { getById, resetState, getReportsCustomLogo } from 'store/ExecutiveReportEditCreate/actions';
import { Wrapper, ContentWrapper } from './styled';
import ReportHeader from './components/Header';
import ReportForm from './components/ReportForm';
import NewReportPreview from './components/ReportPreview';
import { selectError, selectErrorMessage } from 'store/ExecutiveReport/selectors';
import ErrorDialog from 'Common/Components/ErrorDialog';
import { resetErrorValue } from 'store/ExecutiveReport/actions';

const ExecutiveReportEditCreateContext = ({ edit, match }) => {
  const dispatch = useDispatch();
  const workspaceSelected = useSelector(selectCurrentWorkspace);
  const error = useSelector(selectError);
  const errorMessage = useSelector(selectErrorMessage);

  useEffect(() => {
    return () => {
      dispatch(resetState());
      dispatch(resetFilters('reports'));
    };
  }, [match, edit, dispatch, workspaceSelected]);

  useEffect(() => {
    if (edit) {
      const reportId = get(match, 'params.id', -1);
      const parsedId = parseInt(reportId, 10);
      dispatch(getReportsCustomLogo(parsedId));
      dispatch(getById(parsedId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit, dispatch]);

  return (
    <>
    <Wrapper>
      <ReportHeader isEditing={ edit } />
      <ContentWrapper>
        <ReportForm isEditing={ edit } />
        <NewReportPreview />
      </ContentWrapper>
    </Wrapper>
    { error ? <ErrorDialog message={ errorMessage } resetError={ () => dispatch(resetErrorValue()) } /> : null }
    </>
  );
};

ExecutiveReportEditCreateContext.propTypes = {
  edit: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object.isRequired
};

ExecutiveReportEditCreateContext.defaultProps = {
  edit: false
};

export default ExecutiveReportEditCreateContext;
