import styled from 'styled-components';
import { slideInLeft } from 'Styles/effects';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 34px;
    max-width: 547px;
    background-color: ${({ theme }) => theme.backgrounds.sideModal};
    box-shadow: none;
    overflow-y: hidden;
    overflow-x: hidden;
    position: absolute;
    right: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    ${slideInLeft};
`;

Wrapper.displayName = 'Wrapper';

export default Wrapper;
