import styled from 'styled-components';
import { slideInRight } from 'Styles/effects';

export const ItemWrapper = styled.div`
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  padding-left: 23px;
  cursor: pointer;
  
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    background-color: ${({ theme }) => theme.buttons.sidebar.hover};
  }


    
    ${({ selected, theme }) => selected && `
      background-color: ${theme.buttons.sidebar.selected};
  `}
`;

export const Wrapper = styled.div`
  position: relative;
  & 
`;

export const Text = styled.div`
  margin-left: 13px;
  font-size: 15px;
  color: ${({ theme }) => theme.buttons.sidebar.text};
  width: auto;
  ${slideInRight}
  
  ${({ selected }) => selected &&
  `
    font-weight: 600;
  `}
`;

export const Icon = styled.div`
  width: 22px;
  display: flex;
  justify-content: center;
  &:not(.reports) svg {
    width: 22px;
  }
  svg > path {
    ${({ name, theme }) => name === 'Notifications' &&
    `
      stroke: ${theme.icons.sideBar.color} !important;
      fill: transparent !important;
    `}

    fill: ${({ theme }) => theme.icons.sideBar.color};
    ${({ name, theme }) => name === 'Notifications' &&
    `
      stroke: ${theme.icons.sideBar.color} !important;
      fill: transparent !important;
    `}
  }
  svg > g :last-child {
    fill: ${({ theme }) => theme.icons.sideBar.color};
    ${({ selected, theme }) => selected && `
      fill: ${theme.icons.sideBar.selected};
    `}
  }
  .reports {
    margin-left: 3px;
  }
  .kb {
    margin-left: 3px;
  }
`;
