import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { selectMaxSchedulesLimit } from 'store/Config/selector';

export const selectSelectedSection = (state) => get(state, 'schedule.selectedSection', 'scheduledAgents');

export const selectScheduledAgentsTotal = (state) => get(state, 'schedule.scheduledAgents.total', 0);

export const selectScheduledCloudAgentsTotal = (state) => get(state, 'schedule.scheduledCloudAgents.total', 0);

export const selectSchedulersPage = (state, section) => get(state, `schedule.${section}.page`, 0);

export const selectSchedulersPageSize = (state, section) => get(state, `schedule.${section}.pageSize`, 0);

export const selectSchedulersSortBy = (state, section) => get(state, `schedule.${section}.sortBy`, 0);

export const selectSchedulersSortDirection = (state, section) => get(state, `schedule.${section}.sortDirection`, 0);

export const selectSchedulersTotal = (state, section) => get(state, `schedule.${section}.total`, 0);

export const selectFields = (state, section) => get(state, `schedule.${section}.fields`, []); // obtiene el listado de campos para la tabla

export const selectIsFetching = (state, section) => get(state, `schedule.${section}.isFetching`, false);

export const selectData = (state, section) => get(state, `schedule.${section}.list`, []);

export const selectLastSelected = (state) => get(state, 'schedule.lastSelected', {});

export const selectSchedulersSelected = (state) => get(state, 'schedule.schedulersSelected', []);

export const selectSchedulerSelected = (state) => get(state, 'schedule.schedulersSelected[0]', {});

export const allSchedulersAreSelected = (state) => {
  const section = selectSelectedSection(state);
  const schedulersList = selectData(state, section);
  const shedulersSelected = selectSchedulersSelected(state);
  return schedulersList.every((testScheduler) => shedulersSelected.some((scheduler) => scheduler.id === testScheduler.id)) && shedulersSelected.length > 0;
};
export const someSchedulerIsSelected = (state) => {
  return selectSchedulersSelected(state).length > 0;
};

export const selectWorkspaceSelected = (state) => get(state, 'faraday.workspaceSelected', {});

// selecciono el primer item del array, corresponde al ultimo row seleccionado en la tabla
export const selectAgentExecutors = (state) => get(state, 'schedule.schedulersSelected[0].value.agent.executors', {});

// devuelve el total de schedules activos
export const selectTotalSchedules = (state) => {
  const schedules = get(state, 'schedule.table.list.data.rows', []);
  return schedules.length;
};

export const selectLimitReached = (state) => {
  const total = selectTotalSchedules(state);
  const limit = selectMaxSchedulesLimit(state);
  if (!limit) return false;
  return (total >= limit);
};

export const selectHasVulnTags = (state) => {
  const vulnTags = get(state, 'schedule.table.selected.lastRowSelected.value.vuln_tag', []);
  const vulnTagsChecked = get(state, 'schedule.vulnsTagsChecked', false);
  return !isEmpty(vulnTags) || vulnTagsChecked;
};

export const selectHasAssetsTags = (state) => {
  const assetTags = get(state, 'schedule.table.selected.lastRowSelected.value.host_tag', []);
  const assetsTagsChecked = get(state, 'schedule.assetsTagsChecked', false);
  return !isEmpty(assetTags) || assetsTagsChecked;
};

export const selectHasServicesTags = (state) => {
  const serviceTags = get(state, 'schedule.table.selected.lastRowSelected.value.service_tag', []);
  const serviceTagsChecked = get(state, 'schedule.servicesTagsChecked', false);
  return !isEmpty(serviceTags) || serviceTagsChecked;
};

export const selectIsCreateSchedule = (state) => get(state, 'schedule.isCreateSchedule', false);

export const selectError = (state, section) => get(state, `schedule.${section}.error`, 0);

export const selectErrorMessage = (state, section) => get(state, `schedule.${section}.errorMessage`, 0);

export const selectScheduledAgentsCount = (state) => get(state, 'schedule.count.agentSchedulers', 0);

export const selectScheduledCloudAgentsCount = (state) => get(state, 'schedule.count.cloudAgentSchedulers', 0);
