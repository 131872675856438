/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import CustomReactTable from 'Common/Components/Contextualization/CustomReactTable/styled';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { selectGroupByField } from 'store/Filters/selectors';
import {
  selectHostList,
  selectHostsSelected,
  selectIsFetching
} from 'store/HostsGeneral/selectors';
import { selectFields } from 'store/Preferences/hostsGeneral/selectors';
import { expandGroupByAssetsGeneral, selectHostRow, setOrderByAssetsGeneral, showContextMenu, unSelectAllHosts } from 'store/HostsGeneral/actions';
import getColumns from './Columns';
import { Wrapper } from 'Screens/Contextualization/Host/components/Table/styled';
import { selectRiskScoreFeatureFlag } from 'store/Faraday/selectors';
import useTheme from 'Hooks/UseTheme';

const Table = () => {
  const { themeStyles } = useTheme();
  const mockData = [...Array(13).keys()];
  const [expanded, setExpanded] = useState([]);
  const dispatch = useDispatch();
  const data = useSelector(selectHostList);
  const isFetching = useSelector(selectIsFetching);
  const fields = useSelector(selectFields);
  const hostsSelected = useSelector(selectHostsSelected);
  const groupByField = useSelector(state => selectGroupByField('assetsGeneral', state));
  const groupByColumns = getColumns(true, fields, groupByField, isFetching, false, themeStyles);
  const showContextMenuAction = (value, xPos, yPos) => dispatch(showContextMenu(value, xPos, yPos));
  const isAllowedByLicense = useSelector(selectRiskScoreFeatureFlag);
  const columns = getColumns(false, fields, groupByField, isFetching, isAllowedByLicense, themeStyles);

  const onExpandedChange = (expanded, index) => {
    if (expanded[index]) {
      const expandData = data[index];
      dispatch(expandGroupByAssetsGeneral(index, expandData));
    }
    setExpanded(expanded);
  };

  const getTrProps = (state, rowInfo, showContextMenuAction, isFetching) => {
    if (rowInfo && rowInfo.row && !rowInfo.original.count) {
      const selected = hostsSelected.filter((x) => x.id === rowInfo.original.id).length > 0;

      return {
        onContextMenu: (e) => {
          e.preventDefault();
          showContextMenuAction(true, e.pageX, e.pageY);
          if (!selected && !isFetching) {
            dispatch(unSelectAllHosts());
            dispatch(selectHostRow(e, rowInfo.original));
          }
        },
        style: {
          background: selected ? `${themeStyles.backgrounds.selectedTableItem}` : ''
        }
      };
    }

    return {};
  };

  const getThProps = (showContextMenuAction, isDisplayingContextMenu) => {
    return {
      onContextMenu: (e) => {
        e.preventDefault();
        if (isDisplayingContextMenu) showContextMenuAction(false);
      }
    };
  };

  const getSorting = (sorted) => {
    const importantVulnsSortIndex = sorted.findIndex((s) => s.id === 'severity_counts');

    if (importantVulnsSortIndex >= 0) {
      const desc = sorted[importantVulnsSortIndex].desc;
      const sortCriticalCount = { id: 'vulnerability_critical_generic_count', desc };
      const sortHighCount = { id: 'vulnerability_high_generic_count', desc };
      const sortMediumCount = { id: 'vulnerability_medium_generic_count', desc };

      const newSorting = [...sorted];
      newSorting.splice(importantVulnsSortIndex, 1, sortCriticalCount, sortHighCount, sortMediumCount);
      return newSorting;
    }
    return sorted;
  };

  const fetchData = (state) => {
    const { sorted } = state;
    if (!isFetching) {
      const sorting = getSorting(sorted);
      dispatch(setOrderByAssetsGeneral(sorting));
    }
  };
  return (
    <Wrapper>
      <CustomReactTable
        data={ isFetching ? mockData : data }
        columns={ columns }
        manual
        onFetchData={ fetchData }
        minRows={ 0 }
        showPagination={ false }
        getTrProps={ (state, rowInfo) => getTrProps(state, rowInfo, showContextMenuAction, isFetching) }
        getTheadThProps={ () => getThProps(showContextMenuAction, showContextMenu) }
        noDataText={ !isFetching && <FormattedMessage id="app.nodata" /> }
        expanded={ expanded }
        onExpandedChange={ onExpandedChange }
        SubComponent={ (row) => (
          <CustomReactTable
            data={ data[row.index].groupData }
            columns={ groupByColumns }
            manual
            minRows={ 0 }
            showPagination={ false }
            TheadComponent={ () => null }
            getTrProps={ getTrProps }
            style={ { overflow: 'hidden' } }
          />
        ) }
      />
    </Wrapper>
  );
};

export default Table;
