import styled from 'styled-components';

export const Wrapper = styled.div`
    background-color: ${({ theme }) => theme.backgrounds.editModalContent};
    min-height: 93px;
    padding: 9px 21px 20px 20px;
    border-radius: 3px;
`;

Wrapper.displayName = 'PlaceholderWrapper';

export const PlaceholderText = styled.span`
  font-size: 12.5px;
  line-height: 1.76;
  color: ${({ theme }) => theme.textfield.text};
  background-color: #F4F7FA;
  cursor: pointer;
`;

PlaceholderText.displayName = 'PlaceholderText';
