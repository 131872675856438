import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 282px;
  height: 100%;
  width: 100%;
  overflow: auto;
  background: ${({ theme }) => theme.backgrounds.sideModal};
  padding: 21px;
  position: relative;
`;
Wrapper.displayName = 'Wrapper';

export const WrapperTitle = styled.div`
  display: flex;
  margin-bottom: 36px;
  justify-content: flex-start;
  align-items: flex-start;
`;
WrapperTitle.displayName = 'Wrapper';

export const GoBack = styled.button`
  color: ${colors.blueCerulean};
  font-size: 13.5px;
  background-color: ${({ theme }) => theme.buttons.tertiary.background};
  border: none;
  min-width: 50px;
`;
GoBack.displayName = 'GoBack';

export const Title = styled.div`
  font-size: 15px;
  font-weight: 600;
  color: ${({ theme }) => theme.text.sectionTitle};
`;
Title.displayName = 'Title';

export const Name = styled.input`
  padding: 9px 11px;
  box-shadow: inset 0 -1px 0 0 ${({ theme }) => theme.textfield.shadow};
  background-color: ${({ theme }) => theme.textfield.background};
  border: 0;
  margin-bottom: 9px;
  font-size: 14px;
  color: ${({ theme }) => theme.textfield.text};
  &::placeholder {
    color: ${({ theme }) => theme.text.analyticsPlaceholder};
  }
  ::-webkit-input-placeholder {
    color: ${({ theme }) => theme.text.analyticsPlaceholder};
  }
`;
Name.displayName = 'Name';

export const Description = styled.textarea`
  padding: 9px 11px;
  box-shadow: inset 0 -1px 0 0 ${({ theme }) => theme.textfield.shadow};
  background-color: ${({ theme }) => theme.textfield.background};
  border: 0;
  margin-bottom: 20px;
  font-size: 14px;
  min-height: 55px;
  color: ${({ theme }) => theme.textfield.text};
  &::placeholder {
    color: ${({ theme }) => theme.text.analyticsPlaceholder};
  }
  ::-webkit-input-placeholder {
    color: ${({ theme }) => theme.text.analyticsPlaceholder};
  }
`;
Description.displayName = 'Description';

export const WrapperClose = styled.div`
  display: flex;
  flex-direction: row;
  & > :not(:last-child) {
    margin-right: 10px;
  }
`;
WrapperClose.displayName = 'WrapperClose';

export const WrapperConfirm = styled.div`
  display: flex;
  flex-direction: row;
  & > :not(:last-child) {
    margin-right: 10px;
  }
`;
WrapperConfirm.displayName = 'WrapperConfirm';

export const WrapperCheckbox = styled.div`
  display: flex;
  margin-bottom: 26px;
  & > :not(:last-child) {
    margin-right: 10px;
  }
`;
WrapperCheckbox.displayName = 'WrapperCheckbox';

export const Label = styled.div`
  font-size: 12.5px;
  line-height: 1.28;
  color: ${colors.blueGrey};
  cursor: pointer;
`;
Label.displayName = 'Label';
