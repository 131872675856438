import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Wrapper, LeftGroup, RightGroup, Separator, Group } from 'Common/Components/CustomActionBar/styled';
import { setPage } from 'Screens/Contextualization/Users/actions/Actions';
import {
  selectUserCount,
  selectShowDeleteConfirmation
} from 'store/Users/selectors';
import { selectPage, selectRowsPerPage } from 'store/Filters/selectors';
import CreateActions from '../CreateActions';
import Edit from './components/Edit';
import Delete from './components/Delete';
import Pagination from 'Common/Components/Pagination';
import DeleteConfirmationModal from './components/DeleteConfirmationModal';
import AssigWsButton from './components/AssignWsButton';
import TwoFactorAuth from './components/TwoFactorAuth';
import ChangeRole from './components/ChangeRole';

const ActionBar = () => {
  const dispatch = useDispatch();
  const totalUsersCount = useSelector(selectUserCount);
  const page = useSelector((state) => selectPage('users', state));
  const rowsPerPage = useSelector((state) => selectRowsPerPage('users', state));
  const hasUsers = totalUsersCount > 0;
  const onPrev = () => dispatch(setPage(page - 1));
  const onNext = () => dispatch(setPage(page + 1));
  const showDeleteConfirmation = useSelector(selectShowDeleteConfirmation);

  return (
    <Wrapper>
      { showDeleteConfirmation ? <DeleteConfirmationModal /> : null }
      <LeftGroup>
        <CreateActions />
        <Separator />
        <Group>
          <Edit />
          <AssigWsButton />
          <TwoFactorAuth />
          <ChangeRole />
          <Delete />
        </Group>
      </LeftGroup>
      { hasUsers &&
        <RightGroup>
          <Pagination offset={ page } limit={ rowsPerPage } total={ totalUsersCount } onPrev={ onPrev } onNext={ onNext } />
        </RightGroup>
      }
    </Wrapper>
  );
};

export default ActionBar;
