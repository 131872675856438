import styled from 'styled-components';
import colors from 'Styles/colors';

const sevColor = {
  critical: colors.warmPurple,
  high: colors.redPink,
  medium: colors.tangerine,
  low: colors.apple,
  info: colors.grey2,
  disable: '#d6d6d6'
};

export const Wrapper = styled.div`
  border-radius: 3px;
  margin-right: 15px;
  background-color: ${({ color }) => color ? sevColor[color] : '#90a9c0;'};
  width: 47px;
`;
export const Text = styled.p`
  color: ${({ theme }) => theme.text.severitiesDropdown};
  font-size: 17.5px;
  font-weight: 600;
  padding: 3px 5px;
  text-align: center;
`;
