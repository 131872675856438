import styled from 'styled-components';

export const Wrapper = styled.div`
    margin-bottom: 3px;
    text-align: initial;    
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
    font-size: 12.5px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.08;
    letter-spacing: normal;
    color: ${({ theme }) => theme.text.modalLabel};
    font-weight: 600;
    margin-bottom: ${(props) => (props.marginNone ? '0px' : '12px')};
    opacity: ${(props) => (props.disabled ? '0.3' : '1')};
`;
Title.displayName = 'Title';

export const CustomInput = styled.input`
    width: ${(props) => (props.width ? `${props.width}` : '224px')};
    height: ${(props) => (props.height ? `${props.height}` : '38px')};
    border: 1px solid transparent;
    /* box-shadow: 0 1px 0 0 ${({ theme }) => theme.shadows.inputInset}; */
    /* box-shadow: ${(props) => (props.login ? '' : '0 1px 0 0 #afafaf')}; */
    border-bottom: ${(props) => (props.error ? '1px solid red' : 'none')};
    background-color: ${({ login, isEmpty, theme }) => {
      if (login || isEmpty) return theme.textfield.background;
      return theme.textfield.background;
    }};
    padding-left: 15px;
    padding-right: 15px;
    font-size: 14.5px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.48;
    letter-spacing: normal;
    color: ${({ theme }) => theme.textfield.text};
    margin-top:13;

    &.full{
      background-color: ${({ theme }) => theme.textfield.focus}; 
    }

    &::placeholder {
      color: ${({ theme }) => theme.text.placeholder};
      font-size: 13.5px;
      font-weight: 300;
    }

    &:hover{
      border-style: solid;
      border-color: ${({ theme }) => theme.shadows.inputInset};
      border-width: ${(props) => (props.login ? '1px' : '1px 1px 1px 1px')};
    }

    &:focus{
        background-color: ${({ theme }) => theme.textfield.focus};        

        &::placeholder {
            color: transparent;
        }
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    &[type=number] {
    -moz-appearance: textfield;
    }

    &:-webkit-autofill {
      background-color: ${({ theme }) => theme.textfield.background} !important;
      color: ${({ theme }) => theme.textfield.text} !important;
      box-shadow: 0 0 0px 1000px ${({ theme }) => theme.textfield.background} inset !important;
      -webkit-text-fill-color: ${({ theme }) => theme.textfield.text} !important;
      border: none !important;
    }
    
`;
CustomInput.displayName = 'CustomInput';
