import React from 'react';
import { Wrapper } from './styled';
import RadioButton from 'Common/Components/RadioButton';
import { useDispatch, useSelector } from 'react-redux';
import { setProjectByDefault } from 'store/Settings/jiraActions';
import '../styles.css';
import { selectDefaultProject } from 'store/Settings/jiraSelectors';

const Radio = ({ name, projectKey }) => {
  const dispatch = useDispatch();
  const defaultProject = useSelector(selectDefaultProject);
  const onClick = () => dispatch(setProjectByDefault(projectKey));
  const checked = projectKey === defaultProject;

  return (
    <Wrapper>
      <RadioButton checked={ checked } label={ name } name={ 'projects' } className="custom-radio-label" onChange={ onClick } />
    </Wrapper>
  );
};

export default Radio;
