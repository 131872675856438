import React from 'react';
import ModalWrapper from 'Common/Components/ModalWrapper';
import { ModalContent, Content } from './styled';

const FlexModal = ({ children, z, height }) => (
  <ModalWrapper z={ z }>
    <ModalContent height={ height }>
      <Content>
        {children}
      </Content>
    </ModalContent>
  </ModalWrapper>
);

export default FlexModal;
