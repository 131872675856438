import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import {
  Wrapper, Title, Asterisk, Container
} from './styled';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DateInput = ({
  className, min, max, onChange, title, value, required
}) => {
  const [inputValue, setInputValue] = useState(value);

  const handleChange = (date) => {
    if (date) {
      const formattedDate = date.toISOString().split('T')[0];
      setInputValue(formattedDate);
      onChange(formattedDate);
    } else {
      setInputValue('');
      onChange('');
    }
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const onBlur = () => {
    if (!inputValue) return;

    const epochMinDate = min ? new Date(min).getTime() : null;
    const epochMaxDate = max ? new Date(max).getTime() : null;
    const utcDate = new Date(`${inputValue}T00:00:00`);
    const epochUtcDate = utcDate.getTime();

    if (
      (min && epochUtcDate < epochMinDate) ||
      (max && epochUtcDate > epochMaxDate) ||
      isNaN(epochUtcDate)
    ) {
      setInputValue('');
      onChange('');
    } else {
      onChange(epochUtcDate);
    }
  };

  return (
    <Wrapper className={ className }>
      <Title>
        {title}
        {required && <Asterisk>*</Asterisk>}
      </Title>
      <Container>
        <ReactDatePicker
          minDate={ min ? new Date(min) : null }
          maxDate={ max ? new Date(max) : null }
          onCalendarClose={ onBlur }
          onChange={ handleChange }
          selected={ inputValue ? new Date(`${inputValue}T00:00:00`) : null }
          dateFormat="yyyy-MM-dd"
          placeholderText="YYYY-MM-DD"
        />
      </Container>
    </Wrapper>
  );
};

DateInput.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  required: PropTypes.bool
};

DateInput.defaultProps = {
  className: '',
  min: null,
  max: null,
  value: '',
  required: true
};

export default DateInput;
