export default function getTrProps (themeStyles, rowInfo, setShowContextMenu, userSelected, selectUserAction, unSelectAll, isFetching) {
  const selected = userSelected.filter((x) => x.id === rowInfo.original.id).length > 0;

  if (rowInfo && rowInfo.row) {
    return {
      onContextMenu: (e) => {
        e.preventDefault();
        setShowContextMenu(true);
        if (!selected && !isFetching) {
          unSelectAll();
          selectUserAction(rowInfo.original);
        }
      },
      style: {
        background: selected ? `${themeStyles?.backgrounds?.selectedTableItem}` : ''
      },
      className: selected ? 'selected' : 'test-table-selector'
    };
  }
  return {};
}
