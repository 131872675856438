import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

export const PreviewWrapper = styled.div`
  background-color: ${({ theme }) => theme.backgrounds.modal};
  padding: 15px 18px 18px 21px;
  cursor: pointer;
  overflow: auto;
  margin: 20px;
  margin-top: 0;

  ul, ol {
    padding-left: 0;
    list-style-position: inside;
  }
  code {
    color: inherit;
  }
  pre {
    display: block;
    padding: 20px;
    line-height: 28px;
    background-color: #f5f5f5;
  }

  blockquote {
    position: relative;
    margin: 16px 0;
    padding: 5px 8px 5px 30px;
    background: none repeat scroll 0 0 rgba(102,128,153,.05);
    border: none;
    color: #333;
    border-left: 10px solid #d6dbdf;
  }

  a {
    color: ${({ theme }) => theme.text.expander}; 
  }
`;
PreviewWrapper.displayName = 'PreviewWrapper';

export const StyledReactMarkdown = styled(ReactMarkdown)`
  white-space: pre-wrap;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: normal;
  color: #63758d;
  h1 { font-size: 21px; font-weight: bold; }
  h2 { font-size: 17px; font-weight: bold; }
  h3 { font-size: 15px; font-weight: bold; }
  h4 { font-size: 14px; font-weight: bold; }
  h5 { font-size: 13px; font-weight: bold; }
`;
StyledReactMarkdown.displayName = 'StyledReactMarkdown';
