/* eslint-disable no-undef */
/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import colors from 'Styles/colors';
import { Col } from 'Common/Components/Grid';

export const StyledCol = styled(Col)`
  height: 157px;
  margin-top: 50px;
  border-top: solid 1px ${({ theme }) => theme.borders.preferenceMenuLine};
  border-bottom: solid 1px ${({ theme }) => theme.borders.preferenceMenuLine};
  background-color: ${({ theme }) => theme.backgrounds.table};
  overflow: hidden;
  & > *:not(:last-child) {
    border-bottom: 1px solid ${colors.light2};
  }
`;
StyledCol.displayName = 'StyledCol';

export const ItemContainer = styled.div`
  max-height: 119px;
  overflow-y: auto;
  & > *:not(:last-child) {
    border-bottom: 1px solid ${colors.light2};
    border-bottom: solid 1px ${({ theme }) => theme.borders.preferenceMenuLine};

  }
`;
ItemContainer.displayName = 'ItemContainer';

export const HeaderText = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: left;
  padding-left: 38px;
  gap: 9px;
  font-family: 'CentraNo2';
  font-size: 10px;
  color: var(--bluey-grey);
`;
HeaderText.displayName = 'HeaderText';

export const Text = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: left;
  /* font-family: 'SequelSans-Head'; */
  font-size: 11.5px;
  color: ${colors.blueGrey};
`;
Text.displayName = 'Text';

export const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 10px 0px 0px 9px;
`;
RadioContainer.displayName = 'RadioContainer';

export const TextWrapper = styled.div`
  width: ${(props) => (props.width ? `${props.width}` : undefined)};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:first-child {
    margin-right: 10px;
  }
`;
TextWrapper.displayName = 'TextWrapper';

export const HeaderTextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.width ? `${props.width}` : undefined)};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${colors.blueGrey};
`;
HeaderTextWrapper.displayName = 'HeaderTextWrapper';

export const Line = styled.div`
  border: 1px dashed ${colors.blueGrey};
  height: 12px;
`;
Line.displayName = 'Line';
