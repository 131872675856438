import styled from 'styled-components';

import { ReactComponent as _Resizer } from 'Images/resize_bottom_right.svg';

export const InputWrapper = styled.div.attrs(({ containerHeight }) => {
  let height = '';
  if (containerHeight) height = `${containerHeight}px`;
  return ({ style: { height } });
})`
  width: 100%;
`;
InputWrapper.displayName = 'InputWrapper';

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  display: ${({ children }) => (children ? 'flex' : 'none')};
  text-transform: capitalize;
  flex: 0 0 44px;
  padding: 9px 20px;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.73;
  letter-spacing: normal;
  color: ${({ theme }) => theme.textfield.text} !important;
  user-select: none;
  text-align: left;
`;
Title.displayName = 'Title';

export const MDButtonsWrapper = styled.div`
  height: 40px;
  width: 100%;
  background-color: ${({ theme }) => theme.backgrounds.detailFieldsMDButtons};
`;
MDButtonsWrapper.displayName = 'MDButtonsWrapper';

export const Resizer = styled(_Resizer)`
  cursor: ns-resize;
  position: absolute;
  bottom: 10px;
  right: 10px;
`;
Resizer.displayName = 'Resizer';
