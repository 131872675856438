import styled from 'styled-components';
import { ReactComponent as FaradayWhite } from 'Images/faraday_white_logo.svg';
import { ReactComponent as Faraday } from 'Images/faraday_logo.svg';

const wrapperStyle = {
  light: 'linear-gradient(37deg, #cad3db 1%, #f1f5f9 81%, #ececec 92%, #d3d3d3 104%, #dedede 110%)',
  dark: 'linear-gradient(38deg, #410a20 1%, #141414 74%, #565656)',
  generic: 'linear-gradient(32deg, #fff 84%, #d3d3d3 109%, #dedede 111%)'
};

const titleWrapperStyle = {
  light: 'linear-gradient(51deg, #cad3db 24%, #f1f5f9 93% )',
  dark: 'linear-gradient(51deg, #141414 24%, #410a20 75%)',
  generic: ''
};

const textColorStyle = {
  light: '#1c2243',
  dark: '#fff',
  generic: '#1c2243'
};

export const Wrapper = styled.div`
  margin: 0px auto auto auto;
  width: 100%;
  max-width: 170px;
  flex: 0 0 170px;
  display: flex;
  flex-direction: column;
  background-image: ${({ themeTemplate }) => wrapperStyle[themeTemplate]};
  border: ${({ selected }) => (selected ? 'solid 2px #639efe' : 'solid 1px #ddd')};
  /* box-shadow: 12px 12px 50px 0 rgba(207, 207, 207, 0.5); */
  box-shadow: ${({ theme }) => theme?.boxShadow?.modal};
  height: 100%;
`;
Wrapper.displayName = 'Wrapper';

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${({ thumbnail }) => thumbnail ? '30px' : '80px'};
  margin: 0px 0px 10px 0px;
  background-image: ${({ themeTemplate }) => titleWrapperStyle[themeTemplate]};
`;
Wrapper.displayName = 'Wrapper';

export const GenericTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: ${({ thumbnail }) => thumbnail ? '0' : '32px 0px'};
  background-image: ${({ themeTemplate }) => titleWrapperStyle[themeTemplate]};
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  margin-top: ${({ themeTemplate, thumbnail }) => (((themeTemplate === 'generic') || (thumbnail)) ? '0px' : '20px')};
  margin-right: auto;
  margin-left: 30px;
  margin-bottom: 8px;
  min-height: 15px;
  text-align: left;
  user-select: none;
  font-size: 9px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width:  ${({ thumbnail }) => thumbnail ? '75px' : '138px'};
  width:  ${({ thumbnail }) => thumbnail ? '75px' : '138px'};
  color: ${({ themeTemplate }) => textColorStyle[themeTemplate]};

`;
Title.displayName = 'Title';

export const Enterprise = styled.div`
  min-height: 10px;
  text-align: left;
  user-select: none;
  margin: 0 10px auto 30px;
  font-size: 7px;
  font-weight: lighter;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width:  ${({ thumbnail }) => thumbnail ? '75px' : '138px'};
  width:  ${({ thumbnail }) => thumbnail ? '75px' : '138px'};
  color: ${({ themeTemplate }) => textColorStyle[themeTemplate]};
`;
Enterprise.displayName = 'Enterprise';

export const StyledDate = styled.div`
  user-select: none;
  text-align: left;
  margin: 21px 0 10px 30px;
  font-size: 4px;
  font-weight: lighter;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${({ themeTemplate }) => textColorStyle[themeTemplate]};
`;
Enterprise.displayName = 'Enterprise';

export const FaradayWhiteLogo = styled(FaradayWhite)`
  cursor: default;
  width: 20px;
  height: 5px;
  margin: 9px auto 21px 30px;
`;
FaradayWhiteLogo.displayName = 'FaradayWhiteLogo';

export const FaradayLogo = styled(Faraday)`
  cursor: default;
  width: 20px;
  height: 5px;
  margin: 9px auto 21px 30px;
`;
FaradayLogo.displayName = 'FaradayLogo';

export const Separator = styled.div`
  margin: 15px 0px 10px auto;
  width: 75%;
  height: 1px;
  opacity: 0.32;
  background-color: #d8d8d8;
`;
Separator.displayName = 'Separator';
