import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  changeStatus, runSchedule, getCloudSchedules
} from 'store/Schedule/actions';
import {
  selectIsFetching, selectData, selectFields, selectSchedulersSelected
} from 'store/Schedule/selectors';
import { MODAL_SCHEDULER_NO_WS_WARNING, MODAL_SCHEDULER_OFFLINE_AGENT_WARNING } from 'store/modals/modals';
import { openModal } from 'store/modals/actions';
import getColumns from 'Screens/Automation/Schedule/components/ScheduledAgents/Columns';
import { TableWrapper } from '../../styled';
import EmptySchedule from '../EmptySchedule';
import CustomReactTable from 'Common/Components/Contextualization/CustomReactTable/styled';
import useTheme from 'Hooks/UseTheme';

const ScheduledCloudAgents = () => {
  const { themeStyles } = useTheme();
  const section = 'scheduledCloudAgents';
  const dispatch = useDispatch();
  const fields = useSelector((state) => selectFields(state, section));
  const isFetching = useSelector((state) => selectIsFetching(state, section));
  const data = useSelector((state) => selectData(state, section));
  const schedulersSelected = useSelector(selectSchedulersSelected);
  const mockData = [...Array(15).keys()];

  const noDataText = isFetching ? '' : <EmptySchedule />;
  const fetchData = () => !isFetching && dispatch(getCloudSchedules());

  const showNoWsWarning = (workspaces) => {
    const schedulerWithoutWs = workspaces.length === 0;
    return schedulerWithoutWs;
  };

  const onChangeStatus = (id, status, workspaces, type) => {
    const emptyWorkspaces = showNoWsWarning(workspaces);
    if (emptyWorkspaces) {
      dispatch(openModal(MODAL_SCHEDULER_NO_WS_WARNING));
    } else {
      dispatch(changeStatus(id, status, type));
    }
  };

  const run = (e, id, workspaces, isOnline, type) => {
    const emptyWorkspaces = showNoWsWarning(workspaces);
    const offlineAgent = type === 'agent' && !isOnline;
    if (emptyWorkspaces) {
      dispatch(openModal(MODAL_SCHEDULER_NO_WS_WARNING));
    } else if (offlineAgent) {
      dispatch(openModal(MODAL_SCHEDULER_OFFLINE_AGENT_WARNING));
    } else {
      dispatch(runSchedule(id, type));
      e.stopPropagation();
    }
  };

  const getTrProps = (rowInfo, schedulersSelected) => {
    const selected = schedulersSelected.find((scheduler) => scheduler.id === rowInfo.original.id);
    if (rowInfo && rowInfo.row) {
      return {
        style: {
          background: selected ? `${themeStyles?.backgrounds?.selectedTableItem}` : ''
        }
      };
    }
    return {};
  };

  const getThProps = (column, fields) => {
    const defaultMin = fields[column.id] ? fields[column.id].defaultWidth : 0;
    const maxWidth = fields[column.id] ? fields[column.id].maxWidth : 0;
    const response = { minWidth: defaultMin, maxWidth };
    return {
      style: defaultMin ? response : {}
    };
  };

  return (
    <TableWrapper>
      <CustomReactTable
        data={ isFetching ? mockData : data }
        columns={ getColumns(fields, onChangeStatus, isFetching, run) }
        minRows={ 0 }
        manual
        onFetchData={ fetchData }
        showPagination={ false }
        getTrProps={ (_, rowInfo) => getTrProps(rowInfo, schedulersSelected) }
        getTheadThProps={ (_, __, column) => getThProps(column, fields) }
        noDataText={ noDataText }
      />
    </TableWrapper>
  );
};

export default ScheduledCloudAgents;
