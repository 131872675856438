import React from 'react';
import { GridViewButton, ListViewButton } from '../WSActionBar/components/RightGroup';

const ViewTypeWorkspaces = () => {
  return (
    <>
      <GridViewButton />
      <ListViewButton />
    </>
  );
};

export default ViewTypeWorkspaces;
