import styled from 'styled-components';

export const Title = styled.div`
  color: ${({ theme }) => theme.text.modalTitle};
  font-weight: 600;
  font-size: 21.5px;
  user-select: none;
`;
Title.displayName = 'Title';

export const Subtitle = styled.div`
  margin-top: 25px;
  color: ${({ theme }) => theme.textfield.text};
  font-size: 12.5px;
  font-weight: 300;
  display: inline-block;
  text-align: left;
  user-select: none;
`;
Subtitle.displayName = 'Subtitle';

export const Span = styled.span`
  margin-top: auto;
  margin-bottom: auto;
  color: ${({ theme }) => theme.textfield.text};
  font-size: 12.5px;
  font-weight: 300;
  display: inline-block;
  text-align: left;
  user-select: none;
`;
Subtitle.displayName = 'Subtitle';

export const TagContainer = styled.div`
  display: flex;
  flex-direction: 'row';
`;
TagContainer.displayName = 'TagContainer';
