import styled from 'styled-components';
import { ReactComponent as MainDuplicateSVG } from 'Images/main-duplicate.svg';
import { ReactComponent as DuplicateSVG } from 'Images/duplicate.svg';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
Wrapper.displayName = 'Wrapper';

export const Text = styled.p`
  margin: auto 15px auto 0px;
  font-size: 14.5px;
  color: ${({ confirmed, theme }) => (confirmed ? theme.text.tableVulnNameConfirmed : theme.text.tableVulnName)}; 
  font-weight: ${({ confirmed }) => (confirmed ? '600' : 'normal')}; 
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &:hover {
    text-decoration: underline;
  }
`;
Text.displayName = 'Text';

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const IconWrapper = styled.div`
  width: 19px;
  height: 19px;
  margin-left: auto;
  cursor: default;
  margin-right: 14px;
`;
IconWrapper.displayName = 'IconWrapper';

export const MainDuplicateIcon = styled(MainDuplicateSVG)`
  cursor: default;
  `;
MainDuplicateIcon.displayName = 'DuplicateIcon';

export const DuplicateIcon = styled(DuplicateSVG)`
  cursor: default;
`;
DuplicateIcon.displayName = 'DuplicateIcon';
