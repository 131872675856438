/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-unresolved */
import styled from 'styled-components';

export const CodeInput = styled.input.attrs({
  maxLength: 1,
  size: 1,
  type: 'password'
})`
  display: inline-block;
  width: 100%;
  max-width: 34px;
  height: 34px;
  border: 1px solid transparent;
  /* box-shadow: 0 1px 0 0 #afafaf; */
  border-bottom: none;
  background-color: ${({ theme }) => theme.textfield.background};
  font-size: 14.5px;
  font-weight: 300;
  line-height: 2.48;
  color: ${({ theme }) => theme.textfield.text};
  border-radius: 3px;
  text-align: center;
`;
CodeInput.displayName = 'Code';
