import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectTable, selectSelectedTable, selectError, selectMessage } from 'store/Settings/serviceNowSelectors';
import { setField } from 'store/Settings/serviceNowActions';
import { Wrapper, Title, Content } from '../../../SolarWinds/components/MainSettings/styled';
import Settings from './components/Settings';
import Oauth from './components/Oauth';
import Template from './components/Template';
import StandardDropdown from 'Common/Components/StandarDropdown';
import IncidentConfiguration from './components/IncidentConfiguration';
import { Label } from './components/IncidentConfiguration/styled';
import Error from '../../../Error';

const MainSettings = () => {
  const tableOptions = useSelector(selectTable);
  const selectedTable = useSelector(selectSelectedTable);
  const isIncidentConfigSelected = selectedTable === 'Incidents';
  const dispatch = useDispatch();
  const error = useSelector(selectError);
  const errorMessage = useSelector(selectMessage);

  const onChange = (field, value) => {
    dispatch(setField(field, value));
  };

  const onCloseError = () => {
    dispatch(setField('error', false));
    dispatch(setField('message', ''));
  };

  return (
    <Wrapper>
      <Title children='Main settings' />
      <Content>
        <Settings />
        <Oauth />
        <Template />
        <Label>Category</Label>
        <StandardDropdown field="table" options={ tableOptions } updateValue={ onChange } value={ selectedTable } defaultValue={ selectedTable } width="266px" />
        { isIncidentConfigSelected && <IncidentConfiguration /> }
        { error && errorMessage ? <Error message={ errorMessage } close={ onCloseError } /> : null }
      </Content>
    </Wrapper>
  );
};

export default MainSettings;
