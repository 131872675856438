import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { setField } from 'store/Settings/serviceNowActions';
import { selectUrl } from 'store/Settings/serviceNowSelectors';
import {
  Wrapper, Content, Url
} from './styled';

const Settings = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const url = useSelector(selectUrl);

  const onChange = (field, value) => {
    dispatch(setField(field, value));
  };

  return (
    <Wrapper>
      <Content>
        <Url title={ intl.formatMessage({ id: 'preferences.ticketingTools.serviceNow.settings.url.title2' }) } placeholder={ intl.formatMessage({ id: 'preferences.ticketingTools.serviceNow.settings.url.placeholder' }) } value={ url } defaultValue={ url } onChange={ (v) => { onChange('url', v); } } name="url" mandatory />
      </Content>
    </Wrapper>
  );
};
export default Settings;
