import React, { useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import { toolOptions } from 'Screens/Contextualization/Constants';
import { openModal } from 'store/modals/actions';
import {
  MODAL_SEND_TO_JIRA, MODAL_VULNS_NOT_CONFIRMED, MODAL_SEND_TO_SERVICENOW,
  MODAL_SEND_TO_GITLAB, MODAL_SEND_TO_WHD, MODAL_UPGRADE_LICENSE
} from 'store/modals/modals';
import {
  PERMISSION_TYPES, FEATURE_TICKETING_GITLAB, FEATURE_TICKETING_JIRA, FEATURE_TICKETING_SERVICENOW, FEATURE_TICKETING_WHD
} from 'Hooks/usePermission/permissions';
import { selectAllowedByFeature, selectAllowedByRole } from 'Hooks/usePermission/selector';
import { selectVulnsSelected } from 'store/Manage/selectors';
import { getServiceNowSettings } from 'store/Settings/serviceNowActions';
import { Wrapper, Button, SendIcon, dropdownStyle } from './styled';
import { fetchActiveConfig } from 'store/Settings/settingsActions';
import { selectActiveConfig } from 'store/Settings/settingsSelectors';
import { ThemeContext } from 'Context';

const SendTo = () => {
  const dispatch = useDispatch();
  const [tool, setTool] = useState('jira');
  const vulnsSelected = useSelector(selectVulnsSelected);

  const isEnabledJira = useSelector((state) => selectAllowedByFeature(state, FEATURE_TICKETING_JIRA, PERMISSION_TYPES.CREATE));
  const isEnabledGitlab = useSelector((state) => selectAllowedByFeature(state, FEATURE_TICKETING_GITLAB, PERMISSION_TYPES.CREATE));
  const isEnabledServiceNow = useSelector((state) => selectAllowedByFeature(state, FEATURE_TICKETING_SERVICENOW, PERMISSION_TYPES.CREATE));
  const isEnabledWhd = useSelector((state) => selectAllowedByFeature(state, FEATURE_TICKETING_WHD, PERMISSION_TYPES.CREATE));

  const isEnabledByRoleJira = useSelector((state) => selectAllowedByRole(state, FEATURE_TICKETING_JIRA, PERMISSION_TYPES.CREATE));
  const isEnabledByRoleGitlab = useSelector((state) => selectAllowedByRole(state, FEATURE_TICKETING_GITLAB, PERMISSION_TYPES.CREATE));
  const isEnabledByRoleServiceNow = useSelector((state) => selectAllowedByRole(state, FEATURE_TICKETING_SERVICENOW, PERMISSION_TYPES.CREATE));
  const isEnabledByRoleWhd = useSelector((state) => selectAllowedByRole(state, FEATURE_TICKETING_WHD, PERMISSION_TYPES.CREATE));
  const configActive = useSelector(selectActiveConfig);

  const { themeStyles } = useContext(ThemeContext);

  const vulnsNotConfirmed = () => (vulnsSelected.some((e) => e.confirmed === false));

  const validate = (isEnabled, byRole) => {
    if (!isEnabled) {
      dispatch(openModal(MODAL_UPGRADE_LICENSE));
      return false;
    }
    if (!byRole) return false;
    if (vulnsNotConfirmed()) {
      dispatch(openModal(MODAL_VULNS_NOT_CONFIRMED));
      return false;
    }
    return true;
  };

  const sendToJira = () => {
    if (validate(isEnabledJira, isEnabledByRoleJira)) dispatch(openModal(MODAL_SEND_TO_JIRA));
  };

  const sendToServicenow = () => {
    if (validate(isEnabledServiceNow, isEnabledByRoleServiceNow)) {
      dispatch(getServiceNowSettings());
      dispatch(openModal(MODAL_SEND_TO_SERVICENOW));
    }
  };
  const sendToGitlab = () => {
    if (validate(isEnabledGitlab, isEnabledByRoleGitlab)) dispatch(openModal(MODAL_SEND_TO_GITLAB));
  };
  const sendToSolarWinds = () => {
    if (validate(isEnabledWhd, isEnabledByRoleWhd)) dispatch(openModal(MODAL_SEND_TO_WHD));
  };

  const sendVulnTo = {
    jira: () => sendToJira(),
    servicenow: () => sendToServicenow(),
    gitlab: () => sendToGitlab(),
    whd: () => sendToSolarWinds()
  };

  const send = () => sendVulnTo[tool]();

  function mapOptionsWithActiveStatus (options, activeConfig) {
    return options.map(option => ({
      ...option,
      isDisabled: !activeConfig[option.value].active // Sets isDisabled to true if the config is false
    }));
  }

  return (
    <Wrapper>
      <Button onClick={ send } disabled={ !configActive[tool].active } >
        <SendIcon disabled={ !configActive[tool].active } />
        Send to
      </Button>
      <Select
        options={ mapOptionsWithActiveStatus(toolOptions, configActive) }
        value={ toolOptions.filter((opt) => opt.value === tool) }
        onChange={ (e) => setTool(e.value) }
        styles={ dropdownStyle(themeStyles) }
        isSearchable={ false }
        onMenuOpen={ () => dispatch(fetchActiveConfig()) }
      />
    </Wrapper>
  );
};

export default SendTo;
