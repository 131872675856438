import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as DeleteImage } from 'Images/tool_trash.svg';
import { ReactComponent as EditImage } from 'Images/icon-edit.svg';
import { ReactComponent as CloneImage } from 'Images/report-clone.svg';
import { ReactComponent as GeneratingImage } from 'Images/icon_report_generating.svg';
import { ReactComponent as WordImage } from 'Images/report-word.svg';
import { ReactComponent as PDFImage } from 'Images/report-pdf.svg';
import { ReactComponent as warningIcon } from 'Images/warning-delete.svg';

export const ColumnName = styled.div`
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.11px;

    color: ${({ isProcessing, hasError, theme }) => {
        if (isProcessing) return `${colors.blueGrey} !important`;
        if (hasError) return `${colors.redPink} !important`;
        return `${theme.text.tableReportsName} !important`;
    }};

    && {
        color: ${({ isProcessing, hasError, theme }) => {
            if (isProcessing) return `${colors.blueGrey}`;
            if (hasError) return `${colors.redPink}`;
            return `${theme.text.tableReportsName} !important`;
        }};
    }
`;

ColumnName.displayName = 'ColumnName';

export const RetryLabel = styled.div`
    text-decoration: underline;
    user-select: none;
    cursor: pointer;
    text-align: left;
    font-size: 13.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #0082ed;
`;
RetryLabel.displayName = 'RetryLabel';

export const ColumnCreator = styled.div`
    font-size: 13.5px;
    color: ${({ theme }) => (theme.text.tableVulnName)}; 
`;
ColumnCreator.displayName = 'ColumnCreator';

export const ColumnLastActivity = styled.div`
    font-size: 13.5px;
    color: ${({ theme }) => (theme.text.tableVulnName)}; 
`;
ColumnLastActivity.displayName = 'ColumnLastActivity';

export const ColumnVulns = styled.div`
    font-size: 13.5px;
    color: ${({ theme }) => (theme.text.tableVulnName)}; 
    text-align: left;
    padding-right: 19px;
`;
ColumnVulns.displayName = 'ColumnVulns';

export const DeleteIcon = styled(DeleteImage)`
    margin: auto;
    g, path {
      fill: #90A8BF;
    }
`;
DeleteIcon.displayName = 'DeleteIcon';

export const EditIcon = styled(EditImage)`
    margin: auto;
    g, path {
      fill: #90A8BF;
    }
`;
EditIcon.displayName = 'EditIcon';

export const CloneIcon = styled(CloneImage)`
    margin: auto;
`;
EditIcon.displayName = 'EditIcon';

export const ProcessingIcon = styled(GeneratingImage)`
    cursor: default;
`;
ProcessingIcon.displayName = 'ProcessingIcon';

export const PDFIcon = styled(PDFImage)`
    cursor: default;
    height: 23px;
    width: 20px;
`;
PDFIcon.displayName = 'PDFIcon';

export const WarningWrapper = styled.div`
    height: 38px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;
WarningWrapper.displayName = 'WarningWrapper';

export const VerticalLine = styled.div`
    height: 38px;
    border-left: 6px solid #ea3158;
`;
VerticalLine.displayName = 'VerticalLine';

export const WarningIcon = styled(warningIcon)`
    cursor: default;
    width: 18px;
    height: 17px;
    margin: 0 7px 0 13px;
    g, path {
      fill: #ea3158;
    }
`;
WarningIcon.displayName = 'WarningIcon';

export const WordIcon = styled(WordImage)`
    cursor: default;
    height: 23px;
    width: 20px;
`;
WordIcon.displayName = 'WordIcon';

export const IconWrapper = styled.div`
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
IconWrapper.displayName = 'IconWrapper';
