import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: left;
  height: ${(props) => !props.emptySchedule && '100%'};
  overflow: hidden;
`;
Wrapper.displayName = 'Wrapper';

export const TableWrapper = styled.div`
  width: 100%;
  height: 95%;
`;
TableWrapper.displayName = 'TableWrapper';
