import styled from 'styled-components';
import { ReactComponent as DuplicateSVG } from 'Images/icon-show-duplicates.svg';

const DuplicateIcon = styled(DuplicateSVG)`
path {
  fill: ${({ theme }) => theme.icons.actionBar.color};
}
`;
DuplicateIcon.dipslayName = 'DuplicateIcon';

export default DuplicateIcon;
