import get from 'lodash/get';

export const selectIsFetching = (state) => get(state, 'executiveReport.isFetching', false);

export const selectReportList = (state) => get(state, 'executiveReport.reportList', []);

export const selectReportsBeingProcessed = (state) => selectReportList(state).filter((r) => r.status === 'processing');

export const selectReportsCount = (state) => get(state, 'executiveReport.reportsCount', 0);

export const selectError = (state) => get(state, 'executiveReport.error', false);

export const selectErrorMessage = (state) => get(state, 'executiveReport.errorMessage', '');

export const selectPage = (state) => get(state, 'executiveReport.page', 1);

export const selectRowsPerPage = (state) => get(state, 'executiveReport.rowsPerPage', 50);

export const selectOrderBy = (state) => get(state, 'executiveReport.order_by', '');

export const selectOrderByDir = (state) => get(state, 'executiveReport.order_by_dir', '');

export const selectSelectedReports = (state) => get(state, 'executiveReport.selectedReports', []);

export const selectQueryParam = (state) => {
  const page = selectPage(state);
  const rowsPerPage = selectRowsPerPage(state);
  const sort = selectOrderBy(state);
  const sort_dir = selectOrderByDir(state);

  const sortParam = sort ? `&sort=${sort}` : '';
  const sortDirParam = sort_dir ? `&sort_dir=${sort_dir}` : '';

  const queryParam = `page=${page}&page_size=${rowsPerPage}${sortParam}${sortDirParam}`;
  return queryParam;
};
