import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import {
  getServicesGeneral, hideServiceDetail, loadServiceDetail,
  clearError, resetServiceDetail, updateService,
  redirectToServices,
  addServiceTag,
  removeServiceTag,
  setServicesGeneralTags,
  resetState
} from 'store/ServicesGeneral/actions';
import ServicesTable from './components/ServicesTable';
import Wrapper from './styled';
import ActionBar from './components/ActionBar';
import { closeModal } from 'store/modals/actions';
// import ReduxModal from 'Common/Components/ReduxModal';
import { MODAL_CANT_CREATE, MODAL_CREATE_SERVICE } from 'store/modals/modals';
import ErrorDialog from 'Common/Components/ErrorDialog';
import { selectError, selectErrorMessage, selectServiceDetail, selectServiceDetailDescription, selectServiceDetailId, selectServiceDetailOwned, selectServicesSelected, selectServicesTags, selectShowDetail } from 'store/ServicesGeneral/selectors';
import CreateService from 'Screens/Contextualization/Services/components/CreateService';
import ServiceDetail from 'Screens/Contextualization/Services/components/ServiceDetail';
import WarningModal from 'Screens/Contextualization/ManageEditCreate/components/WarningModal';
import selectModal from 'store/modals/selectors';
import { selectTotalHostsCount } from 'store/Faraday/selectors';
import { selectEmptyActiveWorkspaces } from 'store/Workspace/selectors';
import { getHostsCountInAllWorkspaces, getVulnsCountInAllWorkspaces } from 'Screens/Contextualization/Faraday/actions/Actions';
import { getGeneralHostsTags, getGeneralServicesTags, getGeneralTags } from 'Screens/Contextualization/Tags/actions/Actions';
import CreateEditTagsModal from '../Services/components/CreateEditTagsModal';
import { selectShowTagsModal } from 'store/Tags/selectors';
import SelectAll from './components/ServicesTable/components/SelectAll';

const ServicesGeneral = ({ params }) => {
  const dispatch = useDispatch();
  const error = useSelector(selectError);
  const errorMessage = useSelector(selectErrorMessage);
  const showDetail = useSelector(selectShowDetail);
  const data = useSelector(selectServiceDetail);
  const serviceId = useSelector(selectServiceDetailId);
  const owned = useSelector(selectServiceDetailOwned);
  const description = useSelector(selectServiceDetailDescription);
  const showModalCantCreate = useSelector((state) => selectModal(state, MODAL_CANT_CREATE));
  const showModalCreate = useSelector((state) => selectModal(state, MODAL_CREATE_SERVICE));
  const assetsCountAllWorkspaces = useSelector(selectTotalHostsCount);
  const noAssetsFromAllWorkspaces = assetsCountAllWorkspaces === 0;
  const noWorkspaces = useSelector(selectEmptyActiveWorkspaces);
  const showTagsModal = useSelector((state) => selectShowTagsModal('servicesGeneral', state));
  const servicesTags = useSelector(selectServicesTags);
  const servicesSelected = useSelector(selectServicesSelected);

  const handleParamId = useCallback(() => {
    const id = get(params, 'id', 0);
    if (parseInt(id, 10) > 0) dispatch(loadServiceDetail(id));
    else dispatch(hideServiceDetail());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    dispatch(getServicesGeneral());
    dispatch(getGeneralTags());
    dispatch(getGeneralHostsTags());
    dispatch(getGeneralServicesTags());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleParamId();
  }, [handleParamId]);

  useEffect(() => {
    dispatch(getVulnsCountInAllWorkspaces());
    dispatch(getHostsCountInAllWorkspaces());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => () => {
    dispatch(resetState());
  }, [dispatch]);

  useEffect(() => () => {
    dispatch(resetState());
  }, [dispatch]);

  return (
    <Wrapper>
      <ActionBar />
      <SelectAll />
      <ServicesTable />
      { showDetail &&
        <ServiceDetail
          data={ data }
          resetServiceDetail={ resetServiceDetail }
          serviceId={ serviceId }
          owned={ owned }
          updateService={ (id, obj) => dispatch(updateService(id, obj)) }
          redirectToService={ redirectToServices }
          loadServiceDetail={ loadServiceDetail }
          description={ description }
          addTag={ addServiceTag }
          removeTag={ removeServiceTag }
          setServicesTags={ setServicesGeneralTags }
          entity="serviceDetailGeneral"
          allWorkspaces
          serviceTags={ servicesTags }
          servicesSelected={ servicesSelected }
        />
      }
      {/* <ReduxModal id={ MODAL_CREATE_SERVICE }> */}
      { showModalCreate && <CreateService onClose={ () => dispatch(closeModal(MODAL_CREATE_SERVICE)) } entity="servicesGeneral" />}
      {/* </ReduxModal> */}
      { error ? <ErrorDialog message={ errorMessage } resetError={ () => dispatch(clearError()) } /> : null }
      { showModalCantCreate && <WarningModal entity="Service" noAssetsInAllWorkspaces={ noAssetsFromAllWorkspaces } noWorkspaces={ noWorkspaces } /> }
      { showTagsModal && <CreateEditTagsModal servicesTags={ servicesTags } entity="servicesGeneral" servicesSelected={ servicesSelected } addTag={ addServiceTag } removeTag={ removeServiceTag } setServicesTags={ setServicesGeneralTags } allWorkspaces /> }
    </Wrapper>
  );
};

export default ServicesGeneral;
