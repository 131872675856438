import styled from 'styled-components';
import colors from 'Styles/colors';

export const Label = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${({ theme }) => theme.text.modalTitle2};
  margin-left: 10px;
`;
Label.displayName = 'Label';

export const CheckboxWrapper = styled.div`
  display: flex;
  flex: 0 1 auto;
  align-items: center;
  user-select: none;

  &[required] {
    ${Label}::after {
      content: ' *';
      color: ${colors.redPink};
    }
  }
`;
CheckboxWrapper.displayName = 'FieldWrapper';
