import styled from 'styled-components';

export const Dropdown = styled.div`
  margin-top: ${(props) => (props.paddingTop ? `${(props.paddingTop) * 34}px` : `${0}px`)};
  max-height: 314px;
  min-width: 171px;
  max-width: 300px;
  margin-bottom: auto;
  overflow: hidden;
  z-index: 4;
  border-radius: 3px;
  box-shadow: 0 2px 50px 0 ${({ theme }) => theme.shadows.actionDropDown};
  background-color: ${({ theme }) => theme.backgrounds.actionDropDown};
  ${({ supportsList }) => supportsList && `
    width: 145px;
    min-width: 145px;
  `}
`;

export const DropdownOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 8px;
  margin-bottom: 9px;
  max-height: 276px;
`;

export const DropdownOptionLabel = styled.div`
  cursor: pointer;
  padding-right: 13px;
  padding-left: 21px;
  font-size: 13.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.52;
  letter-spacing: normal;
  color: ${({ theme }) => theme.text.actionDropDownItem};
  height: 34px;
  text-align: left;
  background-color: ${(props) => (`${props.isActive ? props.theme.backgrounds.actionDropDownHover : ''}`)};
  ${({ supportsList }) => supportsList && `
    font-size: 11px;
    line-height: normal;
    text-align: center;
    padding: 5px 0 4px 4px;
    height: 39px;
  `}
`;
