
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 9px;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  text-align: left;
  user-select: none;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${({ theme }) => theme.textfield.text}
`;
Title.displayName = 'Title';

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 12px;
  & > *:not(:first-child) {
    margin-left: 30px;
  }
`;
FieldWrapper.displayName = 'FieldWrapper';
