import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as QuestionIcon } from 'Images/help-sysreq.svg';
import { Cancel } from 'Common/Components/Button/styled';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 444px;
  width: 452px;
  background-color: ${({ theme }) => theme.backgrounds.modal};
  padding: 34px;
  border-top: 10px solid ${colors.softBlue};;
  position: relative;
`;
Content.displayName = 'Content';

export const Title = styled.h4`
  font-size: 21.5px;
  font-weight: bold;
  color: ${({ theme }) => theme.text.modalTitle2};
  margin-bottom: 24px;
`;
Title.displayName = 'Title';

export const Box = styled.div`
  height: 230px;
`;
Box.displayName = 'Box';

export const Help = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0 25px 0;
`;
Help.displayName = 'Help';

export const HelpLink = styled.a`
  font-size: 13.5px;
  font-weight: 500;
  color: ${colors.blueCerulean};
  cursor: pointer;
  &:hover {
    color: ${colors.blueCerulean}
  }
`;
HelpLink.displayName = 'HelpLink';

export const QuestionMark = styled(QuestionIcon)`
  margin-right: 8px;
`;
QuestionMark.displayName = 'QuestionMark';

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
`;
Buttons.displayName = 'Buttons';

export const CancelButton = styled(Cancel)`
  margin-right: 10px;
`;
CancelButton.displayName = 'CancelButton';
