/* eslint-disable react/jsx-key */
/* eslint-disable react/no-children-prop */
import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { selectCustomFilters } from 'store/Filters/selectors';
import { getCustomFilters } from 'store/Filters/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Wrapper, Title, TagsContainer, NoCFContainer, NoCF, NoCFBold
} from './styled';
import CustomFilterTag from './components';

const NoCFSubtitle = () => {
  const intl = useIntl();
  const noCFTitle = intl.formatMessage({ id: 'reports.editCreate.fieldFilter.noSavedFilters' });
  const noCFPath = ` ${intl.formatMessage({ id: 'reports.editCreate.fieldFilter.vulnsPath' })}`;

  return (
    <NoCFContainer>
      <NoCF children={ noCFTitle } />
      <NoCFBold children={ noCFPath } />
    </NoCFContainer>
  );
};

const CustomFilters = ({ disabled }) => {
  const dispatch = useDispatch();
  const customFilters = useSelector((state) => selectCustomFilters(state, 'vulns'));
  const noCFAvailable = customFilters.length === 0;
  const title = <FormattedMessage id="reports.editCreate.vulnSection.savedFilter" />;

  useEffect(() => {
    dispatch(getCustomFilters('vulns'));
  }, [dispatch]);

  return (
    <Wrapper>
      <Title children={ title } />
      <TagsContainer>
        {noCFAvailable && <NoCFSubtitle /> }
        {customFilters.map((customFilter) => <CustomFilterTag key={ customFilter } customFilter={ customFilter } disabled={ disabled } />)}
      </TagsContainer>
    </Wrapper>
  );
};

CustomFilters.propTypes = {
  disabled: PropTypes.bool.isRequired
};

export default CustomFilters;
