import React, { useEffect, useState } from 'react';
import { Wrapper, Url, ConsumeKey } from './styled';
import { useDispatch, useSelector } from 'react-redux';
import { selectConsumerKey, selectUrl } from 'store/Settings/jiraSelectors';
import { setField } from 'store/Settings/jiraActions';

const FirstRow = () => {
  const dispatch = useDispatch();
  const storedUrl = useSelector(selectUrl);
  const [url, setUrl] = useState(storedUrl);

  const consumerKey = useSelector(selectConsumerKey);
  const [key, setKey] = useState(consumerKey);

  const storeUrl = () => dispatch(setField('url', url));
  const setConsumerKey = () => dispatch(setField('consumerKey', key));

  useEffect(() => {
    setUrl(storedUrl);
  }, [storedUrl]);

  useEffect(() => {
    setKey(consumerKey);
  }, [consumerKey]);

  return (
    <Wrapper>
        <Url
          title='Url'
          placeholder='http://domain.com'
          onChange={ setUrl }
          onBlur={ storeUrl }
          defaultValue={ url }
          value={ url }
          name="url"
          id="url"
          required
        />
        <ConsumeKey
          title='Consumer Key Name'
          placeholder='http://domain.com'
          onChange={ setKey }
          onBlur={ setConsumerKey }
          value={ key }
          defaultValue={ key }
          name="consumer key"
          id="consumer key"
          required
        />
    </Wrapper>
  );
};

export default FirstRow;
