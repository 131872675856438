import styled from 'styled-components';
import { ReactComponent as _CopyApiLink } from 'Images/api_link.svg';

const CopyApiLinkIcon = styled(_CopyApiLink)`
  path {
    fill: ${({ theme }) => theme.icons.actionBar.color};
  }
`;
CopyApiLinkIcon.displayName = 'CopyApiLinkIcon';

export default CopyApiLinkIcon;
