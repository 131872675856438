import React from 'react';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import { SwaggerContainer } from './styled';

const Swagger = () => {
  // return <SwaggerUI
  //   url="/_api/v3/swagger"
  // />;
  return (
    <SwaggerContainer>
      <SwaggerUI url="/_api/v3/swagger" />
    </SwaggerContainer>
  );
};

export default Swagger;
