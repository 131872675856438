/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import FlexModal from 'Common/Components/JiraTemplateModal/Components/FlexModal';
import { Dropdown } from 'Common/Components/Inputs';
import { useSelector } from 'react-redux';
import { selectDefaultProject, selectIsTemplate, selectProjectData } from 'store/Settings/jiraSelectors';
import { parseToLabel, parseIssueType } from 'store/Settings/helpers';
import {
  Title, SubTitle, CustomInput, CustomTextArea,
  Header,
  TitleSection
} from './styled';
import Buttons from './Components/Buttons';
import './styled.css';
import AlternativeFields from './Components/AlternativeFields';
import RequeridFields from './Components/RequiredFields';
import JiraUserAssign from '../../../JiraUserAssign';

const NewJiraTemplateModal = () => {
  const isTemplate = useSelector(selectIsTemplate);
  const projectData = useSelector(selectProjectData);
  const [users, setUsers] = useState([]);
  const { name = '', issue_types = [], ticket_config = {} } = projectData;
  const defaulProject = useSelector(selectDefaultProject);

  const issueFieldInitialValue = useCallback((templateIssueType) => {
    if (!templateIssueType) return null;
    return issue_types.find((field) => field.key === templateIssueType.key);
  }, [issue_types]);

  const issueFieldDataInitialValue = useCallback((templateIssueType) => {
    if (!templateIssueType) return [];
    return templateIssueType.fields;
  }, []);

  const templateIssueType = parseIssueType(ticket_config?.issue_types || []);
  const issueFieldInitial = issueFieldInitialValue(templateIssueType);
  const issueFieldOptionsRef = useRef(issueFieldInitial);
  const [alternativeFieldsKey, setAlternativeFieldsKey] = useState(0); // Estado adicional

  const [issueFieldData, setIssueFieldData] = useState(
    issueFieldDataInitialValue(templateIssueType, issueFieldInitial?.fields)
  );
  const [ticketConfig, setTicketConfig] = useState(ticket_config);

  const requiredFields = useMemo(() => () => {
    const options = issueFieldOptionsRef.current?.fields || [];
    return options
      .map((field) => field.options.filter((option) => option.required))
      .flat();
  }, []);

  const optionalFields = useMemo(() => () => {
    const options = issueFieldOptionsRef.current?.fields || [];
    return options
      .map((field) => field.options.filter((option) => !option.required))
      .flat();
  }, []);

  const onIssueTypeChange = useCallback((e) => {
    const unselectedIssueTypes = {};
    for (const iTypes in ticketConfig?.issue_types) {
      unselectedIssueTypes[iTypes] = {
        ...ticketConfig?.issue_types[iTypes],
        selected: false
      };
    }

    const issueTypes = {
      ...unselectedIssueTypes,
      [e.key]: {
        ...ticketConfig?.issue_types[e.key],
        selected: true
      }
    };

    issueFieldOptionsRef.current = e;
    setIssueFieldData(parseIssueType(issueTypes).fields);
    setTicketConfig((prevState) => ({
      ...prevState,
      issue_types: issueTypes
    }));

    setAlternativeFieldsKey((prevKey) => prevKey + 1);
  }, [ticketConfig]);

  const fieldDataWithoutLabel = (keySelected) => {
    // filter data to match with label
    const { ...fieldData } = issueFieldData.find((field) => field.key === keySelected);
    return fieldData;
  };

  const handleIssueTypeChange = useCallback((e, key, action) => {
    if (action && (action.action === 'pop-value' || action.action === 'remove-value')) {
      const { [key]: del, ...other } = ticketConfig.issue_types[issueFieldOptionsRef.current?.key].fields;
      setTicketConfig({
        ...ticketConfig,
        [ticketConfig.issue_types[issueFieldOptionsRef.current?.key].fields]: other
      });
    } else {
      setTicketConfig({
        ...ticketConfig,
        issue_types: {
          ...ticketConfig.issue_types,
          [issueFieldOptionsRef.current?.key]: {
            ...ticketConfig.issue_types[issueFieldOptionsRef.current?.key],
            fields: {
              ...ticketConfig.issue_types[issueFieldOptionsRef.current?.key].fields,
              [key]: {
                ...fieldDataWithoutLabel(key),
                value: e
              }
            }
          }
        }
      });
    }
  }, [ticketConfig]);

  const isDisable = useCallback(() => {
    const requiredList = requiredFields();
    const ticketFields = ticketConfig?.issue_types?.[issueFieldOptionsRef?.current?.key]?.fields || {};

    return !requiredList.every((field) => {
      const fieldData = ticketFields[field.key];
      if (!fieldData) return false;
      const { type } = fieldData.schema;
      const { value } = fieldData;
      if (type === 'array') return !!value;
      if (
        ['user', 'version', 'group', 'date', 'datetime', 'string', 'option', 'project', 'priority', 'securitylevel'].includes(
          type
        )
      ) { return value !== '' && value !== undefined; }
      if (type === 'number') return value !== null;

      return true;
    });
  }, [ticketConfig]);

  useEffect(() => {
    if (isTemplate) {
      setTicketConfig((prevConfig) => ({
        ...prevConfig,
        summary: prevConfig.summary || '{{vuln.name}} - {{vuln.severity}}',
        description:
          prevConfig.description ||
          'Description: {{vuln.description}}\n\n{% if vuln.data %}Data: {{vuln.data}}{% endif %}'
      }));
    }
  }, [isTemplate]);

  return (
    <FlexModal fullHeigth={ issueFieldOptionsRef.current?.key }>
      <Header>
        <Title>Configure Issue</Title>
        <Buttons
          isTemplate={ isTemplate }
          ticketConfig={ ticketConfig }
          projectKey={ defaulProject }
          // disabled={ isDisable() && !isTemplate }
          disabled={ isDisable() }
        />
      </Header>
      <SubTitle>
        Configure the attributes of the issue type. Learn more. {name}
      </SubTitle>
      <Dropdown
        required
        options={ parseToLabel(issue_types) }
        title="Issue Types"
        onChange={ onIssueTypeChange }
        defaultValue={
          issueFieldOptionsRef.current && {
            ...issueFieldOptionsRef.current,
            label: issueFieldOptionsRef.current?.key
          }
        }
      />
      {issueFieldOptionsRef.current?.key && (
        <>
          <TitleSection children="Issue Attributes" />
          <div style={ { display: 'flex', flexDirection: 'column', gap: '25px' } }>
            <CustomInput
              title="Summary"
              placeholder="Add your summary"
              defaultValue={ ticketConfig.summary }
              onChange={ (summary) =>
                setTicketConfig((prev) => ({ ...prev, summary }))
              }
            />
            <CustomTextArea
              title="Description"
              placeholder="Add your description"
              value={ ticketConfig.description }
              onChange={ (description) =>
                setTicketConfig((prev) => ({ ...prev, description }))
              }
            />
            <RequeridFields
              options={ requiredFields() }
              issueType={ issueFieldOptionsRef.current?.key }
              handleIssueTypeChange={ handleIssueTypeChange }
              issueFieldData={ issueFieldData }
              users={ users }
            />
          </div>
          <JiraUserAssign users={ users } setUsers={ setUsers } />
          <TitleSection children="Optional Issue Attributes" />
          <AlternativeFields
            key={ alternativeFieldsKey }
            options={ optionalFields() }
            className="option"
            ticketConfig={ ticketConfig }
            setTicketConfig={ setTicketConfig }
            issueFieldOptions={ issueFieldOptionsRef.current }
            issueFieldData={ issueFieldData }
            users={ users }
          />
        </>
      )}
    </FlexModal>
  );
};

export default NewJiraTemplateModal;
