/* eslint-disable import/prefer-default-export */
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import MenuItem from 'Common/Components/ContextMenu/components/MenuItem';
import { useSelector, useDispatch } from 'react-redux';
import { selectSelected } from 'store/Contextualization/AssetDetail/selectors';
import { showContextMenu, showModalDelete } from 'store/Contextualization/AssetDetail/actions';
import { DeleteIcon } from './styled';
import { ThemeContext } from 'Context';

export const Delete = () => {
  const dispatch = useDispatch();
  const vulnsSelected = useSelector((state) => selectSelected('vulns', state));
  const vulnsCount = vulnsSelected.length;
  const singleDelete = useIntl().formatMessage({ id: 'manage.cm.delete' });
  const bulkDelete = useIntl().formatMessage({ id: 'manage.cm.bulkDelete' });
  const title = vulnsCount === 1 ? singleDelete : bulkDelete;
  const { themeStyles } = useContext(ThemeContext);

  const handleClick = () => {
    dispatch(showModalDelete('vulns'));
    dispatch(showContextMenu(false));
  };

  return (
    <MenuItem
      title={ title }
      color={ themeStyles.text.contextMenuDelete }
      icon={ <DeleteIcon /> }
      onClick={ handleClick }
    />
  );
};
