import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import StandardDropdown from 'Common/Components/StandarDropdown';
import { selectTemplate } from 'store/Settings/solarWindsSelectors';
import { setField } from 'store/Settings/solarWindsActions';
import { selectTemplates } from 'store/Settings/settingsSelectors';
import {
  Wrapper, Content, WrapperTemplate, Title
} from './styled';

const IssuesConfiguration = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const templates = useSelector(selectTemplates);
  const selectedTemplate = useSelector(selectTemplate);

  const onChange = (field, value) => {
    dispatch(setField(field, value));
  };

  return (
    <Wrapper>
      <Title>{ intl.formatMessage({ id: 'preferences.ticketingTools.solarWinds.issuesConfiguration.title' }) }</Title>
      <Content>
        <WrapperTemplate>
          <StandardDropdown disabled={ !templates.length } field="template" options={ templates } updateValue={ onChange } value={ selectedTemplate } defaultValue={ selectedTemplate } width="266px" />
        </WrapperTemplate>
      </Content>
    </Wrapper>
  );
};
export default IssuesConfiguration;
