import React, { useContext, useEffect, useState } from 'react';
import { Input } from './../styled';
import Select from './Select';
import { cvssOptions } from 'Screens/Constants';
import { useDispatch } from 'react-redux';
import { removeCVSS } from 'store/Contextualization/AssetDetail/actions';
import { Wrapper, Container, Title, Error } from './styled';
import { isEmpty } from 'lodash';
import { CVSS_PATTERNS, CVSS_TYPES } from './constants';
import { setFiedsError } from 'Screens/ManageEditCreate/actions/Actions';
import { ThemeContext } from 'Context';

const CVSS = ({ manageEditCreate, debouncedSetField, setField }) => {
  const dispatch = useDispatch();
  const types = cvssOptions.map((option) => option.name);
  const [type, setType] = useState('');
  const vector = manageEditCreate?.[CVSS_TYPES[type]]?.vector_string || '';
  const error = !isEmpty(vector) && !CVSS_PATTERNS[type]?.test(manageEditCreate?.[CVSS_TYPES[type]]?.vector_string);
  const { themeStyles } = useContext(ThemeContext);

  const onChange = (value) => {
    debouncedSetField(CVSS_TYPES[type], { vector_string: value });
    dispatch(setFiedsError(!isEmpty(value) && !CVSS_PATTERNS[type]?.test(value)));
  };

  useEffect(() => {
    types.forEach((e) => {
      if (e !== type) {
        dispatch(removeCVSS(CVSS_TYPES[e]));
        dispatch(setFiedsError(false));
      }
    });
  }, [type]);

  return (
      <Wrapper>
        <Title>CVSS Severity Score</Title>
        <Container>
            <Select theme={ themeStyles } updateValue={ (value) => setType(value) } defaultValue={ type || '' } />
            <Input
              error={ error }
              className="custom-background"
              width="276px"
              height="38px"
              name="cvss"
              defaultValue={ vector }
              onChange={ (value) => onChange(value) }
            />
        </Container>
        {error && <Error>Invalid format</Error>}
      </Wrapper>
  );
};

export default CVSS;
