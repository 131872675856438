import styled from 'styled-components';
import colors from 'Styles/colors';
import { fadeIn } from 'Styles/effects';
import { Cancel } from 'Common/Components/Button/styled';

export const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgb(25, 25, 25, 0.4);
  z-index: 500;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  & > :first-child {
    box-shadow: ${({ theme }) => theme.boxShadow.modal};
    border-top: 10px solid ${colors.softBlue};
    border-radius: 2px 2px 0 0;
    padding: 36px 30px;
    overflow: scroll;
    max-height: 85%;
    height: auto;
    ::-webkit-scrollbar-corner { background: transparent }
  }
  ${fadeIn(0, 1)}
`;
ModalWrapper.displayName = 'ModalWrapper';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  background: ${({ theme }) => theme.backgrounds.preferencesContent};
  height: 100%;
  max-height: 500px;
  max-width: 550px;
  width: 100%;
  overflow: scroll;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  font-size: 21.5px;
  font-weight: 600;
  color: ${({ theme }) => theme.text.runAgentTitle};
  padding-bottom: 34px;
`;
Title.displayName = 'Title';

export const Subtitle = styled.div`
  font-size: 13.5px;
  font-weight: normal;
  line-height: 1.03;
  color: ${({ theme }) => theme.text.runAgentDescription};
  margin-bottom: 20px;
`;
Subtitle.displayName = 'Subtitle';

export const WrapperButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 21px 0 0;
  margin-top: auto;
  & > *:not(:last-child) {
    margin-right: 13px;
  }
`;
WrapperButtons.displayName = 'WrapperButtons';

export const CancelButton = styled(Cancel)`
  background-color: transparent;
`;
CancelButton.displayName = 'CancelButton';
