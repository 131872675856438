import React from 'react';
import SearchBar from 'Common/Components/Contextualization/SearchBar/index';
import 'Common/styles/commonStyles.scss';
import { useSelector } from 'react-redux';
import { selectPathname } from 'store/Router/selectors';
import BasicSearcher from './BasicSearcher';

const Searcher = () => {
  const pathname = useSelector(selectPathname);

  const canShowNewSearcher =
    (pathname.includes('/manage')) ||
    pathname.includes('/host') ||
    (pathname.includes('/knowledge_base')) ||
    pathname.includes('/vulnerabilities') ||
    pathname.includes('/workspaces') ||
    pathname.includes('/services') ||
    pathname.includes('/service') ||
    pathname.includes('/report');

  const canShowOldSearcher = pathname.includes('/users');

  if (canShowNewSearcher) return <SearchBar />;
  if (canShowOldSearcher) return <BasicSearcher />;

  return null;
};

export default Searcher;
