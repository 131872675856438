import React from 'react';
import { useSelector } from 'react-redux';
import TabsList from 'Common/Components/TabsList';
import {
  selectTabsWithWorkspace, selectTabs, selectAssetTabs,
  generalView, showTabsWithWs, insideAsset
} from 'store/Tabs/selectors';
import GlobalTabs from './styled';
import { selectPathname } from 'store/Router/selectors';
import { selectWsDetailName } from 'store/Workspace/selectors';
import ViewTypeWorkspaces from '../Workspaces/components/ViewTypeWorkspaces';

const TabsContext = () => {
  const tabsDataWithWorkspace = useSelector(selectTabsWithWorkspace);
  const tabsData = useSelector(selectTabs);
  const assetsTabsData = useSelector(selectAssetTabs);

  const isOutsideWorkspace = useSelector(generalView);
  const showTabsWithWorkspace = useSelector(showTabsWithWs);
  const showAssetsTabs = useSelector(insideAsset);
  const pathname = useSelector(selectPathname);
  const workspaceDetailName = useSelector(selectWsDetailName);

  return (
    <>
      { isOutsideWorkspace &&
        <GlobalTabs>
          <TabsList tabsList={ tabsData } />
          { ((pathname === '/workspaces') || (pathname === `/workspaces/${workspaceDetailName}`)) &&
            <ViewTypeWorkspaces />
          }
        </GlobalTabs>
      }
      { showTabsWithWorkspace && <TabsList tabsList={ tabsDataWithWorkspace } /> }
      { showAssetsTabs && <TabsList tabsList={ assetsTabsData } /> }
    </>

  );
};
export default TabsContext;
