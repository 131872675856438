/* eslint-disable react/destructuring-assignment */
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  setField, addPolicy, removePolicy,
  selectCheckbox, addReference, removeReference,
  addCVE, removeCVE, getHosts, addAsset,
  addWorkspace, removeWorkspace, removeGroupAsset,
  setCreateVulnError
} from 'Screens/Contextualization/ManageEditCreate/actions/Actions';
import {
  selectManageEditCreate,
  selectWorkspacesField,
  selectTargetsHostsOutsideWs
} from 'store/ManageEditCreate/selectors';
import SeverityDropdown from 'Common/Components/SeverityDropdown';
import EORDropdown from 'Common/Components/EORDropdown';
import CustomList from 'Common/Components/CustomList';
import Impacts from 'Common/Components/Impacts';
import MarkdownInput from '../../MarkdownInput';
import { Wrapper, Row, ExternalId, NewColumn, Title, Input } from './../styled';
import debounce from 'lodash/debounce';
import { FEATURE_VULNS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import CVE from '../InputCVE';
import InputSearch from '../InputSearch';
import { selectLocation } from 'store/Router/selectors';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import { selectActiveWorkspaces } from 'store/Workspace/selectors';
import InputFilterGrouped from 'Common/Components/Contextualization/InputFilterGrouped';
import isEmpty from 'lodash/isEmpty';
import { getAssetsByWorkspace } from 'store/ServicesGeneral/actions';
import { selectAssetsList } from 'store/ServicesGeneral/selectors';
import colors from 'Styles/colors';
import { useIntl } from 'react-intl';
import '../styled.css';
import References from '../References';
import CVSS from '../CVSS';

const GeneralView = ({ assets, setAssets }) => {
  const manageEditCreate = useSelector(selectManageEditCreate);
  const dispatch = useDispatch();
  const canUpdate = useSelector((state) => selectAllowedByRole(state, FEATURE_VULNS, PERMISSION_TYPES.CREATE));
  const location = useSelector(selectLocation);
  const currentWs = useSelector(selectCurrentWorkspace);
  const isOutsideWorkspace = location.pathname.startsWith('/vulnerabilities') && !location.pathname.includes(`/manage/${currentWs}`);
  const workspacesOptions = useSelector(selectActiveWorkspaces);
  const addedWorkspaces = useSelector(selectWorkspacesField);
  const assetsByWorkspace = useSelector(selectAssetsList);
  const addedHostsOutsideWs = useSelector((state) => selectTargetsHostsOutsideWs(state, isOutsideWorkspace));
  const cantSelectAsset = isOutsideWorkspace && isEmpty(addedWorkspaces);

  const intl = useIntl();
  const assetsErrorTitle = intl.formatMessage({ id: 'modal.createVuln.missingWs.title' });
  const assetsErrorMessage = intl.formatMessage({ id: 'modal.createVuln.missingWs.message' });

  const handleChange = (field, value) => {
    dispatch(setField(field, value));
  };

  const debouncedSetField = debounce(handleChange, 150);

  const impacts = {
    accountability: manageEditCreate.accountability,
    availability: manageEditCreate.availability,
    confidentiality: manageEditCreate.confidentiality,
    integrity: manageEditCreate.integrity
  };

  const getHostsOptions = () => {
    if (!isOutsideWorkspace) dispatch(getHosts());
  };

  const setWorkspace = (value) => {
    const temp = addedWorkspaces;
    const found = temp.find((item) => item.id === value.id);
    if (!found) {
      dispatch(addWorkspace(value));
      getHostsOptions();
    }
  };

  const onRemoveWorkspace = (value) => {
    const temp = addedWorkspaces;
    const index = temp.findIndex((item) => item.id === value.id);
    if (index > -1) {
      if (!isEmpty(assets)) {
        const filteredAssets = assets.filter((asset) => asset.value.workspace_name !== value.name);
        setAssets(filteredAssets);
      }
      temp.splice(index, 1);
      dispatch(removeWorkspace(value));
      getHostsOptions();
    }
  };

  const setTargetOutsideWs = (element) => {
    if (element) {
      const temp = addedHostsOutsideWs;
      const found = temp.find((item) => item.id === element[element.length - 1].value.id);
      if (!found) {
        dispatch(addAsset(element[element.length - 1]));
        setAssets([...assets, element[element.length - 1]]);
      }
    }
  };

  const removeTargetOutsideWs = (value) => {
    setAssets(value);
    dispatch(removeGroupAsset(value));
  };

  useEffect(() => {
    if (!isEmpty(addedWorkspaces) && isOutsideWorkspace) dispatch(getAssetsByWorkspace(addedWorkspaces));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedWorkspaces]);

  return (
      <Wrapper>
        <NewColumn>
          <>
            <InputSearch
              onSelect={ setWorkspace }
              data={ workspacesOptions }
              placeholder="Add a Workspace"
              id="vuln-add-workspaces"
              title="Workspace(s)"
              required
              addedItems={ addedWorkspaces }
              removeItem={ onRemoveWorkspace }
            />
          </>
          <div>
            <Row>
              <SeverityDropdown updateValue={ (fieldName, value) => dispatch(setField(fieldName, value)) } defaultValue={ manageEditCreate.severity || '' } />
              <EORDropdown updateValue={ (fieldName, value) => dispatch(setField(fieldName, value)) } defaultValue={ manageEditCreate.easeOfResolution || '' } />
            </Row>
          </div>
          <div>
            <Input
              className="custom-background"
              width="405px"
              height="38px"
              title="CWE"
              name="cwe"
              placeholder="CWE-NNN"
              defaultValue={ manageEditCreate.cwe || '' }
              onChange={ (value) => debouncedSetField('CWE', [value]) }
            />
          </div>
          <div>
            <Title>CVE</Title>
            <CVE
              listData={ manageEditCreate.CVE }
              onAdd= { (val) => dispatch(addCVE(val)) }
              onRemove={ (val) => dispatch(removeCVE(val)) }
              expanded
            />
          </div>
          <MarkdownInput
            title="Description"
            value={ manageEditCreate.description }
            onBlur={ (value) => debouncedSetField('description', value) }
            minHeight={ 187 }
            isRequired
          />
          <CustomList
            key="CL_POLICY_VIOLATIONS"
            title="Policy Violations"
            placeholder="Add a Policy Violation"
            addItem={ (val) => dispatch(addPolicy(val)) }
            removeItem={ (index) => dispatch(removePolicy(index)) }
            listData={ manageEditCreate.policies || [] }
            responsive
            canUpdate={ canUpdate }
            spanStyle='icon2-container'
          />
        </NewColumn>
        <NewColumn>
          <>
            <InputFilterGrouped
              options={ isEmpty(addedWorkspaces) ? [] : assetsByWorkspace }
              required
              title="Asset(s)"
              id="vulns-general-add-assets"
              placeholder="Add a Asset"
              cantSelect={ cantSelectAsset }
              groupNameField="workspace_name"
              addItems={ setTargetOutsideWs }
              addedItems={ assets }
              setItems={ (elements) => removeTargetOutsideWs(elements) }
              width="394px"
              bgColor={ colors.iceBlue }
              menuHeight="110px"
              setError={ () => dispatch(setCreateVulnError(true, assetsErrorMessage, assetsErrorTitle)) }
            />
          </>
          <ExternalId
            className="custom-background"
            width="405px"
            height="38px"
            title="External ID"
            name="external_id"
            defaultValue={ manageEditCreate.externalId || '' }
            onChange={ (value) => debouncedSetField('externalId', value) }
          />
          <div>
            <CVSS
              updateValue={ (fieldName, value) => dispatch(setField(fieldName, value)) }
              defaultValue= ''
              manageEditCreate={ manageEditCreate }
              debouncedSetField={ debouncedSetField }
            />
          </div>
          <References
            key="REFERENCES"
            title="References"
            placeholder="Add Reference"
            addItem={ (val) => dispatch(addReference(val)) }
            removeItem={ (index) => dispatch(removeReference(index)) }
            listData={ manageEditCreate.references || [] }
            responsive
            canUpdate={ canUpdate }
            spanStyle='icon2-container'
          />
          <MarkdownInput
            title="Resolution"
            value={ manageEditCreate.resolution }
            onBlur={ (value) => debouncedSetField('resolution', value) }
            minHeight={ 187 }
          />
          <Impacts selectCheckbox={ (field) => dispatch(selectCheckbox(field)) } impacts={ impacts } />
        </NewColumn>
      </Wrapper>
  );
};

export default withRouter(GeneralView);
