import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { getServiceNowSettings, TOOL_NAME, save } from 'store/Settings/serviceNowActions';
import { fetchActiveConfig, getTemplates, updateActiveConfigNew } from 'store/Settings/settingsActions';
import { Wrapper, ServicenowLogo } from './styled';
import { selectSelectedTable, selectCategory, selectSubcategory, selectUrl, selectClientId, selectKey, selectUserName, selectUserPassword } from 'store/Settings/serviceNowSelectors';
import Header from '../../../Header';
import { StatusLabel, Title, Toolbar } from 'Screens/Contextualization/Settings/common-styled';
import Checkbox from 'Common/Components/Checkbox/styled';
import MainSettings from './components/MainSettings';
import { selectActiveConfig } from 'store/Settings/settingsSelectors';
import { selectAllowedByFeature } from 'Hooks/usePermission/selector';
import { FEATURE_TICKETING_SERVICENOW, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import UpgradeLicense from '../UpgradeLicense';
import { MODAL_UPGRADE_LICENSE } from 'store/modals/modals';
import upgradeLicenseImage from 'Images/ticketing-license.png';
import { ACTIONS } from 'tracking/GA/constants';

const ServiceNow = ({ onClose }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const isEnabledServiceNow = useSelector((state) => selectAllowedByFeature(state, FEATURE_TICKETING_SERVICENOW, PERMISSION_TYPES.CREATE));
  const configActive = useSelector(selectActiveConfig);
  const selectedTable = useSelector(selectSelectedTable);
  const url = useSelector(selectUrl);
  const selectedCategory = useSelector(selectCategory);
  const selectedSubcategory = useSelector(selectSubcategory);
  const clientId = useSelector(selectClientId);
  const secretKey = useSelector(selectKey);
  const username = useSelector(selectUserName);
  const password = useSelector(selectUserPassword);

  // obtengo los datos del servidor
  useEffect(() => {
    dispatch(fetchActiveConfig());
    dispatch(getTemplates(TOOL_NAME));
    dispatch(getServiceNowSettings());
  }, [dispatch]);

  const onChange = () => {
    dispatch(updateActiveConfigNew('servicenow'));
  };

  const accept = () => {
    if (isEnabledServiceNow) dispatch(save());
    onClose();
  };

  const allowedToSave = () => url &&
    ((selectedTable === 'Incidents') ? (selectedCategory && selectedSubcategory) : true) &&
    clientId &&
    secretKey &&
    username &&
    password;

  return (
    <Wrapper>
      <Header onAccept={ accept } onClose={ onClose } disabled={ isEnabledServiceNow && !allowedToSave() } />
      { isEnabledServiceNow
        ? <>
            <Title>{ intl.formatMessage({ id: 'preferences.ticketingTools.title' }) }</Title>
            <Toolbar>
              <ServicenowLogo />
              <StatusLabel isActive={ configActive.servicenow.active }>{ configActive.servicenow.active ? 'Active' : 'Inactive' }</StatusLabel>
              <Checkbox defaultChecked={ configActive.servicenow.active } onChange={ onChange } disabled={ !configActive.servicenow.available } />
            </Toolbar>
            <MainSettings />
          </>
        : <UpgradeLicense id={ MODAL_UPGRADE_LICENSE } image={ upgradeLicenseImage } descriptionIntlId="upgradeLicense.ticketing.description" trackingShow={ ACTIONS.showSettings } trackingClick={ ACTIONS.clickSettings } />
      }
    </Wrapper>
  );
};
export default ServiceNow;
