import get from 'lodash/get';
import find from 'lodash/find';

export const selectError = (state) => get(state, 'settings.ticketingTools.serviceNow.error', false);

export const selectMessage = (state) => get(state, 'settings.ticketingTools.serviceNow.message', '');

export const selectIsFetching = (state) => get(state, 'settings.ticketingTools.serviceNow.isFetching', false);

export const selectUrl = (state) => get(state, 'settings.ticketingTools.serviceNow.url', '');

export const selectAuthenticated = (state) => get(state, 'settings.ticketingTools.serviceNow.authenticated', false);

export const selectTemplate = (state) => get(state, 'settings.ticketingTools.serviceNow.template', '');

export const selectCategory = (state) => get(state, 'settings.ticketingTools.serviceNow.category', '');

export const selectSubcategory = (state) => get(state, 'settings.ticketingTools.serviceNow.subcategory', '');

export const selectCategories = () => [
  {
    desc: 'Inquiry / Help',
    name: 'Inquiry / Help',
    items: [
      { desc: 'Antivirus', name: 'Antivirus' },
      { desc: 'Email', name: 'Email' },
      { desc: 'Internet Application', name: 'Internet Application' }
    ]
  },
  {
    desc: 'Software',
    name: 'Software',
    items: [
      { desc: 'Email', name: 'Email' },
      { desc: 'Operating System', name: 'Operating System' }
    ]
  },
  {
    desc: 'Hardware',
    name: 'Hardware',
    items: [
      { desc: 'Cpu', name: 'Cpu' },
      { desc: 'Disk', name: 'Disk' },
      { desc: 'Keyboard', name: 'Keyboard' },
      { desc: 'Memory', name: 'Memory' },
      { desc: 'Monitor', name: 'Monitor' },
      { desc: 'Mouse', name: 'Mouse' }
    ]
  },
  {
    desc: 'Network',
    name: 'Network',
    items: [
      { desc: 'DHCP', name: 'DHCP' },
      { desc: 'DNS', name: 'DNS' },
      { desc: 'Ip Adress', name: 'Ip Adress' },
      { desc: 'VPN', name: 'VPN' },
      { desc: 'Wireless', name: 'Wireless' }
    ]
  },
  {
    desc: 'Database',
    name: 'Database',
    items: [
      { desc: 'DB2', name: 'DB2' },
      { desc: 'MS SQL Server', name: 'MS SQL Server' },
      { desc: 'Oracle', name: 'Oracle' }
    ]
  }
];

export const selectSubcategories = (categoryName) => {
  const categories = selectCategories();
  const category = find(categories, { name: categoryName });
  return get(category, 'items', []);
};

export const selectUserName = (state) => get(state, 'settings.ticketingTools.serviceNow.username', '');

export const selectPassword = (state) => get(state, 'settings.ticketingTools.serviceNow.username', '');

export const selectTable = () => [
  {
    desc: 'Incidents',
    name: 'Incidents'
  },
  {
    desc: 'Application Vulnerable Items',
    name: 'Application Vulnerable Items'
  }
];

export const selectSelectedTable = (state) => get(state, 'settings.ticketingTools.serviceNow.table', 'Incidents');

export const selectClientId = (state) => get(state, 'settings.ticketingTools.serviceNow.clientId', '');

export const selectKey = (state) => get(state, 'settings.ticketingTools.serviceNow.secretKey', '');

export const selectUserPassword = (state) => get(state, 'settings.ticketingTools.serviceNow.password', '');
