import styled from 'styled-components';
import colors from 'Styles/colors';
import { fadeIn, slideInLeft } from 'Styles/effects';

export const DetailWrapper = styled.div`
    display: flex;
    flex: 1 1 0px;
    flex-direction: column;
    padding-left: 4px;
    background-color: ${({ theme }) => theme.backgrounds.detailModal};
    height: 100%;
    width: 97%;
    position: relative;
`;
DetailWrapper.displayName = 'DetailWrapper';

export const Wrapper = styled.div.attrs(({ wrapperWidth }) => {
  let width = '55%';
  if (wrapperWidth) width = `${wrapperWidth}px`;
  return ({ style: { width } });
})`
    display: flex;
    flex-direction: row;
    overflow-y: hidden;
    overflow-x: hidden;
    position: absolute;
    right: 0;
    z-index: 1;
    box-shadow: -5px 0 12px 0 rgba(35, 35, 35, 0.11);
    height: 100%;
    ${slideInLeft};
`;
Wrapper.displayName = 'Wrapper';

export const Resizer = styled.div`
    height: 100%;
    border-radius: 4px;
    width: 8px;
    margin-right: auto;
    background-color: ${({ theme }) => theme.backgrounds.detailModal};
`;
Resizer.displayName = 'Resizer';

export const ResizerWrapper = styled.div`
    display: flex;
    height: 100%;
    cursor: col-resize;
    flex: 0 0 25px;
    background-color: ${({ theme }) => theme.backgrounds.detailModal};
    &:hover{
        & > * {
            background-color: ${({ theme }) => theme.backgrounds.detailModal};
        }
    }
    &:active{
        & > * {
            background-color: ${({ theme }) => theme.backgrounds.detailModal};
        }
    }
`;
ResizerWrapper.displayName = 'ResizerWrapper';

export const Transparency = styled.div`
  position: absolute;
  z-index: 1;
  top: 98px;
  left: 0px;
  width: 100%;
  height: 70px;
  background-image: linear-gradient(to bottom, ${({ theme }) => theme.backgrounds.detailModalTransparency}, rgba(216, 216, 216, 0));
  ${fadeIn(0, 1)};
`;
Transparency.displayName = 'Transparency ';

export const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgb(25, 25, 25, 0.4);
  z-index: 500;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  & > :first-child {
    box-shadow: 18px 43px 50px 0 rgba(167, 167, 167, 0.5);
    border-radius: 2px 2px 0 0;
    overflow: auto;
    max-height: 85%;
    background: ${colors.white};
    border-top: 10px solid ${colors.softBlue};
    padding: 36px 39px;
  }
  ${fadeIn(0, 1)}
`;
ModalWrapper.displayName = 'ModalWrapper';
