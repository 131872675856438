import styled from 'styled-components';
import { ReactComponent as warningIcon } from 'Images/warning-delete.svg';

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.backgrounds.modal};
  text-align: left;
  font-size: 12.5px;
  font-weight: normal;
  line-height: 1.44;
  color: ${({ theme }) => theme.text.modalTitle2};
  border: solid 1px ${({ theme }) => theme.shadows.dashboard};
  min-width: 500px;
`;
Wrapper.displayName = 'Wrapper';

export const WrapperTitle = styled.div`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  padding-bottom: 22px;
  align-items: center;
  & > *:not(:last-child) {
    margin-right: 8px;
  }
`;
WrapperTitle.displayName = 'WrapperTitle';

export const Title = styled.div`
  user-select: none;
  display: inline-block;
  font-size: 21.5px;
  font-weight: 600;
  line-height: 1.49;
  color: ${({ theme }) => theme.text.modalTitle2} !important;
  * > svg { 
    margin-right: 10px;
  }
`;
Title.displayName = 'Title';

export const Message = styled.div`
  user-select: none;
  display: block;
`;
Message.displayName = 'Message';

export const Icon = styled.div`
  display: inline-block;
  margin-right: 10px;
`;
Icon.displayName = 'Icon';

export const Footer = styled.div`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  width: 100%;
  margin-top: 35px;
  justify-content: flex-end;
  & > *:not(:last-child) {
    margin-right: 10px;
  }
  & > * {
    flex: 1 1 0px;
    max-width: 50%;
  }
`;
Footer.displayName = 'Footer';

export const WarningIcon = styled(warningIcon)`
  width: 27px;
  margin-right: 13px;
  height: 27px;
`;
WarningIcon.displayName = 'WarningIcon';
