import Password from 'Common/Components/Password/styled';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  border-bottom: 1px;
  border-bottom-style: solid;
  border-bottom-color: #e9e9e9;
  padding-bottom: 34px;
  text-align: initial;
`;
Wrapper.displayName = 'Wrapper';

export const Content = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  
  ${(props) => props.disabled && css`
    pointer-events: none;
    opacity: 0.4;
  `}
`;

export const StyledCol = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 0;
  max-width: 264px;
  min-width: 264px;
`;

export const Title = styled.div`
  margin: 26px 26px 19px 0;
  font-size: 14.5px;
  font-weight: 500;
  line-height: 0.97;
  text-align: left;
  color: ${({ theme }) => theme.text.modalTitle2};
  & input{
    margin-left: 25px;
  }
`;
Title.displayName = 'Title';

export const CurrentPassword = styled(Password)`
  max-width: unset;
  width: 100%;
`;
CurrentPassword.displayName = 'CurrentPassword';
