/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { ReactComponent as Copy } from 'Images/copy.svg';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Container = styled.div`
  position: relative;
`;

export const TextAreaStyled = styled.textarea`
width: 100%;
height: 100px;
padding: 10px;
font-size: 14px;
line-height: 1.5;
color: ${({ theme }) => theme.textfield.text};
background-color: ${({ theme }) => theme.textfield.background};
resize: none;
box-sizing: border-box;
font-family: inherit;
border-width: 0px 0px 0px 0px;
&:focus {
  outline: none;
  border-color: blue;
}
`;

export const CopyIcon = styled(Copy)`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #666;
  font-size: 20px;
  &:hover {
    color: #000;
  }
`;
