/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-children-prop */
import React from 'react';
// import { useSelector } from 'react-redux';
import DateFormatter from 'Common/Components/DateFormatter';
import get from 'lodash/get';
// import { selectAllowedByRole } from 'Hooks/usePermission/selector';
// import { FEATURE_REPORTING, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import Skeleton from 'Common/Components/Skeleton';
import { PropTypes } from 'prop-types';
import ActionButton from './components/ActionButton';
import DownloadDropdown from './components/DownloadDropdown';
import {
  ColumnCreator, ColumnLastActivity,
  ColumnVulns, DeleteIcon, EditIcon,
  ProcessingIcon, WordIcon, PDFIcon,
  CloneIcon, WarningIcon, RetryLabel,
  WarningWrapper, VerticalLine,
  IconWrapper
} from './styled';
import formatNumber from 'Common/Functions/FormatNumber';
import { useSelector } from 'react-redux';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_REPORTING, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';

const isProcessing = (rowInfo) => get(rowInfo, 'original.status', '') === 'processing';
const isCreated = (rowInfo) => get(rowInfo, 'original.status', '') === 'created';
const hasError = (rowInfo) => get(rowInfo, 'original.status', '') === 'error';
const isWord = (rowInfo) => get(rowInfo, 'original.template_name', '').includes('.doc');

const Warning = () => (
  <WarningWrapper>
    <VerticalLine />
    <WarningIcon />
  </WarningWrapper>
);

export const IconCell = ({ rowInfo, isFetching }) => {
  return (
    <IconWrapper>
      {isFetching
        ? null
        : isProcessing(rowInfo)
          ? <ProcessingIcon />
          : hasError(rowInfo)
            ? <Warning />
            : isWord(rowInfo)
              ? <WordIcon />
              : <PDFIcon />
      }
    </IconWrapper>
  );
};

export const VulnsCountCell = ({ rowInfo, isFetching }) => {
  if (isFetching) return <Skeleton />;
  const vulnsCount = get(rowInfo, 'original.vuln_count', '-');
  const shouldRender = isCreated(rowInfo);
  const parsedVulnsCount = formatNumber(vulnsCount);
  return shouldRender && <ColumnVulns children={ parsedVulnsCount } />;
};

export const CreatorCell = ({ rowInfo, isFetching }) => {
  if (isFetching) return <Skeleton />;
  const owner = get(rowInfo, 'original.metadata.owner', '');
  const creator = get(rowInfo, 'original.metadata.creator', '');
  const shouldRender = isCreated(rowInfo);
  return shouldRender && <ColumnCreator isProcessing={ isProcessing(rowInfo) } children={ owner || creator } />;
};

export const LastActivityCell = ({ rowInfo, isFetching, cloneAction }) => {
  const date = get(rowInfo, 'original.metadata.update_time', '');
  const reportId = get(rowInfo, 'original.id', 0);
  const shouldRender = isCreated(rowInfo);
  if (isFetching) return <Skeleton />;
  if (hasError(rowInfo)) return <RetryLabel children="Retry" onClick={ () => cloneAction(reportId, true) } />;
  return shouldRender && (
  <ColumnLastActivity>
    <DateFormatter date={ date } />
  </ColumnLastActivity>
  );
};

export const WorkspacesCell = ({ rowInfo, isFetching }) => {
  if (isFetching) return <Skeleton />;
  const workspaces = get(rowInfo, 'original.workspaces', '');
  const workspacesNames = workspaces.map((ws) => ws).join(', ');
  const shouldRender = isCreated(rowInfo);
  return shouldRender && <ColumnCreator children={ workspacesNames } />;
};

export const DownloadCell = ({ rowInfo, setError, setTitle, setMessage }) => {
  const reportId = get(rowInfo[0], 'id', '');
  const reportName = get(rowInfo[0], 'name', '');
  const templateName = get(rowInfo[0], 'template_name', '');
  const ids = rowInfo.map((e) => (e?.id));
  const reportsToDownload = { ids };
  return <DownloadDropdown setError={ setError } setTitle={ setTitle } setMessage={ setMessage } reportsToDownload={ reportsToDownload } reportId={ reportId } reportName={ reportName } template_name={ templateName } />;
};

export const CloneAndUpdateCell = ({ rowInfo, cloneAction }) => {
  const reportId = get(rowInfo[0], 'id', 0);
  const canClone = useSelector((state) => selectAllowedByRole(state, FEATURE_REPORTING, PERMISSION_TYPES.CREATE));
  return canClone && <ActionButton title="Clone and update" onClick={ () => cloneAction(reportId) } icon={ <CloneIcon /> } />;
};

export const CloneAndEditCell = ({ rowInfo, editAction }) => {
  const reportId = get(rowInfo[0], 'id', 0);
  const canEdit = useSelector((state) => selectAllowedByRole(state, FEATURE_REPORTING, PERMISSION_TYPES.PATCH));
  return canEdit && <ActionButton title="Clone and edit" onClick={ () => editAction(reportId) } icon={ <EditIcon /> } />;
};

export const DeleteCell = ({ deleteAction }) => {
  const canDelete = useSelector((state) => selectAllowedByRole(state, FEATURE_REPORTING, PERMISSION_TYPES.DELETE));
  return canDelete && <ActionButton title="Delete" onClick={ () => deleteAction() } icon={ <DeleteIcon /> } />;
};

IconCell.propTypes = { rowInfo: PropTypes.object.isRequired, isFetching: PropTypes.bool.isRequired };
VulnsCountCell.propTypes = { rowInfo: PropTypes.object.isRequired, isFetching: PropTypes.bool.isRequired };
CreatorCell.propTypes = { rowInfo: PropTypes.object.isRequired, isFetching: PropTypes.bool.isRequired };
LastActivityCell.propTypes = {
  rowInfo: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  cloneAction: PropTypes.func.isRequired
};
DownloadCell.propTypes = { rowInfo: PropTypes.object.isRequired, isFetching: PropTypes.bool.isRequired };
CloneAndUpdateCell.propTypes = {
  rowInfo: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  cloneAction: PropTypes.func.isRequired
};
CloneAndEditCell.propTypes = {
  rowInfo: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  editAction: PropTypes.func.isRequired
};

DeleteCell.propTypes = {
  rowInfo: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  deleteAction: PropTypes.func.isRequired
};
