/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-useless-constructor */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Dropdown from 'Common/Components/Dropdown';
import { eorDropdownStyle } from 'Common/styles/style';
import { easeOfResolution } from 'Screens/Constants';
import { Wrapper, Title } from './styled';
import { ThemeContext } from 'Context';

const Item = (props) => (
  <div className="d-inline-flex item-option">
    <span>{props.title.charAt(0).toUpperCase() + props.title.slice(1).toLowerCase()}</span>
  </div>
);

Item.propTypes = {
  title: PropTypes.string.isRequired
};

const EORDropdown = ({ defaultValue, updateValue }) => {
  // Accede a themeStyles desde el contexto de tema
  const { themeStyles } = useContext(ThemeContext);

  const onChange = (value) => {
    updateValue('easeOfResolution', value);
  };

  const options = easeOfResolution.map((element) => ({ label: <Item title={ element.desc } />, value: element.name }));

  return (
      <Wrapper>
        <Title>Ease of resolution</Title>
        <Dropdown customStyle={ eorDropdownStyle(themeStyles) } options={ options } className="ease-of-resolution" updateValue={ onChange } placeholder="Ease of Resolution" defaultValue={ defaultValue } />
      </Wrapper>
  );
};

EORDropdown.propTypes = {
  defaultValue: PropTypes.string,
  updateValue: PropTypes.func.isRequired
};

EORDropdown.defaultProps = {
  defaultValue: null
};

export default withRouter(EORDropdown);
