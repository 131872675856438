import styled from 'styled-components';
import colors from 'Styles/colors';
import { bigDot, smallDotEnd, verticalLine } from 'Styles/effects/dots';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  ${verticalLine}
  padding-left: 52px;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  margin-top: 40px;
  font-size: 14.5px;
  font-weight: 500;
  color: ${({ theme }) => theme.text.modalTitle2};
  margin-bottom:13px;
  ${bigDot(colors.purple5)}
`;
Title.displayName = 'Title';

export const Description = styled.div`
  font-size: 13.5px;
  color: ${colors.grey19};
  margin-bottom: 40px;
`;
Description.displayName = 'Description';

export const Buttons = styled.div`
  display: flex;
  ${smallDotEnd(colors.purple5)}
  position: relative;
`;
Buttons.displayName = 'Buttons';
