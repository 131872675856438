import styled from 'styled-components';
import CustomReactTable from 'Common/Components/Contextualization/CustomReactTable/styled';

export const Wrapper = styled.div`
  height: calc(100% - 74px);
  `;
Wrapper.displayName = 'Wrapper';

export const StyledTable = styled(CustomReactTable)`
  height: 100%;
  .rt-tbody .rt-td {
      & > * {
        overflow: visible;
      }
    }
  /* .rt-thead {
    z-index: 3 !important;
  } */

  .rt-table .rt-thead .rt-tr{
    height: 40px;
    border-top: ${({ theme }) => `1px solid ${theme.borders.actionDropDown}`};
    border-bottom: ${({ theme }) => `1px solid ${theme.borders.actionDropDown}`};
  }

  ${({ showNewUI, theme }) => showNewUI && `
    .rt-table .rt-thead {
      border-top: ${theme.borders.reportTable};
      border-bottom: ${theme.borders.reportTable};
    }
    .rt-table .rt-thead .rt-tr {
      background: ${theme.backgrounds.tableHeader} !important;
    }
    `}

  .rt-tbody .rt-tr{
    height: 45px;
  }
  
  .rt-tbody .rt-tr-group {
    &:hover {
      .actionButton{
        visibility: visible;
      }
    }
  }
  .actionButton {
    padding: 0px !important;
    visibility: hidden;
    overflow: visible;
  }

  .icon {
    height: 38px !important;
    padding: 0px !important;
  }

  .no-border-right{
    border-right: 0px !important;
  }

  .no-border-left{
    border-left: 0px !important;
  }
  .rt-tr-group > .rt-tr{
    & :nth-child(2) > div > div {
      color: ${({ theme }) => theme.text.reportTableItem};
    }
  }
`;
CustomReactTable.displayName = 'CustomReactTable';

export const TableHeader = styled.div`
  display: flex;
  align-items: center;
  color: #a6bbce;
  font-size: 10px;
  `;
TableHeader.displayName = 'TableHeader';
