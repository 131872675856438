import React from 'react';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import {
  NameCell, TypeCell, DescriptionCell, DateCell,
  WorkspacesCell
} from './cells';
import { CheckHeader } from './CheckHeader';
import { CheckColumn } from './CheckColumn';
import Skeleton from 'Common/Components/Skeleton';

export function getColumns (isFetching) {
  const columns = [{
    Header: () => <CheckHeader />,
    accessor: 'selected',
    id: 'Selected',
    maxWidth: 38,
    minWidth: 38,
    resizable: false,
    sortable: false,
    show: true,
    Cell: (rowInfo) => (isFetching ? <Skeleton /> : <CheckColumn rowInfo={ rowInfo } />),
    custom_order: -1
  }, {
    Header: () => <FormattedMessage id="analytics.graph.name" />,
    accessor: 'name',
    id: 'name',
    resizable: false,
    sortable: false,
    Cell: (rowInfo) => <NameCell rowInfo={ rowInfo } isFetching={ isFetching } />
  }, {
    Header: () => <FormattedMessage id="analytics.graph.type" />,
    accessor: 'type',
    id: 'type',
    resizable: false,
    width: 310,
    sortable: false,
    Cell: (rowInfo) => <TypeCell rowInfo={ rowInfo } isFetching={ isFetching } />
  }, {
    Header: () => <FormattedMessage id="analytics.graph.description" />,
    accessor: 'description',
    id: 'description',
    resizable: false,
    maxWidth: 350,
    minWidth: 55,
    sortable: false,
    Cell: (rowInfo) => <DescriptionCell rowInfo={ rowInfo } isFetching={ isFetching } />
  }, {
    Header: () => <FormattedMessage id="analytics.table.lastActivity" />,
    accessor: 'date',
    id: 'date',
    resizable: false,
    width: 150,
    sortable: false,
    Cell: (rowInfo) => <DateCell rowInfo={ rowInfo } isFetching={ isFetching } />
  },
  {
    Header: () => <FormattedMessage id="analytics.table.workspaces" />,
    accessor: 'workspaces',
    id: 'workspaces',
    resizable: false,
    width: 350,
    sortable: false,
    headerStyle: { borderRight: '1px dashed transparent' },
    Cell: (rowInfo) => <WorkspacesCell rowInfo={ rowInfo } isFetching={ isFetching } />
  }];
  return columns;
}

export function setOrder (sorted, data) {
  const desc = get(sorted, 'length', 0) > 0 && sorted[0].desc;
  const field = get(sorted, 'length', 0) > 0 && sorted[0].id;

  let graphsSorted = {};
  if (desc) {
    graphsSorted = data.sort((a, b) => b[field] - a[field]);
  } else {
    graphsSorted = data.sort((a, b) => a[field] - b[field]);
  }
  return graphsSorted;
}
