import styled from 'styled-components';
import { ReactComponent as Moon } from 'Images/moon (1).svg';
import { ReactComponent as Sun } from 'Images/sun.svg';
export const Header = styled.div`
  display: flex;
  align-items: center;
  grid-row: 1/2;
  padding:${({ agents }) => agents ? '20px 20px 10px 20px' : '20px 20px 24px 20px'};
`;
Header.displayName = 'Header';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${({ isOutsideWs }) => isOutsideWs ? '0px' : 'auto'};
`;
Wrapper.displayName = 'Wrapper';

export const Separator = styled.div`
  height: 16px;
  border-left: solid 1px ${({ theme }) => theme.text.vulnsCounter};
  transform: rotate(22deg);
  margin-right: 9px;
`;
Separator.displayName = 'Separator';

export const SwitchContainer = styled.div`
  margin-right: 22px;
`;
SwitchContainer.displayName = 'SwitchContainer';

export const IconMoon = styled(Moon)`
`;
Moon.displayName = 'Moon';

export const IconSun = styled(Sun)`
`;
Moon.displayName = 'Moon';
