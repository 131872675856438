import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as ChevronDown } from 'Images/arrow-down.svg';
import { ReactComponent as ScheduledIcon_ } from 'Images/scheduler_calendar.svg';

export const Wrapper = styled.div`
  width: 264px;
  align-self: center;
  user-select: none;
`;
Wrapper.displayName = 'Wrapper';

export const Toggle = styled.div`
  height: 38px;
  font-size: 11.5px;
  background-color: ${({ theme }) => theme.backgrounds.dropdown1};
  color: ${({ theme }) => theme.textfield.text};
  width: 264px;
  display:flex;
  align-items: center;
  cursor: pointer;
  border-radius: 2px;
  border: solid 3px ${({ theme }) => theme.borders.schedulersSectionSelector};
  box-shadow: 0 1px 15px 0 ${({ theme }) => theme.shadows.schedulersSectionSelector}, inset 0 -1px 30px 0 ${({ theme }) => theme.shadows.schedulersSectionSelectorInset};
`;
Toggle.displayName = 'Toggle';

export const IconWrapper = styled.div`
  border-radius: 2px;
  width: 38px;
  height: 100%;
  padding: 6px 1px 1px 9px;
  background-color: ${({ theme }) => theme.backgrounds.schedulerIcon};
`;
IconWrapper.displayName = 'IconWrapper';

export const Icon = styled(ScheduledIcon_)`
`;
Icon.displayName = 'Icon';

export const ArrowDown = styled(ChevronDown)`
  width: 10px;
  color: ${colors.grey5};
`;
ChevronDown.displayName = 'ChevronDown';

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.backgrounds.runAgentInput};
  width: 100%;
  height: 100%;
  padding: 0 15px 0 10px;
`;
TitleWrapper.displayName = 'TitleWrapper';

export const Title = styled.div`
  
`;
Title.displayName = 'Title';

export const Dropdown = styled.div`
  background-color: ${({ theme }) => theme.backgrounds.dropdown1Options};
  width: 264px !important;
  padding-top: 8px;
  padding-bottom: 14px;
  position: absolute;
  z-index: 99;
  border-radius: 3px;
  box-shadow: 0 2px 50px 0 rgba(28, 34, 67, 0.1);
  min-height: 46px;
  max-height: 100px;
  overflow: auto;
  border: 1px solid ${({ theme }) => theme.borders.schedulersOptions};
`;
Dropdown.displayName = 'Dropdown';

export const Item = styled.div`
  cursor: pointer;
  padding-left: 21px;
  padding-right: 17px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background-color: ${({ theme }) => theme.backgrounds.dropdown1OptionsHover} !important;
  }

  &:active {
    color: ${({ theme }) => theme.textfield.text};
  }
`;
Item.displayName = 'Item';

export const SectionName = styled.span`
  font-size: 13.5px;
  font-weight: 400;
  font-stretch: normal;
  color: ${({ theme }) => theme.textfield.text};
  max-width: 210px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
SectionName.displayName = 'SectionName';

export const Count = styled.span`
  font-size: 13.5px;
  font-weight: 400;
  color: ${({ theme }) => theme.textfield.text};
  max-width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
Count.displayName = 'Count';
