import styled from 'styled-components';
import { tagStyles } from 'Styles/styles';

export const TagWrapper = styled.div`
  width: fit-content;
  border-radius: 3px;
  box-shadow: 2px 2px 5px 0 rgb(0 0 0 / 6%);
  border: ${({ theme }) => `solid 1px ${theme.borders.importanceMeter}`};
  background-color: ${({ theme }) => theme.buttons.custom.background};
  color: ${({ theme }) => theme.text.workspaceItem};
  display: inline-block;
  padding: 3px 7px;
  margin-top: 5px;
  &:hover {
   background-color:${({ theme, state }) => (state ? theme.textfield.focus : 'transparent')};
  }
  &:active {
    border: solid 1px #90a9c0;
    background-color: #bec8d2;
    color:#63758d;
  }
`;
TagWrapper.displayName = 'TagWrapper';

export const TagStyles = styled.span`
  ${tagStyles};
  color: inherit;
  font-weight: 500;
  cursor: pointer;
`;
TagStyles.displayName = 'TagStyles';
