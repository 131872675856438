import React from 'react';
import get from 'lodash/get';
import { NameStyled, Wrapper } from './styled';
import { useDispatch } from 'react-redux';
import { selectScheduler, setIsCreateSchedule } from 'store/Schedule/actions';
import { openModal } from 'store/modals/actions';
import { MODAL_SCHEDULE_CREATE_EDIT } from 'store/modals/modals';

const NameColumn = ({ rowInfo }) => {
  const scheduler = get(rowInfo, 'original', {});
  const name = get(rowInfo, 'original.value.description', '');
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(selectScheduler(scheduler));
    dispatch(setIsCreateSchedule(false));
    dispatch(openModal(MODAL_SCHEDULE_CREATE_EDIT));
  };

  return (
    <Wrapper>
      <NameStyled onClick={ handleClick }>{ name }</NameStyled>
    </Wrapper>
  );
};

export default NameColumn;
