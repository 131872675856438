/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Wrapper = styled.div`
  z-index: 2;
  .react-datepicker {
    background-color: ${({ theme }) => theme.backgrounds.modal};
    border: none;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .react-datepicker__header {
    background-color: ${({ theme }) => theme.backgrounds.modal2};
    color: ${({ theme }) => theme.text.modalTitle2};
    border-bottom: none;
    padding: 10px 0;
  }

  .react-datepicker__day,
  .react-datepicker__day-name {
    color: ${({ theme }) => theme.text.modalTitle2};
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: blue;
    color: white;
  }

  .react-datepicker__day:hover {
    background-color: lightblue;
    color: white;
  }
  
  .react-datepicker__day:hover {
    background-color: lightblue;
    color: darkblue;
  }

  .react-datepicker__current-month {
    color: ${({ theme }) => theme.text.modalTitle2};
  }
`;
