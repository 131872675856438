import { max, replace } from 'lodash';
import React, { useContext } from 'react';
import {
  VictoryBar, VictoryChart, VictoryAxis, VictoryStack, VictoryLabel, VictoryVoronoiContainer, VictoryTooltip
} from 'victory';
import { Wrapper } from './styled';
import formatNumber from 'Common/Functions/FormatNumber';
import { ThemeContext } from 'Context';

const typeByHex = {
  '#a4247a': 'open',
  '#bec8d2': 'closed',
  '#ea3158': 're_opened',
  '#88cc3f': 'risk_accepted'
};

const typesText = {
  open: 'Open',
  closed: 'Closed',
  re_opened: 'Re_Opened',
  risk_accepted: 'Risk_accepted'
};

const VulnsPerStatus = ({ data }) => {
  const height = 150 + (data.length * 25);
  const maxTicks = max(data.map((item) => item.data.total));
  const tickCount = maxTicks < 5 ? maxTicks : 5;
  const { themeStyles } = useContext(ThemeContext);

  const styles = {
    colorScale: ['#a4247a', '#bec8d2', '#ea3158', '#88cc3f'],
    VictoryTooltip: { fontSize: '10px', fill: '#63758d', fontFamily: 'Sequel Sans' },
    flyoutStyle: { stroke: '#d9e4ef', strokeWidth: 1, fill: '#fff' },
    none: { display: 'none' },
    VictoryAxis: {
      axis: {
        stroke: themeStyles.text.analyticsGraphAxis,
        strokeWidth: 1
      },
      grid: {
        fill: '#bec8d2',
        stroke: '#bec8d2',
        strokeDasharray: '10,5',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        pointerEvents: 'painted',
        strokeWidth: tickCount ? '2' : '0'
      },
      tickLabels: {
        fontFamily: 'Sequel Sans',
        fill: themeStyles.text.analyticsGraphTickLabels,
        fontSize: 12
      }
    },
    font1: {
      fontFamily: 'Sequel Sans',
      fill: themeStyles.text.analyticsGraphTickLabels,
      fontSize: 12,
      stroke: '#63758d',
      strokeWidth: 0
    },
    font2: {
      axis: {
        stroke: themeStyles.text.analyticsGraphAxis,
        strokeWidth: 1
      },
      tickLabels: {
        fontFamily: 'Sequel Sans',
        fill: themeStyles.text.analyticsGraphTickLabels,
        fontSize: 8,
        stroke: 'transparent',
        strokeWidth: 0
      }
    },
    font3: {
      fontFamily: 'Sequel Sans',
      fill: themeStyles.text.analyticsGraphTickLabels,
      fontSize: 12,
      stroke: '#63758d',
      strokeWidth: 0
    },
    vulnAmountLabel: {
      fontFamily: 'Sequel Sans',
      fill: themeStyles.text.analyticsGraphTickLabels,
      fontSize: 12,
      stroke: '#63758d',
      strokeWidth: 0
    }
  };

  const getLabels = ({ datum }) => {
    const type = typeByHex[datum.style.data.fill];
    const quantity = datum.data[type];
    if (quantity === 0) return null;
    return `${quantity} ${replace(typesText[type], '_', ' ')} Vulnerabilities`;
  };
  const types = ['open', 'closed', 're_opened', 'risk_accepted'];

  return (
    <Wrapper>
      <VictoryChart
        height={ height }
        domainPadding={ 40 }
        padding={ {
          left: 100, top: 0, right: 0, bottom: 80
        } }
        containerComponent={ (
          <VictoryVoronoiContainer
            labels={ getLabels }
            labelComponent={ (
              <VictoryTooltip
                constrainToVisibleArea
                flyoutStyle={ styles.flyoutStyle }
                flyoutPadding={ {
                  top: 10, right: 12, bottom: 10, left: 11
                } }
                style={ styles.VictoryTooltip }
                cornerRadius={ 2 }
                pointerLength={ 0 }
                centerOffset={ { x: 0, y: 25 } }
              />
            ) }
          />
      ) }
      >
        <VictoryAxis
          label="Vulnerabilities Status"
          axisLabelComponent={ (
            <VictoryLabel
              dy={ -60 }
              style={ styles.font1 }
            />
          ) }
          style={ styles.font2 }
        />
        <VictoryAxis
          label="Number of Vulnerabilities"
          tickCount={ tickCount || 1 }
          tickFormat={ (t) => `${formatNumber(t)}` }
          axisLabel={ { fontSize: 20, padding: 30 } }
          axisLabelComponent={ (
            <VictoryLabel
              style={ styles.vulnAmountLabel }
              dy={ 20 }
            />
          ) }
          dependentAxis
          style={ styles.VictoryAxis }
        />
        <VictoryStack
          horizontal
          colorScale={ styles.colorScale }
        >
          {types.map((type, index) => {
            const y = `data.${type}`;
            return (
              <VictoryBar
                key={ `victoryBar_${index}` }
                sortKey="data.total"
                labelComponent={
                  <VictoryLabel style={ styles.none } />
                }
                data={ data }
                y={ y }
                x="label"
                style={ { data: { width: 20 } } }
              />
            );
          })}
        </VictoryStack>
      </VictoryChart>
    </Wrapper>
  );
};

export default VulnsPerStatus;
