import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from 'Common/Components/Checkbox';
import { allSchedulersAreSelected, selectData, selectSchedulersSelected, selectSelectedSection, someSchedulerIsSelected } from 'store/Schedule/selectors';
import { selectAllScheduler, unSelectAll } from 'store/Schedule/actions';

export const CheckHeader = () => { // eslint-disable-line import/prefer-default-export
  const allSchedulerSelected = useSelector(allSchedulersAreSelected);
  const someSchedulerSelected = useSelector(someSchedulerIsSelected);
  const section = useSelector(selectSelectedSection);
  const schedulers = useSelector((state) => selectData(state, section));
  const count = schedulers.length;
  const schedulersSelected = useSelector(selectSchedulersSelected);
  const dispatch = useDispatch();
  const allSchedulersSelected = someSchedulerSelected && (count === schedulersSelected.length);

  const handleClick = () => {
    if (allSchedulersSelected) {
      dispatch(unSelectAll());
    } else {
      dispatch(selectAllScheduler());
    }
  };

  return (
    <Checkbox theme="grey" onChange={ handleClick } state={ allSchedulerSelected } partialChecked={ someSchedulerSelected && !allSchedulerSelected } />
  );
};
