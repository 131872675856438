/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDetail } from 'store/Contextualization/AssetDetail/selectors';
import get from 'lodash/get';
import UploadEvidence from './UploadEvidence';
import Attachment from 'Common/Components/EvidenceTab/components/Attachment';
import { Wrapper, Container } from 'Common/Components/EvidenceTab/styled';
import api from 'services/api';
import { selectCurrentWorkspace, selectIsReadOnly } from 'store/Faraday/selectors';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_VULNS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { LOAD_EVIDENCE_SUCCESS } from 'store/Manage/types';
import { selectAttachments } from 'store/Manage/selectors';

const EvidenceTab = ({ isEditing }) => {
  const dispatch = useDispatch();
  const [evidences, setEvidences] = useState({});
  const attachments = useSelector(selectAttachments);
  const currentVuln = useSelector((state) => selectDetail('vulns', state));
  const vulnId = get(currentVuln, '_id', 0);
  const readOnly = useSelector(selectIsReadOnly);
  const canUpdate = useSelector((state) => selectAllowedByRole(state, FEATURE_VULNS, PERMISSION_TYPES.UPDATE));
  const workspace = useSelector(selectCurrentWorkspace);
  // const files = attachments;
  const files = isEditing ? attachments : evidences;

  const getEvidences = useCallback(async () => {
    try {
      const data = await api.attachments.getAttachments(workspace, vulnId);
      setEvidences(data);
    } catch (e) {
      setEvidences({});
    } finally {
      dispatch({ type: LOAD_EVIDENCE_SUCCESS });
    }
  }, [workspace, vulnId]);

  const deleteEvidence = useCallback(async (name) => {
    try {
      await api.attachments.removeAttachments(workspace, vulnId, name);
      const newEvidences = { ...evidences };
      delete newEvidences[name];
      setEvidences(newEvidences);
    } catch (e) {
      setEvidences(evidences);
    }
  }, [evidences, workspace, vulnId]);

  useEffect(() => {
    getEvidences();
  }, [getEvidences]);

  return (
    <>
    <Wrapper>
      <Container>
        {!readOnly && canUpdate && <UploadEvidence getEvidences={ getEvidences } isEditing={ isEditing } vulnId={ vulnId } /> }
        {Object.keys(files).map((key) => <Attachment vulnDescId={ vulnId } key={ key } deleteEvidence={ deleteEvidence } evidence={ evidences[key] } name={ key } isEditing={ isEditing } desc={ files[key]?.description } />)}
      </Container>
    </Wrapper>
    </>
  );
};

export default EvidenceTab;
