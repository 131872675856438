import React from 'react';
import { Wrapper, Text, CloseButton, CloseIcon, Content } from './styled';

const Success = ({ message, close }) => {
  return (
    <Wrapper>
      <Content>
        <Text>{ message}</Text>
        <CloseButton onClick={ close }><CloseIcon /></CloseButton>
      </Content>
    </Wrapper>
  );
};

export default Success;
