import api from 'services/api';
import {
  selectIsShowingContextMenu, selectedUserId, selectedUserUsername,
  selectedUserEmail, selectedUserWorkspaces, selectedUserRoles
} from 'store/Users/selectors';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import { ACTIONS, CATEGORIES } from 'tracking/GA/constants';
import { trackEvent } from 'tracking/GA';
import { CURRENT_API_VERSION } from 'services/api/connector';
import { openModal } from 'store/modals/actions';
import { MODAL_UPGRADE_LICENSE } from 'store/modals/modals';
import { selectMaxUsersLimit } from 'store/Config/selector';
import { selectQueryParam } from 'store/Filters/selectors';
import {
  setOrderBy, setPageNumber, clearFilters, setFilter
} from 'store/Filters/actions';
import { SET_ORDER_BY } from 'store/Filters/types';

export const GET_DATA_USER_START = 'GET_DATA_USER_START';
export const GET_DATA_USER_FAIL = 'GET_DATA_USER_FAIL';
export const GET_DATA_USER_SUCCESS = 'GET_DATA_USER_SUCCESS';

export const USER_CREATE_START = 'USER_CREATE_START';
export const USER_CREATE_SUCCESS = 'USER_CREATE_SUCCESS';
export const USER_CREATE_FAIL = 'USER_CREATE_FAIL';
export const USER_SELECTED = 'USER_SELECTED';
export const USER_SELECTED_ALL = 'USER_SELECTED_ALL';
export const USER_UNSELECT_ALL = 'USER_UNSELECT_ALL';
export const RESET_STATE_USER = 'RESET_STATE_USER';
export const RESET_TABLE_USER_WITH_ATTRIBUTTES = 'RESET_TABLE_USER_WITH_ATTRIBUTTES';
export const SET_USER_PAGE = 'SET_USER_PAGE';
export const SHOW_MODAL_DELETE_CONFIRMATION_USER = 'SHOW_MODAL_DELETE_CONFIRMATION_USER';
export const HIDE_MODAL_DELETE_CONFIRMATION_USER = 'HIDE_MODAL_DELETE_CONFIRMATION_USER';
export const DELETE_USER = 'DELETE_USER';
export const HIDE_MODAL_DELETE_CONFIRMATION_USER_SUCCED = 'HIDE_MODAL_DELETE_CONFIRMATION_USER_SUCCED';
export const ENABLE_PROPAGATION_USER = 'ENABLE_PROPAGATION_USER';
export const SET_USER_2FA_STATUS = 'SET_USER_2FA_STATUS';
export const UPDATE_SECOND_FACTOR_SUCCESS = 'UPDATE_SECOND_FACTOR_SUCCESS';
export const MORE_OPTIONS_STATUS_CHANGE_USERS = 'MORE_OPTIONS_STATUS_CHANGE_USERS';
export const UPDATE_SUCCESS_BULK = 'UPDATE_SUCCESS_BULK';
export const UPDATE_FAIL_BULK = 'UPDATE_FAIL_BULK';
export const GET_DATA_USERS_NEXT_PAGE = 'GET_DATA_USERS_NEXT_PAGE';
export const GET_DATA_USERS_FILTER = 'GET_DATA_USERS_FILTER';
export const SET_USER_FOR_EDIT = 'SET_USER_FOR_EDIT';
export const SET_VISIBILITY_CREATE_MODAL_USER = 'SET_VISIBILITY_CREATE_MODAL_USER';
export const RESET_STATE_USER_CREATE_UPDATE = 'RESET_STATE_USER_CREATE_UPDATE';

export const SET_SHOW_USERS_CONTEXT_MENU = 'SET_SHOW_USERS_CONTEXT_MENU';
export const GET_USERS_COUNT_FAIL = 'GET_USERS_COUNT_FAIL';

let globalState;

export function getData () {
  return async (dispatch, getState) => {
    dispatch({ type: GET_DATA_USER_START });
    const state = getState();
    const params = selectQueryParam('users', state);
    try {
      const response = await api.users.fetchUsers(params);
      return dispatch({ type: GET_DATA_USER_SUCCESS, data: response.rows.map((user) => user.doc), userCount: response.total_rows });
    } catch (e) {
      return dispatch({ type: GET_DATA_USER_FAIL, data: e });
    }
  };
}

// Summary: Add user selected to state.
export function selectUser (usersSelectedInfo) {
  return (dispatch) => {
    dispatch({
      type: USER_SELECTED, usersSelectedInfo
    });
  };
}

// Summary: Select all users on the table.
export function selectAll (selected) {
  return (dispatch) => {
    dispatch({ type: USER_SELECTED_ALL, selected });
  };
}

export function unselectAll () {
  return (dispatch) => {
    dispatch({ type: USER_UNSELECT_ALL });
  };
}

export function enablePropagation () {
  return (dispatch) => {
    dispatch({ type: ENABLE_PROPAGATION_USER });
  };
}

// Summary: reset state of user screen
export function resetState () {
  return (dispatch) => {
    dispatch({ type: RESET_STATE_USER });
  };
}

export function setPage (page) {
  return async (dispatch) => {
    dispatch(setPageNumber('users', page));
    dispatch(getData());
  };
}

// Summary: delete user/s from database
export function deleteUserSelected () {
  return (dispatch, getState) => {
    globalState = getState();
    let userCount = globalState.users.userSelected.length;
    // let templateSuccedCount = 0;

    dispatch({ type: DELETE_USER });

    // TODO: Modify backend, so it should receive an array and return message
    // when some users could not be deleted. Then, add request.
    globalState.users.userSelected.forEach((user) => {
      const url = `/_api/${CURRENT_API_VERSION}/users/${user.id}`;

      fetch(url, {
        method: 'DELETE',
        body: {},
        headers: {
          'Content-type': 'application/json'
        }
      }).then(() => {
        userCount -= 1;
        dispatch(trackEvent(CATEGORIES.user, ACTIONS.deleteUser.name, ACTIONS.deleteUser.label, user.id));

        if (userCount === 0) {
          dispatch({ type: HIDE_MODAL_DELETE_CONFIRMATION_USER_SUCCED });
          dispatch(getData());
        }
      }).catch(() => {
        userCount -= 1;

        if (userCount === 0) {
          dispatch({ type: HIDE_MODAL_DELETE_CONFIRMATION_USER_SUCCED });
        }
      });
    });
  };
}

// Summary: Hide confirmation modal when user delete user/s
export function hideUserModalDelete () {
  return (dispatch) => {
    dispatch({ type: HIDE_MODAL_DELETE_CONFIRMATION_USER });
  };
}

// Summary: Change 2fa status
export function set2fa (id, params) {
  return async (dispatch) => {
    dispatch({ type: SET_USER_2FA_STATUS });

    try {
      const response = await api.users.updateSecondFactor(id, params);
      if (response.success) {
        const responseUser = await api.users.getUserById(id);
        return dispatch({ type: UPDATE_SECOND_FACTOR_SUCCESS, response: responseUser });
      }
      return dispatch({ type: GET_DATA_USER_FAIL, data: 'An error has occurred.' });
    } catch (e) {
      return dispatch({ type: GET_DATA_USER_FAIL, data: 'An error has occurred.' });
    }
  };
}

// Summary: Show confirmation modal when user delete user/s
export function showUserModalDelete () {
  return (dispatch) => {
    dispatch({ type: SHOW_MODAL_DELETE_CONFIRMATION_USER });
  };
}

// Change status of more options in navigation bar
export function moreStatusChange (value) {
  return (dispatch) => {
    dispatch({ type: MORE_OPTIONS_STATUS_CHANGE_USERS, value });
  };
}

// Update second factor in bulk from context menu
export function update2faBulk () {
  return (dispatch, getState) => {
    const { userSelected } = getState().users;

    try {
      let cont = userSelected.length;
      const newUsersList = [];

      userSelected.forEach(async (user) => {
        const response = await api.users.updateSecondFactor(user.id, user.status_2fa === 'disabled' ? 'request2fa' : 'disable2fa');
        if (response.success) {
          const responseUser = await api.users.getUserById(user.id);
          newUsersList.push(responseUser);
        }
        cont -= 1;

        if (cont === 0) return dispatch({ type: UPDATE_SUCCESS_BULK, data: newUsersList });
        return '';
      });

      return dispatch({ type: '' });
    } catch (e) {
      return dispatch({ type: GET_DATA_USER_FAIL, data: 'An error has occurred.' });
    }
  };
}

// Update roles in bulk from context menu
export function updateRoleBulk (role) {
  return async (dispatch, getState) => {
    const { userSelected } = getState().users;
    try {
      const userPromises = userSelected.map((user) => api.users.updateUser(user.id, { ...user, roles: [role] }));
      const updatedUsersList = await Promise.all(userPromises);
      return dispatch({ type: UPDATE_SUCCESS_BULK, data: updatedUsersList });
    } catch (e) {
      return dispatch({ type: GET_DATA_USER_FAIL, data: 'An error has occurred.' });
    }
  };
}

// Save users in workspaces
export function saveUserWorkspaces (workspacesSelected) {
  return (dispatch, getState) => {
    // TODO: Review sorting and filters when filtering method created
    const { userSelected } = getState().users;

    try {
      let cont = workspacesSelected.length;
      const newUsersList = cloneDeep(userSelected);

      workspacesSelected.forEach(async (workspace) => {
        const response = await api.workspace.getWsStats(workspace.name);
        const usersAppend = userSelected.filter((user) => !response.users.includes(user.name));
        const workspaceUpdated = response;
        workspaceUpdated.users = workspaceUpdated.users.concat(usersAppend.map((u) => u.name));
        workspaceUpdated.duration = {
          start_date: workspaceUpdated.duration.start_date ? workspaceUpdated.duration.start_date : '',
          end_date: workspaceUpdated.duration.end_date ? workspaceUpdated.duration.end_date : ''
        };
        workspaceUpdated.stats = undefined;

        userSelected.forEach((u, index) => {
          if (u.workspaces.filter((ws) => ws.name === workspace.name).length === 0) newUsersList[index].workspaces.push(workspace.name);
        });

        await api.workspace.updateWorkspace(workspaceUpdated.name, workspaceUpdated);
        cont -= 1;

        if (cont === 0) {
          return dispatch({ type: UPDATE_SUCCESS_BULK, data: newUsersList });
        }
        return dispatch({ type: '' });
      });

      return dispatch({ type: '' });
    } catch (e) {
      return dispatch({ type: UPDATE_FAIL_BULK, data: 'Some changes could not be saved.' });
    }
  };
}

export function saveUser (data) {
  return async (dispatch, getState) => {
    const state = getState();
    const id = selectedUserId(state);
    const name = selectedUserUsername(state);
    const email = selectedUserEmail(state);
    const workspacesNames = selectedUserWorkspaces(state);
    const roles = selectedUserRoles(state);

    const orderWs = workspacesNames.sort((a, b) => {
      if (a === b) { return 0; } else if (a < b) { return -1; } else { return 1; }
    });
    const obj = {
      name,
      email,
      workspaces: orderWs.toString(),
      roles: roles.toString()
    };

    dispatch({ type: USER_CREATE_START });
    try {
      let userResponse = '';
      if (id) {
        const changes = {};
        if (data.password) {
          changes.password = data.password;
        }
        for (const item in obj) {
          if (item === 'workspaces' || item === 'roles') {
            const orderedItem = data[item].sort((a, b) => {
              if (a === b) { return 0; } else if (a < b) { return -1; } else { return 1; }
            });
            const stringItem = orderedItem.toString();
            if (obj[item] !== stringItem) {
              changes[item] = stringItem ? stringItem.split(',') : [];
            }
          } else {
            if (obj[item] !== data[item]) {
              changes[item] = data[item];
            }
          }
        }

        userResponse = await api.users.updateUser(id, changes);
        dispatch(trackEvent(CATEGORIES.user, ACTIONS.editUser.name, ACTIONS.editUser.label, userResponse._id));
      } else {
        userResponse = await api.users.saveUser(data);
        dispatch(trackEvent(CATEGORIES.user, ACTIONS.createUser.name, ACTIONS.createUser.label, userResponse.id));
      }

      dispatch({ type: USER_CREATE_SUCCESS, user: userResponse, id });
      dispatch(getData());
      return dispatch({ type: RESET_STATE_USER_CREATE_UPDATE });
    } catch (e) {
      return dispatch({ type: USER_CREATE_FAIL, error: e.message || 'Unable to create user' });
    }
  };
}

export function sortData (sorting) {
  return (dispatch) => {
    if (sorting.length > 0) {
      const orderByRoles = sorting.some((item) => item.id === 'roles__weight');
      if (orderByRoles) {
        const desc = sorting.map((item) => item.desc);
        const newOrderByRoles = [
          {
            field: 'active',
            direction: 'desc'
          },
          {
            field: 'roles__weight',
            direction: desc[0] ? 'desc' : 'asc'
          }
        ];
        dispatch({ type: SET_ORDER_BY, entity: 'users', order_by: newOrderByRoles });
      } else {
        dispatch(setOrderBy('users', sorting));
      }
      dispatch(getData());
    }
  };
}

export function nextPage () {
  return (dispatch, getState) => {
    const isContextMenuOpen = selectIsShowingContextMenu(getState());
    if (!isContextMenuOpen) {
      // react-contextmenu has a bug wherein a scroll in the context menu would trigger a scroll
      // on the parent component (Table) even though it isn't scrollable, triggering a page fetch when it shouldn't
      dispatch({ type: GET_DATA_USERS_NEXT_PAGE });
      dispatch(getData());
    }
  };
}

export function setFilterUsers (value) {
  return (dispatch) => {
    const newFilter = { name: 'username', op: 'ilike', val: `%${value}%` };
    if (value) dispatch(setFilter('users', newFilter));
    else dispatch(clearFilters('users'));
    dispatch(getData());
  };
}

export function showEditModal () {
  return async (dispatch) => dispatch({ type: SET_USER_FOR_EDIT, showModalCreate: true });
}

export function hideCreateEditModal () {
  return (dispatch) => {
    dispatch({ type: RESET_STATE_USER_CREATE_UPDATE });
    dispatch({ type: SET_VISIBILITY_CREATE_MODAL_USER, value: false });
  };
}

export function setVisibilityCreateModal (value) {
  return async (dispatch, getState) => {
    const users = await api.users.fetchActiveUsers(); // obtengo el total de users activos.
    if (value && get(users, 'total_rows', 0) >= selectMaxUsersLimit(getState())) {
      dispatch(openModal(MODAL_UPGRADE_LICENSE));
    } else {
      dispatch({ type: RESET_STATE_USER_CREATE_UPDATE });
      dispatch({ type: SET_VISIBILITY_CREATE_MODAL_USER, value });
    }
  };
}

export function setShowTableContextMenu (value) {
  return (dispatch) => {
    dispatch({ type: SET_SHOW_USERS_CONTEXT_MENU, value });
  };
}
