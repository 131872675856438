/* eslint-disable no-negated-condition */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { redirectToVulnDetail, selectRow, unSelectAll } from 'store/Manage/actions';
import get from 'lodash/get';
import { selectAllowedByFeature, selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_DUPLICATES, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { selectShowQuickPlanner } from 'store/Manage/selectors';
import itemTypes from 'Common/Components/DragAndDrop/ItemTypes';
import Skeleton from 'Common/Components/Skeleton';
import {
  Text, MainDuplicateIcon, DuplicateIcon, IconWrapper, Wrapper
} from './styled';

// eslint-disable-next-line import/prefer-default-export
export const Name = ({
  showSkeleton, groupBy, isPivot, vuln
}) => {
  const dispatch = useDispatch();
  const canUseDuplicates = useSelector((state) => selectAllowedByFeature(state, FEATURE_DUPLICATES, PERMISSION_TYPES.READ) && selectAllowedByRole(state, FEATURE_DUPLICATES, PERMISSION_TYPES.READ));

  const DuplicateMark = () => {
    const isMainDuplicate = get(vuln, 'is_main', false);
    const isDuplicate = get(vuln, 'vulnerability_duplicate_id', 0);
    if (!isDuplicate && !isMainDuplicate) return null;
    return (
      <IconWrapper>
        { isMainDuplicate ? <MainDuplicateIcon /> : <DuplicateIcon /> }
      </IconWrapper>
    );
  };

  const isQuickPlannerOpen = useSelector(selectShowQuickPlanner);

  const handleClick = (e) => {
    if (isQuickPlannerOpen) return;
    dispatch(unSelectAll());
    dispatch(selectRow(e, vuln));
    dispatch(redirectToVulnDetail(vuln._id));
  };

  const [, drag, preview] = useDrag(() => ({
    type: itemTypes.VULN,
    item: vuln,
    collect: () => ({
      canDrag: isQuickPlannerOpen
    }),
    canDrag: isQuickPlannerOpen
  }), [vuln]);

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (showSkeleton) return <Skeleton />;
  if (groupBy && isPivot) return null;
  return (
    <Wrapper ref={ drag }>
      <Text confirmed={ vuln.confirmed } onClick={ handleClick }>{vuln.name}</Text>
      { canUseDuplicates && <DuplicateMark /> }
    </Wrapper>
  );
};
