/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  width: 100%;
  padding: 27px 28px 0px 16px;
  gap: 20px;
  background: ${({ theme }) => theme.backgrounds.ticketFlap};`;
Wrapper.displayName = 'Wrapper';

export const Fields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
Wrapper.displayName = 'Fields';

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 13.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
`;
Wrapper.displayName = 'Title';
