import fetchApi from 'services/api/connector';

export const getActiveWorkspaces = () => fetchApi('ws?filter&active=true');

export const addWorkspace = (data) => fetchApi('ws', { method: 'POST', data });

export const deleteWorkspace = (ws) => fetchApi(`ws/${ws}`, { method: 'DELETE' });

export const getReportStatus = (commandId) => fetchApi(`global_commands/${commandId}`);

export const getWsStats = (ws) => fetchApi(`ws/${ws}`);

export const createWorkspace = (data) => fetchApi('ws', { method: 'POST', data });

export const updateWorkspace = (ws, data) => fetchApi(`ws/${ws}`, { method: 'PATCH', data });

export const fetchWorkspaces = (queryParam, histogram, stats, listView, onlyTotalVulns) => fetchApi(`ws/filter?q=${escape(JSON.stringify(queryParam))}&histogram=${histogram}&exclude_stats=${stats}&list_view=${listView}&only_total_vulns=${onlyTotalVulns}`);

export const getWsStatsFiltered = (ws, queryParam) => fetchApi(`ws/${ws}?${queryParam}`);

export const fetchWorkspacesForSelector = (queryParam, histogram, stats, onlyTotalVulns) => fetchApi(`ws/filter?q=${escape(JSON.stringify(queryParam))}&histogram=${histogram}&exclude_stats=${stats}&only_total_vulns=${onlyTotalVulns}`);

export const fetchVulnsCountInWs = (ws) => fetchApi(`ws/${ws}/vulns/count?group_by=confirmed`);

export const fetchVulnsCountAllWorkspaces = () => fetchApi('vulns/count?group_by=confirmed');

export const fetchHostsCountInWs = (ws) => fetchApi(`ws/${ws}/hosts/count?group_by=owned`);

export const fetchHostsCountInAllWorkspaces = () => fetchApi('hosts/count?group_by=owned');

export const updateWorkspaces = (data) => fetchApi('ws/bulk_update', { method: 'PATCH', data, version: 'v3' });
