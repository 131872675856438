import React, { useEffect } from 'react';
import { useFilePicker } from 'use-file-picker';
import { useDispatch } from 'react-redux';
import { setAttachments } from 'Screens/ManageEditCreate/actions/Actions';
import { addEvidences } from 'store/Manage/actions';
import { openModal } from 'store/modals/actions';
import { MODAL_EVIDENCE_WARNING_UPLOAD } from 'store/modals/modals';
import { ImageWrapper, EvidenceDefault, BlueText } from './styled';
import { isEmpty } from 'lodash';

const UploadEvidence = ({ getEvidences, isEditing, vulnId }) => {
  const dispatch = useDispatch();
  const [openFileSelector, { plainFiles, clear, errors }] = useFilePicker({ multiple: true, readFilesContent: false, maxFileSize: 20 });

  useEffect(() => {
    const onUpload = (plainFiles) => {
      if (isEditing) dispatch(setAttachments(plainFiles));
      else dispatch(addEvidences(plainFiles, getEvidences, vulnId));
      clear();
    };

    if (!isEmpty(plainFiles)) onUpload(plainFiles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEvidences, plainFiles, dispatch, isEditing, clear]);

  if (errors.length) {
    dispatch(openModal(MODAL_EVIDENCE_WARNING_UPLOAD));
    clear();
  }

  return (
    <div style={ { width: '126px', height: '130px', marginBottom: '40px' } } onClick={ openFileSelector }>
      <ImageWrapper title="File size: 20MB max.">
        <EvidenceDefault />
      </ImageWrapper>
      <BlueText>Add Evidence</BlueText>
    </div>
  );
};

export default UploadEvidence;
