import styled from 'styled-components';
import AddButton from 'Common/Components/Contextualization/AddButton';

export const Wrapper = styled.div`
  text-align: right;
  margin: ${({ showGridView }) => showGridView ? '21px 8px 8px 29px' : '8px'};
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  height: 37px;
`;

export const Separator = styled.div`
  &::after {
    margin: auto 0px;
    width: 1px;
    content: ' ';
    background: transparent;
    border-right: 1px ${({ theme }) => theme.borders.tableHeaderDivider};
    border-right-style: dashed;
    height: 11px;
  }
`;
Separator.displayName = 'Separator';

export const Group = styled.div`
  display: flex;
  align-items: center;
`;
Group.displayName = 'Group';

export const LeftGroup = styled(Group)`
  & > *:not(:last-child) {
      margin-right: 15px;
  }
`;
LeftGroup.displayName = 'LeftGroup';

export const RightGroup = styled(LeftGroup)`
  margin-left: auto;
`;
RightGroup.displayName = 'RightGroup';

export const AddWsButton = styled(AddButton)`
  background-color: ${({ theme }) => theme.buttons.addButton.background};
  border: 1px solid ${({ theme }) => theme.buttons.addButton.border};
`;
AddWsButton.displayName = 'AddWsButton';
