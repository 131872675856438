import React, { useEffect } from 'react';
import 'react-table-6/react-table.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  getData, setOrderBy, updateReportsStatus
} from 'store/ExecutiveReport/actions';
import {
  selectReportList, selectIsFetching, selectRowsPerPage, selectReportsBeingProcessed,
  selectSelectedReports
} from 'store/ExecutiveReport/selectors';
import getColumns from './components/cells/Columns';
import { Wrapper, StyledTable } from './styled';
import EmptyReports from './components/EmptyReports';
import useTheme from 'Hooks/UseTheme';

const Table = () => {
  const dispatch = useDispatch();
  const data = useSelector(selectReportList);
  const isFetching = useSelector(selectIsFetching);
  const rowsPerPage = useSelector(selectRowsPerPage);
  const mockData = [...Array(rowsPerPage).keys()];
  const reportsBeingProcessed = useSelector(selectReportsBeingProcessed);
  const noReports = data.length === 0;
  const showTable = !(noReports && !isFetching);
  const reportsSelected = useSelector(selectSelectedReports);
  const { themeStyles } = useTheme();

  const getTrProps = (rowInfo) => {
    const selected = reportsSelected.find((report) => report.id === rowInfo.original.id);
    if (rowInfo && rowInfo.row) {
      return {
        style: {
          background: selected ? `${themeStyles?.backgrounds?.selectedTableItem}` : ''
        }
      };
    }
    return {};
  };

  useEffect(() => {
    dispatch(getData());
  }, [dispatch]);

  useEffect(() => {
    if (reportsBeingProcessed.length > 0) {
      setTimeout(async () => {
        await dispatch(updateReportsStatus());
      }, 6000);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, data]);

  const onFetchData = ({ sorted }) => {
    if (!isFetching) {
      dispatch(setOrderBy(sorted));
    }
  };

  return (
    <>
      <Wrapper>
        <EmptyReports show={ !showTable } />
        {showTable && (
          <StyledTable
            data={ isFetching ? mockData : data }
            columns={ getColumns(isFetching) }
            minRows={ 0 }
            defaultPageSize={ rowsPerPage }
            manual
            onFetchData={ onFetchData }
            showPagination={ false }
            getTrProps={ (_, rowInfo) => getTrProps(rowInfo) }
            showNewUI
          />
        )}
      </Wrapper>
    </>
  );
};

export default Table;
