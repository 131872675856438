import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Table from 'Screens/Contextualization/ExecutiveReport/components/Table';
import ErrorDialog from 'Common/Components/ErrorDialog';
import { WithPermission } from 'Common/Components/WithPermission/index';
import { FEATURE_REPORTING, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import ReduxModal from 'Common/Components/ReduxModal';
import { MODAL_UPGRADE_LICENSE } from 'store/modals/modals';
import UpgradeLicense from 'Common/Components/UpgradeLicense';
import { FormattedMessage } from 'react-intl';
import upgradeLicenseImage from 'Images/workspaces-license.png';
import { resetErrorValue, resetState } from 'store/ExecutiveReport/actions';
import { selectError, selectErrorMessage } from 'store/ExecutiveReport/selectors';
import { ACTIONS } from 'tracking/GA/constants';
import ActionBar from './components/ActionBar';
import { Wrapper, Content } from './styled';

const ExecutiveReportContext = () => {
  const dispatch = useDispatch();
  const error = useSelector(selectError);
  const errorMessage = useSelector(selectErrorMessage);

  useEffect(() => () => dispatch(resetState()), [dispatch]);

  return (
    <Wrapper>
      <Content>
        <ActionBar />
        <WithPermission permission={ FEATURE_REPORTING } type={ PERMISSION_TYPES.READ }>
          <Table />
        </WithPermission>
      </Content>
      { error ? <ErrorDialog message={ errorMessage } resetError={ () => dispatch(resetErrorValue()) } /> : null }
      <ReduxModal id={ MODAL_UPGRADE_LICENSE }>
        <UpgradeLicense id={ MODAL_UPGRADE_LICENSE } image={ upgradeLicenseImage } title={ <FormattedMessage id="upgradeLicense.sendToTool.title" /> } descriptionIntlId="upgradeLicense.workspace.description" trackingShow={ ACTIONS.showReport } trackingClick={ ACTIONS.clickReport } />
      </ReduxModal>
    </Wrapper>
  );
};

export default ExecutiveReportContext;
