import * as FileSaver from 'file-saver';
import api from 'services/api';
import get from 'lodash/get';
import { trackEvent } from 'tracking/GA';
import { ACTIONS, CATEGORIES } from 'tracking/GA/constants';
import { selectQueryParam, selectReportList, selectSelectedReports } from './selectors';
import { selectQueryParam as selectQueryParamFromFilters, selectAdvancedFilterQueryParam } from 'store/Filters/selectors';
import * as types from './types';
import fileFormatInformation from './contants';
import { setFilterError } from 'store/Filters/actions';
import { cloneDeep } from 'lodash';

export const resetState = () => async (dispatch) => dispatch({ type: types.RESET_STATE_REPORT });

const getDataStart = () => async (dispatch) => dispatch({ type: types.GET_DATA_REPORT_START });
const getDataSuccess = (reportList, reportsCount) => async (dispatch) => dispatch({ type: types.GET_DATA_REPORT_SUCCESS, reportList, reportsCount });

const cloneReportStart = () => async (dispatch) => dispatch({ type: types.CLONE_REPORT_START });

const updateReportsStart = () => async (dispatch) => dispatch({ type: types.UPDATE_REPORT_STATUS_START });
const updateReportsSuccess = (updatedReportList) => async (dispatch) => dispatch({ type: types.UPDATE_REPORT_STATUS_SUCCESS, updatedReportList });

const downloadReportStart = () => async (dispatch) => dispatch({ type: types.DOWNLOAD_REPORT_START });
const downloadReportSuccess = () => async (dispatch) => dispatch({ type: types.DOWNLOAD_REPORT_SUCCESS });

const getTemplatesStart = () => async (dispatch) => dispatch({ type: types.GET_REPORT_TEMPLATES_START });
const getTemplatesSuccess = (templates) => async (dispatch) => dispatch({ type: types.GET_REPORT_TEMPLATES_SUCCESS, templates });

const removeStart = () => async (dispatch) => dispatch({ type: types.REMOVE_REPORT_START });
const removeSuccess = (reportsToDelete) => async (dispatch) => dispatch({ type: types.REMOVE_REPORT_SUCCESS, reportsToDelete });

const error = (errorMessage) => async (dispatch) => dispatch({ type: types.EXECUTIVE_REPORT_FAIL, errorMessage });

export const resetErrorValue = () => async (dispatch) => dispatch({ type: types.RESET_ERROR_VALUE_REPORT });

export function getData () {
  return async (dispatch, getState) => {
    const state = getState();
    const queryParam = selectQueryParam(state);
    dispatch(getDataStart());
    try {
      const response = await api.report.getReportsList(queryParam);
      const reportList = response.rows.map((reportRow) => reportRow.value);
      const reportsCount = response.total_rows;
      dispatch(getDataSuccess(reportList, reportsCount));
    } catch (e) {
      dispatch(error('Failed to obtain reports.'));
    }
  };
}

export function removeReport (reportsToDelete) {
  return async (dispatch) => {
    dispatch(removeStart());
    try {
      await api.report.removeReport(reportsToDelete);
    } catch (e) {
      dispatch(error('There was a problem when removing report'));
    }
    dispatch(removeSuccess(reportsToDelete));
  };
}

export function updateReportsStatus () {
  return async (dispatch, getState) => {
    const state = getState();
    const reportList = selectReportList(state);
    dispatch(updateReportsStart());

    try {
      const updatedReportList = await Promise.all(reportList.map((r) => {
        if (r.status === 'processing') return api.report.getReportDetail(r.id);
        return r;
      }));
      dispatch(updateReportsSuccess(updatedReportList));
    } catch (e) {
      dispatch(error('Failed to update report status.'));
    }
  };
}

export function downloadReport (id, name, fileFormat) {
  return async (dispatch) => {
    const payload = get(fileFormatInformation, `${fileFormat}.apiPayload`, null);
    const extension = get(fileFormatInformation, `${fileFormat}.extension`, null);
    const fileType = get(fileFormatInformation, `${fileFormat}.fileType`, null);

    if (!payload || !extension || !fileType) return dispatch(error('Failed to download selected report.'));

    dispatch(downloadReportStart());

    try {
      const response = await api.report.downloadReport(payload, id);
      const data = new Blob([response], { type: fileType });
      FileSaver.saveAs(data, name + extension);
    } catch (e) {
      dispatch(error('Failed to download selected report.'));
    }
    return dispatch(downloadReportSuccess());
  };
}

export function downloadBulkReport (reportsToDownload) {
  return async (dispatch) => {
    const apiPayload = get(fileFormatInformation, `${'zip'}.apiPayload`, null);
    const extension = get(fileFormatInformation, `${'zip'}.extension`, null);
    const fileType = get(fileFormatInformation, `${'zip'}.fileType`, null);

    const payload = {
      ...apiPayload,
      data: reportsToDownload
    };

    if (!apiPayload || !extension || !fileType) return dispatch(error('Failed to download selected report.'));

    dispatch(downloadReportStart());

    try {
      const response = await api.report.downloadBulkReport(payload);
      const data = new Blob([response], { type: fileType });
      FileSaver.saveAs(data, 'Reports' + extension);
    } catch (e) {
      dispatch(error('Failed to download selected report.'));
    }
    return dispatch(downloadReportSuccess());
  };
}

export function setPage (page) {
  return async (dispatch) => {
    dispatch({ type: types.SET_EXECUTIVE_REPORT_PAGE, page });
    dispatch(getData());
  };
}

export function setOrderBy (sort) {
  return async (dispatch) => {
    const order_by = get(sort, '[0].id', '');
    const desc = get(sort, '[0].desc', false);
    const order_by_dir = desc ? 'desc' : 'asc';
    dispatch({ type: types.SET_EXECUTIVE_REPORT_ORDER_BY, order_by, order_by_dir });
    dispatch(getData());
  };
}

export function getTemplates () {
  return async (dispatch) => {
    dispatch(getTemplatesStart());
    try {
      const response = await api.report.fetchTemplates();
      return dispatch(getTemplatesSuccess(response.items));
    } catch (e) {
      return dispatch(error('There was an error, please try again.'));
    }
  };
}

const getTs = () => {
  const now = new Date();
  let month = now.getMonth() + 1;
  if (month < 10) month = `0${month}`;

  return `__${now.getFullYear()}${month}${now.getDate()}_${now.getHours()}${now.getMinutes()}${now.getSeconds()}`;
};

export function cloneReport (id, isRetry = false) {
  return async (dispatch) => {
    const ts = getTs();
    dispatch(cloneReportStart());
    try {
      const report = await api.report.getReportDetail(id);

      if (report.template_name === undefined) dispatch(error('This report was generated with a old version of executive report. Generate a new one and try again!'));
      else {
        const newReport = {
          ...report,
          name: isRetry ? report.name : report.name + ts
        };
        newReport.filter = report.filter || [];
        await api.report.clone(id);

        dispatch(getData());
      }
    } catch (e) {
      dispatch(error('Failed to regenerate selected report.'));
    }
    dispatch(trackEvent(CATEGORIES.report, ACTIONS.regenerateReport.name, ACTIONS.regenerateReport.label, id));
  };
}

export const setReport = (report) => async (dispatch, getState) => {
  const state = getState();
  const selectedReports = selectSelectedReports(state);
  const repeatedRegistration = selectedReports.find((x) => x.id === report.id);

  if (repeatedRegistration) {
    const copySelectedReports = cloneDeep(selectedReports);
    const updateReports = copySelectedReports.filter((e) => e.id !== report.id);
    dispatch({ type: types.SET_REPORTS, reports: updateReports });
    return;
  }
  dispatch({ type: types.SET_REPORT, report });
};

export const setAllReports = () => async (dispatch) => dispatch({ type: types.SET_ALL_REPORTS });

export const unselectAllReports = () => async (dispatch) => dispatch({ type: types.UNSELECT_ALL_REPORTS });

export function getReportsByFilters () {
  return async (dispatch, getState) => {
    dispatch(getDataStart());
    let advancedFilterQueryParam = [];

    try {
      advancedFilterQueryParam = selectAdvancedFilterQueryParam(getState(), 'reports');
    } catch (e) {
      dispatch(setFilterError('reports', 'Syntax error. Please try again. For further help check our documentation'));
    }

    const hasAdvancedFilter = advancedFilterQueryParam.filters.length > 0;
    const standardQueryParam = selectQueryParamFromFilters('reports', getState());
    const queryParam = hasAdvancedFilter ? advancedFilterQueryParam : standardQueryParam;

    try {
      const response = await api.report.getReportsByFilters(queryParam);
      const reportList = response.rows.map((reportRow) => reportRow.value);
      const reportsCount = response.total_rows;

      dispatch(getDataSuccess(reportList, reportsCount));
    } catch (error) {
      dispatch(error('Failed to obtain reports.'));
    }
  };
}
