import * as types from 'store/Manage/types';
import * as FileSaver from 'file-saver';
import api from 'services/api';
import { copy } from 'Common/Functions/Copy';
import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import set from 'lodash/set';
import find from 'lodash/find';
import { MOCK_VULN_ID } from 'Common/Components/Tour/helpers/mockVuln';
import { selectCurrentWorkspace, selectWorkspaceSelected } from 'store/Faraday/selectors';
import { setTourFinished, setNewTourFinished } from 'store/Preferences/Tour/actions';
import {
  selectVulnsList,
  selectVulnDetail,
  selectUsesMockVulns,
  selectVulnsCount,
  selectLastSelected,
  selectVulnsSelected,
  selectShowDetail,
  selectModalBulkUpdateField,
  selectModalBulkUpdateValue,
  selectContextMenuXPos,
  selectContextMenuYPos,
  selectSelectAll,
  selectBulkUpdateCustomAttribute,
  selectVulnDetailId
} from 'store/Manage/selectors';
import { getTags, getHostsTags } from 'Screens/Tags/actions/Actions';
import { trackEvent } from 'tracking/GA';
import { CATEGORIES, ACTIONS } from 'tracking/GA/constants';
import { getData } from 'Screens/Workspaces/actions/Actions';
import {
  selectGroupByField, selectIsGrouping, selectRowsPerPage,
  selectQueryParam, selectAdvancedFilterQueryParam
} from 'store/Filters/selectors';
import { redirect } from 'store/Router/actions';
import { selectCsrfToken } from 'store/Sesion/selectors';
import { MODAL_MANAGE_BULK_UPDATE, MODAL_MANAGE_BULK_UPDATE_CONFIRMATION } from 'store/modals/modals';
import { closeModal, openModal } from 'store/modals/actions';
import { setFilterError } from 'store/Filters/actions';
import { newGetVulns, setVulnsFilterError } from './filterActions';
import { getVulnsCountInWs } from 'Screens/Contextualization/Faraday/actions/Actions';
import { CLEAR_ERROR, VULN_ASSET_ERROR } from 'store/Contextualization/AssetDetail/types';

export function setImagePreview (imagePreview) {
  return (dispatch, getState) => {
    dispatch({
      type: types.SET_IMAGE_PREVIEW, imagePreview
    });
  };
}

export function setSelectedComment (selectedComment) {
  return (dispatch) => {
    dispatch({ type: types.SET_SELECTED_COMMENT, selectedComment });
  };
}

const addDeleteController = (vulns, vulnList) => { // if all vulns are in vulnList then unselect then if not
  const allVulnsAreSelected = vulns.every((testVuln) => vulnList.some((vuln) => vuln._id === testVuln._id));

  if (allVulnsAreSelected) return [types.VULN_UNSELECTED, vulns];
  return [types.VULN_SELECTED, vulns];
};

const selectCalculator = (e, vuln, vulnList, areVulnsSelected) => (dispatch, getState) => {
  const pivot = selectLastSelected(getState());
  const index = vulnList.findIndex((el) => el._id === vuln._id);
  const vulnsSelected = selectVulnsSelected(getState());

  dispatch({ type: types.NEW_PIVOT, payload: index });
  if (e.shiftKey && pivot !== -1 && areVulnsSelected) { // if already had vuln selected and shift key is pressed
    const start = Math.min(pivot, index);
    const end = Math.max(pivot, index) + 1;
    const vulns = vulnList.slice(start, end);
    const [type, payload] = addDeleteController(vulns, vulnsSelected);
    return dispatch({ type, payload });
  }
  const [type, payload] = addDeleteController([vuln], vulnsSelected);
  return dispatch({ type, payload });
};

export const selectRow = (e, vuln) => (dispatch, getState) => {
  const allVulns = selectVulnsList(getState());
  const isGroupingBy = selectIsGrouping('vulns', getState());
  const vulnsSelected = selectVulnsSelected(getState());

  if (isGroupingBy) {
    const group = allVulns.find((g) => g.groupData && g.groupData.find((el) => el._id === vuln._id));
    if (!group) return; // si no hay groupData no se puede hacer calculos con las vulns
    const vulnGroup = group.groupData;
    const areVulnsSelected = vulnsSelected.length > 0 && vulnGroup.some((el) => el.id === vuln.id);
    dispatch(selectCalculator(e, vuln, vulnGroup, areVulnsSelected, vulnsSelected));
  } else {
    dispatch(selectCalculator(e, vuln, allVulns, vulnsSelected.length > 0));
  }
};

export const unSelectAll = () => (dispatch) => dispatch({ type: types.UNSELECT_ALL_VULN });

export const selectAllVulns = () => (dispatch, getState) => {
  const vulnsList = selectVulnsList(getState());
  const vulnsSelected = selectVulnsSelected(getState());
  const vulnCount = selectVulnsCount(getState());
  const pageSize = selectRowsPerPage('vulns', getState());

  if (vulnsSelected.length === vulnCount || vulnsSelected.length >= pageSize) return dispatch(unSelectAll());

  return dispatch({ type: types.SELECT_ALL_VULN, vulnsList });
};

export const selectTotalVulns = () => (dispatch, getState) => { // includes server vulns
  return dispatch({ type: types.SELECT_TOTAL_VULNS });
};

export const unselectTotalVulns = () => (dispatch, getState) => { // includes server vulns
  return dispatch({ type: types.UNSELECT_TOTAL_VULNS });
};

export const selectConfirmedVulns = () => (dispatch, getState) => {
  const state = getState();
  const vulnsList = selectVulnsList(state).filter((vuln) => (vuln.confirmed));

  return dispatch({ type: types.SELECT_ALL_VULN, vulnsList });
};

export const selectNotConfirmedVulns = () => (dispatch, getState) => {
  const state = getState();
  const vulnsList = selectVulnsList(state).filter((vuln) => (!vuln.confirmed));
  return dispatch({ type: types.SELECT_ALL_VULN, vulnsList });
};

// Summary: Show confirmation modal when user delete vuln/s
export function showVulnModalDelete () {
  return (dispatch) => {
    dispatch({ type: types.SHOW_MODAL_DELETE_CONFIRMATION_VULNS });
  };
}

// Summary: Hide confirmation modal when user delete vuln/s
export function hideVulnModalDelete () {
  return (dispatch) => {
    dispatch({ type: types.HIDE_MODAL_DELETE_CONFIRMATION_VULNS });
  };
}

// Summary: reset state of manage screen
export function resetState () {
  return (dispatch) => {
    dispatch({ type: types.RESET_STATE_MANAGE });
  };
}

function exportCsv (result) {
  return async (_, getState) => {
    const workspaceSelected = selectCurrentWorkspace(getState());
    let title = '';

    if (workspaceSelected) title = `SR-${workspaceSelected}`;
    else title = 'Vulnerability Model CSV';

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.csv';

    const data = new Blob([result], { type: fileType });
    FileSaver.saveAs(data, title + fileExtension);
  };
}

export function resetErrorValue () {
  return (dispatch) => {
    dispatch({ type: types.RESET_ERROR_VALUE });
  };
}

export function showManageLeftFilters (visible, breakpoint) {
  return (dispatch) => {
    dispatch({ type: types.SHOW_MANAGE_LEFT_FILTERS, visible, breakpoint });
  };
}

export function redirectToVulnDetail (id) {
  return (dispatch, getState) => {
    const currentWorkspace = selectCurrentWorkspace(getState());
    dispatch(redirect(`/manage/${currentWorkspace}/${id}`));
  };
}

export function redirectToManage () {
  return (dispatch, getState) => {
    const currentWorkspace = selectCurrentWorkspace(getState());
    dispatch(redirect(`/manage/${currentWorkspace}`));
  };
}

export function setVulnerabilityDetailTab (vulnDetailSelectedTab) {
  return (dispatch) => {
    dispatch({ type: types.SET_VULNERABILITY_DETAIL_TAB, vulnDetailSelectedTab });
  };
}

export function showVulnerabilityDetail (id, vulnDetailSelectedTab) {
  return (dispatch, getState) => {
    const currentWorkspace = selectCurrentWorkspace(getState());
    if (id > 0) {
      dispatch(redirect(`/manage/${currentWorkspace}/${id}`));
      // Redirecting to manage/id calls loadVulnerabilityDetail, fetching the detail and showing it.
      dispatch(setVulnerabilityDetailTab(vulnDetailSelectedTab));
    }
  };
}

export function loadVulnerabilityDetail (id, isSelected) {
  return async (dispatch, getState) => {
    dispatch({ type: types.LOAD_VULNERABILITY_DETAIL_REQUEST });

    if (id > 0) {
      const currentWorkspace = selectCurrentWorkspace(getState());
      try {
        const vuln = await api.manage.fetchById(currentWorkspace, id);
        if (isSelected) dispatch({ type: types.VULN_SELECTED, payload: [vuln] });
        dispatch({ type: types.LOAD_VULNERABILITY_DETAIL_SUCCESS, vuln });
      } catch (e) {
        dispatch({ type: types.LOAD_VULNERABILITY_DETAIL_FAILURE, error: e });
        dispatch(redirectToManage());
      }
    } else {
      dispatch({ type: types.LOAD_VULNERABILITY_DETAIL_FAILURE, error: new Error('Invalid ID') });
    }
  };
}

export function hideVulnerabilityDetail () {
  return (dispatch, getState) => {
    const showVulnDetail = selectShowDetail(getState());
    if (showVulnDetail) dispatch({ type: types.HIDE_VULNERABILITY_DETAIL });
  };
}

export function resetVulnerabilityDetail () {
  return (dispatch, getState) => {
    const showVulnDetail = selectShowDetail(getState());
    if (showVulnDetail) dispatch({ type: types.RESET_VULN_DETAIL });
  };
}

export function addEvidences (evidences, getEvidences, vulnId) {
  return async (dispatch, getState) => {
    // const id = selectVulnDetailId(getState());
    const csrfToken = selectCsrfToken(getState());
    const currentWorkspace = selectCurrentWorkspace(getState());
    const promises = evidences.map((evidence) => {
      const newEvidenceName = evidence.name.replace(/\s+/g, '');
      const renameEvidence = new File([evidence], newEvidenceName);
      const fd = new FormData();
      fd.append('csrf_token', csrfToken);
      fd.append('file', renameEvidence);
      fd.append('description', '');
      return api.attachments.saveAttachments(currentWorkspace, vulnId, fd);
    });

    try {
      await Promise.all(promises);
    } catch (e) {
      const doc = e.message ? new DOMParser().parseFromString(e.message, 'text/html') : null;
      const message = e.message ? doc.documentElement.textContent : 'An error has occurred';
      dispatch({ type: types.UPDATE_VULN_MANAGE_PREVIEW_FAILURE, error: message });
    }
    getEvidences();
  };
}

export function addDescriptionInEvidence (vulnDetailId, description, attachmentName) {
  return async (dispatch, getState) => {
    const currentWorkspace = selectCurrentWorkspace(getState());
    try {
      await api.attachments.addDescriptionInEvidence(currentWorkspace, vulnDetailId, description, attachmentName);
    } catch (e) {
      const doc = e.message ? new DOMParser().parseFromString(e.message, 'text/html') : null;
      const message = e.message ? doc.documentElement.textContent : 'An error has occurred';
      dispatch({ type: types.UPDATE_VULN_MANAGE_PREVIEW_FAILURE, error: message });
    }
  };
}

function vulnPreviewEditSuccedCallback (vulnBefore, vulnAfter) {
  return (dispatch, getState) => {
    const groupBy = selectGroupByField('vulns', getState());
    const vulnsList = selectVulnsList(getState());

    if (groupBy) {
      // se actualiza la tabla agrupada
      const indexGroupDataAfter = vulnsList.findIndex((x) => x[groupBy] === vulnAfter[groupBy]);
      const groupDataAfter = vulnsList[indexGroupDataAfter].groupData || [];
      const indexVulnAfter = groupDataAfter.findIndex((x) => vulnAfter._id === x.id || vulnAfter._id === x._id);
      if (indexVulnAfter < 0) { // no lo encontro, lo borro
        const indexGroupDataBefore = vulnsList.findIndex((x) => x[groupBy] === vulnBefore[groupBy]);
        const groupDataBefore = vulnsList[indexGroupDataBefore].groupData || {};
        const indexVulnBefore = groupDataBefore.findIndex((x) => vulnAfter._id === x.id || vulnAfter._id === x._id);
        // lo quito del grupo.
        vulnsList[indexGroupDataBefore].groupData.splice(indexVulnBefore, 1);
        // eslint-disable-next-line no-negated-condition
        if (!vulnsList[indexGroupDataAfter].groupData) vulnsList[indexGroupDataAfter].count += 1;
        else vulnsList[indexGroupDataAfter].groupData.push(vulnAfter);
      } else {
        vulnsList[indexGroupDataAfter].groupData[indexVulnAfter] = vulnAfter;
      }
    } else {
      // se actualiza la tabla
      const index = vulnsList.findIndex((x) => vulnAfter._id === x.id || vulnAfter._id === x._id);
      vulnsList[index] = vulnAfter;
    }

    dispatch({ type: types.UPDATE_VULN_MANAGE_PREVIEW_SUCCESS, vuln: vulnAfter, vulnsList });
  };
}

function vulnPreviewEditErrorCallback (error) {
  return (dispatch) => {
    dispatch({ type: types.UPDATE_VULN_MANAGE_PREVIEW_FAILURE, error: error.message || 'An error has occurred' });
  };
}

export function updateVuln (vulnBefore, field, value, showNewUI) {
  return async (dispatch, getState) => {
    const workspaceSelected = selectCurrentWorkspace(getState());
    let vulnAfter = { ...vulnBefore };
    set(vulnAfter, field, value);

    try {
      let newRefs = [];
      if (field === 'refs') {
        newRefs = value.map((ref) => ({ name: ref, type: 'other' }));
        vulnAfter = await api.manage.updateVuln(workspaceSelected, { _id: vulnBefore._id, refs: newRefs });
      } else {
        vulnAfter = await api.manage.updateVuln(workspaceSelected, { _id: vulnBefore._id, [field]: value });
      }

      dispatch(vulnPreviewEditSuccedCallback(vulnBefore, vulnAfter));
      if (showNewUI && field === 'confirmed') dispatch(getVulnsCountInWs());
    } catch (error) {
      dispatch(vulnPreviewEditErrorCallback(error));
    }
  };
}

export const updateHostParent = (id, newParent, newParentType, ws = null) => async (dispatch, getState) => {
  if (!ws) ws = selectCurrentWorkspace(getState());
  const vulnsList = selectVulnsList(getState());

  const data = {
    parent: newParent,
    parent_type: newParentType || 'Host'
  };

  try {
    const vulnRes = await api.manage.updateVuln(ws, { _id: id, ...data });
    const index = vulnsList.findIndex((x) => vulnRes._id === x.id || vulnRes._id === x._id);
    vulnsList[index] = vulnRes;
    dispatch({ type: types.UPDATE_VULN_MANAGE_PREVIEW_SUCCESS, vuln: vulnRes, vulnsList });
  } catch (error) {
  }
};

export function selectGroup (index, selected) {
  return (dispatch) => {
    dispatch({ type: types.SELECT_GROUP_MANAGE, index, selected });
  };
}

export function selectVulnGroup (vuln, selected) {
  return (dispatch) => {
    dispatch({ type: types.SELECT_VULN_GROUP_MANAGE, vuln, selected });
  };
}

function getCommentsSucceedCallback (data) {
  return (dispatch) => {
    dispatch({
      type: types.GET_COMMENTS_SUCCESS,
      data
    });
  };
}

export function getComments (id) {
  return async (dispatch, getState) => {
    const workspaceSelected = selectCurrentWorkspace(getState());
    try {
      dispatch({ type: types.GET_COMMENTS_REQUEST });
      const data = await api.manage.getComments(workspaceSelected, id);
      dispatch(getCommentsSucceedCallback(data));
    } catch (e) {
      dispatch({ type: types.GET_COMMENTS_FAIL, data: e.message });
    }
  };
}

function addCommentSucceedCallback (data) {
  return (dispatch) => {
    dispatch({
      type: types.ADD_COMMENT_SUCCESS,
      data
    });
  };
}

export function addComment (data, updateFromAssets = false) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: types.ADD_COMMENT_REQUEST });
      const workspace = selectCurrentWorkspace(getState());
      const response = await api.manage.newComment(workspace, data);
      dispatch(addCommentSucceedCallback(response));
      if (updateFromAssets) dispatch({ type: CLEAR_ERROR, entity: 'vulns' });
    } catch (e) {
      dispatch({ type: types.ADD_COMMENT_FAIL, data: e.message });
      if (updateFromAssets) dispatch({ type: VULN_ASSET_ERROR });
    }
  };
}

function removeCommentSucceedCallback (commentId) {
  return (dispatch) => {
    dispatch({
      type: types.REMOVE_COMMENT_SUCCESS,
      commentId
    });
  };
}

export function removeComment (commentId) {
  return async (dispatch, getState) => {
    const workspace = selectCurrentWorkspace(getState());
    try {
      dispatch({ type: types.REMOVE_COMMENT_REQUEST });
      await api.manage.removeComment(workspace, commentId);
      dispatch(removeCommentSucceedCallback(commentId));
    } catch (e) {
      dispatch({ type: types.REMOVE_COMMENT_FAIL, data: e.message });
    }
  };
}

function updateCommentSucceedCallback (data) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_COMMENT_SUCCESS,
      data
    });
  };
}

export function updateComment (data) {
  return async (dispatch, getState) => {
    const workspace = selectCurrentWorkspace(getState());
    try {
      dispatch({ type: types.UPDATE_COMMENT_REQUEST });
      const response = await api.manage.saveComment(workspace, data.id, data);
      dispatch(updateCommentSucceedCallback(response));
    } catch (e) {
      dispatch({ type: types.UPDATE_COMMENT_FAIL, data: e.message });
    }
  };
}

export function bulkAddComment () {
  return (dispatch, getState) => {
    const state = getState();
    const vulnsSelected = selectVulnsSelected(state);
    const text = selectModalBulkUpdateValue(state);
    const vulnsIds = vulnsSelected?.map((vuln) => vuln._id);

    vulnsIds.forEach((id) => {
      const data = {
        text,
        object_type: 'vulnerability',
        object_id: id,
        create_date: new Date()
      };
      dispatch(addComment(data));
    });
  };
}

export function setBulkUpdateField (field) {
  return (dispatch) => {
    dispatch({ type: types.MANAGE_SET_BULK_UPDATE_FIELD, field });
  };
}

export function setBulkUpdateValue (value) {
  return (dispatch) => {
    dispatch({ type: types.MANAGE_SET_BULK_UPDATE_VALUE, value });
  };
}

export function addBulkUpdateValue (value) {
  return (dispatch) => {
    dispatch({ type: types.MANAGE_ADD_BULK_UPDATE_VALUE, value });
  };
}

export function removeBulkUpdateValue (value) {
  return (dispatch) => {
    dispatch({ type: types.MANAGE_REMOVE_BULK_UPDATE_VALUE, value });
  };
}

export function setCustomAttributeField (customAttribute) {
  return (dispatch) => {
    dispatch({ type: types.MANAGE_SET_BULK_CUSTOM_ATTRIBUTE, customAttribute });
  };
}

export function refreshVulnsList (vulnsList, vulnsSelected, vulnDetail) {
  return (dispatch, getState) => {
    dispatch({
      type: types.REFRESH_VULNS, vulnsList, vulnsSelected, vulnDetail, vulnsCount: selectVulnsCount(getState())
    });
  };
}

export const getImpactData = (value, selectedVulns) => {
  const keyNames = Object.keys(selectedVulns[0].impact);
  let impact = {};
  keyNames.forEach((imp) => {
    impact = {
      ...impact,
      [imp]: value.some((x) => x === imp)
    };
  });
  return { impact };
};

export function bulkUpdateVulns () {
  return async (dispatch, getState) => {
    const state = getState();
    const field = selectModalBulkUpdateField(state);
    dispatch({ type: types.CONFIRMATION_CHANGE_START });

    try {
      const vulnsList = selectVulnsList(state);
      const vulnsSelected = selectVulnsSelected(state);
      const vulnDetail = selectVulnDetail(state);
      const value = selectModalBulkUpdateValue(state);
      const workspaceSelected = selectCurrentWorkspace(state);
      const selectAll = selectSelectAll(state);
      const vulnIDs = vulnsSelected.map((v) => v._id);
      const customAttribute = selectBulkUpdateCustomAttribute(state);
      const customAttributeType = get(customAttribute, 'field_type', '');
      const customAttributeFieldName = get(customAttribute, 'field_name', '');
      const customAttributeValue = ((customAttributeType === 'choice') && (value === '(Empty)')) ? '' : value;

      const advancedFilterQueryParam = selectAdvancedFilterQueryParam(state, 'vulns');
      const hasAdvancedFilter = advancedFilterQueryParam.filters.length > 0;
      const standardQueryParam = selectQueryParam('vulns', state);
      const queryParam = hasAdvancedFilter ? advancedFilterQueryParam : standardQueryParam;

      let data = {};

      if (field === 'references') data = { refs: value.map((v) => ({ name: v, type: 'other' })) };
      else if (field === 'policy violations') data = { policyviolations: value };
      else if (field === 'description') data = { description: value, desc: value };
      else if (field === 'impact') data = getImpactData(value, vulnsSelected);
      else if (field === 'custom_fields' && (vulnsSelected?.length > 1)) data = { custom_fields: { [customAttributeFieldName]: customAttributeValue } };
      else if (field === 'custom_fields' && (vulnsSelected?.length === 1)) data = { custom_fields: { ...vulnsSelected[0].custom_fields, [customAttributeFieldName]: customAttributeValue } };
      else data = { [field]: value };

      const isDetailVulnSelected = vulnsSelected.some((selectedVuln) => !isEmpty(vulnDetail) && selectedVuln._id === vulnDetail._id);

      const updatedVulnDetail = isDetailVulnSelected ? { ...vulnDetail, ...data } : { ...vulnDetail };

      if (selectAll) {
        await api.manage.updateAllVulns(workspaceSelected, { filters: queryParam.filters }, data);

        const updatedVulnsList = vulnsList.map((vuln) => ({ ...vuln, ...data }));
        const updatedSelectedVulns = vulnsSelected.map((vuln) => ({ ...vuln, ...data }));

        dispatch(refreshVulnsList(updatedVulnsList, updatedSelectedVulns, updatedVulnDetail));
      } else {
        const payload = { ids: vulnIDs, ...data };

        await api.manage.updateVulns(workspaceSelected, payload);

        const updatedVulnsList = vulnsList.map((vuln) => {
          const isSelected = vulnsSelected.some((selectedVuln) => selectedVuln._id === vuln._id);
          if (isSelected) return { ...vuln, ...data };
          return vuln;
        });

        const updatedSelectedVulns = vulnsSelected.map((vuln) => ({ ...vuln, ...data }));

        dispatch({ type: types.MANAGE_SET_BULK_UPDATE_SUCCESS });
        dispatch(refreshVulnsList(updatedVulnsList, updatedSelectedVulns, updatedVulnDetail));
      }

      dispatch(closeModal(MODAL_MANAGE_BULK_UPDATE));
      dispatch(closeModal(MODAL_MANAGE_BULK_UPDATE_CONFIRMATION));
      dispatch({ type: types.MANAGE_BULK_UPDATE_FINISHED });
    } catch (e) {
      dispatch(closeModal(MODAL_MANAGE_BULK_UPDATE_CONFIRMATION));
      if (field === 'name') dispatch({ type: types.SET_ERROR_MESSAGE, errorMessage: e.message });
      else {
        dispatch({ type: types.MANAGE_VULN_ERROR, errorMessage: e.message || 'An error occured while updating vulns' });
        dispatch(closeModal(MODAL_MANAGE_BULK_UPDATE));
      }
    }
  };
}

export function saveTemplateFromVuln () {
  return async (dispatch, getState) => {
    const vulnsSelected = selectVulnsSelected(getState());
    const workspaceSelected = selectCurrentWorkspace(getState());

    const getVulnsData = vulnsSelected.map((vuln) => api.manage.fetchById(workspaceSelected, vuln._id));

    const vulnsData = await Promise.all(getVulnsData);

    const save = async (template) => {
      const response = await api.knowledgeBase.createTemplate(template);
      return response;
    };

    const data = vulnsData.map((vuln) => {
      const filteredReferences = vuln.refs.filter(ref => ref.name).map(filteredRef => filteredRef.name);
      const template = {
        cwe: '',
        cve: vuln.cve,
        // eslint-disable-next-line no-nested-ternary
        description: vuln.desc,
        desc: vuln.desc,
        data: vuln.data,
        exploitation: vuln.severity,
        name: vuln.name,
        references: filteredReferences,
        refs: filteredReferences,
        resolution: vuln.resolution,
        impact: vuln.impact,
        policyviolations: vuln.policyviolations,
        customfields: vuln.custom_fields,
        easeofresolution: vuln.easeofresolution,
        external_id: vuln.external_id,
        type: 'vulnerability_template'
      };
      if (vuln.cvss3.vector_string !== null) template.cvss3 = vuln.cvss3;
      if (vuln.cvss2.vector_string !== null) template.cvss2 = vuln.cvss2;

      return save(template);
    });

    Promise.allSettled(data).then((results) => {
      const templatesCreated = results ? results.filter((template) => template.status === 'fulfilled').map((t) => t.value) : [];
      const errors = results ? results.filter((template) => template.status === 'rejected').map((t) => t.reason.object) : [];
      dispatch({ type: types.SAVE_TEMPLATE_MANAGE, templatesCreated, errors });
    });
  };
}

export function getTemplatesMassiveUpdate () {
  return async (dispatch) => {
    dispatch({ type: types.GET_TEMPLATES_BULK_START });

    try {
      const data = await api.manage.getTemplates();
      dispatch({ type: types.GET_TEMPLATES_BULK_SUCCESS, data });
    } catch (e) {
      dispatch({ type: types.GET_TEMPLATES_BULK_FAIL });
    }
  };
}

export function setVulnsTags (tagsToAdd = [], tagsToRemove = []) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const vulnsSelected = selectVulnsSelected(state);
      const vulnsList = selectVulnsList(state);
      const selectedVulnsIds = vulnsSelected.map((v) => (v._id));
      const workspace = state.faraday.workspaceSelected;
      const selectAll = selectSelectAll(state);

      const advancedFilterQueryParam = selectAdvancedFilterQueryParam(state, 'vulns');
      const hasAdvancedFilter = advancedFilterQueryParam.filters.length > 0;
      const standardQueryParam = selectQueryParam('vulns', state);
      const queryParam = hasAdvancedFilter ? advancedFilterQueryParam : standardQueryParam;

      if (selectAll) {
        const data = {
          tags_to_add: tagsToAdd,
          tags_to_remove: tagsToRemove
        };

        await api.manage.setAllVulnsTags(workspace, { filters: queryParam.filters }, data);
      } else {
        const data = {
          vulnerability_ids: selectedVulnsIds,
          tags_to_add: tagsToAdd,
          tags_to_remove: tagsToRemove
        };

        await api.manage.setVulnsTags(workspace, data);
      }

      const newTags = tagsToAdd.filter((tag) => !tagsToRemove.includes(tag));

      selectedVulnsIds.forEach((id) => {
        const vuln = find(vulnsSelected, { _id: id });
        const newTagsList = newTags.filter((tag) => !vuln.tags.includes(tag));
        vuln.tags = [
          ...vuln.tags.filter((tag) => !tagsToRemove.includes(tag)),
          ...newTagsList
        ];

        const indexVulnsList = findIndex(vulnsList, { _id: vuln._id });
        if (indexVulnsList > -1 && !isEmpty(vulnsList)) vulnsList.splice(indexVulnsList, 1, vuln);

        const indexVulnsSelected = findIndex(vulnsSelected, { _id: id });
        if (indexVulnsList > -1 && !isEmpty(vulnsSelected)) vulnsSelected.splice(indexVulnsSelected, 1, vuln);
      });

      const vulnDetail = selectVulnDetail(state);
      if (!!vulnDetail && selectedVulnsIds.includes(vulnDetail.id)) {
        vulnDetail.tags = [
          ...vulnDetail.tags.filter((tag) => !tagsToRemove.includes(tag)),
          ...newTags
        ];
      }

      dispatch({ type: types.SET_TAGS_SUCCESS });

      if (vulnsSelected.length === 1) {
        dispatch({ type: types.TAGS_UPDATE_VULN_DETAIL, vuln: vulnDetail });
      }
      dispatch(getTags());
      dispatch(getHostsTags());
    } catch (e) {
      dispatch({ type: types.SET_TAGS_FAIL, error: e.message });
    }
  };
}

export function addTag (tag) {
  return (dispatch) => {
    dispatch(setVulnsTags([tag], []));
  };
}

export function removeTag (tag) {
  return (dispatch) => {
    dispatch(setVulnsTags([], [tag]));
  };
}

export function showBulkUpdateModal (bulkUpdateField, bulkUpdateValue) {
  return (dispatch) => {
    dispatch(setBulkUpdateField(bulkUpdateField));
    dispatch(setBulkUpdateValue(bulkUpdateValue));
    dispatch(openModal(MODAL_MANAGE_BULK_UPDATE));
  };
}

export function onGetVulnsFail (errorMessage) {
  return (dispatch) => {
    dispatch({ type: types.SEARCH_VULNS_FAIL, data: errorMessage });
  };
}

export function removeVulnsTags (currentVuln, tagToRemove) {
  return async (dispatch, getState) => {
    dispatch({ type: types.SET_TAGS_REQUEST });
    try {
      if (currentVuln.tags.includes(tagToRemove)) {
        const state = getState();
        const vulnId = get(currentVuln, '_id', -1);
        const workspace = selectWorkspaceSelected(state);
        const data = {
          vulnerability_ids: [vulnId],
          tags_to_add: [],
          tags_to_remove: [tagToRemove]
        };
        await api.manage.setVulnsTags(workspace, data);

        const newTags = currentVuln.tags.filter((tag) => !tagToRemove.includes(tag));
        const newVuln = copy(currentVuln);
        newVuln.tags = newTags;

        const vulnsList = selectVulnsList(state);
        const newVulnsList = vulnsList.map((vuln) => {
          if (vuln._id === newVuln._id) return newVuln;
          return vuln;
        });

        dispatch({ type: types.UPDATE_VULN_MANAGE_PREVIEW_SUCCESS, vulnsList: newVulnsList, vuln: newVuln });
      }
    } catch (e) {
      dispatch({ type: types.SET_TAGS_FAIL, error: e });
    }
  };
}

export function getUsersList () {
  return async (dispatch) => {
    try {
      const response = await api.users.getUsersList();
      const mappedUsers = response.map((u) => ({ name: u.username }));
      return dispatch({ type: types.GET_USERS_SUCCESS, users: mappedUsers });
    } catch (e) {
      return dispatch({ type: '' });
    }
  };
}

// Summary: Delete vuln/s database
export function deleteSelectedVulns () {
  return async (dispatch, getState) => {
    const workspaceSelected = selectCurrentWorkspace(getState());
    const vulnsSelected = selectVulnsSelected(getState());
    const vulnIDs = vulnsSelected.map((vuln) => vuln._id);
    const groupByField = selectGroupByField('vulns', getState());
    const vulnsList = selectVulnsList(getState());
    const selectAll = selectSelectAll(getState());

    const advancedFilterQueryParam = selectAdvancedFilterQueryParam(getState(), 'vulns');
    const hasAdvancedFilter = advancedFilterQueryParam.filters.length > 0;
    const standardQueryParam = selectQueryParam('vulns', getState());
    const queryParam = hasAdvancedFilter ? advancedFilterQueryParam : standardQueryParam;

    dispatch({ type: types.HIDE_MODAL_DELETE_CONFIRMATION_VULNS_SUCCEED });
    dispatch({ type: types.DELETE_VULNS });

    try {
      if (selectAll) {
        await api.manage.deleteAllVulns(workspaceSelected, { filters: queryParam.filters });
      } else {
        if (vulnIDs.length === 1) await api.manage.deleteVuln(workspaceSelected, vulnIDs[0]);
        else {
          await api.manage.deleteVulns(workspaceSelected, vulnIDs);
          vulnIDs.forEach((id) => dispatch(trackEvent(CATEGORIES.vuln, ACTIONS.deleteVuln.name, ACTIONS.deleteVuln.label, id)));
        }
      }
      dispatch(getData());
      if (groupByField) {
        const newVulnsList = vulnsList.map((group) => {
          const groupedData = get(group, 'groupData', null);
          if (groupedData) {
            const newGroupedData = groupedData.filter((vuln) => !vulnIDs.includes(vuln._id));
            return {
              ...group,
              count: newGroupedData.length,
              groupData: newGroupedData
            };
          }
          return group;
        });
        dispatch({ type: types.UPDATE_VULNS_LIST, vulnsList: newVulnsList });
      } else dispatch(newGetVulns());
      dispatch(getTags());
      dispatch(getVulnsCountInWs());
    } catch (e) {
      dispatch({ type: types.MANAGE_VULN_ERROR, errorMessage: e.message || 'An error occured while deleting vulns' });
    }
  };
}

export function removeMockVuln () {
  return (dispatch, getState) => {
    const vulnList = selectVulnsList(getState());
    const newVulnsList = vulnList.filter((vuln) => vuln._id !== MOCK_VULN_ID);
    const vulnsCount = newVulnsList.length > 0 ? newVulnsList.length - 1 : 0;
    dispatch({ type: types.REMOVE_MOCK_VULN, vulnsList: newVulnsList, vulnsCount });
  };
}

export function endTour () {
  return async (dispatch, getState) => {
    const usesMockVulns = selectUsesMockVulns(getState());
    if (usesMockVulns) dispatch(removeMockVuln());
    dispatch(setTourFinished(true));
  };
}

export function downloadVulns () {
  return async (dispatch, getState) => {
    const workspace = selectCurrentWorkspace(getState());

    const state = getState();
    let advancedFilterQueryParam = [];
    try {
      advancedFilterQueryParam = selectAdvancedFilterQueryParam(state, 'vulns', true);
    } catch (e) {
      dispatch(setFilterError('vulns', 'Syntax error. Please try again. For further help check our documentation'));
      return dispatch(setVulnsFilterError());
    }

    const hasAdvancedFilter = advancedFilterQueryParam.filters.length > 0;
    const standardQueryParam = selectQueryParam('vulns', state, true);
    const queryParam = hasAdvancedFilter ? advancedFilterQueryParam : standardQueryParam;

    try {
      dispatch({ type: types.EXPORT_CSV_MANAGE_REQUEST });
      const response = await api.manage.exportCSV(workspace, queryParam);
      dispatch(exportCsv(response));
    } catch (err) {
      dispatch({ type: types.EXPORT_CSV_MANAGE_FAILURE });
    }

    return dispatch({ type: types.EXPORT_CSV_MANAGE_SUCCESS });
  };
}

export function showContextMenu (show, XPos, YPos) {
  return (dispatch, getState) => {
    const state = getState();
    const currentXPos = selectContextMenuXPos(state);
    const currentYPos = selectContextMenuYPos(state);
    const newXPos = XPos ? (XPos + 1) : currentXPos;
    const newYPos = YPos ? (YPos + 1) : currentYPos;

    dispatch({
      type: types.SHOW_CONTEXT_MENU, show, contextMenuXPos: newXPos, contextMenuYPos: newYPos
    });
  };
}

export function showQuickPlanner (show) {
  return (dispatch) => {
    dispatch({ type: types.SHOW_QUICK_PLANNER, show });
  };
}

export function closeTemplateModalMessage () {
  return (dispatch) => {
    dispatch({ type: types.CLOSE_TEMPLATE_MODAL_MESSAGE });
  };
}

export function refreshVulnEnrichment (vulnBefore) {
  return async (dispatch, getState) => {
    dispatch({ type: types.REFRESH_VULN_ENRICHMENT_REQUEST });

    const workspace = selectCurrentWorkspace(getState());
    const id = selectVulnDetailId(getState());

    try {
      const vulnAfter = await api.manage.refreshEnrichment(workspace, id);
      dispatch(vulnPreviewEditSuccedCallback(vulnBefore, vulnAfter));
      dispatch({ type: types.REFRESH_VULN_ENRICHMENT_SUCCESS });
    } catch (e) {
      dispatch({
        type: types.REFRESH_VULN_ENRICHMENT_FAILURE,
        errorMessage: 'Faraday was not able to determine the requested Risk Information. Please try again later.'
      });
    }
  };
}

export function setError (errorMessage) {
  return (dispatch) => {
    dispatch({ type: types.SET_ERROR, data: errorMessage });
  };
}

export function updateCvss (tempCVSS) {
  return (dispatch, getState) => {
    const currentVuln = selectVulnDetail(getState());
    const currentCVSSValue = currentVuln.cvss3.vector_string;

    const parsedTempCVSS = {
      base_score: Number(tempCVSS.score),
      base_severity: tempCVSS.severity.toLowerCase(),
      vector_string: tempCVSS.vector
    };
    dispatch({ type: types.MANAGE_SET_TEMP_CVSS, tempCVSS: parsedTempCVSS });

    const sameVector = currentCVSSValue === tempCVSS.vector;
    if ((!parsedTempCVSS.vector_string.includes('_')) && (!sameVector)) {
      dispatch(updateVuln(currentVuln, 'cvss3', { vector_string: tempCVSS.vector }));
    }
  };
}

export function resetCVSS () {
  return (dispatch) => {
    dispatch({ type: types.MANAGE_SET_TEMP_CVSS, tempCVSS: null });
  };
}

export const getVulnRefs = (id) => async (dispatch, getState) => {
  const workspaceSelected = selectCurrentWorkspace(getState());
  try {
    const response = await api.manage.fetchById(workspaceSelected, id);
    const refs = get(response, 'refs', []);
    dispatch(showBulkUpdateModal('references', refs.map((r) => r.name)));
  } catch (e) {
    dispatch({ type: types.MANAGE_VULN_ERROR, errorMessage: e.message || 'An error has occurred' });
  }
};

export function endNewTour (endFullTour, flag) {
  return async (dispatch) => {
    if (endFullTour) dispatch(setNewTourFinished({ sidebarTourFinished: true, dashboardTourFinished: true, assetVulnsTourFinished: true, vulnsTourFinished: true }));
    else dispatch(setNewTourFinished({ [flag]: true }));
  };
}

export function getVulnsTotalCount () {
  return async (dispatch, getState) => {
    const selectedWs = selectCurrentWorkspace(getState());
    const response = await api.workspace.getWsStats(selectedWs);
    const count = get(response, 'stats.total_vulns', 0);
    dispatch({ type: types.GET_VULNS_TOTAL_COUNT, count });
  };
}

export function saveTemplateFromVulnContext (vulnsSelected, entity) {
  return async (dispatch, getState) => {
    const isOutsideWorkspace = entity === 'vulnsGeneral';
    const workspaceSelected = selectCurrentWorkspace(getState());

    const getVulnsData = vulnsSelected.map((vuln) => {
      if (isOutsideWorkspace) return api.vulnsGeneral.fetchVulnById(vuln._id);
      else return api.manage.fetchById(workspaceSelected, vuln._id);
    });

    const vulnsData = await Promise.all(getVulnsData);

    const save = async (template) => {
      const response = await api.knowledgeBase.createTemplate(template);
      return response;
    };

    const data = vulnsData.map((vuln) => {
      const filteredReferences = vuln.refs.filter(ref => ref.name).map(filteredRef => filteredRef.name);
      const template = {
        cwe: '',
        cve: vuln.cve,
        description: vuln.desc,
        desc: vuln.desc,
        data: vuln.data,
        exploitation: vuln.severity,
        name: vuln.name,
        references: filteredReferences,
        refs: filteredReferences,
        resolution: vuln.resolution,
        impact: vuln.impact,
        policyviolations: vuln.policyviolations,
        customfields: vuln.custom_fields,
        easeofresolution: vuln.easeofresolution,
        external_id: vuln.external_id,
        type: 'vulnerability_template'
      };
      if (vuln.cvss3.vector_string !== null) template.cvss3 = vuln.cvss3;
      if (vuln.cvss2.vector_string !== null) template.cvss2 = vuln.cvss2;
      return save(template);
    });

    Promise.allSettled(data).then((results) => {
      const templatesCreated = results ? results.filter((template) => template.status === 'fulfilled').map((t) => t.value) : [];
      const errors = results ? results.filter((template) => template.status === 'rejected').map((t) => t.reason.object) : [];
      dispatch({ type: types.SAVE_TEMPLATE_MANAGE, templatesCreated, errors });
    });
  };
}

export function downloadVulnsContext (entity, workspace) {
  return async (dispatch, getState) => {
    const state = getState();
    let advancedFilterQueryParam = [];
    try {
      advancedFilterQueryParam = selectAdvancedFilterQueryParam(state, entity, true);
    } catch (e) {
      dispatch(setFilterError(entity, 'Syntax error. Please try again. For further help check our documentation'));
      return dispatch(setVulnsFilterError());
    }

    const hasAdvancedFilter = advancedFilterQueryParam.filters.length > 0;
    const standardQueryParam = selectQueryParam(entity, state, true);
    const queryParam = hasAdvancedFilter ? advancedFilterQueryParam : standardQueryParam;

    try {
      dispatch({ type: types.EXPORT_CSV_MANAGE_REQUEST });
      const response = await api.manage.exportCSV(workspace, queryParam);
      dispatch(exportCsv(response));
    } catch (err) {
      dispatch({ type: types.EXPORT_CSV_MANAGE_FAILURE });
      if (entity === 'vulnsAssets') dispatch({ type: VULN_ASSET_ERROR, errorMessage: 'There was an error, please try again.' });
    }

    return dispatch({ type: types.EXPORT_CSV_MANAGE_SUCCESS });
  };
}

export const updateCvssV4 = (vector_string) => (dispatch, getState) => {
  const currentVuln = selectVulnDetail(getState());
  dispatch(updateVuln(currentVuln, 'cvss4', { vector_string }));
};
