import styled from 'styled-components';
import { ReactComponent as HomeIcon_ } from 'Images/home.svg';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  border: solid 1px ${({ theme }) => theme.borders.vulnsCounter};
  border-radius: 12px;
  background-color: ${({ theme }) => theme.backgrounds.vulnsCounter};
  height: 31px;
  padding: 6px 11px;
`;
Wrapper.displayName = 'Wrapper';

export const Count = styled.div`
  font-size: 11.5px;
  font-weight: 500;
  text-align: center;
  color: ${({ theme }) => theme.text.vulnsCounter};
  max-width: 142px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 8px;
  height: 16px;
  user-select: none;
`;
Count.displayName = 'Count';

export const HomeIcon = styled(HomeIcon_)`
  cursor: default;
  & path {
    fill: ${({ theme }) => theme.text.vulnsCounter};
  }
`;
HomeIcon.displayName = 'HomeIcon';
