import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { selectHostsSelected } from 'store/HostsGeneral/selectors';
import {
  Wrapper, Icon, Title
} from './styled';

const BulkTitle = () => {
  const hostsSelected = useSelector(selectHostsSelected);
  const selectedHostsCount = hostsSelected.length;

  return (
    <Wrapper>
      <Icon />
      <Title>
        { selectedHostsCount > 1 ? <FormattedMessage id="vulns.edit.bulkTitle" /> : <FormattedMessage id="vulns.edit.title" /> }
      </Title>
    </Wrapper>
  );
};

export default BulkTitle;
