/* eslint-disable no-negated-condition */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Dropdown, DateTimePicker, CreatableInput } from 'Common/Components/Inputs';
import { CustomInput } from '../styled';
import './styled.css';
import { CustomInputStyle, CustomSelectStyle } from './AlternativeFields/styled';
import { dropdownStyle } from 'Common/Components/Inputs/styled';
import { ThemeContext } from 'Context';
import Date from './Date';

// Type A
const ArrayIssueField = ({
  options, setValue, title, value
}) => {
  const onChange = useCallback((e) => {
    setValue(e && e.map((option) => option.value));
  }, [setValue]);

  const onChangeWithOutSpace = useCallback((e) => {
    setValue(e && e.map((option) => option.value.replaceAll('-', '')));
  }, [setValue]);

  const { themeStyles } = useContext(ThemeContext);

  if (options) {
    const parsedOptions = options.map((option) => ({
      label: option,
      value: option
    }));

    return (
        <Dropdown
          className='select'
          styles={ CustomSelectStyle(themeStyles) }
          isMulti
          options={ parsedOptions }
          onChange={ onChange }
          title={ title }
          defaultValue={ value && value.map((option) => ({
            label: option,
            value: option
          })) }
        />
    );
  }
  return (
    <>
      <CreatableInput
        className='select'
        styles={ CustomInputStyle((themeStyles)) }
        title={ title }
        placeholder={ `Type ${title} and press enter...` }
        onChange={ onChangeWithOutSpace }
        defaultValue={ value && value.map((option) => ({
          label: option,
          value: option
        })) }
      />
    </>
  );
};

// Type B
const UserIssueField = ({
  options, title, setValue, value, required, users
}) => {
  const parsedUsers = users.map((e) => e.name);
  const parsedOptions = parsedUsers.map((option) => ({
    label: option,
    value: option
  }));

  // const [defaultValue, setDefaultValue] = useState('');
  const [defaultValue, setDefaultValue] = useState(value);

  const onChange = (e) => {
    setValue(e.value);
    setDefaultValue(e.value);
  };
  const { themeStyles } = useContext(ThemeContext);

  useEffect(() => {
    setValue(value);
  }, []);

  useEffect(() => {
    if (!parsedUsers.find((e) => e === value)) {
      setValue(''); // what is sent
      setDefaultValue(''); // what is displayed selected
    } else {
      setValue(value);
      setDefaultValue(value);
    }
  }, [users]);

  return (
    <>
      <Dropdown
        className={ !required && 'select' }
        styles={ !required ? CustomSelectStyle((themeStyles)) : dropdownStyle(themeStyles) }
        options={ parsedOptions }
        onChange={ onChange }
        title={ title }
        value={ {
          label: defaultValue,
          value: defaultValue
        } }
      />
    </>
  );
};
const ProjectIssueField = ({
  options, title, setValue, value
}) => {
  const parsedOptions = options.map((option) => ({
    label: option,
    value: option
  }));
  const onChange = (e) => setValue(e.value);

  useEffect(() => {
    setValue(value);
  }, []);

  return (
    <>
      <Dropdown
        options={ parsedOptions }
        onChange={ onChange }
        title={ title }
        defaultValue={ {
          label: value,
          value
        } }
      />
    </>
  );
};
const VersionIssueField = ({
  options, title, setValue, value
}) => {
  const parsedOptions = options.map((option) => ({
    label: option,
    value: option
  }));
  const onChange = (e) => setValue(e.value);

  useEffect(() => {
    setValue(value);
  }, []);

  return (
    <>
      <Dropdown
        options={ parsedOptions }
        onChange={ onChange }
        title={ title }
        defaultValue={ {
          label: value,
          value
        } }
      />
    </>
  );
};

const GroupIssueField = ({
  options, title, setValue, value
}) => {
  const parsedOptions = options.map((option) => ({
    label: option,
    value: option
  }));
  const onChange = (e) => setValue(e.value);
  useEffect(() => {
    setValue(value);
  }, []);

  return (
    <>
      <Dropdown
        options={ parsedOptions }
        onChange={ onChange }
        title={ title }
        defaultValue={ {
          label: value,
          value
        } }
      />
    </>
  );
};

const OptionIssueField = ({
  options, title, setValue, value
}) => {
  const parsedOptions = options.map((option) => ({
    label: option,
    value: option
  }));
  const onChange = (e) => setValue(e.value);

  useEffect(() => {
    setValue(value);
  }, []);

  return (
    <>
      <Dropdown
        options={ parsedOptions }
        onChange={ onChange }
        title={ title }
        defaultValue={ {
          label: value,
          value
        } }
      />
    </>
  );
};

// Type C
const PriorityIssueField = ({
  options, title, setValue, value
}) => {
  const parsedOptions = options.map((option) => ({
    label: option,
    value: option
  }));

  const onChange = (e) => setValue(e.value);

  useEffect(() => {
    setValue(value);
  }, []);

  return (
    <>
      <Dropdown
        options={ parsedOptions }
        onChange={ onChange }
        title={ title }
        defaultValue={ {
          label: value,
          value
        } }
      />
    </>
  );
};

const SecurityLevelIssueField = ({
  options, title, setValue, value
}) => {
  const parsedOptions = options.map((option) => ({
    label: option,
    value: option
  }));
  const onChange = (e) => setValue(e.value);

  useEffect(() => {
    setValue(value);
  }, []);

  return (
    <>
      <Dropdown
        options={ parsedOptions }
        onChange={ onChange }
        title={ title }
        defaultValue={ {
          label: value,
          value
        } }
      />
    </>
  );
};

// Type D
const StringIssueField = ({ title, setValue, value }) => {
  useEffect(() => {
    setValue(value);
  }, []);
  return (
    <>
      <CustomInput
        title={ title }
        defaultValue={ value }
        onChange={ setValue }
        type="text"
      />
    </>
  );
};
const NumberIssueField = ({ title, setValue, value }) => {
  useEffect(() => {
    setValue(value);
  }, []);
  return (
    <>
      <CustomInput
        title={ title }
        defaultValue={ value }
        onChange={ setValue }
        type="number"
      />
    </>
  );
};

// Type E
const DateIssueField = ({ title, setValue, value }) => {
  useEffect(() => {
    if (value) setValue(value);
  }, [value]);

  return (<Date value={ value } setValue={ setValue } title={ title } />);
};

const OptionWithChildIssueField = ({ title, setValue, options_cascading }) => {
  const parsedOptions = (options) => options.map((option) => ({
    label: option,
    value: option
  }));

  const parsedNestedOptions = options_cascading.map(({ children, parent }) => ({
    label: parent,
    options: parsedOptions(children)
  }));
  const onChange = (e) => setValue(
    {
      child: e.value,
      parent: options_cascading.find((option) => option.children.some((child) => child === e.value)).parent
    }
  );

  return (
    <>
      <Dropdown
        options={ parsedNestedOptions }
        onChange={ onChange }
        title={ title }
      />
    </>
  );
};

const DateTimeIssueField = ({ title, setValue, value }) => {
  useEffect(() => {
    setValue(`${value}:00.000+0000`);
  }, []);
  return (
    <DateTimePicker onChange={ (date) => setValue(`${date}:00.000+0000`) } title={ title } defaultValue={ value } />
  );
};

const AnyIssueField = ({ title }) => (<Dropdown isDisabled title={ title } />);

const fieldTypeComponent = {
  array: ArrayIssueField,
  option: OptionIssueField,
  optionwithchild: OptionWithChildIssueField,
  datetime: DateTimeIssueField,
  date: DateIssueField,
  user: UserIssueField,
  string: StringIssueField,
  number: NumberIssueField,
  version: VersionIssueField,
  project: ProjectIssueField,
  group: GroupIssueField,
  priority: PriorityIssueField,
  securitylevel: SecurityLevelIssueField,
  any: AnyIssueField
};

export default fieldTypeComponent;
