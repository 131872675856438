import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Wrapper, ResultsTitle, Description,
  InputContainer,
  StyledInput,
  Trash
} from './styled';
import { isEmpty } from 'lodash';

const CWE = ({ currentVuln, updateVuln }) => {
  const { cwe } = currentVuln;
  const dispatch = useDispatch();
  const updateValue = (value) => dispatch(updateVuln(currentVuln, 'cwe', value, true));
  const [value, setValue] = useState(cwe);

  const removeCWE = () => {
    dispatch(updateVuln(currentVuln, 'cwe', ['']));
    setValue([]);
  };

  const onBlur = () => {
    if (!isEmpty(value)) updateValue([value]);
  };

  return (
    <Wrapper>
      <ResultsTitle>CWE</ResultsTitle>
      <Description>CWE Weakness type</Description>
      <InputContainer>
        <StyledInput
          type="text"
          placeholder="CWE-NNN"
          value={ value }
          onChange={ (e) => setValue(e.target.value) }
          onBlur={ onBlur }
        />
        <Trash
          visible={ !isEmpty(cwe) }
          onClick={ () => removeCWE() }
        />
      </InputContainer>
    </Wrapper>
  );
};

export default CWE;
