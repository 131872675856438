/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FaChevronDown as ChevronDown } from 'react-icons/fa';
import { Wrapper, RedAster, InputDropdown } from './styled';
import './styles.scss';
import DataContainer from './components/DataContainer';

const InputFilter = ({
  value, getObjects, onChange, cleanOnSelect, onSelect, type, data, placeholder, id, required, customStyles
}) => {
  const [inputValue, setValue] = useState(value);
  const [showData, setShowData] = useState(false);

  const filteredData = data.filter((it) => inputValue === '' || it.name.toLowerCase().includes(inputValue.toLowerCase()));

  useEffect(() => {
    getObjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeHandler = (v) => {
    setValue(v);
    setShowData(true);
    onChange('name', v);
  };

  const onSelectHandler = (item) => {
    if (cleanOnSelect) setValue('');
    else setValue(item.name);

    setShowData(false);
    onSelect(item);
  };

  const currentTheme = () => {
    const themeClass = localStorage.getItem('theme') === 'light' ? 'light-input' : 'dark-input';
    return themeClass;
  };

  return (
    <Wrapper onBlur={ () => { setShowData(false); } }>
      <InputDropdown id={ id } className={ `input-filter ${inputValue.length > 0 ? 'full' : ''} ${currentTheme()}` } value={ inputValue } placeholder={ placeholder } type={ type } onChange={ (e) => onChangeHandler(e.target.value) } autoComplete="off" />
      { required ? <RedAster>*</RedAster> : null }
      <ChevronDown color="#515a65" className="input-filter-arrow" onClick={ () => { setShowData(!showData); } } />
      {
        (filteredData.length > 0 && showData) &&
        <DataContainer filteredData={ filteredData } onSelectHandler={ onSelectHandler } customStyles={ customStyles } />
      }
    </Wrapper>
  );
};

InputFilter.propTypes = {
  data: PropTypes.array.isRequired,
  getObjects: PropTypes.func.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  value: PropTypes.string,
  customStyles: PropTypes.bool
};

InputFilter.defaultProps = {
  type: 'text',
  placeholder: 'Enter value',
  required: false,
  value: '',
  customStyles: false
};

export default InputFilter;
