import styled from 'styled-components';

import colors from 'Styles/colors';
import { Accept } from 'Common/Components/Button/styled';
import { ReactComponent as _modalIcon } from 'Images/new_vuln_modal_icon.svg';
import { slideIn } from 'Styles/effects';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 906px;
  height: 79%;
  border-radius: 8px 8px 2px 2px;
  box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.24);
  background-color: ${({ theme }) => theme.backgrounds.editModal};
  border-top: 10px solid #419bf9;
  padding: 40px 25px 40px 30px;
  text-align: left;
  ${slideIn}
  overflow: hidden;
`;

export const GeneralWrapper = styled.div`
  height: 530px;
  overflow-y: auto;
  padding-top: 24px;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  width: 350px;
  height: 32px;
  font-size: 21.5px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.49;
  letter-spacing: normal;
  color: ${colors.blue9};
`;
Title.displayName = 'Title';

export const ErrorMessage = styled.p`
    font-size: 12.5px;
    font-weight: normal;
    text-align: left;
    color: ${colors.redPink};
    float: left;
    width: 245px;
    margin-bottom: 8px !important;
    margin-top: 8px;
`;
ErrorMessage.displayName = 'ErrorMessage';

export const CreateButton = styled(Accept)`
  width: 194px;
  margin-left: 10px;
  margin-right: 34px;
  background-color: ${(props) => (props.disabled ? colors.blueCerulean : colors.blue12)};
  opacity:1;

  & span {
    padding: 0 55px;
  }

  & svg {
    margin-bottom: 2px;
  }

`;
CreateButton.displayName = 'CreateButton';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonWrapper = styled.div``;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: scroll;
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;

export const TabContent = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
`;

export const ModalIcon = styled(_modalIcon)``;

export const Tabs = styled.div`
  display: flex;
  margin: 21px 0;
  user-select: none;
  color: ${({ theme }) => theme.text.modalTitle};
`;

export const Tab = styled.div`
  margin-right: 28px;
  padding: 0 13px;
  padding-bottom: 10px;
  cursor: pointer;
  color: ${({ theme }) => theme.text.modalTitle};
  &:hover {
    border: none;
    color: #419bf9;
  }
  border-bottom: 2px solid transparent;

  ${({ active }) => (active
? `
    color: #419bf9;
    border: none;
    border-bottom: 2px solid #419bf9;
    font-weight: 600;
    &:hover {
      border-bottom: 2px solid #419bf9;
    }
  `
: '')}

  ${({ disable }) => (disable
? `
  color: #afb1bc;
  cursor: not-allowed;
   &:hover {
    color: #afb1bc;
   }
`
: '')}

`;
