import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  width:  ${({ mandatory }) => mandatory ? '431px' : '234px'};
  margin-top: ${({ mandatory }) => mandatory ? '10px' : '0'};
`;
Wrapper.displayName = 'Wrapper';

export const Label = styled.div`
  font-size: 12.5px;
  line-height: 1.12;
  font-weight: 400;
  color: ${colors.grey17};
  padding-bottom: 10px;
  position: relative;
  &:after {
    content: '*';
    color: ${colors.redPink};
    position: absolute;
    top: 0px;
    margin-left: 3px;
  }
`;
Label.displayName = 'Label';

export const dropdownStyle = (theme) => ({
  control: (provided, { isDisabled }) => ({
    display: 'flex',
    height: '34px',
    borderRadius: '2px',
    backgroundColor: theme.textfield.background,
    border: `solid 1px ${theme.borders.preferenceMenuLine}`,
    color: theme.textfield.text,
    paddingLeft: '10px',
    fontWeight: '400',
    textOverflow: 'ellipsis',
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    opacity: isDisabled ? '0.7' : '1',
    pointerEvents: isDisabled && 'auto'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  loadingIndicator: () => ({
    display: 'none'
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '13px',
    color: theme.textfield.text,
    fontWeight: 300
  }),
  input: (provided) => ({
    ...provided,
    fontSize: '14.5px',
    color: theme.textfield.text,
    fontWeight: 300
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#505965',
    width: '30px'
  }),
  option: (provided, { isFocused }) => ({
    ...provided,
    backgroundColor: isFocused ? theme.textfield.focus : theme.textfield.background,
    fontSize: '14.5px',
    textAlign: 'left',
    color: theme.textfield.text,
    cursor: isFocused ? 'pointer' : 'default',
    paddingLeft: '22px',
    paddingRight: '22px',
    height: '34px',
    textTransform: 'none',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    lineHeight: '1.2'
  }),
  valueContainer: (provided, { isDisabled }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginRight: 'auto',
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    opacity: isDisabled ? '0.7' : '1',
    pointerEvents: isDisabled && 'auto',
    width: 'calc(100% - 28px)'
  }),
  menu: () => ({
    position: 'absolute',
    marginTop: '6px',
    zIndex: 5000,
    width: '100%',
    border: 'none',
    borderRadius: '3px',
    backgroundColor: theme.textfield.background,
    boxShadow: `2px 2px 30px 0 ${theme.boxShadow.modal}`,
    minWidth: '100%'
  }),
  menuList: () => ({
    maxHeight: '150px',
    overflowX: 'auto',
    borderRadius: '3px',
    paddingTop: '10px',
    paddingBottom: '13px'
  }),
  groupHeading: (provided) => ({
    ...provided,
    fontSize: '10.5px'
  }),
  singleValue: (provided) => {
    const transition = 'opacity 300ms';
    return {
      ...provided, transition, color: '#1c2243', minWidth: '100%', height: '19px', fontSize: '14.5px', fontWeight: 400, top: '8px', position: 'relative', marginTop: '7px'
    };
  }
});
