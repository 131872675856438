import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  max-width: 505px;
  width: 100%;
  padding: 36px 40px;
  box-shadow: 0 2px 50px 0 ${({ theme }) => theme.shadows.confirmationModal};
  border-top: 10px solid ${colors.softBlue};
  border-radius: 2px 2px 0px 0px;
  overflow: auto;
  max-height: 85%;
  text-align: left;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  display: inline-block;
  padding-bottom: 12px;
  font-size: 21.5px;
  font-weight: 600;
  line-height: 1.49;
  color: ${({ theme }) => theme.text.modalTitle2};
`;
Title.displayName = 'Title';

export const Message = styled.div`
  display: block;
  font-size: 12.5px;
  color: ${colors.greyBlue};
`;
Message.displayName = 'Message';

export const Workspace = styled.span`
  font-size: 12.5px;
  color: ${colors.blueCerulean};
`;
Workspace.displayName = 'Workspace';

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 32px 0 0;
  & > *:not(:last-child) {
    margin-right: 13px;
  }
  & > :last-child {
   min-width: 114px;
  }
`;
Footer.displayName = 'Footer';
