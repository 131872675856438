/* eslint-disable react/jsx-key */
import React, { useContext } from 'react';
import startCase from 'lodash/startCase';
import max from 'lodash/max';
import {
  VictoryBar, VictoryChart, VictoryAxis, VictoryStack, VictoryLabel, VictoryVoronoiContainer, VictoryTooltip
} from 'victory';
import { Wrapper, StyledTick } from './styled';
import formatNumber from 'Common/Functions/FormatNumber';
import { ThemeContext } from 'Context';

const typeByHex = {
  '#a4247a': 'critical',
  '#ea3158': 'high',
  '#f59220': 'medium',
  '#88cc3f': 'low'
};

const CustomTick = ({ y, text }) => {
  const { themeStyles } = useContext(ThemeContext);
  return (
    <g>
      <foreignObject x={ 0 } y={ y - 4 } width="90px" height="15px">
        <StyledTick title={ text } themeStyles={ themeStyles }>{text}</StyledTick>
      </foreignObject>
    </g>
  );
};

const VulnsPerHosts = ({ data }) => {
  const height = 150 + (data.length * 25);
  const maxTicks = max(data.map((item) => item.data.total));
  const tickCount = maxTicks < 5 ? maxTicks : 5;
  const { themeStyles } = useContext(ThemeContext);

  const styles = {
    colorScale: ['#a4247a', '#ea3158', '#f59220', '#88cc3f'],
    VictoryTooltip: { fontSize: '10px', fill: '#63758d', fontFamily: 'Sequel Sans' },
    flyoutStyle: { stroke: '#d9e4ef', strokeWidth: 1, fill: '#fff' },
    none: { display: 'none' },
    VictoryAxis: {
      axis: {
        stroke: themeStyles.text.analyticsGraphAxis,
        strokeWidth: 1
      },
      grid: {
        fill: '#bec8d2',
        stroke: '#bec8d2',
        strokeDasharray: '10,5',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        pointerEvents: 'painted',
        strokeWidth: tickCount ? '2' : '0'
      },
      tickLabels: {
        fontFamily: 'Sequel Sans',
        fill: themeStyles.text.analyticsGraphTickLabels,
        fontSize: 12
      }
    },
    font1: {
      fontFamily: 'Sequel Sans',
      fill: themeStyles.text.analyticsGraphTickLabels,
      fontSize: 12,
      stroke: '#63758d',
      strokeWidth: 0
    },
    font2: {
      axis: {
        stroke: themeStyles.text.analyticsGraphAxis,
        strokeWidth: 1
      },
      tickLabels: {
        fontFamily: 'Sequel Sans',
        fill: themeStyles.text.analyticsGraphTickLabels,
        fontSize: 8,
        stroke: 'transparent',
        strokeWidth: 0
      }

    },
    font3: {
      fontFamily: 'Sequel Sans',
      fill: themeStyles.text.analyticsGraphTickLabels,
      fontSize: 12,
      stroke: '#63758d',
      strokeWidth: 0,
      fontWeight: 600
    }

  };

  const getLabels = ({ datum }) => {
    const type = typeByHex[datum.style.data.fill];
    const quantity = datum.data[type];
    if (quantity === 0) return null;

    return `${quantity} ${startCase(type)} Vulnerabilities`;
  };
  const types = ['critical', 'high', 'medium', 'low'];

  return (
    <Wrapper>
      <VictoryChart
        height={ height }
        domainPadding={ 40 }
        padding={ {
          left: 100, top: 0, right: 0, bottom: 80
        } }
        containerComponent={ (
          <VictoryVoronoiContainer
            labels={ getLabels }
            labelComponent={ (
              <VictoryTooltip
                constrainToVisibleArea
                flyoutStyle={ styles.flyoutStyle }
                flyoutPadding={ {
                  top: 10, right: 12, bottom: 10, left: 11
                } }
                style={ styles.VictoryTooltip }
                cornerRadius={ 2 }
                pointerLength={ 0 }
                centerOffset={ { x: 0, y: 25 } }
              />
            ) }
          />
      ) }
      >
        <VictoryAxis
          label="Workspace hosts"
          axisLabelComponent={ (
            <VictoryLabel
              dy={ -60 }
              style={ { display: 'none' } }
            />
          ) }
          style={ styles.font2 }
          tickLabelComponent={ <CustomTick /> }
        />
        <VictoryAxis
          tickCount={ tickCount || 1 }
          tickFormat={ (t) => `${formatNumber(t)}` }
          label="Number of Vulnerabilities"
          axisLabelComponent={ (
            <VictoryLabel
              dy={ 20 }
              style={ styles.font3 }
            />
          ) }
          dependentAxis
          style={ styles.VictoryAxis }
        />
        <VictoryStack
          horizontal
          colorScale={ styles.colorScale }
        >
          {types.map((type) => {
            const y = `data.${type}`;
            return (
              <VictoryBar
                sortKey="data.total"
                labelComponent={ <VictoryLabel style={ styles.none } /> }
                data={ data }
                y={ y }
                x="label"
                style={ { data: { width: 20 } } }
              />
            );
          })}
        </VictoryStack>
      </VictoryChart>
    </Wrapper>
  );
};

export default VulnsPerHosts;
