import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  background-color: ${({ theme }) => theme.backgrounds.actionBar};
  padding-top: 8px;
  position: relative;
`;

Wrapper.displayName = 'Wrapper';

export default Wrapper;
// export const TableContainer = styled.div`
//   position: relative;
//   display: flex;
//   flex-direction: row;
//   overflow: hidden;
//   flex: 1 1 0;
//   width: 100%;
// `;
// TableContainer.displayName = 'TableContainer';
