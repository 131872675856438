import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.backgrounds.pipelineBox};
  padding: 27px 0 45px 40px;
`;
Wrapper.displayName = 'Wrapper';

export const Label = styled.div`
  color:${({ theme }) => theme.textfield.text};
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  opacity: 0.53;
`;
Label.displayName = 'Label';

export const RuleWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
RuleWrapper.displayName = 'RuleWrapper';

export const AttributeWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0 10px 0;
`;
AttributeWrapper.displayName = 'AttributeWrapper';

export const Attribute = styled.div`
  display: flex;
  ${({ isOneOf }) => isOneOf && `
    & > *:not(last-child) {
      height: auto;
      padding: 4px 10px 0px 10px;
    }
  `}
`;
Attribute.displayName = 'Attribute';

export const Tag = styled.div`
  background-color: ${(props) => (props.type === 'op' ? colors.iceBlue : colors.paleGrey)};
  padding: ${(props) => (props.type === 'op' ? '2px 8px' : '2px 10px')};
  border-top: 1px solid ${colors.grey12};
  border-bottom: 1px solid ${colors.grey12};
  border-left: ${(props) => (props.type === 'op' ? 'none' : `1px solid ${colors.grey12}`)};
  border-right: ${(props) => (props.type === 'op' ? 'none' : `1px solid ${colors.grey12}`)};
  border-radius: ${({ type }) => {
    if (type === 'op') return '0';
    if (type === 'field') return '2px 0 0 2px';
    return '0 2px 2px 0';
  }};
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.06);
  color: ${colors.greyBlue};
  font-size: 11.5px;
  height: 24px;
`;
Tag.displayName = 'Tag';

export const AndLabel = styled.div`
  color: #264d6d;
  font-size: 14px;
  margin: 0 8px;
  font-weight: 600;
`;
AndLabel.displayName = 'AndLabel';

export const ThenLabel = styled(Label)`
  margin-top: 13px;
`;
ThenLabel.displayName = 'ThenLabel';

export const TaskWrapper = styled.div`
  display: flex;
  margin-top: 9px;
  padding-bottom: 5px;
`;
TaskWrapper.displayName = 'TaskWrapper';

export const Action = styled.div`
  border-radius: 2px;
  box-shadow: inset 0 0 12px 0 rgba(0, 0, 0, 0.13);
  background-color: #63758d;
  color: #FFF;
  padding: 3px 12px;
  font-size: 11.5px;
  font-weight: 600;
  margin-right: 10px;
`;
Action.displayName = 'Action';

export const Field = styled.div`
  border-radius: 2px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px ${colors.grey12};
  background-color: ${colors.paleGrey};
  color: ${colors.greyBlue};
  padding: 3px 12px;
  font-size: 11.5px;
  font-weight: 500;
  text-transform: capitalize;
  margin-right: 10px;
  ${({ supportsList }) => supportsList && `
    box-shadow: none;
    border: none;
    padding: 0px 7px;
  `}
`;
Field.displayName = 'Field';

export const FieldsWrapper = styled.div`
  width: max-content;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: auto;
  background-color: #dde5f0;
  padding: 5px 0px 5px 8px;
  border-radius: 2px;
  border: solid 1px #bec8d2;
  cursor: pointer;
`;
FieldsWrapper.displayName = 'FieldsWrapper';
