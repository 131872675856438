import React, { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { Input, Label } from './styled';
import DateInput from 'Screens/Automation/Jobs/components/Detail/components/ObjectAndAttributes/components/Rule/components/NewAttribute/components/DateInput';

const Date = ({ setValue, value, title }) => {
  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(value);

  const handleDateSelect = (val) => {
    setSelectedDate(val);
    setValue(val);
    setOpen(false);
  };

  return (
    <>
      <Label children={ title } />
      {!open && <Input onClick={ () => setOpen(true) } children={ selectedDate ? moment(selectedDate).format('YYYY-MM-DD') : 'Select a date' } />}
      {open && <DateInput type="date" onValueSelect={ handleDateSelect } onClose={ () => setOpen(false) } />}
    </>
  );
};

export default Date;
