import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 282px;
  height: 100%;
  width: 100%;
  overflow: auto;
  background: ${({ theme }) => theme.backgrounds.analyticsNewGraph};
  padding: 21px;
`;
Wrapper.displayName = 'Wrapper';

export const Subtitle = styled.div`
  font-size: 12.5px;
  line-height: 1.28;
  color: ${({ theme }) => theme.text.customAttributeItem};
`;
Subtitle.displayName = 'Subtitle';
