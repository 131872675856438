/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserSelected } from 'store/Users/selectors';
import { selectWorkspaces } from 'store/Faraday/selectors';
import {
  Wrapper, WsItem, WorkspaceName
} from './styled';
import Checkbox from 'Common/Components/Checkbox';
import { removeUserFromWorkspace, saveUserWorkspaces } from 'Screens/Contextualization/Users/actions/Actions';

const SubMenuWorkspaces = ({ showing }) => {
  const dispatch = useDispatch();
  const workspaces = useSelector(selectWorkspaces);
  const userSelected = useSelector(selectUserSelected);
  // const state = useSelector((s) => ({ workspaces: selectWorkspaces(s), userSelected: selectUserSelected(s) }));

  const [workspaceList, setWorkspaceList] = useState([]);
  const [workspacesSelected, setWorkspacesSelected] = useState([]);

  const workspacesAssignedOnAllUsers = workspaces.filter((ws) => userSelected.every((user) => user.workspaces.includes(ws.name)));

  useEffect(() => {
    setWorkspaceList(workspaces.filter((workspace) => !workspacesAssignedOnAllUsers.some((ws) => ws._id === workspace._id)));

    if (!showing) {
      setWorkspacesSelected([]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaces, showing]);

  useEffect(() => {
    const workspacesAssignedOnUser = workspaces.filter((ws) => userSelected[0].workspaces.includes(ws.name));
    setWorkspacesSelected(workspacesAssignedOnUser);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showing]);

  const selectWorkspace = (ws) => {
    const newWsSelected = [...workspacesSelected, ws];
    setWorkspaceList(workspaceList.filter((workspace) => workspace.name !== ws.name));
    setWorkspacesSelected(newWsSelected);
    dispatch(saveUserWorkspaces(newWsSelected));
  };

  const removeWorkspace = (ws) => {
    const wsSelected = workspacesSelected.filter((workspace) => workspace.name !== ws.name);
    const wsList = [...workspaceList, ws];
    setWorkspaceList(wsList);
    setWorkspacesSelected(wsSelected);
    dispatch(removeUserFromWorkspace(ws.name));
  };

  const onChangeCheckbox = (e, workspace) => {
    e.stopPropagation();
    if (workspacesSelected.map((ws) => ws.name).includes(workspace.name)) removeWorkspace(workspace);
    else selectWorkspace(workspace);
  };

  return (
    <Wrapper>
      { workspaces
        ? workspaces.map((workspace) =>
          <WsItem key={ workspace.id }>
            <Checkbox onChange={ (e) => { onChangeCheckbox(e, workspace); } } state={ workspacesSelected.map((ws) => ws.name).includes(workspace.name) } />
            <WorkspaceName>{ workspace.name }</WorkspaceName>
          </WsItem>)
        : null
      }
    </Wrapper>
  );
};

export default SubMenuWorkspaces;
