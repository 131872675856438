import styled from 'styled-components';

export const NameWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
NameWrapper.displayName = 'NameWrapper';

export const Name = styled.div`
 font-size: 14.5px;
  color: ${({ confirmed, theme }) => (confirmed ? theme.text.tableVulnNameConfirmed : theme.text.tableVulnName)}; 
  font-weight: ${({ confirmed }) => (confirmed ? '600' : 'normal')}; 
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &:hover {
    text-decoration: underline;
  }
`;
Name.displayName = 'Name';
