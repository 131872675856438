import React from 'react';
import { PropTypes } from 'prop-types';
import {
  ContextMenu, MenuItem
} from 'react-contextmenu';
import { changeReadOnly, moreStatusChange, enableDisable, massiveUpdateWorkspacesActive } from 'Screens/Contextualization/Workspaces/actions/Actions';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { MdKeyboardArrowRight as Arrow } from 'react-icons/md';
import { selectWorkspacesSelected } from 'store/Workspace/selectors';
import {
  MenuItemContent, Text, Wrapper, ArrowIcon, SubMenuStatus, StatusText,
  LockIcon,
  UnlockIcon,
  ArchiveIcon
} from './styled';
import get from 'lodash/get';
import { ActiveIcon } from '../Table/EnableDisableWs/styled';

const ContextMenuWorkspaceMassiveUpdate = ({ trigger, setShowConfirmation }) => {
  const dispatch = useDispatch();
  const workspacesSelected = useSelector((state) => selectWorkspacesSelected(state));
  const ws = get(workspacesSelected, '[0]', null);
  const isBulk = () => workspacesSelected.length > 1;

  const setReadOnly = (value) => {
    if (workspacesSelected.length > 0) {
      workspacesSelected.forEach((ws) => {
        dispatch(changeReadOnly(ws.name, value));
      });
    }
  };

  const handleArchiveWs = (active) => {
    if (workspacesSelected.length === 1) {
      if (ws.active) {
        setShowConfirmation({ show: true, ws: ws.name, isMassive: false });
      } else {
        dispatch(enableDisable(ws.name, true));
      }
    } else {
      if (active) dispatch(massiveUpdateWorkspacesActive(active));
      else setShowConfirmation({ show: true, ws: '', isMassive: true, active });
    }
  };

  return (
    <Wrapper>
      <ContextMenu id={ trigger } onHide={ () => dispatch(moreStatusChange(false)) } onShow={ () => moreStatusChange(true) }>
        { !isBulk() && (
        <MenuItem onClick={ handleArchiveWs }>
          <MenuItemContent>
            <ArchiveIcon />
            <Text><FormattedMessage id="workspaces.contextMenu.activeArchived" /></Text>
          </MenuItemContent>
        </MenuItem>
        ) }
        { isBulk() && (
          <SubMenuStatus title={ (
            <MenuItemContent>
               <ArchiveIcon />
              <Text><FormattedMessage id="workspaces.contextMenu.activeArchived" /></Text>
              <ArrowIcon><Arrow /></ArrowIcon>
            </MenuItemContent>
            ) }
          >
          <MenuItem onClick={ () => { handleArchiveWs(true); } }><ActiveIcon /><StatusText>Active</StatusText></MenuItem>
          <MenuItem onClick={ () => { handleArchiveWs(false); } }><ArchiveIcon /><StatusText>Archived</StatusText></MenuItem>
          </SubMenuStatus>
        ) }
        <SubMenuStatus title={ (
          <MenuItemContent>
            <LockIcon />
            <Text><FormattedMessage id="workspaces.contextMenu.workspaceStatus" /></Text>
            <ArrowIcon><Arrow /></ArrowIcon>
          </MenuItemContent>
            ) }
        >
          <MenuItem onClick={ () => { setReadOnly(false); } }><UnlockIcon /><StatusText><FormattedMessage id="workspaces.table.columns.unlocked" /></StatusText></MenuItem>
          <MenuItem onClick={ () => { setReadOnly(true); } }><LockIcon /><StatusText><FormattedMessage id="workspaces.table.columns.locked" /></StatusText></MenuItem>
        </SubMenuStatus>
      </ContextMenu>
    </Wrapper>
  );
};

ContextMenuWorkspaceMassiveUpdate.propTypes = {
  trigger: PropTypes.string.isRequired
};

export default ContextMenuWorkspaceMassiveUpdate;
