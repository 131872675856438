export const GRAPHS_FETCHING = 'GRAPHS_FETCHING';
export const GRAPHS_FAIL = 'GRAPHS_FAIL';
export const GRAPHS_GET_ALL = 'GRAPHS_GET_ALL';
export const GRAPH_DETAIL = 'GRAPH_DETAIL';
export const GRAPH_SAVE_SUCCESS = 'GRAPH_CREATE_UPDATE_SUCCESS';
export const GRAPH_RESET_DETAIL = 'GRAPH_RESET_DETAIL';
export const GRAPH_RESET_ERROR = 'RESET_STATE_GRAPH_CREATE_UPDATE';
export const GRAPH_TOGGLE_VIEW = 'GRAPH_TOGGLE_VIEW';
export const GRAPH_RESET_STATE = 'GRAPH_RESET_STATE';
export const GRAPH_SET_TYPE = 'GRAPH_SET_TYPE';
export const GRAPH_SET_NAME = 'GRAPH_SET_NAME';
export const GRAPH_SET_DESCRIPTION = 'GRAPH_SET_DESCRIPTION';
export const GRAPH_SET_NOT_CONFIRMED = 'GRAPH_SET_NOT_CONFIRMED';
export const GRAPH_SET_CLOSED = 'GRAPH_SET_CLOSED';
export const GRAPH_SET_WORKSPACES = 'GRAPH_SET_WORKSPACES';
export const GRAPH_SET_HOSTS = 'GRAPH_SET_HOSTS';
export const GRAPH_SET_FROM = 'GRAPH_SET_FROM';
export const GRAPH_SET_TO = 'GRAPH_SET_TO';
export const GRAPH_REFRESH_SUCCESS = 'GRAPH_REFRESH_SUCCESS';
export const GRAPH_SET_ID = 'GRAPH_SET_ID';
export const GRAPH_GET_HOSTS = 'GRAPH_GET_HOSTS';
export const GRAPH_CLOSE_NOTIFICATION = 'GRAPH_CLOSE_NOTIFICATION';
export const GRAPH_UPDATED = 'GRAPH_UPDATED';
export const GRAPH_SET_SHOW_TABLE = 'GRAPH_SET_SHOW_TABLE';
export const GRAPH_REFRESH_FAIL = 'GRAPH_REFRESH_FAIL';
export const GET_CURRENT_WS_DATA = 'GET_CURRENT_WS_DATA';
export const RESET_STATE_GRAPH_UPDATED = 'RESET_STATE_GRAPH_UPDATED';
export const GRAPH_SELECTED = 'GRAPH_SELECTED';
export const GRAPH_UNSELECTED = 'GRAPH_UNSELECTED';
export const GRAPH_NEW_PIVOT = 'GRAPH_NEW_PIVOT';
export const SELECT_ALL_GRAPHS = 'SELECT_ALL_GRAPHS';
export const UNSELECT_ALL_GRAPHS = 'UNSELECT_ALL_GRAPHS';
