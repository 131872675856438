import styled from 'styled-components';
import colors from 'Styles/colors';
import { Title } from 'Screens/Contextualization/Settings/common-styled';

export const Wrapper = styled.div`
  padding-bottom: 20px;
  ${Title} {
    font-size: 14.5px;
    font-weight: 500;
  }
`;
Wrapper.displayName = 'Wrapper';

export const Content = styled.div`
  padding: 0;
  & > *:not(:last-child) {
    padding-bottom: 20px;
  }
`;
Content.displayName = 'Content';

export const Label = styled.div`
  font-size: 12.5px;
  line-height: 1.12;
  font-weight: 400;
  color: ${colors.grey17};
  padding-bottom: 10px;
  ${({ mandatory }) => mandatory && `
    position: relative;
    &:after {
      content: '*';
      color: ${colors.redPink};
      position: absolute;
      top: 0px;
      margin-left: 3px;
    }
  `}  
`;
Label.displayName = 'Label';

export const WrapperField = styled.div`
  display: flex;
  flex-flow: row wrap;
`;
WrapperField.displayName = 'WrapperField';

export const WrapperSubcategory = styled.div`
  padding-top: 20px;
`;
WrapperSubcategory.displayName = 'WrapperSubcategory';

export const WrapperCategory = styled.div`
  margin-right: 25px;
  padding-top: 20px;
`;
WrapperCategory.displayName = 'WrapperCategory';

export const WrapperTemplate = styled.div`
  width: calc(92% / 2);
  padding-top: 20px;
`;
WrapperTemplate.displayName = 'WrapperTemplate';

export const Subtitle = styled.div`
  user-select: none;
  padding: 25px 0 0 0;
  font-size: 12.5px;
  line-height: 1.12;
  text-align: left;
  color: ${colors.grey19};
`;
Subtitle.displayName = 'Subtitle';
