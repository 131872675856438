import styled from 'styled-components';
import colors from 'Styles/colors';

// eslint-disable-next-line import/prefer-default-export
export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
    font-size: 12.5px;
    font-weight: 500;
    line-height: 2.08;
    color: ${colors.darkBlueGrey};
    font-weight: 600;
    margin-bottom: 12px;
    ${({ required }) => required && `
        position: relative;
        &:after {
        content: '*';
        color: ${colors.redPink};
        position: absolute;
        top: 3px;
        margin-left: 3px;
        }
    `}  
`;
Title.displayName = 'Title';

export const DropdownStyle = (theme) => ({
  control: () => ({
    minHeight: '38px',
    width: '122px',
    borderRadius: '2px',
    boxShadow: theme.boxShadow.dropdown,
    backgroundColor: theme.textfield.background,
    color: 'white',
    paddingLeft: '10px',
    fontWeight: '700',
    borderWidth: '0px',
    borderStyle: 'solid',
    marginTop: '11px'
  }),

  indicatorSeparator: () => ({
    display: 'none'
  }),

  placeholder: () => ({
    fontSize: '13px',
    color: theme.text.placeholder,
    position: 'relative',
    fontWeight: 400
  }),

  dropdownIndicator: (provided) => ({
    ...provided,
    position: 'absolute',
    top: '0px',
    right: '4px',
    color: '#515a65',
    width: '30px'
  }),

  valueContainer: () => ({
    height: '33px',
    display: 'flex',
    alignItems: 'center'
  }),

  option: (provided, state) => ({
    ...provided,
    backgroundColor: theme.backgrounds.actionDropDown,
    fontSize: '14px',
    textAlign: 'left',
    color: theme.text.actionDropDownItem,
    cursor: state.isSelected ? 'default' : 'pointer',
    paddingLeft: '22px',
    height: '36px',
    textTransform: 'none',
    fontWeight: state.isSelected ? 600 : 300
  }),

  menu: () => ({
    position: 'absolute',
    marginTop: '6px',
    zIndex: 5000,
    width: '100%',
    border: 'none',
    borderRadius: '3px',
    backgroundColor: theme.backgrounds.dropdown1Options,
    boxShadow: `2px 2px 30px 0 ${theme.shadows.actionDropDown}`,
    minWidth: '122px'
  }),

  menuList: () => ({
    maxHeight: '250px',
    overflowX: 'auto',
    borderRadius: '3px',
    paddingTop: '17px',
    paddingBottom: '13px'
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return {
      ...provided, opacity, transition, color: theme.text.actionDropDownTitle, width: 'fit-content', height: '19px', fontSize: '14px', fontWeight: 400, top: '8px', position: 'relative', maxWidth: 'calc(100% - 24px)'
    };
  }
});

DropdownStyle.displayName = 'DropdownStyle';
