import styled from 'styled-components';
import colors from 'Styles/colors';
import BlueButton from 'Common/Components/BlueButton';

export const CancelButton = styled.button`
  height: 34px;
  border: 1px solid #d4d7e5;
  color: ${colors.darkBlueGrey};
  background-color: white;
  margin-right: 13px;
  font-size: 14.5px;
  padding: 5px 21px;
  border-radius: 3px;
  font-weight: 400;
  width: 90px;
  user-select: none;
`;
CancelButton.displayName = 'CancelButton';

export const CustomBlueButton = styled(BlueButton)`
  height: 34px;
  width: 90px;
  user-select: none;
  padding: 0px;
`;
CustomBlueButton.displayName = 'CustomBlueButton';

export const ButtonsWrapper = styled.div`
  position: absolute;
  padding: 10px;
  bottom: 0px;
  right: 0px;
  background-color: ${({ theme }) => theme.backgrounds.quaternary};
  height: 60px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;
ButtonsWrapper.displayName = 'ButtonsWrapper';
