/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { ReactComponent as collapsedImage } from 'Images/wf_arrow_collapsed.svg';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;
Container.displayName = 'Container';

export const Text = styled.div`
  text-transform: capitalize;
`;
Text.displayName = 'Text';

export const CollapsedIcon = styled(collapsedImage)`
    width: 10px;
    height: 10px;
    margin-left: auto;
    path {
      fill: ${({ theme }) => theme.icons.preferenceMenu.color};
    }
`;
CollapsedIcon.displayName = 'CollapsedIcon';
