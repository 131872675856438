import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import {
  Wrapper, Search, Remove, WrapperSearch
} from './styled';
import DataContainer from 'Common/Components/InputFilter/components/DataContainer';

const ElementSearch = ({
  elementIndex, elementName, onSelectCallback, onRemoveCallback, elements, placeholder, inputRef
}) => {
  const [search, setSearch] = useState(elementName);
  const [show, setShow] = useState();

  const onSelect = (value) => {
    if (value) onSelectCallback(elementIndex, value.name);
  };

  const onChange = (e) => {
    setSearch(e.target.value);
  };

  const onRemove = () => {
    onRemoveCallback(elementIndex);
  };

  const onBlur = debounce(() => {
    if (search !== elementName) setSearch('');
    setShow(false);
  }, 300);

  const onFocus = () => {
    setShow(true);
  };

  useEffect(() => {
    if (search === elementName) setShow(false);
    else setShow(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const filteredElements = elements.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()));

  return (
    <Wrapper>
      <WrapperSearch>
        <Search ref={ inputRef } onFocus={ onFocus } onBlur={ onBlur } placeholder={ placeholder } onChange={ onChange } value={ search } />
        <Remove onClick={ onRemove } />
      </WrapperSearch>
      {show && <DataContainer filteredData={ filteredElements } onSelectHandler={ onSelect } customStyles />}
    </Wrapper>
  );
};

ElementSearch.propTypes = {
  elementIndex: PropTypes.number.isRequired,
  elementName: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onRemoveCallback: PropTypes.func.isRequired,
  onSelectCallback: PropTypes.func.isRequired,
  elements: PropTypes.instanceOf(Array).isRequired, // debe tener objectos con {name}
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ])
};

export default ElementSearch;
