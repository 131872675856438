import React from 'react';
import { useDispatch } from 'react-redux';
import { sendVulnToServiceNowContext } from 'store/Settings/serviceNowActions';
import first from 'lodash/first';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import {
  Wrapper, Title, Subtitle, Footer, Close, Save, Blue
} from './styled';

const ServiceNow = ({ onClose, vulnsSelected, vulnId, isVulnDetailOpen, entity }) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const onSave = () => {
    dispatch(sendVulnToServiceNowContext(vulnsSelected, vulnId, isVulnDetailOpen, entity));
    onClose();
  };

  const getText = () => ((vulnsSelected.length === 1) ? first(vulnsSelected).name : `${vulnsSelected.length} vulnerabilities`);

  return (
    <Wrapper>
      <Title>{ intl.formatMessage({ id: 'sendToTool.serviceNow.title' }) }</Title>
      <Subtitle>
        { intl.formatMessage({ id: 'sendToTool.serviceNow.message.youAreSending' }) }
        <Blue>{ getText() }</Blue>
        { intl.formatMessage({ id: 'sendToTool.serviceNow.message.to' }) }
      </Subtitle>
      <Footer>
        <Close onClick={ onClose }>{ intl.formatMessage({ id: 'sendToTool.serviceNow.form.close' }) }</Close>
        <Save onClick={ onSave }>{ intl.formatMessage({ id: 'sendToTool.serviceNow.form.save' }) }</Save>
      </Footer>
    </Wrapper>
  );
};

ServiceNow.propTypes = {
  onClose: PropTypes.func.isRequired
};
export default ServiceNow;
