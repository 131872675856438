import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: end;
  background-color: ${({ theme }) => theme.backgrounds.primary};
  padding-bottom: 13px;
`;
Wrapper.displayName = 'Wrapper';

export default Wrapper;
