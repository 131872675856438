import styled from 'styled-components';
import { customFieldContent } from 'Styles/styles';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

Wrapper.displayName = 'Wrapper';

export const Buttons = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 34px;
    > :nth-child(1){
        margin-right: 8px;
    }
`;

Buttons.displayName = 'Buttons';

export const List = styled.ul`
    cursor: pointer;
    margin-left: 33px;
`;

List.displayName = 'List';

export const Item = styled.li`
  ${customFieldContent};
`;

Item.displayName = 'Item';

export const CustomListWrapper = styled.div`
    width: 384px;  
`;
CustomListWrapper.displayName = 'CustomListWrapper';
