import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  /* justify-content: space-between; */
  justify-content: ${({ canCreate }) => canCreate ? 'space-between' : 'right'};
  height: 55px;
  padding-left: 9px;
  margin-top: 21px;
  background-color: ${({ theme }) => theme.backgrounds.actionBar};
  border-radius: 2px;
  >:nth-child(2){
    margin-right: 14px;
  }
`;
Wrapper.displayName = 'Wrapper';

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
`;
Actions.displayName = 'Actions';

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  margin-left: auto;
  width: 170px;
  margin-right: 7px;
`;
PaginationContainer.displayName = 'PaginationContainer';
