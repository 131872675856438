import React, { useEffect, useState, useContext } from 'react';
import CreatableSelect from 'react-select/creatable';
import { Title, dropdownStyle } from '../styled';
import { ThemeContext } from 'Context';

const createOption = (label) => ({
  label,
  value: label
});
const components = {
  DropdownIndicator: null
};

const CreatableInput = ({
  className, onChange, placeholder, defaultValue = [], style = dropdownStyle, ...props
}) => {
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState(defaultValue);

  const { themeStyles } = useContext(ThemeContext);

  useEffect(() => {
    if (onChange) onChange(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleKeyDown = (event) => {
    if (!inputValue || value.some((el) => el.label === inputValue)) return;
    if (event.key === 'Enter') {
      setInputValue('');
      setValue([...value, createOption(inputValue)]);
      event.preventDefault();
    }
  };
  const handleChange = (e) => setValue(e || []);

  const handleInputChange = (inputValue) => setInputValue(inputValue);

  return (
    <div className={ className }>
      {props.title && <Title>{props.title}</Title>}
      <CreatableSelect
        styles={ dropdownStyle(themeStyles) }
        components={ components }
        inputValue={ inputValue }
        isClearable
        isMulti
        menuIsOpen={ false }
        onChange={ handleChange }
        onInputChange={ handleInputChange }
        onKeyDown={ handleKeyDown }
        placeholder={ placeholder || 'Type something and press enter...' }
        value={ value }
        { ...props }
      />
    </div>
  );
};
export default CreatableInput;
