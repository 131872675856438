import styled from 'styled-components';
import colors from 'Styles/colors';
import { SubMenu } from 'react-contextmenu';

export const Wrapper = styled.div`
  .react-contextmenu {
    width: 243px;
    border-radius: 2px;
    box-shadow: 2px 2px 30px 0 ${({ theme }) => theme.shadows.actionDropDown};
    border: solid 1px ${({ theme }) => theme.borders.actionDropDown};
    background-color: ${({ theme }) => theme.backgrounds.actionDropDown};
  }
`;
Wrapper.displayName = 'Wrapper';

export const Icon = styled.img`
  width: auto;
  height: auto;
  display: inline-block;
  margin-right: 13px;
`;
Icon.displayName = 'Icon';

export const IconActiveInactive = styled(Icon)`
  margin-bottom: 2px;
`;
IconActiveInactive.displayName = 'IconActiveInactive';

export const IconDelete = styled(IconActiveInactive)`
  width: 17px;
`;
IconDelete.displayName = 'IconDelete';

export const MenuItemContent = styled.div`
  &:hover {
    background-color: ${({ theme }) => theme.backgrounds.actionDropDownHover} !important;
  } 
`;
MenuItemContent.displayName = 'MenuItemContent';

export const Text = styled.div`
  display: inline-block;
  font-size: 13.5px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  margin-left: 13px;
  ${({ isDeleteItem }) => isDeleteItem && `
    color: ${colors.red1}
  `}
`;
Text.displayName = 'Text';

export const SubMenuUsers = styled(SubMenu)`
  background-color: ${({ theme }) => theme.backgrounds.contextMenu} !important;
  width: 243px !important;
`;
SubMenuUsers.displayName = 'SubMenuUsers';

export const SubMenuRol = styled(SubMenu)`
  width: 243px !important;
  border-radius: 2px;
  box-shadow: 2px 2px 30px 0 rgba(0, 0, 0, 0.14);
  background-color: ${({ theme }) => theme.backgrounds.contextMenu} !important;
  font-size: 13.5px;
  padding-top: 9px;
  padding-bottom: 11px;
`;
SubMenuRol.displayName = 'SubMenuRol';

export const SubMenu2Fa = styled(SubMenu)`
  background-color: ${({ theme }) => theme.backgrounds.contextMenu} !important;
  width: 130px !important;
  padding-top: 9px;
  padding-bottom: 11px;
  color: ${({ theme }) => theme.text.contextMenu};
`;
SubMenu2Fa.displayName = 'SubMenu2Fa';

export const ArrowIcon = styled.div`
  width: 10px;
  height: 6px;
  margin-top: 3px;
  display: inline-block;
  float: right;
  color: ${colors.blueGrey};
`;
ArrowIcon.displayName = 'ArrowIcon';

export const RoleText = styled.div`
  color: ${({ theme }) => theme.text.contextMenu};
  /* color: ${({ role }) => {
    if (role === 'admin') return colors.warmPurple;
    if (role === 'pentester') return colors.redPink;
    if (role === 'client') return colors.blueCerulean;
    if (role === 'asset_owner') return colors.softBlue;
    return colors.softBlue;
  }}; */
  font-weight: 300;

`;
RoleText.displayName = 'RoleText';
