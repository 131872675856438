import styled from 'styled-components';
import { ReactComponent as Run } from 'Images/icon-pipeline-run.svg';
import { ReactComponent as RunDisabled } from 'Images/icon-action-bar-trigger-disabled.svg';
import { ReactComponent as Running } from 'Images/icon-pipeline-running.svg';

export const Wrapper = styled.div`
  overflow: auto;
  margin: 30px 12px 0 0;
  padding-right: 10px;
`;
Wrapper.displayName = 'Wrapper';

export const Box = styled.div`
  width: 100%;
  height: 108px;
  background-color: ${({ theme }) => theme.backgrounds.memoListItem};
  border-radius: 3px;
  box-shadow: 0 2px 15px 0 ${({ theme }) => theme.shadows.memoListItem} !important;
  border: solid 1px ${({ theme }) => theme.borders.memoListItem};
  margin-bottom: 30px;
  display: flex;
  padding: 15px 20px 19px 20px;
  position: relative;
  ${({ selected, theme }) => selected && `
    border: solid 3px ${theme.borders.memoListItemSelected};
    padding: 13px 18px;
  `}
`;
Box.displayName = 'Box';

export const LeftIcons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-right: 19px;
  width: 30px;
  height: 76px;
  & > input {
    margin-top: 9px;
  }

`;
LeftIcons.displayName = 'LeftIcons';

export const RunIcon = styled(Run)`
  width: 20px;
`;
RunIcon.displayName = 'RunIcon';

export const DisabledRunIcon = styled(RunDisabled)`
  width: 20px;
  cursor: default;
`;
DisabledRunIcon.displayName = 'DisabledRunIcon';

export const RunningIcon = styled(Running)`
  width: 20px;
  margin: 0 0 5px 0;
`;
RunningIcon.displayName = 'RunningIcon';

export const PipelineData = styled.div`
  max-width: 300px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
PipelineData.displayName = 'PipelineData';

export const TopData = styled.div`
  display: flex;
  flex-direction: column;
`;
TopData.displayName = 'TopData';

export const Name = styled.h3`
  font-size: 17px;
  font-weight: 600;
  color: ${({ theme }) => theme.text.memoListItemTitle};
  margin-bottom: 3px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  max-width: max-content;
`;
Name.displayName = 'Name';

export const Description = styled.span`
  font-size: 12.5px;
  color: ${({ theme }) => theme.text.memoListItemDescription};
  margin-bottom: 11px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
Description.displayName = 'Description';

export const Workspace = styled.span`
  font-size: 13px;
  font-weight: 600;
  color: ${({ theme }) => theme.text.memoListItemDescription};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
Workspace.displayName = 'Workspace';
