import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FEATURE_USERS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { selectUserSelected } from 'store/Users/selectors';
import { Role } from '../../styled';
import IconButton from 'Common/Components/IconButton';
import ModalWarning from 'Common/Components/ModalWarning';
import { useIntl } from 'react-intl';
import useClickOutside from 'Hooks/useClickOutside';
import Wrapper from './styled';
import { Dropdown } from '../TwoFactorAuth/styled';
import MenuItem from 'Common/Components/ContextMenu/components/MenuItem';
import { updateRoleBulk } from 'Screens/Contextualization/Users/actions/Actions';
import { selectActiveUser } from 'store/Sesion/selectors';

const Item = ({ option, handleDropdown }) => {
  const parsedRole = {
    'Administrator': 'admin',
    'Pentester': 'pentester',
    'Asset Owner': 'asset_owner',
    'Client': 'client'
  };
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(updateRoleBulk(parsedRole[option]));
    handleDropdown();
  };

  return (
    <MenuItem
      title={ option }
      onClick={ handleClick }
    />
  );
};

const ChangeRole = () => {
  const [showWarning, setShowWarning] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const userSelected = useSelector(selectUserSelected);
  const activeUser = useSelector(selectActiveUser);
  const allowedByRole = useSelector((state) => selectAllowedByRole(state, FEATURE_USERS, PERMISSION_TYPES.UPDATE));
  const canUpdate = allowedByRole && !userSelected.some((u) => u.username === activeUser);
  const intl = useIntl();
  const message = intl.formatMessage({ id: 'modalWarning.selectOneOrMore' }, { entity: 'user' });

  const roleDropdownRef = useRef();
  useClickOutside(roleDropdownRef, () => setShowDropdown(false));

  const handleDropdown = () => setShowDropdown(!showDropdown);

  const handleClick = () => {
    if (canUpdate) {
      if ((userSelected.length > 0)) handleDropdown();
      else setShowWarning(true);
    }
  };

  const onClose = () => setShowWarning(false);

  return (
    <Wrapper ref={ roleDropdownRef }>
      <IconButton icon={ <Role disabled={ !canUpdate } /> } title="Change Role" onClick={ handleClick } disabled={ !canUpdate } />
      {showDropdown && (
        <Dropdown width="157px">
          <Item option="Administrator" handleDropdown={ handleDropdown } />
          <Item option="Pentester" handleDropdown={ handleDropdown } />
          <Item option="Asset Owner" handleDropdown={ handleDropdown } />
          <Item option="Client" handleDropdown={ handleDropdown } />
        </Dropdown>
      )}
      { showWarning && <ModalWarning onCloseCallback={ onClose } title="Change Role" description={ message } /> }
    </Wrapper>
  );
};

export default ChangeRole;
