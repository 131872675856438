import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomActionBar from 'Screens/Contextualization/Host/components/CustomActionBar';
import { showHostModalDelete, toggleModalEditCreate } from 'store/Host/actions';
import { selectShowDeleteConfirmation } from 'store/Host/selectors';
import { selectHostDetail } from 'store/HostDetail/selectors';
import { clearGroupByAssetsGeneral, setGroupByAssetsGeneral, setPageNumberAssetsGeneral } from 'store/HostsGeneral/actions';
import { selectHostGeneralCount, selectHostsSelected, selectIsFetching, selectSelectAllHosts } from 'store/HostsGeneral/selectors';
import { resetDefault, setFieldVisibility, setNewOrderColumns } from 'store/Preferences/hostsGeneral/contextActions';
import { selectFields } from 'store/Preferences/hostsGeneral/selectors';

const ActionBar = () => {
  const dispatch = useDispatch();
  const hostsSelected = useSelector(selectHostsSelected);
  const hostsCount = useSelector(selectHostGeneralCount);
  const showHostDetail = useSelector(selectHostDetail);
  const isFetching = useSelector(selectIsFetching);
  const showDeleteConfirmation = useSelector(selectShowDeleteConfirmation);
  const fields = useSelector(selectFields);
  const selectAll = useSelector(selectSelectAllHosts);

  return (
    <CustomActionBar
      hostsSelected={ hostsSelected }
      hostsCount={ hostsCount }
      showHosDetail={ showHostDetail }
      entity="assetsGeneral"
      setPage={ (page) => dispatch(setPageNumberAssetsGeneral(page)) }
      toggleCreateModal={ (value) => dispatch(toggleModalEditCreate(value)) }
      showHostModalDelete={ () => dispatch(showHostModalDelete()) }
      setGroupBy={ (groupBy) => dispatch(setGroupByAssetsGeneral(groupBy)) }
      clearGroupBy={ () => dispatch(clearGroupByAssetsGeneral()) }
      resetDefault={ () => dispatch(resetDefault()) }
      setFieldVisibility={ (fieldObj, value) => dispatch(setFieldVisibility(fieldObj, value)) }
      setNewOrderColumns={ (columns) => dispatch(setNewOrderColumns(columns)) }
      isFetching={ isFetching }
      showDeleteConfirmation={ showDeleteConfirmation }
      fields={ fields }
      selectAll={ selectAll }
    />
  );
};

export default ActionBar;
