/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import get from 'lodash/get';
import last from 'lodash/last';
import { useDispatch, useSelector } from 'react-redux';
import * as FileSaver from 'file-saver';
import { Base64 } from 'js-base64';
import { deleteAttachment, setDescriptionInAttachment } from 'Screens/ManageEditCreate/actions/Actions';
import { selectVulnDetailId } from 'store/Manage/selectors';
import copyToClipboard from 'utils/clipboard';
import {
  ImageWrapper, FileName, Options, AttachmentWrapper, DeleteEvidenceIcon,
  Clipboard, FileNameWrapper, FileExtension, DownloadIcon,
  Wrapper,
  StyledTextarea,
  Container,
  Text,
  Line,
  AttachmentContainer,
  EvidenceImg,
  ImagePlaceholder
} from './styled';
import Comment from './Comment';
import { addDescriptionInEvidence, setImagePreview } from 'store/Manage/actions';

const Attachment = ({ vulnDescId, deleteEvidence, evidence, name, isEditing, desc }) => {
  const dispatch = useDispatch();
  const vulnDetailId = useSelector(selectVulnDetailId);
  const contentType = get(evidence, 'content_type', '');
  const data = get(evidence, 'data', '');

  const isAnImage = contentType.includes('image');
  const imageSrc = `data:${contentType};base64,${data}`;
  const filename = name.split('.').slice(0, -1).join('.');
  const extension = `.${last(name.split('.'))}`;
  const [description, setDescription] = useState(desc);

  const copyTextToClipboard = () => copyToClipboard(`(evidence:vulnerability:${vulnDetailId}:${name})`);

  const onDelete = () => {
    if (isEditing) dispatch(deleteAttachment(name));
    else deleteEvidence(name);
  };

  const download = () => {
    if (contentType.includes('image')) {
      Base64.extendString();
      const u8arr = Base64.toUint8Array(data);
      const file = new File([u8arr], filename, { type: contentType });
      FileSaver.saveAs(file, name);
    } else {
      Base64.extendString();
      const u8arr = data.toUint8Array();
      const file = new File([u8arr], filename, { type: contentType });
      FileSaver.saveAs(file, name);
    }
  };

  const handleChange = (e) => {
    setDescription(e.target.value);
    dispatch(setDescriptionInAttachment(name, e.target.value));
  };

  const onBlur = () => vulnDescId && dispatch(addDescriptionInEvidence(vulnDescId, { description }, name));

  return (
    <Wrapper>
      <AttachmentWrapper key={ `attachement_vuln_detail_${name}` }>
        <AttachmentContainer>
          <ImageWrapper>
            {isAnImage ? <EvidenceImg onClick={ () => dispatch(setImagePreview(imageSrc)) } src={ imageSrc } alt={ name } /> : <ImagePlaceholder />}
            {/* <Text>
              <Line children='Evidence' />
              <Line children='File' />
            </Text> */}
            <Options>
              { !isEditing && <DownloadIcon onClick={ download } title="Download" /> }
              { (!isEditing && isAnImage) && <Clipboard onClick={ copyTextToClipboard } title="Copy markdown reference" /> }
              <DeleteEvidenceIcon onClick={ onDelete } />
            </Options>
          </ImageWrapper>
        </AttachmentContainer>
        <FileNameWrapper>
          <FileName children={ filename } />
          <FileExtension children={ extension } />
        </FileNameWrapper>
      </AttachmentWrapper>
      <Comment>
        <Container>
          <StyledTextarea value={ description } placeholder="Comment this evidence" onChange={ (e) => handleChange(e) } onBlur={ onBlur } />
        </Container>
      </Comment>
    </Wrapper>
  );
};

export default Attachment;
