import React from 'react';
import Switch from 'react-switch';
import colors from 'Styles/colors';
import { SwitchContainer } from './styled';

const TwoFactorSwitch = ({ status, id, change2fa }) => (
  <SwitchContainer checked={ status !== 'disabled' }>
    <Switch
      checked={ status !== 'disabled' }
      onChange={ (checked, e) => { change2fa(id, !checked); } }
      onClick={ (checked, e) => { change2fa(id, !checked); } }
      onColor={ colors.green4 }
      offColor={ colors.grey14 }
      onHandleColor={ colors.green3 }
      offHandleColor={ colors.grey15 }
      boxShadow="0 0 4px 0 rgba(83, 83, 83, 0.5)"
      activeBoxShadow=""
      height={ 14 }
      width={ 39 }
      handleDiameter={ 21 }
      uncheckedIcon={ false }
      checkedIcon={ false }
    />
  </SwitchContainer>
);

export default TwoFactorSwitch;
