import styled from 'styled-components';

export const LegacyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 37px;
`;
LegacyWrapper.displayName = 'LegacyWrapper';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px 0 20px 10px;
  background-color: ${({ theme }) => theme.backgrounds.actionBar};
`;
Wrapper.displayName = 'Wrapper';

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  height: 37px;
`;
ButtonWrapper.displayName = 'ButtonWrapper';

export const Title = styled.div`
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.08;
  letter-spacing: normal;
  color: #1c2b43;
  user-select: none;
`;
Title.displayName = 'Title';
