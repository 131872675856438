import get from 'lodash/get';
import { ADD_WORKSPACE_SUCCESS, SET_WORKSPACE_SELECTED } from 'Screens/Wizard/actions/Actions';
import {
  SET_SHOW_USER_FEEEDBACK,
  SET_URL_RETURN
} from '../../Screens/Faraday/actions/Actions';
import { SET_MODE_TEMPLATE_CREATE_UPDATE } from '../../Screens/KnowledgeBaseEditCreate/actions/Actions';
import { LOAD_PREFERENCES } from '../../Screens/Login/actions/Actions';
import {
  GET_DATA_WORKSPACES_SUCCESS,
  GET_WORKSPACES_FOR_WORKING_STARTED,
  GET_WORKSPACES_FOR_WORKING_SUCCESS,
  GET_WORKSPACES_FOR_WORKING_FAILURE,
  CURRENT_WORKSPACE_EDITED_UPDATE,
  SET_CURRENT_WORKSPACE_AS_INVALID,
  SET_SHOW_WORKSPACE_VALIDATOR,
  WORKSPACE_SELECTED_FOR_WORKING
} from '../../Screens/Workspaces/actions/Actions';
import {
  SET_HOSTS_COUNT_FAILURE,
  SET_HOSTS_COUNT_SUCCESS,
  SET_VULNS_COUNT_SUCCESS,
  SET_VULNS_COUNT_FAILURE,
  SET_TOTAL_COUNT_HOSTS_SUCCESS,
  SET_TOTAL_COUNT_HOSTS_FAILURE,
  WORKSPACE_EDITED_UPDATE
} from '../../Screens/Contextualization/Faraday/actions/Actions';

const initialState = {
  isFetching: false,
  workspaces: [],
  workspaceSelected: undefined,
  readonly: false,
  workspaceList: [],
  showWorkspaceValidator: false,
  error: false,
  errorMessage: '',
  mode: '',
  permissions: {},
  version: '',
  hash: '',
  role: '',
  userName: '',
  urlReturn: '',
  showUserFeedback: false
};

function faraday (state = initialState, action) {
  switch (action.type) {
    case SET_SHOW_USER_FEEEDBACK: {
      return {
        ...state,
        showUserFeedback: action.show
      };
    }
    case WORKSPACE_SELECTED_FOR_WORKING: {
      const ws = action.workspace ? state.workspaces.filter((ws) => ws.name === action.workspace)[0] : null;
      const readonly = get(ws, 'readonly', false);
      return {
        ...state, workspaceSelected: action.workspace, readonly
      };
    }

    case CURRENT_WORKSPACE_EDITED_UPDATE: {
      const ws = action.oldWsName ? state.workspaces.filter((ws) => ws.name === action.oldWsName)[0] : null;
      const readonly = get(ws, 'readonly', false);
      return {
        ...state, workspaceSelected: action.newWsName, readonly
      };
    }

    case GET_WORKSPACES_FOR_WORKING_STARTED:
      return { ...state, isFetching: true };

    case GET_WORKSPACES_FOR_WORKING_SUCCESS:
      return { ...state, workspaces: action.data, isFetching: false };

    case GET_WORKSPACES_FOR_WORKING_FAILURE:
      return {
        ...state, error: true, errorMessage: action.data, isFetching: false
      };

    case GET_DATA_WORKSPACES_SUCCESS:
      return { ...state, workspaces: action.data, isFetching: false };

    case SET_MODE_TEMPLATE_CREATE_UPDATE: {
      return { ...state, mode: action.mode };
    }

    case LOAD_PREFERENCES: {
      return {
        ...state,
        permissions: action.permissions,
        hash: action.hash,
        role: action.role,
        edate: action.edate,
        licenseStatus: get(action, 'licenseStatus.license_status', 'INVALID'),
        userName: action.userName,
        userId: action.user_id,
        email: action.email,
        wsPort: action.wsPort,
        type: action.licenseType,
        notificationsEnabled: get(action, 'notificationsEnabled', false),
        featureFlags: action.featureFlags
      };
    }

    case ADD_WORKSPACE_SUCCESS: {
      const workspaces = state.workspaces;
      workspaces.unshift(action.data);

      return {
        ...state,
        workspaces,
        workspaceSelected: action.data.name
      };
    }

    case SET_CURRENT_WORKSPACE_AS_INVALID: {
      return {
        ...state,
        workspaceSelected: null,
        workspaces: state.workspaces.filter((ws) => ws.name !== state.workspaceSelected),
        showWorkspaceValidator: true
      };
    }

    case SET_SHOW_WORKSPACE_VALIDATOR: {
      return {
        ...state,
        showWorkspaceValidator: action.show
      };
    }
    case SET_WORKSPACE_SELECTED: {
      return {
        ...state,
        workspaceSelected: action.workspace
      };
    }

    case WORKSPACE_EDITED_UPDATE: {
      const copyWorkspaces = state.workspaces.map((ws) =>
        ws.name === action.ws.name ? { ...ws, readonly: action.ws.readonly } : ws
      );

      const updatedWorkspace = copyWorkspaces.find((ws) => ws.name === action.ws.name);
      if (!updatedWorkspace) {
        return state;
      }

      return {
        ...state,
        workspaces: copyWorkspaces,
        readonly: updatedWorkspace.readonly
      };
    }

    case SET_URL_RETURN: {
      return {
        ...state,
        urlReturn: action.urlReturn
      };
    }

    case SET_VULNS_COUNT_SUCCESS: {
      return {
        ...state,
        vulnsCount: {
          confirmed: action.confirmed,
          unconfirmed: action.unconfirmed,
          total: action.total
        }
      };
    }

    case SET_VULNS_COUNT_FAILURE: {
      return {
        ...state,
        error: true,
        errorMessage: action.data,
        isFetching: false
      };
    }

    case SET_HOSTS_COUNT_SUCCESS: {
      return {
        ...state,
        hostsCount: action.total
      };
    }

    case SET_HOSTS_COUNT_FAILURE: {
      return {
        ...state,
        error: true,
        errorMessage: action.data,
        isFetching: false
      };
    }

    case SET_TOTAL_COUNT_HOSTS_SUCCESS: {
      return {
        ...state,
        totalHosts: action.totalHosts
      };
    }

    case SET_TOTAL_COUNT_HOSTS_FAILURE: {
      return {
        ...state,
        error: true,
        errorMessage: action.data,
        isFetching: false
      };
    }

    default:
      return state;
  }
}

export default faraday;
