import styled from 'styled-components';
import colors from 'Styles/colors';
import { ellipsisText } from 'Styles/styles';

export const Title = styled.div`
  color: ${({ theme }) => theme.text.assetName};
  font-size: 14.5px;
  cursor: pointer;
  font-weight: 500;
  &:hover {
    text-decoration: underline;
  }
`;
Title.displayName = 'Title';

export const Text = styled.div`
  color: ${({ theme }) => theme.text.analyticsTable};
  font-size: 13.5px;
`;
Text.displayName = 'Text';

export const GraphDate = styled.div`
  color: ${colors.blueGrey};
  font-size: 11.5px;
`;
GraphDate.displayName = 'GraphDate';

export const WrapperWs = styled.div`
  display: flex;
`;
WrapperWs.displayName = 'WrapperWs';

export const Workspaces = styled.div`
  font-size: 13.5px;
  font-weight: 400;
  color: ${({ theme }) => theme.text.analyticsTable};
  cursor: pointer;
  ${ellipsisText}
  padding-right: 15px;
  font-weight: 500;
`;
Workspaces.displayName = 'Workspaces';
