import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  margin-bottom: 10px;
`;
Wrapper.displayName = 'CheckboxWrapper';

export const Subtitle = styled.div`
    opacity: ${({ disabled }) => (disabled ? '0.3' : '1')} ;
    user-select: none;
    text-align: left;
    margin-bottom: 10px;
    font-size: 13.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: ${({ theme }) => theme.text.expander};
`;
Subtitle.displayName = 'Subtitle';

export const CheckboxWrapper = styled.div`
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')} ;
  display: flex;
  flex-direction: row;
  height: 24px;
  margin-bottom: 15px;
  >:nth-child(1){
      margin-top: auto;
      margin-bottom: auto;
  }
`;
CheckboxWrapper.displayName = 'CheckboxWrapper';

export const Title = styled.div`
  font-size: 13.5px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: ${({ theme }) => theme.text.blackenedModalTitle};
  user-select: none;
  margin: auto 0px auto 12px;
`;
Title.displayName = 'Title';
