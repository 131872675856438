import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PERMISSION_TYPES, FEATURE_USERS } from 'Hooks/usePermission/permissions';
import IconButton from 'Common/Components/IconButton';
import { showUserDetail } from 'Screens/Contextualization/Users/actions/Actions';
import { selectUserSelected } from 'store/Users/selectors';
import { Edit as EditIcon } from '../../styled';
import ModalWarning from 'Common/Components/ModalWarning';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { useIntl } from 'react-intl';
import { selectActiveUser } from 'store/Sesion/selectors';

const Edit = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [showWarning, setShowWarning] = useState(false);
  const userSelected = useSelector(selectUserSelected);
  const activeUser = useSelector(selectActiveUser);
  const allowedByRole = useSelector((state) => selectAllowedByRole(state, FEATURE_USERS, PERMISSION_TYPES.UPDATE));
  const canUpdate = allowedByRole && !userSelected.some((u) => u.username === activeUser);
  const message = intl.formatMessage({ id: 'modalWarning.selectOnlyOne' }, { entity: 'user' });
  const handleClick = () => {
    if (canUpdate) {
      if ((userSelected.length === 1)) dispatch(showUserDetail(true));
      else setShowWarning(true);
    }
  };

  const onClose = () => setShowWarning(false);
  return (
    <>
      <IconButton icon={ <EditIcon disabled={ !canUpdate } /> } title="Edit" onClick={ handleClick } disabled={ !canUpdate } />
      { showWarning && <ModalWarning onCloseCallback={ onClose } title="Edit" description={ message } /> }
    </>
  );
};

export default Edit;
