import get from 'lodash/get';

export const selectError = (state) => get(state, 'settings.ticketingTools.solarWinds.error', false);

export const selectMessage = (state) => get(state, 'settings.ticketingTools.solarWinds.message', '');

export const selectUrl = (state) => get(state, 'settings.ticketingTools.solarWinds.url', '');

export const selectApiKey = (state) => get(state, 'settings.ticketingTools.solarWinds.apiKey', '');

export const selectTemplate = (state) => get(state, 'settings.ticketingTools.solarWinds.template', '');

export const selectClientId = (state) => get(state, 'settings.ticketingTools.solarWinds.client_id', '');

export const selectCategoryId = (state) => get(state, 'settings.ticketingTools.solarWinds.category_id', '');

export const selectLocationId = (state) => get(state, 'settings.ticketingTools.solarWinds.location_id', '');
