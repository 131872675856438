import get from 'lodash/get';

export const selectTemplates = (state) => {
  let templates = get(state, 'manageEditCreate.templates.rows', []);
  templates = templates.map((template) => template.doc);
  return templates;
};

export const selectManageEditCreate = (state) => {
  const manageEditCreate = get(state, 'manageEditCreate', {});
  return manageEditCreate;
};

export const selectDataField = (state) => get(state, 'manageEditCreate.data', '');
export const selectNameField = (state) => get(state, 'manageEditCreate.name', '');
export const selectDescriptionField = (state) => get(state, 'manageEditCreate.description', '');
export const selectSeverityField = (state) => get(state, 'manageEditCreate.severity', '');
export const selectTargetsField = (state) => get(state, 'manageEditCreate.targets', []);
export const selectRequestField = (state) => get(state, 'manageEditCreate.request', '');
export const selectResponseField = (state) => get(state, 'manageEditCreate.response', '');
export const selectIsWebVuln = (state) => get(state, 'manageEditCreate.isWebVuln', false);
export const selectWebsiteField = (state) => get(state, 'manageEditCreate.website', '');
export const selectStatusCodeField = (state) => get(state, 'manageEditCreate.status_code', '');
export const selectParamNameField = (state) => get(state, 'manageEditCreate.paramName', '');
export const selectParamsField = (state) => get(state, 'manageEditCreate.params', '');
export const selectQueryField = (state) => get(state, 'manageEditCreate.query', '');
export const selectMethodField = (state) => get(state, 'manageEditCreate.method', '');
export const selectPathField = (state) => get(state, 'manageEditCreate.path', '');
export const selectFieldsError = (state) => get(state, 'manageEditCreate.fieldsError', false);

export const selectReadonly = (state) => {
  const readonly = get(state, 'faraday.readonly', false);
  return readonly;
};

export const selectServicesAndHosts = (state) => {
  let services = get(state, 'manageEditCreate.services', []);
  const hosts = get(state, 'manageEditCreate.hosts', []);

  const data = hosts.map((host) => host.value);
  services = services.map((service) => service.value);
  services.forEach((service) => {
    const host = data.find((item) => item.id === service.host_id);
    if (host) {
      const serv = { ...service, hostname: host.name, hostnameData: host.hostnames.length > 0 ? host.hostnames : '' };
      data.push(serv);
    }
  });

  return data;
};

export const selectField = (state, field) => get(state, `manageEditCreate.customFields.${field.field_name}`, '');

export const selectFilesToUpload = (state) => get(state, 'manageEditCreate.filesToUpload', []);
export const selectUploadState = (state) => get(state, 'manageEditCreate.uploadState', '');
export const selectFileIndex = (state) => get(state, 'manageEditCreate.fileIndex', -1);
export const selectErrorFilesList = (state) => get(state, 'manageEditCreate.errorFilesList', []);
export const selectCurrentUploadState = (state) => get(state, 'manageEditCreate.currentUploadState', '');
export const selectCurrentUploadError = (state) => get(state, 'manageEditCreate.currentUploadError', '');

export const selectError = (state) => get(state, 'manageEditCreate.error', false);
export const selectErrorMessage = (state) => get(state, 'manageEditCreate.errorMessage', false);
export const selectIgnoreInfo = (state) => get(state, 'manageEditCreate.ignoreInfo', false);
export const selectResolveHost = (state) => get(state, 'manageEditCreate.resolveHost', false);

export const selectTargetsHosts = (state) => {
  const targets = selectTargetsField(state);
  const hostsSelected = targets.filter((target) => target.type === 'Host');
  return hostsSelected;
};

export const selectTargetsServices = (state) => {
  const targets = selectTargetsField(state);
  const servicesSelected = targets.filter((target) => target.type === 'Service');
  return servicesSelected;
};

export const selectAllHosts = (state) => get(state, 'manageEditCreate.hosts', []);

export const selectAllServices = (state) => {
  const services = get(state, 'manageEditCreate.services', []);
  const data = services.map((service) => service);
  return data;
};

export const selectHasAssetTags = (state) => get(state, 'manageEditCreate.assetsTags', false);
export const selectHasVulnTags = (state) => get(state, 'manageEditCreate.vulnsTags', false);
export const selectHasServiceTags = (state) => get(state, 'manageEditCreate.servicesTags', false);

export const selectTargetsHostsOutsideWs = (state, isOutsideWs) => {
  const targets = selectTargetsField(state);
  const hostsSelected = isOutsideWs ? targets.filter((target) => target.value?.type === 'Host') : [];
  return hostsSelected;
};

export const selectTargetsServicesOutsideWs = (state) => {
  const targets = selectTargetsField(state);
  const servicesSelected = targets.filter((target) => target.value?.type === 'Service');
  return servicesSelected;
};

export const selectErrorCreateVuln = (state) => get(state, 'manageEditCreate.errorModalFields', false);
export const selectErrorMessageCreateVuln = (state) => get(state, 'manageEditCreate.errorMessageModalFields', '');
export const selectErrorTitleCreateVuln = (state) => get(state, 'manageEditCreate.errorTitleModalFields', '');

export const selectWorkspacesField = (state) => get(state, 'manageEditCreate.workspaces', []);

export const selectServicesAndHostsContext = (state) => {
  let services = get(state, 'manageEditCreate.services', []);
  const hosts = get(state, 'manageEditCreate.hosts', []);

  const data = hosts.map((host) => host);
  services = services.map((service) => service);
  services.forEach((service) => {
    const host = data.find((item) => item.id === service.host_id);
    if (host) {
      const serv = { ...service, hostname: host.name, hostnameData: host.hostnames.length > 0 ? host.hostnames : '' };
      data.push(serv);
    }
  });

  return data;
};

export const selectServicesAndHostsContextMove = (state) => {
  let services = get(state, 'manageEditCreate.services', []);
  const hosts = get(state, 'manageEditCreate.hosts', []);

  const data = hosts.map((host) => { return { ...host, type: 'Host' }; });
  services = services.map((service) => service.value);
  services.forEach((service) => {
    const host = data.find((item) => item.id === service.host_id);
    if (host) {
      const serv = { ...service, name: service.name, hostname: host.name, hostnameData: host.hostnames.length > 0 ? host.hostnames : '', type: 'Service' };
      data.push(serv);
    }
  });

  return data;
};

export const selectWorkspaceSelected = (state) => get(state, 'manageEditCreate.workspace', false);
