import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from 'Common/Components/Checkbox';
import Skeleton from 'Common/Components/Skeleton';
import { selectSelectedReports } from 'store/ExecutiveReport/selectors';
import { setReport } from 'store/ExecutiveReport/actions';

export const CheckReport = ({ report, showSkeleton }) => { // eslint-disable-line import/prefer-default-export
  const reportSelected = useSelector(selectSelectedReports);
  const dispatch = useDispatch();
  const isSelected = reportSelected.find((vulnSelected) => vulnSelected.id === report.id);
  const handleClick = (e) => dispatch(setReport(report));

  if (showSkeleton) return <Skeleton />;
  return (
    <Checkbox theme="grey" state={ !!isSelected } onChange={ handleClick } />
  );
};
