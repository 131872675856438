import styled from 'styled-components';

const Wrapper = styled.div`
  svg {
    color: ${({ theme }) => theme.icons.actionBar.color} !important;
  }
`;
Wrapper.displayName = 'Wrapper';

export default Wrapper;
