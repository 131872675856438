import styled from 'styled-components';
import { ReactComponent as Send } from 'Images/icon-action-bar-send.svg';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 14px 0 11px 20px;
  position: relative;
`;
Wrapper.displayName = 'Wrapper';

export const Button = styled.button`
  width: 101px;
  height: 34px;
  border-radius: 2px;
  border: solid 1px ${({ theme }) => theme.buttons.custom.border};
  background-color: ${({ theme }) => theme.buttons.custom.background};
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.buttons.custom.color};
  display: flex;
  align-items: center;
  padding: 0 15px 0 8px;
  margin-right: 6px;

  &:disabled {
    background-color: ${({ theme }) => theme.buttons.disabled.background};
    color: ${({ theme }) => theme.buttons.disabled.color};
    border: solid 1px ${({ theme }) => theme.buttons.custom.border};
    cursor: not-allowed;
  }
`;
Button.displayName = 'Button';

export const SendIcon = styled(Send)`
  height: 18px;
  margin-right: 7px;
  path {
    fill: ${({ theme, disabled }) => disabled ? theme.buttons.disabled.color : theme.buttons.custom.color};
  }
`;
SendIcon.displayName = 'SendIcon';

export const dropdownStyle = (theme) => ({
  control: () => ({
    display: 'flex',
    height: '34px',
    width: '150px',
    borderRadius: '1px',
    backgroundColor: theme.backgrounds.actionDropDown,
    border: `solid 1px ${theme.borders.actionDropDown}`,
    color: theme.textfield.text,
    paddingLeft: '10px',
    fontWeight: '700',
    textOverflow: 'ellipsis',
    cursor: 'pointer'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  placeholder: (provided) => ({
    ...provided,
    color: theme.text.actionDropDown,
    fontWeight: 500,
    fontSize: '14.5px'
  }),
  input: (provided) => ({
    ...provided,
    fontSize: '13px',
    color: theme.text.actionDropDown,
    fontWeight: 300

  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: theme.text.actionDropDown,
    width: '30px'
  }),
  option: (provided, { isFocused, isDisabled, isSelected, data }) => {
    let backgroundColor;
    if (isDisabled) {
      backgroundColor = theme.backgrounds.actionDropDown;
    } else if (isFocused) {
      backgroundColor = theme.backgrounds.modal2;
    } else if (isSelected) {
      backgroundColor = theme.backgrounds.modal4;
    } else if (data.required) {
      backgroundColor = 'rgba(255,86,48,0.1)';
    } else {
      backgroundColor = theme.backgrounds.actionDropDown;
    }

    const color = isDisabled ? theme.buttons.disabled.color : theme.buttons.custom.color;

    return {
      ...provided,
      backgroundColor,
      fontSize: '14px',
      textAlign: 'left',
      color,
      cursor: 'pointer',
      paddingLeft: '13px',
      height: '36px',
      textTransform: 'capitalize',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden'
    };
  },
  valueContainer: () => ({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginRight: 'auto'
  }),
  menu: () => ({
    position: 'absolute',
    marginTop: '6px',
    zIndex: 5000,
    width: '100%',
    border: 'none',
    borderRadius: '3px',
    backgroundColor: theme.backgrounds.actionDropDown,
    boxShadow: `2px 2px 30px 0 ${theme.shadows.actionDropDown}`,
    minWidth: '140px'
  }),
  menuList: () => ({
    maxHeight: '250px',
    overflowX: 'auto',
    borderRadius: '3px',
    paddingTop: '17px',
    paddingBottom: '13px'
  }),
  singleValue: (provided) => {
    const opacity = 1;
    const transition = 'opacity 300ms';
    return {
      ...provided, opacity, transition, color: theme.text.actionDropDown, width: '105px', height: '19px', fontSize: '14px', fontWeight: 400, top: '8px', position: 'relative'
    };
  }
});
