import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 40px;
  background-color: ${({ theme }) => theme.backgrounds.modal};
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${({ theme }) => theme.borders.tableItem};
  `;

export const Text = styled.p`
color: ${({ theme }) => theme.text.card};
font-size: 14.5px;
`;

export const Bold = styled.b`
font-weight: 600;
`;
export const Blue = styled.span`
color: #0082ed;
font-size: 14.5px;
  font-weight: 500;
  margin-left: 12px;
  cursor: pointer;
  padding: 6px 12px;

  &:hover{
  border-radius: 2px;
  background-color:rgba(0,130,237, 0.06);
}
`;
