import React, { useContext } from 'react';
import DropdownItem from '../../UserDropdownItem';
import { CollapsedIcon, Container, Text } from './styled';
import { ThemeContext } from 'Context';

const AppearanceDropdown = ({ setAppearance }) => {
  const { theme } = useContext(ThemeContext);

  const action = (e) => {
    e.stopPropagation();
    setAppearance(true);
  };

  return (
    <DropdownItem action={ (e) => action(e) }>
      <Container>
        <Text>{`Appearance: ${theme}`}</Text>
        <CollapsedIcon />
      </Container>
    </DropdownItem>
  );
};

export default AppearanceDropdown;
