import React from 'react';
import { Wrapper, Title, Fields } from './styled';
import FirstRow from '../FirstRow';
import SecondRow from '../SecondRow';
import ThirdRow from '../ThirdRow';
import FourthRow from '../FourthRow';
import Table from '../Table';

const Elements = () => {
  return (
    <Wrapper>
      <Title children='Jira configuration' />
      <Fields>
        <FirstRow />
        <SecondRow />
        <ThirdRow />
        <FourthRow />
        <Table />
      </Fields>
    </Wrapper>
  );
};

export default Elements;
