import styled from 'styled-components';
import { ReactComponent as StatusSVG } from 'Images/status.svg';

export const Wrapper = styled.div`
  
`;
Wrapper.displayName = 'Wrapper';

export const Dropdown = styled.div`
  position: absolute;
  width: 200px;
  box-shadow: 2px 2px 10px 0 ${({ theme }) => theme.shadows.actionDropDown};
  background-color: ${({ theme }) => theme.backgrounds.actionDropDown};
  border: 1px solid ${({ theme }) => theme.borders.actionDropDown};
  border-radius: 0.25rem;
  z-index: 3;
`;
Dropdown.displayName = 'Dropdown';

export const StatusIcon = styled(StatusSVG)`
  & > path {
    fill: ${({ theme }) => theme.icons.actionBar.color};
  }
`;
StatusIcon.displayName = 'StatusIcon';
