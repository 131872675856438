import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RiSearchLine as SearchLine } from 'react-icons/ri';
import {
  Wrapper,
  DataContainer,
  DataItem
} from './styled';
import './styles.scss';

class SearchWs extends React.Component {
  constructor (props) {
    super(props);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onSelectHandler = this.onSelectHandler.bind(this);

    this.state = {
      value: '',
      showData: true
    };
  }

  onChangeHandler (v) {
    this.setState({ value: v });
    this.setState({ showData: true });
  }

  onSelectHandler (item) {
    const { onSelect } = this.props;
    this.setState({ value: item.name });
    // this.setState({ value: '' });
    this.setState({ showData: false });
    onSelect(item);
  }

  currentTheme () {
    const themeClass = localStorage.getItem('theme') === 'light' ? 'light-search-ws' : 'dark-search-ws';
    return themeClass;
  }

  render () {
    const { value, showData } = this.state;
    const { type, data, placeholder } = this.props;

    const filteredData = data.filter((it) => value !== '' && it.name.toLowerCase().includes(value.toLowerCase()));

    return (
      <Wrapper>
        <SearchLine color="#a1b4b6" className={ `custom-input-search-lupe ${this.currentTheme()}` } onClick={ () => { this.setState({ showData: true }); } } />
        <input className={ `custom-input-search ${value.length > 0 ? 'full' : ''}` } value={ value } placeholder={ placeholder } type={ type } onChange={ (e) => this.onChangeHandler(e.target.value) } onBlur={ () => { this.setState({ showData: false }); } } />
        {
          value.length > 0 && showData &&
          (
            <DataContainer>
              { filteredData.length > 0 && filteredData.map((item) => (
                <DataItem className="custom-data-item text-left" key={ `${item.type}_${item.id}` } onMouseDown={ () => { this.onSelectHandler(item); } }>
                  {item.name}
                </DataItem>
              )) }
            </DataContainer>
          )
        }
      </Wrapper>
    );
  }
}

SearchWs.propTypes = {
  data: PropTypes.array.isRequired,
  // getObjects: PropTypes.func.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  onSelect: PropTypes.func.isRequired
};

SearchWs.defaultProps = {
  type: 'text',
  placeholder: 'Search'
};

const mapDispatchToProps = () => ({

});

const mapStateToProps = () => ({

});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchWs));
