import React from 'react';
import { useSelector } from 'react-redux';
import {
  selectGraphs, selectIsFetching, selectCurrentViewMode, selectIsEmpty,
  selectGraphsSelected
} from 'store/Analytics/selectors';
import { FormattedMessage } from 'react-intl';
import { GRAPH_LIST_VIEW } from 'store/Analytics/viewMode';
import { getColumns, setOrder } from './Columns';
import { Wrapper, StyledTable } from './styled';
import useTheme from 'Hooks/UseTheme';

const Table = () => {
  const isEmpty = useSelector(selectIsEmpty);
  const viewMode = useSelector(selectCurrentViewMode);
  const graphs = useSelector(selectGraphs);
  const isFetching = useSelector(selectIsFetching);
  const graphsSelected = useSelector(selectGraphsSelected);
  const mockData = [...Array(15).keys()];
  const { themeStyles } = useTheme();

  const fetchData = (state) => {
    const { sorted } = state;
    if (!isFetching) {
      setOrder(sorted, graphs);
    }
  };

  const getTrProps = (rowInfo, graphsSelected) => {
    const selected = graphsSelected.find((graph) => graph.id === rowInfo.original.id);
    if (rowInfo && rowInfo.row) {
      return {
        style: {
          background: selected ? `${themeStyles?.backgrounds?.selectedTableItem}` : ''
        }
      };
    }
    return {};
  };

  if (isEmpty) return null;
  if (viewMode === GRAPH_LIST_VIEW) {
    return (
      <Wrapper>
        <StyledTable
          data={ isFetching ? mockData : graphs }
          columns={ getColumns(isFetching) }
          minRows={ 0 }
          showPagination={ false }
          noDataText={ !isFetching && <FormattedMessage id="app.nodata" /> }
          onFetchData={ fetchData }
          manual
          getTrProps={ (_, rowInfo) => getTrProps(rowInfo, graphsSelected) }
        />
      </Wrapper>
    );
  }
  return null;
};

export default Table;
