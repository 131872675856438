import React from 'react';
import { WithPermission } from 'Common/Components/WithPermission/index';
import { FEATURE_ANALYTICS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { Description, StyledTitle, Subtitle, SubtitleWrapper, TitleReportsWrapper, TitleWrapper } from '../styled';

export const KbTitle = () => (
  <StyledTitle>Vulnerability Knowledge Base</StyledTitle>
);

export const Agents = () => (
  <StyledTitle>Agents</StyledTitle>
);

export const AnalyticsGraphs = () => (
  <WithPermission permission={ FEATURE_ANALYTICS } type={ PERMISSION_TYPES.CREATE }>
    <TitleWrapper>
        <StyledTitle>Analytics</StyledTitle>
        <Subtitle>My graphs</Subtitle>
    </TitleWrapper>
  </WithPermission>
);

export const NewGraph = () => (
  <WithPermission permission={ FEATURE_ANALYTICS } type={ PERMISSION_TYPES.CREATE }>
    <TitleWrapper>
      <StyledTitle>Analytics</StyledTitle>
      <Subtitle>Create new graph</Subtitle>
    </TitleWrapper>
  </WithPermission>
);
export const EditGraph = () => (
  <WithPermission permission={ FEATURE_ANALYTICS } type={ PERMISSION_TYPES.CREATE }>
    <TitleWrapper>
      <StyledTitle>Analytics</StyledTitle>
      <Subtitle>Graph data</Subtitle>
    </TitleWrapper>
  </WithPermission>
);

export const Planner = () => (
  <TitleWrapper>
    <StyledTitle>Planner</StyledTitle>
    <Subtitle>My Projects</Subtitle>
  </TitleWrapper>
);

export const Reports = () => (
  <TitleReportsWrapper>
    <StyledTitle>Reports</StyledTitle>
    <Subtitle>My Reports</Subtitle>
  </TitleReportsWrapper>
);

export const CreateReport = () => (
  <TitleWrapper>
    <StyledTitle>Reports</StyledTitle>
    <Subtitle>Create Report</Subtitle>
  </TitleWrapper>
);

export const EditReport = () => (
  <TitleWrapper>
    <StyledTitle>Reports</StyledTitle>
    <Subtitle>Edit Report</Subtitle>
  </TitleWrapper>
);

export const Notifications = () => (
  <TitleWrapper>
    <StyledTitle>Notifications</StyledTitle>
    <SubtitleWrapper>
      <Subtitle>History</Subtitle>
      <Description> - Logged Faraday events</Description>
    </SubtitleWrapper>

  </TitleWrapper>
);

export const NotificationsSettings = () => (
  <TitleWrapper>
    <StyledTitle>Notifications</StyledTitle>
    <SubtitleWrapper>
      <Subtitle>Settings</Subtitle>
      <Description> - Enable / disable event logging and trigger notifications / alerts</Description>
    </SubtitleWrapper>
  </TitleWrapper>
);

export const Scheduler = () => (
  <StyledTitle>Scheduler</StyledTitle>
);

export const Users = () => (
  <StyledTitle>Users</StyledTitle>
);
