import styled from 'styled-components';
import ReactTable from 'react-table-6';
import colors from 'Styles/colors';

const CustomReactTable = styled(ReactTable)`

  border: 0;
  background: ${colors.white1};
  height: 100%;
  overflow: auto;

  .rt-expandable{
    width: fit-content !important;
    max-width: fit-content !important;
  }

  .tableRow:hover {
    background-color: ${colors.blue8} !important;
  }

  .rt-table {
    overflow: unset;
    .rt-thead {
      min-width: 100% !important;
      width: fit-content;
      padding: 0;
      background: ${({ theme }) => theme.backgrounds.tableHeader};
      border-top: 1px solid ${({ theme }) => theme.borders.tableHeaderTop};
      border-bottom: 1px solid ${({ theme }) => theme.borders.tableHeaderBottom};
      position: sticky;
      top: 0;
      z-index: 1;
      &.-header {
        box-shadow: unset;
      }
      .rt-tr {
        line-height: 33px;
        height: 33px;
        align-items: center;
        .rt-th {
          min-height: 13px;
          font-family: 'CentraNo2';
          font-weight: 400;
          .table-order-icon, .table-order-icon-preview {
            visibility: hidden;
            position: absolute;
            display: inline-block;
            color: ${colors.grey9};
            right: 15px;
          }
          &:hover .table-order-icon {
            visibility: visible;
          }

          &.-sort-asc, &.-sort-desc {
            display: inline;
            box-shadow: none;
            .table-order-icon {
            visibility: visible;
          }
        }
          :not(.hideSortIcon){
            &.-sort-asc:after, &.-sort-desc:after {
              content: ' ';
              right: 5px;
              top: 40%;
              position: absolute;
              border: solid #adb6c1;
              border-width: 0 1px 1px 0;
              display: inline-block;
              padding: 2px;
            }

            &.-sort-asc:after {
              transform: rotate(-135deg);
              -webkit-transform: rotate(-135deg);
            }

            &.-sort-desc:after {
              transform: rotate(45deg);
              -webkit-transform: rotate(45deg);
            }
          }
        }
      }
      .rt-th, .rt-td {
        text-align: left;
        padding: 0px 10px;
        border-right: 1px dashed ${({ theme }) => theme.borders.tableHeaderDivider};
        & > * {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 10px;
          font-weight: 300;
          text-transform: uppercase;
          text-align: left;
          color: ${({ theme }) => theme.text.tableHeader};
          line-height: normal;
        }
      }
    }
  }
  .rt-tbody {
    min-width: 100% !important;
    width: fit-content;
    background: ${({ theme }) => theme.backgrounds.table};
    overflow: unset;
    .rt-tr-group {
      flex: none;
      border-bottom: solid 1px ${({ theme }) => theme.borders.tableItem};
      &:hover {
        /* background: ${({ theme }) => theme.backgrounds.hoverTableItem}; */
        background: ${({ theme }) => theme.backgrounds.selectedTableItem};
        .goToButton {
          visibility: visible;
        }

      }
      .group-header {
        background-color: ${colors.light4};
      }
      .rt-expandable {
        .-group-expanded, .-group-unexpanded {
          display: flex;
          align-items: center;
          font-weight: bold;
          font-size: 14px;
          &:before {
            content: ' ';
            border: solid ${colors.grey11};
            border-width: 0 2px 2px 0;
            width: 6px;
            height: 6px;
            margin: 0 15px;
          }
        }
        .-group-expanded:before {
          transform: rotate(-135deg);
          -webkit-transform: rotate(-135deg);
        }

        .-group-unexpanded:before {
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
        }
      }
    }
    .rt-tr {
      line-height: 45px;
      height: 45px;
      align-items: center;
      &.preview {
        background: ${colors.iceBlue};
      }
      .rt-td {
        border-right: none;
        font-weight: 300;
        label {
          margin-bottom: 0px;
        }
        &.opened{
          color: ${colors.red2};
        }
        &.closed{
          color: ${colors.green2};
        }
      }
    }

    .rt-th, .rt-td {
      padding: 0 10px;
      text-align: left;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: ${({ theme }) => theme.text.tableHeader};
      &.column_name {
        font-size: 14px;
        font-weight: 400;
        color: ${({ theme }) => theme.text.tableItem};
      }
      &.column_target {
        font-size: 12.5px;
        font-weight: 400;
        letter-spacing: -.1px;
      }
      &.description {
        font-size: 12.5px;
        font-weight: 400;
        letter-spacing: -.1px;
        color: red;
      }
      & > * {
        //overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        input, img {
          display: block;
        }
      }
    }
  }
  .-loading {
    bottom: 50%;
  }
  .rt-noData {
    background-color: ${({ theme }) => theme.backgrounds.table};
    width: 100%;
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 64px;
    color: ${({ theme }) => theme.text.emptyState};
    font-size: 13px;
    font-weight: 500;
  }
  .rt-thead{
    z-index: 1;
  }
  .goToButton {
    padding: 0px !important;
    visibility: hidden;
    overflow: visible;
  }
`;
CustomReactTable.displayName = 'CustomReactTable';

export default CustomReactTable;
