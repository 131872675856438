const initialState = {
  isFetching: false,
  error: false,
  errorMessage: '',
  reportList: [],
  reportsCount: 0,
  rowsPerPage: 50,
  page: 1,
  order_by: 'create_time',
  order_by_dir: 'desc',
  templates: [],
  selectedReports: []
};

export default initialState;
