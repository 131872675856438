import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as MoveVuln } from 'Images/moveVuln.svg';
import { slideIn } from 'Styles/effects';
import { ReactComponent as AssetImage } from 'Images/assets.svg';

export const AssetIcon = styled(AssetImage)`
  margin-right: 11px !important;
  path {
    fill: ${({ theme }) => theme.icons.detailModalTitle.color};
  }
`;
AssetIcon.displayName = 'AssetIcon';

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
FieldContainer.displayName = 'FieldContainer';

export const MoveIcon = styled(MoveVuln)`

`;

export const ModalContent = styled.div`
  padding: 34px;
  width: 505px;
  box-shadow: ${({ theme }) => theme.boxShadow.modal};
  border-radius: 2px;
  border-top: 10px solid ${colors.softBlue};
  background: ${({ theme }) => theme.backgrounds.modal};
  ${slideIn}
`;
ModalContent.displayName = 'ModalContent';

export const Title = styled.h3`
font-size: 21.5px;
font-weight: 600;
line-height: 1.49;
letter-spacing: normal;
color: ${({ theme }) => theme.text.modalTitle2};
margin: 0 0 0 14px;
`;

export const Subtitle = styled.h5`
font-size: 12.5px;
font-weight: 500;
line-height: 1.44;
color: #63758d;
margin-bottom: 12px;
`;

export const Flex = styled.div`
display:flex;
align-items: center;
margin-bottom: 17px;
`;

export const Footer = styled.div`
  display: flex;
  width: min-content;
  margin-top: 50px;
  margin-left: auto;
  & > *:not(:last-child) {
    margin-right: 10px;
  }
`;
Footer.displayName = 'Footer';
