import styled from 'styled-components';
import { ReactComponent as EditIcon } from 'Images/icon-action-bar-edit.svg';
import { ReactComponent as TrashIcon } from 'Images/icon-action-bar-trash.svg';
import { ReactComponent as ColumnIcon } from 'Images/icon-action-bar-column.svg';
import { ReactComponent as TagIcon } from 'Images/icon-action-bar-tags.svg';
import { ReactComponent as SendIcon } from 'Images/icon-action-bar-send.svg';
import { ReactComponent as MoreIcon } from 'Images/icon-action-bar-more.svg';
import { ReactComponent as DownloadIcon } from 'Images/download-icon.svg';
import colors from 'Styles/colors';
import { fadeIn } from 'Styles/effects';

export const EditColumns = styled(ColumnIcon).attrs({
  id: 'qa-edit-columns-vuln'
})`
    height: 17px;
    g > path {
      fill: ${({ theme }) => theme.icons.actionBar.color};
    }
`;

EditColumns.displayName = 'EditColumns';

export const Trash = styled(TrashIcon).attrs({
  id: 'qa-delete-vuln'
})`
    height: 17px;
    g > path {
      fill: ${colors.red1};
    }
`;

Trash.displayName = 'Trash';

export const Edit = styled(EditIcon).attrs({
  id: 'qa-edit-vuln'
})`
    height: 17px;
    g > path {
      fill: ${({ theme }) => theme.icons.actionBar.color};
    }
`;

export const Download = styled(DownloadIcon).attrs({
  id: 'qa-download-ws'
})`
    height: 17px;
    & path{
    fill: ${({ theme }) => theme.icons.actionBar.color};
  }
`;

Edit.displayName = 'Edit';

export const Tag = styled(TagIcon)`
    height: 17px;
`;

Tag.displayName = 'Tag';

export const Send = styled(SendIcon)`
    height: 17px;
`;

Send.displayName = 'Send';

export const More = styled(MoreIcon)`
    height: 17px;
    width: 20px;
`;

More.displayName = 'More';

export const DownloadButton = styled.button`
  border-radius: 20px;
  border: 0;
  background-color: transparent;
  width: 37px;
  height: 37px;
  align-items: center;
  justify-content: center;
  & > svg {
    min-width: 17px;
    margin-bottom: 3px;
  }
  &:hover {
    background-color: ${({ theme }) => theme.icons.actionBar.background};
    & > svg g, path {
      fill: ${({ theme }) => theme.icons.actionBar.hover};
    }
  }
  &:active {
    ${fadeIn(0, 1)}
    background-color: ${colors.blueGrey};
    & > svg {
      g, path {
        fill: ${colors.white};
      }
    }
  }
  &:disabled {
    & > svg {
      fill: transparent;
      cursor: default;
    }
    & > svg path {
      fill: ${colors.grey12};
      cursor: default;
    }
  }
`;
DownloadButton.displayName = 'DownloadButton';
