import styled from 'styled-components';
import { ReactComponent as _Plus } from 'Images/icon-action-bar-plus.svg';

export const Input = styled.input`
border: none;
padding-right: 35px;
padding-left: 15px;
width: 100%;
height: 34px;
background: ${({ theme }) => theme.backgrounds.inputPlus};
color: ${({ theme }) => theme.textfield.text};
font-size: 13px;
font-weight: 300;

&::placeholder {
    font-size: 13px;
    color: ${({ theme }) => theme.textfield.text};

    font-weight: 300;
    opacity: 0.7;
}

`;

export const Plus = styled(_Plus)`
  width: 12px;
  position: absolute;
  right: 0px;
  margin: 11.5px;
  & path {
    fill: ${({ theme }) => theme.textfield.text};
  }
`;

export const Wrapper = styled.div`
position: relative;

`;
