import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as Icon } from 'Images/tasks_icon.svg';

export const PlannerIcon = styled(Icon)`
  cursor: default;
  margin: 50px auto 0px auto;
  width: 61px;
  height: 55px;
  & g {
    fill: #EDF2F7;
  }
`;

export const Wrapper = styled.div`
  flex: 1 1 0px;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.backgrounds.table};
`;
Wrapper.displayName = 'Wrapper';

export const SubtitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: ${({ theme }) => theme.backgrounds.table};
  justify-content: center;
`;
SubtitleWrapper.displayName = 'SubtitleWrapper';

export const Title = styled.div`
  user-select: none;
  margin: 22px auto 0px auto;
  font-size: 21.5px;
  text-align: center;
  color: ${colors.blueGrey};
`;
Title.displayName = 'Title';

export const Subtitle = styled.div`
  user-select: none;
  margin-top: 14px;
  font-size: 12.5px;
  text-align: center;
  color: ${colors.blueGrey};
`;
Subtitle.displayName = 'Subtitle';

export const NewProject = styled.div`
  font-size: 13.5px;
  margin-top: 13px;
  padding-left: 3px;
  color: ${colors.blueCerulean};
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;
NewProject.displayName = 'NewProject';
