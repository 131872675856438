import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.form`
  width: 264px;
  margin-top: 20px;
`;
Wrapper.displayName = 'Wrapper';

export const Label = styled.label`
  font-size: 12.5px;
  font-weight: 400;
  color: ${colors.grey17};
  margin-bottom: 6px;
`;
Label.displayName = 'Label';

export const Input = styled.div`
  height: 34px;
  padding: 7px 10px 0 15.5px;
  /* box-shadow: inset 0 -1px 0 0 ${colors.grey30}; */
  background-color: ${({ theme }) => theme.textfield.background};
  font-size: 14.5px;
  color: ${({ theme }) => theme.textfield.text}; 
`;
Input.displayName = 'Input';
