import React from 'react';
import { Wrapper, Button } from './styled';
import TextArea from './TextArea';
import { useDispatch } from 'react-redux';
import { getJiraPublicKey } from 'store/Settings/jiraActions';

const SecondRow = () => {
  const dispatch = useDispatch();
  const onClick = () => dispatch(getJiraPublicKey());

  return (
    <Wrapper>
        <Button onClick={ onClick } label="Generate Consumer Key" />
        <TextArea />
    </Wrapper>
  );
};

export default SecondRow;
