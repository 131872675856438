import styled from 'styled-components';
import { ReactComponent as GoTo } from 'Images/icon-goto.svg';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;
Wrapper.displayName = 'Wrapper';

export const Text = styled.div`
  flex: 0 1 auto;
  font-size: 14.5px;
  color: ${({ theme }) => theme.text.tableVulnName};
  font-weight: 600;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &:hover {
    text-decoration: underline;
  }
`;
Text.displayName = 'Text';

export const GoToButton = styled.div`
  width: 38px;
  cursor: pointer;
  border-radius: 20px;
  &:hover {
    background-color: #dfe5ed;
  }
`;
GoToButton.displayName = 'GoToButton';

export const GoToIcon = styled(GoTo)`
  path:not([d="M0 0h38v38H0z"]) {
    fill: ${({ theme }) => theme.icons.actionBar.color} !important;
  }
`;
GoToIcon.displayName = 'GoToIcon';
