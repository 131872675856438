import styled from 'styled-components';
import { ReactComponent as Refresh } from 'Images/reload.svg';

export const Wrapper = styled.div`
  margin-left: 54px;
  display: flex;
  align-items: center;
  margin-top: 2px;
  & >*:not(:first-child) {
    margin-left: 12px;
  }

`;
Wrapper.displayName = 'Wrapper';

export const IconButton = styled.div`
  cursor: pointer;
`;
IconButton.displayName = 'IconButton';

export const RefreshIcon = styled(Refresh)`
  display: flex;
  align-items: center;
  g {
    fill: ${({ theme }) => theme.icons.actionBar.color};
  }
  g >:first-child {
    stroke: ${({ theme }) => theme.icons.actionBar.color};
  }
`;
RefreshIcon.displayName = 'RefreshIcon';
