import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLimitReached } from 'store/Host/selectors';
import { useIntl } from 'react-intl';
import { selectIsReadOnly } from 'store/Faraday/selectors';
import { FEATURE_HOSTS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import AddButton from 'Common/Components/Contextualization/AddButton';
import { selectActiveWorkspacesCount } from 'store/Global/selectors';
import { openModal } from 'store/modals/actions';
import { MODAL_CANT_CREATE } from 'store/modals/modals';

const CreateActions = ({ setVisibilityCreateModal, entity }) => {
  const intl = useIntl();
  const addText = intl.formatMessage({ id: 'host.create' });
  const limitReached = useSelector(selectLimitReached);
  const readOnly = useSelector(selectIsReadOnly);
  const allowedByRole = useSelector((state) => selectAllowedByRole(state, FEATURE_HOSTS, PERMISSION_TYPES.CREATE));
  const canUpdate = !readOnly && !limitReached && allowedByRole;
  const activeWorkspacesCount = useSelector(selectActiveWorkspacesCount);
  const dispatch = useDispatch();

  const handleShow = () => {
    if (entity === 'assetsGeneral' && !activeWorkspacesCount) {
      dispatch(openModal(MODAL_CANT_CREATE));
    } else if (canUpdate) {
      setVisibilityCreateModal(true);
    }
  };

  return (
    <AddButton
      disabled={ !canUpdate }
      onClick={ handleShow }
      title={ addText }
      text={ addText }
    />
  );
};

export default CreateActions;
