import styled from 'styled-components';
import { ReactComponent as Trash } from 'Images/icon-trash-red.svg';
import { ReactComponent as Edit } from 'Images/icon-edit-enabled.svg';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 20px;
  gap: 13px;
`;
Wrapper.displayName = 'Wrapper';

export const TrashIcon = styled(Trash)`
  opacity: ${({ disabled }) => disabled ? '0.5' : '1'};
  pointer-events: ${({ disabled }) => disabled ? 'none' : 'auto'};
`;
TrashIcon.displayName = 'TrashIcon';

export const EditIcon = styled(Edit)`
`;
EditIcon.displayName = 'EditIcon';
